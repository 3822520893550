import React, {Component} from "react";
import MaterialTable from "material-table";
import Permissions from "../../../auth/permissions";
import VisibilityIcon from '@material-ui/icons/Visibility';
import "../../../assets/scss/Static.scss";
import {BaseRentURL} from "../../../urls";
import {instance} from "../../../auth/axios_instance";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import ReceiptIcon from '@material-ui/icons/Receipt';
import {sub_directory} from "../../../App";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {UpdateRentsStatus, viewRentInvoice} from "./rents_apis";
import {Hide} from "../../../components/Hide";
import {CustomBreadcrumbs} from "../../../components/CustomBreadcrumbs";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Toolbar from "@material-ui/core/Toolbar";
import {permissions_data} from "../../../auth/permission_data";

export default class Rents extends Component {
    constructor() {
        super();

        this.tableRef = React.createRef();

        this.state = {
            columns: sort => ([
                {title: getLabel("name"), field: 'full_name'},
                {title: getLabel("service"), field: 'service_name'},
                {title: getLabel("start_date"), field: 'start_date'},
                {title: getLabel("end_date"), field: 'end_date'},
                {
                    title: getLabel("pages.rents.tabel_column.ends_during"),
                    field: 'ends_during',
                    defaultSort: "asc",
                    sorting: sort,
                    render: rowData => <label>{rowData.ends_during} <label className="mr-1 ml-1">Days</label></label>
                },
                {title: getLabel("paid"), field: 'paid'},
                {title: getLabel("balance"), field: 'balance'},
            ]),
            search: "",
            current_tab: 0
        };
    }

    handleTabsChange = (event, newValue) => {
        this.setState({current_tab: newValue})
    };


    reloadData = async () => {
        this.tableRef.current && this.tableRef.current.onQueryChange();
    };

    handleSearchField = (name, newValue) => {
        this.setState({
            [name]: newValue
        });

        if ((newValue.length >= 5 && /^\d+$/.test(newValue)) || newValue === '') {
            this.reloadData();
        }
    };


    render() {

        return (
            <Paper>

                {/*<Toolbar variant="regular" style={{paddingInlineStart: '2rem', paddingTop: '0.7rem'}}>*/}
                {/*    <CustomBreadcrumbs match={this.props.match}/>*/}
                {/*</Toolbar>*/}

                <Grid item xs={12} className="Paper">
                    <Tabs
                        value={this.state.current_tab}
                        onChange={this.handleTabsChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label={getLabel("latest_rents_clients")} id={0}/>
                        <Tab label={getLabel("need_to_be_deliver")} id={1}/>
                        <Tab label={getLabel("need_to_be_collect")} id={2}/>
                    </Tabs>
                </Grid>

                <Grid container spacing={3}>

                    <Grid item xs={12}>

                        <TableToolbar
                            searchFieldName='search'
                            searchValue={this.state.search}
                            onSearchChangeHandle={this.handleSearchField}
                            searchAction={() => this.reloadData()}
                            actions={
                                [
                                    {
                                        hide: !Permissions.checkAllPermissions([permissions_data.rent.add]),
                                        onClick: () => this.props.history.push(sub_directory + 'rents/new'),
                                        label: "add_rent"
                                    },
                                ]
                            }
                        />

                        <Hide hide={this.state.current_tab !== 0}>

                            <MaterialTable
                                tableRef={this.tableRef}
                                columns={this.state.columns(false)}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        let url = BaseRentURL;
                                        url += '?page=' + (query.page + 1);

                                        if (this.state.search !== '') {
                                            url += '&search=' + this.state.search;
                                        }

                                        instance.get(url)
                                            .then(result => {
                                                resolve({
                                                    data: result.data.results,
                                                    page: result.data.page - 1,
                                                    totalCount: result.data.total,
                                                })
                                            })
                                    })
                                }

                                actions={[
                                    {
                                        icon: 'edit',
                                        hidden: !Permissions.checkAllPermissions([permissions_data.rent.change]),
                                        tooltip: getLabel("edit_rent"),
                                        onClick: (event, rowData) => this.props.history.push(sub_directory + 'rents/' + rowData.id)
                                    },
                                    {
                                        icon: VisibilityIcon,
                                        hidden: Permissions.checkAllPermissions([permissions_data.rent.change]),
                                        tooltip: getLabel("pages.rents.view_rent"),
                                        onClick: (event, rowData) => this.props.history.push(sub_directory + 'rents/' + rowData.id)
                                    },
                                    {
                                        icon: ReceiptIcon,
                                        hidden: !Permissions.checkAllPermissions([permissions_data.rent_payment.view]),
                                        tooltip: getLabel("pages.rents.view_rent_invoice"),
                                        onClick: (event, rowData) => viewRentInvoice(rowData.id)
                                    },
                                ]}

                                {...material_table_style}
                                {...material_table_options()}
                                {...material_table_localization()}

                            />
                        </Hide>
                        <Hide hide={this.state.current_tab !== 1}>

                            <MaterialTable
                                tableRef={this.tableRef}
                                columns={this.state.columns(false)}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        let url = BaseRentURL;
                                        url += '?page=' + (query.page + 1) + "&equipment_delivered=false";

                                        if (this.state.search_two !== '') {
                                            url += '&search=' + this.state.search;
                                        }

                                        instance.get(url)
                                            .then(result => {
                                                resolve({
                                                    data: result.data.results,
                                                    page: result.data.page - 1,
                                                    totalCount: result.data.total,
                                                })
                                            })
                                    })
                                }
                                actions={[
                                    {
                                        icon: () => <Button className="text-center" size="small"
                                                            variant="outlined">{getLabel("delivered")}</Button>,
                                        hidden: !Permissions.checkAllPermissions([permissions_data.rent.change]),
                                        tooltip: getLabel("delivered"),
                                        onClick: async (event, rowData) => {
                                            await UpdateRentsStatus(rowData.id, {equipment_delivered: true});
                                            await this.reloadData()
                                        }
                                    }
                                ]}


                                {...material_table_style}
                                {...material_table_options()}
                                {...material_table_localization()}

                            />
                        </Hide>

                        <Hide hide={this.state.current_tab !== 2}>

                            <MaterialTable
                                tableRef={this.tableRef}
                                columns={this.state.columns(true)}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        let url = BaseRentURL;
                                        url += '?page=' + (query.page + 1) + "&equipment_received=false&equipment_delivered=true";

                                        if (this.state.search_three !== '') {
                                            url += '&search=' + this.state.search;
                                        }

                                        instance.get(url)
                                            .then(result => {
                                                resolve({
                                                    data: result.data.results,
                                                    page: result.data.page - 1,
                                                    totalCount: result.data.total,
                                                })
                                            })
                                    })
                                }
                                actions={[
                                    {
                                        icon: () => <Button className="text-center" size="small"
                                                            variant="outlined">{getLabel("received")}</Button>,
                                        hidden: !Permissions.checkAllPermissions([permissions_data.rent.change]),
                                        tooltip: getLabel("received"),
                                        onClick: async (event, rowData) => {
                                            await UpdateRentsStatus(rowData.id, {equipment_received: true});
                                            await this.reloadData()
                                        }
                                    }
                                ]}


                                {...material_table_style}
                                {...{
                                    options: {
                                        ...material_table_options().options, rowStyle: rowData => ({
                                            color: rowData.ends_during < 2 ? "red" : rowData.ends_during < 3 ? "orange" : "blue"
                                        })
                                    }
                                }}
                                {...material_table_localization()}

                            />
                        </Hide>
                    </Grid>


                </Grid>
            </Paper>
        );
    }

}

