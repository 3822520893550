import React, {useEffect, useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import auth from "./auth/auth";
import {Loading} from "./components/Loading";
import {AuthCode} from "./auth/AuthCode";
import moment from "moment";
import "./assets/scss/Dashboard.scss";
import "./assets/scss/Static.scss";
import {LanguageContext} from "./store/use_context";
import Cookies from 'js-cookie';
import {setLocalization} from "./localization/MainLocalization";
import {lang_ar} from "./localization/Ar";
import {lang_en} from "./localization/En";
import {jssPreset, StylesProvider, ThemeProvider} from "@material-ui/core/styles";
import {ThemeOne} from "./layout/Themes";
import rtl from "jss-rtl";
import {EntityRoute} from "./routes/EntityRoute";
import {Redirect} from "react-router";
import {create} from "jss";
import {HomePage} from "./pages/authorization/HomePage";
import {NotFoundOne} from "./pages/notfound/NotFoundOne";
import {SignUpPage} from "./pages/authorization/SigupPage";
import 'rsuite/dist/styles/rsuite-default.css';


const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
});

export const getRandomInt = (max=100)=> {
    return Math.floor(Math.random() * max);
}

export const not_validate_value = [undefined, null, ""];
export var dir = "ltr";
export var sub_directory = `/${localStorage.getItem("sub_directory") || "notfound"}/`;


export function setSubDirectory(value) {
    sub_directory = `/${value}/`;
}


export var redirect = "dashboard";

export function setRedirect(value) {
    redirect = value;
}

export function timeTo12Hour(time) {
    return moment("1995-06-09 " + time).format("hh:mm A")
}

export function timeTo24Hour(time) {
    return moment("1995-06-09 " + time).format("hh:mm")
}

export function compareTime(a, b) {
    if (moment("1995-06-09 " + a.start_time).isBefore("1995-06-09 " + b.start_time)) {
        return -1;
    }
    if (moment("1995-06-09 " + a.start_time).isAfter("1995-06-09 " + b.start_time)) {
        return 1;
    }
    if (moment("1995-06-09 " + a.end_time).isBefore("1995-06-09 " + b.end_time)) {
        return -1;
    }
    if (moment("1995-06-09 " + a.end_time).isAfter("1995-06-09 " + b.end_time)) {
        return 1;
    }

    return 0;
}


export function compareDate(a, b) {
    if (moment(a).isBefore(b)) {
        return -1;
    }
    if (moment(a).isAfter(b)) {
        return 1;
    }
    if (moment(a).isBefore(b)) {
        return -1;
    }
    if (moment(a).isAfter(b)) {
        return 1;
    }

    return 0;
}

const App = () => {
    const [is_loading, setIsLoading] = useState(true)
    const [language, setLanguage] = useState(Cookies.get('language') || "en")

    const setLanguageSetting = async () => {
        if (language === 'en') {
            dir = 'ltr';
            await setLocalization(lang_en);
        } else {
            dir = 'rtl'
            await setLocalization(lang_ar)
        }
        document.body.dir = dir;
    }

    const changeLanguage = async () => {
        if (language === 'en') {
            await setLanguage('ar')
            await Cookies.set('language', 'ar');
        } else {
            await setLanguage('en')
            await Cookies.set('language', 'en');
        }
    }

    useEffect(() => {
        const checkAuth = async () => {
            await setLanguageSetting()
            let isAuth = await auth.checkAuthenticate();
            if (isAuth.status) {
                setIsLoading(false)
            }
        }

        checkAuth();
    }, [])


    useEffect(() => {
        const action = async () => {
            if (is_loading) {
                await setLanguageSetting();
            } else {
                await setIsLoading(true);
                await setLanguageSetting();
                await setIsLoading(false);
            }
        }

        action();
    }, [language]);


    const AppContent = () => (
        <Switch>
            <Route path="/home" component={HomePage}/>
            <Route path="/signup" component={SignUpPage}/>
            <Route path='/:sub_directory' component={EntityRoute}/>
        </Switch>
    );

    return (
        <>
            {is_loading? (
                <Loading/>
            ) : (
                <LanguageContext.Provider value={{changeLanguage}}>
                        <BrowserRouter>
                            <Switch>
                                <Route path="/notfound" component={NotFoundOne}/>
                                <Route path='/auth' component={AuthCode}/>
                                {/*<Route path="/home" component={HomePage}/>*/}
                                <Route exact path="/" render={() => <Redirect to="/home"/>}/>
                                <ThemeProvider theme={ThemeOne(dir)}>
                                    {(language === 'en') ? <AppContent/> :
                                        (
                                            <StylesProvider jss={jss}>
                                                <AppContent/>
                                            </StylesProvider>
                                        )
                                    }
                                </ThemeProvider>
                            </Switch>
                        </BrowserRouter>
                </LanguageContext.Provider>
            )}

        </>
    );
}

export default App;
