import React, {useEffect, useState} from "react";
import Tab from "@material-ui/core/Tab";

export const CustomTab = props =>{
    const [custom_style, setCustomStyle] = useState({});

    useEffect(()=>{
        if (window.innerWidth < 850){
            setCustomStyle({
                fontSize:'12px',
            });
        }else{
            setCustomStyle({});
        }
    }, [window.innerWidth])

    return(
        <Tab style={custom_style} {...props}/>
    )
}
