import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../assets/scss/Static.scss';
import {CustomModal} from "../../../../components/CustomModal";
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {StaffAutoComplete} from "../../../../components/fields/StaffAutoComplete";
import {FormHeader} from "../../../../components/FormHeader";
import {CreateCashTransfer, GetMyCashBalance} from "../cash_transfer_apis";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../../localization/MainLocalization";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseFinanceCashTransferCollectURL, BaseFinanceCashTransferSenderURL} from "../../../../urls";
import {AlertContext} from "../../../../store/use_context";

export const CashTransferFormModal = props => {
    const [data, setData] = useState({})
    const [helper_text, setHelperText] = useState({})
    const [my_cash, setMyCashBalance] = useState({})
    const [is_loading, setIsLoading] = useState(false)
    const {setAlert} = useContext(AlertContext);

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            let response = await GetMyCashBalance();
            if (response.status) {
                setMyCashBalance(response.data);
            } else {
                await setAlert(response.msg);
                await props.handleModalClose()
            }
            await setIsLoading(false);
        };

        fetchData();

    }, [])

    const onChangeHandle = (name, value) => {
        setData({...data, [name]: value})
    }

    const handleSaveForm = async () => {
        await setIsLoading(true);
        await setHelperText({});
        let url = BaseFinanceCashTransferSenderURL
        let temp_data = {...data}

        if (props.type === "collect"){
            url = BaseFinanceCashTransferCollectURL
            temp_data = {amount:temp_data.amount, 'sender':temp_data.recipient}
        }

        let response = await  httpRequestWithAuth(url,"POST", temp_data)

        if (response.status) {
            await props.handleModalClose()
        } else {
            await setHelperText(response.error_feedback)
        }
        await setIsLoading(false);
    }

    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="transfer_form"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>

                        <Hide hide={props.type === "collect"}>
                            <Grid item xs={12} className="d-inline-flex justify-content-around">
                                <Typography variant="body1" className='p-1 text-center'>
                                    <Typography variant="h6" className='p-1'>
                                        {getLabel("current_balance")}
                                    </Typography>
                                    {my_cash.current_balance || 0}
                                </Typography>

                                <Typography variant="body1" className='p-1 text-center text-danger'>
                                    <Typography variant="h6" className='p-1'>
                                        {getLabel("pending_balance")}
                                    </Typography>
                                    {my_cash.pending_balance || 0}
                                </Typography>

                                <Typography variant="body1" className='p-1 text-center text-success'>
                                    <Typography variant="h6" className='p-1'>
                                        {getLabel("available_balance")}
                                    </Typography>
                                    {my_cash.free_balance || 0}
                                </Typography>

                            </Grid>
                        </Hide>

                        <Grid item xs={12} md={6}>
                            <StaffAutoComplete
                                can_receive_cash={props.type !== "collect"}
                                have_user
                                exclude_self
                                name="recipient"
                                label={props.type === "collect"? 'staff' :'recipient'}
                                onChangeHandle={onChangeHandle}
                                error={helper_text.recipient !== undefined}
                                helper_text={helper_text.recipient}
                                value={data.recipient}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                required={true}
                                error={helper_text.amount !== undefined}
                                helper_text={helper_text.amount}
                                name="amount"
                                type="number"
                                label="amount"
                                value={data.amount}
                                onChangeHandle={onChangeHandle}
                            />
                        </Grid>

                        <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={props.handleModalClose}
                            handleSaveButton={handleSaveForm}
                        />

                    </Hide>

                </Grid>
            </CustomModal>
        </>
    );
};
