import React, {useEffect, useState} from "react";
import timezone from 'moment-timezone/builds/moment-timezone-with-data';
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {getLabel} from "../../localization/MainLocalization";
import {not_validate_value} from "../../App";
import countryList from "react-select-country-list";


let timezonesList = timezone.tz.names();


export const TimeZoneAutoComplete = props => {
    const [options, setOptions] = useState([])
// console.log(timezone.tz.zonesForCountry(countryList.getValue("Kuwait")))
// alert(countryList().getValue('Kuwait'))

    useEffect(() => {
        const filterData = async () => {
            if (!not_validate_value.includes(props.country)) {
                setOptions(timezone.tz.zonesForCountry(countryList().getValue(props.country)))
            } else {
                setOptions(timezonesList)
            }
        }

        filterData();
    }, [props.country])

    return (
        <Autocomplete
            ListboxProps={{className:"list-box-autocomplete"}}
            renderOption={(option) => (
                <span className='w-100 text-start'>{option}</span>
            )}
            disabled={props.disabled || props.viewOnly}
            options={options}
            getOptionLabel={option => option}
            renderInput={params => (
                <TextField {...params}
                           variant="outlined"
                           label={<label> {getLabel("timezone")}<span
                               className="text-danger">{props.required ? "*" : ""}</span></label>}
                           error={props.error}
                           helperText={props.helper_text}
                           fullWidth/>
            )}
            onChange={(event, value) => {
                if (!props.viewOnly) {
                    value = value !== null ? value : "";
                    props.onChangeHandle(props.name, value);
                }
            }}
            value={not_validate_value.includes(props.value) ? null : props.value + ""}
        />
    );
};
