import {
    BaseRentInvoiceURL,
    BaseRentStartDateURL,
    BaseRentURL, BaseRentPaymentURL, BaseRentCancellationURL
} from "../../../urls";
import {instance} from "../../../auth/axios_instance";
import * as axios from "axios";
import * as jsPDF from 'jspdf'


export const ListRents = async (search = null) => {
    let url = BaseRentURL;

    if (search !== null && search !== "") {
        url = url + "?search=" + search;
    }

    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });

    return feedback
};


export const RetiveRents = async (id) => {
    let url = BaseRentURL + id + "/";
    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};


export const CreateRents = async (data) => {
    let url = BaseRentURL;

    const feedback = await instance.post(url, data).then(res => {
        return {status: true, data: res.data, msg: res.statusText}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}

    });
    return feedback
};


export const UpdateRents = async (id, data) => {
    let url = BaseRentURL + id + "/";

    const feedback = await instance.put(url, data).then(res => {
        return {status: true, data: res.data, msg: res.statusText}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}

    });
    return feedback
};


export const UpdateRentsByField = async (id, data) => {
    let url = BaseRentURL + id + "/";

    const feedback = await instance.patch(url, data).then(res => {
        return {status: true, data: res.data, msg: res.statusText}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}

    });
    return feedback
};

export const DeleteRents = async (id) => {
    let url = BaseRentURL + id + "/";

    const feedback = await instance.delete(url).then(res => {
        return {status: true, data: res.data, msg: res.statusText}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};


export const UpdateRentsStatus = async (id, data) => {
    let url = BaseRentURL + id + "/status";

    const feedback = await instance.patch(url,data).then(res => {
        return {status: true, data: res.data, msg: res.statusText}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};

export const CalcStartAndEndDateRents = async (start_date = null, Client = null, Price = null, extra_days = null) => {
    let url = BaseRentStartDateURL;

    url = url + "?client=" + Client;
    url = url + "&price=" + Price;

    if (start_date != null) {
        url = url + "&start_date=" + start_date;
    }

    if (extra_days != null) {
        url = url + "&extra_days=" + extra_days;
    }

    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    })
        .catch(error => {
            return {status: false, msg: error.response.status}
        });
    //
    return feedback
};


export const viewRentInvoice = (id) => {
    let url = BaseRentInvoiceURL + id;
    instance(url, {
        method: 'GET',
        responseType: 'blob',
    })
        .then(response => {
            const file = new Blob(
                [response.data],
                {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        })
        .catch(error => {
            console.log(error);
        });
};


export const viewRentInvoice2 = (id) => {
    let url = BaseRentInvoiceURL + id;
    axios(url, {
        method: 'GET',
        responseType: 'blob',
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('accessToken')}
    })
        .then(response => {
            const file = new Blob(
                [response.data],
                {type: 'application/pdf'});

            const url = URL.createObjectURL(file);

            // window.open(file)

            var doc = new jsPDF();
            var elementHandler = {
                '#ignorePDF': function (element, renderer) {
                    return true;
                }
            };
            // file.text().then(source => {
            //     console.log(source);
            doc.fromHTML(
                response.data,
                15,
                15,
                {
                    'width': 180, 'elementHandlers': elementHandler
                });

            doc.output("dataurlnewwindow");
        })

        // })
        .catch(error => {
            console.log(error);
        });
};


export const ListRentPayment = async (rent = null) => {
    let url = BaseRentPaymentURL;

    if (rent != null) {
        url += '?rent=' + rent
    }

    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};


export const CreateRentsPayment = async (data) => {
    let url = BaseRentPaymentURL;
    const feedback = await instance.post(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const UpdateRentsPayment = async (id, data) => {
    let url = BaseRentPaymentURL + id + "/";

    const feedback = await instance.put(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};


export const DeleteRentsPayment = async (id) => {
    let url = BaseRentPaymentURL + id + "/";

    const feedback = await instance.delete(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};




export const ListRentCancellation = async (rent = null) => {
    let url = BaseRentCancellationURL;

    if (rent != null){
        url += "?rent=" + rent
    }

    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const CreateRentCancellation = async (data) => {
    let url = BaseRentCancellationURL;

    const feedback = await instance.post(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const UpdateRentCancellation = async (id, data) => {
    let url = BaseRentCancellationURL + id + "/";

    const feedback = await instance.put(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};


export const DeleteRentCancellation = async (id) => {
    let url = BaseRentCancellationURL + id + "/";

    const feedback = await instance.delete(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};








