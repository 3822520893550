import React, {useEffect, useState} from "react";
import auth from "../auth/auth";
import {Route, useParams} from "react-router-dom";
import {Layout} from "../layout/Layout";
import {httpRequestWithoutAuth} from "../auth/axios_instance";
import {BaseBackEndURL, BaseEntityGetSubDirectoryURL} from "../urls";
import {Loading} from "../components/Loading";
import {Hide} from "../components/Hide";
import {AlertContext, ConfirmationContext} from "../store/use_context";
import Cookies from "js-cookie";
import {not_validate_value} from "../App";
import {sub_directory as user_sub_directory} from "../App"

export const EntityRoute = props => {
    let {sub_directory} = useParams();
    const [is_loading, setIsLoading] = useState(true)
    const [alert_msg, setAlert] = useState("")
    const [confirm_data, setConfirmData] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            if (user_sub_directory === `/${sub_directory}/`){
            let response = await httpRequestWithoutAuth(BaseBackEndURL + BaseEntityGetSubDirectoryURL, "GET", null, {sub_directory})
            if (response.status) {
                if (auth.isAuthenticated() && sub_directory !== response.data.sub_directory) {
                    await auth.revoke_token()
                     window.location.href = BaseBackEndURL + response.data.sub_directory + "/login"
                } else if (!auth.isAuthenticated()){
                     window.location.href = BaseBackEndURL + response.data.sub_directory + "/login"
                } else{
                    await localStorage.setItem('logo', response.data.display_logo);
                    await localStorage.setItem('company_name', response.data.company_name);
                    await localStorage.setItem('business_name', response.data.business_name);
                    await localStorage.setItem('currency_symbol', response.data.currency_symbol);
                    await setIsLoading(false)
                    Cookies.set('sub_directory', response.data.sub_directory);
                }
            } else {
                Cookies.remove('sub_directory')
                auth.revoke_token()
                 window.location.href = BaseBackEndURL + sub_directory + "/login"
            }
            }else{
                Cookies.remove('sub_directory')
                auth.revoke_token()
                window.location.href = BaseBackEndURL + sub_directory + "/login"
            }

        }

        fetchData()
    }, [])

    return (
        <>
            <Hide hide={!is_loading}>
                <Loading/>
            </Hide>

            <Hide hide={is_loading}>
                <AlertContext.Provider value={{alert_msg, setAlert}}>
                    <ConfirmationContext.Provider value={{confirm_data, setConfirmData}}>
                        <Route path="/:sub_directory" component={Layout}/>
                    </ConfirmationContext.Provider>
                </AlertContext.Provider>
            </Hide>
        </>
    )
}
