import React, {useContext} from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import {dir} from "../../App";
import {SideMenuToggleContext} from "../../store/use_context";
import {ListItems} from "./ListItems";

export const SideMenu = props => {
    const {side_menu_toggle, setSideMenuToggle} =useContext(SideMenuToggleContext);

    return (
        <>
            <Hidden smDown>
                {/*<Grid item xs={4} sm={4} md={3} lg={2} >*/}
                <div className={(dir === 'ltr'?"float-left":"float-right") + " sub-root side-menu"}>
                        <List>
                            <ListItems/>
                        </List>
                </div>

                {/*</Grid>*/}
            </Hidden>

            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    open={side_menu_toggle}
                    anchor="left"
                    className="float-left float-right"
                    onBackdropClick={()=>setSideMenuToggle(!side_menu_toggle)}
                >
                    <List
                          style={{width:'240px'}}
                          className='overflow-x-hidden sub-root side-menu'
                    >
                    <ListItems is_drawer/>
                    </List>
                </Drawer>
            </Hidden>
        </>
    );
}
