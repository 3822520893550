import React, {useContext, useEffect, useRef, useState} from 'react';
import MaterialTable from 'material-table';
import Permissions from "../../../../../auth/permissions";
import {BaseGroupAthletesURL} from "../../../../../urls";
import Paper from "@material-ui/core/Paper";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../../../components/Material_conf";
import {getLabel} from "../../../../../localization/MainLocalization";
import {Hide} from "../../../../../components/Hide";
import {httpRequestWithAuth} from "../../../../../auth/axios_instance";
import {permissions_data} from "../../../../../auth/permission_data";
import {sub_directory} from "../../../../../App";
import {AlertContext} from "../../../../../store/use_context";
import {AthleteGroupFormModal} from "./components/AthleteGroupFormModal";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";


export const AthletesGroups = props => {
    const state = props.history.location.state || {};

    useEffect(() => {
        if (!state.group) {
            props.history.push(`${sub_directory}seasons`);
        }
    }, []);

    const {setAlert} = useContext(AlertContext);

    const [material_table_localization_value] = useState(material_table_localization());

    const [columns] = useState([
        {title: getLabel("name"), field: 'athlete_full_name', cellStyle:{width:"50%"}},
        {render:rowData=>{return(
                <div className="d-inline-flex">
                    {Permissions.checkAllPermissions([permissions_data.athlete.view]) && (
                        <Button className="mr-1 ml-1" size="small" variant="outlined"
                                onClick={()=> props.history.push(
                                    {
                                        pathname: sub_directory + "athletes/edit",
                                        state: {
                                            id: rowData.athlete,
                                            tag: rowData.athlete_short_name
                                        }
                                    }
                                )}>
                            {getLabel("view_athlete_profile")}
                        </Button>)}

                </div>
            )}}
    ]);

    const [form_open, setFormOpen] = useState(false);
    const [new_athlete, setNewAthlete] = useState({});
    const [is_loading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const listData = async () => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(BaseGroupAthletesURL, "GET", null, {group: state.group})
        if (response.status){
            await setData(response.data);
        }
        await setIsLoading(false);
    };

    useEffect(()=>{
        listData()
    }, [])

    const handleModalClose = async () => {
        await listData();
        await setFormOpen(false)
        await setNewAthlete({})
    };


    const handleModalOpen = async data => {
        if (data !== "new") {
            await setNewAthlete(data)
        }
        await setFormOpen(true)
    };



    return (
        <>
            <Hide hide={!form_open}>
                <AthleteGroupFormModal
                    open={form_open}
                    handleModalClose={handleModalClose}
                    default_data={{...new_athlete, group: state.group}}
                />
            </Hide>


            <Paper>
                <MaterialTable
                    columns={columns}
                    data={data}
                    isLoading={is_loading}
                    editable={
                        Permissions.checkPermission(permissions_data.athlete_group.delete) && {
                            onRowDelete: async rowData => {
                                let response = await httpRequestWithAuth(`${BaseGroupAthletesURL}${rowData.id}/`, "DELETE");
                                if (response.status){
                                    await listData();
                                }else{
                                    setAlert(response.error_feedback.non_field_errors)
                                }
                            }
                        }}


                    actions={[
                        {
                            icon: 'edit',
                            hidden: !Permissions.checkAllPermissions([permissions_data.athlete_group.change]),
                            tooltip: getLabel("edit_athlete_group"),
                            onClick: (event, rowData) => handleModalOpen(rowData)
                        },
                        {
                            icon: ()=> (<Button variant="contained" color="primary" size="small"
                                                startIcon={<AddIcon/>}>{getLabel('add_athlete')}</Button>),
                            hidden: !Permissions.checkAllPermissions([permissions_data.athlete_group.add]),
                            onClick: () => handleModalOpen('new'),
                            isFreeAction: true
                        },

                    ]}

                    options={{
                        searchFieldAlignment: 'left',
                        showTitle: false,
                        actionsColumnIndex: -1,
                        paging: false
                    }}

                    {...material_table_style}
                    {...material_table_localization_value}
                />
            </Paper>
        </>
    );
}

