import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {Hide} from "./Hide";
import DefaultPhoto from "../assets/images/profile_photo.jpeg";
import {not_validate_value} from "../App";
import {getLabel} from "../localization/MainLocalization";
import {Avatar} from "@material-ui/core";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

const style = {
    img: {
        width: "275px",
        height: "275px",
        border: "5px solid black",
        borderRadius: "155px"
    },
    photo_width: {
        width: 275
    },
    photo_height: {
        height: 275
    }
}

export const ProfilePhoto = props => {
    const [change_photo, setChangePhoto] = useState(false);
    const [crop, setCrop] = useState(null);
    const [src, setSrc] = useState(null);
    const [new_photo, setNewPhoto] = useState(null);
    const [imageRef, setImageRef] = useState(null);

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener(
                'load',
                () => setSrc(reader.result),
                false
            )
            reader.readAsDataURL(e.target.files[0])
        }
        setChangePhoto(true);

    }

    const onImageLoaded = image => {
        setImageRef(image)
    }

    const onCropChange = crop => {
        setCrop(crop);
    }

    const onRemovePhoto = () => {
        if (!props.viewOnly) {
            props.onChangeHandle(props.name, null);
        }
    };

    const onCancel = () => {
        setSrc(null);
        setCrop(null);
        setNewPhoto(null);
        setChangePhoto(false);
    };

    const onSave = () => {
        props.onChangeHandle(props.name, new_photo);
        setSrc(null);
        setCrop(null);
        setNewPhoto(null);
        setChangePhoto(false);
    };



    const onCropComplete = crop => {
        if (crop.width && crop.height) {
            const croppedImageUrl = getCroppedImg(imageRef, crop)
        }
    }

    const getCroppedImg = (image, crop) =>{
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })
    }

    const dataURLtoFile =(dataurl, filename) =>{

        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        let croppedImage = new File([u8arr], filename, {type:mime});
        setNewPhoto(croppedImage);

    }

    return (
        <>
            <Hide hide={!change_photo}>
                <ReactCrop
                    src={src}
                    crop={crop}
                    onChange={onCropChange}
                    onComplete={onCropComplete}
                    onImageLoaded={onImageLoaded}
                />

                <div className="text-center">
                    <div className="d-inline-flex" style={style.photo_width}>
                        <Button className="m-1 text-danger p-0" onClick={onCancel}>
                            {getLabel("cancel")}
                        </Button>

                        <div className="mr-auto"/>

                        <Button className="m-1 p-0" onClick={onSave}>
                                {getLabel("save")}
                        </Button>

                        <input
                            type='file'
                            id="file-upload"
                            onChange={onSelectFile}
                        />
                    </div>
                </div>
            </Hide>

            <Hide hide={change_photo}>
                <Avatar src={props.value || DefaultPhoto} style={style.img} className="m-auto"/>
                <Hide hide={props.viewOnly}>
                    <div className="text-center">
                        <div className="d-inline-flex" style={style.photo_width}>
                            <Button className="m-1 text-danger p-0" onClick={onRemovePhoto}
                                    disabled={not_validate_value.includes(props.value)}>
                                {getLabel("remove_photo")}
                            </Button>

                            <div className="mr-auto"/>

                            <Button className="m-1 p-0">
                                <label htmlFor="file-upload" className="p-0 m-0">
                                    {getLabel("change_photo")}
                                </label>
                            </Button>

                            <input
                                type='file'
                                id="file-upload"
                                onChange={onSelectFile}
                            />
                        </div>
                    </div>
                </Hide>
            </Hide>
        </>
    )

}

