import React, {useContext, useState} from 'react';
import "../assets/scss/Static.scss";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {ConfirmationContext} from "../store/use_context";
import {CustomModal} from "./CustomModal";
import {not_validate_value} from "../App";
import {FormHeader} from "./FormHeader";
import {getLabel} from "../localization/MainLocalization";
import {Hide} from "./Hide";
import {Loading} from "./Loading"; // web.cjs is required for IE 11 support

export const ConfirmationModal = props => {
    const {confirm_data, setConfirmData} = useContext(ConfirmationContext);
    const [is_loading, setIsLoading] = useState(false);

    const handleClose = async () => {
        await setConfirmData({});
    };

    const handleNoBtn = async () => {
        await setIsLoading(true);
        let no_action = confirm_data.noBtnAction;
        await setConfirmData({});

        if (no_action) {
            await no_action();
        }
        await setIsLoading(false);
    };

    const handleYesBtn = async () => {
        await setIsLoading(true);
        let action = confirm_data.action;
        await setConfirmData({});
        await action();
        await setIsLoading(false);
    };




    return (
        <CustomModal
            open={!not_validate_value.includes(confirm_data.msg)}
        >
            <Grid container spacing={2}>
                <FormHeader
                    title="confirmation"
                    handleClose={handleClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>

                    <Grid item xs={12}>
                        <Typography variant="h6" className='p-1 pre-line'>
                            {confirm_data.msg}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className='text-end'>
                        <Button
                            onClick={handleNoBtn}
                            variant="contained"
                            color='inherit'
                            size="medium"
                            className="m-1"
                        >
                            {getLabel("no")}
                        </Button>

                        <Button
                            variant="contained"
                            color='primary'
                            size="medium"
                            className="m-1"
                            onClick={handleYesBtn}
                        >
                            {getLabel("yes")}
                        </Button>
                    </Grid>
                </Hide>

            </Grid>
        </CustomModal>
    );
}
