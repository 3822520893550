import {BaseGroupURL, BaseUserURL} from "../urls";
import {instance} from "./axios_instance";


export const ListPermissionGroup = async () => {
    let url = BaseGroupURL;

    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });

    return feedback
};


export const CreateUser = async (data) => {
    let url = BaseUserURL;

    const feedback = await instance.post(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });

    return feedback
};


export const UpdateUser = async (id, data) => {
    let url = BaseUserURL + id + "/";

    const feedback = await instance.patch(url, data,).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });

    return feedback
};



export const DeleteUser = async id => {
    let url = BaseUserURL + id + "/";

    const feedback = await instance.delete(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });

    return feedback
};


