import React from "react";
import Tabs from "@material-ui/core/Tabs";


export const CustomTabs = props =>{
    return(
        <Tabs
            value={props.currentTab}
            onChange={props.handleTabsChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
        >
            {props.children}
        </Tabs>
    )
}