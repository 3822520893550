
export let lang_ar = {
        "id": "الرقم التعريفي",
        "username": "اسم المستخدم",
        "password": "كلمة السر",
        "name": "الأسم",
        "first_name": "الأسم الاول",
        "second_name": "الأسم الثاني",
        "middle_name": "الاسم الأوسط",
        "last_name": "الأسم الاخير",
        "start_date": "تاريخ البدء",
        "end_date": "تاريخ الانتهاء",
        "start_time": "وقت البدء",
        "end_time": "وقت الإنتهاء",
        "branches": "الأفرع",
        "service": "الخدمة",
        "services": "الخدمات",
        "packages": "الصفقات",
        "package": "صفقة",
        "price_list":"قائمة الاسعار",
        "default_price_list": "قائمة الاسعار الافتراضية",
        "prices": "الاسعار",
        "price": "السعر",
        "total": "المجموع",
        "gender": "الجنس",
        "birthday": "تاريخ الميلاد",
        "birth_date": "تاريخ الميلاد",
        "branch": "الفرع",
        "profile": "الملف الشخصي",
        "logout": "تسجيل الخروج",
        "signin": "تسجيل الدخول",
        "signup": "تسجيل الإشتراك",
        "search": "بحث",
        "clients": "العملاء",
        "client": "العميل",
        "memberships": "الاعضاء المشتركين",
        "membership": "عضوية الإشتراك",
        "employee": "الموطف",
        "employees": "الموطفين",
        "actions": "الأجراءات",
        "action": "الأجراء",
        "mobile": "رقم الهاتف",
        "telephone": "هاتف",
        "social_id": "الهوية الاجتماعية",
        "nationality": "الجنسية",
        "add": "اضافة",
        "remove": "إزالة",
        "add_all": "اضافة الكل",
        "remove_all": "إزالة الكل",
        "delete": "حذف",
        "save": "حفظ",
        "cancel": "إلغاء",
        "close": "إغلاق",
        "paid": "المدفوع",
        "balance": "المتبقي",
        "male": "ذكر",
        "female": "انثى",
        "none": "لا قيمة",
        "change_lang": "التغير اللغة إلى الإنجليزية",
        "yes": "نعم",
        "no": "لا",
        "account": "الحساب",
        "status": "الحاله",
        "notes": "الملاحظات",
        "duration": "المده",
        "duration_days": "المده (الايام)",
        "date_created": "تاريخ الإنشاء",
        "date_modified": "تاريخ التعديل",
        "approve": "الموافق",
        "reject": "الرفض",
        "sessions": "الحصص",
        "address": "العنوان",
        "coordinates": "الإحداثيات",
        "timezone": "المنطقة زمنية",
        "tax": "الضريبة",
        "discount": "الخصم",
        "amount": "المبلغ",
        "passport_number": "دقم جواز السفر",
        "email": "البريد الإلكتروني",
        "is_active": "فعال",
        "Disactive": "غير فعال",
        "country": "دولة",
        "currency_symbol": "رمز العملة",
        "emergency_contact_name": "اسم جهة اتصال الطوارئ",
        "emergency_contact_telephone": "هاتف الاتصال في حالات الطوارئ",
        "request_no": "رقم الطلب",
        "feedback_by": "الرد بواسظة",
        "feedback_date": "تاريخ الرد",
        "other": "أخرى",
        "cash": "نقدي",
        "debit": "بطاقة ائتمان",
        "credit": "بطاقة ائتمان ٢",
        "pay": "دفع",
        "refund": "إعادة مال",
        "back": "الرجوع",
        "for": "لأجل",
        "males": "الرجال",
        "females": "النساء",
        "offers": "العروض",
        "type": "النوع",
        "description": "الوصف",
        "equipment": "المعدات",
        "qty": "الكمية",
        "increase": "زيادة",
        "decrease": "تخفيض",
        "received": "تم الأستلام",
        "delivered": "تم التوصيل",
        "total_qty": "إجمالي الكمية",
        "available_qty": "الكمية المتوفرة",
        "rented_qty": "الكمية المؤجرة",
        "area":"منطقة",
        "city":"مدينة",
        "locations": "المواقع",
        "location": "الموقع",
        "address_line_one" : "العنوان الأول",
        "address_line_two" : "العنوان الثاني",
        "address_line_three" : "العنوان الثالث",
        "map_location": "خريطة الموقع",
        "staff": "الموطفين",
        "change_photo": "تغير الصورة",
        "role": "المنصب",
        "permission_group": "مجموعة الصلاحية",
        "change_password": "تغير كلمة السر",
        "assigned_to": "مخصص إلى",
        "all_locations": "جميع المواقع",
        "details": "التفاصيل",
        "information": "معلومات",
        "setting": "الإعداد",
        "available_at": "متوفر عند",
        "show": "مشاهده",
        "cancellation": "الإلغاء",
        "freezes": "الإيقافات",
        "payments": "المدفوعات",
        "cancellation_date": "تاريخ الإلغاء",
        "title": "العنوان",
        "date": "التايخ",
        "expenses": "النفقات",
        "expense": "النفقة",
        "ex_number": "رقم النفقة",
        "ex_date": "تاريخ النفقة",
        "add_expense": "إضافة نفقة",
        "edit_expense": "تحرير النفقة",
        "view_expense": "عرض النفقة",
        "expense_category": "فئة النفقة",
        "add_expense_category": "أضافة فئة النفقات",
        "edit_expense_category": "تحرير فئة النفقات",
        "open": "فتح",
        "payment_information": "معلومات الدفع",
        "expense_information": "معلومات النفقة",
        "expense_attachment": "مرفق النفقة",
        "attachment": "المرفق",
        "add_attachment": "إضافة مرفق",
        "edit_attachment": "تحرير المرفق",
        "cost": "التكلفة",
        "remove_file": "إزالة الملف",
        "paid_by": "دفع عن طريق",
        "add_payment_method": "إضافة طريقة دفع",
        "edit_payment_method": "تحرير طريقة دفع",
        "commission_type" : "نوع العمولة",
        "commission" : "العمولة",
        "invoices" : "الفاوتير",
        "finance" : "المالية",
        "bank_transfers" : "التحويلات المصرفية",
        "add_bank_transfer" : "إضافة تحويل مصرفي",
        "edit_bank_transfer" : "تحرير التحويل المصرفي",
        "view_bank_transfer" : "عرض التحويل المصرفي",
        "bank_account" : "الحساب المصرفي",
        "add_bank_account" : "إضافة حساب مصرفي",
        "edit_bank_account" : "تحرير حساب مصرفي",
        "view_bank_account" : "عرض الحساب المصرفي",
        "bank_transactions" : "معاملات بنكية",
        "current_balance" : "الرصيد الحالي",
        "sender" : "المرسل",
        "total_price": "السعر الكلي",
        "access_permissions": "أذونات الوصول",
        "reference": "المرجع",
        "payment_date": "تاريخ الدفع",
        "payment_type": "نوع الدفع",
        "payment_method": "طريقة الدفع",
        "add_payment": "إضافة دفعة جديدة",
        "add_refund": "إعادة مال",
        "number": "الرقم",
        "rents": "الإيجارات",
        "add_rent": "إضافة إيجار جديد",
        "edit_rent": "تحرير الإيجار",
        "activate_rent" : "تنشيط الإيجار",
        "cancel_rent" : "إلغاء الإيجار",
        "transfer_number" : "رقم التحويل",
        "recipient" : "المستلم",
        "cash_transfer" : "تحويل نقدي",
        "new_transfer" : "تحويل جديد",
        "add_transfer" : "إضافة تحويل",
        "sent_transfer" : "النقل المرسل",
        "received_transfer" : "التحويل المستلم",
        "transfer_form" : "نموذج التحويل",
        "cancel_transfer" : "إلغاء النقل",
        "confirm" : "تأكيد",
        "cash_log" : "سجل النقدية",
        "sender_type" : "نوع المرسل",
        "recipient_type" : "نوع المستلم",
        "sender_current_balance" : "الرصيد الحالي للمرسل",
        "recipient_current_balance" : "الرصيد الحالي للمستلم",
        "pending_balance" : "الرصيد المعلق",
        "free_balance" : "رصيد مجاني",
        "available_balance" : "الرصيد المتاح",
        "target_staff" : "الموظفين المستهدفين",
        "add_cash_deposit" : "أضف وديعة نقدية",
        "add_funds" : "إضافة الأموال",
        "add_withdraw" : "إضافة سحب",
        "received_as" : "تم الاستلام كـ",
        "method" : "طريقة",
        "total_amount": "المبلغ الإجمالي",
        "from_cash_log": "من سجل النقدية",
        "bank_transfer_information": "معلومات التحويل المصرفي",
        "cheque": "الشيك",
        "bank_transfer" : "تحويل مصرفي",
        "paid_from" : "مدفوع من",
        "personal_money" : "المال الشخصي",
        "reimbursements" : "التسديدات",
        "remaining" : "المتبقي",
        "view_reimbursement" : "عرض السداد",
        "attachments" : "المرفقات",
        "add_class" : "إضافة فصل",
        "edit_class" : "تحرير الفصل",
        "classes" : "الحصص",
        "schedule" : "جدول",
        "limit" : "الحد",
        "duration_minutes" : "المدة (دقائق)",
        "saturday" : "السبت",
        "sunday" : "الأحد",
        "monday" : "الإثنين",
        "tuesday" : "الثلاثاء",
        "wednesday" : "الأربعاء",
        "thursday" : "الخميس",
        "friday" : "الجمعة",
        "from_date" : "من التاريخ",
        "to_date" : "حتى تاريخ",
        "add_time" : "إضافة وقت",
        "days" : "أيام",
        "vip_capacity" : "سعة كبار الشخصيات",
        "capacity" : "سعة",
        "room" : "غرفة",
        "edit_class_session" : "تحرير جلسة الفصل",
        "add_class_session" : "إضافة جلسة الفصل",
        "class_sessions" : "جلسات الفصل",
        "is_locked" : "مغلق",
        "registered_number" : "الرقم المسجل",
        "rooms": "غرف",
        "add_room" : "أضف غرفة",
        "edit_room" : "تحرير غرفة",
        "room_form" : "نموذج الغرفة",
        "edit_attendee" : "تحرير الحضور",
        "add_attendee" : "إضافة حاضر",
        "attendee_form" : "نموذج الحضور",
        "attendee": "الحضور",
        "attendees": "الحاضرين",
        "is_present": "حاضر",
        "is_cancelled": "تم إلغاء",
        "added_by": "أضيف بواسطة",
        "group_by_date": "تجميع حسب التاريخ",
        "class_session" : "جلسة الفصل",
        "add_class_sessions" : "إضافة جلسة الفصل",
        "edit_class_sessions" : "تحرير جلسة الفصل",
        "add_screen": "إضافة شاشة",
        "edit_screen": "تحرير الشاشة",
        "screen": "شاشة",
        "screens": "شاشات",
        "workout_screens": "شاشات التمارين",
        "workout": "تمرين",
        "add_workout": "أضف تمرين",
        "edit_workout": "تحرير تمرين",
        "workout_form": "نموذج تمرين",
        "content": "المحتوى",
        "header": "العنوان",
        "display_screen": "شاشة عرض",
        "next": "التالى",
        "duplicate_workout": "كرر التمرين",
        "workouts": "التمارين",
        "whole_day": "طوال اليوم",
        "view_for": "عرض ل",
        "add_section": "Add Section",
        "edit_section": "Edit Section",
        "workout_section_form": "Workout Section Form",
        "all_class_sessions": "All Class Sessions",
        "add_location": "إضافة موقع جديد",
        "edit_location": "تعديل بيانات موقع",
        "preferred_training_time": "وقت التدريب المفضل",
        "invoice_date": "تاريخ الفاتورة",
        "dashboard": "لوحة الإدارة",
        "view": "عرض",
        "confirm_password": "تأكيد كلمة المرور",
        "company_name": "اسم الشركة",
        "business_name": "الاسم التجاري",
        "personal_info": "المعلومات الشخصية",
        "entity_info": "معلومات الكيان.",
        "user_info": "معلومات الحساب",
        "confirm_&_submit": "تأكيد وإرسال",
        "already_have_an_account?_sign_in": "هل لديك حساب؟ تسجيل الدخول",
        "submit": "إرسال",
        "personal_information": "المعلومات الشخصية",
        "entity_information": "معلومات الكيان",
        "user_information": "معلومات الحساب",
        "membership_info": "بيانات المشترك",
        "freezed": "متوقف",
        "transfer": "تحويل",
        "upgrade_duration": "تطوير المدة",
        "alert": "تنبيه",
        "ok": "موافق",
        "change_invoice_date": "تغيير تاريخ الفاتورة",
        "change_start_date": "Change start date",
        "sub_directory": "الدليل الفرعي",
        "view_transfer_from": "عرض التحويل من",
        "view_transfer_to": "عرض نقل إلى",
        "create": "إنشاء",
        "show_first_name_with_spaces": "إظهار الاسم الأول بمسافات",
        "add_freeze": "إضافة ايقاف جديد",
        "edit_freeze": "تعديل بيانات الإيقاف",
        "location_freeze_form": "استمارة تجميد الموقع",
        "memberships_freezed_number": "عدد الأعضاء المجمدة",
        "membership(s)": "عضويات",
        "membership_invoice_prefix": "بادئة فاتورة العضوية",
        "is_editable": "قابل للتحرير",
        "reason": "السبب",
        "attended_sessions": "الجلسات التي حضرها",
        "session(s)": "جلسات",
        "invoice_number": "رقم الفاتورة",
        "freeze_days": "أيام الإيقاف",
        "expense_invoice_prefix": "بادئة فاتورة المصاريف",
        "client(s)": "عملاء",
        "attendance": "الحضور",
        "reports": "التقارير",
        "duration_type": "نوع المدة",
        "yearly": "Yearly",
        "monthly": "Monthly",
        "daily": "Daily",
        "change_extra_days_and_extra_sessions": "تغيير الأيام الإضافية والجلسات الإضافية",
        "extra_days": "أيام إضافية",
        "extra_sessions": "حصص إضافية",
        "change_preferred_time": "تغيير الوقت المفضل",
        "change_notes": "تغيير الملاحظات",
        "all_day": "طوال اليوم",
        "apply": "تنفيذ",
        "delete_all": "حذف الكل",
        "applied": "تم التنفيذ",
        "cant_apply_before_end_date": "لا يمكن التنفيذ قبل تاريخ انتهاء",
        "memberships_freezes": "العضويات المجمدة",
        "confirm_apply": "تأكيد التنفيذ",
        "read_notes_and_confirm_to_apply": "Read notes and confirm to apply",
        "via": "بواسطة",
        "staff_balance": "رصيد الموظف",
        "new_memberships": "عضويات جديدة",
        "renewed_memberships": "عضوية متجددة",
        "all_services": "جميع الخدمات",
        "all": "الكل",
        "collect_cash": "جمع النقود",
        "recurring_classes": "الفصول المتكررة",
        "list": "قائمة",
        "training": "تدريب",
        "financial": "الأمور المالية",
        "general": "عام",
        "generate_classes": "توليد الحصص",
        "add_recurring_class": "إضافة حصص متكررة",
        "edit_recurring_class": "تعديل حصص متكررة",
        "edit_classes": "تحرير الحصص",
        "view_classes": "عرض الحصص",
        "edit_all": "تعديل الكل",
        "equal": "متساوي",
        "greater_than": "أكثر من",
        "less_than": "أقل من",
        "operation": "العملية",
        "edit_actions": "عمليات التعديل",
        "change_location_,_room_and_service": "تغيير الموقع والغرفة والخدمة",
        "change_staff": "تغيير الموظف",
        "change_time_duration": "تغيير المدة الزمنية",
        "change_date": "تاريخ التغيير",
        "day": "يوم",
        "timing": "التوقيت",
        "time_form": "نموذج وقت",
        "filter": "مرشح",
        "add_client": "إضافة عميل جديد",
        "add_membership": "إضافة مشترك جديد",
        "screen_code": "رمز الشاشه",
        "apply_on_memberships_start_date": "تنطبق على عضوية تاريخ بدء",
        "can_edit_by_membership": "يمكن تعديل من قبل الأعضاء",
        "before": "قبل",
        "after": "بعد",
        "on": "في",
        "apply_start_date": "تطبيق تاريخ البدء",
        "apply_condition": "تطبيق الشرط",
        "out_of_balance": "خارج الحسبه",
        "activate_on_location": "تفعيل في الموقع",
        "deactivate_from_location": "تعطيل من موقع",
        "shifted_memberships": "العضويات المنقولة",
        "confirmation": "التأكيد",
        "banners": "لافتات",
        "edit_banner": "تعديل اللافتات",
        "add_banner": "إضافة لافتة",
        "mobile_app": "تطبيق الهاتف",
        "image": "صورة",
        "url": "URL",
        "link": "ربط",
        "move_up": "تحرك لأعلى",
        "move_down": "تحرك لأسفل",
        "vip_client_can_book_classes_within_days": "يمكن للعميل المهم جدًا حجز الدروس في غضون أيام",
        "normal_client_can_book_classes_within_days": "يمكن للعميل العادي حجز الفصول بداخله",
        "vip": "شخص مهم جدا",
        "activation_code": "رمز التفعيل",
        "unregister_from_classes": "إلغاء التسجيل من الحصص",
        "cancelled_by": "ألغى بواسطة",
        "today": "اليوم",
        "tomorrow": "غدا",
        "client_form": "نموذج العميل",
        "view_class": "عرص الحصه",
        "cancel_edit": "إلغاء التحرير",
        "edit": "تحرير",
        "no_logo": "بدون شعار",
        "no_photo": "ليس هناك صورة",
        "invoice_id": "معرف الفاتورة",
        "save_&_create_membership": "حفظ وإنشاء العضوية",
        "shared_locations": "المواقع المشتركة",
        "all_prices": "جميع الأسعار",
        "select_prices": "تحديد الأسعار",
        "save_done": "تم الحفظ",
        "select_locations": "تحديد المواقع",
        "for_locations": "للمواقع",
        "for_prices": "للأسعار",
        "civil_id": "الرقم المدني",
        "workout_sections": "أقسام التمرين",
        "view_workout_sections": "عرض أقسام التمرين",
        "training_area": "منطقة التدريب",
        "training_area_form": "نموذج منطقة التدريب",
        "add_training_area": "أضف منطقة التدريب",
        "edit_training_area": "تعديل منطقة التدريب",
        "training_areas": "مناطق التدريب",
        "change_location_,_training_area_and_service": "تغيير الموقع ومنطقة التدريب والخدمة",
        "access_privileges": "امتيازات الوصول",
        "claims": "المطالبات",
        "my_claims": "مطالباتي",
        "new_claim": "مطالبة جديدة",
        "claim_type": "نوع المطالبة",
        "request_type": "نوع الطلب",
        "is_new_purchase": "شراء جديد",
        "is_exists_purchase": "شراء موجود",
        "cash_log_amount": "مبلغ السجل النقدي",
        "personal_money_amount": "مبلغ المال الشخصي",
        "add_claim": "إضافة مطالبة",
        "claim_id": "معرف المطالبة",
        "staff_claims": "مطالبات الموظفين",
        "pending": "قيد الانتظار",
        "confirmed": "تم تأكيد",
        "rejected": "مرفوضه",
        "settled": "تم التسوية",
        "cancelled": "تم الالغاء",
        "personal_amount": "المبلغ الشخصي",
        "confirm_claim": "تأكيد المطالبة",
        "link_with_exists_expense": "ربط مع مصروف موجود",
        "create_new_expense": "إنشاء مصروف جديد",
        "paid_for": "مدفوع لأجل",
        "save_&_submit": "حفظ وتقديم",
        "submitted": "المقدمة",
        "approved": "موافق عليه",
        "partially_paid": "مدفوعة جزئيا",
        "returned": "عاد",
        "approve_claim": "الموافقة على المطالبة",
        "reject_or_return": "رفض أو إرجاع",
        "return": "إرجاع",
        "reject_or_return_claim": "رفض أو إرجاع المطالبة",
        "amount_form_personal_money": "المبلغ من المال الشخصي",
        "amount_form_cash_box": "المبلغ من صندوق النقد",
        "total_invoice_amount": "المبلغ الإجمالي للفاتورة",
        "add_vendor": "إضافة بائع",
        "vendor": "بائع",
        "vendors": "الباعة",
        "edit_contact": "تعديل جهة الاتصال",
        "add_contact": "إضافة جهة اتصال",
        "contacts": "جهات الاتصال",
        "contact_form": "نموذج جهة اتصال",
        "phone": "هاتف",
        "cash_box_amount": "مبلغ الصندوق النقدي",
        "view_file": "عرض الملف",
        "file": "الملف",
        "settlement_to": "تسوية ل",
        "cash_box": "الصندوق النقدي",
        "payment": "دفع",
        "change_discount": "تغيير الخصم",
        "unlimited_access": "وصول غير محدود",
        "one_day_access": "الوصول ليوم واحد",
        "limited_access": "وصول محدود",
        "created_by": "إنشاء بواسطة",
        "modified_by": "تم التعديل بواسطة",
        "pending_transfer": "التحويل معلق",
        "coach": "مدرب",
        "my_cash_box": "الصندوق النقدي الخاض بي",
        "cash_box_of_the_employee_who_create_the_payment": "الصندوق النقدي للموظف الذي انشاء الدفعة",
        "select_cash_box_form": "نموذج تحدديد صندوق النقد",
        "please_select_a_cash_box_to_deduct_the_amount_from,_this_is_required_to_delete_cash_payment": "الرجاء تحديد صندوق نقدي لخصم المبلغ منه ، وهذا مطلوب لحذف الدفع النقدي",
        "please_select_a_cash_box_to_refund_the_amount_to,_this_is_required_to_delete_cash_refund_payment": "الرجاء تحديد صندوق نقدي لإعادة المبلغ إليه ، وهذا مطلوب لحذف مدفوعات الاسترداد النقدي",
        "original_price": "السعر الاصلي",
        "Cash_box_of_employee_who_created": "الصندوق النقدي للموظف الذي أنشأه",
        "classes_register_time": "وقت تسجل الفصول",
        "enable_classes_registration_on_specific_time_one_day_before": "تمكين تسجيل الفصول في وقت محدد قبلها بيوم",
        "change_logs": "سجلات التغيير",
        "field_name": "اسم الحقل",
        "old_value": "القيمة القديمة",
        "new_value": "قيمة جديدة",
        "change": "التغيير",
        "from": "من",
        "to": "إلى",
        "by": "بواسطة",
        "create_account": "إنشاء حساب",
        "need_to_create_account_for_staff_first": "تحتاج إلى إنشاء حساب للموظف أولا",
        "absent": "غائب",
        "present": "حاضر",
        "register": "تسجيل",
        "unregister": "إلغاء تسجيل",
        "invoice": "فاتورة",
        "change_gender": "تغيير الجنس",
        "clear_filter": "مسح الفلتر",
        "change_capacity": "تغيير السعة",
        "membership_changed_freeze": "عضوية غيرت التجميد",
        "activate_memberships": "تفعيل العضويات",
        "confirm_generate_classes": "تأكيد إنشاء الحصص التدريبية",
        "classes_name": "اسم الحصص",
        "available_amount": "المبلغ المتاح",
        "pending_amount": "المبلغ المعلق",
        "athletes": "الرياضيين",
        "athlete": "رياضي",
        "add_athlete": "اضافة رياضي",
        "edit_athlete": "تحرير الرياضي",
        "click_or_drop_attachment_here": "انقر أو أفلت المرفق هنا",
        "click_or_drop_photo_here": "انقر أو أفلت الصورة هنا",
        "file_uploaded": "ملف مرفوع",
        "remove_photo": "إزالة الصورة",
        "active": "نشط",
        "inactive": "غير نشط",
        "view_categories": "عرض الفئات",
        "add_season": "أضف الموسم",
        "edit_season": "تحرير الموسم",
        "season_form": "تنسيق الموسم",
        "seasons": "مواسم",
        "categories": "فئات",
        "groups": "مجموعات",
        "view_groups": "عرض المجموعات",
        "category_form": "شكل الفئة",
        "add_category": "إضافة فئة",
        "edit_category": "نموذج الفئة",
        "add_group": "أضافة مجموعة",
        "edit_group": "تحرير المجموعة",
        "group_form": " نموذج مجموعة",
        "view_athletes": "مشاهدة الرياضيين",
        "athlete_form": "نموذج رياضي",
        "view_athlete_profile": "عرض الملف الشخصي الرياضي",
        "class_for": "فئة لـ",
        "clients_attendance": "حضور العملاء",
        "athletes_attendance": "حضور الرياضيين",
        "athletes_attendance_is_required": "حضور الرياضيين مطلوب",
        "select_category_or_write_new_category_name": "حدد فئة أو اكتب اسم فئة جديدة",
        "category": "فئة",
        "athletes_group": "مجموعة الرياضيين",
        "teams": "الفرق",
        "add_team": "اضافة فريق",
        "edit_team": "تحرير الفريق",
        "for_athlete_group": "لمجموعة الرياضيين",
        "any_athlete_group": "أي مجموعة رياضية",
        "selected_athlete_group": "مجموعة رياضية مختارة",
        "team_form": "نموذج الفريق",
        "season": "موسم",
        "team": "فريق",
        "season_name": "اسم الموسم",
        "athlete_name": "اسم الرياضي",
        "TBA": "يُعلن لاحقًا",
        "unregistered": "غير مسجل",
        "WOD": "عمل اليوم",
        "attendance_percentage": "نسبة الحضور",
        "athlete_attendee_form": "استمارة حضور رياضي",
        "auto_refresh": "تحديث تلقائي",
        "full_screen": "شاشة كاملة",
        "optional_athletes_capacity": "Optional athletes capacity",
        "required_teams_to_attend": "Required teams to attend",
        "optional_teams_to_attend": "Optional teams to attend",
        "clients_capacity": "Clients capacity",
        "optional_groups_to_attend": "Optional groups to attend",
        "required_groups_to_attend": "Required groups to attend",
        "optional_attended_classes": "Optional attended classes",
        "required_attended_classes": "Required attended classes",
        "classes_per_week": "Classes per week",
        "benchmark": "المعيار",
        "benchmarks": "المعايير",
        "score_info": "معلومات النتيجة",
        "reps": "عدة",
        "rounds": "جولات",
        "time": "وقت",
        "weight": "وزن",
        "add_benchmark": "إضافة معيار",
        "edit_benchmark": "تحرير المعيار",
        "benchmark_form": "نموذج المعيار",
        "unit": "الوحدة",
        "athlete_benchmark_form": "نموذج قياس الأداء الرياضي",
        "minutes": "دقائق",
        "seconds": "ثواني",
        "performance": "الأداء",
        "view_performance_result": "عرض نتيجة الأداء",
        "restore_classes": "استعادة الحصص",
        "deleted_classes": "الحصص المحذوفة",
        "lock_class": "قفل الحصه",
        "unlock_class": "إلغاء قفل الحصه",
        "lock_classes": "قفل الحصص",
        "unlock_classes": "إلغاء قفل الحصص",
        "male_only": "الرجال فقط",
        "female_only": "السيدات فقط",
        "male_&_female": "الرجال والسيدات",
        "male_or_female": "الرجال اوالسيدات",
        "ending_within": "ينتهي خلال",
        "ending_within_days": "ينتهي خلال (الايام)",

        "empty_data_source_message": "لا توحد سجلات للعرض",
        "table.delete_text": "هل أنت متأكد أنك تريد حذف هذة البيانات ؟",

        "login.remember_me": "تذكرني",
        "login.forgot_password": "نسيت كلمة السر ؟",
        "login.no_account": "لا يوجد لديك حساب ؟ سجل",

        "layout.appbar.menu.my_leave_request": "طلبات اجازاتي",

        "layout.sideDrawer.menu": "القائمة",

        "layout.sideDrawer.main": "الرئيسي",
        "layout.sideDrawer.main.rents": "التآجيرات",
        "layout.sideDrawer.main.reports": "التقارير",
        "layout.sideDrawer.accounting": "المحاسبة",

        "layout.sideDrawer.store": "المخزن",
        "layout.sideDrawer.store.sales": "مبيعات",
        "layout.sideDrawer.store.companies": "الشركات",
        "layout.sideDrawer.store.categories": "الفئات",
        "layout.sideDrawer.store.products": "البضاعة",

        "layout.sideDrawer.hr": "الموارد البشرية",
        "layout.sideDrawer.hr.leaves": "الإجازات",

        "layout.sideDrawer.setting.entity": "الكيان",
        "layout.sideDrawer.setting.services": "الخدمات",
        "layout.sideDrawer.setting.global_action": "إجراءات عامه",
        "layout.sideDrawer.setting.export_data": "استخلاص البيانات",

        "pages.dashboard.latest_memberships": "اخر الاشتراكات",
        "pages.dashboard.clients_age_scale": "مقياس عمر العملاء",
        "pages.dashboard.clients_up_coming_birthDay": "العملاء القريب تاريخ ميلادهم",

        "pages.clients.add_client": "اضافة عميل جديد",
        "pages.clients.edit_client": "تعديل بيانات العميل",
        "pages.clients.view_client": "اظهار بيانات العميل",
        "pages.clients.add_membership": "اضافة اشتراك للعميل",

        "pages.clientsinfo.referred_by": "توصية بواسطة",
        "pages.clientsinfo.back": "قائمة العملاء",
        "pages.clientsinfo.header": "بيانات العميل",

        "pages.staff.edit_staff": "تعديل بيانات الموظف",
        "pages.staff.add_staff": "إضافة موظف جديد",

        "pages.staff.staff_info.tab_three": "حساب الموطف",
        "pages.staff.staff_info.edit_staff_role": "تعديل منصب الموطف",
        "pages.staff.staff_info.add_staff_role": "اضافة منصب جديد",
        "pages.staff.staff_info.edit_staff_permission": "تعديل صلاحية الموطف",
        "pages.staff.staff_info.add_staff_permission": "اضافة صلاحية جديد",
        "pages.staff.staff_info.staff_permission.form.header": "إذن الموظفين",

        "pages.memberships.add_membership": "اضافة مشترك جديد",
        "pages.memberships.edit_membership": "تعديل بيانات المشترك",
        "pages.memberships.view_membership": "اظهار بيانات المشترك",
        "pages.memberships.view_membership_invoice": "فاتورة المشترك",

        "pages.membershipsinfo.back": "قائمة المشتركين",
        "pages.membershipsinfo.cancel": "إلغاء الإشتراك",
        "pages.membershipsinfo.activate": "تفعيل الإشتراك",
        "pages.membershipsinfo.freezed_by": "الإيقاف بواسطة ",
        "pages.membershipsinfo.freeze_date": "تاريخ الإيقاف",
        "pages.membershipsinfo.payment_table.header": "سجل الدفع",
        "pages.membershipsinfo.freeze_table.header": "سجل الإيقافات",
        "pages.membershipsinfo.add_freeze": "إضافة ايقاف جديد",
        "pages.membershipsinfo.edit_freeze": "تعديل بيانات الإيقاف",
        "pages.membershipsinfo.extra_days": "أيام إضافية",
        "pages.membershipsinfo.extra_sessions": "حصص إضافية",
        "pages.membershipsinfo.payment_information.header": "معلومات الدفع",
        "pages.membershipsinfo.invoice_num": "رقم الفاتوة",
        "pages.membershipsinfo.invoice_date": "تاريخ الفاتوة",
        "pages.membershipsinfo.original_price": "السعر الاصلي",
        "cancel.header": "إلغاء الإشتراك",
        "cancel.cancel_reason": "سبب الإلغاء",
        "cancel.other_reason": "أسباب أخرى",
        "cancel_reason_1": "معدات سيئة",
        "cancel_reason_2": "صالة الألعاب الرياضية ليست نظيفة",
        "cancel_reason_3": "مزدحم",
        "cancel_reason_4": "فشلت إدارة صالة الألعاب الرياضية في معالجة المخاوف",
        "cancel_reason_5": "قضية صحية",
        "pages.membershipsinfo.freeze_form.header": "ايفاف مؤقت للإشتراك",
        "Payment_form.header": "عملية الدفع",
        "pages.membershipsinfo.Payment_form.payment_type": "نوع الدفع",
        "pages.membershipsinfo.Payment_form.payment_method": "طريقة الدفع",

        "latest_rents_clients": "أخر العملاء المؤجرين",
        "need_to_be_deliver": "بحاجة إلى توصبل",
        "need_to_be_collect": "بحاجة إلى سحب",
        "pages.rents.tabel_column.ends_during": "ينتهي خلال",
        "pages.rentsinfo.header": "معلومات الإيجار",
        "pages.rentsinfo.received_date": "تاريخ الاستلام",
        "pages.rentsinfo.delivered_date": "تاريخ التسليم",
        "pages.rentsinfo.cancel": "إلغاء الإيجار",

        "pages.employee_request.employee_id": "رقم التعريف للموظف",
        "pages.employee_request.new_leave": "الإجازات الجديده",
        "pages.employee_request.old_leave": "الإجازات القديمة",
        "pages.employee_request.add_leave": "إضافة إجازة جديد",
        "pages.employee_request.leave_form.header": "انشاء طلب إجازة",

        "pages.locationinfo.edit_service": "تعديل الخدمة",
        "pages.locationinfo.deactivate_service": "تعطيل الخدمة",
        "pages.locationinfo.activate_service": "تفعيل الخدمة",
        "pages.locationinfo.service_packages_form.header": "بيانات الخدمة و قائمة الاسعار",

        "pages.services.service_id": "رقم الخدمة",
        "pages.services.view_price_list": "عرض قوائم الأسعار",
        "pages.services.edit_service": "تعديل الخدمة",
        "pages.services.add_service": "إضافة خدمة جديدة",
        "pages.services.form.header": "بيانات الخدمة",
        "pages.services.service_name": "اسم الخدمة",
        "pages.services.service_form.is_rental_service": "خدمة تأجير",
        "pages.services.service_form.is_rental_service.note": "(لا يمكن التغيير بمجرد إنشائه)",

        "pages.packages.form.header": "بيانات الصفقة",
        "pages.packages.header": "قوائم الأسعار",
        "pages.packages.package_id": "رقم الصفقة",
        "pages.packages.package_name": "اسم الصفقة",
        "pages.packages.add_package": "إضافة صفقة جديده",
        "pages.packages.edit_package": "تعديل بيانات الصفقة",
        "pages.packages.manage_package_equipment": "إدارة المعدات",
        "pages.packages.view_offers": "العروض",
        "pages.packages.view_prices": "الاسعار",

        "pages.price_list.offers.title": "عروض قائمة الأسعار",
        "pages.price_list.offers.deactivate_offer": "إلغاء تنشيط العرض",
        "pages.price_list.offers.activate_offer": "تنشيط العرض",
        "pages.packages.package_offer.add_offer": "إضافة عرض جديد",
        "pages.packages.package_offer.edit_offer": "تعديل العرض",
        "pages.packages.package_offer.new_offer_prices": "أسعار العرض جديد",
        "pages.packages.package_offer.view_offer_prices": "عرض أسعار العرض",
        "pages.packages.package_offer.offer_prices.header": "أسعار العرض",
        "pages.packages.package_offer.price.form.original_price": "السعر الأصلي",
        "pages.packages.package_offer.price.form.offer_price": "سعر العرض",
        "pages.packages.package_offer.price.form.original_duration": "المدة الأصلية (أيام)",
        "pages.packages.package_offer.price.form.offer_duration": "مدة العرض (أيام)",
        "pages.packages.package_offer.price.form.original_sessions": "جلسات الأصلية",
        "pages.packages.package_offer.price.form.offer_sessions": "جلسات العرض",
        "pages.packages.package_offer.price.form.original_freeze_days": "أيام الإيقاف الأصلية",
        "pages.packages.package_offer.price.form.offer_freeze_days": "أيام الإيقاف العرض",

        "pages.prices.form.header": "بيانات السعر",
        "pages.prices.price_id": "رقم السعر",
        "pages.prices.price_name": "اسم السعر",
        "pages.prices.freeze_days": "أيام الإيقاف",
        "pages.prices.add_price": "إضافة سعر جديد",
        "pages.prices.edit_price": "تعديل بيانات السعر",

        "pages.global_actions.global_freeze": "الايقافات العامة",
        "pages.global_actions.global_freeze_id": "الرقم",
        "pages.global_actions.add_global_freeze": "إضافة ايقاف عام جديد",
        "pages.global_actions.edit_global_freeze": "تعديل بيانات الايقاف عام",

        "pages.entity.header.tab_one": "معلومات الكيان",
        "pages.entity.header.tab_two": "إعداد الكيان",
        "pages.entity.change_logo": "تغيير الشعار",
        "pages.entity.company_name": "اسم الشركة",
        "pages.entity.business_name": "الاسم التجاري",
        "pages.entity.lock_ended_membership": "قفل العضوية المنتهية",
        "pages.entity.lock_after": "قفل بعد (أيام)",

        "pages.my_profile.header": "بيانات ملفي الشخصي",
        "pages.my_profile.height": "الطول (سم)",

        "pages.my_request.header": "إجازاتي",
        "pages.my_request.edit_leave": "تعديل الإجازة",
        "pages.my_request.add_leave": "إضافة إجازة جديدة",

        "pages.equipment.add_equipment": "إضافة جهاز",
        "pages.equipment.edit_equipment": "تعديل بيانات الجهاز",
        "pages.equipment.manage_qty": "إدارة الكمية",
        "pages.equipment.form.header": "بيانات الجهاز",
        "pages.equipment.check_qty": "تحقق من الكمية",

};
