import React, {useRef, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import MaterialTable from "material-table";
import {permissions_data} from "../../../auth/permission_data";
import {instance} from "../../../auth/axios_instance";
import {BaseClassWorkoutURL} from "../../../urls";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {not_validate_value, sub_directory} from "../../../App";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {ViewWorkoutSections} from "./components/ViewWorkoutSections";
import {Hide} from "../../../components/Hide";
import Button from "@material-ui/core/Button";

export const Workouts = props => {
    const tableRef = useRef();
    const [workout_sections_data, setWorkoutSectionsData] = useState({open: false, data: []});

    const [columns, setColumns] = useState( [
        {
            title: getLabel("location"), render: rowData => (
                <Typography>
                    <Link href="" onClick={e => {
                        e.preventDefault();
                        viewWorkout(rowData.id)
                    }}>
                        {rowData.location_name}
                    </Link>
                </Typography>
            )
        },
        {title: getLabel("training_area"), field: 'training_area_name'},
        {title: getLabel("date"), field: 'date_full_view'},
        {
            title: getLabel("all_class_sessions"),
            render: rowData => (
                <Chip color={rowData.all_class_sessions ? "primary" : ""}
                      label={getLabel(rowData.all_class_sessions_view)}/>
            )
        },
        {
            render: rowData => (
                    <Button color="primary" size="small"
                            onClick={() => openViewWorkoutSections(rowData.sections)}>
                        {getLabel('view_workout_sections')}
                    </Button>
            )
        }
    ]);

    const [search, setSearch] = useState("")

    const viewWorkout = (id) => {
        props.history.push(
            {
                pathname: `${sub_directory}workouts/edit`,
                state: {
                    id: id,
                    tag: getLabel('edit_workout')
                }
            }
        )
    }
    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if ((newValue.length >= 5 && /^\d+$/.test(newValue)) || newValue === '') {
            reloadData();
        }

    };

    const openViewWorkoutSections = data => {
        let temp_sections;
        if (!not_validate_value.includes(data)) {
            temp_sections = JSON.parse(data);
            temp_sections.map((Section, Index) => Section.index = Index);
        }
        setWorkoutSectionsData({open: true, data: temp_sections})
    }

    const closeViewWorkoutSections = () => {
        setWorkoutSectionsData({open: false, data: []})
    }

    return (
        <>
            <Hide hide={!workout_sections_data.open}>
                <ViewWorkoutSections
                    open={workout_sections_data.open}
                    handleModalClose={closeViewWorkoutSections}
                    details={workout_sections_data.data}
                />
            </Hide>

            <Paper>

                <TableToolbar
                    searchFieldName='search'
                    searchValue={search}
                    onSearchChangeHandle={handleSearchField}
                    searchAction={reloadData}
                    match={props.match}
                    actions={
                        [
                            {
                                hide: !Permissions.checkPermission(permissions_data.workout.add),
                                onClick: () => props.history.push(
                                    {
                                        pathname: `${sub_directory}workouts/new`,
                                        state: {id: "new"}
                                    }
                                ),
                                label: "add_workout"
                            },
                        ]
                    }
                />

                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let url = BaseClassWorkoutURL;

                            url += '?page=' + (query.page + 1);

                            if (search !== '') {
                                url += '&search=' + search;
                            }

                            instance.get(url)
                                .then(result => {
                                    resolve({
                                        data: result.data.results,
                                        page: result.data.page - 1,
                                        totalCount: result.data.total,
                                    })
                                })
                        })
                    }
                    // viewOnly={{
                    //     isDeletable: !Permissions.checkAllPermissions([permissions_data.workout.delete]),
                    //         onRowDelete: async rowData => {
                    //             await httpRequest(`${BaseClassWorkoutURL}${rowData.id}/`, "DELETE")
                    //             await reloadData();}}

                    actions={[
                        {
                            icon: FileCopyIcon,
                            tooltip: getLabel('duplicate_workout'),
                            hidden: !Permissions.checkPermission([permissions_data.workout.add]),
                            onClick: (event, rowData) => props.history.push(
                                {
                                    pathname: `${sub_directory}workouts/new`,
                                    state: {
                                        id: "new",
                                        sectionsData: rowData.sections
                                    }
                                }
                            )
                        }
                    ]}


                    {...material_table_style}
                    {...material_table_options()}
                    {...material_table_localization()}


                    {...material_table_localization()}
                />
            </Paper>
        </>
    );

}
