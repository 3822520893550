import {
    BaseMembershipCancellationURL,
    BaseMembershipFilterDataURL,
    BaseMembershipFreezeURL,
    BaseMembershipInvoiceURL,
    BaseMembershipPaymentURL,
    BaseMembershipStartDateURL,
    BaseMembershipURL
} from "../../../urls";
import {httpRequestWithAuth, instance} from "../../../auth/axios_instance";
import * as axios from "axios";
import * as jsPDF from 'jspdf'


export const MembershipFilterData = async () => {
    let url = BaseMembershipFilterDataURL;
    const feedback = await  httpRequestWithAuth(url,"GET")

    // const feedback = await instance.get(url).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status}
    // });

    return feedback
};
export const RetrieveMemberships = async (id) => {
    let url = BaseMembershipURL + id + "/";
    const feedback = await  httpRequestWithAuth(url,"GET")
    // const feedback = await instance.get(url).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status}
    // });
    return feedback
};


export const CreateMemberships = async (data) => {
    let url = BaseMembershipURL;

    const feedback = await  httpRequestWithAuth(url,"POST", data)

    // const feedback = await instance.post(url, data).then(res => {
    //     return {status: true, data: res.data, msg: res.statusText}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status, error_feedback: error.response.data}
    //
    // });
    return feedback
};


export const UpdateMemberships = async (id, data) => {
    let url = BaseMembershipURL + id + "/";

    const feedback = await  httpRequestWithAuth(url,"PUT", data)

    // const feedback = await instance.put(url, data).then(res => {
    //     return {status: true, data: res.data, msg: res.statusText}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status, error_feedback: error.response.data}
    //
    // });
    return feedback
};


export const UpdateMembershipsByField = async (id, data) => {
    let url = BaseMembershipURL + id + "/";
    const feedback = await  httpRequestWithAuth(url,"PATCH", data)

    // const feedback = await instance.patch(url, data).then(res => {
    //     return {status: true, data: res.data, msg: res.statusText}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status, error_feedback: error.response.data}
    //
    // });
    return feedback
};

export const DeleteMemberships = async (id) => {
    let url = BaseMembershipURL + id + "/";
    const feedback = await  httpRequestWithAuth(url,"DELETE")

    // const feedback = await instance.delete(url).then(res => {
    //     return {status: true, data: res.data, msg: res.statusText}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status}
    // });
    return feedback
};


export const StartDateMemberships = async (start_date = null, client = null, price = null, extra_days = null, membership= null) => {
    let url = BaseMembershipStartDateURL;
    let params ={
        start_date,
        client,
        price,
        extra_days,
        membership,
    }
    // url = url + "?client=" + Client;
    // url = url + "&price=" + Price;
    // if (start_date != null) {
    //     url = url + "&start_date=" + start_date;
    // }
    //
    // if (extra_days != null) {
    //     url = url + "&extra_days=" + extra_days;
    // }
    //
    // if (membership != null) {
    //     url = url + "&membership=" + membership;
    // }
    const feedback = await  httpRequestWithAuth(url,"GET", null, params)

    // const feedback = await instance.get(url).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // })
    //     .catch(error => {
    //         return {status: false, msg: error.response.status}
    //     });

    return feedback
};


export const viewMembershipInvoice = (id) => {
    let url = BaseMembershipInvoiceURL + id;
    instance(url, {
        method: 'GET',
        responseType: 'blob',
    })
        .then(response => {
            const file = new Blob(
                [response.data],
                {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        })
        .catch(error => {
            console.log(error);
        });
};


export const viewMembershipInvoice2 = (id) => {
    let url = BaseMembershipInvoiceURL + id;
    axios(url, {
        method: 'GET',
        responseType: 'blob',
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('accessToken')}
    })
        .then(response => {
            // const file = new Blob(
            //     [response.data],
            //     {type: 'application/pdf'});

            // const url = URL.createObjectURL(file);

            // window.open(file)

            var doc = new jsPDF();
            var elementHandler = {
                '#ignorePDF': function (element, renderer) {
                    return true;
                }
            };
            // file.text().then(source => {
            //     console.log(source);
            doc.fromHTML(
                response.data,
                15,
                15,
                {
                    'width': 180, 'elementHandlers': elementHandler
                });

            doc.output("dataurlnewwindow");
        })

        // })
        .catch(error => {
            console.log(error);
        });
};


export const ListMembershipPayment = async (membership = null) => {
    let url = BaseMembershipPaymentURL;
    if (membership != null) {
        url += '?membership=' + membership
    }
    const feedback = await  httpRequestWithAuth(url,"GET", null, { membership })

    // const feedback = await instance.get(url).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status}
    // });
    return feedback
};

export const CreateMembershipsPayment = async (data) => {
    let url = BaseMembershipPaymentURL;
    const feedback = await  httpRequestWithAuth(url,"POST", data)

    // const feedback = await instance.post(url, data).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status, error_feedback: error.response.data}
    // });
    return feedback
};


export const UpdateMembershipsPayment = async (id, data) => {
    let url = BaseMembershipPaymentURL + id + "/";

    const feedback = await  httpRequestWithAuth(url,"PUT", data)

    // const feedback = await instance.put(url, data).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status}
    // });
    return feedback
};




export const CreateMembershipsFreeze = async (data) => {
    let url = BaseMembershipFreezeURL;

    const feedback = await  httpRequestWithAuth(url,"POST", data)

    // const feedback = await instance.post(url, data).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status, error_feedback: error.response.data}
    // });
    return feedback
};


export const UpdateMembershipsFreeze = async (id, data) => {
    let url = BaseMembershipFreezeURL + id + "/";

    const feedback = await  httpRequestWithAuth(url,"PUT", data)

    // const feedback = await instance.put(url, data).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status}
    // });
    return feedback
};


export const DeleteMembershipsFreeze = async (id) => {
    let url = BaseMembershipFreezeURL + id + "/";
    const feedback = await  httpRequestWithAuth(url,"DELETE")

    // const feedback = await instance.delete(url).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status}
    // });
    return feedback
};



export const ListMembershipCancellation = async (membership = null) => {
    let url = BaseMembershipCancellationURL;
    const feedback = await  httpRequestWithAuth(url,"GET", null, { membership })

    // const feedback = await instance.get(url).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status, error_feedback: error.response.data}
    // });
    return feedback
};


export const CreateMembershipCancellation = async (data) => {
    let url = BaseMembershipCancellationURL;
    const feedback = await  httpRequestWithAuth(url,"POST", data )

    // const feedback = await instance.post(url, data).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status, error_feedback: error.response.data}
    // });
    return feedback
};


export const UpdateMembershipCancellation = async (id, data) => {
    let url = BaseMembershipCancellationURL + id + "/";
    const feedback = await  httpRequestWithAuth(url,"PUT", data)

    // const feedback = await instance.put(url, data).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status}
    // });
    return feedback
};


export const DeleteMembershipCancellation = async (id) => {
    let url = BaseMembershipCancellationURL + id + "/";
    const feedback = await  httpRequestWithAuth(url,"DELETE")

    // const feedback = await instance.delete(url).then(res => {
    //     return {status: true, msg: res.statusText, data: res.data}
    // }).catch(error => {
    //     return {status: false, msg: error.response.status}
    // });
    return feedback
};


