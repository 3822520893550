import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import '../../../assets/scss/Static.scss';
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import moment from "moment";
import Permissions from "../../../auth/permissions";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {ClientAutoComplete} from "../../../components/fields/ClientAutoComplete";
import {sub_directory, dir, not_validate_value} from "../../../App";
import {Loading} from "../../../components/Loading";
import {ActionButtons} from "../../../components/ActionButton";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import {getLabel} from "../../../localization/MainLocalization";
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CustomCheckBox} from "../../../components/CustomCheckBox";
import {DateField} from "../../../components/fields/DateField";
import {CustomHeader} from "../../../components/CustomHeader";
import {CreateRents, DeleteRents, RetiveRents, CalcStartAndEndDateRents, UpdateRents} from "./rents_apis";
import {ListLocationServicesFullData} from "../../setting/locations/location-apis";
import {Hide} from "../../../components/Hide";
import {LocationsAutoComplete} from "../../../components/fields/LocationsAutoComplete";
import {CustomTextArea} from "../../../components/fields/CustomTextArea";
import {permissions_data} from "../../../auth/permission_data";

let rent_id = -1;
let access_permission = true;

let price_lists = [];
let prices = [];


export default class RentForm extends Component {
    constructor(props) {
        super(props);

        rent_id = props.rent_id;
        access_permission = ((Permissions.checkAllPermissions([permissions_data.rent.add]) && rent_id === 'new') ||
            Permissions.checkAllPermissions([permissions_data.rent.change]));

        this.state = {
            rent: {
                balance: '0',
                paid: '0',
                original_price: '0',
                total_price: '0',
                discount: '0',
            },
            client_gender: "",
            helper_text: {},
            min_date: null,
            services: [],
            is_loading: true,
            out_of_stock: false,
            have_permission: access_permission
        };

    }

    async componentDidMount() {
        await this.ListRentData();
    };

    setHelpText = data => {
        this.setState({
            helper_text: {...data}
        });
    };


    ListServiceData = async location => {
        if (location !== "") {
            let ListServicesRespone = await ListLocationServicesFullData(location, true);
            if (ListServicesRespone.status) {
                let services = [];
                ListServicesRespone.data.map((Service, index) => {
                    services.push({
                        index: index,
                        value: Service.id,
                        title: Service.id + " - " + Service.name,
                        data: Service
                    });
                    return 0;
                });
                this.setState({
                    services: services
                });
            }
        } else {
            this.setState({
                services: []
            });
        }
    };

    ListRentData = async () => {
        if (rent_id !== 'new') {

            let RetiveRentResponse = await RetiveRents(rent_id);
            if (RetiveRentResponse.status) {
                this.props.setIsCancelledValue(RetiveRentResponse.data.is_cancelled)

                await this.ListServiceData(RetiveRentResponse.data.location);

                this.state.services.map((Service) => {
                    if (Service.value === RetiveRentResponse.data.service) {
                        price_lists = [];
                        Service.data.price_lists.map((Price_list) => {
                            price_lists.push({
                                value: Price_list.id,
                                title: Price_list.id + " - " + Price_list.name,
                                data: Price_list
                            });

                            if (Price_list.id === RetiveRentResponse.data.price_list) {
                                prices = [];
                                Price_list.price.map(Price => {
                                    prices.push({
                                        value: Price.id,
                                        title: Price.id + " - " + Price.name,
                                        data: Price
                                    });
                                    return 0;
                                });
                            }
                            return 0;
                        });
                    }
                    return 0;
                });


                await this.setState({
                    rent: RetiveRentResponse.data,
                    have_permission: access_permission && !RetiveRentResponse.data.is_done,
                    is_loading: false
                });

            } else {
                this.props.history.push("/notfound");
            }
        } else if (rent_id !== 'new') {
            this.props.history.push("/notfound");
        }

        this.setState({
            is_loading: false
        });
    };

    getStartAndEndDate = async (start_date = null, extra_days = null) => {
        if (start_date !== null) {
            start_date = moment(start_date).format("YYYY-MM-DD");
        }
        let Client = this.state.rent.client;
        let Price = this.state.rent.price;
        if (Client !== "" && Price !== "") {
            let Response = await CalcStartAndEndDateRents(start_date, Client, Price, extra_days);
            if (Response.status) {
                let data = Response.data;
                this.setState(state => ({
                    rent: {
                        ...state.rent,
                        start_date: data.start_date,
                        end_date: moment(data.end_date).format("YYYY-MM-DD"),
                    },
                }))
            }
        }
    };

    handleFieldChange = async (name, newValue) => {
        if (!this.state.rent.is_cancelled || !this.state.have_permission) {
            if (name === 'extra_days') {
                newValue = parseInt(newValue === '' ? 0 : newValue);
                if (newValue < 0) {
                    newValue = 0;
                }
            }

            if (name === 'extra_days' && this.state.rent.price !== "") {
                await this.getStartAndEndDate(this.state.rent.start_date, newValue)
            }

            this.setState(state => ({
                rent: {...state.rent, [name]: newValue},
            }));


            if (name === 'discount') {
                if (newValue === '') {
                    newValue = 0;
                } else {
                    newValue = parseFloat(newValue);
                }
                this.setState(state => ({
                    rent: {
                        ...state.rent,
                        discount: newValue,
                        total_price: Math.max(0, parseFloat(state.rent.original_price) - newValue)
                    }
                }));
            }
        }
    };


    handleDateChange = async (id, date) => {
        if (this.state.have_permission) {
            if (date + "" !== 'Invalid Date') {
                await this.getStartAndEndDate(date, this.state.rent.extra_days);
            } else {
                this.setState(state => ({
                    rent: {
                        ...state.rent,
                        [id]: null,
                        end_date: ""
                    },
                }));
            }
        }

    };


    handleAutoCompleteValue = id => {
        let value = "";

        if (id === 'service') {
            this.state.services.map(Service => {
                if (Service.value === this.state.rent[id]) {
                    value = Service;
                }
                return 0;

            });
        }

        if (id === 'price_list') {
            price_lists.map(PriceList => {
                if (PriceList.value === this.state.rent[id]) {
                    value = PriceList;
                }
                return 0;

            });
        }

        if (id === 'price') {
            prices.map(Price => {
                if (Price.value === this.state.rent[id]) {
                    value = Price;
                }
                return 0;

            });
        }

        return value;
    };

    getClientGender = value => {
        this.setState({
            client_gender: value
        })
    };

    handlePriceChange = newValue => {
        let is_null = newValue === null;
        this.setState(state => ({
            rent: {
                ...state.rent,
                original_price: is_null ? 0 : newValue.data.price, duration: is_null ? 0 : newValue.data.duration,
                price: is_null ? "" : newValue.value,
                end_date: is_null ? "" : state.end_date,
                total_price: is_null ? 0 : Math.max(0, parseFloat(newValue.data.price) - parseFloat(state.rent.discount)),
                offer_price: is_null ? "" : not_validate_value.includes(newValue.data.offer_price) ? "" : newValue.data.offer_price,
            }
        }));
    };


    handlePriceListChange = newValue => {
        this.handlePriceChange(null);
        prices = [];
        if (newValue !== null) {
            newValue.data.price.map(Price => {
                prices.push({
                    value: Price.id,
                    title: Price.id + " - " + Price.name,
                    data: Price
                });
                return 0;
            });
        }
        this.setState(state => ({
            rent: {
                ...state.rent,
                price_list: newValue === null ? "" : newValue.value,
            },
            out_of_stock: newValue === null ? false : newValue.out_of_stock
        }));
    };


    handleServiceChange = newValue => {
        this.handlePriceListChange(null);
        price_lists = [];
        if (newValue !== null) {
            newValue.data.price_lists.map((Price_list, index) => {
                price_lists.push({
                    value: Price_list.id,
                    title: Price_list.id + " - " + Price_list.name,
                    data: Price_list,
                    out_of_stock: Price_list.out_of_stock
                });
                if (Price_list.id === newValue.data.default_Price_list &&
                    ((this.state.client_gender === "Male" && Price_list.for_males) ||
                        (this.state.client_gender === "Female" && Price_list.for_females))) {
                    this.handlePriceListChange(price_lists[index]);
                }
                return 0;
            });
        }
        this.setState(state => ({
            rent: {
                ...state.rent,
                service: newValue === null ? "" : newValue.value,
            }
        }))
    };

    handleAutoCompleteChange = async (name, newValue) => {
        if (this.state.have_permission) {
            if (name === 'service' && (newValue === null || this.state.rent.service !== newValue.value)) {
                await this.handleServiceChange(newValue)
            }

            if (name === 'price_list' && (newValue === null || this.state.rent.price_list !== newValue.value)) {
                await this.handlePriceListChange(newValue)
            }

            if (name === 'price') {
                await this.handlePriceChange(newValue)
            }


            if (name === 'location' && (newValue === null || this.state.rent.location !== newValue)) {
                await this.handleServiceChange(null);
                await this.ListServiceData(newValue)

            }

            if (['location', 'client'].includes(name)) {
                await this.setState(state => ({
                    rent: {...state.rent, [name]: newValue}
                }))
            }
            this.getStartAndEndDate(this.state.rent.start_date, this.state.rent.extra_days);
        }
    };


    handleSaveButton = async () => {
        this.setState({is_loading: true});

        if (this.state.have_permission) {
            let rent = {
                ...this.state.rent,
                start_date: not_validate_value.includes(this.state.rent.start_date) ? null : moment(this.state.rent.start_date).format('YYYY-MM-DD'),
                end_date: not_validate_value.includes(this.state.rent.end_date) ? null : moment(this.state.rent.end_date).format('YYYY-MM-DD'),
                equipment_delivered_date: !this.state.equipment_delivered || not_validate_value.includes(this.state.rent.equipment_delivered_date) ? null : moment(this.state.rent.equipment_delivered_date).format('YYYY-MM-DD'),
                equipment_received_date: !this.state.equipment_received || not_validate_value.includes(this.state.rent.equipment_received_date) ? null : moment(this.state.rent.equipment_received_date).format('YYYY-MM-DD'),
                balance: Math.max(0, parseFloat(this.state.rent.total_price) - parseFloat(this.state.rent.paid))
            };

            let response;
            if (rent_id === 'new') {
                rent.rent_number = parseInt(Math.random() * 1000);
                response = await CreateRents(rent);
            } else {
                response = await UpdateRents(rent_id, rent);
            }

            if (response.status) {
                if (response.data.balance !== 0) {
                    if (rent_id === 'new'){
                        this.props.history.push({
                            pathname: `${sub_directory}rents/${response.data.id}`,
                            state: {moveToNextTab: true}
                        });
                    }else{
                        this.props.moveToNextTab();
                    }
                } else {
                    this.props.history.push(`${sub_directory}rents`)
                }
            } else {
                this.setHelpText(response.error_feedback);
            }
            this.ListRentData();
        }

        this.setState({is_loading: false});
    };

    handleDeleteButton = async () => {
        let deleteRentResponse = await DeleteRents(rent_id);
        if (deleteRentResponse.status) {
            this.props.history.push(sub_directory + 'rents');
        }
    };

    handleBackButton = async () => {
        this.props.history.push(sub_directory + 'rents');
    };


    render() {
        // rent data
        const RentData = (
            <Paper className="h-100">
                <Grid container spacing={2} className='Paper'>


                    <CustomHeader
                        title="pages.rentsinfo.header"
                        history={this.props.history}
                    />

                    <Grid item xs={12} sm={6}>
                        <ClientAutoComplete
                            viewOnly={!this.state.have_permission}
                            disabled={this.state.rent.is_cancelled}
                            required={true}
                            name='client'
                            label="client"
                            onChangeHandle={this.handleAutoCompleteChange}
                            helper_text={this.state.helper_text.client}
                            error={this.state.helper_text.client !== undefined}
                            value={this.state.rent.client}
                            getClientGender={this.getClientGender}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <LocationsAutoComplete
                            viewOnly={!this.state.have_permission || not_validate_value.includes(this.state.rent.client)}
                            disabled={this.state.rent.is_cancelled}
                            required={true}
                            name='location'
                            label="location"
                            onChangeHandle={this.handleAutoCompleteChange}
                            helper_text={this.state.helper_text.location}
                            error={this.state.helper_text.location !== undefined}
                            value={this.state.rent.location}
                        />

                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            ListboxProps={{className:"list-box-autocomplete"}}
                            renderOption={(option) => (
                                <span className={"w-100 text-start"}>{option.title}</span>
                            )}
                            disabled={this.state.rent.is_cancelled || !this.state.have_permission}
                            options={this.state.services}
                            getOptionLabel={option => option.title}
                            renderInput={params => (
                                <TextField {...params}
                                           label={<label>{getLabel("service")}<span
                                               className='text-danger'>*</span></label>}
                                           variant="outlined"
                                           error={(this.state.error && this.state.rent.service === "") || this.state.helper_text.service !== undefined}
                                           helperText={this.state.helper_text.service}
                                           disabled={this.state.rent.is_cancelled}
                                           className={this.state.rent.is_cancelled ? "disabled-field" : ""}
                                           fullWidth/>
                            )}
                            onChange={(event, value) => {
                                this.handleAutoCompleteChange('service', value)
                            }}
                            value={this.handleAutoCompleteValue('service')}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            ListboxProps={{className:"list-box-autocomplete"}}
                            renderOption={(option) => (
                                <span
                                    className={"w-100  text-left-start" + (option.out_of_stock ? " text-danger" : "")}>
                                    {option.out_of_stock && (
                                        <Tooltip title="Out of Stock">
                                            <WarningIcon color="secondary" className="mr-2 ml-2"/>
                                        </Tooltip>
                                    )}
                                    {option.title}

                                </span>
                            )}
                            disabled={this.state.rent.is_cancelled || !this.state.have_permission}
                            options={price_lists}
                            getOptionLabel={option => option.title}
                            renderInput={params => (
                                <TextField {...params}
                                           label={<label>{getLabel("price_list")}<span
                                               className='text-danger'>*</span></label>}
                                           variant="outlined"
                                           error={this.state.helper_text.price_list !== undefined}
                                           helpertText={this.state.helper_text.price_list}
                                           disabled={this.state.rent.is_cancelled}
                                           className={this.state.rent.is_cancelled ? "disabled-field" : ""}
                                           fullWidth
                                           InputProps={{
                                               ...params.InputProps,
                                               startAdornment: (

                                                   <InputAdornment position="start">
                                                       {this.state.out_of_stock && (
                                                           <Tooltip title="Out of Stock">
                                                               <WarningIcon color="secondary" className="mr-2 ml-2"/>
                                                           </Tooltip>
                                                       )}
                                                   </InputAdornment>
                                               ),
                                           }}

                                />
                            )}
                            onChange={(event, value) => {
                                this.handleAutoCompleteChange('price_list', value)
                            }}
                            value={this.handleAutoCompleteValue('price_list')}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            ListboxProps={{className:"list-box-autocomplete"}}
                            renderOption={(option) => (
                                <span className={"w-100 text-left-start"}>
                                    {option.title}
                                    {!not_validate_value.includes(option.data.offer_price) && (
                                        <MoneyOffIcon color="primary" className="mr-2 ml-2"/>
                                    )}
                                </span>
                            )}
                            disabled={this.state.rent.is_cancelled || !this.state.have_permission}
                            options={prices}
                            getOptionLabel={option => option.title}
                            renderInput={params => (
                                <TextField {...params}
                                           label={<label>{getLabel("price")}<span
                                               className='text-danger'>*</span></label>}
                                           variant="outlined"
                                           error={this.state.helper_text.price !== undefined}
                                           helperText={this.state.helper_text.price}
                                           disabled={this.state.rent.is_cancelled}
                                           className={this.state.rent.is_cancelled ? "disabled-field" : ""}
                                           fullWidth/>
                            )}
                            onChange={(event, value) => {
                                this.handleAutoCompleteChange('price', value)
                            }}
                            value={this.handleAutoCompleteValue('price')}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DisabledTextField
                            label="duration_days"
                            value={this.state.rent.duration || ""}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <CustomTextField
                            error={this.state.helper_text.extra_days !== undefined}
                            helper_text={this.state.helper_text.extra_days}
                            name="extra_days"
                            label="pages.membershipsinfo.extra_days"
                            type="number"
                            value={this.state.rent.extra_days}
                            onChange={this.handleFieldChange}
                            disabled={this.state.rent.is_cancelled}
                            viewOnly={!this.state.have_permission}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DateField
                            name="start_date"
                            disabled={this.state.rent.is_cancelled || !this.state.have_permission}
                            viewOnly={this.state.rent.is_cancelled || !this.state.have_permission}
                            error={this.state.helper_text.start_date !== undefined}
                            helper_text={this.state.helper_text.start_date}
                            label="start_date"
                            minDate={this.state.min_date}
                            value={this.state.rent.start_date}
                            onChangeHandle={this.handleDateChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DisabledTextField
                            label="end_date"
                            value={this.state.rent.end_date}
                        />
                    </Grid>

                    {rent_id !== 'new' && (
                        <>

                            <Grid item xs={9}>
                                <DateField
                                    name="equipment_delivered_date"
                                    disabled={this.state.rent.is_cancelled || !this.state.have_permission || !this.state.rent.equipment_delivered}
                                    viewOnly={this.state.rent.is_cancelled || !this.state.have_permission || !this.state.rent.equipment_delivered}
                                    error={this.state.helper_text.equipment_delivered_date !== undefined}
                                    helper_text={this.state.helper_text.equipment_delivered_date}
                                    label="pages.rentsinfo.delivered_date"
                                    value={this.state.rent.equipment_delivered_date}
                                    onChangeHandle={this.handleFieldChange}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <CustomCheckBox
                                    disable={this.state.rent.is_cancelled || !this.state.have_permission || !this.state.rent.equipment_delivered}
                                    value={this.state.rent.equipment_delivered}
                                    name='equipment_delivered'
                                    onChangeHandle={this.handleFieldChange}
                                    label="delivered"
                                />
                            </Grid>

                            {this.state.rent.equipment_delivered && (
                                <>
                                    <Grid item xs={9}>
                                        <DateField
                                            name="equipment_received_date"
                                            viewOnly={!this.state.rent.equipment_received}
                                            disabled={!this.state.rent.equipment_received}
                                            error={this.state.helper_text.equipment_received_date !== undefined}
                                            helper_text={this.state.helper_text.equipment_received_date}
                                            label="pages.rentsinfo.received_date"
                                            value={this.state.rent.equipment_received_date}
                                            onChangeHandle={this.handleFieldChange}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <CustomCheckBox
                                            disable={this.state.rent.is_cancelled || !this.state.have_permission || !this.state.rent.equipment_delivered}
                                            value={this.state.rent.equipment_received}
                                            name='equipment_received'
                                            onChangeHandle={this.handleFieldChange}
                                            label="received"
                                        />
                                    </Grid>
                                </>
                            )}
                        </>
                    )}

                    <Grid item xs={12} className="mt-3">
                        <CustomTextArea
                            disabled={this.state.rent.is_cancelled || !this.state.have_permission}
                            label="notes"
                            error={this.state.helper_text.notes !== undefined}
                            helper_text={this.state.helper_text.notes}
                            name="notes"
                            rows={3}
                            value={this.state.rent.notes || ""}
                            onChangeHandle={this.handleFieldChange}
                        />
                    </Grid>


                    <ActionButtons
                        errorMsg={this.state.helper_text.non_field_errors}
                        allowDelete={Permissions.checkAllPermissions([permissions_data.rent.delete]) && this.state.have_permission}
                        deleteAction={this.handleDeleteButton}
                        allowBack={this.state.have_permission}
                        backAction={this.handleBackButton}
                        allowSave={this.state.have_permission}
                        saveAction={this.handleSaveButton}
                    />

                </Grid>
            </Paper>
        );


        const PaymentData = (
            <Paper className="h-100">

                <Grid container spacing={2} className="Paper pt-2">

                    <CustomHeader
                        title="pages.rentsinfo.header"
                    />

                    {rent_id !== 'new' && (
                        <>
                            <Grid item xs={12}>
                                <DisabledTextField
                                    label="pages.membershipsinfo.invoice_num"
                                    value={this.state.rent.rent_number || ""}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <DisabledTextField
                                    label="pages.membershipsinfo.invoice_date"
                                    value={moment(this.state.rent.rent_date).format("DD-MM-YYYY HH:mm:ss") || ""}
                                />
                            </Grid>
                        </>
                    )}


                    <Grid item xs={12}>
                        <DisabledTextField
                            label="pages.membershipsinfo.original_price"
                            value={this.state.rent.original_price}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextField
                            name="discount"
                            label="discount"
                            type="number"
                            error={this.state.helper_text.discount !== undefined}
                            helper_text={this.state.helper_text.discount}
                            value={this.state.rent.discount}
                            onChange={this.handleFieldChange}
                            disabled={this.state.rent.is_cancelled}
                            viewOnly={!this.state.have_permission}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DisabledTextField
                            label="pages.membershipsinfo.total_price"
                            value={this.state.rent.total_price}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DisabledTextField
                            value={this.state.rent.paid}
                            label="paid"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DisabledTextField
                            value={this.state.rent.balance}
                            label="balance"
                        />
                    </Grid>
                </Grid>
            </Paper>
        );


        return (
            <>
                <Hide hide={!this.state.is_loading}>
                    <Loading/>
                </Hide>

                <Grid container spacing={3}>

                    <Hide hide={this.state.is_loading}>
                        <Grid item xs={12} md={12} lg={8}>
                            {RentData}
                        </Grid>


                        <Grid item xs={12} md={12} lg={4}>
                            {PaymentData}
                        </Grid>
                    </Hide>

                </Grid>
            </>
        );
    }
}
