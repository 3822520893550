import React, {useEffect, useState} from 'react';
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis,} from 'recharts';
import Typography from "@material-ui/core/Typography";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseFinanceLocationsTotalRevenueURL} from "../../../../urls";
import moment from "moment";
import {DateField} from "../../../../components/fields/DateField";
import {not_validate_value} from "../../../../App";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";

export const LocationsTotalRevenue = props => {
    const [data, setData] = useState({total_of_all_locations: 0,})
    const [is_loading, setIsLoading] = useState(true)
    const [min_date, setMinDate] = useState(moment());
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

    const handleDateChange = async (name, value) => {
        if (not_validate_value.includes(value)){
            value = moment().format("YYYY-MM-DD")
        }

        await setDate(value)
    };

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            let response = await httpRequestWithAuth(BaseFinanceLocationsTotalRevenueURL, "GET", null, {date});
            if (response.status) {
                await setData(response.data)
                await setMinDate(response.data.min_date)
            }
            await setIsLoading(false);
        }

        fetchData();
    }, [date])

    return (
        <div className='card-paper-1 h-100 w-100'>
            <Typography variant="h6" className="text-start mb-2">
                Locations total revenue
            </Typography>

                    <DateField
                        date_view="monthly"
                        name="date"
                        label="date"
                        min_date={min_date}
                        maxDate={moment()}
                        value={date}
                        onChangeHandle={handleDateChange}
                    />

            <Typography component="p" variant="h5" className="text-end mt-3">
                {`Total: ${localStorage.getItem('currency_symbol')} ${data.total_of_all_locations}`}
            </Typography>

            <Hide hide={!is_loading}>
                <div className="fixedHeight-400">
                    <Loading/>
                </div>
            </Hide>

            <Hide hide={is_loading}>
            <div dir='ltr' className="h-100 w-100">

                <ResponsiveContainer width="100%" height='90%' className="fixedHeight-400">

                    <BarChart
                        data={data.details}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <XAxis dataKey="location_name"/>
                        <YAxis/>
                        <Tooltip/>

                        <Bar dataKey="amount" barSize={30} fill="#3f51b5"/>

                    </BarChart>
                </ResponsiveContainer>
            </div>
            </Hide>
        </div>
    );
}
