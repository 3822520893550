import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {ClassForm} from "./ClassForm";
import {ClassesClientsAttendees} from "./ClassesClientsAttendees";
import {sub_directory} from "../../../App";
import {CustomTabs} from "../../../components/CustomTabs";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {ClassesAthletesAttendees} from "./ClassesAthletesAttendees";


export const ClassInfo = props => {
    const state = props.history.location.state || {};
    const [currentTab, setCurrentTab] = React.useState(0);
    const [class_id, setClassId] = useState(state.id);
    const [for_clients, setForClients] = useState(false);
    const [for_athletes, setForAthletes] = useState(false);


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };


    useEffect(()=>{
        if (state.id){
            setClassId(state.id);
            if (state.attendanceTab){
                setCurrentTab(1);
            }
        }else{
            props.history.push(`${sub_directory}classes`);
        }
    },[])


    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match} details={{tag: state.tag}}/>*/}

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >
                <Tab label={getLabel("details")} id={0}/>

                <Tab label={getLabel("clients_attendance")} id={1} disabled={class_id === "new" || !for_clients || !Permissions.checkPermission(permissions_data.class_attendance.view)}/>

                <Tab label={getLabel("athletes_attendance")} id={2} disabled={class_id === "new" || !for_athletes|| !Permissions.checkPermission(permissions_data.class_attendance.view)}/>

            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <ClassForm
                        {...props}
                        class_id={class_id}
                        setForClients={setForClients}
                        setForAthletes={setForAthletes}
                    />
                </Hide>

                <Hide hide={currentTab !== 1}>
                    <ClassesClientsAttendees
                        {...props}
                        class_id={class_id}
                        for_clients={for_clients}
                    />
                </Hide>

                <Hide hide={currentTab !== 2}>
                    <ClassesAthletesAttendees
                        {...props}
                        class_id={class_id}
                        for_athletes={for_athletes}
                    />
                </Hide>

            </Grid>
        </Paper>
    );
}
