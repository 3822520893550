import React, {useContext, useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {CreateMembershipsPayment, ListMembershipPayment} from "./memberships_apis";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {PaymentModal} from "../../../components/PaymentModal";
import {Hide} from "../../../components/Hide";
import {permissions_data} from "../../../auth/permission_data";
import {sub_directory} from "../../../App";
import {ActionButtons} from "../../../components/ActionButton";
import {AlertContext} from "../../../store/use_context";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseMembershipPaymentURL} from "../../../urls";
import {SelectCashBoxForDeletePaymentModal} from "./components/SelectCashBoxForDeletePaymentModal";


export const  MembershipPayments = props => {
       const {membership_id} = props;
        const {setAlert} = useContext(AlertContext);

            const payments_columns = [
                {title: getLabel("type"), field: 'payment_type'},
                {title: getLabel("payment_method"), field: 'payment_method_name'},
                {title: getLabel("amount"), field: 'amount'},
                {title: getLabel("reference"), field: 'reference'},
                {title: getLabel("payment_date"), field: 'payment_date_view'},
                {title: getLabel("created_by"), field: 'created_by_name'},
            ];
    const [payments, setPayments] = useState([])
    const [payment_form_open, setPaymentFormOpen] = useState(false)
    const [payment_type_form, setPaymentTypeForm] = useState("")
    const [open_select_cash_box, setOpenSelectCashBox] = useState(false)
    const [select_cash_box_data, setSelectCashBoxData] = useState({})
    const [is_loading, setIsLoading] = useState(true)

    const listPaymentsData = async () => {
        await setIsLoading(true);

        let ListPaymentsResponse = await ListMembershipPayment(membership_id);
        if (ListPaymentsResponse.status) {
           await setPayments(ListPaymentsResponse.data)
        }
        await setIsLoading(false);
    };

    useEffect(()=>{
        listPaymentsData();
    }, [])

    const handlePaymentModalOpen = data => {
        setPaymentTypeForm(data)
        setPaymentFormOpen(true);
    };

    const handleSelectCashBoxModalOpen = data => {
        setSelectCashBoxData(data);
        setOpenSelectCashBox(true);
    };

    const handleModalClose = async () => {
        await listPaymentsData();
        setPaymentTypeForm("")
        setPaymentFormOpen(false);
        setOpenSelectCashBox(false);
        setSelectCashBoxData({});
    };

    const handleBackButton = async () => {
        props.history.push(sub_directory + 'memberships');
    };


        return (
            <>
                <Hide hide={!open_select_cash_box}>
                    <SelectCashBoxForDeletePaymentModal
                    open={open_select_cash_box}
                    handleModalClose={handleModalClose}
                    {...select_cash_box_data}
                    />
                </Hide>

                <Hide hide={!payment_form_open}>
                    <PaymentModal
                        assigned_to="memberships"
                        formOpen={payment_form_open}
                        handleModalClose={handleModalClose}
                        id={membership_id}
                        name="membership"
                        CreatePayment={CreateMembershipsPayment}
                        payment_type={payment_type_form}
                    />
                </Hide>

                <Grid item xs={12}>
                    <MaterialTable
                        // title={ get_label("pages.membershipsinfo.payment_table.header")}
                        style={{padding: '0.5rem', boxShadow: "none"}}
                        isLoading={is_loading}
                        columns={payments_columns}
                        data={payments}
                        editable={
                            (Permissions.checkPermission(permissions_data.membership_payment.delete) && ((!props.is_done && !props.is_cancelled ))) && {
                            onRowDelete: async PaymentData => {
                                let response = await  httpRequestWithAuth(`${BaseMembershipPaymentURL}${PaymentData.id}/`,"DELETE");
                                if (response.status){
                                    await listPaymentsData();
                                }else{
                                    if (response.error_feedback.select_cash_box_error){
                                        handleSelectCashBoxModalOpen({payment_id: PaymentData.id, payment_type: PaymentData.payment_type, created_by_name: PaymentData.created_by_name});
                                    }else{
                                        await setAlert(response.error_feedback.non_field_errors)
                                    }
                                }
                            }
                        }}

                        actions={[
                            {
                                icon: () => (
                                    <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                            startIcon={<AddIcon/>}>
                                    <Typography variant="body1">{getLabel('add_payment')}</Typography>
                                    </Button>
                                ),
                                hidden :!(Permissions.checkAllPermissions([permissions_data.membership_payment.add]) && ((!props.is_done && !props.is_cancelled )) && !props.is_fully_paid) ,
                                tooltip:  getLabel("pages.membershipsinfo.edit_freeze"),
                                isFreeAction: true,
                                onClick: () => handlePaymentModalOpen('Payment')
                            },
                            // {
                            //     icon: () => (
                            //         <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                            //                 startIcon={<AddIcon/>}>
                            //             <Typography variant="body1">{getLabel('add_refund')}</Typography>
                            //         </Button>
                            //     ),
                            //     hidden :!(Permissions.checkAllPermissions([permissions_data.membership_payment.add, permissions_data.membership_payment.add_refund]) && ((!props.is_done && !props.is_cancelled) || (props.is_cancelled && !props.is_fully_refund))) ,
                            //     isFreeAction: true,
                            //     onClick: () => handlePaymentModalOpen('Refund')
                            // }
                        ]}

                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false,
                            showTitle: false,
                            // toolbar: false
                        }}

                        {...material_table_localization()}
                    />
                </Grid>

                <ActionButtons
                    allowBack={true}
                    backAction={handleBackButton}
                />
            </>
        );

}
