import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {
    BaseFinanceCashTransferRecipientStatusURL,
    BaseFinanceCashTransferSenderCancellationURL,
    BaseFinanceCashTransferSenderURL, BaseFinanceMyCashBalanceURL
} from "../../../urls";

export const CreateCashTransfer = async (data) => {
    let url = BaseFinanceCashTransferSenderURL;

    const feedback = await  httpRequestWithAuth(BaseFinanceCashTransferSenderURL,"POST", data)
    return feedback
};



export const CancelCashTransfer = async (id) => {
    let url = BaseFinanceCashTransferSenderCancellationURL + id + "/";

    const feedback = await  httpRequestWithAuth(url,"PATCH", {status:"Cancelled"})
    return feedback
};


export const UpdatelCashTransferStatusAsRecipient = async (id, status) => {
    let url = BaseFinanceCashTransferRecipientStatusURL + id + "/";

    const feedback = await  httpRequestWithAuth(url,"PATCH", {status})
    return feedback
};


export const GetMyCashBalance = async () => {
    let url = BaseFinanceMyCashBalanceURL;

    const feedback = await  httpRequestWithAuth(url,"GET")
    return feedback
};