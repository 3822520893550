import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {CustomTextField} from "../../components/fields/CustomTextField";
import {Hide} from "../../components/Hide";
import {PhoneNumberField} from "../../components/fields/PhoneNumberField";
import {CountriesAutoComplete} from "../../components/fields/CountriesAutoComplete";
import {TimeZoneAutoComplete} from "../../components/fields/TimeZoneAutoComplete";
import {CustomStepper} from "../../components/CustomStepper";
import {not_validate_value, redirect, setSubDirectory, sub_directory} from "../../App";
import {
    BaseBackEndURL,
    BaseCheckUserDataURL,
    BaseEntityCheckSubDirectoryURL,
    BaseEntityRegisterURL,
    BaseLoginURL
} from "../../urls";
import axios from "axios";
import ErrorIcon from "@material-ui/icons/Error";
import auth from "../../auth/auth";
import {permissions} from "../../auth/permissions";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithoutAuth} from "../../auth/axios_instance";


export const SignUpPage = props => {
    useEffect(()=>{
        if (auth.isAuthenticated() && Object.keys(permissions).length !== 0 ) {
            props.history.push(redirect);
        }
    }, [])

    const [data, setData] = useState({password:""});
    const [helper_text, setHelperText] = useState({});
    const [current_step, setStep] = useState(0);
    const steps = ["personal_info", "entity_info"]

    const handleDataChange = (name, value) => {
        setData({...data, [name]: value})
    }

    const handleNext = async () => {
        if (current_step === 0 && await checkPersonalStep()) {
            await setStep(current_step + 1);
        }

        if (current_step === 1 && await checkEntityStep()) {
            let response = await httpRequestWithoutAuth(BaseBackEndURL + BaseEntityRegisterURL, "POST", data);
            if (response.status){
                await setSubDirectory(data.sub_directory);
                window.location.href = BaseLoginURL()
            }else{
                setHelperText({non_field_errors:"Something went wrong, try again!"})

            }
        }



    };

    const handleBack = () => {
        setStep(current_step - 1);
    };

    const checkPersonalStep = async () => {
        const {first_name, last_name, mobile, email, password} = data;

        let noError = true;
        let temp_helper_text = {}

        if (not_validate_value.includes(first_name)) {
            noError = false
            temp_helper_text = {...temp_helper_text, 'first_name': "this field is required!"}
        }

        if (not_validate_value.includes(last_name)) {
            noError = false
            temp_helper_text = {...temp_helper_text, 'last_name': "this field is required!"}
        }

        if (not_validate_value.includes(mobile)) {
            noError = false
            temp_helper_text = {...temp_helper_text, 'mobile': "this field is required!"}
        }

        if (not_validate_value.includes(email)) {
            noError = false
            temp_helper_text = {...temp_helper_text, 'email': "this field is required!"}
        }

        if (not_validate_value.includes(password)) {
            noError = false
            temp_helper_text = {...temp_helper_text, 'password': "this field is required!"}
        }


        if (!not_validate_value.includes(password)) {
            let response = await axios.post(BaseBackEndURL + BaseCheckUserDataURL, {password});
            if (response.status) {
                if (response.data.error) {
                    temp_helper_text = {...temp_helper_text, ...response.data};
                    noError = false;
                }
            }
        }

        if (noError) {
            setHelperText({})
        } else {
            setHelperText(temp_helper_text)
        }

        return noError
    }

    const checkEntityStep = async () => {
        const {company_name, business_name, country, timezone, sub_directory} = data;
        let noError = true;
        let temp_helper_text = {}

        // if (not_validate_value.includes(company_name)) {
        //     noError = false
        //     temp_helper_text = {...temp_helper_text, 'company_name': "this field is required!"}
        // }

        if (not_validate_value.includes(business_name)) {
            noError = false
            temp_helper_text = {...temp_helper_text, 'business_name': "this field is required!"}
        }

        if (not_validate_value.includes(sub_directory)) {
            noError = false
            temp_helper_text = {...temp_helper_text, 'sub_directory': "this field is required!"}
        }


        if (!not_validate_value.includes(sub_directory)) {
            await axios.post(BaseBackEndURL + BaseEntityCheckSubDirectoryURL, {sub_directory})
            .then(response=>{
                if (response.data.error) {
                    temp_helper_text = {...temp_helper_text, ...response.data};
                    noError = false;
                }
            })

        }

        if (not_validate_value.includes(country)) {
            noError = false
            temp_helper_text = {...temp_helper_text, 'country': "this field is required!"}
        }

        if (not_validate_value.includes(timezone)) {
            noError = false
            temp_helper_text = {...temp_helper_text, 'timezone': "this field is required!"}
        }


        if (noError) {
            setHelperText({})
        } else {
            setHelperText(temp_helper_text)
        }
        return noError
    }




    const PersonalDataForm = (
        <>
            <Grid item xs={12} sm={6}>
                <CustomTextField
                    autoComplete="off"
                    required={true}
                    value={data.first_name}
                    onChangeHandle={handleDataChange}
                    name="first_name"
                    label="first_name"
                    helper_text={helper_text.first_name}
                    error={helper_text.first_name !== undefined}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <CustomTextField
                    autoComplete="off"
                    required={true}
                    value={data.last_name}
                    onChangeHandle={handleDataChange}
                    name="last_name"
                    label="last_name"
                    helper_text={helper_text.last_name}
                    error={helper_text.last_name !== undefined}
                />
            </Grid>



            <Grid item xs={12} md={6}>
                <CustomTextField
                    autoComplete="off"
                    required={true}
                    value={data.email}
                    onChangeHandle={handleDataChange}
                    name="email"
                    label="email"
                    helper_text={helper_text.email}
                    error={helper_text.email !== undefined}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <PhoneNumberField
                    autoComplete="off"
                    required={true}
                    value={data.mobile}
                    onChangeHandle={handleDataChange}
                    name="mobile"
                    label="mobile"
                    helper_text={helper_text.mobile}
                    error={helper_text.mobile !== undefined}
                />
            </Grid>

            <Grid item xs={12}>
                <CustomTextField
                    required={true}
                    value={data.password}
                    onChangeHandle={handleDataChange}
                    name="password"
                    label="password"
                    type="password"
                    helper_text={helper_text.password}
                    error={helper_text.password !== undefined}
                />
            </Grid>

        </>
    );


    const EntityDataForm = (
        <>
            {/*<Grid item xs={12} md={6}>*/}
            {/*    <CustomTextField*/}
            {/*        required={true}*/}
            {/*        helper_text={helper_text.company_name}*/}
            {/*        error={helper_text.company_name !== undefined}*/}
            {/*        value={data.company_name}*/}
            {/*        onChangeHandle={handleDataChange}*/}
            {/*        name="company_name"*/}
            {/*        label="company_name"*/}
            {/*    />*/}
            {/*</Grid>*/}

            <Grid item xs={12} md={6}>
                <CustomTextField
                    required={true}
                    helper_text={helper_text.business_name}
                    error={helper_text.business_name !== undefined}
                    value={data.business_name}
                    onChangeHandle={handleDataChange}
                    name="business_name"
                    label="business_name"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <CustomTextField
                    required={true}
                    helper_text={helper_text.sub_directory}
                    error={helper_text.sub_directory !== undefined}
                    value={data.sub_directory}
                    onChangeHandle={handleDataChange}
                    name="sub_directory"
                    label="sub_directory"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <CountriesAutoComplete
                    required={true}
                    label="country"
                    name="country"
                    onChangeHandle={handleDataChange}
                    helper_text={helper_text.country}
                    error={helper_text.country !== undefined}
                    value={data.country}
                />
            </Grid>


            <Grid item xs={12} md={6}>
                <TimeZoneAutoComplete
                    country={data.country}
                    required={true}
                    error={helper_text.timezone !== undefined}
                    helper_text={helper_text.timezone}
                    name="timezone"
                    onChangeHandle={handleDataChange}
                    value={data.timezone}
                />
            </Grid>

        </>
    );




    return (
        <Container component="main" maxWidth="md" className="pt-5  pb-5">
            <CssBaseline/>

            <div className="mt-5 flex-column align-items-center d-flex card-paper-1">

                <Avatar className="m-2 primary-bg">
                    <LockOutlinedIcon color="inherit"/>
                </Avatar>

                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>

                <Grid container spacing={2} className="mt-5">

                    <CustomStepper
                        current_step={current_step}
                        steps={steps}
                    />

                    <Hide hide={current_step !== 0}>
                        {PersonalDataForm}
                    </Hide>

                    <Hide hide={current_step !== 1}>
                        {EntityDataForm}
                    </Hide>


                    {!not_validate_value.includes(helper_text.non_field_errors) && (
                        <Grid item xs={12} className="error-box">
                            <ErrorIcon className="margin-right-2"/>
                            {helper_text.non_field_errors}
                        </Grid>
                    )}

                    <div className="d-inline-flex w-100">
                        <Hide hide={current_step === 0}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="m-3"
                                onClick={handleBack}
                            >
                                {getLabel("back")}
                            </Button>
                        </Hide>

                        <div className="mr-auto"/>

                            <Button
                                variant="contained"
                                color="primary"
                                className="m-3"
                                onClick={handleNext}
                            >
                                {getLabel(steps.length - 1 === current_step?"submit":"next")}
                            </Button>

                    </div>

                </Grid>

                {/*<Grid container>*/}
                {/*    <Grid item>*/}
                {/*        <Link href="#" variant="h6">*/}
                {/*            {getLabel("already_have_an_account?_sign_in")}*/}
                {/*        </Link>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </div>
        </Container>
    );
}