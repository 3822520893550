import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../../assets/scss/Static.scss';
import {CustomTextField} from "../../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../../components/FormHeader";
import {FormActionButtons} from "../../../../../components/FormActionButton";
import {CustomModal} from "../../../../../components/CustomModal";
import {CustomCheckBox} from "../../../../../components/CustomCheckBox";
import {DateField} from "../../../../../components/fields/DateField";
import {MaleFemaleCheckBox} from "../../../../../components/MaleFemaleCheckBox";
import {Hide} from "../../../../../components/Hide";
import {not_validate_value} from "../../../../../App";
import moment from "moment";
import {Loading} from "../../../../../components/Loading";
import {httpRequestWithAuth} from "../../../../../auth/axios_instance";
import {BasePriceListOfferURL} from "../../../../../urls";


export const PriceListOfferFormModal = props => {
    const [is_loading, setIsLoading] = useState(false);
    const [data, setData] = useState({is_active:true, ...props.default_data});
    const [helper_text, setHelperText] = useState({});

    const handleDateChange = (id, date) => {

        if (id === 'start_date' && moment(date).isAfter(data.end_date)) {
            setData({...data, [id]: date, end_date: date})
        } else {
            setData({...data, [id]: date})
        }
    };

    const handleFieldChange = (name, newValue) => {
        setData({...data, [name]: newValue})
    };

    const handleSaveForm = async () => {
        await setIsLoading(true);
        let price_list_offer = {
            ...data,
            start_date: !not_validate_value.includes(data.start_date) ? moment(data.start_date).format("YYYY-MM-DD") : null,
            end_date: !not_validate_value.includes(data.end_date) ? moment(data.end_date).format("YYYY-MM-DD") : null,
        };

        let Response;
        if (data.id === undefined) {
            Response = await httpRequestWithAuth(`${BasePriceListOfferURL}`, "POST", price_list_offer);
        } else {
            Response = await httpRequestWithAuth(`${BasePriceListOfferURL}${price_list_offer.id}/`,"PATCH", price_list_offer);
        }

        if (Response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(Response.error_feedback);
        }

        await setIsLoading(false);
    };


    return (
        <CustomModal
            open={props.open}
        >

            <Grid container spacing={2}>

                <FormHeader
                    title="pages.packages.form.header"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12}>
                        <CustomTextField
                            required
                            error={helper_text.name !== undefined}
                            helper_text={helper_text.name}
                            name="name"
                            label="pages.packages.package_name"
                            value={data.name}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DateField
                            name="start_date"
                            error={helper_text.start_date !== undefined}
                            helper_text={helper_text.start_date}
                            label="start_date"
                            value={data.start_date}
                            onChangeHandle={handleDateChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DateField
                            name="end_date"
                            error={helper_text.end_date !== undefined}
                            helper_text={helper_text.end_date}
                            label="end_date"
                            min_date={data.start_date}
                            value={data.end_date}
                            onChangeHandle={handleDateChange}
                        />
                    </Grid>
                    <Hide hide={props.is_rent}>
                        <Grid item xs={12}>
                            <MaleFemaleCheckBox
                                onChangeHandle={handleFieldChange}
                                for_males={data.for_males}
                                male_name="for_males"
                                for_females={data.for_females}
                                female_name="for_females"
                                disabled={props.disable_gender}
                            />
                        </Grid>
                    </Hide>


                    <Grid item xs={12}>
                        <CustomCheckBox
                            value={data.is_active}
                            name='is_active'
                            onChangeHandle={handleFieldChange}
                            label="is_active"
                        />
                    </Grid>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                    />
                </Hide>
            </Grid>
        </CustomModal>
    );
};