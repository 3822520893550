import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {InputFile} from "../../../components/InputFile";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {permissions_data} from "../../../auth/permission_data";
import {ConfirmationContext} from "../../../store/use_context";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseBannersURL} from "../../../urls";
import {CustomCheckBox} from "../../../components/CustomCheckBox";
import {sub_directory} from "../../../App";
import {CreateBanner, UpdateBanner} from "./bannses_apis";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {getLabel} from "../../../localization/MainLocalization";


export const BannerForm = props => {
    const banner_id = props.banner_id;
    const {setConfirmData} = useContext(ConfirmationContext);

    const have_permission = (banner_id !== 'new' && Permissions.checkAllPermissions([permissions_data.banners.change])) ||
        (banner_id === 'new' && Permissions.checkAllPermissions([permissions_data.banners.add]));

    const [data, setData] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(true);
    const [edit_data, setEditData] = useState(false);


    useEffect(() => {
        setEditData(banner_id === 'new');
    }, [banner_id]);

    useEffect(() => {
        const fetchData = async () => {
            if (banner_id !== 'new') {
                let response = await httpRequestWithAuth(`${BaseBannersURL}${banner_id}/`, "GET");

                if (response.status) {
                    setData(response.data)
                } else {
                    props.history.push("/notfound");
                }
            }

            setIsLoading(false)
        }

        fetchData();
    }, [banner_id]);


    const handleInfoFieldChange = (name, newValue) => {
        if (have_permission) {
            if (name === "image") {
                setData({...data, [name]: newValue, display_image: URL.createObjectURL(newValue)});
            } else {
                setData({...data, [name]: newValue});
            }
        }
    };

    const handleSaveButton = async () => {
        await setIsLoading(true);
        let response;

        if (banner_id === "new") {
            response = await CreateBanner(data);
        } else {
            response = await UpdateBanner(banner_id, data);
        }

        await setHelperText({});
        if (!response.status) {
            setEditData(false);
            await setHelperText(response.error_feedback);
        } else {
            await handleBackButton();
        }
        await setIsLoading(false)
    };


    const handleBackButton = () => {
        props.history.push(`${sub_directory}banners`)
    }


    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this Banner ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let response = await httpRequestWithAuth(`${BaseBannersURL}${banner_id}/`, "DELETE");
        if (response.status) {
            await handleBackButton()
        } else {
            setHelperText(response.error_feedback)
        }
    }

    const handleEditAction = () => {
        setEditData(!edit_data);
    }

    return (
        <>
            <Hide hide={!is_loading}><Loading/></Hide>

            <Hide hide={is_loading}>
                <Hide hide={!have_permission || banner_id === 'new'}>
                    <Grid item xs={12} className="text-end">
                        <Button
                            size="small"
                            startIcon={edit_data ? <CloseIcon/> : <EditIcon/>}
                            className={`text-white ${edit_data ? "bg-danger" : "primary-bg"}`}
                            onClick={handleEditAction}
                        >
                            {edit_data ? getLabel("cancel_edit") : getLabel("edit")}
                        </Button>
                    </Grid>
                </Hide>

                <Grid item xs={12} className='border m-2 p-4'>
                    <div className='w-50 m-auto text-center'>
                        <img src={data.display_image} className='w-75' alt=""/>
                        <Hide hide={!edit_data}>
                            <InputFile
                                name='image'
                                onChangeHandle={handleInfoFieldChange}
                                label="image"
                            />
                        </Hide>
                    </div>
                    <FormHelperText className="text-danger text-center"> {helper_text.image} </FormHelperText>
                </Grid>


                <Grid item xs={12} md={6}>
                    <CustomTextField
                        viewOnly={!edit_data}
                        required
                        helper_text={helper_text.title}
                        error={helper_text.title !== undefined}
                        value={data.title}
                        onChangeHandle={handleInfoFieldChange}
                        name="title"
                        label="title"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        viewOnly={!edit_data}
                        helper_text={helper_text.url}
                        error={helper_text.url !== undefined}
                        value={data.url}
                        onChangeHandle={handleInfoFieldChange}
                        name="url"
                        label="url"
                    />
                </Grid>


                <Grid item xs={12}>
                    <CustomCheckBox
                        viewOnly={!edit_data}
                        value={data.is_active}
                        name='is_active'
                        onChangeHandle={handleInfoFieldChange}
                        label="is_active"
                    />
                </Grid>


                {banner_id !== 'new' && (
                    <>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        label="created_by"
                                        disabled
                                        value={data.created_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_created"
                                        value={data.date_created}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="modified_by"
                                        value={data.modified_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_modified"
                                        value={data.date_modified}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                    </>
                )}


                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowDelete={Permissions.checkAllPermissions([permissions_data.banners.delete]) && banner_id !== 'new'}
                    deleteAction={handleDeleteButton}
                    allowBack={true}
                    backAction={handleBackButton}
                    allowSave={have_permission && edit_data}
                    saveAction={handleSaveButton}
                />

            </Hide>

        </>

    );
}
