import React, {useEffect, useState} from "react";
import MaterialTable, { MTableToolbar } from 'material-table';

export const CustomMaterialTable = props =>{
    // const [columns, setColumns] = useState(props.normalColumns);
    //
    // useEffect(()=>{
    //     if (){
    //         setColumns(props.mobileColumns);
    //     }else{
    //         setColumns(props.normalColumns);
    //     }
    // }, [window.innerWidth])
    //
    return(

        <MaterialTable
            {...props}

            options={{
                ...props.options,
                header: !(props.hideHeaderOnMobileView && window.innerWidth < 850) || props.options.header
            }}

            components={{
                Toolbar: props => (
                        <MTableToolbar {...props} classes={{ root: "p-0 m-0" }}/>
                )
            }}
            columns={window.innerWidth < 850? props.mobileColumns: props.normalColumns}
        />
    )
}
