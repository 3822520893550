import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import {getLabel} from "../localization/MainLocalization";

export const GenderRadioButton = props => {
    return (
        <FormControl fullWidth>
            <FormLabel
                       error={props.error}
                       className="text-start"
            >

                {getLabel(props.label)}
                <FormHelperText component='span'
                                              className='text-danger '>{props.required ? "*" : ""} {props.helper_text}</FormHelperText>
            </FormLabel>

            <RadioGroup
                name={props.name}
                value={props.value || ""}
                onChange={(e) => {
                    if (!props.viewOnly){
                        props.onChangeHandle(e.target.name, e.target.value)
                    }
                }}
                row
            >
                <FormControlLabel value="Male" control={<Radio/>} label={getLabel("male")}/>
                <FormControlLabel value="Female" control={<Radio/>} label={getLabel("female")}/>
            </RadioGroup>
        </FormControl>
    );
};