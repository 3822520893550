import {blueGrey, red} from "@material-ui/core/colors";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";


export const MainTheme = dir =>createMuiTheme({
    direction: dir,
    typography: {
        fontFamily: [
            "Roboto",
            "Trebuchet MS",
            "Nunito",
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});


export const ThemeOne = dir =>createMuiTheme({
    ...MainTheme(dir),
    palette: {
        primary: {
            main: '#3f51b5',
        },
    },
});

export const ThemeTwo = createMuiTheme({
    ...MainTheme,
    palette: {
        primary: red,
        secondary: {
            main: red[500],
        },
    },
});
