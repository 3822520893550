import {createContext} from 'react';

export const AlertContext = createContext("")

export const LanguageContext = createContext("")

export const BreadcrumbContext = createContext("")

export const SideMenuToggleContext = createContext("")

export const ConfirmationContext = createContext("")

export const CashTransferContext = createContext("")
