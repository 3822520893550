import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import {getLabel} from "../../localization/MainLocalization";

export const CustomSelectField = props => {
    return (
        <FormControl variant="outlined" fullWidth
                     disabled={props.viewOnly}
                     className={props.disabled ? "disabled-field" : ""}
                     error={props.error}>

            <InputLabel>{getLabel(props.label)}<span className="text-danger">{props.required ? "*" : ""}</span></InputLabel>

            <Select
                value={props.value || ""}
                label={getLabel(props.label)}

                inputProps={{
                    name: props.name,
                }}
                className={"text-start"}
            >
                <MenuItem value={null} onClick={()=>props.onChangeHandle(props.name, null)}>
                    <em>{getLabel('none')}</em>
                </MenuItem>
                {props.options.map((Option, Index) => {
                    return (
                        <MenuItem key={Index} onClick={()=>props.onChangeHandle(props.name, Option)} value={props.valueKey?Option[props.valueKey]:Option}> {props.titleKey?Option[props.titleKey]:Option}</MenuItem>
                    );
                })}
            </Select>
            <FormHelperText>{props.helper_text}</FormHelperText>
        </FormControl>
    )
};
