import React, {useContext, useEffect, useRef, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization, material_table_style} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import Grid from "@material-ui/core/Grid";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseClassSessionClientsAttendeesURL} from "../../../urls";
import Chip from "@material-ui/core/Chip";
import {Hide} from "../../../components/Hide";
import {AttendeeModel} from "./components/AttendeeModel";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {dir, not_validate_value} from "../../../App";
import {AlertContext} from "../../../store/use_context";
import {ViewRegisterClasses} from "./components/ViewRegisterClasses";
import Typography from "@material-ui/core/Typography";
import {CustomMaterialTable} from "../../../components/CustomMaterialTable";


export const ClassesClientsAttendees = props => {
    const tableRef = useRef();
    const class_id = props.class_id
    const is_modal_view = props.modalView || false
    const [data, setData] = useState([]);
    const {setAlert} = useContext(AlertContext);
    const [multi_classes_data, setMultiClassesData] = useState({open: false, details: []});

    const [mobile_columns] = useState([
        {
            title: getLabel("client"), render: rowData => (
                <>
                    <Grid container className="align-items-center text-center" spacing={1}>
                        <Grid item xs={12}>
                            <div className="d-inline-flex">
                                <Typography gutterBottom variant="subtitle2" className="font-weight-bold mr-1"
                                            component="h6">
                                    {rowData.client_name}
                                </Typography>
                                {rowData.is_cancelled ? (
                                    <Chip size="small" color={rowData.is_cancelled ? "secondary" : ""}
                                          label={getLabel("unregistered")}/>
                                ) : (
                                    <Chip size="small"
                                          color={rowData.is_present ? "primary" : !not_validate_value.includes(rowData.is_present) ? "secondary" : ""}
                                          label={getLabel(rowData.is_present ? "present" : !not_validate_value.includes(rowData.is_present) ? "absent" : "TBA")}/>
                                )}
                            </div>
                        </Grid>

                        <Hide hide={rowData.is_present && !not_validate_value.includes(rowData.is_present)}>
                            <Grid item xs={12}>
                                <Button className="mr-1 ml-1" size="small"
                                        variant="outlined"
                                        fullWidth
                                        color={rowData.is_present ? "secondary" : "primary"}
                                        onClick={() => handleBtnAction(rowData, true, false)}
                                >
                                    {getLabel("present")}
                                </Button>
                            </Grid>
                        </Hide>

                        <Hide hide={!rowData.is_present && !not_validate_value.includes(rowData.is_present)}>
                            <Grid item xs={12}>
                                <Button className="mr-1 ml-1" size="small"
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        onClick={() => handleBtnAction(rowData, false, false)}
                                >
                                    {getLabel("absent")}
                                </Button>
                            </Grid>
                        </Hide>

                        <Hide
                            hide={!rowData.is_cancelled && !Permissions.checkPermission(permissions_data.class_attendance.cancel_attendance)}>
                            <Grid item xs={12}>
                                <Button className="mr-1 ml-1" size="small"
                                        variant="outlined"
                                        fullWidth
                                        color={rowData.is_cancelled ? "primary" : "secondary"}
                                        onClick={() => handleBtnAction(rowData, false, !rowData.is_cancelled)}
                                >
                                    {getLabel(rowData.is_cancelled ? "register" : "unregister")}
                                </Button>
                            </Grid>
                        </Hide>

                    </Grid>
                </>
            )
        },
    ]);

    const [normal_columns] = useState([
        {title: getLabel("client"), field: 'client_name'},
        {
            title: getLabel("status"),
            render: rowData => {
                return rowData.is_cancelled ?(
                        <Chip size="small" color={rowData.is_cancelled ? "secondary" : ""}
                              label={getLabel("unregistered")}/>
                    ) : (
                        <Chip size="small"
                              color={rowData.is_present ? "primary" : !not_validate_value.includes(rowData.is_present) ? "secondary" : ""}
                              label={getLabel(rowData.is_present ? "present" : !not_validate_value.includes(rowData.is_present) ? "absent" : "TBA")}/>
                    )}

        },

        {title: getLabel('added_by'), field: 'added_by_name', hidden: is_modal_view},
        {title: getLabel('cancelled_by'), field: 'cancelled_by_name', hidden: is_modal_view},
        {title: getLabel('cancel_reason'), field: 'cancel_reason', hidden: is_modal_view},
        {
            hidden: !Permissions.checkAllPermissions([permissions_data.class_attendance.change]),
            render: rowData => {
                return (
                    <div className="d-inline-flex">
                        <Hide hide={rowData.is_present && !not_validate_value.includes(rowData.is_present)}>
                            <Button className="mr-1 ml-1"  size="small"
                                    variant="outlined"
                                    color={rowData.is_present ? "secondary" : "primary"}
                                    onClick={()=> handleBtnAction(rowData, true, false)}
                            >
                                {getLabel( "present")}
                            </Button>
                        </Hide>

                        <Hide hide={!rowData.is_present && !not_validate_value.includes(rowData.is_present)}>
                            <Button className="mr-1 ml-1"  size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={()=> handleBtnAction(rowData, false, false)}
                            >
                                {getLabel("absent")}
                            </Button>
                        </Hide>


                        <Hide hide={!rowData.is_cancelled && !Permissions.checkPermission(permissions_data.class_attendance.cancel_attendance)}>
                        <Button className="mr-1 ml-1"  size="small"
                                variant="outlined"
                                color={rowData.is_cancelled ? "primary" : "secondary"}
                                onClick={()=> handleBtnAction(rowData, false, !rowData.is_cancelled)}
                        >
                            {getLabel(rowData.is_cancelled ? "register" : "unregister")}
                        </Button>
                        </Hide>

                    </div>
                )
            }
        }
    ]);

    const [formOpen, setFormOpen] = useState(false)
    const [formData, setFormData] = useState({})
    const [is_loading, setLoading] = useState(false)


        const handleBtnAction = async (rowData, is_present, is_cancelled) => {
            await setLoading(true);
            let temp_data = {
                ...rowData,
                is_present: is_present,
                is_cancelled: is_cancelled
            }

            let response = await httpRequestWithAuth(`${BaseClassSessionClientsAttendeesURL}${temp_data.id}/`, "PATCH", temp_data)

            if (response.status) {
                await listAttendance();
            } else {
                if (response.error_feedback.multi_classes_error) {
                    await setMultiClassesData({
                        open: true,
                        details: response.error_feedback.details,
                        info: response.error_feedback.info,
                        action: ()=> handleBtnAction(rowData, is_present, is_cancelled)
                    })
                } else {
                    setAlert(response.error_feedback.non_field_errors)
                }
            }

            await setLoading(false);
        };

    const listAttendance = async () => {
        let response = await httpRequestWithAuth(BaseClassSessionClientsAttendeesURL, "GET", null, {class_session: class_id})
        if (response.status) {
            await setData(response.data);
        }
    }


    const handleModalOpen = (data) => {
        setFormOpen(true)
        setFormData(data)
    };

    const handleModalClose = async () => {
        await listAttendance();
        await setFormOpen(false);
        await setFormData({});
    };

    useEffect(() => {
        const fetchData = async ()=>{
        await setLoading(true);
        listAttendance();
        await setLoading(false);
        }

        fetchData();
    }, [])

    const handleRegisterClassesModalClose = () => {
        setMultiClassesData({open: false, details: []})
    }

    return (
        <Grid item xs={12}>

            <Hide hide={!multi_classes_data.open}>
                <ViewRegisterClasses
                    {...props}
                    open={multi_classes_data.open}
                    details={multi_classes_data.details}
                    handleModalClose={handleRegisterClassesModalClose}
                    addAttendance={multi_classes_data.action}
                    info={multi_classes_data.info}
                    for="Client"
                />
            </Hide>

            <Hide hide={!formOpen}>
                <AttendeeModel
                    {...props}
                    open={formOpen}
                    handleModalClose={handleModalClose}
                    data={formData}
                    class_id={class_id}
                    for="Client"
                />
            </Hide>

            <CustomMaterialTable
                tableRef={tableRef}
                mobileColumns={mobile_columns}
                normalColumns={normal_columns}                isLoading={is_loading}
                data={data}
                editable={Permissions.checkPermission(permissions_data.class_attendance.delete) && {
                    onRowDelete: async rowData => {
                        let response = await httpRequestWithAuth(`${BaseClassSessionClientsAttendeesURL}${rowData.id}/`, "DELETE");
                        if (response.status) {
                            await listAttendance();
                        } else {
                            setAlert(response.error_feedback.non_field_errors)
                        }
                    }
                }}

                actions={[
                    {
                        hidden: !Permissions.checkPermission(permissions_data.class_attendance.add),
                        onClick: () => handleModalOpen({}),
                        isFreeAction: tableRef,
                        icon: () => (
                            <Button size="small" variant="contained" className='m-1' color='primary'
                                    style={{minWidth: "125px"}}
                                    startIcon={<AddIcon/>}>
                                {getLabel("add_client")}
                            </Button>
                        )
                    },
                ]}

                options={{
                    actionsColumnIndex: -1,
                    searchFieldAlignment: 'left',
                    showTitle: false,
                    paging: false,
                    rowStyle: {
                        direction: "ltr",
                    },
                    actionsCellStyle: {
                        direction: dir
                    },
                }}
                {...material_table_style}
                {...material_table_localization()}
            />
        </Grid>
    );

}
