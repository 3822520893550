import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {not_validate_value, sub_directory} from "../../../App";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseClassWorkoutURL, BaseClientURL} from "../../../urls";
import {TrainingAreaAutoComplete} from "../../../components/fields/TrainingAreaAutoComplete";
import {LocationsAutoComplete} from "../../../components/fields/LocationsAutoComplete";
import {DateField} from "../../../components/fields/DateField";
import {CustomRadioGroup} from "../../../components/CustomRadioGroup";
import {ClassSessionsAutoComplete} from "../../../components/fields/ClassSessionsAutoComplete";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import {WorkoutSectionFormModal} from "./components/WorkoutSectionFormModal";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {ConfirmationContext} from "../../../store/use_context";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {DisabledTextField} from "../../../components/fields/DisabledField";


export const WorkoutForm = (props) => {
    const workout_id = props.workout_id
    const {setConfirmData} = useContext(ConfirmationContext)

    const view_options = [
        {title: "all_day", value: "true"},
        {title: "class_sessions", value: "false"},
    ]

    const section_columns = [
        {title: getLabel("header"), field: "header"},
        {title: getLabel("content"), field: "content", cellStyle:{
                whiteSpace: 'pre-line',
            }}
    ]

    const have_permission = ((Permissions.checkPermission(permissions_data.workout.add) && workout_id === 'new') ||
        Permissions.checkPermission(permissions_data.workout.change));

    const [helper_text, setHelperText] = useState({})
    const [open_section_modal, setOpenSectionModal] = useState(false)
    const [section_modal_data, setSectionModalData] = useState({})
    const [is_loading, setIsLoading] = useState(true)
    const [is_loading_section, setIsLoadingSection] = useState(true)
    const [data, setData] = useState({})
    const [section_data, setSectionData] = useState([])
    const [edit_data, setEditData] = useState(false);

    useEffect(()=>{
        setEditData(workout_id === 'new' && have_permission);
    }, [workout_id]);

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            if (workout_id !== 'new') {
                let response = await httpRequestWithAuth(`${BaseClassWorkoutURL}${workout_id}/`, "GET");

                if (response.status) {
                    setData(response.data)

                    if (!not_validate_value.includes(response.data.sections)){
                        let temp_sections = JSON.parse(response.data.sections);
                        temp_sections.map((Section, Index) =>Section.index = Index);
                        setSectionData(temp_sections);
                    }
                } else {
                    props.history.push("/notfound");
                }

            } else {
                // setData(props.location.state || {})

                if (!not_validate_value.includes(props.copiedSectionsData)){
                    let temp_sections = JSON.parse(props.copiedSectionsData);
                    temp_sections.map((Section, Index) =>Section.index = Index);
                    setSectionData(temp_sections);
                }
            }

            await setIsLoading(false)
        }

        fetchData();
    }, [workout_id, edit_data])


    const handleFieldChange = (name, newValue) => {
        if (have_permission && edit_data) {
            setData({...data, [name]: newValue})
        }
    };

    const onLocationChange = async (name, newValue) => {
        if (have_permission && edit_data) {
            await setData({...data, [name]: newValue, training_area: "", class_sessions: []})
        }
    }

    const onTrainingAreaAndDateChange = async (name, newValue) => {
        if (have_permission && edit_data) {
            await setData({...data, [name]: newValue, class_sessions: []})
        }
    }

    const handleModalClose = async () => {
        await setSectionModalData({})
        await setOpenSectionModal(false)
        await setIsLoadingSection(false)
        await setHelperText({...helper_text, header: undefined});

    }

    const handleModalOpen = async (data) => {
        await setSectionModalData(data)
        await setOpenSectionModal(true)
        await setIsLoadingSection(true)
    }

    const handleSaveButton = async () => {
        setIsLoading(true)

        let workout_data = {...data,sections: JSON.stringify([...section_data])}

        let response;
        if (workout_id === 'new') {
            response = await httpRequestWithAuth(BaseClassWorkoutURL, "POST", workout_data);
        } else {
            response = await httpRequestWithAuth(`${BaseClassWorkoutURL}${workout_id}/`, "PUT", workout_data);
        }

        await setHelperText({});
        if (response.status) {
            handleBackButton()
        } else {
            await setHelperText(response.error_feedback);
        }

        setIsLoading(false)
    };

    const handleBackButton = () => {
        props.history.push(`${sub_directory}workouts`)
    }


    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this Workout ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let response = await httpRequestWithAuth(`${BaseClassWorkoutURL}${workout_id}/`, "DELETE");

        if (response.status) {
            handleBackButton()
        }else{
            await setHelperText(response.error_feedback)

        }
    }

    const handleSaveForm = async (data) =>{
        let check_data = true;
        await section_data.map((Section, index)=>{
            if (data.index !== index && Section.header === data.header){
                setHelperText({...helper_text, header: "this field must be unique"});
                check_data = false;
            }
        })

        if (not_validate_value.includes(data.header)){
            setHelperText({...helper_text, header: "this field cant't be empty"});
            check_data = false;
        }

        if (check_data){
            let new_data = section_data;
            if (not_validate_value.includes(data.index)){
                new_data = [...section_data, {header:data.header, content:data.content || ""}]
            }else{
                new_data[data.index].header = data.header
                new_data[data.index].content = data.content
            }
            await new_data.map((Section, index)=>{
                Section.index = index;
            })
            await setSectionData(new_data);
            await handleModalClose();
        }
    }

    const moveUp = (index)=>{
        if (have_permission && edit_data) {
            if (index === 0) return;
            let temp_data = [
                ...section_data.slice(0, index - 1),
                section_data[index],
                section_data[index - 1],
                ...section_data.slice(index + 1)
            ]

            temp_data.map((Data, Index) => {
                Data.index = Index
            })
            setSectionData(temp_data);
        }
    }

    //
    // const moveDown = (index)=>{
    //     if (index === section_data.length -1)return ;
    //     let temp_data = [
    //         ...section_data.slice(0, index),
    //         section_data[index + 1],
    //         section_data[index],
    //         ...section_data.slice(index + 2)
    //     ]
    //
    //     temp_data.map((Data,Index)=>{
    //         Data.index = Index
    //     })
    //     setSectionData(temp_data);
    // }


    const handleEditAction = () => {
        setEditData(!edit_data);
    }

    return (
        <>
            <Hide hide={!is_loading}><Loading/></Hide>


            <Hide hide={is_loading}>

                <Hide hide={!open_section_modal}>
                    <WorkoutSectionFormModal
                        open={open_section_modal}
                        handleModalClose={handleModalClose}
                        data={section_modal_data}
                        workout_id={workout_id}
                        helper_text={helper_text}
                        handleSaveForm={handleSaveForm}
                    />
                </Hide>

                <Hide hide={!have_permission || workout_id === 'new'}>
                    <Grid item xs={12} className="text-end">
                        <Button
                            size="small"
                            startIcon={edit_data ? <CloseIcon/> : <EditIcon/>}
                            className={`text-white ${edit_data ? "bg-danger" : "primary-bg"}`}
                            onClick={handleEditAction}
                        >
                            {edit_data ? getLabel("cancel_edit") : getLabel("edit")}
                        </Button>
                    </Grid>
                </Hide>

                <Grid item xs={12} md={6}>
                    <LocationsAutoComplete
                        required
                        active
                        location={data.location}
                        viewOnly={!edit_data}
                        helper_text={not_validate_value.includes(data.location) ? helper_text.training_area : ""}
                        error={helper_text.training_area !== undefined && not_validate_value.includes(data.location)}
                        name='location'
                        label="location"
                        onChangeHandle={onLocationChange}
                        value={data.location}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TrainingAreaAutoComplete
                        required
                        byLocation
                        viewOnly={!edit_data}
                        location={data.location}
                        helper_text={helper_text.training_area}
                        error={helper_text.training_area !== undefined}
                        value={data.training_area}
                        onChangeHandle={onTrainingAreaAndDateChange}
                        name="training_area"
                        label="training_area"
                    />
                </Grid>

                <Grid item xs={12}>
                    <DateField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.date}
                        error={helper_text.date !== undefined}
                        name="date"
                        label="date"
                        value={data.date}
                        onChangeHandle={onTrainingAreaAndDateChange}
                    />
                </Grid>


                <Grid item xs={12}>
                    <CustomRadioGroup
                        label="view_for"
                        name="all_class_sessions"
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.all_class_sessions}
                        error={helper_text.all_class_sessions !== undefined}
                        value={data.all_class_sessions + ""}
                        onChangeHandle={handleFieldChange}
                        options={view_options}
                    />
                </Grid>

                <Grid item xs={12}>
                    <ClassSessionsAutoComplete
                        disabled={data.all_class_sessions + "" !== "false"}
                        multiple
                        byTrainingArea
                        training_area={data.training_area}
                        byDate
                        date={data.date}
                        viewOnly={!edit_data}
                        error={helper_text.class_sessions !== undefined}
                        helper_text={helper_text.class_sessions}
                        name="class_sessions"
                        label="class_sessions"
                        value={data.class_sessions}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MaterialTable
                        style={{padding: '0.5rem', boxShadow: "none", marginBottom: '1rem'}}
                        is_loading={is_loading_section}
                        columns={section_columns}
                        data={section_data}
                        editable={edit_data && {
                            onRowDelete: async rowData => {
                                let new_data = [...section_data];
                                await new_data.splice(rowData.index, 1);
                                await setSectionData(new_data);
                            }
                        }}

                        actions={[
                            {
                                icon: () => (
                                    <Button key="0" size="small" variant="contained" className='mt-2'
                                            color='primary'
                                            startIcon={<AddIcon/>}>
                                        <Typography variant="body1">{getLabel('add_section')}</Typography>
                                    </Button>
                                ),
                                isFreeAction: true,
                                onClick: () => handleModalOpen({}),
                                hidden: !edit_data
                            },
                            // {
                            //     icon: ()=><ArrowDownwardOutlinedIcon/>,
                            //     hidden: true,
                            //     tooltip: getLabel('move_down'),
                            //     onClick: (event, rowData) => moveDown(rowData.index)
                            // },
                            {
                                icon: ()=><ArrowUpwardOutlinedIcon/>,
                                tooltip: getLabel('move_up'),
                                onClick: (event, rowData) => moveUp(rowData.index),
                                hidden: !edit_data
                            },
                            {
                                icon: 'edit',
                                tooltip: getLabel('edit_section'),
                                onClick: (event, rowData) => handleModalOpen(rowData),
                                hidden: !edit_data
                            },
                        ]}

                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false,
                            showTitle: false
                        }}

                        {...material_table_localization()}
                    />
                </Grid>


                {workout_id !== 'new' && (
                    <>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="created_by"
                                        value={data.created_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_created"
                                        value={data.date_created}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="modified_by"
                                        value={data.modified_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_modified"
                                        value={data.date_modified}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                    </>
                )}

                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowDelete={Permissions.checkAllPermissions([permissions_data.workout.delete]) && workout_id !== 'new'}
                    deleteAction={handleDeleteButton}
                    allowBack={true}
                    backAction={handleBackButton}
                    allowSave={edit_data}
                    saveAction={handleSaveButton}
                />


            </Hide>

        </>

    );
}
