import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import {getLabel} from "../localization/MainLocalization";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import {Hide} from "./Hide";

export const CustomDropDown = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <Button onClick={handleClick}>
                {props.readerItem}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                {props.options.map((Item, index) => (
                        <Hide hide={Item.disabled} key={index}>
                            <MenuItem onClick={()=>{
                                handleClose();
                                Item.action();
                            }}>
                                {getLabel(Item.label)}
                            </MenuItem>
                        </Hide>
                    )
                )}

            </Menu>

        </>
    );
}