import {
    BaseBannersURL,
} from "../../../urls";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {not_validate_value} from "../../../App";




export const CreateBanner = async (data) => {
    let url = BaseBannersURL;

    var bodyFormData = new FormData();
    Object.keys(data).map(Name=> {
        if (!not_validate_value.includes(data[Name])) {
            bodyFormData.append(Name, data[Name]);
        }
    })

    const feedback = await httpRequestWithAuth(url, "POST", bodyFormData)
    return feedback
};


export const UpdateBanner = async (id, data) => {
    let url = BaseBannersURL + id + "/";

    var bodyFormData = new FormData();
    Object.keys(data).map(Name=> {
        if (!not_validate_value.includes(data[Name])) {
            bodyFormData.append(Name, data[Name]);
        }
    })

    const feedback = await httpRequestWithAuth(url, "PATCH", bodyFormData)
    return feedback
};
