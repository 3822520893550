import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {BannerForm} from "./BannerForm";


export const BannerInfo = props => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [banner_id, setBannerId] = React.useState('new');


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };

    useEffect(()=>{
        const {state} = props.history.location;
        if (state !== undefined && state.id){
            setBannerId(state.id);
        }else{
            props.history.push(`/banners`);
        }
    },[])

    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match}/>*/}

            <Tabs
                value={currentTab}
                onChange={handleTabsChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label={getLabel("details")} id={0}/>
            </Tabs>

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <BannerForm
                        {...props}
                        banner_id={banner_id}
                    />
                </Hide>
            </Grid>
        </Paper>
    );
}