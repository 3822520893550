import axios from "axios";
import Permissions from "./permissions";
import {not_validate_value, setSubDirectory} from "../App";
import {BaseBackEndURL, BaseLoginURL, BaseMyProfileURL} from "../urls";
import moment from "moment";
import {createAxiosInstance, httpRequestWithAuth, httpRequestWithoutAuth} from "./axios_instance";

export const client_id = process.env.REACT_APP_CLIENT_ID;

export async function setLocalStorage (data){
    await localStorage.setItem("isAuth", "true");
    await localStorage.setItem('access_token', data.access_token);
    await localStorage.setItem('refresh_token', data.refresh_token);
    await localStorage.setItem('expires_in', moment().add(data.expires_in, 'second'));
}

async function loadData(data) {
    await setLocalStorage(data)
    await createAxiosInstance(data.access_token);
    await Permissions.setPermissions();

    let user_data = await httpRequestWithAuth(BaseMyProfileURL);

    if (user_data.status) {
        await setSubDirectory(user_data.data.sub_directory)
        await localStorage.setItem('sub_directory', user_data.data.sub_directory);
        await localStorage.setItem('username', user_data.data.username);
        await localStorage.setItem('short_name', user_data.data.short_name);
        await localStorage.setItem('username_id', user_data.data.id);
        await localStorage.setItem('profile_img', user_data.data.photo);
        await localStorage.setItem('is_entity_owner', user_data.data.is_entity_owner);
    }
}

class Auth {

    constructor() {
        if (localStorage.getItem("isAuth") == null) {
            localStorage.setItem("isAuth", "false");
        }
    }


    login = async (code) => {

        var bodyFormData = new FormData();
        bodyFormData.set("client_id", client_id);
        bodyFormData.append("grant_type", "authorization_code");
        bodyFormData.append("code", code);
        // bodyFormData.append("redirect_uri", "http://localhost:3000/notfound/");


        let feedback = await httpRequestWithoutAuth(BaseBackEndURL + "o/token/", "POST", bodyFormData);
        if (feedback.status){
            await loadData(feedback.data)
        }else {
            await localStorage.clear();
            await Permissions.removePermissions();
        }
        return feedback
    };


    revoke_token(){
        var bodyFormData = new FormData();
        bodyFormData.set("token", localStorage.getItem("access_token"));
        bodyFormData.set("client_id", client_id);

        axios.post(BaseBackEndURL + "o/revoke_token/",
            bodyFormData, {"Content-Type": 'multipart/form-data'}
        ).catch(error => {
            console.log('error ' + error);
        });

        localStorage.clear();
        Permissions.removePermissions();
        return {status:true}
    }

    logout() {
        this.revoke_token()
        window.location.href = BaseLoginURL()
    }

    isAuthenticated() {
        return localStorage.getItem("isAuth") === 'true';
    }

    async getRefreshToken(){
        var bodyFormData = new FormData();
        bodyFormData.set("client_id", client_id);
        bodyFormData.append("grant_type", "refresh_token");
        bodyFormData.append("refresh_token", localStorage.getItem("refresh_token"));

        return await httpRequestWithoutAuth(BaseBackEndURL + "o/token/", "POST", bodyFormData)
    }

    async checkAuthenticate() {
        if(!not_validate_value.includes(localStorage.getItem('expires_in')) && moment().add(3600, 'seconds').isAfter(moment(localStorage.getItem('expires_in')))){
            let response = await this.getRefreshToken()

            if (response.status) {
                await loadData(response.data);
            } else {
                await localStorage.clear();
                await Permissions.removePermissions();
            }

        }else if(!not_validate_value.includes(localStorage.getItem('access_token'))){
            await createAxiosInstance(localStorage.getItem("access_token"));
            await Permissions.setPermissions();
        }


        return {status: true}
    }
}

export default new Auth();
