import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import countryList from "react-select-country-list";
import {getLabel} from "../../localization/MainLocalization";
import {not_validate_value} from "../../App";

let countries = countryList().getLabels();

export const CountriesAutoComplete = props => {

    return (
        <Autocomplete
            ListboxProps={{className:"list-box-autocomplete"}}
            renderOption={(option) => (
                <span className="w-100 text-start">{option}</span>
            )}
            disabled={props.disabled || props.viewOnly}
            options={countries}
            getOptionLabel={option => option}
            value={not_validate_value.includes(props.value)? null: props.value + ""}
            renderInput={params => (
                <TextField variant="outlined" {...params}
                           label={<label>{getLabel(props.label)}<span
                               className='text-danger'>{props.required ? "*" : ""}</span></label>}
                           helperText={props.helper_text}
                           error={props.error}
                           fullWidth
                />
            )}
            onChange={(event, value) => {
                if (!props.viewOnly){
                value = value !== null ? value : "";
                props.onChangeHandle(props.name, value);
                }
            }}
        />
    );
};
