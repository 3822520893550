import React, {useRef, useState} from 'react';
import MaterialTable from "material-table";
import {instance} from "../../../auth/axios_instance";
import {BaseFinanceBankTransactionsURL} from "../../../urls";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";

export const BankTransactions = props => {

        const tableRef = useRef();
        const [search, setSearch] = useState("");

            const columns = [
                {title: getLabel('sender'), field:'sender'},
                {title: getLabel('action'), field:'action'},
                {title: getLabel('amount'), field:'amount'},
                {title: getLabel('current_balance'), field:'current_balance'},
                {title: getLabel('date'), field:'date_created'},
            ];



    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleSearchField = async (name, newValue) => {
        await setSearch(newValue)

        if (newValue.length >= 5 || newValue === '') {
            await reloadData();
        }
    };


        return (
            <>
                <Paper>
                    <TableToolbar
                        searchFieldName='search'
                        searchValue={search}
                        onSearchChangeHandle={handleSearchField}
                        searchAction={reloadData}
                        match={props.match}
                    />

                    <MaterialTable
                        tableRef={tableRef}
                        columns={columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let url = BaseFinanceBankTransactionsURL;

                                url += '?page=' + (query.page + 1);

                                if (search !== '') {
                                    url += '&search=' + search;
                                }

                                instance.get(url)
                                    .then(result => {
                                        resolve({
                                            data: result.data.results,
                                            page: result.data.page - 1,
                                            totalCount: result.data.total,
                                        })
                                    })
                            })
                        }

                        {...material_table_style}
                        {...material_table_options()}
                        {...material_table_localization()}

                    />

                </Paper>
            </>
        );
}