import React, {useRef, useState} from 'react';
import MaterialTable from 'material-table';
import Permissions from '../../../auth/permissions';
import {BaseStaffURL} from '../../../urls';
import Paper from '@material-ui/core/Paper';
import {TableToolbar} from '../../../components/TableToolbar';
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from '../../../components/Material_conf';
import {sub_directory, not_validate_value} from '../../../App';
import {getLabel} from "../../../localization/MainLocalization";
import Button from "@material-ui/core/Button";
import {StaffAccessPermissionsModal} from "./components/StaffAccessPermissionsModal";
import {Hide} from "../../../components/Hide";
import {instance} from "../../../auth/axios_instance";
import {permissions_data} from "../../../auth/permission_data";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export const Staff = props => {
    const tableRef = useRef();

    const columns = [
        {title: getLabel("name"), render: rowData=>(
                <Typography>
                    <Link href="" onClick={e => {
                        e.preventDefault();
                        viewStaff(rowData.id, rowData.full_name)
                    }}>
                        {rowData.full_name}
                    </Link>
                </Typography>
            ), cellStyle: {width: "30%"}},
        {title: getLabel('mobile'), field: 'mobile'},
        {title: getLabel('gender'), field: 'gender'},
        {title: getLabel('nationality'), field: 'nationality.name'},
        {title: getLabel('account'), field: 'username'},
        {title: getLabel('access_permissions'), render: rowData=>{return(
                <Hide hide={not_validate_value.includes(rowData.email)}>
                    <div className="d-inline-flex">
                        {Permissions.checkAllPermissions([permissions_data.staff_permission.view]) && (
                            <Button color="primary" size="small"
                                    onClick={()=> viewPermsModel(rowData.id)}>
                                {getLabel("show")}
                            </Button>)}
                    </div>
                </Hide>
            )}},
    ];
    const [search, setSearch] = useState("");
    const [staff_id, setStaffId] = useState("");


    const viewStaff = (id, tag) => {
        props.history.push({
            pathname: sub_directory +'staff/edit',
            state: {
                id: id,
                tag: tag
            }
        })
    };


    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };

    const viewPermsModel = async value => {
        await setStaffId(value)
    };

    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length > 4 || not_validate_value.includes(newValue)){
            reloadData();
        }
    };

    return (
        <>


            <Paper>
                <Hide hide={staff_id === ""}>
                    <StaffAccessPermissionsModal
                        open={staff_id !== ""}
                        staff_id={staff_id}
                        handleModalClose={()=> viewPermsModel("")}
                    />
                </Hide>

                <TableToolbar
                    // title={get_label('staff')}
                    searchFieldName='search'
                    searchValue={search}
                    onSearchChangeHandle={handleSearchField}
                    searchAction={reloadData}
                    match={props.match}
                    backbtn={true}
                    actions={
                        [
                            {
                                hide: !Permissions.checkAllPermissions([permissions_data.staff.add]),
                                onClick: () => props.history.push(
                                    {
                                        pathname: sub_directory + 'staff/new',
                                        state: {id: "new"}
                                    }
                                ),
                                label: "pages.staff.add_staff"
                            },
                        ]
                    }
                />

                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let url = BaseStaffURL;
                            url += '?page=' + (query.page + 1);

                            if (search !== '') {
                                url += '&search=' + search;
                            }

                            instance.get(url)
                                .then(result => {
                                    resolve({
                                        data: result.data.results,
                                        page: result.data.page - 1,
                                        totalCount: result.data.total,
                                    })
                                })
                        })
                    }

                    {...material_table_style}
                    {...material_table_options()}
                    {...material_table_localization()}
                />
            </Paper>
        </>
    );
}
