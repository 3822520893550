import {BasePermissionsURL} from "../urls";
import {httpRequestWithAuth, instance} from "./axios_instance";

export var  permissions ={};

class Permissions {

    setPermissions = async () => {
        let url = BasePermissionsURL;

        let feedback = await httpRequestWithAuth(url)
        if (feedback.status){
            feedback.data.map(Permission =>{
                permissions[Permission] = '1';
                return 0;
            })
        }else{
            await localStorage.clear();
        }

        return feedback
    };

    removePermissions = async () => {
        permissions = {}
    }


    checkPermission(perm) {
        return permissions[perm] !== undefined
    }

     checkAllPermissions(perms) {
         for (const Perm of perms) {
             if (permissions[Perm] === undefined){
                 return false
             }
         }
             return true
    }

     checkOnePermissions(perms) {
         for (const Perm of perms) {
             if (permissions[Perm] !== undefined){
                 return true
             }
         }
         return false
    }

}

export default new Permissions();