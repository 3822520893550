import React from "react";
import {TextField} from "@material-ui/core";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {not_validate_value} from "../../App";

export const DisabledTextField = props => {

    return (
        <TextField
            disabled
            autoComplete="off"
            variant="outlined"
            label={getLabel(props.label)}
            value={not_validate_value.includes(props.value)? "": props.value + ""}
            fullWidth
            className="disabled-field"
        />
    )
};