import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {getLabel} from "../localization/MainLocalization";
import "../assets/scss/Static.scss";

export const CustomCheckBox = props =>{
    return(
        <div className="text-start">
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.value || false}
                    color={props.color || "primary"}
                />
            }
            name={props.name}
            className={(props.disable ? " text-dark" : "")}
            onChange={e => {
                if (!props.viewOnly){
                props.onChangeHandle(props.name, e.target.checked)
                }

            }}
            label={<span>{getLabel(props.label)}<span
                className='text-danger margin-left-2'>{getLabel(props.note)}</span></span>}
        />
        </div>
    );
}