import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {ClientAutoComplete} from "../../../../components/fields/ClientAutoComplete";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseClassSessionAthletesAttendeesURL, BaseClassSessionClientsAttendeesURL} from "../../../../urls";
import moment from "moment";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {ViewRegisterClasses} from "./ViewRegisterClasses";
import {AthleteAutoComplete} from "../../../../components/fields/AthleteAutoComplete";


export const AttendeeModel = props => {
    const [data, setData] = useState(props.data || {});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(false);
    const [multi_classes_data, setMultiClassesData] = useState({open: false, details: []});

    const onChangeHandle = (name, value) => {
        let new_data = {...data, [name]: value};

        if (name === "is_present" && value === false){
            delete new_data[name]
        }

        setData(new_data)
    }


    const handleSaveForm = async () => {
        await setIsLoading(true);

        let response;
        let url ;
        if (props.for === "Client"){
            url = BaseClassSessionClientsAttendeesURL;
        }else{
            url = BaseClassSessionAthletesAttendeesURL;
        }

        if (data.id === undefined) {
            response = await httpRequestWithAuth(url, "POST", {
                class_session: props.class_id,
                date: moment().format("YYYY-MM-DD"), ...data
            })
        } else {
            response = await httpRequestWithAuth(`${url}${data.id}/`, "PATCH", {
                class_session: props.class_id,
                date: moment().format("YYYY-MM-DD"), ...data
            })
        }

        if (response.status) {
            await props.handleModalClose();
        } else {
            if (response.error_feedback.multi_classes_error) {
                await setMultiClassesData({
                    open: true,
                    details: response.error_feedback.details,
                    info: response.error_feedback.info
                })
            } else {
                await setHelperText(response.error_feedback)
            }
        }

        await setIsLoading(false);
    };

    const handleRegisterClassesModalClose = () => {
        setMultiClassesData({open: false, details: []})
    }

    return (
        <CustomModal
            open={props.open}
        >

            <Grid container spacing={2}>

                <Hide hide={!multi_classes_data.open}>
                    <ViewRegisterClasses
                        {...props}
                        open={multi_classes_data.open}
                        details={multi_classes_data.details}
                        handleModalClose={handleRegisterClassesModalClose}
                        addAttendance={handleSaveForm}
                        info={multi_classes_data.info}
                        for={props.for}
                    />
                </Hide>

                <FormHeader
                    title="attendee_form"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Hide hide={props.for !== "Client"}>
                    <Grid item xs={12}>
                        <ClientAutoComplete
                            required
                            name='client'
                            label="client"
                            onChangeHandle={onChangeHandle}
                            helper_text={helper_text.client}
                            error={helper_text.client !== undefined}
                            value={data.client}
                        />
                    </Grid>
                    </Hide>

                    <Hide hide={props.for !== "Athlete"}>
                        <Grid item xs={12}>
                            <AthleteAutoComplete
                                required
                                name='athlete'
                                label="athlete"
                                onChangeHandle={onChangeHandle}
                                helper_text={helper_text.athlete}
                                error={helper_text.athlete !== undefined}
                                value={data.athlete}
                            />
                        </Grid>
                    </Hide>

                    <Grid item xs={12} md={6}>
                        <CustomCheckBox
                            value={data.is_present}
                            name='is_present'
                            onChangeHandle={onChangeHandle}
                            label="is_present"
                        />
                    </Grid>


                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                    />
                </Hide>

            </Grid>
        </CustomModal>
    );
}
