import React, {useContext, useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import '../../../assets/scss/Static.scss';
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import moment from "moment";
import {
    CreateMemberships,
    DeleteMemberships,
    RetrieveMemberships,
    StartDateMemberships,
    UpdateMemberships
} from "./memberships_apis";
import Permissions from "../../../auth/permissions";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {ClientAutoComplete} from "../../../components/fields/ClientAutoComplete";
import {not_validate_value, sub_directory} from "../../../App";
import {Loading} from "../../../components/Loading";
import {ActionButtons} from "../../../components/ActionButton";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import {getLabel} from "../../../localization/MainLocalization";
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import {DateField} from "../../../components/fields/DateField";
import {CustomHeader} from "../../../components/CustomHeader";
import {Hide} from "../../../components/Hide";
import {ListLocationServicesFullData} from "../../setting/locations/location-apis";
import {LocationsAutoComplete} from "../../../components/fields/LocationsAutoComplete";
import {CustomTextArea} from "../../../components/fields/CustomTextArea";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseGenerateClassSessionsTimingURL} from "../../../urls";
import {SelectField} from "../../../components/fields/SelectField";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import {CustomDropDown} from "../../../components/CustomDropDown";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {AlertContext, ConfirmationContext} from "../../../store/use_context";
import {TransferModal} from "./components/TransferModal";
import {ChangeInvoiceDate} from "./components/ChangeInvoiceDateModal";
import {ChangeStartDate} from "./components/ChangeStartDateModal";
import {ChangePrice} from "./components/UpgradePriceModal";
import Button from "@material-ui/core/Button";
import {ChangeExtraDaysAndSessionsModal} from "./components/ChangeExtraDaysAndSessionsModal";
import {ChangePreferredTimeModal} from "./components/ChangePreferredTimeModal";
import {ChangeNotesModal} from "./components/ChangeNotesModal";
import {DisabledDateField} from "../../../components/fields/DisabledDateField";
import {ChangeDiscountModal} from "./components/ChangeDiscountModal";
// import Emoji from "react-emoji-render";


export const MembershipForm = props => {

    const {membership_id} = props;
    const have_permission = Permissions.checkPermission(permissions_data.membership.add) && membership_id === 'new';

    const {setAlert} = useContext(AlertContext);
    const {setConfirmData} = useContext(ConfirmationContext)

    const [membership, setMembership] = useState({
        balance: '0',
        paid: '0',
        original_price: '0',
        total_price: '0',
        discount: '0',
        extra_days: '0',
        extra_sessions: '0',
        invoice_date: new Date()
    });

    const [client_gender, setClientGender] = useState("")
    const [old_client_gender, setOldClientGender] = useState("")
    const [helper_text, setHelperText] = useState({})
    const [services, setServices] = useState([])
    const [price_lists, setPriceList] = useState([])
    const [prices, setPrices] = useState([])
    const [sessions_timing, setSessionsTiming] = useState([])
    const [is_loading, setIsLoading] = useState(true)
    const [open_transfer, setOpenTransfer] = useState(false)
    const [open_change_invoice_date_modal, setOpenChangeInvoiceDateModal] = useState(false)
    const [open_change_start_date_modal, setOpenChangeStartDateModal] = useState(false)
    const [open_upgrade_price_modal, setOpenUpgradePriceModal] = useState(false)
    const [open_change_extra_days_and_sessions, setOpenChangeExtraDaysAndSessions] = useState(false)
    const [open_change_preferred_time, setOpenChangePreferredTime] = useState(false)
    const [open_change_notes, setOpenChangeNotes] = useState(false)
    const [open_change_discount, setOpenChangeDiscount] = useState(false)
    const [location_default_option, setLocationDefaultOption] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true)
            const state = props.location.state || {}
            if (props.location.state !== undefined) {
                await setMembership({...membership, client: state.client_id})
            }

            await listMembershipData();

            await setIsLoading(false)
        }

        fetchData();
    }, [membership_id])


    useEffect(() => {
        const fetchServices = async () => {
            if (!not_validate_value.includes(membership.location)) {
                let services_temp = await listServiceData(membership.location)
                await setServices(services_temp)
            } else {
                setServices([])
            }
        }

        fetchServices()
    }, [membership.location])

    const handleNotesModal = async (reload = false) => {
        if (open_change_notes) {
            await setOpenChangeNotes(false);
            if (reload) {
                await setIsLoading(true);
                await listMembershipData();
                await setIsLoading(false);
            }

        } else {
            await setOpenChangeNotes(true)
        }
    }

  const handleDiscountModal = async (reload = false) => {
        if (open_change_discount) {
            await setOpenChangeDiscount(false);
            if (reload) {
                await setIsLoading(true);
                await listMembershipData();
                await setIsLoading(false);
            }

        } else {
            await setOpenChangeDiscount(true)
        }
    }

    const handlePreferredTimeModal = async (reload = false) => {
        if (open_change_preferred_time) {
            await setOpenChangePreferredTime(false);
            if (reload) {
                await setIsLoading(true);
                await listMembershipData();
                await setIsLoading(false);
            }

        } else {
            await setOpenChangePreferredTime(true)
        }
    }

    const handleExtraDaysAndSessionsModal = async (reload = false) => {
        if (open_change_extra_days_and_sessions) {
            await setOpenChangeExtraDaysAndSessions(false);
            if (reload) {
                await setIsLoading(true);
                await listMembershipData();
                await setIsLoading(false);
            }

        } else {
            await setOpenChangeExtraDaysAndSessions(true)
        }
    }

    const handleUpgradePriceModal = async (reload = false) => {
        if (open_upgrade_price_modal) {
            await setOpenUpgradePriceModal(false);
            if (reload) {
                await setIsLoading(true);
                await listMembershipData();
                await setIsLoading(false);
            }

        } else {
            await setOpenUpgradePriceModal(true)
        }
    }


    const handleChangeStartDateModal = async (reload = false) => {
        if (open_change_start_date_modal) {
            await setOpenChangeStartDateModal(false);
            if (reload) {
                await setIsLoading(true);
                await listMembershipData();
                await setIsLoading(false);
            }

        } else {
            await setOpenChangeStartDateModal(true)
        }
    }


    const handleChangeInvoiceDateModal = async (reload = false) => {
        if (open_change_invoice_date_modal) {
            await setOpenChangeInvoiceDateModal(false);
            if (reload) {
                await setIsLoading(true);
                await listMembershipData();
                await setIsLoading(false);
            }

        } else {
            await setOpenChangeInvoiceDateModal(true)
        }
    }

    const handleTransferModal = () => {
        if (open_transfer) {
            setOpenTransfer(false)
        } else if (!membership.is_full_paid) {
            setAlert('Membership should pay full amount to transfer to other client !!');
        } else {
            setOpenTransfer(true)
        }

    }

    const goToMembership = async (id, tag) => {
        await setIsLoading(true);
        await props.setMembershipId(id)
        await props.history.push({
            pathname: `${sub_directory}memberships/edit`,
            state: {
                id: id,
                tag: tag
            }
        });
    }

    const listServiceData = async (location) => {
        let services_data = [];
        if (location !== "") {
            let ListServicesRespone = await ListLocationServicesFullData(location, false, client_gender);
            if (ListServicesRespone.status) {
                ListServicesRespone.data.map((Service, index) => {
                    services_data.push({
                        index: index,
                        value: Service.id,
                        title: Service.name,
                        data: Service
                    });
                    return 0;
                });
            }
        }
        return services_data;
    };

    const listMembershipData = async () => {
        if (membership_id === 'new') {
            let temp_sessions_timing = []

            let response = await httpRequestWithAuth(BaseGenerateClassSessionsTimingURL, "GET");

            if (response.status) {
                response.data.map(Time_data => {
                    temp_sessions_timing.push(
                        {
                            title: `${Time_data.start_time} - ${Time_data.end_time}`,
                            value: `${Time_data.start_time} - ${Time_data.end_time}`
                        }
                    )
                })
                await setSessionsTiming(temp_sessions_timing)
            }
        }


        if (membership_id !== 'new') {

            let RetiveMembershipsResponse = await RetrieveMemberships(membership_id);
            if (RetiveMembershipsResponse.status) {
                props.setIsCancelledValue(RetiveMembershipsResponse.data.is_cancelled)
                props.setIsFullyPaidValue(RetiveMembershipsResponse.data.is_full_paid)
                props.setIsFullyRefundValue(RetiveMembershipsResponse.data.is_full_refund)
                props.setIsDoneValue(RetiveMembershipsResponse.data.is_done)
                props.setIsTransferredValue(RetiveMembershipsResponse.data.is_transeferred)

                await setMembership(RetiveMembershipsResponse.data)
            } else {
                props.history.push("/notfound");
            }
        }
    };

    const getStartAndEndDate = async (start_date = null, extra_days = null, client = null, price = null) => {
        if (start_date !== null) {
            start_date = moment(start_date).format("YYYY-MM-DD");
        }
        if (!not_validate_value.includes(client) && !not_validate_value.includes(price)) {
            let Response = await StartDateMemberships(start_date, client, price, extra_days, membership_id === 'new' ? null : membership_id);
            if (Response.status) {
                let data = Response.data;
                return {
                    original_start_date: data.start_date,
                    start_date: data.start_date,
                    end_date: moment(data.end_date).format("YYYY-MM-DD"),
                }
            }
        }
        return {original_start_date: null, start_date: null, end_date: ""}
    };

    const handleFieldChange = async (name, newValue) => {
        if (have_permission) {
            let membership_data = {...membership};

            if (name === 'extra_days' || name === 'extra_sessions') {
                newValue = parseInt(newValue === '' ? 0 : newValue);
                if (newValue < 0) {
                    newValue = 0;
                }
            }

            if (name === 'extra_days') {
                let start_date = await getStartAndEndDate(membership_data.start_date, newValue, membership_data.client, membership_data.price);
                membership_data = {...membership_data, ...start_date};
            }

            membership_data[name] = newValue;


            if (name === 'discount') {
                if (newValue === '') {
                    newValue = 0;
                } else {
                    newValue = parseFloat(newValue);
                }
                membership_data['discount'] = newValue;
                membership_data['total_price'] = Math.max(0, parseFloat(membership.original_price) - newValue);
            }

            await setMembership(membership_data);
        }
    };


    const handleDateChange = async (id, date) => {
        if (have_permission) {
            if (!not_validate_value.includes(membership.price) && "Invalid date" !== date) {
                let data = await getStartAndEndDate(date, membership.extra_days, membership.client, membership.price);
                await setMembership({...membership, ...data});
            } else {
                setMembership({
                    ...membership,
                    [id]: null,
                    end_date: ""
                })
            }
        }

    };


    const handleAutoCompleteValue = id => {
        let value = null;

        if (id === 'service') {
            services.map(Service => {
                if (Service.value === membership[id]) {
                    value = Service;
                }
                return 0;

            });
        }

        if (id === 'price_list') {
            price_lists.map(Price_list => {
                if (Price_list.value === membership[id]) {
                    value = Price_list;
                }
                return 0;

            });
        }

        if (id === 'price') {
            prices.map(Price => {
                if (Price.value === membership[id]) {
                    value = Price;
                }
                return 0;

            });
        }

        return value;
    };

    const getClientGender = async value => {
        await setOldClientGender(client_gender);
        await setClientGender(value)
    };

    const handlePriceChange = async newValue => {
        let is_null = newValue === null;
        return {
            original_price: is_null ? 0 : newValue.data.price, duration: is_null ? 0 : newValue.data.duration,
            price: is_null ? "" : newValue.value,
            end_date: is_null ? "" : membership.end_date,
            total_price: is_null ? 0 : Math.max(0, parseFloat(newValue.data.price) - parseFloat(membership.discount)),
            sessions: is_null ? 0 : newValue.data.sessions,
            freeze_days: is_null ? 0 : newValue.data.freeze_days,
            offer_price: is_null ? "" : not_validate_value.includes(newValue.data.offer_price) ? "" : newValue.data.offer_price,
        }
    };


    const handlePriceListChange = async newValue => {
        let price_data = await handlePriceChange(null);
        let temp_prices = [];
        if (newValue !== null) {
            await newValue.data.price.map(async Price => {
                await temp_prices.push({
                    value: Price.id,
                    title: Price.name,
                    data: Price
                });
                return 0;
            });
        }
        setPrices(temp_prices)
        return {
            ...price_data,
            price_list: newValue === null ? "" : newValue.value,
        };
    };


    const handleServiceChange = async newValue => {
        let price_list_data = await handlePriceListChange(null);
        let temp_price_lists = [];
        if (newValue !== null) {
            for (const Price_list of newValue.data.price_lists) {
                await temp_price_lists.push({
                    value: Price_list.id,
                    title: Price_list.name,
                    data: Price_list
                });

                if (Price_list.id === newValue.data.default_price_list &&
                    ((client_gender === "Male" && Price_list.for_males) ||
                        (client_gender === "Female" && Price_list.for_females))) {
                    price_list_data = await handlePriceListChange(temp_price_lists[temp_price_lists.length - 1]);
                }
            }
        }
        await setPriceList(temp_price_lists)
        return {
            ...price_list_data,
            service: newValue === null ? "" : newValue.value,
        }
    };

    const handleLocationChange = async (newValue) => {
        let service_data = await handleServiceChange(null);
        return {...service_data, location: newValue};
    }

    const handleAutoCompleteChange = async (name, newValue) => {
        if (have_permission) {
            let new_data = {}
            if (name === 'service' && (not_validate_value.includes(newValue) || membership.service !== newValue.value)) {
                new_data = await handleServiceChange(newValue)
            }

            if (name === 'price_list' && (not_validate_value.includes(newValue) || membership.price_list !== newValue.value)) {
                new_data = await handlePriceListChange(newValue)
            }

            if (name === 'price') {
                new_data = await handlePriceChange(newValue)
            }


            if (name === 'location' && (not_validate_value.includes(newValue) || membership.location !== newValue)) {
                new_data = await handleLocationChange(newValue)
            }

            if (name === 'client' && (not_validate_value.includes(newValue) || membership.client !== newValue)) {
                if (old_client_gender !== client_gender || not_validate_value.includes(newValue)) {
                    new_data = await handleLocationChange("")
                }
                new_data[name] = newValue;
            }

            new_data = {...membership, ...new_data}

            if (['client', 'price'].includes(name)) {
                let start_date = await getStartAndEndDate(new_data.start_date, new_data.extra_days, new_data.client, new_data.price);
                new_data = {...new_data, ...start_date}
            }


            await setMembership(new_data);
        }
    };


    const handleSaveButton = async () => {
        await setIsLoading(true);

        if (have_permission) {
            let membership_data = {
                ...membership,
                invoice_date: not_validate_value.includes(membership.invoice_date) ? null : moment(membership.invoice_date).format('YYYY-MM-DD'),
                start_date: not_validate_value.includes(membership.start_date) ? null : moment(membership.start_date).format('YYYY-MM-DD'),
                end_date: not_validate_value.includes(membership.end_date) ? null : moment(membership.end_date).format('YYYY-MM-DD'),
                origin_end_date: not_validate_value.includes(membership.start_date) ? null : moment(membership.start_date).add(parseInt(membership.extra_days) + parseInt(membership.duration), 'days').format('YYYY-MM-DD'),
            };

            let response;
            if (membership_id === 'new') {
                response = await CreateMemberships(membership_data);
            } else {
                response = await UpdateMemberships(membership_id, membership_data);
            }

            if (response.status) {
                if (response.data.balance !== 0 && Permissions.checkPermission(permissions_data.membership_payment.view)) {
                    if (membership_id === 'new') {
                        props.history.push({
                            pathname: `${sub_directory}memberships/edit`,
                            state: {
                                moveToNextTab: true,
                                id: response.data.id,
                                tag: response.data.short_name
                            }
                        });
                    } else {
                        props.moveToNextTab();
                    }
                } else {
                    await handleBackButton()
                }
            } else {
                setHelperText(response.error_feedback);
            }
        }
        await setIsLoading(false);
    };


    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this membership ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let deleteMembershipResponse = await DeleteMemberships(membership_id);
        if (deleteMembershipResponse.status) {
            await handleBackButton()
        } else {
            setHelperText(deleteMembershipResponse.error_feedback)
        }
    }

    const handleBackButton = async () => {
        props.history.push(sub_directory + 'memberships');
    };


    // Membership data
    const MembershipData = (
        <Paper className="h-100">

            <Grid container spacing={2} className='Paper'>

                <Grid item xs={12} className="d-sm-inline-flex justify-content-between">

                    <Typography variant="h6" className="text-start">
                        {getLabel('membership_info')}

                        {membership.is_freeze && (
                            <Chip color="secondary" className="margin-left-3" label={getLabel("freezed")}/>
                        )}

                        {props.is_cancelled && (
                            <Chip color="secondary" className="margin-left-3" label={getLabel("cancelled")}/>
                        )}
                    </Typography>


                    <div>

                        <Hide hide={membership_id === "new"}>
                            <Typography variant='inherit' className={`margin-right-2 ${membership.ending_within == 0? "text-danger": membership.ending_within == 1?"text-waring":""}`}>
                                {`${getLabel('ending_within')}: ${membership.ending_within} ${getLabel('days')}`}
                            </Typography>
                        </Hide>

                        <Hide hide={not_validate_value.includes(membership.transfer_from)}>
                            <Button color="primary" size="small"
                                    onClick={() => goToMembership(membership.transfer_from, membership.transfer_from_name)}>
                                {getLabel("view_transfer_from")}
                            </Button>
                        </Hide>

                        <Hide hide={not_validate_value.includes(membership.transfer_to)}>
                            <Button color="primary" size="small"
                                    onClick={() => goToMembership(membership.transfer_to, membership.transfer_to_name)}>
                                {getLabel("view_transfer_to")}
                            </Button>
                        </Hide>

                        <Hide
                            hide={props.is_cancelled || membership_id === 'new' || !Permissions.checkPermission(permissions_data.membership.change) ||
                            !Permissions.checkOnePermissions([permissions_data.membership.transfer, permissions_data.membership.upgrade_duration,
                                permissions_data.membership.change_start_date, permissions_data.membership.change_invoice_date,
                                permissions_data.membership.change_preferred_time, permissions_data.membership.change_notes,
                                permissions_data.membership.change_extra_sessions_and_extra_days
                            ])
                            }>
                            <CustomDropDown
                                readerItem={(
                                    <>
                                        <Typography variant='inherit' className="margin-left-2">
                                            {getLabel('actions')}
                                        </Typography>
                                        <ArrowDropDownIcon/>
                                    </>
                                )}

                                options={
                                    [
                                        {
                                            label: "transfer",
                                            action: handleTransferModal,
                                            disabled: !Permissions.checkPermission(permissions_data.membership.transfer) || membership.is_done
                                        },
                                        {
                                            label: "upgrade_duration",
                                            action: () => handleUpgradePriceModal(false),
                                            disabled: !Permissions.checkPermission(permissions_data.membership.upgrade_duration)
                                        },
                                        {
                                            label: "change_start_date",
                                            action: () => handleChangeStartDateModal(false),
                                            disabled: !Permissions.checkPermission(permissions_data.membership.change_start_date) || membership.is_done
                                        },
                                        {
                                            label: "change_invoice_date",
                                            action: () => handleChangeInvoiceDateModal(false),
                                            disabled: !Permissions.checkPermission(permissions_data.membership.change_invoice_date) || membership.is_done
                                        },
                                        {
                                            label: "change_extra_days_and_extra_sessions",
                                            action: () => handleExtraDaysAndSessionsModal(false),
                                            disabled: !Permissions.checkPermission(permissions_data.membership.change_extra_sessions_and_extra_days)
                                        },
                                        {
                                            label: "change_preferred_time",
                                            action: () => handlePreferredTimeModal(false),
                                            disabled: !Permissions.checkPermission(permissions_data.membership.change_preferred_time)
                                        },
                                        {
                                            label: "change_notes",
                                            action: () => handleNotesModal(false),
                                            disabled: !Permissions.checkPermission(permissions_data.membership.change_notes)
                                        },
                                        {
                                            label: "change_discount",
                                            action: () => handleDiscountModal(false),
                                            disabled: !Permissions.checkPermission(permissions_data.membership.change_discount)
                                        },
                                    ]
                                }
                            />
                        </Hide>
                    </div>

                </Grid>

                <Grid item xs={12} sm={6}>
                    <Hide hide={membership_id !== 'new'}>
                        <ClientAutoComplete
                            location={location_default_option}
                            viewOnly={!have_permission}
                            disabled={props.is_cancelled}
                            required={true}
                            name='client'
                            label="client"
                            onChangeHandle={handleAutoCompleteChange}
                            helper_text={helper_text.client}
                            error={helper_text.client !== undefined}
                            value={membership.client}
                            getClientGender={getClientGender}
                        />
                    </Hide>

                    <Hide hide={membership_id === 'new'}>
                        <CustomTextField
                            label="client"
                            value={membership.short_name}
                            disabled={props.is_cancelled}
                            viewOnly={!have_permission}
                        />
                    </Hide>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Hide hide={membership_id !== 'new'}>
                        <LocationsAutoComplete
                            active
                            location={location_default_option}
                            for={client_gender}
                            viewOnly={!have_permission || not_validate_value.includes(membership.client)}
                            disabled={props.is_cancelled}
                            required={true}
                            name='location'
                            label="location"
                            onChangeHandle={handleAutoCompleteChange}
                            helper_text={helper_text.location}
                            error={helper_text.location !== undefined}
                            value={membership.location}
                        />
                    </Hide>

                    <Hide hide={membership_id === 'new'}>
                        <CustomTextField
                            label="location"
                            value={membership.location_name}
                            disabled={props.is_cancelled}
                            viewOnly={!have_permission}
                        />
                    </Hide>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Hide hide={membership_id !== 'new'}>
                        <Autocomplete
                            ListboxProps={{className:"list-box-autocomplete"}}
                            renderOption={(option) => (
                                <span className={"w-100 text-start"}>{option.title}</span>
                            )}
                            filterOptions={options => {
                                return options.filter(option => {
                                    return (client_gender === "Male" && option.data.for_males) ||
                                        (client_gender === "Female" && option.data.for_females) ||
                                        (not_validate_value.includes(client_gender))
                                })
                            }}
                            disabled={props.is_cancelled || !have_permission}
                            options={services}
                            getOptionLabel={option => option.title}
                            renderInput={params => (
                                <TextField {...params}
                                           label={<label>{getLabel("service")}<span
                                               className='text-danger'>*</span></label>}
                                           variant="outlined"
                                           error={not_validate_value.includes(membership.service) && helper_text.price !== undefined}
                                           helperText={not_validate_value.includes(membership.service) && helper_text.price}
                                           disabled={props.is_cancelled}
                                           className={props.is_cancelled ? "disabled-field" : ""}
                                           fullWidth/>
                            )}
                            onChange={(event, value) => {
                                handleAutoCompleteChange('service', value)
                            }}
                            value={handleAutoCompleteValue('service')}
                        />
                    </Hide>

                    <Hide hide={membership_id === 'new'}>
                        <CustomTextField
                            label="service"
                            value={membership.service_name}
                            disabled={props.is_cancelled}
                            viewOnly={!have_permission}
                        />
                    </Hide>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Hide hide={membership_id !== 'new'}>
                        <Autocomplete
                            ListboxProps={{className:"list-box-autocomplete"}}
                            renderOption={(option) => (
                                <span className="w-100 text-start">{option.title}</span>
                            )}
                            filterOptions={options => {
                                return options.filter(option => {
                                    return (client_gender === "Male" && option.data.for_males) ||
                                        (client_gender === "Female" && option.data.for_females) ||
                                        (not_validate_value.includes(client_gender))
                                })
                            }}
                            disabled={props.is_cancelled || !have_permission}
                            options={price_lists}
                            getOptionLabel={option => option.title}
                            renderInput={params => (
                                <TextField {...params}
                                           label={<label>{getLabel("price_list")}<span
                                               className='text-danger'>*</span></label>}
                                           variant="outlined"
                                           error={not_validate_value.includes(membership.price_list) && helper_text.price !== undefined}
                                           helperText={not_validate_value.includes(membership.price_list) && helper_text.price}
                                           disabled={props.is_cancelled}
                                           className={props.is_cancelled ? "disabled-field" : ""}
                                           fullWidth/>
                            )}
                            onChange={(event, value) => {
                                handleAutoCompleteChange('price_list', value)
                            }}
                            value={handleAutoCompleteValue('price_list')}
                        />
                    </Hide>

                    <Hide hide={membership_id === 'new'}>
                        <CustomTextField
                            label="price_list"
                            value={membership.price_list_name}
                            disabled={props.is_cancelled}
                            viewOnly={!have_permission}
                        />
                    </Hide>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Hide hide={membership_id !== 'new'}>
                        <Autocomplete
                            ListboxProps={{className:"list-box-autocomplete"}}
                            renderOption={(option) => (
                                <span className="w-100 text-start h-100">
                                    {option.title}
                                    {!not_validate_value.includes(option.data.offer_price) && (
                                        <MoneyOffIcon color="primary" className="mr-2 ml-2"/>
                                    )}
                                </span>
                            )}
                            disabled={props.is_cancelled || !have_permission}
                            options={prices}
                            // getOptionLabel={option =>(<Emoji text=":fire"/>)}
                            // end
                            getOptionLabel={option => option.title}
                            renderInput={params => (
                                <TextField {...params}
                                           label={<label>{getLabel("duration")}<span
                                               className='text-danger'>*</span></label>}
                                           variant="outlined"
                                           error={helper_text.price !== undefined}
                                           helperText={helper_text.price}
                                           disabled={props.is_cancelled}
                                           className={props.is_cancelled ? "disabled-field" : ""}
                                           fullWidth/>
                            )}
                            onChange={(event, value) => {
                                handleAutoCompleteChange('price', value)
                            }}
                            value={handleAutoCompleteValue('price')}
                        />
                    </Hide>

                    <Hide hide={membership_id === 'new'}>
                        <CustomTextField
                            label="duration"
                            value={membership.price_name}
                            disabled={props.is_cancelled}
                            viewOnly={!have_permission}
                        />
                    </Hide>
                </Grid>

                <Grid item xs={12} md={4}>
                    <DisabledTextField
                        label="duration_days"
                        value={membership.duration || ""}
                    />
                </Grid>



                <Grid item xs={12} md={4}>
                    <DisabledTextField
                        label="sessions"
                        value={membership.sessions}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DisabledTextField
                        label="freeze_days"
                        value={membership.freeze_days}
                    />
                </Grid>


                <Hide
                    hide={membership_id === 'new' && !Permissions.checkPermission(permissions_data.membership.add_extra_days)}>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            error={helper_text.extra_days !== undefined}
                            helper_text={helper_text.extra_days}
                            name="extra_days"
                            label="pages.membershipsinfo.extra_days"
                            type="number"
                            value={membership.extra_days}
                            onChangeHandle={handleFieldChange}
                            disabled={props.is_cancelled}
                            viewOnly={!have_permission}
                        />
                    </Grid>
                </Hide>

                <Hide
                    hide={membership_id === 'new' && !Permissions.checkPermission(permissions_data.membership.add_extra_sessions)}>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            error={helper_text.extra_sessions !== undefined}
                            helper_text={helper_text.extra_sessions}
                            name="extra_sessions"
                            label="pages.membershipsinfo.extra_sessions"
                            type="number"
                            value={membership.extra_sessions}
                            onChangeHandle={handleFieldChange}
                            disabled={props.is_cancelled}
                            viewOnly={!have_permission}
                        />
                    </Grid>
                </Hide>

                <Grid item xs={12} sm={6}>
                    <Hide hide={membership_id !== 'new'}>
                        <DateField
                            required
                            disabled={props.is_cancelled}
                            viewOnly={props.is_cancelled || !have_permission}
                            name="start_date"
                            error={helper_text.start_date !== undefined}
                            helper_text={helper_text.start_date}
                            label="start_date"
                            value={membership.start_date}
                            onChangeHandle={handleDateChange}
                        />
                    </Hide>

                    <Hide hide={membership_id === 'new'}>
                        <DisabledDateField
                            label="start_date"
                            value={membership.start_date}
                        />
                    </Hide>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DisabledDateField
                        disabled={true}
                        label="end_date"
                        value={membership.end_date}
                    />
                </Grid>


                <Grid item xs={12}>
                    <Hide hide={membership_id !== 'new'}>
                        <SelectField
                            viewOnly={props.is_cancelled || !have_permission}
                            disabled={props.is_cancelled}
                            label="preferred_training_time"
                            name="preferred_training_time"
                            value={membership.preferred_training_time}
                            onChangeHandle={handleFieldChange}
                            options={sessions_timing}
                            error={helper_text.start_date !== undefined}
                            helper_text={helper_text.start_date}
                        />
                    </Hide>

                    <Hide hide={membership_id === 'new'}>
                        <CustomTextField
                            label="preferred_training_time"
                            value={membership.preferred_training_time}
                            disabled={props.is_cancelled}
                            viewOnly={!have_permission}
                        />
                    </Hide>
                </Grid>


                <Grid item xs={12} className="mb-2">
                    <CustomTextArea
                        label="notes"
                        error={helper_text.notes === undefined}
                        helper_text={helper_text.notes}
                        name="notes"
                        rows={3}
                        value={membership.notes || ""}
                        onChangeHandle={handleFieldChange}
                        disabled={props.is_cancelled}
                        viewOnly={!have_permission}
                    />
                </Grid>

                {membership_id !== 'new' && (
                    <>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="created_by"
                                        value={membership.created_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_created"
                                        value={membership.date_created}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="modified_by"
                                        value={membership.modified_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_modified"
                                        value={membership.date_modified}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                    </>
                )}

            </Grid>
        </Paper>
    );


    const PaymentData = (
        <Paper className="h-100">
            <Grid container spacing={2} className="Paper pt-2">

                <CustomHeader
                    title="pages.membershipsinfo.payment_information.header"
                />

                <Hide hide={membership_id === 'new'}>
                    <Grid item xs={12}>
                        <DisabledTextField
                            label="pages.membershipsinfo.invoice_num"
                            value={membership.invoice_id || ""}
                        />
                    </Grid>
                </Hide>


                <Grid item xs={12}>
                    <DateField
                        required
                        disabled={membership_id !== 'new'}
                        viewOnly={membership_id !== 'new'}
                        name="invoice_date"
                        error={helper_text.invoice_date !== undefined}
                        helper_text={helper_text.invoice_date}
                        label="invoice_date"
                        value={membership.invoice_date}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>


                <Grid item xs={12}>
                    <DisabledTextField
                        label="pages.membershipsinfo.original_price"
                        value={membership.original_price}
                    />
                </Grid>

                <Hide
                    hide={membership_id === 'new' && !Permissions.checkPermission(permissions_data.membership.add_discount)}>
                    <Grid item xs={12}>
                        <CustomTextField
                            name="discount"
                            label="discount"
                            type="number"
                            error={helper_text.discount !== undefined}
                            helper_text={helper_text.discount}
                            value={membership.discount}
                            onChangeHandle={handleFieldChange}
                            disabled={props.is_cancelled}
                            viewOnly={!have_permission}
                        />
                    </Grid>
                </Hide>

                <Grid item xs={12}>
                    <DisabledTextField
                        label="total_price"
                        value={membership.total_price}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DisabledTextField
                        value={membership.paid}
                        label="paid"
                    />
                </Grid>

                {/*<Grid item xs={12}>*/}
                {/*    <DisabledTextField*/}
                {/*        value={membership.refund}*/}
                {/*        label="refund"*/}
                {/*    />*/}
                {/*</Grid>*/}

                <Grid item xs={12}>
                    <DisabledTextField
                        value={membership.balance}
                        label="balance"
                    />
                </Grid>
            </Grid>
        </Paper>
    );


    return (
        <>

            <Hide hide={!is_loading}>
                <Loading/>
            </Hide>

            <Hide hide={is_loading}>

                <Grid container spacing={3}>

                    <Hide hide={!open_upgrade_price_modal}>
                        <ChangePrice
                            membership={membership_id}
                            open={open_upgrade_price_modal}
                            handleModalClose={handleUpgradePriceModal}
                            default_value={membership}
                            price={membership.price}
                        />
                    </Hide>

                    <Hide hide={!open_change_discount}>
                        <ChangeDiscountModal
                            membership={membership_id}
                            open={open_change_discount}
                            handleModalClose={handleDiscountModal}
                            default_value={membership}
                        />
                    </Hide>

                    <Hide hide={!open_change_notes}>
                        <ChangeNotesModal
                            membership={membership_id}
                            open={open_change_notes}
                            handleModalClose={handleNotesModal}
                            default_value={membership}
                        />
                    </Hide>

                    <Hide hide={!open_change_preferred_time}>
                        <ChangePreferredTimeModal
                            membership={membership_id}
                            open={open_change_preferred_time}
                            handleModalClose={handlePreferredTimeModal}
                            default_value={membership}
                        />
                    </Hide>

                    <Hide hide={!open_change_extra_days_and_sessions}>
                        <ChangeExtraDaysAndSessionsModal
                            membership={membership_id}
                            open={open_change_extra_days_and_sessions}
                            handleModalClose={handleExtraDaysAndSessionsModal}
                            default_value={membership}
                        />
                    </Hide>

                    <Hide hide={!open_change_start_date_modal}>
                        <ChangeStartDate
                            membership={membership_id}
                            open={open_change_start_date_modal}
                            handleModalClose={handleChangeStartDateModal}
                            default_value={membership}
                        />
                    </Hide>

                    <Hide hide={!open_change_invoice_date_modal}>
                        <ChangeInvoiceDate
                            membership={membership_id}
                            open={open_change_invoice_date_modal}
                            handleModalClose={handleChangeInvoiceDateModal}
                            default_value={membership.invoice_date}
                        />
                    </Hide>

                    <Hide hide={!open_transfer}>
                        <TransferModal
                            location={membership.location}
                            membership={membership_id}
                            open={open_transfer}
                            handleModalClose={handleTransferModal}
                            goToMembership={goToMembership}
                        />
                    </Hide>

                    <Grid item xs={12} md={12} lg={8}>
                        {MembershipData}
                    </Grid>


                    <Grid item xs={12} md={12} lg={4}>
                        {PaymentData}
                    </Grid>

                    <ActionButtons
                        errorMsg={helper_text.non_field_errors}
                        allowDelete={Permissions.checkPermission(permissions_data.membership.delete) && membership_id !== 'new'}
                        deleteAction={handleDeleteButton}
                        allowBack={true}
                        backAction={handleBackButton}
                        allowSave={have_permission}
                        saveAction={handleSaveButton}
                    />

                </Grid>
            </Hide>
        </>
    );
}
