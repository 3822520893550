import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import {getLabel} from "../localization/MainLocalization";
import {dir, not_validate_value} from "../App";
import ErrorIcon from "@material-ui/icons/Error";
import LinearProgress from "@material-ui/core/LinearProgress";
import {ErrorBox} from "./ErrorBox";
import {Hide} from "./Hide";

export const FormActionButtons = props => {
    return (
        <>
            <ErrorBox
                errorMsg={props.errorMsg}
            />

        <Grid item xs={12} className='d-inline-flex justify-content-between'>
            <Button
                onClick={props.handleCancelButton}
                variant="contained"
                color='default'
                size="medium"
                startIcon={<CancelIcon/>}
            >
                {getLabel("cancel")}
            </Button>

            <Hide hide={!props.handleSaveButton}>
            <Button
                onClick={props.handleSaveButton}
                variant="contained"
                color='primary'
                size="medium"
                startIcon={<SaveIcon/>}
            >
                {getLabel(props.saveLabel? props.saveLabel: "save")}
            </Button>
            </Hide>

        </Grid>

            {props.isloading && (
            <Grid item={12}>
                <LinearProgress variant="query" />
            </Grid>
            )}
            </>
    );
};
