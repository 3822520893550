import React, {useEffect, useState} from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import Typography from "@material-ui/core/Typography";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseGroupAthletesAgeScaleURL} from "../../../../urls";
import Grid from "@material-ui/core/Grid";
import {CategoryAutoCompleteFreeSolo} from "../../../../components/fields/CategoryAutoCompleteFreeSolo";
import {Hide} from "../../../../components/Hide";
import {SeasonAutoComplete} from "../../../../components/fields/SeasonAutoComplete";
import {GroupsAutoComplete} from "../../../../components/fields/GroupsAutoComplete";
import {not_validate_value} from "../../../../App";


export const AthleteAgeScale = props => {
    const [data, setData] = useState( []);
    const [setting, setSetting] = useState( {
        season:"",
        category:"",
        team:"",
    });

    const handleSettingField = (name, value) =>{
        let new_setting = {...setting}

        if (name === "season"){
            new_setting['category'] = ""
            new_setting['team'] = ""
        }

        if (name === "category"){
            new_setting['team'] = ""
        }

        new_setting[name] = value

        setSetting(new_setting)
    }

    useEffect(()=>{
        const fetchData = async () =>{
            let response = await httpRequestWithAuth(BaseGroupAthletesAgeScaleURL, "GET",  null, setting);
            if (response.status){
                await setData([
                    {name:"<15", athletes: response.data[0]},
                    {name:"<20", athletes: response.data[1]},
                    {name:"<25", athletes: response.data[2]},
                    {name:"<30", athletes: response.data[3]},
                    {name:"<35", athletes: response.data[4]},
                    {name:"<40", athletes: response.data[5]},
                    {name:"<45", athletes: response.data[6]},
                    {name:">45", athletes: response.data[7]},
                    {name:"Unknown", athletes: response.data[8]},
                ])
            }
        }

        fetchData();
    },[setting.season, setting.category, setting.team])

        return (
            <div className='card-paper-1 h-100 w-100'>
                <Typography variant="h6" className="text-start mb-2">
                    Athlete Age scale
                </Typography>

                <Grid container spacing={2} className="mb-2">

                    <Grid item xs={12} md={6}>
                        <SeasonAutoComplete
                            name='season'
                            label="season"
                            onChangeHandle={handleSettingField}
                            value={setting.season}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CategoryAutoCompleteFreeSolo
                            disabled={not_validate_value.includes(setting.season)}
                            name='category'
                            label="category"
                            onChangeHandle={handleSettingField}
                            value={setting.category}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <GroupsAutoComplete
                            season={setting.season}
                            categoryName={setting.category}
                            disabled={not_validate_value.includes(setting.category)}
                            groupFullName
                            name="team"
                            label="team"
                            value={setting.team}
                            onChangeHandle={handleSettingField}
                        />
                    </Grid>

                </Grid>

                <div dir='ltr' className="fixedHeight-400 w-100">
                <ResponsiveContainer width="100%" height='90%'>
                    <LineChart
                        data={data}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="athletes" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
                </div>

            </div>
        );
}
