import React from "react";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "./CustomTextField";
import {CountriesAutoComplete} from "./CountriesAutoComplete";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../localization/MainLocalization";

export  const AddressFields = props =>{
    return(
        <>
            <Grid item xs={12}>
                <Typography variant="h6" className='p-1'>
                    {getLabel("address")}
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <CountriesAutoComplete
                    viewOnly={props.viewOnly}
                    label="country"
                    name="country"
                    onChangeHandle={props.onChangeHandle}
                    helper_text={props.helper_text.country}
                    error={props.helper_text.country !== undefined}
                    value={props.value.country}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <CustomTextField
                    viewOnly={props.viewOnly}
                    helperText={props.helper_text.city}
                    error={props.helper_text.city !== undefined}
                    name="city"
                    label="city"
                    value={props.value.city}
                    onChangeHandle={props.onChangeHandle}
                />
            </Grid>


            <Grid item xs={12} md={6}>
                <CustomTextField
                    viewOnly={props.viewOnly}
                    helperText={props.helper_text.area}
                    error={props.helper_text.area !== undefined}
                    name="area"
                    label="area"
                    value={props.value.area}
                    onChangeHandle={props.onChangeHandle}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <CustomTextField
                    viewOnly={props.viewOnly}
                    helperText={props.helper_text.address_line_one}
                    error={props.helper_text.address_line_one !== undefined}
                    name="address_line_one"
                    label="address_line_one"
                    value={props.value.address_line_one}
                    onChangeHandle={props.onChangeHandle}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <CustomTextField
                    viewOnly={props.viewOnly}
                    helperText={props.helper_text.address_line_two}
                    error={props.helper_text.address_line_two !== undefined}
                    name="address_line_two"
                    label="address_line_two"
                    value={props.value.address_line_two}
                    onChangeHandle={props.onChangeHandle}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <CustomTextField
                    viewOnly={props.viewOnly}
                    helperText={props.helper_text.address_line_three}
                    error={props.helper_text.address_line_three !== undefined}
                    name="address_line_three"
                    label="address_line_three"
                    value={props.value.address_line_three}
                    onChangeHandle={props.onChangeHandle}
                />
            </Grid>

        </>
    );
}