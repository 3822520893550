import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseCategoryURL} from "../../urls";
import TextField from "@material-ui/core/TextField";
import {getLabel} from "../../localization/MainLocalization";

export const CategoryAutoCompleteFreeSolo = props=> {
    const [options, setOptions] = useState([])

    useEffect(()=>{
        const fetchData = async ()=>{

            let response = await httpRequestWithAuth(BaseCategoryURL, "GET", null);
            if (response.status){
                let temp_data = []
                response.data.map(Data=>temp_data.push(Data.name))
                await setOptions(temp_data);
            }else{
                setOptions([])
            }
        }

        fetchData();
    },[])

    const handleOnChange = (event, value) => {
        if (!props.viewOnly){
        props.onChangeHandle(props.name, value);
        }

    };


        return (
            <Autocomplete
                ListboxProps={{className:"list-box-autocomplete"}}
                renderOption={(option) => (
                    <span className="w-100 text-start">{`${option}`}</span>
                )}
                freeSolo={props.freeSolo}
                autoSelect={props.autoSelect}
                disabled={props.disabled || props.viewOnly}
                options={options}
                getOptionLabel={option => `${option}`}
                renderInput={params => (
                    <TextField variant="outlined" {...params}
                               label={<label>{getLabel(props.label)}<span
                                   className='text-danger'>{props.required ? "*" : ""}</span></label>}
                               helperText={props.helper_text}
                               error={props.error}
                               fullWidth
                               size="large"
                               disabled={props.disabled}
                               className={props.disabled ? "disabled-field" : ""}
                               placeholder={props.placeHolder ? getLabel(props.placeHolder) : null}
                    />
                )}
                value={props.value || null}
                onChange={handleOnChange}
            />
        );
}
