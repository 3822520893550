import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {RecurringClassesForm} from "./RecurringClassesForm";
import {CustomTabs} from "../../../components/CustomTabs";


export const RecurringClassesInfo = props => {
    const state = props.history.location.state || {};
    const [currentTab, setCurrentTab] = React.useState(0);
    const [recurring_class_id, setRecurringClassId] = React.useState(state.id);

    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };

    useEffect(()=>{
        if (state.id){
            setRecurringClassId(state.id);
        }else{
            props.history.push(`/classes`);
        }
    },[])

    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match} details={{tag: state.tag}}/>*/}

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >
                <Tab label={getLabel("details")} id={0}/>
            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <RecurringClassesForm
                        {...props}
                        recurring_class_id={recurring_class_id}
                    />
                </Hide>

            </Grid>
        </Paper>
    );
}
