import React, {Fragment, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "./FormHeader";
import {FormActionButtons} from "./FormActionButton";
import "../assets/scss/Static.scss";
import {CustomModal} from "./CustomModal";
import {TimeField} from "./fields/TimeField";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../localization/MainLocalization";
import Chip from "@material-ui/core/Chip";
import {getRandomInt, not_validate_value, timeTo12Hour} from "../App";
import moment from "moment";
import {Hide} from "./Hide";
import {Loading} from "./Loading";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {ErrorBox} from "./ErrorBox";


export const TimeModal = props => {
    const [time_data, setTimeData] = useState([{id: getRandomInt(), start_time: null, end_time: null, errors: {}}]);
    const [error, setError] = useState({});
    const [days, selectDay] = useState({});
    const [is_loading, setIsLoading] = useState(false);
    const days_name = ['saturday', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

    const onDayChipClick = clicked_day => {
        selectDay({
            ...days,
            [clicked_day]: not_validate_value.includes(clicked_day) ? true : !days[clicked_day]
        })
    }

    const addNewTime = () => {
        let last_data = time_data[time_data.length-1];

        let new_obj =  {id: getRandomInt(), start_time: null, end_time: null, errors: {}};

        if(!not_validate_value.includes(last_data.start_time) &&  !not_validate_value.includes(last_data.end_time)){
            let start_time = moment(last_data.start_time, "HH:mm");
            let end_time = moment(last_data.end_time, "HH:mm");
            let duration = end_time.diff(start_time) / 60000;

            new_obj.start_time = end_time.format("HH:mm");
            new_obj.end_time = end_time.add(duration, "minutes").format("HH:mm");
        }

        setTimeData([...time_data, new_obj]);
    }

    const removeTime = (index) => {
        index = parseInt(index);
        if (time_data.length > 1) {
            let temp_data = [...time_data];
            temp_data.splice(index, 1);
            setTimeData(temp_data);
        }
    }

    const handleTimeChange = (index, name, value) => {
        let temp_time_data = [...time_data];
        let current_obj = {...temp_time_data[index]};

        let newValue = moment("1995-06-09 " + value)

        if (name === 'start_time' && newValue.isAfter(moment("1995-06-09 " + current_obj.end_time))) {
            current_obj[name] = value;
            current_obj['end_time'] = value;
        } else if (name === 'end_time' && newValue.isBefore(moment("1995-06-09 " + current_obj.start_time))) {
            current_obj[name] = value;
            current_obj['start_time'] = value;
        } else {
            current_obj[name] = value;
        }

        temp_time_data[index] = current_obj;

        setTimeData(temp_time_data);
    }

    const handleSaveForm = async () => {
        await setIsLoading(true);

        let check_error = true
        let temp_error = {}

        await Object.keys(days).map(Day => {
            if (days[Day] === true) {
                check_error = false
            }
            return 0;
        })


        if (check_error) {
            temp_error = {...temp_error, 'non_field_errors': " Should select one day at least"}
        } else {
            temp_error['non_field_errors'] = undefined
        }

        let temp_time_data = [...time_data];
        temp_time_data.map(TimeData=>{

        if (not_validate_value.includes(TimeData.start_time)) {
            TimeData.errors.start_time = "This field can't be null or empty";
        } else {
            TimeData.errors.start_time = undefined;
        }

        if (not_validate_value.includes(TimeData.end_time)) {
            TimeData.errors.end_time = "This field can't be null or empty";
        } else {
            TimeData.errors.end_time = undefined;
        }

        if (!not_validate_value.includes(TimeData.start_time) &&
            !not_validate_value.includes(TimeData.end_time) &&
            moment(TimeData.end_time, 'hh:mm').diff(moment(TimeData.start_time, 'hh:mm'), 'minutes') < 10) {
            TimeData.errors.non_field_errors = (not_validate_value.includes(temp_error.non_field_errors) ? "" : temp_error.non_field_errors + " and ")
                + "Minimum class duration is 10 minutes";
        }

             Object.keys(TimeData.errors).map(Error => {
                if (temp_error[Error] !== undefined) {
                    check_error = true
                }
            })

        })


        await setError({...temp_error})

        await Object.keys(temp_error).map(Error => {
            if (temp_error[Error] !== undefined) {
                check_error = true
            }
        })

        if (!check_error) {
            temp_time_data.map(TimeData=> {
                TimeData.start_time = timeTo12Hour(TimeData.start_time);
                TimeData.end_time = timeTo12Hour(TimeData.end_time);
            })

            await props.handleAddTime(temp_time_data, days)
            await props.handleModalClose()
        }

        await setIsLoading(false);
    }


    return (

        <CustomModal
            open={props.open}
        >
            <Grid container spacing={2}>

                <FormHeader
                    title="time_form"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>

                    {time_data.map((TimeData, index) => (
                        <Fragment key={TimeData.id}>
                            <Grid item xs={10} key={TimeData.id}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} md={6}>
                                        <TimeField
                                            required
                                            error={TimeData.errors.start_time !== undefined}
                                            helper_text={TimeData.errors.start_time}
                                            label="start_time"
                                            name="start_time"
                                            value={TimeData.start_time}
                                            onChangeHandle={(name, value) => handleTimeChange(index, name, value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TimeField
                                            required
                                            error={TimeData.errors.end_time !== undefined}
                                            helper_text={TimeData.errors.end_time}
                                            label="end_time"
                                            name="end_time"
                                            value={TimeData.end_time}
                                            onChangeHandle={(name, value) => handleTimeChange(index, name, value)}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={2}
                                  className="d-flex align-items-center justify-content-md-center justify-content-end">
                                <Button className="text-danger"
                                        startIcon={<RemoveCircleIcon className="text-danger"/>}
                                        onClick={() => removeTime(index)}>
                                    {getLabel('remove')}
                                </Button>
                            </Grid>

                            <ErrorBox
                                errorMsg={TimeData.errors.non_field_errors}
                            />

                        </Fragment>
                    ))}


                    <Grid item xs={12} className="text-end">
                        <Button startIcon={<AddIcon/>} onClick={addNewTime}>Add New Time</Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" className='p-1 m-1'>
                            {getLabel("days")}:
                        </Typography>

                        {days_name.map((Day, index) => (
                            <Chip
                                key={index}
                                color={days[Day] !== true ? "default" : "primary"}
                                className="m-2"
                                label={getLabel(Day)}
                                onClick={() => onDayChipClick(Day)}
                            />
                        ))}
                    </Grid>

                    <FormActionButtons
                        errorMsg={error.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                    />
                </Hide>
            </Grid>
        </CustomModal>
    );
}
