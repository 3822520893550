import {BasePriceListURL} from "../../../../urls";
import {instance} from "../../../../auth/axios_instance";


export const ListPriceLists = async (services = null) => {
    let url = BasePriceListURL;

    if (services != null) {
        services = services.join();
        url += '?services=' + services
    }

    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback

};


export const RetrievePriceList = async (id = null) => {
    let url = BasePriceListURL + id + "/";

    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback

};

export const CreatePriceList = async (data) => {
    let url = BasePriceListURL;
    const feedback = await instance.post(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const UpdatePriceListByField = async (id = null, data = null) => {
    let url = BasePriceListURL + id + "/";

    const feedback = await instance.patch(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const DeletePriceList = async (PriceLists = null) => {
    let url = BasePriceListURL + PriceLists + "/";

    const feedback = await instance.delete(url).then(res => {
        return {status: true, msg: res.statusText}
    }).catch(error => {
        return {stats: false, msg: error.response.status}
    });
    return feedback
};


