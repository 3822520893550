import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {LocationsForm} from "./LocationsForm";
import {LocationServices} from "./LocationsServices";
import {TrainingArea} from "./TrainingArea";
import {sub_directory} from "../../../App";
import {LocationFreezes} from "./LocationFreezes";
import {CustomTabs} from "../../../components/CustomTabs";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";


export const LocationInfo = props => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [location_id, setLocationId] = React.useState("new");
    const [location_gender, setLocationGender] = React.useState("");
    const state = props.history.location.state || {};

    const handleLocationGenderChange = (for_males, for_females)=>{
        if (for_females && for_males){
            setLocationGender("");
        }else if (for_females){
            setLocationGender("Female");
        }else if (for_males){
            setLocationGender("Male");
        }
    }

    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };


    const moveToNextTab = () => {
        setCurrentTab(currentTab + 1)
    }


    useEffect(() => {
        if (state.id){
            setLocationId(state.id);
        }else{
            props.history.push(`${sub_directory}locations`);
        }

        if (state.moveToNextTab) {
            moveToNextTab();
        }
    }, [])


    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match} details={{tag: state.tag}}/>*/}

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >
                <Tab label={getLabel("details")} id={0}/>

                <Tab label={getLabel("services")} id={1} disabled={location_id === 'new' || !Permissions.checkPermission(permissions_data.location_service.view)}/>

                <Tab label={getLabel("training_areas")} id={2} disabled={location_id === 'new' || !Permissions.checkPermission(permissions_data.training_area.view)}/>

                <Tab label={getLabel("freezes")} id={3} disabled={location_id === 'new' || !Permissions.checkPermission(permissions_data.location_freeze.view)}/>

            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden' spacing={2}>

                <Hide hide={currentTab !== 0}>
                    <LocationsForm
                        {...props}
                        setGender={handleLocationGenderChange}
                        location_id={location_id}
                    />
                </Hide>

                <Hide hide={currentTab !== 1}>
                    <LocationServices
                        location_gender={location_gender}
                        {...props}
                        location={location_id}
                    />
                </Hide>


                <Hide hide={currentTab !== 2}>
                    <TrainingArea history={props.history} location_id={location_id}/>
                </Hide>

                <Hide hide={currentTab !== 3}>
                    <LocationFreezes {...props} location_id={location_id}/>
                </Hide>

            </Grid>
        </Paper>
    );
}