import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import {getLabel} from "../localization/MainLocalization";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export const ClientAthleteCheckBox = props =>{
    return(
        <>
            <FormLabel
                className="w-100 text-start"
            >
                {getLabel("class_for")}
            </FormLabel>
            <FormGroup row >
                <FormControlLabel disabled={props.disabled}
                    control={<Checkbox checked={props.for_clients || false}
                                       color="primary"
                                       onChange={e => {
                                           if (!props.viewOnly){
                                           props.onChangeHandle(e.target.name,e.target.checked);
                                           }
                                       }} name={props.clients_name}/>}
                    label={getLabel("clients")}
                />

                <FormControlLabel
                    disabled={props.disabled}
                    control={<Checkbox checked={props.for_athletes || false}
                                       style={{color:"green"}}
                                       onChange={e => {
                                           if (!props.viewOnly){
                                           props.onChangeHandle(e.target.name,e.target.checked);
                                           }
                                       }} name={props.athletes_name} />}
                    label={getLabel("athletes")}
                />

            </FormGroup>
        </>
    );
}
