import React, {useContext, useRef, useState} from "react";
import MaterialTable from "material-table";
import Permissions from "../../../auth/permissions";
import "../../../assets/scss/Static.scss";
import {httpRequestWithAuth, instance} from "../../../auth/axios_instance";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Hide} from "../../../components/Hide";
import {permissions_data} from "../../../auth/permission_data";
import {
    BaseFinanceMyClaimRequestURL
} from "../../../urls";
import {AlertContext, ConfirmationContext} from "../../../store/use_context";
import {sub_directory} from "../../../App";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

export const MyClaimRequests = props =>{
        const tableRef = useRef();
        const {setConfirmData} = useContext(ConfirmationContext)

           const  [columns, setColumns] = useState([
                {title: getLabel("claim_id"),
                        render: rowData => (
               <Typography>
                   <Link href="" onClick={e => {
                       e.preventDefault();
                       viewClaim(rowData.id, rowData.claim_id)
                   }}>
                       #{rowData.claim_id}
                   </Link>
               </Typography>
                        )
                },
                {title: getLabel("title"), field: 'title'},
                {title: getLabel("personal_amount"), field: 'personal_money_amount'},
                {title: getLabel("cash_box_amount"), field: 'cash_box_amount'},
                {title: getLabel("status"), field: 'status'},
                {title: getLabel("invoice_date"), field: 'invoice_date'},

               // {title: getLabel("date_created"), field: 'date_created'},
                // {title: getLabel("date_modified"), field: 'date_modified'},
            ]);


    const [search, setSearch] = useState("")
    const {setAlert} = useContext(AlertContext)

    const viewClaim = (id, tag) => {
        props.history.push({
            pathname: sub_directory +'my-claims/edit',
            state: {
                id: id,
                tag: tag
            }
        })
    };

    const reloadData = async () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleRejectButton = async (id, claim_id) => {
        setConfirmData({
            msg: `Are you sure to cancel claim ${claim_id} ??`,
            action: ()=>handleCancelClaim(id)
        })
    };

    const handleCancelClaim = async (id) => {
        let response = await httpRequestWithAuth(`${BaseFinanceMyClaimRequestURL}${id}/`, "PATCH", {status:"Cancelled"});
        if (response.status){
        await reloadData();
        }else{
            setAlert(response.error_feedback.non_field_errors)
        }
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length >= 5  || newValue === '') {
            reloadData();
        }
    };




        return (
            <Paper>

                <Grid container spacing={3}>

                    <Grid item xs={12}>

                        <TableToolbar
                            searchFieldName='search'
                            searchValue={search}
                            onSearchChangeHandle={handleSearchField}
                            searchAction={() => reloadData()}
                            actions={
                                [
                                    {
                                        hide: !Permissions.checkAllPermissions([permissions_data.claim.add]),
                                        onClick: () => props.history.push({
                                            pathname: `${sub_directory}my-claims/new`,
                                            state: {id: 'new'}
                                        }),
                                        label: "add_claim"
                                    },
                                ]
                            }
                        />

                            <MaterialTable
                                tableRef={tableRef}
                                columns={columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        let url = BaseFinanceMyClaimRequestURL;
                                        url += '?page=' + (query.page + 1);

                                        if (search !== '') {
                                            url += '&search=' + search;
                                        }


                                        instance.get(url)
                                            .then(result => {
                                                resolve({
                                                    data: result.data.results,
                                                    page: result.data.page - 1,
                                                    totalCount: result.data.total,
                                                })
                                            })
                                    })
                                }


                                {...material_table_style}
                                {...material_table_options()}
                                {...material_table_localization()}

                            />

                    </Grid>
                </Grid>
            </Paper>
        );

}

