import {httpRequestWithAuth, instance} from "../../../../auth/axios_instance";
import {BaseFinancePaymentsMethodURL} from "../../../../urls";

export const ListPaymentMethod = async (assigned_to = null) => {
    return await httpRequestWithAuth(BaseFinancePaymentsMethodURL, "GET", null, {assigned_to});
};


export const CreatePaymentMethod = async (data) => {
    return await httpRequestWithAuth(BaseFinancePaymentsMethodURL, "POST", data);
};


export const UpdatePaymentMethod = async (id, data) => {
    let url = BaseFinancePaymentsMethodURL + id + "/";
    return await httpRequestWithAuth(url, "PUT", data);
};


export const DeletePaymentMethod = async (id) => {
    let url = BaseFinancePaymentsMethodURL + id + "/";
    return await httpRequestWithAuth(url, "DELETE");
};


