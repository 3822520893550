import React, {useEffect, useState} from "react";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {LocationsAutoComplete} from "../../../../components/fields/LocationsAutoComplete";
import {CreateStaffPermission, ListPermissionGroupOfStaff, UpdateStaffPermission} from "../staff_apis";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {getLabel} from "../../../../localization/MainLocalization";
import Grid from "@material-ui/core/Grid";
import {not_validate_value} from "../../../../App";
import {Hide} from "../../../../components/Hide";
import {CustomModal} from "../../../../components/CustomModal";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {Loading} from "../../../../components/Loading";


export const StaffPermissionModal = props => {
    const [staff_permission, setStaffPermission] = useState(props.value || {})
    const [permissionGroups_options, setPermissionGroupsOptions] = useState([])
    const [helper_text, setHelperText] = useState({})
    const [is_entity_level, setIsEntityLevel] = useState(true)
    const [is_loading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            let response = await ListPermissionGroupOfStaff();
            if (response.status) {
                await setPermissionGroupsOptions(response.data);
                response.data.map(async Data => {
                    if (Data.id === staff_permission.permission_group) {
                        await setStaffPermission({...staff_permission, permission_group: Data})
                        await setIsEntityLevel(Data.entity_level);
                    }
                    return 0;
                });
            }
        }

        fetchData();
    }, [])


    const onChangeHandle = async (name, value) => {

        await setStaffPermission({...staff_permission, [name]: value})

        if (name === "permission_group") {
            await setIsEntityLevel(not_validate_value.includes(value) ? true : value.entity_level)
        }
    }

    const handleSaveForm = async () => {
        await setIsLoading(true);

        let staff_permission_data = {
            ...staff_permission,
            staff: props.staff_id,
            permission_group: not_validate_value.includes(staff_permission.permission_group) ? "" : staff_permission.permission_group.id,
            location: is_entity_level || staff_permission.all_locations ? "" : staff_permission.location
        }

        let response;
        if (staff_permission.id === undefined) {
            response = await CreateStaffPermission(staff_permission_data);
        } else {
            response = await UpdateStaffPermission(staff_permission_data.id, staff_permission_data)
        }

        if (response.status) {
            await props.handleModalClose()
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    }

    const handleAutoCompleteValue = () => {
        let value = null;
        permissionGroups_options.map(Data => {
            if (!not_validate_value.includes(staff_permission.permission_group) && Data.id === staff_permission.permission_group.id) {
                value = Data;
            }
            return 0;
        });

        return value;
    };

    return (
        <CustomModal
            open={props.open}
        >
            <Grid container spacing={3}>
                <FormHeader
                    title="pages.staff.staff_info.staff_permission.form.header"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12}>
                        <Autocomplete
                            ListboxProps={{className:"list-box-autocomplete"}}
                            renderOption={(option) => (
                                <span className="w-100 text-start">{option.name}</span>
                            )}
                            options={permissionGroups_options}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                                <TextField variant="outlined" {...params}
                                           label={<label>{getLabel("permission_group")}<span
                                               className='text-danger'>*</span></label>}
                                           helperText={helper_text.permission_group}
                                           error={helper_text.permission_group !== undefined}
                                           fullWidth
                                />
                            )}
                            value={handleAutoCompleteValue()}
                            onChange={(event, value) => onChangeHandle("permission_group", value)}
                        />
                    </Grid>

                    <Hide hide={is_entity_level}>
                        <Grid item xs={12}>
                            <LocationsAutoComplete
                                disabled={staff_permission.all_locations}
                                required={true}
                                name='location'
                                label="locations"
                                onChangeHandle={onChangeHandle}
                                error={helper_text.location !== undefined}
                                helper_text={helper_text.location}
                                value={staff_permission.location}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <CustomCheckBox
                                    value={staff_permission.all_locations}
                                    name='all_locations'
                                    onChangeHandle={onChangeHandle}
                                    label="all_locations"
                                />
                            </Grid>
                        </Grid>
                    </Hide>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                    />

                </Hide>
            </Grid>
        </CustomModal>
    );
}

