import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {not_validate_value} from '../../App';
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseClientsSearchURL} from "../../urls";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import {ClientModel} from "../ClientModel";
import {Hide} from "../Hide";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export const ClientAutoComplete = props => {
    const [options, setOptions] = useState([])
    const {location} = props || null
    const [create_client, setCreateClient] = useState(false)

    let FilterCustomerOption = async (search) => {
        let ListClientsResponse = await httpRequestWithAuth(BaseClientsSearchURL, "GET", null, {search, location});
        let clients = [];
        if (ListClientsResponse.status) {
            ListClientsResponse.data.map((Client) => {
                clients.push({
                    value: Client.id,
                    title: Client.full_name,
                    gender: Client.gender
                });
                return 0;
            });

            setOptions(clients)

        }
        return clients;
    }

    FilterCustomerOption = FilterCustomerOption.bind(this);


    const onChangeFilter = async value => {
        if (!not_validate_value.includes(value) && value.length > 4) {
            await FilterCustomerOption(value)
        }
    }

    const fetchClientData = async (value) => {
        let clients = await FilterCustomerOption(value);
        if (props.getClientGender !== undefined && clients.length === 1) {
            await props.getClientGender(clients[0].gender)
        }
    }

    useEffect(() => {
        fetchClientData(props.value);
    }, [])


    const handleAutoCompleteClientClose = (value) => {
        if (not_validate_value.includes(value)) {
            setOptions([])
        }
    };


    const handleAutoCompleteValue = () => {
        let value = null;
        options.map(Data => {
            if (Data.value === props.value) {
                value = Data;
            }
            return 0;
        });

        return value;
    };

    const onClientChange = async (event, value) => {
        if (props.getClientGender !== undefined) {
            await props.getClientGender(value !== null ? value.gender : "")
        }
        value = value !== null ? value.value : "";
        await props.onChangeHandle(props.name, value);
        await handleAutoCompleteClientClose(value)
    }

    const handleModalClose = () =>{
        setCreateClient(false);
    }

    const handleSaveClient = async (client_data) =>{
        await fetchClientData(client_data.id)
        await props.onChangeHandle(props.name, client_data.id);
    }

    return (
        <>
            <Hide hide={!create_client}>
                <ClientModel
                    open={create_client}
                    handleModalClose={handleModalClose}
                    handleActionSave={handleSaveClient}
                />
            </Hide>

            <Autocomplete
                ListboxProps={{className:"list-box-autocomplete"}}
                renderOption={(option) => (
                    <span className={"w-100 text-start"}>{option.title}</span>
                )}
                disabled={props.disabled || props.viewOnly}
                options={options}
                getOptionLabel={option => option.title}
                filterOptions={() => {
                    return options
                }}
                value={handleAutoCompleteValue()}
                renderInput={params => (
                    <TextField {...params}
                               onChange={(e) => onChangeFilter(e.target.value)}
                               label={<label>{getLabel(props.label)}<span
                                   className='text-danger'>{props.required ? "*" : ""}</span></label>}
                               variant="outlined"
                               error={props.error}
                               helperText={props.helper_text}
                               disabled={props.disabled}
                               className={props.disabled ? "disabled-field" : ""}
                               fullWidth
                               InputProps={{
                                   ...params.InputProps,
                                   // startAdornment: (
                                   //     <Tooltip title={getLabel('add_client')}>
                                   //         <IconButton size="small" onClick={()=>setCreateClient(true)}>
                                   //             <AddIcon className="text-success"/>
                                   //         </IconButton>
                                   //     </Tooltip>
                                   // )
                               }}
                    />
                )}
                onChange={onClientChange}
            />
        </>
    );
}
