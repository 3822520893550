import React, {useCallback, useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {LocationsAutoComplete} from "../../../../components/fields/LocationsAutoComplete";
import Grid from "@material-ui/core/Grid";
import {Hide} from "../../../../components/Hide";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseViewAthleteWorkoutAndAttendanceURL} from "../../../../urls";
import {not_validate_value, sub_directory} from "../../../../App";
import {CustomSelectField} from "../../../../components/fields/CustomSelectField";
import Avatar from "@material-ui/core/Avatar";
import {CustomTabs} from "../../../../components/CustomTabs";
import Tab from "@material-ui/core/Tab";
import {getLabel} from "../../../../localization/MainLocalization";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import {FormControlLabel, Switch} from "@material-ui/core";

export const AthleteWorkout = props => {
    const [setting, setSetting] = useState({date: moment().format("YYYY-MM-DD")});
    const [training_areas, setTrainingAreas] = useState([]);
    const [current_training_area, setCurrentTrainingArea] = useState({});
    const [classes, setClasses] = useState([]);
    const [current_session, setCurrentSession] = useState({})
    const [attendance, setAttendance] = useState([]);
    const [workouts, setWorkouts] = useState([]);
    const [currentTab, setCurrentTab] = useState(0)
    const [is_loading, setIsLoading] = useState(true);
    const [view_full_screen, setViewFullScreen] = useState(null);
    // const [full_screen_changed, setFullScreenChanged] = useState(0);
    const [auto_refresh, setAutoRefresh] = useState(false);
    const [reload, setReload] = useState(false);

    // const onScreenChange = () => {
    //     setFullScreenChanged(Math.random()*1000)
    // };

    useEffect(() => {
        document.addEventListener("fullscreenchange", ()=>{
            if (!document.fullscreenElement){
                setViewFullScreen(null);
            }
        }, false);
    }, []);

    useEffect(()=>{

        if (!not_validate_value.includes(view_full_screen)){
        let elem = document.documentElement;
        if (view_full_screen){
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
        }else{
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
        }

    },[view_full_screen])

    const handleClassChange = async (name, data) => {
        if (not_validate_value.includes(data)) {
            data = {}
        }

        if (current_session.id !== data.id) {
            setCurrentSession({...data});
            setWorkouts([...(data.workout_section || [])]);
            setAttendance([...(data.attendance || [])]);
            setCurrentTab(0);
        }
    }

    const handleTrainingAreaChange = async (name, data) => {
        let temp_current_class = {};
        let temp_current_class_attendance = [];
        let temp_current_workout = [];

        if (!not_validate_value.includes(data)) {
            data.classes.map(Session => {
                if (current_session.id === Session.id) {
                    temp_current_class = {...Session};
                }
            })

            if (data.is_all_day_workouts) {
                temp_current_workout = [...data.all_day_workouts];
            } else if (!not_validate_value.includes(temp_current_class.id)) {
                temp_current_workout = [...temp_current_class.workout_section];
            } else {
                temp_current_workout = [];
            }

            if (!not_validate_value.includes(temp_current_class.id)) {
                temp_current_class_attendance = [...temp_current_class.attendance]
            }
        } else {
            data = {}
        }


        await setCurrentTrainingArea(data);
        await setClasses([...(data.classes || [])]);
        await setCurrentSession(temp_current_class);
        await setAttendance(temp_current_class_attendance);
        await setWorkouts(temp_current_workout);

    }


    const checkCurrentClass = async TrainingArea => {
        let format = 'hh:mm:ss';

        let time_now = moment();

        await TrainingArea.classes.map(async Session => {
            let start = moment(Session.start_time, format);
            let end = moment(Session.end_time, format);

            if (time_now.isBetween(start, end)) {
                await setClasses(TrainingArea.classes);
                await setCurrentSession(Session);
                await setAttendance(Session.attendance || []);
                await setWorkouts(Session.workout_section);
            }
        })
    }

    const ListData = async () => {
        if (!not_validate_value.includes(setting.date) && !not_validate_value.includes(setting.location)) {
            let response = await httpRequestWithAuth(BaseViewAthleteWorkoutAndAttendanceURL, "GET", null, setting)

            if (response.status) {
                const training_areas = response.data;
                await setTrainingAreas(training_areas);

                if (!not_validate_value.includes(current_training_area.id) ||
                    (not_validate_value.includes(current_training_area.id) && training_areas.length > 0)) {
                    let is_available = false
                    training_areas.map(async (TrainingArea) => {
                        if (TrainingArea.id === current_training_area.id) {
                            is_available = true;
                            await handleTrainingAreaChange("training_area", TrainingArea);
                            await checkCurrentClass(TrainingArea);
                        }
                    })

                    if (!is_available) {
                        if (training_areas.length > 0) {
                            await handleTrainingAreaChange("training_area", training_areas[0]);
                            await checkCurrentClass(training_areas[0]);
                        } else {
                            await setCurrentTrainingArea({});
                            await setCurrentSession({});
                            await setAttendance([]);
                        }
                    }
                }
            }

        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            await ListData();
            await setIsLoading(false);
        }

        fetchData();
    }, [setting.location, setting.date])

    useEffect(() => {
        const fetchData = async () => {
            if (auto_refresh) {
                await ListData();
            }
        }

        fetchData();
    }, [reload])

    useEffect(() => {
        const updateData = () => {
            let value = true;
            setInterval(() => {
                value = !value;
                setReload(value);
            }, 60000)
        }

        updateData();
    }, [])

    const handleSettingChange = (name, value) => {
        let new_setting = {...setting}

        new_setting[name] = value
        if (name === "location"){
            setTrainingAreas([]);
            handleTrainingAreaChange("training_area", null)
        }
        setSetting(new_setting);
    }

    const backDate = async () => {
        await setSetting({
            ...setting, date: moment(setting.date).add(-1, 'day').format("YYYY-MM-DD")
        });

    }

    const nextDate = async () => {
        await setSetting({
            ...setting, date: moment(setting.date).add(1, 'day').format("YYYY-MM-DD")
        });
    }


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };


    const viewAthlete = (id, tag) => {
        props.history.push({
            pathname: sub_directory + "athletes/edit",
            state: {
                id: id,
                tag: tag
            }
        })
    }
    return (
                <div  className={`${view_full_screen?"full-screen ": ""}  card-paper-1 h-100 w-100 overflow-auto`}>
                    <div className="text-end pr-2 pl-2 align-items-center">
                        <FormControlLabel
                            control={<Switch color="primary" checked={auto_refresh}
                                             onChange={() => setAutoRefresh(!auto_refresh)}/>}
                            label={getLabel("auto_refresh")}
                        />

                        <FormControlLabel
                            control={<Switch color="primary" checked={view_full_screen} onChange={() => {
                                let new_value = not_validate_value.includes(view_full_screen)? true: !view_full_screen
                                setViewFullScreen(new_value);
                            }}/>}
                            label={getLabel("full_screen")}
                        />

                        {/*{view_full_screen?(*/}
                        {/*    <Button onClick={()=> {*/}
                        {/*        setViewFullScreen(!view_full_screen);*/}
                        {/*    }} startIcon={<FullscreenExitIcon/>}>Exit Full Screen</Button>*/}
                        {/*):(*/}
                        {/*    <Button onClick={()=> {*/}
                        {/*        setViewFullScreen(!view_full_screen);*/}
                        {/*        onRequest();*/}
                        {/*    }} startIcon={<FullscreenIcon/>}>Full Screen</Button>*/}
                        {/*)}*/}

                    </div>


                    <div className="d-md-inline-flex w-100 align-items-center justify-content-md-between text-end">
                        <Typography variant="h6" className="text-start">
                            Athlete Training
                        </Typography>

                        <div className="d-inline-flex align-items-center justify-content-center">
                            <IconButton onClick={backDate} disabled={is_loading}>
                                <ArrowBackIosIcon/>
                            </IconButton>

                            <Typography variant="body1" color="textSecondary" component="p">
                                {`${moment(setting.date, "YYYY-MM-DD").format("dddd, DD MMM YYYY")}`}
                            </Typography>

                            <IconButton onClick={nextDate} disabled={is_loading}>
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        </div>
                    </div>


                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <LocationsAutoComplete
                                selectFirstByDefault
                                label="location"
                                name="location"
                                value={setting.location}
                                onChangeHandle={handleSettingChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <CustomSelectField
                                options={training_areas}
                                valueKey="id"
                                titleKey="name"
                                value={current_training_area.id}
                                onChangeHandle={handleTrainingAreaChange}
                                name="training_area"
                                label="training_area"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <CustomSelectField
                                options={classes}
                                valueKey="id"
                                titleKey="start_time_view"
                                value={current_session.id}
                                onChangeHandle={handleClassChange}
                                name="classes"
                                label="classes"
                            />
                        </Grid>

                        <Grid item xs={12} className="mb-3">
                            <CustomTabs
                                currentTab={currentTab}
                                handleTabsChange={handleTabsChange}
                            >


                                <Tab label={getLabel("attendance")}/>


                                <Tab label={getLabel("training")}/>

                            </CustomTabs>
                        </Grid>

                        <Hide hide={currentTab !== 0}>

                            <Hide hide={attendance.length > 0}>
                                <Typography variant="h5" className="ml-2 mr-2 w-100 text-center">
                                    {getLabel("empty_data_source_message")}
                                </Typography>
                            </Hide>

                            <Hide hide={not_validate_value.includes(current_session.id)}>
                                {attendance.map((Data, Index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={Index}
                                          className="w-100 p-1">

                                        <div
                                            className="p-2 w-100 h-100 text-center border border-radius align-items-center">

                                            <div className="w-100 text-end">
                                                <Chip
                                                    color={["unregistered", "absent"].includes(Data.status) ? "secondary" : Data.status === "present" ? "primary" : ""}
                                                    label={getLabel(Data.status)}/>
                                            </div>


                                            <Avatar src={Data.photo} className="mr-auto ml-auto"
                                                    onClick={() => viewAthlete(Data.athlete_id, Data.athlete_name)}
                                                    style={{width: "100px", height: "100px"}}/>

                                            <Typography variant="h6" className="mt-3">
                                                <Link href="" onClick={e => {
                                                    e.preventDefault();
                                                    viewAthlete(Data.athlete_id, Data.athlete_name)
                                                }}>
                                                    {Data.athlete_name}
                                                </Link>
                                            </Typography>


                                            {Data.attendance_percentage.map(AttendancePercentageData => (
                                                <Typography variant="subtitle1" className="mt-2">
                                                    {`${AttendancePercentageData.team_name}: `}
                                                    <span className="font-weight-bold">{AttendancePercentageData.attendance_percentage}</span>
                                                    <p>{`${getLabel("optional_attended_classes")}: `}
                                                        <span className="font-weight-bold">{AttendancePercentageData.optional_attendance_classes}</span>
                                                    </p>
                                                </Typography>
                                            ))}
                                        </div>

                                    </Grid>
                                ))}


                            </Hide>

                        </Hide>

                        <Hide hide={currentTab !== 1}>

                            <Hide hide={workouts.length > 0}>
                                <Typography variant="h5" className="pt-5 pb-5 ml-2 mr-2  w-100 text-center">
                                    {getLabel("empty_data_source_message")}
                                </Typography>
                            </Hide>

                            {workouts.map((Workout, index) => (
                                <Grid item xs={12} md={6} className="pr-3 pl-3" key={index}>
                                    <Typography variant="h6" className="border-bottom font-weight-bold">
                                        {Workout.header}
                                    </Typography>

                                    <div className="h-100 margin-left-1">
                                        <Typography variant="subtitle1" component="h6"
                                                    className="font-weight-bold pb-3 pre-line m-0">
                                            {Workout.content}
                                        </Typography>
                                    </div>
                                </Grid>
                            ))}
                        </Hide>

                    </Grid>
                </div>
    )
}
