import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {not_validate_value} from '../../App';
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseAthleteSearchURL} from "../../urls";

export const AthleteAutoComplete = props => {
    const [options, setOptions] = useState([])

    let FilterCustomerOption = async (search) => {
        let response = await httpRequestWithAuth(BaseAthleteSearchURL, "GET", null, {search});
        let athletes = [];
        if (response.status) {
            setOptions(response.data);
            athletes = response.data;
        }
        return athletes;
    }

    FilterCustomerOption = FilterCustomerOption.bind(this);


    const onChangeFilter = async value => {
        if (!not_validate_value.includes(value) && value.length > 4) {
            await FilterCustomerOption(value)
        }
    }

    const fetchData = async (value) => {
        let athletes = await FilterCustomerOption(value);
    }

    useEffect(() => {
        fetchData(props.value);
    }, [])


    const handleAutoCompleteClose = (value) => {
        if (not_validate_value.includes(value)) {
            setOptions([])
        }
    };


    const handleAutoCompleteValue = () => {
        let value = null;
        options.map(Data => {
            if (Data.id === props.value) {
                value = Data;
            }
            return 0;
        });

        return value;
    };

    const onChange = async (event, value) => {

        if (not_validate_value.includes(value)){
            value = "";
        }else if (!props.getFullObject){
            value = value.id;
        }

        await props.onChangeHandle(props.name, value);
        await handleAutoCompleteClose(value)
    }

    return (
        <>

            <Autocomplete
                ListboxProps={{className:"list-box-autocomplete"}}
                renderOption={(option) => (
                    <span className={"w-100 text-start"}>{option.full_name}</span>
                )}
                disabled={props.disabled || props.viewOnly}
                options={options}
                getOptionLabel={option => option.full_name}
                filterOptions={() => {
                    return options
                }}
                value={handleAutoCompleteValue()}
                renderInput={params => (
                    <TextField {...params}
                               onChange={(e) => onChangeFilter(e.target.value)}
                               label={<label>{getLabel(props.label)}<span
                                   className='text-danger'>{props.required ? "*" : ""}</span></label>}
                               variant="outlined"
                               error={props.error}
                               helperText={props.helper_text}
                               disabled={props.disabled}
                               className={props.disabled ? "disabled-field" : ""}
                               fullWidth
                               size={props.size}
                    />
                )}
                onChange={onChange}
            />
        </>
    );
}
