import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseMembershipChangeDiscountURL, BaseMembershipURL} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {DisabledTextField} from "../../../../components/fields/DisabledField";
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {PaymentMethods} from "../../../../components/PaymentMethods";
import moment from "moment";
import {getLabel} from "../../../../localization/MainLocalization";
import Typography from "@material-ui/core/Typography";
import Permissions from "../../../../auth/permissions";
import {permissions_data} from "../../../../auth/permission_data";


export const ChangeDiscountModal = props => {
    const [data, setData] = useState({
        ...props.default_value,
        payment_type: "Refund",
        payment_date: moment().format("YYYY-MM-DD"),
    });
    const [add_refund, setAddRefund] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(false);

    const handleDiscountChange = async (name, newValue) => {
        let temp_data = {...data};
        if (newValue === '') {
            newValue = 0;
        } else {
            newValue = parseFloat(newValue);
        }
        temp_data['discount'] = newValue;
        temp_data['total_price'] = Math.max(0, parseFloat(temp_data.original_price) - newValue);

        await setData(temp_data);
    };

    const handleFieldChange = async (name, newValue) => {
        await setData({...data, [name]: newValue});
    }

    const handleSaveButton = async () => {
        await setIsLoading(true);

        let url = `${BaseMembershipURL}${props.membership}/${BaseMembershipChangeDiscountURL}`;
        let response = await httpRequestWithAuth(url, "PATCH", data)

        if (response.status) {
            await props.handleModalClose(true)
        } else {
            if (response.error_feedback.paid_more_error && Permissions.checkPermission(permissions_data.membership_payment.add_refund)){
                await setData({...data, 'amount': response.error_feedback.paid_more_amount, add_refund: true});
                await setAddRefund(true);
            }
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };


    return (

        <CustomModal
            open={props.open}
        >
            <Grid container spacing={2}>

                <FormHeader
                    title="change_discount"
                    handleClose={() => props.handleModalClose(false)}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>

                    <Grid item xs={12}>
                        <CustomTextField
                            disabled={add_refund}
                            name="discount"
                            label="discount"
                            type="number"
                            error={helper_text.discount !== undefined}
                            helper_text={helper_text.discount}
                            value={data.discount}
                            onChangeHandle={handleDiscountChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DisabledTextField
                            label="original_price"
                            value={data.original_price}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DisabledTextField
                            label="total_price"
                            value={data.total_price}
                        />
                    </Grid>

                    <Hide hide={!add_refund}>

                        <Grid item xs={12}>
                            <Typography variant="h6" className='p-1'>
                                {getLabel("add_refund")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <PaymentMethods
                                disableAmount
                                assigned_to="memberships"
                                required
                                new_payment={data}
                                helper_text={helper_text}
                                handleNewPaymentFieldChange={handleFieldChange}
                            />
                        </Grid>
                    </Hide>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={() => props.handleModalClose(false)}
                        handleSaveButton={handleSaveButton}
                    />
                </Hide>
            </Grid>
        </CustomModal>
    );
}