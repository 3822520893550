import React, {useContext, useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {Hide} from "../../../components/Hide";
import {permissions_data} from "../../../auth/permission_data";
import {sub_directory} from "../../../App";
import {ActionButtons} from "../../../components/ActionButton";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseFinanceClaimAttachmentURL, BaseFinanceMyClaimAttachmentRequestURL} from "../../../urls";
import {CreateClaimAttachments, UpdateClaimAttachments} from "./claim_apis";
import {AlertContext} from "../../../store/use_context";
import {ClaimAttachmentFormModal} from "./components/ClaimAttachmentFormModal";


export const ClaimAttachment = props => {
    const claim_id = props.claim_id;
    const {setAlert} = useContext(AlertContext);
    const url = props.staff_view ? BaseFinanceClaimAttachmentURL : BaseFinanceMyClaimAttachmentRequestURL;

    const columns = [
        {title: getLabel("name"), field: 'name'},
    ];

    const [attachments, setAttachments] = useState([])
    const [helper_text, setHelperText] = useState({})
    const [form_open, setFormOpen] = useState(false)
    const [form_data, setFormData] = useState({})
    const [is_loading, setIsLoading] = useState({})
    const [edit_attach, setEditAttach] = useState(true)


    const ListAttachmentsData = async () => {
        await setIsLoading(true)

        let response = await httpRequestWithAuth(url, "GET", null, {claim: claim_id});
        if (response.status) {
            await setAttachments(response.data)
        }

        await setIsLoading(false)
    };

    useEffect(() => {
        ListAttachmentsData();
    }, [])

    const onChangeHandle = (name, value) => {
        setFormData({...form_data, [name]: value});
    }

    const handleModalOpen = (data, edit) => {
        setEditAttach(edit)
        setFormData(data)
        setFormOpen(true)
    };

    const handleModalClose = async () => {
        await ListAttachmentsData();
        await setHelperText({});
        setFormData({})
        setFormOpen(false)
    };


    const handleBackButton = () => {
        props.history.push(sub_directory + "my-claims")
    }

    const handleSaveModal = async () => {
        await setIsLoading(true)
        let response;
        if (form_data.id === undefined) {
            response = await CreateClaimAttachments(url, {claim: claim_id, ...form_data});
        } else {
            response = await UpdateClaimAttachments(url, form_data.id, {name: form_data.name});
        }

        if (response.status) {
            await handleModalClose();
        } else {
            setHelperText(response.error_feedback)
        }

        await setIsLoading(false)
    };


    return (
        <>
            <Hide hide={!form_open}>
                <ClaimAttachmentFormModal
                    open={form_open}
                    handleModalClose={handleModalClose}
                    data={form_data}
                    handleSaveForm={handleSaveModal}
                    helper_text={helper_text}
                    onChangeHandle={onChangeHandle}
                    is_loading={is_loading}
                    edit_attach={edit_attach}
                />
            </Hide>

            <Grid item xs={12}>
                <MaterialTable
                    style={{padding: '0.5rem', boxShadow: "none"}}
                    is_loading={is_loading}
                    columns={columns}
                    data={attachments}
                    onRowClick={(event, rowData) => window.open(rowData.file)}
                    editable={Permissions.checkPermission(permissions_data.claim_attachment[props.staff_view ? "delete_staff" : "delete"]) && {
                        onRowDelete: async attachmentData => {
                            let response = await httpRequestWithAuth(`${url}${attachmentData.id}/`, "DELETE")
                            if (response.status) {
                                await ListAttachmentsData();
                            } else {
                                setAlert(response.error_feedback.non_field_errors)
                            }
                        }
                    }}

                    actions={[
                        {
                            icon: () => (
                                <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                        startIcon={<AddIcon/>}>
                                    <Typography variant="body1">{getLabel('add_attachment')}</Typography>
                                </Button>
                            ),
                            hidden: !Permissions.checkAllPermissions([permissions_data.claim_attachment[props.staff_view ? "add_staff" : "add"]]),
                            isFreeAction: true,
                            onClick: () => handleModalOpen({}, true)
                        },
                        {
                            icon: () => (
                                <Button  size="small"
                                         color="primary"
                                         variant="outlined">
                                    {getLabel("view_file")}
                                </Button>
                            ),
                            tooltip:getLabel('view_file'),
                            onClick: (event, rowData) => window.open(rowData.file)
                        },
                        {
                            icon: 'edit',
                            tooltip: getLabel('edit_attachment'),
                            hidden: !Permissions.checkAllPermissions([permissions_data.claim_attachment[props.staff_view ? "change_staff" : "change"]]),
                            onClick: (event, rowData) => handleModalOpen(rowData, false)
                        }
                    ]}

                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        showTitle: false
                    }}

                    {...material_table_localization()}
                />
            </Grid>


            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />
        </>
    );

}