import React, {useEffect, useState} from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import Typography from "@material-ui/core/Typography";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseMembershipsStatusReportURL} from "../../../../urls";
import moment from "moment";
import {DateField} from "../../../../components/fields/DateField";
import {Grid} from "@material-ui/core";
import {LocationsAutoComplete} from "../../../../components/fields/LocationsAutoComplete";
import {not_validate_value} from "../../../../App";
import {CustomRadioGroup} from "../../../../components/CustomRadioGroup";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {ServiceAutoComplete} from "../../../../components/fields/ServiceAutoComplete";


const duration_type_options = [
    {title: "yearly", value: "yearly"},
    {title: "monthly", value: "monthly"},
    {title: "daily", value: "daily"},
]

const status_options = [
    {title: "all", value: "all"},
    {title: "new_memberships", value: "new_memberships"},
    {title: "renewed_memberships", value: "renewed_memberships"},
]


const data1 = [
    {
        name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
        name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
        name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
        name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
        name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
        name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
        name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
];


export const MembershipsStatus = props => {
    const [data, setData] = useState({total: {all:0}})
    const [is_loading, setIsLoading] = useState(true)
    const [min_date, setMinDate] = useState(moment())
    const [total, setTotal] = useState(0)
    const [setting, setSetting] = useState({
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        duration_type: "yearly",
        location: "", service: "", status:"all",
        new_memberships:true, renewed_memberships: true
    })

    const handleSettingChange = async (name, value) => {
        if (name === 'location' && not_validate_value.includes(value)) {
            value = ""
        }

        await setSetting({...setting, [name]: value})
    };

    const handleDateChange = async (id, date) => {
        let data = {...setting, [id]: moment(date).format("YYYY-MM-DD")}

        if (id === 'from_date' && moment(date).isAfter(data.to_date)) {
            data = {...setting, to_date: moment(date).format("YYYY-MM-DD")}
        }

        await setSetting(data);
    };


    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            const {from_date, to_date, location, duration_type, service} = setting
            let response = await httpRequestWithAuth(BaseMembershipsStatusReportURL, "GET", null,
                {from_date, to_date, location: not_validate_value.includes(location) ? null : location, service: not_validate_value.includes(service) ? null : service,  duration_type});

            if (response.status) {
                await setData(response.data)
                await setMinDate(response.data.min_date)
            }

            await setIsLoading(false);
        }

        fetchData();
    }, [setting.from_date, setting.to_date, setting.time_type, setting.location, setting.duration_type, setting.service])

    return (
        <div className='card-paper-1 h-100 w-100'>
            <Typography variant="h6" className="text-start mb-2">
                Memberships Status
            </Typography>

            <Grid container spacing={2} className="mb-3 mt-2">

                <Grid item xs={12} md={6}>
                    <CustomRadioGroup
                        label="duration_type"
                        name="duration_type"
                        value={setting.duration_type}
                        onChangeHandle={handleSettingChange}
                        options={duration_type_options}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomRadioGroup
                        label="status"
                        name="status"
                        value={setting.status}
                        onChangeHandle={handleSettingChange}
                        options={status_options}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DateField
                        date_view={setting.duration_type}
                        name="from_date"
                        label="from_date"
                        maxDate={moment()}
                        min_date={min_date}
                        value={setting.from_date}
                        onChangeHandle={handleDateChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DateField
                        date_view={setting.duration_type}
                        maxDate={moment()}
                        min_date={setting.from_date}
                        name="to_date"
                        label="to_date"
                        value={setting.to_date}
                        onChangeHandle={handleDateChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <LocationsAutoComplete
                        allLocationOption
                        name='location'
                        label="location"
                        onChangeHandle={handleSettingChange}
                        value={setting.location}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <ServiceAutoComplete
                        allServiceOption
                        value={setting.service}
                        onChangeHandle={handleSettingChange}
                        name="service"
                        label="service"
                    />
                </Grid>

                {/*<Grid item xs={12} className="d-inline-flex">*/}
                {/*    <CustomCheckBox*/}
                {/*        value={setting.new_memberships}*/}
                {/*        name='new_memberships'*/}
                {/*        onChangeHandle={handleSettingChange}*/}
                {/*        label="new_memberships"*/}
                {/*    />*/}
                {/**/}
                {/*    <CustomCheckBox*/}
                {/*        value={setting.renewed_memberships}*/}
                {/*        name='renewed_memberships'*/}
                {/*        onChangeHandle={handleSettingChange}*/}
                {/*        label="renewed_memberships"*/}
                {/*    />*/}
                {/*</Grid>*/}

            </Grid>

            <Typography component="p" variant="h5" className="text-end mb-2">
                {`Total memberships: ${data.total[setting.status]}`}
            </Typography>

            <Hide hide={!is_loading}>
                <div className="fixedHeight-400">
                    <Loading/>
                </div>
            </Hide>

            <Hide hide={is_loading}>
                <div dir='ltr' className="h-100 w-100">
                    <ResponsiveContainer width="100%" height='90%' className="fixedHeight-400">
                        <BarChart
                            data={data.details}
                            margin={{
                                top: 20, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Tooltip/>


                            {['new_memberships', 'all'].includes(setting.status) && (
                                <Bar dataKey="New memberships"  barSize={30} stackId="a" fill="#8884d8"/>
                            )}

                            {['renewed_memberships', 'all'].includes(setting.status) && (
                                <Bar dataKey="Renewed memberships" barSize={30} stackId="a" fill="#3f51b5"/>
                            )}

                            {/*<Bar dataKey="pv" stackId="a" fill="#8884d8" />*/}
                            {/*<Bar dataKey="amt" stackId="a" fill="#82ca9d" />*/}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Hide>

        </div>
    );
}
