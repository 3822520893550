import React from "react";
// import MomentUtils from "@date-io/moment";
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {getLabel} from "../../localization/MainLocalization";
// zimport DateFnsUtils from "@date-io/date-fns";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import {not_validate_value} from "../../App";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {DisabledDateField} from "./DisabledDateField";

const date_view_options = {
    'yearly': ["year"],
    'monthly':  ["year", "month"],
}


export const DateField = props=>{
    const handleDateChange = date =>{
        if (!props.viewOnly){
        let value = moment(date).format("YYYY-MM-DD");
        if(value === "Invalid date"){
            value = null
        }
        props.onChangeHandle(props.name, value)
        }
    }

    const handleClearDate = e => {
        e.stopPropagation();
        props.onChangeHandle(props.name, null)
    }

    if (props.viewOnly){
        return (
            <DisabledDateField
            label={props.label}
            value={props.value}
            disabled={props.disabled}
            />
        )
    }

    return(
        <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    views={props.date_view ? date_view_options[props.date_view]: props.openToYear && ["year", "month", "date"]}
                    autoOk
                    disableToolbar
                    openTo={props.openToYear && "year"}
                    disabled={props.viewOnly}
                    className={props.disabled ? "disabled-field" : ""}
                    error={props.error}
                    helperText={props.helper_text || null}
                    inputVariant="outlined"
                    variant="inline"
                    format={not_validate_value.includes(date_view_options[props.date_view]) && "DD-MM-YYYY"}
                    label={<label> {getLabel(props.label)}<span className='text-danger'>{props.required ? "*" : ""}</span></label>}
                    minDate={props.min_date || undefined}
                    fullWidth
                    maxDate={props.maxDate || undefined}
                    value={not_validate_value.includes(props.value)? null : props.value || null}
                    onChange={handleDateChange}
                    InputProps={!props.viewOnly && !props.disabled &&{
                         endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClearDate}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
        </MuiPickersUtilsProvider>
    );
}
