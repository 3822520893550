import {useDropzone} from 'react-dropzone'
import Typography from "@material-ui/core/Typography";
import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import {not_validate_value} from "../App";
import "../assets/scss/Static.scss";
import {getLabel} from "../localization/MainLocalization";
import Button from "@material-ui/core/Button";

export const DropNDrag = props => {
    const onDrop = file =>{
        props.onChangeHandle(props.name, file[0])
    }
    // const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (
        <>
        <div {...getRootProps()} className="Drag-n-Drop">
            <input {...getInputProps()}/>
                {not_validate_value.includes(props.value)?(
                    <Typography variant="h6" className="font-weight-bold mt-2">
                    "Click or Drop Attachment here"
                    </Typography>
                    )

                    :(
                        <>
                        <Typography variant="h6" className="font-weight-bold">
                    "File uploaded"
                        </Typography>
                            <Typography variant="h6" className="font-weight-bold">
                                {props.value.name}
                            </Typography>
                            <Button variant="contained" color="primary" className="mt-3" onClick={()=>onDrop("")}>{getLabel("remove_file")}</Button>
                        </>
                    )
                }
        </div>
            <FormHelperText className="text-danger">{props.helper_text}</FormHelperText>
        </>
    );
}
