import React from "react";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import {getLabel} from "../localization/MainLocalization";

export const CustomStepper = props => {
    return(
        <Grid item xs={12}>
            <Stepper activeStep={props.current_step} className="stepper">
                {props.steps.map((label) => (
                    <Step key={label} className="step">
                        <StepLabel>{getLabel(label)}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Grid>
    );
}