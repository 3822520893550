import React, {useContext, useRef, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import MaterialTable from 'material-table';
import {BaseAthleteSeasonURL} from "../../../urls";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import Chip from "@material-ui/core/Chip";
import Permissions from '../../../auth/permissions';
import Button from "@material-ui/core/Button";
import {sub_directory} from "../../../App";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {Hide} from "../../../components/Hide";
import {httpRequestWithAuth, instance} from "../../../auth/axios_instance";
import {permissions_data} from "../../../auth/permission_data";
import {AlertContext} from "../../../store/use_context";
import {SeasonFormModal} from "./components/SeasonFormModal";
import {Groups} from "./groups/Groups";


export const Seasons = props => {

    const tableRef = useRef();
    const {setAlert} = useContext(AlertContext);
    const [material_table_localization_value] = useState(material_table_localization());
    const [material_table_options_value] = useState(material_table_options({
        rowStyle: {
            backgroundColor: 'whitesmoke',
        }
    }));

    const [columns, setCoumns] = useState([
        {title: getLabel('season_name'), field: 'name', cellStyle: {width: "30%"}},
        {title: getLabel('service'), field: 'service_name'},
        {title: getLabel('start_date'), field: 'start_date_view'},
        {title: getLabel('end_date'), field: 'end_date_view'},
         {
             title: getLabel('status'),
             render: rowData => {
                 return (
                     <Chip color={rowData.is_active ? "primary" : "default"} label={getLabel(rowData.is_active_view)}/>)
            },
        },

    ]);

    const [form_open, setFormOpen] = useState(false);
    const [new_season, setNewSeason] = useState({});
    const [search, setSearch] = useState("");

    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleModalOpen = async data => {
        if (data !== 'new') {
            await setNewSeason(data)
        }
        await setFormOpen(true)
    };

    const handleModalClose = async () => {
        await reloadData();
        await setFormOpen(false)
        await setNewSeason({})
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length >= 5 || newValue.length === 0) {
            reloadData()
        }
    }


    return (
        <Paper>


            <Hide hide={!form_open}>
                <SeasonFormModal
                    default_data={new_season}
                    open={form_open}
                    handleModalClose={handleModalClose}
                />
            </Hide>

            <TableToolbar
                searchFieldName='search'
                searchValue={search}
                onSearchChangeHandle={handleSearchField}
                searchAction={reloadData}
                match={props.match}
                actions={
                    [
                        {
                            hide: !Permissions.checkAllPermissions([permissions_data.season.add]),
                            onClick: () => handleModalOpen('new'),
                            label: "add_season"
                        },
                    ]
                }
            />

            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={query =>
                    new Promise((resolve, reject) => {
                        let url = BaseAthleteSeasonURL;
                        url += '?page=' + (query.page + 1);

                        if (search !== '') {
                            url += '&search=' + search;
                        }

                        instance.get(url)
                            .then(result => {
                                resolve({
                                    data: result.data.results,
                                    page: result.data.page - 1,
                                    totalCount: result.data.total,
                                })
                            })
                    })
                }

                editable={
                    Permissions.checkPermission(permissions_data.season.delete) && {
                        onRowDelete: async rowData => {
                           let response =  await httpRequestWithAuth(`${BaseAthleteSeasonURL}${rowData.id}/`, "DELETE");
                           if (response.status){
                                await reloadData();
                           }else{
                               setAlert(response.error_feedback.non_field_errors)
                           }
                        }
                    }}

                actions={[
                    {
                        icon: 'edit',
                        hidden: !Permissions.checkAllPermissions([permissions_data.season.change]),
                        tooltip: getLabel('edit_season'),
                        onClick: (event, rowData) => handleModalOpen(rowData)
                    }
                ]}


                detailPanel={rowData => (
                    <Groups {...props} season={rowData.id} season_name={rowData.name}/>
                )}


                {...material_table_style}
                {...material_table_options_value}
                {...material_table_localization_value}
            />
        </Paper>

    );

}
