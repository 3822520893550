import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CustomModal} from "../../../../components/CustomModal";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {Loading} from "../../../../components/Loading";
import {Hide} from "../../../../components/Hide";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseBenchmarkURL} from "../../../../urls";

export const BenchFormModal = props => {
    const [helper_text, setHelperText] = useState({})
    const [data, setData] = useState({is_active:true, ...props.default_data})
    const [is_loading, setIsLoading] = useState(false)

    const handleFieldChange = (name, newValue) => {
        setData({...data, [name]: newValue})
    };

    const handleSaveForm = async () => {
        await setIsLoading(true);
        let response;
        if (data.id === undefined) {
            response = await httpRequestWithAuth(BaseBenchmarkURL, "POST", data)
        } else {
            response = await httpRequestWithAuth(`${BaseBenchmarkURL}${data.id}/`, "PATCH", data)
        }

        if (response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="benchmark_form"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>
                        <Grid item xs={12}>
                            <CustomTextField
                                required
                                error={helper_text.name !== undefined}
                                helper_text={helper_text.name}
                                name="name"
                                label="name"
                                value={data.name}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                error={helper_text.unit !== undefined}
                                helper_text={helper_text.unit}
                                name="unit"
                                label="unit"
                                value={data.unit}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                error={helper_text.score_info !== undefined}
                                helper_text={helper_text.score_info}
                                name="score_info"
                                label="score_info"
                                value={data.score_info}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <CustomCheckBox
                                value={data.reps}
                                name='reps'
                                onChangeHandle={handleFieldChange}
                                label="reps"
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <CustomCheckBox
                                value={data.rounds}
                                name='rounds'
                                onChangeHandle={handleFieldChange}
                                label="rounds"
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <CustomCheckBox
                                value={data.time}
                                name='time'
                                onChangeHandle={handleFieldChange}
                                label="time"
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <CustomCheckBox
                                value={data.weight}
                                name='weight'
                                onChangeHandle={handleFieldChange}
                                label="weight"
                            />
                        </Grid>


                        <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={props.handleModalClose}
                            handleSaveButton={handleSaveForm}
                        />
                    </Hide>

                </Grid>
            </CustomModal>
        </>
    );
};
