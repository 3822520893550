import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {FormHeader} from "../../../../components/FormHeader";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {not_validate_value} from "../../../../App";
import {Hide} from "../../../../components/Hide";
import {StaffAutoComplete} from "../../../../components/fields/StaffAutoComplete";
import {CustomRadioGroup} from "../../../../components/CustomRadioGroup";
import {Loading} from "../../../../components/Loading";
import moment from "moment";
import {PaymentMethodAutoComplete} from "../../../../components/fields/PaymentMethodAutoComplete";
import {DateField} from "../../../../components/fields/DateField";
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseFinanceClaimPaymentsURL} from "../../../../urls";


export const ClaimPaymentModal = props => {
    const [new_payment, setNewPayment] = useState( {
        [props.name]: props.id,
        payment_type: "Payment",
        payment_date: moment().format("YYYY-MM-DD")
    });

    const view_options = [
        {title: "personal_money", value: "Personal Money"},
        {title: "cash_box", value: "Cash Box"},
    ]

    const [helper_text, setHelperText] = useState( {})
    const [total_paid, setTotalPaid] = useState( )
    const [show_cash_box, setShowCashBox] = useState( false)
    const [is_loading, setIsLoading] = useState( false)

    const handlePaymentFormSaveButton = async () => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(BaseFinanceClaimPaymentsURL, "POST", {claim:props.claim, ...new_payment})
        if (response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };

    const handleNewPaymentFieldChange = (name, newValue) => {
        if (name === "payment_method"){
            if (!not_validate_value.includes(newValue)) {
                if (newValue.name === "Cash") {
                    setShowCashBox(true)
                } else {
                    setShowCashBox(false)
                }
                newValue = newValue.id;
            }else{
                setShowCashBox(false)
            }
        }

            setTotalPaid(parseFloat(total_paid) + (parseInt(newValue === '' ? 0 : newValue) - parseFloat(new_payment[name] === '' ? 0 : new_payment[name])));
            setNewPayment({...new_payment, [name]: newValue});
    };


        return (
                <CustomModal
                    open={props.formOpen}
                >
                        <Grid container  spacing={2}>

                            <FormHeader
                                title="Payment_form.header"
                                handleClose={props.handleModalClose}
                            />

                            <Hide hide={!is_loading}>
                                <Loading/>
                            </Hide>

                            <Hide hide={is_loading}>

                                <Grid item xs={12}>
                                    <CustomRadioGroup
                                        label="settlement_to"
                                        name="settlement_to"
                                        required
                                        helper_text={helper_text.settlement_to}
                                        error={helper_text.settlement_to !== undefined}
                                        value={new_payment.settlement_to + ""}
                                        onChangeHandle={handleNewPaymentFieldChange}
                                        options={view_options}
                                    />
                                </Grid>

                                <Hide hide={new_payment.settlement_to !== "Personal Money"}>
                                <Grid item xs={12}>
                                    <PaymentMethodAutoComplete
                                        fullObject
                                        required
                                        assigned_to="claims"
                                        label="payment_method"
                                        name="payment_method"
                                        value={new_payment.payment_method}
                                        onChangeHandle={handleNewPaymentFieldChange}
                                        error={helper_text.payment_method !== undefined}
                                        helper_text={helper_text.payment_method}
                                    />
                                </Grid>
                                </Hide>

                                <Grid item xs={12} md={6}>
                                    <DateField
                                        required
                                        name="payment_date"
                                        error={helper_text.payment_date !== undefined}
                                        helper_text={helper_text.payment_date}
                                        label="payment_date"
                                        value={new_payment.payment_date}
                                        onChangeHandle={handleNewPaymentFieldChange}
                                    />
                                </Grid>


                                <Grid item xs={12} md={6}>
                                    <CustomTextField
                                        required={true}
                                        name="amount"
                                        label="amount"
                                        type="number"
                                        error={helper_text.amount !== undefined}
                                        helper_text={helper_text.amount}
                                        value={new_payment.amount}
                                        onChangeHandle={handleNewPaymentFieldChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <CustomTextField
                                        name="reference"
                                        label="reference"
                                        error={helper_text.reference !== undefined}
                                        helperText={helper_text.reference}
                                        value={new_payment.reference}
                                        onChangeHandle={handleNewPaymentFieldChange}
                                    />
                                </Grid>


                            <Hide hide={!show_cash_box || new_payment.settlement_to !== "Personal Money"}>
                                <Grid item xs={12}>
                                    <StaffAutoComplete
                                        required
                                        name='cash_box'
                                        label="cash_box"
                                        onChangeHandle={handleNewPaymentFieldChange}
                                        error={helper_text.cash_box !== undefined}
                                        helper_text={helper_text.cash_box}
                                        value={new_payment.cash_box}
                                    />
                                </Grid>
                            </Hide>

                            <FormActionButtons
                                errorMsg={helper_text.non_field_errors}
                                handleCancelButton={props.handleModalClose}
                                handleSaveButton={handlePaymentFormSaveButton}
                            />

                            </Hide>

                        </Grid>
                </CustomModal>

        );
    }


