import React, {useEffect, useState} from "react";
import {RetrieveStaffUser} from "./staff_apis";
import Grid from "@material-ui/core/Grid";
import {Hide} from "../../../components/Hide";
import {Loading} from "../../../components/Loading";
import {not_validate_value} from "../../../App";
import {StaffAccessPermissions} from "./components/StaffAccessPermissions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../localization/MainLocalization";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";


export const StaffAccessPermissionsTab = props => {
    const {staff_id} = props;

    const [user, setUser] = useState({})
    const [is_loading, setIsLoading] = useState(true)


    const reloadData = async () => {
        await setIsLoading(true);
        let staffUserResponse = await RetrieveStaffUser(staff_id);
        if (staffUserResponse.status) {
            if (!not_validate_value.includes(staffUserResponse.data.id)) {
                await setUser(staffUserResponse.data);
            }
        }

        await setIsLoading(false);
    }

    useEffect(() => {
        reloadData();
    }, [])

    return (
        <>

            <Hide hide={!is_loading}>
                <Loading/>
            </Hide>

            <Hide hide={user.id === undefined}>
                <StaffAccessPermissions staff_id={staff_id}/>
            </Hide>

            <Hide hide={!not_validate_value.includes(user.id)}>
                <Grid item xs={12} className="text-center">
                    <Typography variant="h6" className='p-1'>
                        {getLabel("need_to_create_account_for_staff_first")}
                    </Typography>
                </Grid>

                <Hide hide={!Permissions.checkAllPermissions([permissions_data.user.view, permissions_data.user.add])}>
                <Grid item xs={12} className="text-center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.moveToTab(2)}
                    >
                        {getLabel('create_account')}
                    </Button>
                </Grid>
                </Hide>
            </Hide>

        </>
    );

}
