import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {InputFile} from "../../../components/InputFile";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {CountriesAutoComplete} from "../../../components/fields/CountriesAutoComplete";
import {TimeZoneAutoComplete} from "../../../components/fields/TimeZoneAutoComplete";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {RetrieveEntity, UpdateEntity} from "./entity_apis";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {permissions_data} from "../../../auth/permission_data";
import {AlertContext} from "../../../store/use_context";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {getLabel} from "../../../localization/MainLocalization";
import {not_validate_value} from "../../../App";
import Typography from "@material-ui/core/Typography";
import Cookies from "js-cookie";

export const EntityInfo = props =>{
    const {setAlert} = useContext(AlertContext)

    const have_permission = Permissions.checkPermission(permissions_data.entity.change);

    const [entity, setEntity] = useState({})
    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(true)
    const [edit_data, setEditData] = useState(false);

    useEffect(()=>{
        const fetchData = async () => {
            await setIsLoading(true)
            let response = await RetrieveEntity();
            if (response.status) {
                setEntity({...response.data[0], country: response.data[0].country.name})
            }
                await setIsLoading(false)
        }

        fetchData();
    }, [edit_data]);


    const handleInfoFieldChange = (name, newValue) => {
        if (have_permission && edit_data) {
            if (name === "logo"){
                setEntity({...entity, [name]: newValue, display_logo: URL.createObjectURL(newValue)});
            }else{
                setEntity({...entity, [name]: newValue})
            }
        }
    };

    const handleEntitySaveButton = async () => {
        await setIsLoading(true)
        let response = await UpdateEntity(entity.id, entity);
        await setHelperText({});
        if (!response.status) {
            await setHelperText(response.error_feedback);
        }else{
            setAlert("Account information updated successfully")
            setEditData(false)
            localStorage.setItem('sub_directory', response.data.sub_directory);
            Cookies.set('sub_directory', response.data.sub_directory);
        }
        await setIsLoading(false)
    };


    const handleEditAction = () => {
        setEditData(!edit_data);
    }

        return(
            <>
        <Hide hide={!is_loading}><Loading/></Hide>

                <Hide hide={is_loading}>

                    <Hide hide={!have_permission}>
                        <Grid item xs={12} className="text-end">
                            <Button
                                size="small"
                                startIcon={edit_data ? <CloseIcon/> : <EditIcon/>}
                                className={`text-white ${edit_data ? "bg-danger" : "primary-bg"}`}
                                onClick={handleEditAction}
                            >
                                {edit_data ? getLabel("cancel_edit") : getLabel("edit")}
                            </Button>
                        </Grid>
                    </Hide>

                <Grid item xs={12} className='border m-2 p-4'>
                    <div className='w-50 m-auto text-center'>
                        {not_validate_value.includes(entity.display_logo)?(
                            <Typography variant="h4">
                                {getLabel("no_logo")}
                            </Typography>
                            ):(
                            <img src={entity.display_logo} className='w-75' alt=""/>
                            )}

                            <Hide hide={!edit_data}>
                        <InputFile
                            name='logo'
                            onChangeHandle={handleInfoFieldChange}
                            label="pages.entity.change_logo"
                        />
                            </Hide>

                    </div>
                </Grid>


                <Grid item xs={12} md={6}>
                    <CustomTextField
                        viewOnly={!edit_data}
                        helper_text={helper_text.company_name}
                        error={helper_text.company_name !== undefined}
                        value={entity.company_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="company_name"
                        label="pages.entity.company_name"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.business_name}
                        error={helper_text.business_name !== undefined}
                        value={entity.business_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="business_name"
                        label="pages.entity.business_name"
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomTextField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.sub_directory}
                        error={helper_text.sub_directory !== undefined}
                        value={entity.sub_directory}
                        onChangeHandle={handleInfoFieldChange}
                        name="sub_directory"
                        label="sub_directory"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CountriesAutoComplete
                        required
                        viewOnly={!edit_data}
                        label="country"
                        name="country"
                        onChangeHandle={handleInfoFieldChange}
                        helper_text={helper_text.country}
                        error={helper_text.country !== undefined}
                        value={entity.country}
                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <TimeZoneAutoComplete
                        country={entity.country}
                        required
                        viewOnly={!edit_data}
                        error={helper_text.timezone !== undefined}
                        helper_text={helper_text.timezone}
                        name="timezone"
                        onChangeHandle={handleInfoFieldChange}
                        value={entity.timezone}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DisabledTextField
                        label="date_created"
                        value={entity.date_created}
                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <DisabledTextField
                        label="date_modified"
                        value={entity.date_modified}
                    />
                </Grid>

                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowSave={edit_data}
                    saveAction={handleEntitySaveButton}
                />
                </Hide>

            </>

        );
}