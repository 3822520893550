import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseAllStaffURL} from "../../urls";
import {not_validate_value} from "../../App";

export const StaffAutoComplete = props => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchOptions = async () => {
            let params ={}
            if (!not_validate_value.includes(props.have_user)){
                params['have_user'] = props.have_user
            }

            if (!not_validate_value.includes(props.can_receive_cash)){
                params['can_receive_cash'] = props.can_receive_cash
            }

            if (!not_validate_value.includes(props.exclude_self)){
                params['exclude_self'] = props.exclude_self
            }

            let response = await  httpRequestWithAuth(BaseAllStaffURL,"GET", null, params)


            if (response.status) {
                await setOptions(response.data)
            }

        }
        fetchOptions()
    }, []);


    const handleOnChange = (event, value) => {
        if (!props.viewOnly) {
            value = value !== null ? value.id : "";
            props.onChangeHandle(props.name, value)
        }
    };

   const handleAutoCompleteValue = () => {
        let value = null;

        options.map(Data => {
            if (Data.id === props.value) {
                value = Data;
            }
            return 0;
        });

        return value;
    };


        return (
            <Autocomplete
                ListboxProps={{className:"list-box-autocomplete"}}
                renderOption={(option) => (
                    <span className="w-100 text-start">{option.full_name}</span>
                )}
                disabled={props.disabled || props.viewOnly}
                options={options}
                getOptionLabel={option => option.full_name}
                renderInput={params => (
                    <TextField variant="outlined" {...params}
                               label={<label>{getLabel(props.label)}<span
                                   className='text-danger'>{props.required ? "*" : ""}</span></label>}
                               helperText={props.helper_text}
                               error={props.error}
                               fullWidth
                               disabled={props.disabled}
                               className={props.disabled ? "disabled-field" : ""}
                    />
                )}
                value={handleAutoCompleteValue()}
                onChange={handleOnChange}
            />
        );
}
