 import React, {Component} from "react";
import {getLabel} from "../localization/MainLocalization";
import Grid from "@material-ui/core/Grid";
import {SelectField} from "./fields/SelectField";
import {CustomTextField} from "./fields/CustomTextField";
import {PaymentMethodAutoComplete} from "./fields/PaymentMethodAutoComplete";
import {StaticPaymentMethod} from "./fields/StaticPaymentMethod";
 import {DateField} from "./fields/DateField";

export const PaymentMethods = props => {
            const payment_options = [
                {title: getLabel("payment") , value: "Payment"},
                {title: getLabel("refund") , value: "Refund"}
            ];

            // const payment_method_option = [
            //     {title: get_label("cash"), value: "Cash"},
            //     {title: get_label("debit"), value: "Debit"},
            //     {title: get_label("credit"), value: "Credit"},
                // {title: "Cheque", value: "Cheque"},
                // {title: "Bank Transfer", value: "Bank Transfer"}}
            // ];

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <SelectField
                        viewOnly
                        disabled={true}
                        label="pages.membershipsinfo.Payment_form.payment_type"
                        name="payment_type"
                        value={props.new_payment.payment_type}
                        onChangeHandle={props.handleNewPaymentFieldChange}
                        options={payment_options}
                        error={props.helper_text.payment_type !== undefined}
                        helperText={props.helper_text.payment_type}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    {props.staticMethodOptions ?

                        (
                            <StaticPaymentMethod
                                required={true}
                                label="payment_method"
                                name="payment_method"
                                value={props.new_payment.payment_method}
                                onChangeHandle={props.handleNewPaymentFieldChange}
                                error={props.helper_text.payment_method !== undefined}
                                helper_text={props.helper_text.payment_method}
                            />
                        ):(
                            <PaymentMethodAutoComplete
                                fullObject={props.paymentMethodFullObject}
                                assigned_to={props.assigned_to}
                                required={true}
                                label="payment_method"
                                name="payment_method"
                                value={props.new_payment.payment_method}
                                onChangeHandle={props.handleNewPaymentFieldChange}
                                error={props.helper_text.payment_method !== undefined}
                                helper_text={props.helper_text.payment_method}
                            />
                        )}

                </Grid>


                <Grid item xs={12} md={6}>
                    <DateField
                        required
                        name="payment_date"
                        error={props.helper_text.payment_date !== undefined}
                        helper_text={props.helper_text.payment_date}
                        label="payment_date"
                        value={props.new_payment.payment_date}
                        onChangeHandle={props.handleNewPaymentFieldChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        required={true}
                        name="amount"
                        label="amount"
                        type="number"
                        error={props.helper_text.amount !== undefined}
                        helper_text={props.helper_text.amount}
                        value={props.new_payment.amount}
                        onChangeHandle={props.handleNewPaymentFieldChange}
                        disabled={props.disableAmount}
                    />
                </Grid>

                    <Grid item xs={12}>
                        <CustomTextField
                            name="reference"
                            label="reference"
                            error={props.helper_text.reference !== undefined}
                            helperText={props.helper_text.reference}
                            value={props.new_payment.reference}
                            onChangeHandle={props.handleNewPaymentFieldChange}
                        />
                    </Grid>


            </Grid>

        );
    }
