import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {ClientAutoComplete} from "../../../../components/fields/ClientAutoComplete";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseMembershipTransferURL, BaseMembershipURL} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";


export const TransferModal = props => {
    const [client, setClient] = useState("")
    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(false)

    const handleFieldChange = async (name, value) => {
        await setClient(value);
    };

    const handleSaveButton = async () => {
        await setIsLoading(true);

        let url = `${BaseMembershipURL}${props.membership}/${BaseMembershipTransferURL}`;
        let response = await httpRequestWithAuth(url, "PATCH", {client})

        if (response.status) {
            props.handleModalClose();
            await props.goToMembership(response.data.id, response.data.short_name);
            // alert(response.status)
        } else {
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };

    return (

        <CustomModal
            open={props.open}
        >
            <Grid container spacing={2}>

                <FormHeader
                    title="transfer"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12}>
                        <ClientAutoComplete
                            location={props.location}
                            required={true}
                            name='client'
                            label="client"
                            onChangeHandle={handleFieldChange}
                            helper_text={helper_text.client}
                            error={helper_text.client !== undefined}
                            value={client}
                        />
                    </Grid>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveButton}
                    />

                </Hide>
            </Grid>
        </CustomModal>
    );
}