import React, {Component} from "react";
import {sub_directory, redirect} from "../App";
import auth from "./auth";
import queryString from 'query-string'
import {Loading} from "../components/Loading";

let code = "";

export class AuthCode extends Component{
    constructor(props) {
        super(props);
        code = queryString.parse(this.props.location.search).code
    }

    async componentDidMount() {
        let feedBack =  await auth.login(code);
        if (feedBack.status){
            this.props.history.push(sub_directory + redirect);
        }else {
            auth.logout(auth.logout());
        }
    }

    render() {
        return(
            <Loading/>
        );
    }
}