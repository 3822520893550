import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../assets/scss/Static.scss';
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CustomModal} from "../../../../components/CustomModal";
import {CustomTextArea} from "../../../../components/fields/CustomTextArea";
import {Loading} from "../../../../components/Loading";
import {Hide} from "../../../../components/Hide";
import Chip from "@material-ui/core/Chip";

export const WorkoutSectionFormModal = props => {
    const [data, setData] = useState(props.data)
    const [is_loading, setIsLoading] = useState(false)


    const onChangeHandle = async (name, newValue) => {
        await setData({...data, [name]: newValue})
    }

   const handleSaveBtn = async () =>{
       await setIsLoading(true);
       await props.handleSaveForm(data);
       await setIsLoading(false);
   }

    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="workout_section_form"
                        handleClose={props.handleModalClose}
                    />


                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>

                        <Grid item xs={12}>
                            <Chip className="m-1" label="WOD" color="primary" onClick={()=>onChangeHandle('header', 'WOD')}/>
                            <Chip className="m-1" label="SKILL/STRENGTH" color="primary" onClick={()=>onChangeHandle('header', 'SKILL/STRENGTH')}/>
                            <Chip className="m-1" label="STRENGTH" color="primary" onClick={()=>onChangeHandle('header', 'STRENGTH')}/>
                            <Chip className="m-1" label="METCON" color="primary" onClick={()=>onChangeHandle('header', 'METCON')}/>
                        </Grid>

                        <Grid item xs={12}>
                            <CustomTextField
                                required
                                error={props.helper_text.header !== undefined}
                                helper_text={props.helper_text.header}
                                name="header"
                                label="header"
                                value={data.header}
                                onChangeHandle={onChangeHandle}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <CustomTextArea
                                required
                                label="content"
                                error={props.helper_text.content === undefined}
                                helper_text={props.helper_text.content}
                                name="content"
                                rows={8}
                                value={data.content}
                                onChangeHandle={onChangeHandle}
                            />
                        </Grid>


                    <FormActionButtons
                        errorMsg={props.helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveBtn}
                    />

                    </Hide>
                </Grid>

            </CustomModal>
        </>
    );
};