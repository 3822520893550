import React, {useContext, useEffect, useState} from "react";
import Permissions from "../../../auth/permissions";
import {RetrieveExpense} from "./expenses_apis";
import {not_validate_value, sub_directory} from "../../../App";
// import moment from "moment";
import {Hide} from "../../../components/Hide";
import {Loading} from "../../../components/Loading";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {CustomTextArea} from "../../../components/fields/CustomTextArea";
import {DateField} from "../../../components/fields/DateField";
import {LocationsAutoComplete} from "../../../components/fields/LocationsAutoComplete";
import {ExpenseCategoryComplete} from "../../../components/fields/ExpenseCategoryComplete";
import {ActionButtons} from "../../../components/ActionButton";
import Paper from "@material-ui/core/Paper";
import {CustomHeader} from "../../../components/CustomHeader";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import moment from "moment";
import {permissions_data} from "../../../auth/permission_data";
import {ConfirmationContext} from "../../../store/use_context";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseClassWorkoutURL, BaseFinanceExpenseURL} from "../../../urls";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {getLabel} from "../../../localization/MainLocalization";
import {VendorAutoComplete} from "../../../components/fields/VendorAutoComplete";


export const ExpenseForm = props => {
    const {setConfirmData} = useContext(ConfirmationContext)

    const {expense_id} = props;
    const have_permission = ((Permissions.checkPermission(permissions_data.expense.add) && expense_id === 'new') ||
        Permissions.checkPermission(permissions_data.expense.change));


    const [expense, setExpense] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(true);
    const [edit_data, setEditData] = useState(false);

    const listExpenseDate = async () => {
        await setIsLoading(true)

        if (expense_id !== 'new') {
            let response = await httpRequestWithAuth(`${BaseFinanceExpenseURL}${expense_id}/`, "GET")

            if (response.status) {
                await props.setIsClosedValue(response.data.is_closed);
                await setExpense(response.data);
            } else {
                await props.history.push("/notfound");
            }
        }
        await setIsLoading(false)
    }

    useEffect(() => {
        listExpenseDate()
    }, [expense_id, edit_data])

    useEffect(() => {
        setEditData(expense_id === 'new');
    }, [expense_id])


    const handleFieldChange = (name, newValue) => {
        if (have_permission) {
            setExpense({...expense, [name]: newValue})
        }
    };


    const handleBackButton = () => {
        props.history.push(`${sub_directory}expenses`)
    }


    const handleSaveButton = async () => {
        await setIsLoading(true)
        let expense_data = {
            ...expense,
            invoice_date: !not_validate_value.includes(expense.invoice_date) ? moment(expense.invoice_date).format("YYYY-MM-DD") : null,
        }

        let response;
        if (expense_id === 'new') {
            response = await httpRequestWithAuth(`${BaseFinanceExpenseURL}`, "POST", expense_data);
        } else {
            response = await httpRequestWithAuth(`${BaseFinanceExpenseURL}${expense_id}/`, "PATCH", expense_data);
        }

        await setHelperText({});
        if (response.status) {

            if (response.data.balance !== 0) {
                if (expense_id === 'new' && Permissions.checkPermission(permissions_data.expense_payment.view)) {
                    await props.history.push({
                        pathname: `${sub_directory}expenses/edit`,
                        state: {
                            moveToNextTab: true,
                            id: response.data.id,
                            tag: response.data.invoice_id
                        }
                    });
                } else {
                    await props.moveToNextTab();
                }
            } else {
                await handleBackButton()
            }
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };



    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this expense ??",
            action: deleteAction
        })
    };

    const deleteAction = async ()=>{
        let response = await httpRequestWithAuth(`${BaseFinanceExpenseURL}${expense_id}/`, "DELETE");
        if (response.status) {
            await handleBackButton()
        } else {
            setHelperText(response.error_feedback)
        }
    }


    const handleChangeStatusButton = async () => {
        let status = expense.is_closed ? "Open" : "Close"
        let response = await httpRequestWithAuth(`${BaseFinanceExpenseURL}${expense_id}/`, "PATCH", {status: status});
        if (response.status) {
            setEditData(false);
            await props.setIsClosedValue(response.data.is_closed)
            await listExpenseDate();
        }
    };

    const ExpenseData = (
        <Paper className="h-100">

            <Grid container spacing={2} className="Paper pt-2">
                <CustomHeader
                    title="expense_information"
                />

                <Grid item xs={12}>
                    <CustomTextField
                        disabled={expense.is_closed}
                        viewOnly={expense.is_closed || !edit_data}
                        required={true}
                        helper_text={helper_text.title}
                        error={helper_text.title !== undefined}
                        value={expense.title}
                        onChangeHandle={handleFieldChange}
                        name="title"
                        label="title"
                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <ExpenseCategoryComplete
                        category={expense.category}
                        disabled={expense.is_closed}
                        viewOnly={expense.is_closed || !edit_data}
                        required
                        name='category'
                        label="expense_category"
                        onChangeHandle={handleFieldChange}
                        error={helper_text.category !== undefined}
                        value={expense.category}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DateField
                        required
                        viewOnly={expense.is_closed || !edit_data}
                        disabled={expense.is_closed}
                        name="invoice_date"
                        error={helper_text.invoice_date !== undefined}
                        helper_text={helper_text.invoice_date}
                        label="invoice_date"
                        value={expense.invoice_date}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <LocationsAutoComplete
                        viewOnly={expense.is_closed || !edit_data}
                        disabled={expense.is_closed}
                        name='location'
                        label="location"
                        onChangeHandle={handleFieldChange}
                        error={helper_text.location !== undefined}
                        value={expense.location}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <VendorAutoComplete
                        viewOnly={expense.is_closed || !edit_data}
                        disabled={expense.is_closed}
                        name='vendor'
                        label="vendor"
                        onChangeHandle={handleFieldChange}
                        error={helper_text.vendor !== undefined}
                        value={expense.vendor}
                    />
                </Grid>

                <Grid item xs={12} className="mt-3 mb-2">
                    <CustomTextArea
                        viewOnly={expense.is_closed || !edit_data}
                        disabled={expense.is_closed}
                        label="description"
                        error={helper_text.description !== undefined}
                        helper_text={helper_text.description}
                        name="description"
                        rows={10}
                        value={expense.description || ""}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>

                {expense_id !== 'new' && (
                    <>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="created_by"
                                        value={expense.created_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_created"
                                        value={expense.date_created}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="modified_by"
                                        value={expense.modified_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_modified"
                                        value={expense.date_modified}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                    </>
                )}


            </Grid>
        </Paper>
    )


    const PaymentData =(
        <Paper className="h-100">
            <Grid container spacing={2} className="Paper pt-2">

                <CustomHeader
                    title="payment_information"
                />

                <Hide hide={expense_id === 'new'}>
                    <Grid item xs={12}>
                        <DisabledTextField
                            label="invoice_id"
                            value={expense.invoice_id}
                        />
                    </Grid>

                    {/*<Grid item xs={12}>*/}
                    {/*    <DisabledTextField*/}
                    {/*        label="invoice_date"*/}
                    {/*        value={moment(membership.member_date).format("DD-MM-YYYY HH:mm:ss") || ""}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                </Hide>

                <Grid item xs={12}>
                    <CustomTextField
                        name="cost"
                        label="cost"
                        type="number"
                        error={helper_text.cost !== undefined}
                        helper_text={helper_text.cost}
                        value={expense.cost}
                        onChangeHandle={handleFieldChange}
                        disabled={expense.is_closed}
                        viewOnly={expense.is_closed || !edit_data}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DisabledTextField
                        label="paid"
                        value={expense.paid}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DisabledTextField
                        value={expense.refund}
                        label="refund"
                    />
                </Grid>

                <Grid item xs={12}>
                    <DisabledTextField
                        value={expense.balance}
                        label="balance"
                    />
                </Grid>
            </Grid>
        </Paper>
    );

    return (
        <>
            <Hide hide={!is_loading}><Loading/></Hide>

            <Hide hide={is_loading}>
                <Grid container spacing={3}>

                    <Hide hide={!have_permission || expense_id === 'new' || (not_validate_value.includes(expense.is_closed)?true : expense.is_closed)}>
                        <Grid item xs={12} className="text-end">
                            <div style={{marginInlineEnd: "2rem"}}>
                            <Button
                                size="small"
                                startIcon={edit_data?<CloseIcon/>:<EditIcon/>}
                                className={`text-white ${edit_data?"bg-danger": "primary-bg"}`}
                                onClick={()=>setEditData(!edit_data)}
                            >
                                {edit_data? getLabel("cancel_edit"): getLabel("edit")}
                            </Button>
                            </div>

                        </Grid>
                    </Hide>

                    <Grid item xs={12} md={12} lg={8}>
                        {ExpenseData}
                    </Grid>

                    <Grid item xs={12} md={12} lg={4}>
                        {PaymentData}
                    </Grid>


                    <ActionButtons
                        errorMsg={helper_text.non_field_errors}
                        allowDelete={Permissions.checkPermission(permissions_data.expense.delete) && !expense.is_closed}
                        deleteAction={handleDeleteButton}
                        allowBack={true}
                        allowCancel={have_permission && expense_id !== 'new' && !expense.is_closed}
                        cancelAction={handleChangeStatusButton}
                        allowActivate={have_permission && expense_id !== 'new' && expense.is_closed}
                        cancelLabel="close"
                        activateAction={handleChangeStatusButton}
                        activateLabel="open"
                        backAction={handleBackButton}
                        allowSave={edit_data && !expense.is_closed}
                        saveAction={handleSaveButton}
                    />
                    =
                </Grid>

            </Hide>
        </>
    );

}
