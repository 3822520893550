import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {compareTime, not_validate_value, sub_directory} from "../../../App";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseClassRecurringURL} from "../../../urls";
import {ServiceAutoComplete} from "../../../components/fields/ServiceAutoComplete";
import {LocationsAutoComplete} from "../../../components/fields/LocationsAutoComplete";
import {StaffAutoComplete} from "../../../components/fields/StaffAutoComplete";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../localization/MainLocalization";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import {DateField} from "../../../components/fields/DateField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import {TimeModal} from "../../../components/TimeModal";
import {TrainingAreaAutoComplete} from "../../../components/fields/TrainingAreaAutoComplete";
import {ConfirmationContext} from "../../../store/use_context";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {MaleFemaleCheckBox} from "../../../components/MaleFemaleCheckBox";
import {ClientAthleteCheckBox} from "../../../components/ClientAthleteCheckBox";
import {GroupsMultipleAutoComplete} from "../../../components/fields/GroupsMultipleAutoComplete";
import MaterialTable from "material-table";
import {CustomDisplayDataTable} from "../../../components/CustomDisplayDataTable";

const table_options = {
    paging: false,
    toolbar: false
}

const table_style = {
    padding: '0',
    boxShadow: "none",
    marginBottom: '1rem'
}

export const RecurringClassesForm = (props) => {
    const recurring_class_id = props.recurring_class_id;
    const {setConfirmData} = useContext(ConfirmationContext)

    const schedule_default_value = {
        from_date: null,
        to_date: null,
        days: {
            'saturday': [], 'sunday': [],
            'monday': [], 'tuesday': [],
            'wednesday': [], 'thursday': [],
            'friday': []
        }
    };

    const have_permission = ((Permissions.checkPermission(permissions_data.recurring_classes.add) && recurring_class_id === 'new') ||
        Permissions.checkPermission(permissions_data.recurring_classes.change));

    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(true)
    const [open_model, setOpenModel] = useState(false)
    const [recurring_class_data, setRecurringClassData] = useState( {required_groups_to_attend: [], schedule: schedule_default_value})
    const [edit_data, setEditData] = useState(false);
    const [columns] = useState([
        {title: getLabel("season_name"), field: "season_name"},
        {title: getLabel("team"), field: "name"},
        {title: getLabel("start_date"), field: "season_start_date"},
        {title: getLabel("end_date"), field: "season_end_date"},
        {title: getLabel("notes"), field: "notes", cellStyle:{color:"red"}},
    ]);
    const [required_athlete_teams_details, setRequiredAthleteTeamsDetails] = useState([]);
    const [optional_athlete_teams_details, setOptionalAthleteTeamsDetails] = useState([]);


    useEffect(()=>{
        setEditData(recurring_class_id === 'new' && have_permission);
    },[recurring_class_id]);

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            if (recurring_class_id !== 'new') {
                let response = await httpRequestWithAuth(`${BaseClassRecurringURL}${recurring_class_id}/`, "GET");
                if (response.status) {
                    let temp_data = {...response.data, schedule: JSON.parse(response.data.schedule)}
                     await setRecurringClassData(temp_data);
                } else {
                    props.history.push("/notfound");
                }

            }
            await setIsLoading(false)
        }

        fetchData();
    }, [recurring_class_id, edit_data])

    const onLocationChange = (name, newValue) => {
        if (have_permission && edit_data) {
            setRecurringClassData({
                ...recurring_class_data,
                [name]: newValue,
                training_area: "",
                service: "",
                staff: "",
                schedule: schedule_default_value
            })
        }
    }

    const handleFieldChange = (name, newValue) => {
        if (have_permission && edit_data) {
            setRecurringClassData({...recurring_class_data, [name]: newValue});
        }
    };

    const handleScheduleFieldChange = async (name, newValue) => {
        if (have_permission && edit_data) {
            let new_data = {
                ...recurring_class_data,
                schedule: {
                    ...recurring_class_data.schedule,
                    [name]: not_validate_value.includes(newValue) ? null : moment(newValue).format("YYYY-MM-DD")
                }
            }

            if (name === 'from_date' && !not_validate_value.includes(recurring_class_data.schedule.to_date) && moment(newValue).isAfter(recurring_class_data.schedule.to_date)) {
                new_data['schedule']['to_date'] = moment(newValue).format("YYYY-MM-DD")
            }

            if (['from_date', 'to_date'].includes(name)){
                new_data['required_groups_to_attend'] = []
                new_data['optional_groups_to_attend'] = []
                await setRequiredAthleteTeamsDetails([]);
                await setOptionalAthleteTeamsDetails([]);
            }

            await setRecurringClassData(new_data);
        }
    };

    const handleScheduleTimeDelete = (day, index) => {
        if (have_permission && edit_data) {
            let temp = [...recurring_class_data.schedule.days[day]]
            temp.splice(index, 1);

            setRecurringClassData({
                ...recurring_class_data,
                schedule: {...recurring_class_data.schedule, days: {...recurring_class_data.schedule.days, [day]: temp}}
            })
        }
    };

    const checkSchedule = () => {
        let noError = false
        let helper_text_temp = {}

        if (not_validate_value.includes(recurring_class_data.schedule.from_date)) {
            noError = true
            helper_text_temp = {...helper_text_temp, from_date: "this field is required!"}
        }

        if (not_validate_value.includes(recurring_class_data.schedule.to_date)) {
            noError = true
            helper_text_temp = {...helper_text_temp, to_date: "this field is required!"}
        }
        setHelperText(helper_text_temp);
        return noError
    }

    const handleSaveButton = async () => {
        await setIsLoading(true)

        if (!checkSchedule()) {
            let data = {...recurring_class_data}

            if (!data['for_clients']){
                data['clients_capacity'] = 0
            }
            Object.keys(recurring_class_data.schedule.days).map(Day => {
                data.schedule.days[Day].sort(compareTime)
            })

            data['schedule'] = JSON.stringify({...data.schedule})

            let response;
            if (recurring_class_id === 'new') {
                response = await httpRequestWithAuth(BaseClassRecurringURL, "POST", data);
            } else {
                response = await httpRequestWithAuth(`${BaseClassRecurringURL}${recurring_class_id}/`, "PUT", data);
            }

            await setHelperText({});
            if (response.status) {
                await handleBackButton();
            } else {
                await setHelperText(response.error_feedback);
            }
        }

        await setIsLoading(false)
    };

    const handleBackButton = () => {
        props.history.push(`${sub_directory}recurring-classes`)
    }


    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this recurring classes ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let response = await httpRequestWithAuth(`${BaseClassRecurringURL}${recurring_class_id}/`, "DELETE");
        if (response.status) {
            await handleBackButton()
        } else {
            setHelperText(response.error_feedback)
        }
    }


    const handleAddTime = (time_data, days) => {
        let temp_days_data = {...recurring_class_data.schedule.days}
        time_data.map(TimeData => {
            Object.keys(days).map(Day => {
            if (days[Day] === true) {
                let have_value = false
                recurring_class_data.schedule.days[Day].map(Data => {
                    if (Data.start_time === TimeData.start_time && Data.end_time === TimeData.end_time) {
                        have_value = true
                    }
                })
                if (!have_value) {
                    temp_days_data[Day].push({start_time: TimeData.start_time, end_time: TimeData.end_time})
                }
            }
            return 0;
        });
        })

        setRecurringClassData({
            ...recurring_class_data,
            schedule: {
                ...recurring_class_data.schedule, days: temp_days_data
            }
        });

    }


    const handleEditAction = () => {
         setEditData(!edit_data);
    }

    const getRequiredGroupDetails = (name, data) =>{
        let temp_data = []

        data.map(Data=>{
            let notes = "";

            if (moment(Data.season_start_date, "DD-MM-YYYY").isAfter(moment(recurring_class_data.schedule.from_date))){
                notes = "Season duration not full includes in recurring class generate dates"
            }

            if (moment(Data.season_end_date, "DD-MM-YYYY").isBefore(moment(recurring_class_data.schedule.to_date))){
                notes = "Season duration not full includes in recurring class generate dates"
            }


            if (!Data.is_active){
                notes = "Team not Active"
            }

            if (!Data.season_is_active){
                notes = "Season not Active"
            }
            temp_data.push({
                ...Data, notes
            })
        })

        setRequiredAthleteTeamsDetails(temp_data);
    }


    const getOptionalGroupDetails = (name, data) =>{
        let temp_data = [];
        data.map(Data=>{
            let notes = "";

            if (moment(Data.season_start_date, "DD-MM-YYYY").isAfter(moment(recurring_class_data.schedule.from_date))){
                notes = "Season duration not full includes in recurring class generate dates"
            }

            if (moment(Data.season_end_date, "DD-MM-YYYY").isBefore(moment(recurring_class_data.schedule.to_date))){
                notes = "Season duration not full includes in recurring class generate dates"
            }


            if (!Data.is_active){
                notes = "Team not Active"
            }

            if (!Data.season_is_active){
                notes = "Season not Active"
            }

            temp_data.push({
                ...Data, notes
            })
        })
        setOptionalAthleteTeamsDetails(temp_data);
    }


    return (
        <>
            <Hide hide={!is_loading}><Loading/></Hide>

            <Hide hide={!open_model}>
                <TimeModal
                    handleModalClose={() => setOpenModel(false)}
                    handleAddTime={handleAddTime}
                    open={open_model}
                    location={recurring_class_data.location}
                />
            </Hide>



            <Hide hide={is_loading}>

                <Hide hide={!have_permission || recurring_class_id === 'new'}>
                    <Grid item xs={12} className="text-end">
                        <Button
                            size="small"
                            startIcon={edit_data ? <CloseIcon/> : <EditIcon/>}
                            className={`text-white ${edit_data ? "bg-danger" : "primary-bg"}`}
                            onClick={handleEditAction}
                        >
                            {edit_data ? getLabel("cancel_edit") : getLabel("edit")}
                        </Button>
                    </Grid>
                </Hide>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.name}
                        error={helper_text.name !== undefined}
                        value={recurring_class_data.name}
                        onChangeHandle={handleFieldChange}
                        name="name"
                        label="name"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <LocationsAutoComplete
                        required
                        active
                        location={recurring_class_data.location}
                        helper_text={helper_text.location}
                        viewOnly={!edit_data}
                        error={helper_text.location !== undefined}
                        name='location'
                        label="location"
                        onChangeHandle={onLocationChange}
                        value={recurring_class_data.location}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TrainingAreaAutoComplete
                        byLocation
                        required
                        location={recurring_class_data.location}
                        viewOnly={!edit_data}
                        helper_text={helper_text.training_area}
                        error={helper_text.training_area !== undefined}
                        value={recurring_class_data.training_area}
                        onChangeHandle={handleFieldChange}
                        name="training_area"
                        label="training_area"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <ServiceAutoComplete
                        required
                        byLocation
                        location={recurring_class_data.location}
                        viewOnly={!edit_data}
                        helper_text={helper_text.service}
                        error={helper_text.service !== undefined}
                        value={recurring_class_data.service}
                        onChangeHandle={handleFieldChange}
                        name="service"
                        label="service"
                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <StaffAutoComplete
                        name='staff'
                        label="staff"
                        viewOnly={!edit_data}
                        onChangeHandle={handleFieldChange}
                        error={helper_text.staff !== undefined}
                        helper_text={helper_text.staff}
                        value={recurring_class_data.staff}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <MaleFemaleCheckBox
                        viewOnly={!edit_data}
                        onChangeHandle={handleFieldChange}
                        for_males={recurring_class_data.for_males}
                        male_name="for_males"
                        for_females={recurring_class_data.for_females}
                        female_name="for_females"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <ClientAthleteCheckBox
                        viewOnly={!edit_data}
                        onChangeHandle={handleFieldChange}
                        for_clients={recurring_class_data.for_clients}
                        clients_name="for_clients"
                        for_athletes={recurring_class_data.for_athletes}
                        athletes_name="for_athletes"
                    />
                </Grid>

                <Hide hide={!recurring_class_data.for_clients}>
                    <Grid item xs={12}>
                        <CustomTextField
                            required
                            viewOnly={!edit_data}
                            helper_text={helper_text.clients_capacity}
                            error={helper_text.clients_capacity !== undefined}
                            value={recurring_class_data.clients_capacity}
                            type="number"
                            onChangeHandle={handleFieldChange}
                            name="clients_capacity"
                            label="clients_capacity"
                        />
                    </Grid>
                </Hide>

                <Grid item xs={12} className="mt-3">
                    <Typography variant="h6">
                        {getLabel("schedule")}
                    </Typography>
                </Grid>


                <Grid item xs={12} md={6}>
                    <DateField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.from_date}
                        error={helper_text.from_date !== undefined}
                        name="from_date"
                        label="from_date"
                        value={recurring_class_data.schedule.from_date}
                        onChangeHandle={handleScheduleFieldChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DateField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.to_date}
                        error={helper_text.to_date !== undefined}
                        name="to_date"
                        label="to_date"
                        value={recurring_class_data.schedule.to_date}
                        onChangeHandle={handleScheduleFieldChange}
                        min_date={recurring_class_data.schedule.from_date}
                    />
                </Grid>


                <Hide hide={!recurring_class_data.for_athletes}>
                    <Grid item xs={12}>
                        <GroupsMultipleAutoComplete
                            disabled={not_validate_value.includes(recurring_class_data.schedule.from_date) || not_validate_value.includes(recurring_class_data.schedule.to_date)}
                            activeInDateRange={{'from_date': recurring_class_data.schedule.from_date, 'to_date': recurring_class_data.schedule.to_date}}
                            exclude={recurring_class_data.optional_groups_to_attend}
                            groupFullName
                            fullObject
                            activeGroup
                            defaultOptions={recurring_class_data.required_groups_to_attend_data || []}
                            viewOnly={!edit_data}
                            helper_text={helper_text.required_groups_to_attend}
                            error={helper_text.required_groups_to_attend !== undefined}
                            name="required_groups_to_attend"
                            label="required_groups_to_attend"
                            value={recurring_class_data.required_groups_to_attend}
                            onChangeHandle={handleFieldChange}
                            getGroupDetails={getRequiredGroupDetails}
                        />
                    </Grid>

                    <Grid item xs={12} className="text-end mt-2 mb-3">
                        <CustomDisplayDataTable
                            columns={columns}
                            data={required_athlete_teams_details}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <GroupsMultipleAutoComplete
                            disabled={not_validate_value.includes(recurring_class_data.schedule.from_date) || not_validate_value.includes(recurring_class_data.schedule.to_date)}
                            activeInDateRange={{'from_date': recurring_class_data.schedule.from_date, 'to_date': recurring_class_data.schedule.to_date}}
                            exclude={recurring_class_data.required_groups_to_attend}
                            groupFullName
                            fullObject
                            activeGroup
                            defaultOptions={recurring_class_data.optional_groups_to_attend_data || []}
                            viewOnly={!edit_data}
                            helper_text={helper_text.optional_groups_to_attend}
                            error={helper_text.optional_groups_to_attend !== undefined}
                            name="optional_groups_to_attend"
                            label="optional_groups_to_attend"
                            value={recurring_class_data.optional_groups_to_attend}
                            onChangeHandle={handleFieldChange}
                            getGroupDetails={getOptionalGroupDetails}
                        />
                    </Grid>


                    <Hide hide={(recurring_class_data.optional_groups_to_attend || []).length < 1}>
                        <Grid item xs={12}>
                            <CustomTextField
                                viewOnly={!edit_data}
                                helper_text={helper_text.optional_athletes_capacity}
                                error={helper_text.optional_athletes_capacity !== undefined}
                                value={recurring_class_data.optional_athletes_capacity}
                                type="number"
                                onChangeHandle={handleFieldChange}
                                name="optional_athletes_capacity"
                                label="optional_athletes_capacity"
                            />
                        </Grid>
                    </Hide>


                    <Grid item xs={12} className="text-end mt-2">
                        <CustomDisplayDataTable
                            columns={columns}
                            data={optional_athlete_teams_details}
                        />
                    </Grid>

                </Hide>

                <Hide hide={!edit_data}>
                <Grid item xs={12} className="text-end mt-2">
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<AddIcon/>}
                        onClick={() => setOpenModel(true)}
                    >
                        {getLabel("add_time")}
                    </Button>
                </Grid>
            </Hide>

                {Object.keys(recurring_class_data.schedule.days).map((Day, index) => {
                    recurring_class_data.schedule.days[Day].sort(compareTime)
                    return (
                        <Grid item xs={12} className="mt-2" key={index}>
                            <div className="text-center border w-100 d-md-inline-flex align-items-center h-100">

                                <div className="bg-light p-2 h-auto justify-content-center align-items-center d-flex" style={{minWidth:'120px'}}>
                                <Typography variant="h6">
                                    {getLabel(Day)}
                                </Typography>
                                </div>

                                <Divider horizontal variant="fullWidth"/>

                                <div>
                                    {recurring_class_data.schedule.days[Day].map((Data, index) => {
                                        return (
                                            <Chip key={`${Day}-${Data.start_time}-${Data.end_time}`} className="m-2"
                                                  label={`${Data.start_time} - ${Data.end_time}`}
                                                  size="small"
                                                  onDelete={() => handleScheduleTimeDelete(Day, index)}/>
                                        )
                                    })}
                                </div>

                                <Hide hide={recurring_class_data.schedule.days[Day].length !== 0}>
                                    <Typography variant="body1" className="text-black-50 m-2">
                                        <span>{getLabel("empty_data_source_message")}</span>
                                    </Typography>
                                </Hide>
                            </div>
                        </Grid>
                    )
                })}


                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowDelete={Permissions.checkPermission(permissions_data.recurring_classes.delete) && recurring_class_id !== 'new'}
                    deleteAction={handleDeleteButton}
                    allowBack={true}
                    backAction={handleBackButton}
                    allowSave={edit_data}
                    saveAction={handleSaveButton}
                />


            </Hide>

        </>

    );
}
