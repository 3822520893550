import React from "react";
import {getLabel} from "../../../../localization/MainLocalization";
import {Hide} from "../../../../components/Hide";
import {ViewDataModel} from "../../../../components/ViewDataModel";

export const ViewWorkoutSections = props => {
    const columns = [
        {title: getLabel("header"), field: "header"},
        {title: getLabel("content"), field: "content", cellStyle:{
            whiteSpace: 'pre-line',
            }},
    ]

    return (
        <Hide hide={!props.open}>
            <ViewDataModel
                open={props.open}
                header="workout_sections"
                columns={columns}
                data={props.details}
                handleModalClose={props.handleModalClose}
                options={{
                    toolbar: false,
                    paging: false
                }}
            />
        </Hide>
    )
}