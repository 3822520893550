import React from "react";
import {Grid} from "@material-ui/core";
import {Hide} from "../../../components/Hide";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {ClientsUpComingBirthday} from "./components/ClientsUpComingBirthday";
import {LatestMemberships} from "./components/LatestMemberships";
import {MembershipsFinishingSoon} from "./components/MembershipsFinshingSoon";
import {ClassSessionOfDay} from "./components/ClassSessionOfDay";
import {CashOnHand} from "./components/CashOnHand";
import {TotalRevenue} from "./components/TotalRevenue";
import {TodaysMemberships} from "./components/TodaysMemberships";
import {StaffCashLog} from "../reports/components/StaffCashLog";
import {PendingCashTransfer} from "./components/PendingCashTransfer";
import {UnPaidMemberships} from "./components/UnPaidMemberships";
import {AthleteWorkout} from "./components/AthleteWorkout";

export const Dashboard = props => {
    let can_view_membership = Permissions.checkPermission(permissions_data.membership.view)
    return (
        <Grid container spacing={3}>

            <Hide hide={!can_view_membership}>
            <Grid item xs={12} md={can_view_membership ? 4 : 6} className="h-auto">
                <CashOnHand/>
            </Grid>
            </Hide>

            <Hide hide={!can_view_membership}>
            <Grid item xs={12} md={can_view_membership ? 4 : 6} className="h-auto">
                <TotalRevenue/>
            </Grid>
            </Hide>

            <Hide hide={!can_view_membership}>
                <Grid item xs={12} md={4} className="h-auto">
                    <TodaysMemberships/>
                </Grid>
            </Hide>

            <Hide hide={!Permissions.checkAllPermissions([permissions_data.classes.view])}>
                <Grid item xs={12} className="h-auto">
                    <ClassSessionOfDay {...props}/>
                </Grid>
            </Hide>

            <Hide
                hide={!Permissions.checkPermission(permissions_data.cash_transfer_request.view_staff_pending_request_and_staff_cash)}>
                <Grid item xs={12} md={4} className="h-auto">
                    <StaffCashLog {...props}/>
                </Grid>

                <Grid item xs={12} md={8} className="h-auto">
                    <PendingCashTransfer {...props}/>
                </Grid>
            </Hide>

            <Hide hide={!Permissions.checkAllPermissions([permissions_data.client.view])}>
                <Grid item xs={12} md={6} className="h-auto">
                    <ClientsUpComingBirthday {...props}/>
                </Grid>
            </Hide>


            <Hide hide={!can_view_membership}>
                <Grid item xs={12} md={6} className="h-auto">
                    <LatestMemberships {...props}/>
                </Grid>
            </Hide>


            <Hide hide={!can_view_membership}>
                <Grid item xs={12} md={6} className="h-auto">
                    <MembershipsFinishingSoon {...props}/>
                </Grid>
            </Hide>

            <Hide hide={!can_view_membership}>
                <Grid item xs={12} md={6} className="h-auto">
                    <UnPaidMemberships {...props}/>
                </Grid>
            </Hide>

            <Hide hide={!Permissions.checkAllPermissions([permissions_data.classes.view])}>
                <Grid item xs={12} className="h-auto">
                    <AthleteWorkout {...props}/>
                </Grid>
            </Hide>


        </Grid>
    )
}
