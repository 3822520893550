import React from 'react';
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../../localization/MainLocalization";
import MaterialTable from "material-table";
import {
    BaseFinanceListPendingCashTransferURL,
} from "../../../../urls";
import {instance} from "../../../../auth/axios_instance";


export const PendingCashTransfer = props => {
    const columns =[
        {
            title: getLabel("transfer_number"),
            render: rowData => <label># {rowData.transfer_number}</label>
        },
        {title: getLabel("from"), field: 'sender_name'},
        {title: getLabel("to"), field: 'recipient_name'},
        {title: getLabel("amount"), field: 'amount'},
        {title: getLabel("date_created"), field: 'date_created'},
    ];



    return (
        <div className='card-paper-1 h-100 w-100'>
            <Typography variant="h6" className="text-start mb-2">
                Pending Cash Transfer
            </Typography>

            <MaterialTable
                columns={columns}
                data={query =>
                    new Promise((resolve, reject) => {
                        let url = BaseFinanceListPendingCashTransferURL;
                        url += '?page=' + (query.page + 1);

                        instance.get(url)
                            .then(result => {
                                resolve({
                                    data: result.data.results,
                                    page: result.data.page - 1,
                                    totalCount: result.data.total,
                                })
                            })
                    })
                }

                style={{
                    boxShadow: "none",
                }}
                options={{
                    search: false,
                    toolbar: false,
                    pageSize: 10,
                    pageSizeOptions: [10]
                }}
            />
        </div>
    );
}
