import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {
    BaseGenerateClassSessionsTimingURL,
    BaseMembershipChangePreferredTrainingTimeURL,
    BaseMembershipURL
} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {SelectField} from "../../../../components/fields/SelectField";


export const ChangePreferredTimeModal = props => {
    const [data , setData] = useState({...props.default_value});
    const [helper_text , setHelperText] = useState({});
    const [is_loading , setIsLoading] = useState(false);
    const [sessions_timing, setSessionsTiming] = useState([])

    useEffect(()=>{
        const fetchData = async ()=>{
            let temp_sessions_timing = []

            let response = await httpRequestWithAuth(BaseGenerateClassSessionsTimingURL, "GET");

            if (response.status) {
                response.data.map(Time_data => {
                    temp_sessions_timing.push(
                        {
                            title: `${Time_data.start_time} - ${Time_data.end_time}`,
                            value: `${Time_data.start_time} - ${Time_data.end_time}`
                        }
                    )
                })
                setSessionsTiming(temp_sessions_timing)
            }
        }

        fetchData();
    }, [])

    const handleFieldChange = async (name, newValue) => {
       await setData({...data, [name]:newValue})
    }


    const handleSaveButton = async () => {
        await setIsLoading(true);

        let url = `${BaseMembershipURL}${props.membership}/${BaseMembershipChangePreferredTrainingTimeURL}`;
        let response = await httpRequestWithAuth(url, "PATCH", data)

        if (response.status) {
            await props.handleModalClose(true)
        } else {
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };


    return (

            <CustomModal
                open={props.open}
            >
                    <Grid container spacing={2}>

                        <FormHeader
                            title="change_preferred_time"
                            handleClose={()=>props.handleModalClose(false)}
                        />

                        <Hide hide={!is_loading}>
                            <Loading/>
                        </Hide>

                        <Hide hide={is_loading}>
                        <Grid item xs={12}>
                            <SelectField
                                label="preferred_training_time"
                                name="preferred_training_time"
                                value={data.preferred_training_time}
                                onChangeHandle={handleFieldChange}
                                options={sessions_timing}
                                error={helper_text.start_date !== undefined}
                                helper_text={helper_text.start_date}
                            />
                        </Grid>

                            <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={()=>props.handleModalClose(false)}
                            handleSaveButton={handleSaveButton}
                        />
                        </Hide>
                    </Grid>
            </CustomModal>
        );
}
