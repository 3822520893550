import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";


export const DataBox = props => {
    return (
            <Paper className="card-paper-1 h-100 w-100">

            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {props.header}
            </Typography>

            <Typography component="p" variant="h4">
                {props.content}
            </Typography>

            <Typography color="textSecondary" className='flex-grow-1'>
                {props.footer}
            </Typography>

            </Paper>
    );
}