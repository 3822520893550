import {
    BaseAllStaffURL,
    BaseListPermissionGroupURL, BaseStaffAttachmentURL,
    BaseStaffPermissionURL,
    BaseStaffURL,
    BaseStaffUserURL
} from "../../../urls";
import {httpRequestWithAuth, instance} from "../../../auth/axios_instance";
import {not_validate_value} from "../../../App";

export const RetrieveStaff = async (id) => {
    let url = BaseStaffURL + id + "/";

    const feedback = await  httpRequestWithAuth(url,"GET")

    return feedback
};


export const CreateStaff = async (data) => {
    let url = BaseStaffURL;

    let bodyFormData = new FormData();
    Object.keys(data).map(Name=>{
        if (!not_validate_value.includes(data[Name])){
        bodyFormData.append(Name, data[Name]);
        }
    })

    const feedback = await httpRequestWithAuth(url,"POST", bodyFormData)

    return feedback
};


export const DeleteStaff = async (id) => {
    let url = BaseStaffURL + id + "/";

    const feedback = await httpRequestWithAuth(url,"DELETE")

    return feedback
};

export const UpdateStaff = async (id, data) => {
    let url = BaseStaffURL + id + "/";

    var bodyFormData = new FormData();
    Object.keys(data).map(Name=>{
        if (!not_validate_value.includes(data[Name])){
            bodyFormData.append(Name, data[Name]);
        }
    })

    const feedback = await httpRequestWithAuth(url,"PATCH", bodyFormData)

    return feedback
};



export const ListStaffPermission = async (staff = null) => {
    let url = BaseStaffPermissionURL;

    const feedback = await httpRequestWithAuth(url,"GET", null, { staff })

    return feedback
};



export const RetrieveStaffPermission = async (id) => {
    let url = BaseStaffPermissionURL + id + "/";

    const feedback = await httpRequestWithAuth(url,"GET", null )

    return feedback
};


export const CreateStaffPermission = async (data) => {
    let url = BaseStaffPermissionURL;

    const feedback = await httpRequestWithAuth(url,"POST", data)
    return feedback
};


export const DeleteStaffPermission = async (id) => {
    let url = BaseStaffPermissionURL + id + "/";

    const feedback = await httpRequestWithAuth(url,"DELETE")
    return feedback
};

export const UpdateStaffPermission = async (id, data,) => {
    let url = BaseStaffPermissionURL + id + "/";

    const feedback = await httpRequestWithAuth(url,"PUT", data)

    return feedback
};


export const RetrieveStaffUser = async (id) => {
    let url = BaseStaffUserURL +  id + "/";


    const feedback = await httpRequestWithAuth(url,"GET")
    return feedback
};


export const ListPermissionGroupOfStaff = async (staff = null) => {
    let url = BaseListPermissionGroupURL;

    const feedback = await httpRequestWithAuth(url,"GET")

    return feedback
};



export const ListStaffAttachments = async (staff=null) => {
    let url = BaseStaffAttachmentURL;

    const feedback = await httpRequestWithAuth(url,"GET", null, { staff })

    return feedback
};


export const CreateStaffAttachments = async (data) => {
    let url = BaseStaffAttachmentURL;

    var bodyFormData = new FormData();
    bodyFormData.set("staff", data.staff);


    if (!not_validate_value.includes(data.name)){
        bodyFormData.append("name", data.name);
    }
    if (!not_validate_value.includes(data.file)){
        bodyFormData.append("file", data.file);
    }

    const feedback = await httpRequestWithAuth(url,"POST", bodyFormData)

    return feedback
};


export const UpdateStaffAttachments = async (id, data) => {
    let url = BaseStaffAttachmentURL + id + "/";

    var bodyFormData = new FormData();
    if (!not_validate_value.includes(data.staff)) {
        bodyFormData.set("staff", data.staff);
    }
    if (!not_validate_value.includes(data.name)){
        bodyFormData.append("name", data.name);
    }
    if (!not_validate_value.includes(data.file)){
        bodyFormData.append("file", data.file);
    }

    const feedback = await httpRequestWithAuth(url,"PATCH", bodyFormData)


    return feedback
};


export const DeleteStaffAttachments = async (id) => {
    let url = BaseStaffAttachmentURL + id + "/";


    const feedback = await httpRequestWithAuth(url,"DELETE")

    return feedback
};

