// export const BaseBackEndURL = "http://rkrds-staging.eu-west-3.elasticbeanstalk.com/";
// export const BaseBackEndURL = "http://localhost:8000/";
// export const BaseBackEndURL = "http://127.0.0.1:8000/";
// export const BaseBackEndURL = "http://localhost:8001/";
// export const BaseBackEndURL = "http://localhost:8002/";
// export const BaseBackEndURL = "http://localhost:8003/";
// export const BaseBackEndURL = "http://localhost:8004/";
// export const BaseBackEndURL = "http://localhost/";
import {sub_directory} from "./App";

export const BaseBackEndURL = process.env.REACT_APP_BACKEND_URL;
export const BaseLoginURL = ()=> BaseBackEndURL + sub_directory.substr(1) + "login";


// export const BaseExportDataURL = "/export/csv/";

export const BaseEntityURL = "entities/";
export const BaseEntitySettingURL = BaseEntityURL + "setting/";
export const BaseEntityCheckSubDirectoryURL = BaseEntityURL + "check-sub-directory/";
export const BaseEntityRegisterURL = BaseEntityURL + "register/";
export const BaseEntityGetSubDirectoryURL = BaseEntityURL + "get_entity_by_sub_directory/";

// export const BaseGymFeaturesURL = "gyms/features/";
export const BaseLocationURL = "locations/";
export const BaseFilterLocationsURL =  BaseLocationURL + "filter/";
export const BaseListLocationsServicesURL = BaseLocationURL + "services/";
export const BaseListSharedLocationsServicesURL = BaseListLocationsServicesURL + "shared/";
export const BaseTrainingAreaURL = BaseLocationURL + "training-areas/";
export const BaseTrainingAreaListAllURL = BaseTrainingAreaURL + "list_all/";
export const BaseListLocationsFreezeURL = BaseLocationURL + "freeze/";
export const BaseListLocationsFreezeApplyURL = id => `${BaseListLocationsFreezeURL}${id}/apply_freeze/`;
export const BaseListLocationsFreezeDeleteClassSessionsOnFreezeURL = id => `${BaseListLocationsFreezeURL}${id}/delete_classes_on_freeze/`;
export const BaseListLocationServicesFullDataURL = BaseListLocationsServicesURL + "full-data/";


export const BaseServiceURL = "services/";
export const BasePriceListURL = BaseServiceURL + "price-lists/";
export const BaseServicesReportURL = BaseServiceURL + "reports/";
export const BaseServicesMonthlyReportsURL = BaseServicesReportURL + "services-monthly-report/";
export const BasePriceURL = BasePriceListURL + "prices/";
export const BasePriceListAllURL = BasePriceURL + "list_all/";
export const BasePriceUpgradeToURL = "list_can_upgrade_to/"
export const BasePriceForOfferURL = BaseServiceURL + "prices/list/offer/";
export const ServiceFullDataURL = "services/full-data/";
export const BaseServiceListAllURL = BaseServiceURL + "list-all/";
export const BaseUpdateLocationServiceForServiceURL = id => `${BaseServiceURL}${id}/edit_on_location_service/`;
export const BaseUpdateLocationServiceForPriceListURL = id => `${BasePriceListURL}${id}/edit_on_location_service/`;

export const BasePriceListOfferURL = BasePriceListURL+ "offers/";
export const BasePriceOfferURL = BasePriceListOfferURL + "prices/";

export const BaseEquipmentURL = "equipment/";
export const BaseEquipmentLocationsURL = `${BaseEquipmentURL}locations/`;
export const BaseEquipmentFilterURL = `${BaseEquipmentURL}list/`;
export const BaseEquipmentPriceListURL = `${BaseEquipmentURL}price-list/`;


export const BaseAthleteURL = "athletes/";
export const BaseAthleteSearchURL = BaseAthleteURL + "search/";
export const BaseAthleteAttachmentURL = BaseAthleteURL + "attachment/";
export const BaseAthleteSeasonURL = BaseAthleteURL + "seasons/";
export const BaseAthleteSeasonListAllWithGroupsURL = BaseAthleteSeasonURL + "list_all_with_groups/";
export const BaseCategoryURL = BaseAthleteURL + "categories/";
export const BaseSeasonGroupURL = BaseAthleteSeasonURL + "groups/";
export const BaseSeasonGroupListAllURL = BaseSeasonGroupURL + "list_all/";
export const BaseGroupAthletesURL = BaseSeasonGroupURL + "athletes/";
export const BaseGroupAthletesGroupClassesNumberURL = BaseAthleteURL + "group-classes-number/";
export const BaseGroupAthletesAgeScaleURL = BaseAthleteURL + "athlete-age-scale/";
export const BaseGroupAthletesNewAndOldNumberURL = BaseAthleteURL + "new-and-old-number/";
export const BaseViewAthleteWorkoutAndAttendanceURL = BaseAthleteURL + "view-athlete-workout-and-attendance/";
export const BaseBenchmarkURL = BaseAthleteURL + "benchmarks/";
export const BaseAthleteBenchmarkURL = BaseAthleteURL + "athlete-benchmarks/";
export const BaseAthleteBenchmarkPerformanceURL= id => `${BaseAthleteURL+id}/benchmark_performance/`;

export const BaseClientURL = "clients/";
export const BaseClientsSearchURL = BaseClientURL + "search/";
export const BaseClientsAttachmentURL = BaseClientURL + "attachment/";
export const BaseClientsMembershipsURL = BaseClientURL + "memberships/";
export const BaseClientsAgeScaleURL = BaseClientURL + "age_scale/";
export const BaseClientBirthdaySoonURL = BaseClientURL + "up_coming_birthday/";
export const BaseClientCheckSocialIdIfExistsURL = BaseClientURL + "check_social_id_if_exists/";
export const BaseClientCheckMobileIfExistsURL = BaseClientURL + "check_mobile_if_exists/";


export const BaseMembershipURL = "memberships/";
export const BaseMembershipChangeLogsURL = BaseMembershipURL + "change-logs/";
export const BaseMembershipReportsURL = BaseMembershipURL + "reports/";
export const BaseMembershipTodaysMembershipsURL = BaseMembershipReportsURL + "todays-memberships/";
export const BaseMembershipsStatusReportURL = BaseMembershipReportsURL + "memberships-status-report/";
export const BaseMembershipLatestURL = BaseMembershipURL + "latest/";
export const BaseMembershipFinishingSoonURL = BaseMembershipURL + "finishing_soon/";
export const BaseMembershipFilterDataURL = BaseMembershipURL + "filter-data/";
export const BaseMembershipStartDateURL = BaseMembershipURL + "start-date/";
export const BaseMembershipPaymentURL = BaseMembershipURL + "payments/";
export const BaseMembershipFreezeURL = BaseMembershipURL + "freezes/";
export const BaseMembershipCancellationURL = BaseMembershipURL + "cancellation/";
export const BaseMembershipCanCancelURL = BaseMembershipCancellationURL + "check_can_cancel/";
export const BaseMembershipTransferURL = "transfer/";
export const BaseMembershipChangeInvoiceDateURL = "invoice_date/";
export const BaseMembershipChangeStartDateURL = "start_date/";
export const BaseMembershipUpgradePriceURL = "upgrade_price/";
export const BaseMembershipChangeDiscountURL = "change_discount/";
export const BaseMembershipChangeExtraDaysExtraSessionsURL = "change_extra_days_extra_sessions/";
export const BaseMembershipChangePreferredTrainingTimeURL = "change_preferred_training_time/";
export const BaseMembershipChangeNotesURL = "change_notes/";
export const BaseMembershipInvoiceURL = BaseBackEndURL + BaseMembershipURL + "invoice/pdf/?id=";


export const BaseRentURL = "rents/";
export const BaseRentStartDateURL = BaseRentURL + "start-date/";
export const BaseRentPaymentURL = BaseRentURL + "payments/";
export const BaseRentCancellationURL = BaseRentURL + "cancellation/";
export const BaseRentInvoiceURL = BaseBackEndURL + BaseRentURL + "invoice/pdf/?id=";


export const BaseStaffURL = "staff/";
export const BaseStaffUserURL = "staff/user/";
export const BaseStaffPermissionURL = BaseStaffURL + "staff-permission/";
export const BaseListPermissionGroupURL = BaseStaffURL + "list-permission-group/";
export const BaseAllStaffURL = BaseStaffURL + "list-all/";
export const BaseStaffAttachmentURL = BaseStaffURL + "attachment/";


// export const BaseMyLeavesListURL = "employees/my-leave/";
// export const BaseEmployeeLeavesURL = "employees/leaves/";

// export const BaseStoreCompanyURL = "stores-company/";
// export const BaseStoreCompaniesListURL = "companies-list/";
// export const BaseStoreCategoryURL = "stores-category/";
// export const BaseStoreCategoriesListURL = "catagories-list/";
// export const BaseStoreProductURL = "stores-product/";
// export const BaseStoreProductsListURL = "products-List/";

export const BaseFinanceURL = "finance/";
export const BaseFinanceReportsURL = BaseFinanceURL + "reports/";
export const BaseFinanceLocationsCashLogURL = BaseFinanceReportsURL + "locations-cash-log/";
export const BaseFinanceMyTotalRevenueURL = BaseFinanceReportsURL + "my-total-revenue/";
export const BaseFinanceStaffCashLogURL = BaseFinanceReportsURL + "staff-cash-log/";
export const BaseFinanceLocationsTotalRevenueURL = BaseFinanceReportsURL + "locations-total-revenue/";
export const BaseFinanceEntityTotalRevenueURL = BaseFinanceReportsURL + "entity-total-revenue/";
export const BaseFinanceExpenseURL = BaseFinanceURL + "expenses/";
export const BaseFinanceExpenseListAllURL = BaseFinanceExpenseURL + "list_all/";
export const BaseFinanceReimbursementURL = BaseFinanceURL + "reimbursements/";
export const BaseFinanceReimbursementAttachmentURL = BaseFinanceReimbursementURL + "attachments/";
export const BaseFinanceReimbursementPaymentURL = BaseFinanceReimbursementURL + "payments/";
export const BaseFinanceExpensePaymentURL = BaseFinanceExpenseURL + "payments/";
export const BaseFinanceExpenseCategoryURL = BaseFinanceExpenseURL + "category/";
export const BaseFinanceExpenseAttachmentURL = BaseFinanceExpenseURL + "attachment/";
export const BaseFinancePaymentsMethodURL = BaseFinanceURL + "payment-method/";
export const BaseFinanceBankAccountURL = BaseFinanceURL + "bank-account/";
export const BaseFinanceBankAccountAttachmentURL = BaseFinanceBankAccountURL + "attachments/";
export const BaseFinanceBankTransactionsURL = BaseFinanceURL + "bank-transactions/";
export const BaseFinanceCashTransferSenderURL = BaseFinanceURL + "my-cash-transfer/sender/";
export const BaseFinanceCashTransferCollectURL = BaseFinanceURL + "my-cash-transfer/collect/";
export const BaseFinanceListPendingCashTransferURL = BaseFinanceURL + "cash-transfer/pending/";
export const BaseFinanceCountPendingCashTransferURL = BaseFinanceListPendingCashTransferURL + "count/";
export const BaseFinanceCashTransferSenderCancellationURL = BaseFinanceCashTransferSenderURL + "cancel/";
export const BaseFinanceCashTransferRecipientURL = BaseFinanceURL + "my-cash-transfer/recipient/";
export const BaseFinanceCashTransferRecipientStatusURL = BaseFinanceCashTransferRecipientURL + "status/";
export const BaseFinanceCashLogURL = BaseFinanceURL + "cash-log/";
export const BaseFinanceMyCashBalanceURL = BaseFinanceURL + "my-cash-balance/";
export const BaseFinanceMyClaimRequestURL = BaseFinanceURL + "my-claims/";
export const BaseFinanceMyClaimAttachmentRequestURL = BaseFinanceMyClaimRequestURL + "attachment/";
export const BaseFinanceMyClaimPaymentsRequestURL = BaseFinanceMyClaimRequestURL + "payments/";
export const BaseFinanceClaimRequestURL = BaseFinanceURL + "claims/";
export const BaseFinanceClaimAttachmentURL = BaseFinanceClaimRequestURL + "attachment/";
export const BaseFinanceClaimPaymentsURL = BaseFinanceClaimRequestURL + "payments/";
export const BaseFinanceClaimApproveURL = id => `${BaseFinanceClaimRequestURL}${id}/approved_claim/`;
export const BaseFinanceVendorURL = BaseFinanceURL + "vendor/";
export const BaseFinanceVendorListAllURL = BaseFinanceVendorURL + "list_all/";


export const BaseClassesURL = "classes/";
export const BaseViewDeletedClassesURL = BaseClassesURL + "view_deleted_classes/";
export const BaseRestoreDeletedClassesURL = BaseClassesURL + "restore_deleted_classes/";
export const BaseClassRecurringURL = BaseClassesURL + "recurring/";
export const BaseClassesGetAllURL = BaseClassesURL + "get_all/";
export const BaseClassesDeleteAllURL = BaseClassesURL + "delete_all/";
export const BaseClassReportsURL = BaseClassesURL + "reports/";
export const BaseClassClassSessionsOfDayURL = BaseClassReportsURL + "classes_of_day/";
export const BaseClassScreenURL = BaseClassesURL + "screens/";
export const BaseClassSessionsListURL =  BaseClassesURL + `list/`;
export const BaseClassScreenListURL =  BaseClassScreenURL + `list/`;
export const BaseClassWorkoutURL = BaseClassesURL + "workouts/";
export const BaseClassesListByDateURL = BaseClassesURL + "list-by-date/";
export const BaseClassesAthleteInClassGroupsNotRegisterToClassURL = id => `${BaseClassesURL}${id}/athlete_in_class_groups_not_register_to_class/`;
export const BaseClassesChangeLocationTrainingAreaServiceURL = BaseClassesURL + "change_location_training_area_service/";
export const BaseClassesChangeStaffURL = BaseClassesURL + "change_staff/";
export const BaseClassesAddTeamURL = BaseClassesURL + "add_team/";
export const BaseClassesRemoveTeamURL = BaseClassesURL + "remove_team/";
export const BaseClassesChangeIsLockedURL = BaseClassesURL + "change_is_locked/";
export const BaseClassesChangeForClientsURL = BaseClassesURL + "change_for_clients/";
export const BaseClassesChangeClientsCapacityURL = BaseClassesURL + "change_clients_capacity/";
export const BaseClassesChangeGenderURL = BaseClassesURL + "change_gender/";
export const BaseClassesChangeOptionalAthletesCapacityURL = BaseClassesURL + "change_optional_athletes_capacity/";
export const BaseClassesChangeTimeDurationURL = BaseClassesURL + "change_time_duration/";
export const BaseClassesChangeNameURL = BaseClassesURL + "change_name/";

export const BaseClassSessionClientsAttendeesURL = BaseClassesURL + "attendees/clients/";
export const BaseClassRemoveClientAttendanceOnDateURL = BaseClassSessionClientsAttendeesURL + "remove_client_attendance_on_date/";

export const BaseClassSessionAthletesAttendeesURL = BaseClassesURL + "attendees/athletes/";
export const BaseClassRemoveAthleteAttendanceOnDateURL = BaseClassSessionAthletesAttendeesURL + "remove_athlete_attendance_on_date/";

export const BaseGenerateClassSessionsURL = BaseClassesURL + "generate-classes/";
export const BaseGenerateClassSessionsTimingURL = BaseClassesURL + "sessions-timing/";

export const BaseUserURL = "users/";
export const BaseMyProfileURL = BaseUserURL + "my-profile/";
export const BaseCheckUserDataURL = BaseUserURL + "check-user-password/";

export const BasePermissionsURL = BaseBackEndURL + "users/permissions/";
export const BaseGroupURL = BaseBackEndURL + "group/";

export const BaseMobileAppURL = "mobile-app/";
export const BaseBannersURL = `${BaseMobileAppURL}banners/`;


