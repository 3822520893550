import React, {useContext, useState} from "react";
import {getLabel} from "../../../../localization/MainLocalization";
import {sub_directory} from "../../../../App";
import Button from "@material-ui/core/Button";
import {Hide} from "../../../../components/Hide";
import {ViewDataModel} from "../../../../components/ViewDataModel";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {
    BaseClassRemoveAthleteAttendanceOnDateURL,
    BaseClassRemoveClientAttendanceOnDateURL,
} from "../../../../urls";
import {AlertContext} from "../../../../store/use_context";

export const ViewRegisterClasses = props => {
    const notes = `${props.for} already register at classes with same day`

    const {setAlert} = useContext(AlertContext);
    const [is_loading, setIsLoading] = useState(false);

    const [columns, setColumns] = useState( [
        {title: getLabel("name"), field: 'name',},
        {title: getLabel("start_time"), field: 'start_time'},
        {title: getLabel("end_time"), field: 'end_time'},
        {
            render: rowData => {
                return (
                    <Button size="small"
                            variant="outlined"
                            component='a'
                            target="_blank"
                            href={`${sub_directory}classes/?class-session=${rowData.id}&tag=${rowData.name}`}
                            >
                        {getLabel("view")}
                    </Button>
                )
            }
        }
    ]);

    const handleUnregisterClientFromClasses = async () => {
        await setIsLoading(true);
        let response;
        if (props.for === "Client"){
             response = await httpRequestWithAuth(BaseClassRemoveClientAttendanceOnDateURL, "PATCH", props.info);
        }else{
             response = await httpRequestWithAuth(BaseClassRemoveAthleteAttendanceOnDateURL, "PATCH", props.info);
        }
        if (response.status) {
            await props.handleModalClose();
            await props.addAttendance();
        } else {
            await setAlert(response.error_feedback.non_field_errors);
        }
        await setIsLoading(false);
    }

    return (
        <Hide hide={!props.open}>
            <ViewDataModel
                isLoading={is_loading}
                open={props.open}
                header="class_sessions"
                notes={notes}
                columns={columns}
                data={props.details}
                handleModalClose={props.handleModalClose}
                actionBtn={(
                    <Button
                        onClick={handleUnregisterClientFromClasses}
                        variant="contained"
                        color='secondary'
                        size="small"
                    >
                        {getLabel("unregister_from_classes")}
                    </Button>
                )}
            />
        </Hide>
    )
}
