import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseSeasonGroupListAllURL} from "../../urls";
import {not_validate_value} from "../../App";

export const GroupsAutoComplete = props => {
    const [options, setOptions] = useState([])
    const [view_name] = useState(props.groupFullName ? "group_full_name" : "name")

    useEffect(() => {
        const fetchData = async () => {
            let param = {}

            if (!not_validate_value.includes(props.activeGroup)){
                param['is_active'] = props.activeGroup
            }

            if (!not_validate_value.includes(props.activeSeason)){
                param['active_season'] = props.activeSeason
            }

            if (!not_validate_value.includes(props.season)){
                param['season'] = props.season
            }

            if (!not_validate_value.includes(props.category)){
                param['category'] = props.category
            }

            if (!not_validate_value.includes(props.categoryName)){
                param['category__name'] = props.categoryName
            }


            let response = await httpRequestWithAuth(BaseSeasonGroupListAllURL, "GET", null, param);
            if (response.status) {
                setOptions(response.data);
            } else {
                setOptions([])
            }
        }

        fetchData();
    }, [props.season, props.active_season, props.is_active, props.category, props.categoryName])

    const handleOnChange = (event, value) => {
        if (!props.viewOnly) {
            value = value !== null ? props.fullObject?value: value.id : "";
            props.onChangeHandle(props.name, value);
        }
    };

    const handleAutoCompleteValue = () => {
        let value = null;

        options.map(Data => {
            if (props.value === Data.id) {
                value = Data;
            }
            return 0;
        });

        return value;
    };


    return (
        <Autocomplete
            ListboxProps={{className: "list-box-autocomplete"}}
            renderOption={(option) => (
                <span className="w-100 text-start">{`${option[view_name]}`}</span>
            )}
            groupBy={option => props.groupedBy?option.season_name:null}
            disabled={props.disabled || props.viewOnly}
            options={options}
            getOptionLabel={option => `${option[view_name]}`}
            renderInput={params => (
                <TextField variant="outlined" {...params}
                           label={<label>{getLabel(props.label)}<span
                               className='text-danger'>{props.required ? "*" : ""}</span></label>}
                           helperText={props.helper_text}
                           error={props.error}
                           fullWidth
                           disabled={props.disabled}
                           className={props.disabled ? "disabled-field" : ""}
                />
            )}
            value={handleAutoCompleteValue()}
            onChange={handleOnChange}
        />
    );
}
