import React from "react";
import {getLabel} from "../../../../localization/MainLocalization";
import {sub_directory} from "../../../../App";
import Button from "@material-ui/core/Button";
import {Hide} from "../../../../components/Hide";
import {ViewDataModel} from "../../../../components/ViewDataModel";

export const ViewMembershipsChangedFreeze = props => {

    const columns = [
        {title: getLabel("invoice_id"), field: 'membership_invoice_id'},
        {title: getLabel("name"), field: 'membership_name'},
        {title: getLabel("service"), field: 'membership_service'},
        {title: getLabel("start_date"), field: 'membership_start_date'},
        {title: getLabel("end_date"), field: 'membership_end_date'},
        {
            render: rowData => {
                return (
                    <Button size="small"
                            variant="outlined"
                            component="a"
                            target="_blank"
                            href={`${sub_directory}memberships/?membership_id=${rowData.membership}&tag=${rowData.membership_name}&view-freeze=${true}`}
                    >
                        {getLabel("view")}
                    </Button>
                )
            }
        }
    ]
    return (
        <Hide hide={!props.open}>
            <ViewDataModel
                open={props.open}
                header="memberships"
                columns={columns}
                data={props.data}
                handleModalClose={props.handleModalClose}
            />
        </Hide>
    )
}
