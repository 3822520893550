import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Hide} from "../../../components/Hide";
import {VendorForm} from "./VendorForm";


export const VendorInfo = props => {
    const [currentTab, setCurrentTab] = useState(0);
    const [vendor_id, setVendorId] = useState("new");
    const {state} = props.history.location || {};


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };

    useEffect(()=>{
        if (state !== undefined && state.id){
            setVendorId(state.id);
        }else{
            props.history.push(`/vendors`);
        }
    },[])


    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match}/>*/}

            {/*<CustomTabs*/}
            {/*    currentTab={currentTab}*/}
            {/*    handleTabsChange={handleTabsChange}*/}
            {/*>*/}
            {/*    <Tab label={getLabel("details")} id={0}/>*/}
            {/*</CustomTabs>*/}

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <VendorForm
                        {...props}
                        vendor_id={vendor_id}
                    />
                </Hide>
            </Grid>
        </Paper>
    );
}