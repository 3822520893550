import React, {useContext, useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import {material_table_localization, material_table_style} from "../../../components/Material_conf";
import {AlertContext, ConfirmationContext} from "../../../store/use_context";
import Typography from "@material-ui/core/Typography";
import {Hide} from "../../../components/Hide";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {
    BaseRestoreDeletedClassesURL,
    BaseViewDeletedClassesURL
} from "../../../urls";
import {Loading} from "../../../components/Loading";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';

export const DeletedClasses = props => {
    const {setAlert} = useContext(AlertContext);
    const {setConfirmData} = useContext(ConfirmationContext)
    const [is_loading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);
    const columns = [
        {title: getLabel("name"), field: 'name',},
        {title: getLabel("service"), field: 'service_name'},
        {title: getLabel("location"), field: 'location_name'},
        {title: getLabel("staff"), field: 'staff_name'},
        {title: getLabel("for"), field: 'for_who'},
        {title: getLabel("training_area"), field: 'training_area_name'},
        {title: getLabel("capacity"), field: 'capacity'},
        {title: getLabel("vip_capacity"), field: 'vip_capacity'},
        {title: getLabel("day"), field: 'day'},
        {title: getLabel("date"), field: 'date_view'},
        {title: getLabel("start_time"), field: 'start_time_view'},
        {title: getLabel("end_time"), field: 'end_time_view'},
    ];

    const listData = async () =>{
        await setIsLoading(true);
        let response = await httpRequestWithAuth(BaseViewDeletedClassesURL, "GET")
        if (response.status){
            setData(response.data);
        }
        await setIsLoading(false);
    }

    useEffect(()=>{
        listData();
    }, [])


    const handleRestoreAll = async (e, data) => {
        setConfirmData({
            msg: `Are you sure to restore ${data.length} classes ??`,
            action: ()=> checkLockRestoredClasses(data)
        })
    }

    const checkLockRestoredClasses = async (data) => {
        setConfirmData({
            msg: `Do you want to lock restore classes ??`,
            action: ()=> RestoreAllAction(data, true),
            noBtnAction: ()=> RestoreAllAction(data, false),
        })
    }


    const RestoreAllAction = async (data, is_locked = false) => {
        await setIsLoading(true);

        let temp_data = []
        data.map(session => {
            temp_data.push(session.id)
        })

        let response = await httpRequestWithAuth(BaseRestoreDeletedClassesURL, "PATCH", {is_locked,classes:temp_data})

        if (response.status) {
            await listData();
        } else {
            await setAlert(response.error_feedback.non_field_errors)
        }
        await setIsLoading(false);
    }


    return (
        <>


            <Paper className="Paper">
                <Grid container spacing={2} className="pt-4 pb-3">
                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>


                    <Hide hide={is_loading}>
                        <Grid item xs={12}>
                            <MaterialTable
                                columns={columns}
                                data={data}
                                isLoading={is_loading}

                                options={{
                                    selection: true,
                                    search: true,
                                    showTitle: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10],
                                    searchFieldAlignment: "left",
                                    selectionProps: rowData => ({
                                        color: 'primary'
                                    }),
                                    showTextRowsSelected: false,
                                }}

                                actions={[
                                    {
                                        icon: () => (
                                            <Button key="0" size="small" variant="contained" color='primary'
                                                    startIcon={<RestoreFromTrashIcon/>}>
                                                <Typography variant="body1">{getLabel('restore_classes')}</Typography>
                                            </Button>
                                        ),
                                        tooltip: getLabel('restore_classes'),
                                        onClick: handleRestoreAll,
                                        hidden: !Permissions.checkPermission(permissions_data.classes.restore_deleted_classes),
                                    },
                                ]}

                                {...material_table_style}
                                {...material_table_localization()}
                            />
                        </Grid>
                    </Hide>

                </Grid>
            </Paper>
        </>
    )
}