import React, {useContext, useState} from "react";
import {getLabel} from "../../../../localization/MainLocalization";
import {sub_directory} from "../../../../App";
import Button from "@material-ui/core/Button";
import {Hide} from "../../../../components/Hide";
import {ViewDataModel} from "../../../../components/ViewDataModel";
import DeleteIcon from '@material-ui/icons/Delete';
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseListLocationsFreezeDeleteClassSessionsOnFreezeURL} from "../../../../urls";
import {AlertContext} from "../../../../store/use_context";

export const ViewClassSessionsToDelete = props => {
    const notes = "To apply freeze need to delete this class sessions, click delete all to complete apply process"
    const {setAlert} = useContext(AlertContext);
    const [is_loading, setIsLoading] = useState(false);

    const handleDeleteAllBtn = async () => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(BaseListLocationsFreezeDeleteClassSessionsOnFreezeURL(props.freeze_id), "DELETE");
        if (response.status) {
            await props.handleModalClose();
            await props.handleApply(props.freeze_id);
        } else {
            await setAlert(response.error_feedback.non_field_errors);
        }
        await setIsLoading(false);
    }
    const columns = [
        {title: getLabel("name"), field: 'name',},
        {title: getLabel("service"), field: 'service_name'},
        {title: getLabel("date"), field: 'date'},
        {
            title: getLabel('registered_number'),
            render: rowData => {
                return (
                    <a href={`${sub_directory}classes/?attend-for-class-session=${rowData.id}&tag=${rowData.name}`}
                       target="_blank">{`${rowData.current_registered_number} ${getLabel('client(s)')}`}</a>
                )
            },
        },
        {
            render: rowData => {
                return (
                    <Button size="small"
                            variant="outlined"
                            onClick={() => props.history.push({
                                pathname: sub_directory + 'classes/edit',
                                state: {
                                    id: rowData.id,
                                    tag: rowData.name
                                }
                            })}>
                        {getLabel("view")}
                    </Button>
                )
            }
        }
    ]
    return (
        <Hide hide={!props.open}>
            <ViewDataModel
                isLoading={is_loading}
                open={props.open}
                header="class_sessions"
                notes={notes}
                columns={columns}
                data={props.data}
                handleModalClose={props.handleModalClose}
                actionBtn={(
                    <Button
                        onClick={handleDeleteAllBtn}
                        variant="contained"
                        color='secondary'
                        size="medium"
                        startIcon={<DeleteIcon/>}
                    >
                        {getLabel("delete_all")}
                    </Button>
                )}
            />
        </Hide>
    )
}