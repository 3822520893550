import React from "react";
import {CustomModal} from "./CustomModal";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "./FormHeader";
import MaterialTable from "material-table";
import {material_table_localization} from "./Material_conf";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import {getLabel} from "../localization/MainLocalization";
import {ErrorBox} from "./ErrorBox";
import {Hide} from "./Hide";
import {Loading} from "./Loading";


export const ViewDataModel = props => {
    return (
        <CustomModal
            open={props.open}
        >
            <Grid container spacing={2}>

                <FormHeader
                    title={props.header}
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!props.isLoading}>
                    <Loading/>
                </Hide>

                <Hide hide={props.isLoading}>

                    <ErrorBox
                        errorMsg={props.notes}
                    />

                    <Grid item xs={12}>
                        <MaterialTable
                            columns={props.columns}
                            data={props.data}
                            style={{boxShadow: "none"}}
                            options={{
                                pageSize: 5,
                                pageSizeOptions: [5],
                                actionsColumnIndex: -1,
                                showTitle: false,
                                searchFieldAlignment: 'left',
                                ...props.options
                            }}

                            {...material_table_localization()}
                        />
                    </Grid>


                    <Grid item xs={12} className='d-inline-flex justify-content-between mt-2'>
                        <Button
                            onClick={props.handleModalClose}
                            variant="contained"
                            color='default'
                            size="small"
                            startIcon={<CancelIcon/>}
                        >
                            {getLabel("cancel")}
                        </Button>

                        {props.actionBtn}
                    </Grid>
                </Hide>

            </Grid>
        </CustomModal>
    )
}