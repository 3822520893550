import React from "react";
import {TextField} from "@material-ui/core";
import {getLabel} from "../../localization/MainLocalization";
import {not_validate_value} from "../../App";

export const CustomTextField = props => {
    const handleFieldChange = async (e) =>{
        if (!props.viewOnly){
            await props.onChangeHandle(e.target.name, e.target.value)
        }
    }

    const onBlur = async (e, x) =>{
        if (props.onBlur){
            props.onBlur(e.target.value)
        }
    }

    return (
        <TextField
            size={props.size || "medium"}
            autoComplete="off"
            helperText={props.helper_text}
            variant="outlined"
            error={props.error}
            name={props.name}
            type={props.type + ""}
            label={<label> {getLabel(props.label)}<span className='text-danger'>{props.required ? "*" : ""}</span></label>}
            value={not_validate_value.includes(props.value)? "": props.value + ""}
            onChange={handleFieldChange}
            fullWidth
            disabled={props.disabled}
            className={props.disabled ? "disabled-field " + props.className : props.className}
            onBlur={onBlur}
        />
    )
};
