import React, {useContext} from "react";
import Button from "@material-ui/core/Button";
import LanguageIcon from "@material-ui/icons/Language";
import {dir, redirect} from "../App";
import {LanguageContext} from "../store/use_context";


export const LanguageBtn = props =>{
    const {changeLanguage} = useContext(LanguageContext)

    return(
        <Button
            onClick={()=>changeLanguage()}
            className={props.className}
            startIcon={<LanguageIcon/>}
        >
            {dir === "rtl" ? "EN" : "AR"}
        </Button>
    )
}