import React from 'react';
import {Route, Switch} from "react-router-dom";
import {Redirect} from "react-router";
import ProtectedRoute from "./ProtectedRoute";
import {Entity} from "../pages/setting/entity/Entity";
import {Service} from "../pages/setting/services/service/Service";
import {PriceLists} from "../pages/setting/services/price_list/PriceList";
import {Prices} from "../pages/setting/services/price/Prices";
import {OffersPriceList} from "../pages/setting/services/price_list_offers/PriceListsOffers";
import {PricesOffers} from "../pages/setting/services/price_offers/OffersPrices";
import {Locations} from "../pages/setting/locations/Locations";
import {LocationInfo} from "../pages/setting/locations/LocationsInfo";
import {Staff} from "../pages/human_resources/staff/Staff";
import {StaffInfo} from "../pages/human_resources/staff/StaffInfo";
import {Clients} from "../pages/main/clients/Clients";
import {ClientInfo} from "../pages/main/clients/ClientInfo";
import {Memberships} from "../pages/main/memberships/Memberships";
import {MembershipInfo} from "../pages/main/memberships/MembershipInfo";
import {ExpenseCategory} from "../pages/finance/expenses/ExpenseCategory";
import {ExpenseInfo} from "../pages/finance/expenses/ExpenseInfo";
import {PaymentMethod} from "../pages/setting/entity/payment_method/PaymentMethod";
import {BankAccountInfo} from "../pages/finance/bank_account/BankAccountInfo";
import {Equipment} from "../pages/setting/equipment/Equipment";
import Rents from "../pages/main/rents/Rents";
import {RentInfo} from "../pages/main/rents/RentInfo";
import {permissions_data} from "../auth/permission_data";
import {RecurringClasses} from "../pages/classes/recurring_classes/RecurringClasses";
import {RecurringClassesInfo} from "../pages/classes/recurring_classes/RecurringClassesInfo";
import {Classes} from "../pages/classes/classes/Classes";
import {ClassInfo} from "../pages/classes/classes/ClassInfo";
import {Workouts} from "../pages/classes/workouts/Workouts";
import {WorkoutInfo} from "../pages/classes/workouts/WorkoutInfo";
import {CashTransfer} from "../pages/finance/cash_transfer/CashTransfer";
import {BankTransactions} from "../pages/finance/bank_transactions/BankTransactions";
import {BankAccount} from "../pages/finance/bank_account/BankAccount";
import {CashLog} from "../pages/finance/cash_log/CashLog";
import {Dashboard} from "../pages/main/dashboard/Dashboard";
import {Reports} from "../pages/main/reports/Reports";
import {ClassesBulkEdit} from "../pages/classes/classes/ClassesBulkEdit";
import {Banners} from "../pages/mobile_app/banners/Banners";
import {BannerInfo} from "../pages/mobile_app/banners/BannerInfo";
import {sub_directory} from "../App";
import {MyClaimRequests} from "../pages/finance/claims/MyClaimRequests";
import {MyClaimsInfo} from "../pages/finance/claims/MyClaimsInfo";
import {Expenses} from "../pages/finance/expenses/Expenses";
import {StaffClaimRequests} from "../pages/finance/claims/StaffClaimRequests";
import {StaffClaimsInfo} from "../pages/finance/claims/StaffClaimsInfo";
import {Vendors} from "../pages/finance/vendor/Vendors";
import {VendorInfo} from "../pages/finance/vendor/VendorInfo";
import {Athletes} from "../pages/main/athletes/Athletes";
import {AthleteInfo} from "../pages/main/athletes/AthleteInfo";
import {Seasons} from "../pages/setting/seasons/Seasons";
import {Groups} from "../pages/setting/seasons/groups/Groups";
import {AthletesGroups} from "../pages/setting/seasons/groups/athletes/AthletesGroup";
import {Benchmarks} from "../pages/setting/benchmarks/Benchmarks";
import {DeletedClasses} from "../pages/classes/deleted_classes/DeletedClasses";

const Routes = props => {

        return (
        <Switch>

            <ProtectedRoute title="Account" path={`/:sub_directory/teams/athletes`} permission={[permissions_data.athlete_group.view]}
                            component={AthletesGroups}/>
            {/*<ProtectedRoute title="Account" path={`/:sub_directory/seasons/groups`} permission={[permissions_data.group.view]}*/}
            {/*                component={Groups}/>*/}
            <ProtectedRoute title="Account" path={`/:sub_directory/teams`} permission={[permissions_data.season.view]}
                            component={Seasons}/>

            <ProtectedRoute title="Athletes" key="edit-athletes" path={`/:sub_directory/athletes/edit`} permission={[permissions_data.athlete.view]}
                            component={AthleteInfo}/>
            <ProtectedRoute title="Athletes" key="add-athletes" path={`/:sub_directory/athletes/new`} permission={[permissions_data.athlete.add]}
                            component={AthleteInfo}/>
            <ProtectedRoute title="Athletes" key="athletes" path={`/:sub_directory/athletes`} permission={[permissions_data.athlete.view]}
                            component={Athletes}/>

            <ProtectedRoute title="Mobile App" key="add-Banner" path={`/:sub_directory/banners/new`}
                            permission={[permissions_data.banners.add]}
                            component={BannerInfo}/>
            <ProtectedRoute title="Mobile App" key="edit-Banner" path={`/:sub_directory/banners/edit`}
                            permission={[permissions_data.banners.view]}
                            component={BannerInfo}/>
            <ProtectedRoute title="Mobile App" key="banners" path={`/:sub_directory/banners`} permission={[permissions_data.banners.view]}
                            component={Banners}/>

            <ProtectedRoute title="Workouts" key="add-workouts" path={`/:sub_directory/workouts/new`}
                            permission={[permissions_data.workout.add]}
                            component={WorkoutInfo}/>
            <ProtectedRoute title="Workouts" key="edit-workouts" path={`/:sub_directory/workouts/edit`}
                            permission={[permissions_data.workout.view]}
                            component={WorkoutInfo}/>
            <ProtectedRoute title="Workouts" key="workouts" path={`/:sub_directory/workouts`}
                            permission={[permissions_data.workout.view]}
                            component={Workouts}/>



            <ProtectedRoute title="Classes" key="deleted-classes" path={`/:sub_directory/classes/deleted-classes`}
                            permission={[permissions_data.classes.view_deleted_classes]}
                            component={DeletedClasses}/>

            <ProtectedRoute title="Classes" key="classes-bulk-edit" path={`/:sub_directory/classes/edit-classes`}
                            permission={[permissions_data.classes.change]}
                            component={ClassesBulkEdit}/>


            <ProtectedRoute title="Classes" key="add-class-session" path={`/:sub_directory/classes/new`}
                            permission={[permissions_data.classes.add]}
                            component={ClassInfo}/>
            <ProtectedRoute title="Classes" key="edit-class-session" path={`/:sub_directory/classes/edit`}
                            permission={[permissions_data.classes.view]}
                            component={ClassInfo}/>
            <ProtectedRoute title="Classes" path={`/:sub_directory/classes`} permission={[permissions_data.classes.view]}
                            component={Classes}/>


            <ProtectedRoute title="Classes" key="add-class" path={`/:sub_directory/recurring-classes/new`}
                            permission={[permissions_data.recurring_classes.add]}
                            component={RecurringClassesInfo}/>
            <ProtectedRoute title="Classes" key="edit-class" path={`/:sub_directory/recurring-classes/edit`}
                            permission={[permissions_data.recurring_classes.view]}
                            component={RecurringClassesInfo}/>
            <ProtectedRoute title="Classes" path={`/:sub_directory/recurring-classes`}
                            permission={[permissions_data.recurring_classes.view]}
                            component={RecurringClasses}/>


            {/*<ProtectedRoute path={`/:sub_directory/reimbursements/edit`}*/}
            {/*                permission={[permissions_data.reimbursement.view]}*/}
            {/*                component={ReimbursementInfo}/>*/}
            {/*<ProtectedRoute path={`/:sub_directory/reimbursements`} permission={[permissions_data.reimbursement.view]}*/}
            {/*                component={Reimbursements}/>*/}

            <ProtectedRoute title="Finance" path={`/:sub_directory/cash-log`} permission={[permissions_data.cash_log.view]}
                            component={CashLog}/>


            <ProtectedRoute title="Finance" path={`/:sub_directory/cash-transfer-requests`}
                            permission={[permissions_data.cash_transfer_request.view]}
                            component={CashTransfer}/>


            <ProtectedRoute title="Finance" key="add-rent" path={`/:sub_directory/rents/new`} permission={[permissions_data.rent.add]}
                            component={RentInfo}/>
            <ProtectedRoute title="Finance" key="edit-rent" path={`/:sub_directory/rents/edit`}
                            permission={[permissions_data.rent.view]}
                            component={RentInfo}/>
            <ProtectedRoute title="Finance" path={`/:sub_directory/rents`} permission={[permissions_data.rent.view]}
                            component={Rents}/>


            <ProtectedRoute title="Finance" path={`/:sub_directory/equipment`} permission={[permissions_data.equipment.view]}
                            component={Equipment}/>


            <ProtectedRoute title="Finance" path={`/:sub_directory/bank-transactions`}
                            permission={[permissions_data.bank_transactions.view]}
                            component={BankTransactions}/>


            <ProtectedRoute title="Finance" key="new-bank-transfer" path={`/:sub_directory/bank-account/new`}
                            permission={[permissions_data.bank_account.add]}
                            component={BankAccountInfo}/>
            <ProtectedRoute title="Finance" key="edit-bank-transfer" path={`/:sub_directory/bank-account/edit`}
                            permission={[permissions_data.bank_account.view]}
                            component={BankAccountInfo}/>
            <ProtectedRoute title="Finance" path={`/:sub_directory/bank-account`} permission={[permissions_data.bank_account.view]}
                            component={BankAccount}/>


                <ProtectedRoute title="Finance" key="new-vendors" path={`/:sub_directory/vendors/new`}
                                permission={[permissions_data.vendor.add]}
                                component={VendorInfo}/>
                <ProtectedRoute title="Finance" key="edit-vendors" path={`/:sub_directory/vendors/edit`}
                                permission={[permissions_data.vendor.view]}
                                component={VendorInfo}/>
                    <ProtectedRoute title="Finance" key="vendors" path={`/:sub_directory/vendors`}
                            permission={[permissions_data.vendor.view]}
                            component={Vendors}/>


            <ProtectedRoute title="Finance" key="edit-my-claims" path={`/:sub_directory/claims/view`}
                            permission={[permissions_data.claim.view_staff]}
                            component={StaffClaimsInfo}/>
            <ProtectedRoute title="Finance" key="staff-claims" path={`/:sub_directory/claims`}
                            permission={[permissions_data.claim.view_staff]}
                            component={StaffClaimRequests}/>

            <ProtectedRoute title="Finance" key="new-my-claims" path={`/:sub_directory/my-claims/new`}
                            permission={[permissions_data.claim.add]}
                            component={MyClaimsInfo}/>
            <ProtectedRoute title="Finance" key="edit-my-claims" path={`/:sub_directory/my-claims/edit`}
                            permission={[permissions_data.claim.view]}
                            component={MyClaimsInfo}/>
            <ProtectedRoute title="Finance" key="my-claims" path={`/:sub_directory/my-claims`}
                            permission={[permissions_data.claim.view]}
                            component={MyClaimRequests}/>

            <ProtectedRoute title="Finance" path={`/:sub_directory/expenses/category`}
                            permission={[permissions_data.expense_category.view]}
                            component={ExpenseCategory}/>

            <ProtectedRoute title="Finance" key="new-expense" path={`/:sub_directory/expenses/new`}
                            permission={[permissions_data.expense.add]}
                            component={ExpenseInfo}/>
            <ProtectedRoute title="Finance" key="edit-expense" path={`/:sub_directory/expenses/edit`}
                            permission={[permissions_data.expense.view]}
                            component={ExpenseInfo}/>
            <ProtectedRoute title="Finance" path={`/:sub_directory/expenses`} permission={[permissions_data.expense.view]}
                            component={Expenses}/>

            <ProtectedRoute title="Finance" path={`/:sub_directory/payment-method`} permission={[permissions_data.payment_method.view]}
                            component={PaymentMethod}/>


            <ProtectedRoute title="Clients" key="new-clients" path={`/:sub_directory/clients/new`}
                            permission={[permissions_data.client.add]} component={ClientInfo}/>
            <ProtectedRoute title="Clients" key="edit-clients" path={`/:sub_directory/clients/edit`}
                            permission={[permissions_data.client.view]} component={ClientInfo}/>
            <ProtectedRoute title="Clients" path={`/:sub_directory/clients`} permission={[permissions_data.client.view]}
                            component={Clients}/>


            <ProtectedRoute title="Memberships" key="add-member" path={`/:sub_directory/memberships/new`}
                            permission={[permissions_data.membership.add]}
                            component={MembershipInfo}/>
            <ProtectedRoute title="Memberships" key="edit-member" path={`/:sub_directory/memberships/edit`}
                            permission={[permissions_data.membership.view]}
                            component={MembershipInfo}/>
            <ProtectedRoute title="Memberships" path={`/:sub_directory/memberships`} permission={[permissions_data.membership.view]}
                            component={Memberships}/>


            <ProtectedRoute title="Account" key="new-staff" path={`/:sub_directory/staff/new`}
                            permission={[permissions_data.staff.add]} component={StaffInfo}/>
            <ProtectedRoute title="Account" key="old-staff" path={`/:sub_directory/staff/edit`}
                            permission={[permissions_data.staff.view]} component={StaffInfo}/>
            <ProtectedRoute title="Account" path={`/:sub_directory/staff`} permission={[permissions_data.staff.view]}
                            component={Staff}/>

            <ProtectedRoute title="Account" path={`/:sub_directory/entity`} permission={[permissions_data.entity.view]}
                            component={Entity}/>


            <ProtectedRoute title="Account" key="new-location" path={`/:sub_directory/locations/new`}
                            permission={[permissions_data.location.add]} component={LocationInfo}/>
            <ProtectedRoute title="Account" key="edit-location" path={`/:sub_directory/locations/edit`}
                            permission={[permissions_data.location.view]} component={LocationInfo}/>
            <ProtectedRoute title="Account" path={`/:sub_directory/locations`} permission={[permissions_data.location.view]}
                            component={Locations}/>


            <ProtectedRoute title="Account" path={`/:sub_directory/services/price-lists/offers/prices`}
                            permission={[permissions_data.offer_price.view]}
                            component={PricesOffers}/>
            <ProtectedRoute title="Account" path={`/:sub_directory/services/price-lists/offers`}
                            permission={[permissions_data.offer_price_list.view]}
                            component={OffersPriceList}/>
            <ProtectedRoute title="Account" path={`/:sub_directory/services/price-lists/prices/`}
                            permission={[permissions_data.price.view]}
                            component={Prices}/>
            <ProtectedRoute title="Account" path={`/:sub_directory/services/price-lists`}
                            permission={[permissions_data.price_list.view]}
                            component={PriceLists}/>
            <ProtectedRoute title="Account" path={`/:sub_directory/services`} permission={[permissions_data.service.view]}
                            component={Service}/>

            <ProtectedRoute title="Benchmarks" path={`/:sub_directory/benchmarks`} permission={[permissions_data.benchmark.view]}
                            component={Benchmarks}/>

            <ProtectedRoute title="Reports" path={`/:sub_directory/reports`} permission={[permissions_data.entity.view_reports]} component={Reports}/>

            <ProtectedRoute title="Dashboard" path={`/:sub_directory/dashboard`} permission={true} component={Dashboard}/>

            <Route exact title="Dashboard" path="/:sub_directory" render={() => <Redirect to={`${sub_directory}dashboard`}/>}/>

            <Route exact title="Dashboard" path="/:sub_directory/" render={() => <Redirect to={`${sub_directory}dashboard`}/>}/>

            {/*<Route path="*" component={NotFoundOne}/>*/}
            {/*<Route path="*" render={() => <NotFoundOne hideHomeBtn/>}/>*/}
                <Route path="*" render={() => <Redirect to="/notfound"/>}/>
        </Switch>

    );
}


export default Routes;
