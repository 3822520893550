import React, {useContext, useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {Hide} from "../../../components/Hide";
import {AthleteAttachmentFormModal} from "./components/AthleteAttachmentFormModal";
import {permissions_data} from "../../../auth/permission_data";
import {ActionButtons} from "../../../components/ActionButton";
import {not_validate_value, sub_directory} from "../../../App";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseAthleteAttachmentURL} from "../../../urls";
import {AlertContext} from "../../../store/use_context";


export const AthleteAttachment = props => {
    const {athlete_id} = props;
    const {setAlert} = useContext(AlertContext);
    const [material_table_localization_value] = useState(material_table_localization());

    const columns = [
        {title: getLabel("name"), field: 'name'},
    ];
    const [attachments, setAttachments] = useState([])
    const [helper_text, setHelperText] = useState({})
    const [form_open, setFormOpen] = useState(false)
    const [form_data, setFormData] = useState({})
    const [edit_attach, setEditAttach] = useState(false)
    const [is_loading, setIsLoading] = useState(true)

    const listData = async () => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(`${BaseAthleteAttachmentURL}`, "GET", {athlete:athlete_id});
        if (response.status) {
            await setAttachments(response.data);
        }

        await setIsLoading(false);
    };

    useEffect(() => {
        listData();
    }, [])

    const onChangeHandle = (name, value) => {
        setFormData({...form_data, [name]: value});
    }

    const handleModalOpen = (data, edit) => {
        setFormData(data)
        setEditAttach(edit)
        setFormOpen(true)
    };

    const handleModalClose = async () => {
        await listData();
        setHelperText({});
        setFormData({})
        setFormOpen(false)

    };

    const handleSaveModal = async () => {
        await setIsLoading(true)

        let bodyFormData = new FormData();
        bodyFormData.append('athlete', athlete_id);
        Object.keys(form_data).map(Name => {
            if (!not_validate_value.includes(form_data[Name])) {
                bodyFormData.append(Name, form_data[Name]);
            }
        })


        let response;
        if (form_data.id === undefined) {
            response = await httpRequestWithAuth(BaseAthleteAttachmentURL, "POST", bodyFormData);

        } else {
            bodyFormData.delete('file');
            response = await httpRequestWithAuth(`${BaseAthleteAttachmentURL}${form_data.id}/`, "PATCH", bodyFormData);

        }

        if (response.status) {
            await handleModalClose();
        } else {
            setHelperText(response.error_feedback)
        }
        await setIsLoading(false)
    };

    const handleBackButton = () => {
        props.history.push(sub_directory + "athletes")
    }

    return (
        <>
            <Hide hide={!form_open}>
                <AthleteAttachmentFormModal
                    open={form_open}
                    handleModalClose={handleModalClose}
                    data={form_data}
                    handleSaveForm={handleSaveModal}
                    helper_text={helper_text}
                    onChangeHandle={onChangeHandle}
                    is_loading={is_loading}
                    edit_attach={edit_attach}
                />
            </Hide>

            <Grid item xs={12}>
                <MaterialTable
                    style={{padding: '0.5rem', boxShadow: "none"}}
                    is_loading={is_loading}
                    columns={columns}
                    data={attachments}
                    editable={Permissions.checkPermission(permissions_data.athlete_attachment.delete) && {
                        onRowDelete: async attachmentData => {
                            let response = await httpRequestWithAuth(`${BaseAthleteAttachmentURL}${attachmentData.id}/`, "DELETE");
                            if (response.status) {
                                await listData();
                            } else {
                                setAlert(response.error_feedback.non_field_errors)
                            }
                        }
                    }}


                    actions={[
                        {
                            icon: () => (
                                <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                        startIcon={<AddIcon/>}>
                                    <Typography variant="body1">{getLabel('add_attachment')}</Typography>
                                </Button>
                            ),
                            hidden: !Permissions.checkPermission(permissions_data.athlete_attachment.add),
                            isFreeAction: true,
                            onClick: () => handleModalOpen({}, true)
                        },
                        {
                            icon: () => (
                                <Button size="small"
                                        color="primary"
                                        variant="outlined">
                                    {getLabel("view_file")}
                                </Button>
                            ),
                            tooltip: getLabel('view_file'),
                            onClick: (event, rowData) => window.open(rowData.file)
                        },
                        {
                            icon: 'edit',
                            tooltip: getLabel('edit_attachment'),
                            hidden: !Permissions.checkPermission(permissions_data.athlete_attachment.change),
                            onClick: (event, rowData) => handleModalOpen(rowData, false)
                        }
                    ]}

                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        showTitle: false
                    }}

                    {...material_table_localization_value}
                />
            </Grid>

            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />
        </>
    );

}
