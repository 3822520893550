import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {
    BaseGenerateClassSessionsTimingURL, BaseMembershipChangeNotesURL,
    BaseMembershipChangePreferredTrainingTimeURL,
    BaseMembershipURL
} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {SelectField} from "../../../../components/fields/SelectField";
import {CustomTextArea} from "../../../../components/fields/CustomTextArea";


export const ChangeNotesModal = props => {
    const [data , setData] = useState({...props.default_value});
    const [helper_text , setHelperText] = useState({});
    const [is_loading , setIsLoading] = useState(false);

    const handleFieldChange = async (name, newValue) => {
       await setData({...data, [name]:newValue})
    }


    const handleSaveButton = async () => {
        await setIsLoading(true);

        let url = `${BaseMembershipURL}${props.membership}/${BaseMembershipChangeNotesURL}`;
        let response = await httpRequestWithAuth(url, "PATCH", data)

        if (response.status) {
            await props.handleModalClose(true)
        } else {
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };


    return (

            <CustomModal
                open={props.open}
            >
                    <Grid container spacing={2}>

                        <FormHeader
                            title="change_extra_days_and_extra_sessions"
                            handleClose={()=>props.handleModalClose(false)}
                        />

                        <Hide hide={!is_loading}>
                            <Loading/>
                        </Hide>
                        
                        <Hide hide={is_loading}>

                            <Grid item xs={12}>
                                <CustomTextArea
                                    label="notes"
                                    error={helper_text.notes === undefined}
                                    helper_text={helper_text.notes}
                                    name="notes"
                                    rows={3}
                                    value={data.notes}
                                    onChangeHandle={handleFieldChange}
                                />
                            </Grid>

                            <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={()=>props.handleModalClose(false)}
                            handleSaveButton={handleSaveButton}
                        />
                        </Hide>
                    </Grid>
            </CustomModal>
        );
}