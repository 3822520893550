import React, {useRef, useState} from 'react';
import Permissions from "../../../auth/permissions";
import MaterialTable from "material-table";
import {instance} from "../../../auth/axios_instance";
import {BaseAthleteURL} from "../../../urls";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {sub_directory} from "../../../App";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";
import {permissions_data} from "../../../auth/permission_data";
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";

export const Athletes = props => {

    const tableRef = useRef();

    const [material_table_localization_value] = useState(material_table_localization());
    const [material_table_options_value] = useState(material_table_options());

    const columns = [
        {
            title: getLabel("name"), render: rowData => (
                <Typography>
                    <Link href="" onClick={e => {
                        e.preventDefault();
                        viewAthlete(rowData.id, rowData.short_name)
                    }}>
                        {rowData.full_name}
                    </Link>
                </Typography>
            ), cellStyle: {width: "50%"}
        },
        {title: getLabel("mobile"), field: 'mobile_view'},
        {title: getLabel("gender"), field: 'gender', cellStyle: {width: "10%"}},
        {title: getLabel("birthday"), field: 'birth_date_view', cellStyle: {width: "100px"}},

    ];
    const [search, setSearch] = useState("");

    const viewAthlete = (id, tag) => {
        props.history.push({
            pathname: sub_directory + 'athletes/edit',
            state: {
                id: id,
                tag: tag
            }
        })
    };


    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if ((newValue.length >= 5 && /^\d+$/.test(newValue)) || newValue === '') {
            reloadData();
        }

    };


    return (
        <>
            <Paper>
                <TableToolbar
                    searchFieldName='search'
                    searchValue={search}
                    onSearchChangeHandle={handleSearchField}
                    searchAction={reloadData}
                    match={props.match}
                    actions={
                        [
                            {
                                hide: !Permissions.checkPermission(permissions_data.athlete.add),
                                onClick: () => props.history.push({
                                    pathname: sub_directory + 'athletes/new',
                                    state: {id: "new"}
                                }),
                                label: "add_athlete"
                            },
                        ]
                    }
                />

                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let url = BaseAthleteURL;

                            url += '?page=' + (query.page + 1);

                            if (search !== '') {
                                url += '&search=' + search;
                            }

                            instance.get(url)
                                .then(result => {
                                    resolve({
                                        data: result.data.results,
                                        page: result.data.page - 1,
                                        totalCount: result.data.total,
                                    })
                                })
                        })
                    }

                    {...material_table_style}
                    {...material_table_options_value}
                    {...material_table_localization_value}

                />

            </Paper>
        </>
    );
}
