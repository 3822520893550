import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {CustomModal} from "../../../../components/CustomModal";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseAthleteBenchmarkURL} from "../../../../urls";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {BenchmarkAutoComplete} from "../../../../components/fields/BenchmarkAutoComplete";
import {not_validate_value} from "../../../../App";
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {DateField} from "../../../../components/fields/DateField";
import moment from "moment";

export const AthleteBenchmarkFormModal = props => {
    const [is_loading, setIsLoading] = useState(false);
    const [helper_text, setHelperText] = useState([])
    const [data, setData] = useState({...props.data})
    const [benchmark_data, setBenchmarkData] = useState({})


    const handleFieldChange = (name, newValue) => {
        let new_data = {...data, [name]: newValue};

        if (name === "benchmark") {
            let value = not_validate_value.includes(newValue) ? {} : newValue;
            setBenchmarkData(value);
            new_data[name] = value.id;
        }

        setData(new_data);
    };

    const handleSaveForm = async () => {
        await setIsLoading(true);
        let temp_data = {
            ...data,
            date: not_validate_value.includes(data.date)? null:moment(data.date).format("YYYY-MM-DD"),
        };


        let response;
        if (data.id === undefined) {
            response = await httpRequestWithAuth(BaseAthleteBenchmarkURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${BaseAthleteBenchmarkURL}${data.id}/`, "PATCH", temp_data);
        }

        if (response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    return (
        <CustomModal
            open={props.open}
        >
            <Grid container spacing={3}>

                <FormHeader
                    title="athlete_benchmark_form"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>


                    <Grid item xs={12} md={6}>
                        <BenchmarkAutoComplete
                            required
                            fullObject
                            error={helper_text.benchmark !== undefined}
                            helper_text={helper_text.benchmark}
                            name="benchmark"
                            label="benchmark"
                            value={data.benchmark}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DateField
                            openToYear
                            required
                            error={helper_text.date !== undefined}
                            helper_text={helper_text.date}
                            name="date"
                            label="date"
                            value={data.date}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>

                    <Hide hide={!benchmark_data.reps}>
                        <Grid item xs={12}>
                            <CustomTextField
                                required
                                error={helper_text.reps !== undefined}
                                helper_text={helper_text.reps}
                                name="reps"
                                label="reps"
                                value={data.reps}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>
                    </Hide>


                    <Hide hide={!benchmark_data.rounds}>
                        <Grid item xs={12}>
                            <CustomTextField
                                required
                                error={helper_text.rounds !== undefined}
                                helper_text={helper_text.rounds}
                                name="rounds"
                                label="rounds"
                                value={data.rounds}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>
                    </Hide>


                    <Hide hide={!benchmark_data.weight}>
                        <Grid item xs={12}>
                            <CustomTextField
                                required
                                error={helper_text.weight !== undefined}
                                helper_text={helper_text.weight}
                                name="weight"
                                label="weight"
                                value={data.weight}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>
                    </Hide>


                    <Hide hide={!benchmark_data.time}>
                        <Grid item xs={6}>
                            <CustomTextField
                                required
                                error={helper_text.minutes !== undefined}
                                helper_text={helper_text.minutes}
                                name="minutes"
                                label="minutes"
                                value={data.minutes}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <CustomTextField
                                timeMask
                                required
                                error={helper_text.seconds !== undefined}
                                helper_text={helper_text.seconds}
                                name="seconds"
                                label="seconds"
                                value={data.seconds}
                                onChangeHandle={handleFieldChange}
                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}

                            />
                        </Grid>
                    </Hide>


                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                    />

                </Hide>
            </Grid>

        </CustomModal>
    );
};
