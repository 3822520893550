import React, {useContext, useEffect, useState} from "react";
import Permissions from "../../../auth/permissions";
import {RetrieveStaffUser} from "./staff_apis";
import Grid from "@material-ui/core/Grid";
import {Hide} from "../../../components/Hide";
import {CustomCheckBox} from "../../../components/CustomCheckBox";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {Loading} from "../../../components/Loading";
import {CreateUser, UpdateUser} from "../../../auth/System-apis";
import {not_validate_value, sub_directory} from "../../../App";
import {StaffAccessPermissions} from "./components/StaffAccessPermissions";
import {permissions_data} from "../../../auth/permission_data";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import {getLabel} from "../../../localization/MainLocalization";
import {ErrorBox} from "../../../components/ErrorBox";
import {AlertContext} from "../../../store/use_context";


export const StaffAccount = props => {
        const {staff_id} = props;
        const {setAlert} = useContext(AlertContext)

        const [have_permission, setHavePermission] = useState(false);

    const [user, setUser] = useState({})
    const [change_password, setChangePassword] = useState(false)
    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(true)


    const reloadData = async () => {
        await setIsLoading(true);
        let staffUserResponse = await RetrieveStaffUser(staff_id);
        if (staffUserResponse.status) {
            if (!not_validate_value.includes(staffUserResponse.data.id)) {
                await setUser(staffUserResponse.data);
                 setHavePermission(Permissions.checkPermission(permissions_data.user.change));
                await setChangePassword(false);
            }else{
                setHavePermission(Permissions.checkPermission(permissions_data.user.add));
            }
        }

        await setIsLoading(false);
    }

useEffect(()=>{
    reloadData();
}, [])

    const handleUserFieldChange = (name, newValue) => {
        if (have_permission){
        if (name === 'change_password') {
            setChangePassword(newValue)
        } else {
            setUser({...user, [name]: newValue})
        }
        }
    };


    const handleSaveButton = async () => {
        await setIsLoading(true);
        let response;
        if (user.id === undefined) {
            let data = {staff: staff_id, user: user};
            response = await CreateUser(data);
        } else {
            response = await UpdateUser(user.id, user);
        }

        await setHelperText({});
        if (response.status) {
            if (user.id === undefined) {
                await setAlert("Create user successfully")
            }else{
                await setAlert("Update user successfully")
            }
            await reloadData();
        } else {
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };

    const handleBackButton = () => {
        props.history.push(`${sub_directory}staff`)
    }

        return (
            <>

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12}>
                        <CustomTextField
                            required
                            viewOnly={!have_permission}
                            name="email"
                            label="email"
                            value={user.email}
                            onChangeHandle={handleUserFieldChange}
                            helper_text={helper_text.email}
                            error={helper_text.email !== undefined}
                        />
                    </Grid>


                    <Hide hide={!(change_password || user.id === undefined)}>
                        <Grid item xs={12}>
                            <CustomTextField
                                required
                                viewOnly={!have_permission}
                                name="password"
                                label="password"
                                onChangeHandle={handleUserFieldChange}
                                value={user.password}
                                helper_text={helper_text.password}
                                error={helper_text.password !== undefined}
                            />
                        </Grid>
                    </Hide>

                    <Hide hide={user.id === undefined || !have_permission}>
                        <Grid item xs={12}>
                            <CustomCheckBox
                                viewOnly={!have_permission}
                                value={change_password}
                                name='change_password'
                                onChangeHandle={handleUserFieldChange}
                                label="change_password"
                            />
                        </Grid>
                    </Hide>

                    <Grid item xs={12}>
                        <CustomCheckBox
                            viewOnly={!have_permission}
                            value={user.is_active}
                            name='is_active'
                            onChangeHandle={handleUserFieldChange}
                            label="is_active"
                        />
                    </Grid>

                    <Hide hide={!have_permission}>
                        <ErrorBox errorMsg={helper_text.non_field_errors}/>

                        <Button
                            onClick={handleSaveButton}
                            variant="contained"
                            color='primary'
                            size="small"
                            className="mb-3 ml-auto"
                            component={Grid}
                            item
                            xs={12}
                            md={2}
                            startIcon={<SaveIcon/>}
                        >
                            {user.id === undefined?getLabel("create"):getLabel("save")}
                        </Button>
                    </Hide>
                </Hide>


            </>
        );

}
