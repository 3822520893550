import React, {useRef, useState} from 'react';
import Permissions from "../../../auth/permissions";
import VisibilityIcon from '@material-ui/icons/Visibility';
import MaterialTable from "material-table";
import {instance} from "../../../auth/axios_instance";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {sub_directory} from "../../../App";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";
import {BaseFinanceExpenseURL} from "../../../urls";
import Chip from "@material-ui/core/Chip";
import {permissions_data} from "../../../auth/permission_data";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export const Expenses = props => {

        const tableRef = useRef();

            const [columns, setColumns] = useState([
                {title: getLabel("invoice_id"), render: rowData=>(
                        <Typography>
                            <Link href="" onClick={e => {
                                e.preventDefault();
                                viewExpense(rowData.id, rowData.invoice_id)
                            }}>
                                {rowData.invoice_id}
                            </Link>
                        </Typography>
                    ), cellStyle: {width: "25%"}},
                {title: getLabel("title"), field: 'title'},
                {title: getLabel("cost"), field: 'cost'},
                {title: getLabel("paid"), field: 'paid'},
                {title: getLabel("date"), field: 'invoice_date'},
                {title: getLabel('status'),
                    render:rowData=>{return(<Chip color={!rowData.is_closed?"primary":"default"} label={rowData.status}/>)},
                },
            ]);
    const [search, setSearch] = useState("")

    const viewExpense = (id, tag) => {
        props.history.push({
            pathname: sub_directory +'expenses/edit',
            state: {
                id: id,
                tag: tag
            }
        })
    };

    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length > 4|| newValue === '') {
            reloadData();
        }
    };


        return (
            <>
                <Paper>
                    <TableToolbar
                        searchFieldName='search'
                        searchValue={search}
                        onSearchChangeHandle={handleSearchField}
                        searchAction={reloadData}
                        match={props.match}
                        actions={
                            [
                                {
                                    hide: !Permissions.checkAllPermissions([permissions_data.expense.add]),
                                    onClick: () => props.history.push(
                                        {
                                            pathname: sub_directory + 'expenses/new',
                                            state: {id: "new"}
                                        }
                                    ),
                                    label: "add_expense"
                                },
                            ]
                        }
                    />

                    <MaterialTable
                        tableRef={tableRef}
                        columns={columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let url = BaseFinanceExpenseURL;

                                url += '?page=' + (query.page + 1);

                                if (search !== '') {
                                    url += '&search=' + search;
                                }

                                instance.get(url)
                                    .then(result => {
                                        resolve({
                                            data: result.data.results,
                                            page: result.data.page - 1,
                                            totalCount: result.data.total,
                                        })
                                    })
                            })
                        }


                        {...material_table_style}
                        {...material_table_options()}
                        {...material_table_localization()}

                    />

                </Paper>
            </>
        );
}
