import React, {useContext, useEffect, useState} from "react";
import {Hide} from "../../../components/Hide";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import {getLabel} from "../../../localization/MainLocalization";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Permissions from "../../../auth/permissions";
import {sub_directory} from "../../../App";
import {material_table_localization} from "../../../components/Material_conf";
import {permissions_data} from "../../../auth/permission_data";
import {ActionButtons} from "../../../components/ActionButton";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseListLocationsFreezeApplyURL, BaseListLocationsFreezeURL} from "../../../urls";
import {LocationFreezeModal} from "./components/LocationFreezeModal";
import Chip from "@material-ui/core/Chip";
import {ViewClassSessionsToDelete} from "./components/ViewClassSessionToDelete";
import {AlertContext} from "../../../store/use_context";
import {ViewMembershipsForFreezeApply} from "./components/ViewMembershipsForFreezeApply";
import {ConfirmModalForApply} from "./components/ConfirmModalForApply";
import {ViewMembershipsChangedFreeze} from "./components/ViewMembershipsChangedFreeze";


export const LocationFreezes = props => {

    const {location_id} = props;
    const [open_class_sessions_modal_details, setOpenClassSessionsModalDetails] = useState(false)
    const [open_memberships_freezes_modal_details, setOpenMembershipsFreezesModalDetails] = useState(false)
    const [open_memberships_changed_freezes_modal_details, setOpenMembershipsChangedFreezesModalDetails] = useState({
        open: false,
        details: []
    })
    const [open_confirm_apply_modal, setOpenConfirmApplyModal] = useState(false)
    const [data_model_details, setDataModelDetails] = useState({})
    const {setAlert} = useContext(AlertContext);

    const handleApplyBtn = async (id) => {
        await setDataModelDetails({
            freeze_id: id,
            details: []
        });
        await setOpenConfirmApplyModal(true);
    }

    const handleApplyAction = async (id, activate_attended_membership_confirm=null) => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(BaseListLocationsFreezeApplyURL(id), "PATCH", {activate_attended_membership_confirm})
        if (!response.status) {
            if (response.error_feedback.classes_error === true) {
                await setDataModelDetails({
                    freeze_id: id,
                    details: response.error_feedback.details
                });
                await setOpenClassSessionsModalDetails(true);
            } else if (response.error_feedback.attendance_error) {
                await setDataModelDetails({
                    msg: response.error_feedback.msg,
                    details: response.error_feedback.details,
                    attendance_error: true,
                    action_label: "activate_memberships",
                    action: ()=>{
                        handleApplyAction(id, true);
                        handleDataModelClose();
                    }
                })
                await setOpenMembershipsFreezesModalDetails(true);
            } else {
                await setAlert(response.error_feedback.non_field_errors)
            }
        } else {
            await setAlert("Freeze apply successfully")
            await listFreezesData();
        }

        await setIsLoading(false);
    }

    const handleMembershipsFreezeModelOpen = async (data, action=null) => {
        await setDataModelDetails({
            msg: data.msg,
            details: data.details,
            action: action
        })
        await setOpenMembershipsFreezesModalDetails(true);
    }

    const handleDataModelClose = async () => {
        await setOpenMembershipsFreezesModalDetails(false);
        await setOpenClassSessionsModalDetails(false);
        await setDataModelDetails({details: []});
        await setOpenMembershipsChangedFreezesModalDetails({open: false, details: []});
    }

    const handleOpenMembershipChangedFreezeModal = data => {
        setOpenMembershipsChangedFreezesModalDetails({
            open: true,
            details: data
        })
    }

    const [columns, setColumns] = useState([

        {title: getLabel("start_date"), field: 'start_date_view'},
        {title: getLabel("end_date"), field: 'end_date_view'},
        {title: getLabel("duration"), field: 'duration'},
        {
            title: getLabel('memberships_freezed_number'),
            render: rowData => {
                return (
                    <a href={`${sub_directory}memberships/?freeze=${rowData.id}`}
                       target="_blank">{`${rowData.memberships_freezed_number} ${getLabel('membership(s)')}`}</a>
                )
            },
        },
        {
            title: getLabel('shifted_memberships'),
            render: rowData => {
                return (
                    <a href={`${sub_directory}memberships/?shifted_by_freeze=${rowData.id}`}
                       target="_blank">{`${rowData.memberships_shifted_number} ${getLabel('membership(s)')}`}</a>
                )
            },
        }, {
            title: getLabel('membership_changed_freeze'),
            render: rowData => {
                return (
                    <Hide hide={!rowData.is_applied}>
                        <Button color="primary" size="small"
                                onClick={() => handleOpenMembershipChangedFreezeModal(rowData.memberships_change_end_date)}>
                            {`${rowData.memberships_change_end_date.length} ${getLabel('membership(s)')}`}
                        </Button>
                    </Hide>
                )
            },
        },
        {
            title: getLabel('is_editable'),
            render: rowData => {
                return (
                    <Chip color={rowData.is_editable ? "primary" : "default"}
                          label={rowData.is_editable ? 'yes' : 'no'}/>)
            },
        },

        {title: getLabel("reason"), field: 'reason'},
        {title: getLabel("date_created"), field: 'date_created'},
        {title: getLabel("date_modified"), field: 'date_modified'},
        {
            render: rowData => {
                return (
                    <>
                        <Hide hide={rowData.is_applied}>
                            <div className="d-inline-flex">
                                <Button className="mr-1 ml-1" style={{width: "125px"}} size="small"
                                        variant="outlined"
                                        onClick={() => handleApplyBtn(rowData.id)}>
                                    {getLabel("apply")}
                                </Button>
                            </div>
                        </Hide>

                        <Hide hide={!rowData.is_applied}>
                            <label>{getLabel("applied")}</label>
                        </Hide>

                        {/*<Hide hide={rowData.can_apply}>*/}
                        {/*    <label>{getLabel("cant_apply_before_end_date")}</label>*/}
                        {/*</Hide>*/}
                    </>
                )
            }
        }
    ]);

    const [freezes, setFreezes] = useState([]);
    const [freeze_form_open, setFreezeFormOpen] = useState(false);
    const [freeze_data, setFreezeData] = useState({});
    const [is_loading, setIsLoading] = useState({});

    const listFreezesData = async () => {
        await setIsLoading(true);

        let response = await httpRequestWithAuth(BaseListLocationsFreezeURL, "GET", null, {location: location_id});
        if (response.status) {
            await setFreezes(response.data)
        }
        await setIsLoading(false);
    };

    useEffect(() => {
        listFreezesData();
    }, [])


    const handleFreezeModalOpen = async (data = undefined) => {
        setFreezeFormOpen(true)
        setFreezeData(data || {})
    };


    const handleModalClose = async () => {
        await listFreezesData();
        setFreezeFormOpen(false)
        setFreezeData({})
    };


    const handleBackButton = async () => {
        props.history.push(sub_directory + 'locations');
    };

    return (
        <>
            <Hide hide={!open_class_sessions_modal_details}>
                <ViewClassSessionsToDelete
                    open={open_class_sessions_modal_details}
                    data={data_model_details.details}
                    freeze_id={data_model_details.freeze_id}
                    handleModalClose={handleDataModelClose}
                    handleApply={handleApplyAction}
                />
            </Hide>

            <Hide hide={!open_memberships_freezes_modal_details}>
                <ViewMembershipsForFreezeApply
                    attendance_error={data_model_details.attendance_error}
                    msg={data_model_details.msg}
                    open={open_memberships_freezes_modal_details}
                    data={data_model_details.details}
                    action={data_model_details.action}
                    actionLabel={data_model_details.action_label}
                    handleModalClose={handleDataModelClose}
                />
            </Hide>

            <Hide hide={!open_memberships_changed_freezes_modal_details.open}>
                <ViewMembershipsChangedFreeze
                    open={open_memberships_changed_freezes_modal_details.open}
                    data={open_memberships_changed_freezes_modal_details.details}
                    handleModalClose={handleDataModelClose}
                />
            </Hide>

            <Hide hide={!open_confirm_apply_modal}>
                <ConfirmModalForApply
                    open={open_confirm_apply_modal}
                    handleModalClose={() => setOpenConfirmApplyModal(false)}
                    handleApply={handleApplyAction}
                    freeze_id={data_model_details.freeze_id}
                />
            </Hide>

            <Hide hide={!freeze_form_open}>
                <LocationFreezeModal
                    location={location_id}
                    formOpen={freeze_form_open}
                    value={freeze_data}
                    handleModalClose={handleModalClose}
                    handleMembershipsFreezeModelOpen={handleMembershipsFreezeModelOpen}
                />
            </Hide>

            <Grid item xs={12}>
                <MaterialTable
                    style={{padding: '0.5rem', boxShadow: "none"}}
                    columns={columns}
                    data={freezes}
                    isLoading={is_loading}
                    editable={
                        Permissions.checkPermission(permissions_data.location_freeze.delete) && {
                            // isDeletable: Freeze_data => !Freeze_data.is_over,
                            onRowDelete: async Freeze_data => {
                                let response = await httpRequestWithAuth(`${BaseListLocationsFreezeURL}${Freeze_data.id}/`, "DELETE");
                                if (response.status) {
                                    await listFreezesData();
                                } else {
                                    if (response.error_feedback.memberships_freeze_error) {
                                        await handleMembershipsFreezeModelOpen(response.error_feedback)
                                    } else {
                                        await setAlert(response.error_feedback.non_field_errors);
                                    }
                                }
                            }
                        }
                    }

                    actions={[
                        {
                            icon: () => (
                                <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                        startIcon={<AddIcon/>}>
                                    <Typography variant="body1">{getLabel('add_freeze')}</Typography>
                                </Button>
                            ),
                            hidden: !Permissions.checkAllPermissions([permissions_data.location_freeze.add]),
                            isFreeAction: true,
                            onClick: () => handleFreezeModalOpen()
                        },
                        (rowData) => {
                            return (
                                {
                                    icon: 'edit',
                                    hidden: !Permissions.checkAllPermissions([permissions_data.location_freeze.change]),
                                    tooltip: getLabel("edit_freeze"),
                                    onClick: () => handleFreezeModalOpen(rowData)
                                }
                            )
                        },

                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        showTitle: false,
                    }}

                    {...material_table_localization()}
                />
            </Grid>

            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />

        </>
    );
}
