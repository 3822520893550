import React, {useEffect, useState} from "react";
import {getLabel} from "../../../../localization/MainLocalization";
import {DeleteStaffPermission, ListStaffPermission} from "../staff_apis";
import {StaffPermissionModal} from "./StaffPermissionModal";
import {Hide} from "../../../../components/Hide";
import Permissions from "../../../../auth/permissions";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import {material_table_localization} from "../../../../components/Material_conf";
import {permissions_data} from "../../../../auth/permission_data";

export const StaffAccessPermissions = props =>{
        const { staff_id } = props;

           const columns = [
                {title: getLabel("permission_group"), field: "permission_group_name"},
                {title: getLabel("location"), field: "location_name"},
            ];

    const [permission_data, setPermissionData] = useState([])
    const [form_open, setFormOpen] = useState(false)
    const [form_data, setformData] = useState([])
    const [is_loading, setIsLoading] = useState(true)

    const reloadData = async () => {
        await setIsLoading(true)

        let permissionDataResponse = await ListStaffPermission(staff_id);
        if (permissionDataResponse.status) {
            setPermissionData(permissionDataResponse.data)
        }

        await setIsLoading(false)
    }
    useEffect(()=>{
        reloadData();
    },[])

    const handleFormOpen = data => {
        setformData(data)
        setFormOpen(true)
        setIsLoading(true)
    };

    const handleFormClose = async () => {
        await reloadData()
        await setformData({})
        await setIsLoading(false)
        await setFormOpen(false)
    };

    return(
        <>
        <Hide hide={!form_open}>
            <StaffPermissionModal
                open={form_open}
                handleModalClose={handleFormClose}
                value={form_data}
                staff_id={staff_id}
            />
        </Hide>

    <Hide
        hide={!Permissions.checkAllPermissions([permissions_data.staff_permission.view])}>
        <Grid item xs={12}>
            <MaterialTable
                columns={columns}
                isLoading={is_loading}
                data={permission_data}
                style={{boxShadow: "none"}}
                onRowClick={(e, value) => console.log(value)}
                editable={Permissions.checkPermission(permissions_data.staff_permission.delete) && {
                        onRowDelete: async rowData => {
                            await DeleteStaffPermission(rowData.id);
                            reloadData();
                        }
                    }}
                actions={[
                      {
                        icon: 'edit',
                        hidden: !Permissions.checkAllPermissions([permissions_data.staff_permission.change,]),
                        tooltip: getLabel("pages.staff.staff_info.edit_staff_permission"),
                        onClick: (event, rowData) => handleFormOpen(rowData)
                    },

                     {
                        icon: () => {
                            return (<Button size="small" variant="contained" className='mt-2'
                                            color='primary'
                                            startIcon={<AddIcon/>}
                            >
                                <Typography
                                    variant="body1">{getLabel('pages.staff.staff_info.add_staff_permission')}</Typography>
                            </Button>);
                        },
                         hidden: !Permissions.checkAllPermissions([permissions_data.staff_permission.add,]),
                        isFreeAction: true,
                         onClick: () => handleFormOpen()
                    }
                ]}

                options={{
                    paging: false,
                    actionsColumnIndex: -1,
                    showTitle: false,
                    search: false
                }}

                {...material_table_localization()}
            />

        </Grid>
    </Hide>
            </>

);

}