import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import Button from "@material-ui/core/Button";
import {compareDate, not_validate_value, sub_directory} from "../../../App";
import {getLabel} from "../../../localization/MainLocalization";
import MaterialTable, {MTableToolbar} from "material-table";
import {BaseClassesListByDateURL} from "../../../urls";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {DateField} from "../../../components/fields/DateField";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import moment from "moment";
import queryString from "query-string";
import {Hide} from "../../../components/Hide";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {ViewClassAttendance} from "./components/ViewClassAttendance";
import {TableToolbar} from "../../../components/TableToolbar";

export const Classes = props => {
    const [url_params] = useState(queryString.parse(props.location.search));

    useEffect(()=>{
        if (!not_validate_value.includes(url_params['class-session'])) {
            props.history.push({
                pathname: sub_directory + 'classes/edit',
                state: {
                    id: url_params['class-session'],
                    tag: url_params.tag,
                }
            })
        }

        if (!not_validate_value.includes(url_params['attend-for-class-session'])) {
            props.history.push({
                pathname: sub_directory + 'classes/edit',
                state: {
                    id: url_params['attend-for-class-session'],
                    tag: url_params.tag,
                    attendanceTab: true
                }
            })
        }
    },[])


    const [group, setGroup] = useState(false);
    const [search, setSearch] = useState("");
    const [dates, setDate] = useState({
        from_date: moment(),
        to_date: moment().add(7, 'days'),
    });

    const [original_data, setOriginalData] = useState([]);
    const [data, setData] = useState([]);
    const [is_loading, setIsLoading] = useState(false);
    const [view_class_attendance, setViewClassAttendance] = useState({open: false});

    const [columns, setColumns] = useState([
        {
            title: getLabel("name"), render: rowData => (
                <Typography>
                    <Link href="" onClick={e => {
                        e.preventDefault();
                        viewClass(rowData.id, rowData.name)
                    }}>
                        {rowData.name}
                    </Link>
                </Typography>
            ), cellStyle: {width: "35%"}
        },
        {title: getLabel("service"), field: 'service_name'},
        {title: getLabel("location"), field: 'location_name', defaultGroupOrder: '2',},
        {title: getLabel("start_time"), field: 'start_time_view'},
        {title: getLabel("staff"), field: 'staff_name'},
        {
            title: getLabel('registered_number'), render: rowData => {
                let can_view = Permissions.checkPermission(permissions_data.class_attendance.view)
                return (
                    <div className="d-inline-flex">
                        <Hide hide={!can_view}>
                            <Button color="primary" size="small"
                                    onClick={() => setViewClassAttendance({open: true, class_id: rowData.id, for_clients: rowData.for_clients, for_athletes: rowData.for_athletes})}>
                                {`${rowData.current_registered_number} of ${rowData.total_capacity}`}
                            </Button>
                        </Hide>

                        <Hide hide={can_view}>
                            <label className="mt-1 mb-1 mr-2 ml-2">
                            {`${rowData.current_registered_number} of ${rowData.total_capacity}`}
                            </label>
                        </Hide>

                    </div>
                )
            }
        },
        {
            title: getLabel("date"),
            field: 'date_view',
            defaultGroupOrder: '1',
            type: 'date',
            customSort: (a, b)=> compareDate(a.date_view, b.date_view)
        },
    ]);

    const viewClass = (id, tag) => {
        props.history.push({
            pathname: sub_directory + 'classes/edit',
            state: {
                id: id,
                tag: tag
            }
        })
    };

    const listClasses = async () => {
        await setIsLoading(true);
        let temp_dates = {
            from_date: moment(dates.from_date).format("YYYY-MM-DD"),
            to_date: moment(dates.to_date).format("YYYY-MM-DD"),
        }

        let response = await httpRequestWithAuth(BaseClassesListByDateURL, "GET", null, temp_dates);
        if (response.status) {
            await setData(searchInData(search, response.data));
            await setOriginalData(response.data);
        }
        await setIsLoading(false);
    }


    useEffect(() => {
        listClasses();
    }, [])

    const onFilterClicked = async () => {
        await listClasses();
    }


    const handleDateChange = (name, newValue) => {
        if (name === 'from_date' && moment(newValue).isAfter(moment(dates.to_date))) {
            setDate({...dates, [name]: newValue, 'to_date': newValue})
        } else if (name === 'to_date' && moment(newValue).isBefore(moment(dates.from_date))) {
            setDate({...dates, [name]: newValue, 'from_date': newValue})
        } else {
            setDate({...dates, [name]: newValue})
        }
    }


    let filter_fields = (
        <Toolbar variant="regular" className="p-3">
            <Grid container spacing={3}>


                <Grid item xs={12} md={4}>
                    <DateField
                        label="from_date"
                        name="from_date"
                        // inputVariant="standard"
                        value={dates.from_date || null}
                        onChangeHandle={handleDateChange}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DateField
                        label="to_date"
                        name="to_date"
                        min_date={dates.from_date}
                        // inputVariant="standard"
                        value={dates.to_date || null}
                        onChangeHandle={handleDateChange}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Button
                        variant="outlined"
                        color='primary'
                        size="large"
                        fullWidth
                        style={{height: '99%'}}
                        onClick={onFilterClicked}
                    >
                        {getLabel("filter")}
                    </Button>
                </Grid>

            </Grid>

        </Toolbar>
    );

    const handleViewAttendanceModelClose = () => {
        listClasses();
        setViewClassAttendance({open: false})
    }

    const handleSearchField = (name, newValue) => {
        setSearch(newValue);
        if (newValue.length === 0){
            setData(original_data);
        }
        // setData(searchInData(newValue, original_data));
    };

    const searchInData = (value, current_data) => {
        if (value.length === 0){
            return current_data;
        }

        value = value.toLocaleLowerCase();
        return current_data.filter(Session =>
                Session.name.toLocaleLowerCase().includes(value) ||
                Session.location_name.toLocaleLowerCase().includes(value) ||
                Session.start_time_view.toLocaleLowerCase().includes(value) ||
                Session.staff_name.toLocaleLowerCase().includes(value) ||
                Session.service_name.toLocaleLowerCase().includes(value)
        );
    };


    const handleSearchAction = () => {
        setData(searchInData(search, original_data));
    }

    return (
        <Paper>
            <Hide hide={!view_class_attendance.open}>
                <ViewClassAttendance
                    open={view_class_attendance.open}
                    class_id={view_class_attendance.class_id}
                    for_clients={view_class_attendance.for_clients}
                    for_athletes={view_class_attendance.for_athletes}
                    handleModalClose={handleViewAttendanceModelClose}
                />
            </Hide>

            <TableToolbar
                searchFieldName='search'
                searchValue={search}
                onSearchChangeHandle={handleSearchField}
                searchAction={handleSearchAction}
                match={props.match}
                actions={
                    [
                        {
                            hide: !Permissions.checkPermission(permissions_data.classes.change),
                            onClick: () => props.history.push(
                                {
                                    pathname: `${sub_directory}classes/edit-classes`,
                                }),
                            label: "edit_classes"
                        },
                        {
                            hide: !Permissions.checkPermission(permissions_data.classes.add),
                            onClick: () => props.history.push(
                                {
                                    pathname: `${sub_directory}classes/new`,
                                    state: {id: "new"}
                                }),
                            label: "add_class"
                        },
                    ]
                }
            />

            {filter_fields}

            <MaterialTable
                columns={columns}
                data={data}
                isLoading={is_loading}

                onGroupRemoved={(groupedColumn) => {
                    if (groupedColumn.title === "Date") {
                        setGroup(false)
                    }
                }}

                {...material_table_style}
                {...material_table_options({
                    grouping: true,
                    groupTitle: false,
                })}
                {...material_table_localization()}

            />

        </Paper>
    )
}
