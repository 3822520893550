import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {not_validate_value} from "../App";
import {getLabel} from "../localization/MainLocalization";
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import {ErrorBox} from "./ErrorBox";

export const ActionButtons = props => {
    return (
        <Grid item xs={12}>
        <Grid container className='mt-3 pt-1 d-inline-flex'>
            <ErrorBox errorMsg={props.errorMsg}/>

            {props.allowDelete && (
                <Button
                    onClick={props.deleteAction}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className="m-1"
                    component={Grid}
                    item
                    xs={12}
                    md={2}
                    startIcon={<DeleteIcon/>}
                >
                    {getLabel("delete")}
                </Button>
            )}

            <div className='mr-auto'/>

            {props.allowCancel && (
                <Button
                    onClick={props.cancelAction}
                    variant="contained"
                    color='default'
                    size="small"
                    className='text-danger m-1'
                    component={Grid}
                    item
                    xs={12}
                    md={4}
                    startIcon={<CancelIcon/>}
                >
                    {getLabel(props.cancelLabel)}
                </Button>
            )}

            {props.allowActivate && (
                <Button
                    onClick={props.activateAction}
                    variant="contained"
                    color='default'
                    size="small"
                    className='text-danger m-1'
                    component={Grid}
                    item
                    xs={12}
                    md={4}
                    startIcon={<CheckCircleIcon />}
                >
                    {getLabel(props.activateLabel)}
                </Button>
            )}

            {props.allowBack && (
                <Button
                    onClick={props.backAction}
                    variant="contained"
                    color='default'
                    size="small"
                    className="m-1"
                    component={Grid}
                    item
                    xs={12}
                    md={3}
                >
                    {getLabel("back")}
                </Button>
            )}


            {props.allowExtraSave && (
                <Button
                    onClick={props.extraSaveAction}
                    variant="contained"
                    color='primary'
                    size="small"
                    className="m-1"
                    component={Grid}
                    item
                    xs={12}
                    md={3}
                    startIcon={<SaveIcon/>}
                >
                    {getLabel(props.extraSaveLabel)}
                </Button>
            )}

            {props.allowSave && (
                <Button
                    onClick={props.saveAction}
                    variant="contained"
                    color='primary'
                    size="small"
                    className="m-1"
                    component={Grid}
                    item
                    xs={12}
                    md={2}
                    startIcon={<SaveIcon/>}
                >
                    {getLabel("save")}
                </Button>
            )}
        </Grid>
        </Grid>
    );
};
