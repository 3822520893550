import React, {useRef, useState} from 'react';
import MaterialTable from "material-table";
import {instance} from "../../../auth/axios_instance";
import {BaseFinanceCashLogURL} from "../../../urls";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";

export const CashLog = props => {

            const tableRef = useRef();
            const [search, setSearch] = useState("")
            const columns = [
                {title: getLabel('staff'), field:'staff_name'},
                {title: getLabel('via'), field:'via_details'},
                {title: getLabel('amount'), field:'amount'},
                {title: getLabel('action'), field:'action'},
                {title: getLabel('staff_balance'), field:'staff_balance'},
                {title: getLabel('transfer_number'), field:'transfer_number'},
                {title: getLabel('date'), field:'date_created'},
                {title: getLabel('notes'), field:'notes'},
            ];




    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue);

        if (newValue.length >= 5 || newValue === '') {
            reloadData();
        }
    };


        return (
            <>
                <Paper>
                    <TableToolbar
                        searchFieldName='search'
                        searchValue={search}
                        onSearchChangeHandle={handleSearchField}
                        searchAction={reloadData}
                        match={props.match}
                    />

                    <MaterialTable
                        tableRef={tableRef}
                        columns={columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let url = BaseFinanceCashLogURL;

                                url += '?page=' + (query.page + 1);

                                if (search !== '') {
                                    url += '&search=' + search;
                                }

                                instance.get(url)
                                    .then(result => {
                                        resolve({
                                            data: result.data.results,
                                            page: result.data.page - 1,
                                            totalCount: result.data.total,
                                        })
                                    })
                            })
                        }

                        {...material_table_style}
                        {...material_table_options()}
                        {...material_table_localization()}

                    />

                </Paper>
            </>
        );
    }
