import React, {useContext, useRef, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {permissions_data} from "../../../auth/permission_data";
import {ActionButtons} from "../../../components/ActionButton";
import {sub_directory} from "../../../App";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {AlertContext} from "../../../store/use_context";
import {BaseAthleteBenchmarkURL} from "../../../urls";
import {instance} from "../../../auth/axios_instance";
import {material_table_options, material_table_style} from "../../../components/Material_conf";
import {TableToolbar} from "../../../components/TableToolbar";
import {AthleteBenchmarkFormModal} from "./components/AthleteBenchmarkFormModal";
import {Hide} from "../../../components/Hide";


export const AthleteBenchmarks = props => {
    const {athlete_id} = props;
    const {setAlert} = useContext(AlertContext);

    const [material_table_localization_value] = useState(material_table_localization());
    const [material_table_options_value] = useState(material_table_options());

    const columns = [
        {title: getLabel("benchmark"), field: 'benchmark_name'},
        {title: getLabel("date"), field: 'date_view'},
        {title: getLabel("reps"), field: 'reps', render:rowData=>rowData.reps || "---"},
        {title: getLabel("rounds"), field: 'reps', render:rowData=>rowData.rounds || "---"},
        {title: getLabel("weight"), field: 'reps', render:rowData=>rowData.weight || "---"},
        {title: getLabel("time"), render:rowData=> rowData.minutes ? `${rowData.minutes} : ${rowData.seconds}` : "---"},
    ];

    const [form_open, setFormOpen] = useState(false)
    const [form_data, setFormData] = useState({})
    const tableRef = useRef();

    const [search, setSearch] = useState("");


    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
    };


    const handleModalOpen = (data) => {
        setFormData(data)
        setFormOpen(true)
    };

    const handleModalClose = async () => {
        reloadData();
        setFormData({})
        setFormOpen(false)
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length >= 3 || newValue === '') {
            reloadData();
        }

    };


    const handleBackButton = () => {
        props.history.push(sub_directory + "athletes")
    }

    return (
        <>
            <Hide hide={!form_open}>
                <AthleteBenchmarkFormModal
                    open={form_open}
                    handleModalClose={handleModalClose}
                    data={{...form_data, athlete: athlete_id}}
                />
            </Hide>

            <Grid item xs={12}>
                <TableToolbar
                    searchFieldName='search'
                    searchValue={search}
                    onSearchChangeHandle={handleSearchField}
                    searchAction={reloadData}
                    match={props.match}
                    actions={
                        [
                            {
                                hide: !Permissions.checkPermission(permissions_data.athlete_benchmark.add),
                                onClick: () => handleModalOpen({}),
                                label: "add_benchmark"
                            },
                        ]
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let url = BaseAthleteBenchmarkURL;

                            url += '?page=' + (query.page + 1);
                            url += '&athlete=' + athlete_id;

                            if (search !== '') {
                                url += '&search=' + search;
                            }

                            instance.get(url)
                                .then(result => {
                                    resolve({
                                        data: result.data.results,
                                        page: result.data.page - 1,
                                        totalCount: result.data.total,
                                    })
                                })
                        })
                    }

                    editable={Permissions.checkPermission(permissions_data.benchmark.delete) && {
                        onRowDelete: async rowData => {
                            let response = await httpRequestWithAuth(`${BaseAthleteBenchmarkURL}${rowData.id}/`, "DELETE");
                            if (response.status) {
                                 reloadData();
                            } else {
                                setAlert(response.error_feedback.non_field_errors)
                            }
                        }
                    }}


                    actions={[
                        {
                            icon: 'edit',
                            tooltip: getLabel('edit_benchmark'),
                            hidden: !Permissions.checkPermission(permissions_data.benchmark.change),
                            onClick: (event, rowData) => handleModalOpen(rowData, false)
                        }
                    ]}

                    {...material_table_style}
                    {...material_table_options_value}
                    {...material_table_localization_value}

                />
            </Grid>

            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />
        </>
    );

}
