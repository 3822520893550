import React, {useContext} from 'react';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import "../assets/scss/Static.scss";
import {AlertContext} from "../store/use_context";
import {FormHeader} from "./FormHeader";
import Grid from "@material-ui/core/Grid";
import {dir, not_validate_value} from "../App";
import Button from "@material-ui/core/Button";
import {getLabel} from "../localization/MainLocalization";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import {CustomModal} from "./CustomModal"; // web.cjs is required for IE 11 support

export const CustomAlertModal = props => {
    const {alert_msg, setAlert} = useContext(AlertContext);

    const handleClose = () => {
        setAlert("");
    };

    return (
            <CustomModal
                open={!not_validate_value.includes(alert_msg)}
            >
                    <Grid container  spacing={2}>
                        <FormHeader
                            title="alert"
                            handleClose={handleClose}
                        />

                    <Grid item xs={12}>
                        <Typography variant="h6" className='p-1'>
                            {alert_msg}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className='text-end'>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            color='primary'
                            size="medium"
                        >
                            {getLabel("ok")}
                        </Button>
                    </Grid>
                    </Grid>
            </CustomModal>
    );
}
