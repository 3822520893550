import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import "../../../assets/scss/Static.scss";
import {ActionButtons} from "../../../components/ActionButton";
import MaterialTable from "material-table";
import {getLabel} from "../../../localization/MainLocalization";
import {sub_directory} from "../../../App";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import {ListClientMemberships} from "./clients_apis";
import {permissions_data} from "../../../auth/permission_data";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

export const ClientMemberships = props => {
    const {client_id} = props;

    const [columns, setColumns] = useState([
        {title: getLabel("invoice_number"), field: 'invoice_id'},
        {title: getLabel('service'), field: 'service_name'},
        {title: getLabel('location'), field: 'location_name'},
        {title: getLabel('start_date'), field: 'start_date_view'},
        {title: getLabel('end_date'), field: 'end_date_view'},
        {title: getLabel('total_price'), field: 'total_price'},
        {title: getLabel('balance'), field: 'balance'},
        {
            title: getLabel('attended_sessions'),
            render: rowData => {
                return (
                    <a href={`${sub_directory}clients/?client=${rowData.client}&tag=${rowData.short_name}&attend_for_membership=${rowData.id}`}
                       target="_blank">{`${rowData.attended_session_number} ${getLabel('session(s)')}`}</a>
                )
            },
        },
    ]);
    const [data, setData] = useState([]);
    const [is_loading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);

            let response = await ListClientMemberships(client_id);
            if (response.status) {
                setData(response.data);
            }

            await setIsLoading(false);
        }

        fetchData();
    }, [])

    const handleBackButton = () => {
        props.history.push(sub_directory + "clients")
    }


    const handleRowClick = (rowData) => {
        if (Permissions.checkPermission(permissions_data.membership.view)) {
            props.history.push(
                {
                    pathname: sub_directory + 'memberships/edit',
                    state: {
                        id: rowData.id,
                        tag: rowData.short_name
                    }
                }
            )
        }
    }


    return (
        <>
            <Grid item xs={12}>
                <MaterialTable
                    columns={columns}
                    isLoading={is_loading}
                    data={data}
                    style={{boxShadow: "none"}}
                    onRowClick={(e, rowData) => handleRowClick(rowData)}

                    actions={[
                        {
                            icon: () => (
                                <Button
                                    size="small"
                                    className="primary-bg text-white m-1"
                                    startIcon={<AddIcon/>}
                                    onClick={() => {
                                        props.history.push({
                                            pathname: sub_directory + 'memberships/new',
                                            state: {
                                                client_id: client_id,
                                                id: 'new'
                                            }
                                        });
                                    }}
                                >
                                    {getLabel("add_membership")}
                                </Button>
                            ),
                            isFreeAction: true,
                            hidden: !Permissions.checkPermission(permissions_data.membership.add)
                        }
                    ]}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10],
                        actionsColumnIndex: -1,
                        showTitle: false,
                        searchFieldAlignment: 'left'
                    }}

                    {...material_table_localization()}

                />
            </Grid>


            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />

        </>
    );
}

