import React, {useEffect, useRef, useState} from 'react';
import MaterialTable from 'material-table';
import Permissions from "../../../../auth/permissions";
import {TableToolbar} from "../../../../components/TableToolbar";
import Paper from "@material-ui/core/Paper";
import "../../../../assets/scss/Static.scss";
import {sub_directory} from "../../../../App";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../../components/Material_conf";
import {getLabel} from "../../../../localization/MainLocalization";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import {Hide} from "../../../../components/Hide";
import {PriceListFormModal} from "./components/PriceListFormModal";
import {BasePriceListURL, BaseServiceURL, BaseUpdateLocationServiceForPriceListURL} from "../../../../urls";
import {DeletePriceList} from "./price_list_apis";
import {ViewLocations} from "../components/ViewLocations";
import {httpRequestWithAuth, instance} from "../../../../auth/axios_instance";
import {permissions_data} from "../../../../auth/permission_data";


export const PriceLists = props => {
    const state = props.history.location.state || {};

    useEffect(() => {
        if (!(state.service)) {
            props.history.push(`${sub_directory}services`);
        }
    }, []);




        const  tableRef = useRef();

            const [columns, setColumns] = useState([
                {title: getLabel("name"), field: 'name', cellStyle:{width:"30%"}},
                {title: getLabel('assigned_to'), field: 'for_who'},
                {title: getLabel('available_at'), render: rowData=>{return(
                        <div className="d-inline-flex">
                                <Button color="primary" size="small"
                                        onClick={()=> viewLocationsModel(rowData)}>
                                    {rowData.locations.length} Location(s)
                                </Button>
                        </div>
                    )}},
                {title: getLabel("start_date"), field: 'start_date'},
                {title: getLabel("end_date"), field: 'end_date'},
                {title: getLabel('status'),
                    render:rowData=>{return(<Chip color={rowData.is_active?"primary":"default"} label={rowData.is_active_view}/>)},
                },
                {render:rowData=>{return(
                        <div className="d-inline-flex">
                            {Permissions.checkAllPermissions([permissions_data.price.view]) && (
                                <Button className="mr-1 ml-1" size="small" variant="outlined"
                                        onClick={()=> props.history.push(
                                        {
                                            pathname: sub_directory + "services/price-lists/prices",
                                            state: {
                                                ...state,
                                                pricelist: rowData.id,
                                                pricelist_name: rowData.name
                                            }
                                        }
                                        )}>
                                    {getLabel("pages.packages.view_prices")}
                                </Button>)}

                            {Permissions.checkAllPermissions([permissions_data.offer_price_list.view]) && (
                                <Button className="mr-1 ml-1" size="small" variant="outlined"
                                        onClick={()=> props.history.push(
                                        {
                                            pathname: sub_directory + "services/price-lists/offers",
                                            state: {
                                                ...state,
                                                pricelist: rowData.id,
                                                pricelist_name: rowData.name
                                        }
                                        }
                                        )}>
                                    {getLabel("pages.packages.view_offers")}
                                </Button>)}
                        </div>
                    )}},

            ]);

    const [is_rent, setIsRent] = useState(false)
    const [disable_gender, setDisableGender] = useState(false)
    const [is_loading, setIsLoading] = useState(true)
    const [form_open, setFormOpen] = useState(false)
    const [new_price_list, setNewPriceList] = useState({})
    const [locationForm_open, setLocationFormOpen] = useState(false)
    const [locations_data, setLocationsData] = useState([])
    const [helper_text, setHelperText] = useState({})
    const [search, setSearch] = useState("")
    const [request_url, setRequestUrl] = useState("")
    const [show_locations, setShowLocation] = useState([])

    const removeColumn = (index) => {
        var array = [...columns]; // make a separate copy of the array
        if (index !== -1) {
            array.splice(index, 1);
            setColumns(array)
        }
    }

    useEffect(()=>{
        const fetchData = async () =>{
            await setIsLoading(true)

            let response = await httpRequestWithAuth(`${BaseServiceURL}${state.service}`, "GET");
            if (response.status){
                let disable_gender_value = !(response.data.for_males && response.data.for_females)
                await setDisableGender(disable_gender_value);

                if (disable_gender_value){
                    await setNewPriceList({for_males:response.data.for_males, for_females:response.data.for_females});
                }

                await setIsRent(response.data.is_rental_service);

                if (is_rent){
                    removeColumn(1);
                }

            }

            await setIsLoading(false)
        }

        fetchData();
    }, [])


    const reloadData = async () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


   const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length >= 5 || newValue === '') {
            reloadData();
        }
    };


    const viewLocationsModel = async data => {
        await setRequestUrl(BaseUpdateLocationServiceForPriceListURL(data.id))
        await setShowLocation(data.service_locations)
        await setLocationsData(data.locations)
        await setLocationFormOpen(true)
    };


    const handleModalClose = async () => {
        await setHelperText({});
        await reloadData();
        await setLocationsData({})
        await setLocationFormOpen(false)
        await setFormOpen(false)
        await setNewPriceList(disable_gender ? {
            for_males: new_price_list.for_males,
            for_females: new_price_list.for_females
        }: {});
    };


    const handleModalOpen = async (data) => {
        if (data !== 'new') {
            await setNewPriceList({...data})
        }
        await setFormOpen(true)
    };




        return (
            <>
                <Hide hide={!locationForm_open}>
                    <ViewLocations
                        request_url={request_url}
                        show_locations={show_locations}
                        active_locations={locations_data}
                        open={locationForm_open}
                        handleModalClose={handleModalClose}
                    />
                </Hide>

                <Hide hide={!form_open}>
                <PriceListFormModal
                        service={state.service}
                        open={form_open}
                        is_rent={is_rent}
                        handleModalClose={handleModalClose}
                        value={new_price_list}
                        disable_gender={disable_gender}
                    />
                </Hide>

                <Paper>
                    <TableToolbar
                        searchFieldName='search'
                        searchValue={search}
                        onSearchChangeHandle={handleSearchField}
                        searchAction={reloadData}
                        match={props.match}
                        breadcrumDetails={{...state}}
                        backbtn={true}
                        actions={
                            [
                                {
                                    hide: !Permissions.checkAllPermissions([permissions_data.price_list.add]),
                                    onClick: ()=>handleModalOpen('new'),
                                    label:"pages.packages.add_package"
                                },
                            ]
                        }
                    />

            <Hide hide={is_loading}>
                    <MaterialTable
                        tableRef={tableRef}
                        columns={columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let url = BasePriceListURL;
                                url += '?page=' + (query.page + 1);

                                url += '&service=' + state.service;

                                if (search !== '') {
                                    url += '&search=' + search;

                                }

                                instance.get(url)
                                    .then(result => {
                                        resolve({
                                            data: result.data.results,
                                            page: result.data.page - 1,
                                            totalCount: result.data.total,
                                        })
                                    })
                            })
                        }
                        editable={
                            Permissions.checkPermission(permissions_data.price_list.delete) && {
                                onRowDelete: async PriceList => {
                                    await DeletePriceList(PriceList.id);
                                    reloadData();
                                }
                            }}


                        actions={[
                            {
                                icon: 'edit',
                                hidden: !Permissions.checkAllPermissions([permissions_data.price_list.change]),
                                tooltip: getLabel("pages.packages.edit_package"),
                                onClick: (event, rowData) => handleModalOpen(rowData)
                            },
                        ]}

                        {...material_table_style}
                        {...material_table_options()}
                        {...material_table_localization()}
                    />
            </Hide>
                </Paper>
            </>
        );
}

