import React, {useContext, useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {permissions_data} from "../../../auth/permission_data";
import {ActionButtons} from "../../../components/ActionButton";
import {sub_directory} from "../../../App";
import {AlertContext} from "../../../store/use_context";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseFinanceClaimPaymentsURL, BaseFinanceMyClaimPaymentsRequestURL} from "../../../urls";
import {ClaimPaymentModal} from "./components/ClaimPaymentModal";
import {Hide} from "../../../components/Hide";


export const ClaimPayments = props => {
    const {claim_id} = props;
    const {setAlert} = useContext(AlertContext);

    const payments_columns = [
        {title: getLabel("payment_method"), field: 'payment_method_name'},
        {title: getLabel("amount"), field: 'amount'},
        {title: getLabel("settlement_to"), field: 'settlement_to'},
        {title: getLabel("reference"), field: 'reference'},
        {title: getLabel("cash_box"), field: 'cash_box_name'},
        {title: getLabel("payment_date"), field: 'payment_date_view'},
        {title: getLabel("created_by"), field: 'created_by_name'},
    ];

    const [payments, setPayments] = useState([])
    const [payment_form_open, setPaymentFormOpen] = useState(false)
    const [payment_type_form, setPaymentTypeForm] = useState("")
    const [is_loading, setIsLoading] = useState(true)

    const listPaymentsData = async () => {
        await setIsLoading(true)
        let url = props.staff_view ? BaseFinanceClaimPaymentsURL: BaseFinanceMyClaimPaymentsRequestURL;
        let response = await httpRequestWithAuth(url, "GET", null, {claim: claim_id})
        if (response.status) {
            await setPayments(response.data)
        }

        await setIsLoading(false)
    };

    useEffect(() => {
        listPaymentsData()
    }, [])

    const handlePaymentModalOpen = async data => {
        await setPaymentFormOpen(true)
    };

    const handleModalClose = async () => {
        await listPaymentsData();
        await setPaymentFormOpen(false)
    };


    const handleBackButton = () => {
        if (props.staff_view){
            props.history.push(`${sub_directory}claims`)
        }else{
            props.history.push(`${sub_directory}my-claims`)
        }
    }

    return (
        <>
            <Hide hide={!payment_form_open}>
                <ClaimPaymentModal
                    formOpen={payment_form_open}
                    handleModalClose={handleModalClose}
                    claim={claim_id}
                />
            </Hide>

            <Grid item xs={12}>
                <MaterialTable
                    style={{padding: '0.5rem', boxShadow: "none"}}
                    isLoading={is_loading}
                    columns={payments_columns}
                    data={payments}
                    editable={Permissions.checkPermission(permissions_data.claim_payment.delete) && props.staff_view && {
                        onRowDelete: async PaymentData => {
                            let response = await httpRequestWithAuth(`${BaseFinanceClaimPaymentsURL}${PaymentData.id}`, "DELETE");
                            if (response.status) {
                                await listPaymentsData();
                            } else {
                                await setAlert(response.error_feedback.non_field_errors)
                            }
                        }
                    }}

                    actions={[
                        {
                            icon: () => (
                                <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                        startIcon={<AddIcon/>}>
                                    <Typography variant="body1">{getLabel('add_payment')}</Typography>
                                </Button>
                            ),
                            hidden: !Permissions.checkPermission(permissions_data.claim_payment.add) || props.is_full_paid || !props.staff_view,
                            isFreeAction: true,
                            onClick: handlePaymentModalOpen
                        },
                    ]}

                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        showTitle: false
                    }}

                    {...material_table_localization()}
                />
            </Grid>


            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />
        </>
    );

}
