import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../../../components/FormHeader";
import {FormActionButtons} from "../../../../../../components/FormActionButton";
import {CustomModal} from "../../../../../../components/CustomModal";
import {Hide} from "../../../../../../components/Hide";
import {Loading} from "../../../../../../components/Loading";
import {httpRequestWithAuth} from "../../../../../../auth/axios_instance";
import {BaseGroupAthletesURL} from "../../../../../../urls";
import {AthleteAutoComplete} from "../../../../../../components/fields/AthleteAutoComplete";

export const AthleteGroupFormModal = props => {
    const [is_loading, setIsLoading] = useState(false);
    const [helper_text, setHelperText] = useState([])
    const [data, setData] = useState({...props.default_data})


    const handleFieldChange = (name, newValue) => {
        setData({...data, [name]: newValue})
    };

    const handleSaveForm = async () => {
        await setIsLoading(true);
        let temp_data = {...data};


        let response;
        if (data.id === undefined) {
            response = await httpRequestWithAuth(BaseGroupAthletesURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${BaseGroupAthletesURL}${data.id}/`, "PATCH", temp_data);
        }

        if (response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    return (
        <CustomModal
            open={props.open}
        >
            <Grid container spacing={3}>

                <FormHeader
                    title="athlete_form"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12}>
                        <AthleteAutoComplete
                            required
                            error={helper_text.athlete !== undefined}
                            helper_text={helper_text.athlete}
                            name="athlete"
                            label="athlete"
                            value={data.athlete}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>


                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                    />

                </Hide>
            </Grid>

        </CustomModal>
    );
};
