import React, {useEffect, useState} from 'react';
import {Bar, BarChart, Tooltip, XAxis, YAxis,} from 'recharts';
import Typography from "@material-ui/core/Typography";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseGroupAthletesNewAndOldNumberURL} from "../../../../urls";
import {not_validate_value} from "../../../../App";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import Grid from "@material-ui/core/Grid";
import {SeasonAutoComplete} from "../../../../components/fields/SeasonAutoComplete";
import {CategoryAutoCompleteFreeSolo} from "../../../../components/fields/CategoryAutoCompleteFreeSolo";

export const OldAndNewAthlete = props => {
    const [data, setData] = useState({details:[], 'total':0})
    const [is_loading, setIsLoading] = useState(true)
    const [setting, setSetting] = useState( {
        season:"",
        category:"",
    });


    const handleSettingField = (name, value) =>{
        let new_setting = {...setting}

        if (name === "season"){
            new_setting['category'] = ""
        }

        new_setting[name] = value

        setSetting(new_setting)
    }

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            let response = await httpRequestWithAuth(BaseGroupAthletesNewAndOldNumberURL, "GET", null, setting);
            if (response.status) {
                await setData(response.data)
            }
            await setIsLoading(false);
        }

        fetchData();
    }, [setting.season, setting.category])

    return (
        <div className='card-paper-1 h-100 w-100'>
            <Typography variant="h6" className="text-start mb-2">
                Athletes
            </Typography>

            <Grid container spacing={2} className="mb-2">

            <Grid item xs={12} md={6}>
                <SeasonAutoComplete
                    name='season'
                    label="season"
                    onChangeHandle={handleSettingField}
                    value={setting.season}
                />
            </Grid>


            <Grid item xs={12} md={6}>
                <CategoryAutoCompleteFreeSolo
                    disabled={not_validate_value.includes(setting.season)}
                    name='category'
                    label="category"
                    onChangeHandle={handleSettingField}
                    value={setting.category}
                />
            </Grid>
            </Grid>

            <Typography component="p" variant="h5" className="text-end mt-3">
                {`Total: ${data.total}`}
            </Typography>

            <Hide hide={!is_loading}>
                <div className="fixedHeight-400">
                    <Loading/>
                </div>
            </Hide>

            <Hide hide={!is_loading && !not_validate_value.includes(setting.season)}>
                <Typography variant="subtitle1" className="text-center mb-2">
                    Please select Season
                </Typography>
            </Hide>

            <Hide hide={is_loading || not_validate_value.includes(setting.season)}>
            <div dir='ltr' className="h-100 w-100">

                <ResponsiveContainer width="100%" height='90%' className="fixedHeight-400">

                    <BarChart
                        data={data.details}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <XAxis dataKey="type"/>
                        <YAxis/>
                        <Tooltip/>


                        <Bar dataKey="number" barSize={30} fill="#3f51b5"  />

                    </BarChart>
                </ResponsiveContainer>
            </div>
            </Hide>
        </div>
    );
}
