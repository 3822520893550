import React, {Component} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {SelectField} from "../../../components/fields/SelectField";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {Hide} from "../../../components/Hide";
import Grid from "@material-ui/core/Grid";
import {ActionButtons} from "../../../components/ActionButton";
import {Loading} from "../../../components/Loading";
import {DateField} from "../../../components/fields/DateField";
import {CustomTextArea} from "../../../components/fields/CustomTextArea";
import {CreateRentCancellation, DeleteRentCancellation, ListRentCancellation} from "./rents_apis";

let rent_id = -1;

export default class RentCancellation extends Component{
    constructor(props) {
        super(props);

        rent_id = props.rent_id

        this.state ={
            cancel_options:[
                {title: getLabel("cancel_reason_1"), value: "Bad Equipment"},
                {title: getLabel("cancel_reason_2"), value: "The Gym Isn't clean"},
                {title: getLabel("cancel_reason_3"), value: "It's too crowded"},
                {title: getLabel("cancel_reason_4"), value: "Gym management fails to address concerns"},
                {title: getLabel("cancel_reason_5"), value: "healthy issue"},
                {title: getLabel("other"), value: "Other"},
            ],
            helper_text:{},
            cancellation:{},
            is_loading: true,
        }
    }

    LoadRentCancellation = async () =>{
        await this.setState({is_loading:true});
        this.setHelpText();
        let response = await ListRentCancellation(rent_id);
        if (response.status) {
            if (response.data.length !== 0){
            await this.setState(state=>({
                cancellation: {...response.data[0],
                reason_select: state.cancel_options.find(option => option.value === response.data[0].reason)?response.data[0].reason: "Other"
                }
            }));
            }

        }

        await this.setState({is_loading:false});
    }

    async componentDidMount() {
       await this.LoadRentCancellation();
       await this.setState({is_loading:false});
    }

    setHelpText = data => {
        this.setState({
            helper_text: {...data}
        });
    };

    handleFieldChange = (name, newValue) => {
        this.setState(state => ({
            cancellation: {...state.cancellation, [name]: newValue}
        }));

        if (name === "reason_select"){
            this.setState(state => ({
                cancellation: {...state.cancellation, reason: newValue}
            }));
        }
    };


    handleCancelModelSaveButton = async () => {
        let cancellation_data = {
            rent: rent_id,
            ...this.state.cancellation
        };

        let response  = await CreateRentCancellation(cancellation_data);

        if (response.status) {
            await this.LoadRentCancellation();
            this.props.setIsCancelledValue(true);
        } else {
            this.setHelpText(response.error_feedback)
        }
    };


    handleActivateButton = async () => {
        let response = await DeleteRentCancellation(this.state.cancellation.id);

        if (response.status) {
            this.setState(state=>({cancellation:{...state.cancellation, id:undefined}}))
            this.props.setIsCancelledValue(false);
        } else {
            this.setHelpText(response.error_feedback);
        }

    };

    render() {
        return(
            <>
                <Hide hide={!this.state.is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={this.state.is_loading}>
                <Grid item xs={12}>
                    <SelectField
                        viewOnly={this.state.cancellation.id !== undefined}
                        disabled={this.state.cancellation.id !== undefined}
                        name="reason_select"
                        label="cancel.cancel_reason"
                        onChangeHandle={this.handleFieldChange}
                        options={this.state.cancel_options}
                        value={this.state.cancellation.reason_select}
                    />
                </Grid>

                    <Hide hide={this.state.cancellation.reason_select !== 'Other'}>
                    <Grid item xs={12}>
                        <CustomTextField
                            disabled={this.state.cancellation.id !== undefined}
                            viewOnly={this.state.cancellation.id !== undefined}
                            helper_text={this.state.helper_text.reason}
                            error={this.state.helper_text.reason !== undefined}
                            value={this.state.cancellation.reason}
                            name="reason"
                            label="cancel.other_reason"
                            onChangeHandle={this.handleFieldChange}
                        />
                    </Grid>
                    </Hide>

                    <Grid item xs={12}>
                        <DateField
                            required={true}
                            disabled={this.state.cancellation.id !== undefined}
                            viewOnly={this.state.cancellation.id !== undefined}
                            name="cancellation_date"
                            error={this.state.helper_text.cancellation_date !== undefined}
                            helper_text={this.state.helper_text.cancellation_date}
                            label="cancellation_date"
                            value={this.state.cancellation.cancellation_date}
                            onChangeHandle={this.handleFieldChange}
                        />
                    </Grid>

                    <Grid item xs={12} className="mt-3">
                        <CustomTextArea
                            label="notes"
                            disabled={this.state.cancellation.id !== undefined}
                            error={this.state.helper_text.notes !== undefined}
                            helper_text={this.state.helper_text.notes}
                            name="notes"
                            rows={5}
                            value={this.state.cancellation.notes}
                            onChangeHandle={this.handleFieldChange}
                        />
                    </Grid>


                    <ActionButtons
                    errorMsg={this.state.helper_text.non_field_errors}
                    allowCancel={this.state.cancellation.id === undefined}
                    cancelLabel="cancel_rent"
                    cancelAction={this.handleCancelModelSaveButton}
                    allowActivate={this.state.cancellation.id !== undefined}
                    activateLabel="activate_rent"
                    activateAction={this.handleActivateButton}
                />
                </Hide>

                </>
        );
    }

}