import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import '../../assets/scss/Home.scss';
import auth from "../../auth/auth";
import {not_validate_value, redirect, sub_directory} from "../../App";
import {getLabel} from "../../localization/MainLocalization";
import Typography from "@material-ui/core/Typography";
import {permissions} from "../../auth/permissions";
import Cookies from "js-cookie";
import {BaseBackEndURL} from "../../urls";
import {Hide} from "../../components/Hide";
import {Loading} from "../../components/Loading";
// import {BaseBackEndURL} from "../../urls";


export const HomePage = props => {
    const [is_loading, setIsLoading] = useState(true)

    useEffect(() => {
        if (auth.isAuthenticated() && Object.keys(permissions).length !== 0) {
            props.history.push(sub_directory + redirect);
        } else if (!not_validate_value.includes(Cookies.get('sub_directory'))) {
            window.location.href = BaseBackEndURL + Cookies.get('sub_directory') + "/login"
        } else {
            setIsLoading(false);
        }
    }, [])


    return (
        <Grid container component="main" className="root">

            <CssBaseline/>

            <Hide hide={!is_loading}>
                <Loading/>
            </Hide>

            <Hide hide={is_loading}>
                <Grid item xs={false} sm={4} md={6} className="image"/>
                <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
                    <div className="paper">
                        <Avatar className="avatar">
                            <LockOutlinedIcon/>
                        </Avatar>

                        <Typography variant="h5" className="m-5">
                            Welcome to RKRDS Web App
                        </Typography>
                        {/*<Button*/}
                        {/*    fullWidth*/}
                        {/*    variant="contained"*/}
                        {/*    color="primary"*/}
                        {/*    type='submit'*/}
                        {/*    className="submit"*/}
                        {/*    component="a"*/}
                        {/*    href={`${BaseBackEndURL}login/`}*/}
                        {/*>*/}
                        {/*    { getLabel("signin")}*/}
                        {/*</Button>*/}

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            component="a"
                            className="submit"
                            href={`/signup`}
                            // onClick={()=>props.history.push(`${default_url}signup`)}
                        >
                            {getLabel("signup")}
                        </Button>
                    </div>
                </Grid>
            </Hide>
        </Grid>
    );
}
