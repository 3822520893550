import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {getLabel} from "../../../../localization/MainLocalization";
import MaterialTable from "material-table";
import {BaseClientBirthdaySoonURL} from "../../../../urls";
import Button from "@material-ui/core/Button";
import {sub_directory} from "../../../../App";


export const    ClientsUpComingBirthday = props => {
    const [data, setData] = useState( []);
    const [is_loading, setIsLoading] = useState( true);

    const columns =[
            {title: getLabel("name"), field: 'full_name'},
            {title: getLabel("gender"), field: 'gender'},
            {title: getLabel("date"), field: 'birth_date_view'},
        {
            render: rowData => {
                return (
                            <Button size="small"
                                    variant="outlined"
                                    onClick={() => props.history.push({
                                        pathname: sub_directory +'clients/edit',
                                        state: {
                                            id: rowData.id,
                                            tag: rowData.short_name
                                        }
                                    })}>
                                {getLabel("view")}
                            </Button>
                )
            }
        }
        ];

    useEffect(()=>{
        const fetchData = async () =>{
            await setIsLoading(true);
            let response = await httpRequestWithAuth(BaseClientBirthdaySoonURL, "GET");
            if (response.status){
                await setData(response.data)
            }
            await setIsLoading(false);
        }

        fetchData();
    },[])

        return (
            <div className='card-paper-1 h-100 w-100'>
                <Typography variant="h6" className="text-start mb-2">
                    Upcoming Birthdays
                </Typography>

                <MaterialTable
                    columns={columns}
                    data={data}
                    isLoading={is_loading}
                    onRowClick={(e, value) => console.log(value)}
                    style={{
                        boxShadow: "none",
                    }}

                    options={{
                        search: false,
                        toolbar: false,
                        pageSize: 10,
                        pageSizeOptions: [10],
                    }}
                />
            </div>
        );
}
