import React, {useEffect, useState} from "react";
import {SideMenu} from "./side_menu/SideMenu";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import DehazeIcon from '@material-ui/icons/Dehaze';
import Toolbar from "@material-ui/core/Toolbar";
import "../assets/scss/Static.scss";
import "../assets/scss/Layout.scss";
import auth from "../auth/auth";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Routes from "../routes/Routes";
import {LanguageBtn} from "../components/LanguageBtn";
import {CustomDropDown} from "../components/CustomDropDown";
import {CustomAlertModal} from "../components/CustomAlertModal";
import {CustomBreadcrumbs} from "../components/CustomBreadcrumbs";
import {BreadcrumbContext, CashTransferContext, SideMenuToggleContext} from "../store/use_context";
import {not_validate_value, sub_directory} from "../App";
import {Hide} from "../components/Hide";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import {getLabel} from "../localization/MainLocalization";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {ConfirmationModal} from "../components/ConfirmationModal";
import Permissions from "../auth/permissions";
import {permissions_data} from "../auth/permission_data";
import {CashTransferFormModal} from "../pages/finance/cash_transfer/components/CashTransferFormModal";
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {httpRequestWithAuth} from "../auth/axios_instance";
import {BaseFinanceCountPendingCashTransferURL} from "../urls";
import Badge from "@material-ui/core/Badge";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" className='mt-4'>
            {'Copyright © '}
            <Link color="inherit" href="#">
                Rekords Team
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export const Layout = props => {
    const [side_menu_toggle, setSideMenuToggle] = React.useState(false);
    const [breadcrumbProps, setBreadcrumbProps] = React.useState(null);
    const [open_modal, setOpenModal] = useState(false);
    const [blink_flash, setBlinkFlash] = useState(false);
    const [pending_received_cash_transfer, setPendingReceivedCashTransfer] = useState(0);
    const [check_pending_received_cash_transfer, setCheckPendingReceivedCashTransfer] = useState(0);
    const [modal_type, setModalType] = useState("");


    const handleShowModal = async (value, action_type = "") => {
        await setModalType(action_type)
        await setOpenModal(value)
    }

    const handleToggleDrawer = () => {
        setSideMenuToggle(!side_menu_toggle)
    }

    const check_pending_cash_transfer = async () => {
        let response = await httpRequestWithAuth(BaseFinanceCountPendingCashTransferURL, "GET")
        if (response.status) {
            setPendingReceivedCashTransfer(response.data.received)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setBlinkFlash(!blink_flash);
        }, 500)
    }, [blink_flash])

    useEffect(() => {
        const checkInterval = () => {
            setInterval(check_pending_cash_transfer, 90000);
        }

        checkInterval();
    }, []);

    useEffect(() => {
        check_pending_cash_transfer();
    }, [check_pending_received_cash_transfer]);

    const PendingCashTransferBtn = (
        <Button
            style={blink_flash ? {opacity: 0.5, transition: '0.3s'} : {
                opacity: 1,
                transition: '0.3s'
            }}
            color="secondary"
            onClick={() => props.history.push({
                pathname: `${sub_directory}cash-transfer-requests`,
                state: {pending_transfer: true}
            })}
            startIcon={
                <AttachMoneyIcon/>
            }
        >
            <Hidden smUp>
                <Badge badgeContent={pending_received_cash_transfer} color="error"/>
            </Hidden>

            <Hidden xsDown>
                {getLabel("pending_transfer")}
            </Hidden>
        </Button>
    )
    return (
        <>
            <div className="root bg-light">
                {/*<div className="sub-root-right"/>*/}
                {/*<div className="sub-root-left"/>*/}
                <CssBaseline/>

                <CustomAlertModal/>

                <ConfirmationModal/>

                <Hide hide={!open_modal}>
                    <CashTransferFormModal
                        open={open_modal}
                        type={modal_type}
                        handleModalClose={() => handleShowModal(false)}
                    />
                </Hide>

                <SideMenuToggleContext.Provider value={{side_menu_toggle, setSideMenuToggle}}>
                    <SideMenu/>
                </SideMenuToggleContext.Provider>

                <div className="sub-root w-auto">
                    <Toolbar>

                        <Hidden mdUp>
                            <IconButton onClick={handleToggleDrawer}>
                                <DehazeIcon/>
                            </IconButton>
                        </Hidden>

                        {/*<Typography variant='h6' className='text-center mt-3 mb-3'>*/}
                        {/*    Logo or quick actions...*/}
                        {/*</Typography>*/}
                        <div>
                            <Hide hide={!Permissions.checkPermission(permissions_data.client.add)}>
                                <Button
                                    onClick={() => props.history.push({
                                        pathname: `${sub_directory}clients/new`,
                                        state: {id: 'new'}
                                    })}
                                    className={props.className}
                                    startIcon={<PersonAddIcon/>}
                                >
                                    <Hidden xsDown>
                                        {getLabel("add_client")}
                                    </Hidden>
                                </Button>
                            </Hide>

                            <Hide hide={!Permissions.checkPermission(permissions_data.membership.add)}>
                                <Button
                                    onClick={() => props.history.push({
                                        pathname: `${sub_directory}memberships/new`,
                                        state: {id: 'new'}
                                    })}
                                    startIcon={<GroupAddIcon/>}
                                >
                                    <Hidden xsDown>
                                        {getLabel("add_membership")}
                                    </Hidden>
                                </Button>
                            </Hide>

                            <Hide hide={!Permissions.checkPermission(permissions_data.cash_transfer_request.add)}>
                                <Button
                                    onClick={() => handleShowModal(true, "transfer")}
                                    startIcon={<TransferWithinAStationIcon/>}
                                >
                                    <Hidden xsDown>
                                        {getLabel("add_transfer")}
                                    </Hidden>
                                </Button>
                            </Hide>

                            <Hide
                                hide={!Permissions.checkPermission(permissions_data.cash_transfer_request.receive) || pending_received_cash_transfer === 0}>

                                <Hidden xsDown>
                                    <Badge badgeContent={pending_received_cash_transfer} color="error">
                                        {PendingCashTransferBtn}
                                    </Badge>
                                </Hidden>

                                <Hidden smUp>
                                    {PendingCashTransferBtn}
                                </Hidden>

                            </Hide>
                        </div>


                        <div className='mr-auto'/>

                        <LanguageBtn className="text-black-50"/>

                        <CustomDropDown
                            readerItem={(
                                <>
                                    <Avatar src={localStorage.getItem('profile_img')}/>
                                    <Hidden xsDown>
                                        <Typography variant='inherit'
                                                    className="margin-left-2 font-weight-bold text-black-50">
                                            {localStorage.getItem('short_name')}
                                        </Typography>
                                    </Hidden>
                                    <ArrowDropDownIcon className="text-black-50"/>
                                </>
                            )}
                            options={
                                [
                                    // {label: "profile", action: () => alert("ok")},
                                    {label: "logout", action: () => auth.logout()}
                                ]
                            }
                        />


                    </Toolbar>

                    <Toolbar>
                        <Hide hide={not_validate_value.includes(breadcrumbProps)}>
                            <CustomBreadcrumbs {...breadcrumbProps}/>
                        </Hide>
                    </Toolbar>

                    {/*<div className='container-header'/>*/}

                    <main className="container-body">

                        {/*// Page Content*/}
                        <BreadcrumbContext.Provider value={{breadcrumbProps, setBreadcrumbProps}}>
                        <CashTransferContext.Provider value={{pending_received_cash_transfer, setCheckPendingReceivedCashTransfer}}>
                            <Routes/>
                        </CashTransferContext.Provider>
                        </BreadcrumbContext.Provider>

                        <Copyright/>

                    </main>

                </div>

            </div>
        </>
    );
}
