import React, {useContext, useEffect, useState} from "react";
import MaterialTable from "material-table";
import {getLabel} from "../../../../localization/MainLocalization";
import {material_table_localization} from "../../../../components/Material_conf";
import Grid from "@material-ui/core/Grid";
import {CustomModal} from "../../../../components/CustomModal";
import {FormHeader} from "../../../../components/FormHeader";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Permissions from "../../../../auth/permissions";
import {permissions_data} from "../../../../auth/permission_data";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseFilterLocationsURL} from "../../../../urls";
import {AlertContext} from "../../../../store/use_context";

export const ViewLocations = props =>{
    const {setAlert} = useContext(AlertContext);

    let columns = [
        {title: getLabel("name"), field:'name'}
    ]
    const [data, setData] = useState([])
    const [is_loading, setIsLoading] = useState(false)

    useEffect(()=>{
        const fetchData = async ()=>{
            await setIsLoading(true);
            let response = await httpRequestWithAuth(BaseFilterLocationsURL, "GET")
            if (response.status){
                const locations = response.data
                let temp_data = []
                let index = 0;
                locations.map((Location) =>{
                    if (!props.show_locations || (props.show_locations && props.show_locations.includes(Location.id))){
                        Location.index = index
                    if(props.active_locations.includes(Location.id)){
                        Location.status = true
                    }else{
                        Location.status = false
                    }

                        temp_data.push(Location)
                        index++;
                    }
                })
                await setData(temp_data)
            }
            await setIsLoading(false);
        }

        fetchData()
    },[])

    const handleAction = async (index, location, is_active)=>{
        let response = await httpRequestWithAuth(props.request_url, "PATCH", {location, is_active})
        if(response.status){
            let temp_data = [...data]
            temp_data[index]['status'] = is_active
            await setData(temp_data)
        }else{
            await setAlert(response.error_feedback.non_field_errors)
        }
    }

    return(
        <CustomModal
            open={props.open}>
        <Grid container spacing={3}>

            <FormHeader
                title="locations"
                handleClose={props.handleModalClose}
            />

            <Grid item xs={12}>
            <MaterialTable
                columns={columns}
                data={data}
                isLoading={is_loading}
                style={{boxShadow: "none"}}
                options={{
                    paging: false,
                    actionsColumnIndex: -1,
                    showTitle: false,
                    searchFieldAlignment: 'left'
                }}
                actions={[
                    ((rowData) => {
                        return (
                            {
                                icon: () => <CheckCircleOutlineIcon/>,
                                hidden: !Permissions.checkAllPermissions([permissions_data.location_service.add, permissions_data.location_service.change]) || rowData.status,
                                tooltip: getLabel("activate_on_location"),
                                onClick: () => handleAction(rowData.index, rowData.id, true)
                            }
                        );
                    }),

                    ((rowData) => {
                        return (
                            {
                                icon: RemoveCircleOutlineIcon,
                                hidden: !Permissions.checkAllPermissions([permissions_data.location_service.delete]) || !rowData.status,
                                tooltip: getLabel("deactivate_from_location"),
                                onClick: () => handleAction(rowData.index, rowData.id, false)
                            }
                        )
                    }),
                ]}

                {...material_table_localization()}

            />
            </Grid>

        </Grid>
        </CustomModal>

    )

}