import React, {useContext, useRef, useState} from "react";
import MaterialTable from "material-table";
import Permissions from "../../../auth/permissions";
import "../../../assets/scss/Static.scss";
import {httpRequestWithAuth, instance} from "../../../auth/axios_instance";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Hide} from "../../../components/Hide";
import Tab from "@material-ui/core/Tab";
import {
    BaseClientURL,
    BaseFinanceClaimRequestURL
} from "../../../urls";
import {AlertContext} from "../../../store/use_context";
import {CustomTabs} from "../../../components/CustomTabs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {sub_directory} from "../../../App";
import {ApproveClaimFormModal} from "./components/ApproveClaimFormModal";
import {RejectOrReturnClaimFormModal} from "./components/RejectOrReturnClaimFormModal";

export const StaffClaimRequests = props =>{
    const tableRef = useRef();

    const  [columns, setColumns] = useState([
        {title: getLabel("claim_id"),
            render: rowData => (
                <Typography>
                    <Link href="" onClick={e => {
                        e.preventDefault();
                        viewClaim(rowData.id, rowData.claim_id)
                    }}>
                        #{rowData.claim_id}
                    </Link>
                </Typography>
            )
        },
        {title: getLabel("staff"), field: 'staff_name'},
        {title: getLabel("title"), field: 'title'},
        {title: getLabel("personal_amount"), field: 'personal_money_amount'},
        {title: getLabel("cash_box_amount"), field: 'cash_box_amount'},
        {title: getLabel("total_amount"), field: 'total_amount'},
        {title: getLabel("status"), field: 'status'},
        {title: getLabel("invoice_date"), field: 'invoice_date'},
    ]);


    const [search, setSearch] = useState("")
    const [current_tab, setCurrentTab] = useState(0)


    const viewClaim = (id, tag) => {
        props.history.push({
            pathname: sub_directory +'claims/view',
            state: {
                id: id,
                tag: tag
            }
        })
    };

   const handleTabsChange = (event, newValue) => {
       setCurrentTab(newValue);
       reloadData();
    };

    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length >= 5  || newValue === '') {
            reloadData();
        }
    };


        return (
            <Paper>
                <Grid item xs={12} className="Paper">
                    <CustomTabs
                        currentTab={current_tab}
                        handleTabsChange={handleTabsChange}
                    >
                        <Tab label={getLabel("all")} id={0}/>
                        <Tab label={getLabel("submitted")} id={1}/>
                        <Tab label={getLabel("approved")} id={2}/>
                        <Tab label={getLabel("partially_paid")} id={3}/>
                        <Tab label={getLabel("paid")} id={4}/>
                        <Tab label={getLabel("returned")} id={5}/>
                        <Tab label={getLabel("rejected")} id={6}/>
                        <Tab label={getLabel("cancelled")} id={7}/>
                    </CustomTabs>
                </Grid>

                <Grid container spacing={3}>

                    <Grid item xs={12}>

                        <TableToolbar
                            searchFieldName='search'
                            searchValue={search}
                            onSearchChangeHandle={handleSearchField}
                            searchAction={() => reloadData()}
                        />


                            <MaterialTable
                                tableRef={tableRef}
                                columns={columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        let url = BaseFinanceClaimRequestURL;
                                        url += '?page=' + (query.page + 1);

                                        if (search !== '') {
                                            url += '&search=' + search;
                                        }

                                        if (current_tab === 1){
                                            url+= "&status=Submitted"
                                        }

                                        if (current_tab === 2){
                                            url+= "&status=Approved"
                                        }

                                        if (current_tab === 3){
                                            url+= "&status=Partially paid"
                                        }

                                        if (current_tab === 4){
                                            url+= "&status=Paid"
                                        }

                                        if (current_tab === 5){
                                            url+= "&status=Returned"
                                        }

                                        if (current_tab === 6){
                                            url+= "&status=Rejected"
                                        }

                                        if (current_tab === 7){
                                            url+= "&status=Cancelled"
                                        }
                                        instance.get(url)
                                            .then(result => {
                                                resolve({
                                                    data: result.data.results,
                                                    page: result.data.page - 1,
                                                    totalCount: result.data.total,
                                                })
                                            })
                                    })
                                }


                                {...material_table_style}
                                {...material_table_options()}
                                {...material_table_localization()}

                            />

                    </Grid>
                </Grid>
            </Paper>
        );

}

