import React, {Component, Fragment, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../../assets/scss/Static.scss';
import {CustomTextField} from "../../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../../components/FormHeader";
import {FormActionButtons} from "../../../../../components/FormActionButton";
import {CustomModal} from "../../../../../components/CustomModal";
import {CustomCheckBox} from "../../../../../components/CustomCheckBox";
import {DateField} from "../../../../../components/fields/DateField";
import {MaleFemaleCheckBox} from "../../../../../components/MaleFemaleCheckBox";
import Permissions from "../../../../../auth/permissions";
import {not_validate_value} from "../../../../../App";
import moment from "moment";
import {CreatePriceList, UpdatePriceListByField} from "../price_list_apis";
import {
    CreatePriceListEquipment,
    DeletePriceListEquipment, ListPriceListEquipment,
    UpdatePriceListEquipment
} from "../../../equipment/equipment-apis";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../../../localization/MainLocalization";
import {Hide} from "../../../../../components/Hide";
import {EquipmentAutoCompelete} from "../../../../../components/fields/EquipmentAutoCompelete";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import {Loading} from "../../../../../components/Loading";
import {permissions_data} from "../../../../../auth/permission_data";



export const  PriceListFormModal = props =>{
        const price_list_id = props.value.id
        let delete_equipment = [];
        const have_perm = Permissions.checkAllPermissions([permissions_data.price_list_equipment.change, permissions_data.price_list_equipment.delete, permissions_data.price_list_equipment.add])

    const [data, setData] = useState({is_active:true, ...props.value});
    const [equipment, setEquipment] = useState([]);
    const [equipment_id, setEquipmentID] = useState([]);
    const [is_loading, setIsLoading] = useState(true);
    const [helper_text, setHelperText] = useState({});

    useEffect(()=>{
        const fetchData = async () =>{
            await setIsLoading(true);

            if (!not_validate_value.includes(price_list_id)) {
                let response = await ListPriceListEquipment(price_list_id);
                if (response.status) {
                    let equipment_id = []
                    await response.data.map(Data => equipment_id.push(Data.equipment))
                    setEquipment(response.data)
                    setEquipmentID(equipment_id)
                }
            }

            await setIsLoading(false);
        }

        fetchData();
    }, []);


    const handleIconClick = (index, action) => {
        index = parseInt(index)
        setEquipment([
            ...equipment.slice(0, index),
            {
                ...equipment[index],
                quantity: Math.max((action === "add" ? 1 : -1) + parseInt(equipment[index].quantity), 0)
            },
            ...equipment.slice(index + 1),
        ]);
    }

    const handleAddNewEquipment = () => {
        setEquipment(
            [
                    ...equipment,
                    {equipment: "", quantity: 0}
                ]
        )
        setEquipmentID([...equipment_id, ""])
    }


    const handleDeleteEquipment = async (index) => {
        index = parseInt(index)
        if (equipment[index].id !== undefined) {
            await delete_equipment.push(equipment[index].id)
        }

            setEquipment([
                ...equipment.slice(0, index),
                ...equipment.slice(index + 1),
            ]);
            setEquipmentID([
                ...equipment_id.slice(0, index),
                ...equipment_id.slice(index + 1),
            ]);
    }

    const handleChangeQuantity = (index, value) => {
        value = value === "" ? 0 : value;
        value = parseInt(value);
        index = parseInt(index);
        if (value < 0) {
            value = 0;
        }

            setEquipment([
                ...equipment.slice(0, index),
                {...equipment[index], quantity: value},
                ...equipment.slice(index + 1),
            ]);
    }

    const handleChangeEquipment = async (index, value) => {
        index = parseInt(index)
            setEquipment([
                ...equipment.slice(0, index),
                {...equipment[index], equipment: value},
                ...equipment.slice(index + 1),
            ]);

            setEquipmentID([
                ...equipment_id.slice(0, index),
                value,
                ...equipment_id.slice(index + 1),
            ]);
    }

    const handleSaveBtn = async () => {
        await setIsLoading(true);

        let price_list = {
            ...data,
            service: props.service,
            start: !not_validate_value.includes(data.start) ? moment(data.start).format("YYYY-MM-DD") : null,
            end: !not_validate_value.includes(data.end) ? moment(data.end).format("YYYY-MM-DD") : null,
        };

        let Response;

        if (data.id === undefined) {
            Response = await CreatePriceList(price_list);
        } else {
            Response = await UpdatePriceListByField(price_list.id, price_list);
        }

        if (Response.status) {
            if (props.is_rent) {
                for (const price_list_equipment of delete_equipment) {
                    await DeletePriceListEquipment(price_list_equipment);
                }

                for (const price_list_equipment of equipment) {
                    if (price_list_equipment.id !== undefined && (not_validate_value.includes(price_list_equipment.equipment) || parseInt(price_list_equipment.quantity) === 0)) {
                        await DeletePriceListEquipment(price_list_equipment.id);
                    }
                }

                for (const price_list_equipment of equipment) {
                    if (!not_validate_value.includes(price_list_equipment.equipment) || parseInt(price_list_equipment.quantity) !== 0) {
                        if (price_list_equipment.id === undefined) {
                            await CreatePriceListEquipment({...price_list_equipment, price_list: Response.data.id});
                        } else {
                            await UpdatePriceListEquipment(price_list_equipment.id, {
                                ...price_list_equipment,
                                price_list: Response.data.id
                            });
                        }
                    }
                }
            }
            await props.handleModalClose();
        } else {
            setHelperText(Response.error_feedback);
        }
        await setIsLoading(false);
    }

    const onChangeHandle = (name, newValue) => {
        setData({...data, [name]: newValue})
    };

        return (
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={2}>

                    <FormHeader
                        title="pages.packages.form.header"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>
                    <Grid item xs={12}>
                        <CustomTextField
                            required={true}
                            error={helper_text.name !== undefined}
                            helper_text={helper_text.name}
                            name="name"
                            label="pages.packages.package_name"
                            value={data.name}
                            onChangeHandle={onChangeHandle}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DateField
                            name="start_date"
                            error={helper_text.start_date !== undefined}
                            helper_text={helper_text.start_date}
                            label="start_date"
                            value={data.start_date}
                            onChangeHandle={onChangeHandle}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DateField
                            name="end_date"
                            error={helper_text.end_date !== undefined}
                            helper_text={helper_text.end_date}
                            label="end_date"
                            min_date={data.start_date}
                            value={data.end_date}
                            onChangeHandle={onChangeHandle}
                        />
                    </Grid>

                    <Hide hide={props.is_rent}>
                        <Grid item xs={12}>
                            <MaleFemaleCheckBox
                                onChangeHandle={onChangeHandle}
                                for_males={data.for_males}
                                male_name="for_males"
                                for_females={data.for_females}
                                female_name="for_females"
                                disabled={props.disable_gender}
                            />
                        </Grid>
                    </Hide>


                    <Grid item xs={12}>
                        <CustomCheckBox
                            value={data.is_active}
                            name='is_active'
                            onChangeHandle={onChangeHandle}
                            label="is_active"
                        />
                    </Grid>

                <Hide hide={!props.is_rent}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className='p-1'>
                            {getLabel("pages.packages.manage_package_equipment")}
                        </Typography>
                    </Grid>

                    {equipment.map((Data, index) => {
                        return (
                            <Fragment key={index}>
                                <Hide hide={index === 0}>
                                    <Grid item xs={12} className="mt-3 mb-3"><Divider variant='middle'/></Grid>
                                </Hide>

                                <Hide hide={!have_perm}>
                                    <Grid item xs={2} md={1} className="text-center">
                                        <Tooltip title={getLabel("remove")}>
                                            <IconButton size="small" className={"mt-2 pt-2 text-start"}
                                                        color='inherit'
                                                        onClick={() => handleDeleteEquipment(index)}>
                                                <RemoveCircleIcon color="secondary"/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Hide>

                                <Grid item xs={10} md={6}>
                                    <EquipmentAutoCompelete
                                        disabled={!have_perm}
                                        hideData={equipment_id}
                                        required={true}
                                        name={index}
                                        label="equipment"
                                        onChangeHandle={handleChangeEquipment}
                                        value={Data.equipment}
                                    />
                                </Grid>

                                <Hide hide={!have_perm}>

                                    <Grid item xs={3} md={1} className="text-center">
                                        <Tooltip title={getLabel("decrease")}>
                                            <IconButton size="small" className={"mt-2 pt-2 text-start"}
                                                        color='inherit'
                                                        onClick={() => handleIconClick(index, "remove")}>
                                                <RemoveCircleIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Hide>

                                <Grid item xs={6} md={3}>
                                    <CustomTextField
                                        name={index}
                                        label="qty"
                                        disabled={!have_perm}
                                        type="number"
                                        value={Data.quantity}
                                        onChange={handleChangeQuantity}
                                    />
                                </Grid>

                                <Hide hide={!have_perm}>

                                    <Grid item xs={3} md={1} className="text-center">
                                        <Tooltip title={getLabel("increase")}>
                                            <IconButton size="small" className={"mt-2 pt-2 text-start"}
                                                        color='inherit'
                                                        onClick={() => handleIconClick(index, "add")}>
                                                <AddCircleIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Hide>
                            </Fragment>
                        );
                    })}

                        <Hide hide={equipment.length !== 0}>

                            <Grid item xs={12} className="text-center">
                                <Typography variant="body1">
                                    {getLabel("empty_data_source_message")}
                                </Typography>
                            </Grid>
                        </Hide>

                    <Hide hide={!have_perm}>
                        <Grid item xs={12} className={"text-start"}>
                            <Tooltip className="mt-3" title={getLabel('pages.equipment.add_equipment')}>
                                <IconButton
                                    onClick={handleAddNewEquipment}
                                >
                                    <AddIcon fontSize='small'/>
                                    <Typography variant='subtitle2'>
                                        {getLabel('pages.equipment.add_equipment')}
                                    </Typography>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Hide>

                </Hide>

                <FormActionButtons
                    errorMsg={helper_text.non_field_errors}
                    handleCancelButton={props.handleModalClose}
                    handleSaveButton={handleSaveBtn}
                />
                    </Hide>

            </Grid>

    </CustomModal>

    );
}