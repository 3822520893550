import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {ClientAutoComplete} from "../../../../components/fields/ClientAutoComplete";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseMembershipChangeInvoiceDateURL, BaseMembershipTransferURL, BaseMembershipURL} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {DateField} from "../../../../components/fields/DateField";


export const ChangeInvoiceDate = props => {
    const [data , setData] = useState(props.default_value || null)
    const [helper_text , setHelperText] = useState({})
    const [is_loading , setIsLoading] = useState(false)

    const handleFieldChange = async (name, value) => {
        await setData(value);
    };

    const handleSaveButton = async () => {
        await setIsLoading(true);

        let url = `${BaseMembershipURL}${props.membership}/${BaseMembershipChangeInvoiceDateURL}`;
        let response = await httpRequestWithAuth(url, "PATCH", {'invoice_date': data})

        if (response.status) {
            await props.handleModalClose(true)
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

        return (

            <CustomModal
                open={props.open}
            >
                    <Grid container spacing={2}>

                        <FormHeader
                            title="change_invoice_date"
                            handleClose={()=>props.handleModalClose(false)}
                        />

                        <Hide hide={!is_loading}>
                            <Loading/>
                        </Hide>

                        <Hide hide={is_loading}>

                            <Grid item xs={12}>
                                <DateField
                                    required={true}
                                    name="invoice_date"
                                    error={helper_text.invoice_date !== undefined}
                                    helper_text={helper_text.invoice_date}
                                    label="invoice_date"
                                    value={data}
                                    onChangeHandle={handleFieldChange}
                                />
                            </Grid>


                            <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={()=>props.handleModalClose(false)}
                            handleSaveButton={handleSaveButton}
                        />
                        </Hide>
                    </Grid>
            </CustomModal>
        );
}