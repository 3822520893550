import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Hide} from "../../../components/Hide";
import {WorkoutForm} from "./WorkoutForm";
import {not_validate_value} from "../../../App";


export const WorkoutInfo = props => {
    const [currentTab, setCurrentTab] = useState(0);
    const [workout_id, setWorkoutId] = useState("new");
    const [copied_sections, setCopiedSections] = useState("");
    const {state} = props.history.location || {};


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };

    useEffect(()=>{
        if (state !== undefined && state.id){
            setWorkoutId(state.id);
        }else{
            props.history.push(`/workouts`);
        }

        if (state !== undefined && state.sectionsData && !not_validate_value.includes(state.sectionsData)) {
            setCopiedSections(state.sectionsData);
        }
    },[])


    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match}/>*/}

            {/*<CustomTabs*/}
            {/*    currentTab={currentTab}*/}
            {/*    handleTabsChange={handleTabsChange}*/}
            {/*>*/}
            {/*    <Tab label={getLabel("details")} id={0}/>*/}
            {/*</CustomTabs>*/}

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <WorkoutForm
                        {...props}
                        workout_id={workout_id}
                        copiedSectionsData={copied_sections}
                    />
                </Hide>
            </Grid>
        </Paper>
    );
}