import React, {Fragment, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CustomModal} from "../../../../components/CustomModal";
import {
    CreateEquipment,
    CreateEquipmentLocations,
    ListEquipmentLocations, UpdateEquipment,
    UpdateEquipmentLocations
} from "../equipment-apis";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Tooltip from "@material-ui/core/Tooltip";
import {getLabel} from "../../../../localization/MainLocalization";
import IconButton from "@material-ui/core/IconButton";
import {DisabledTextField} from "../../../../components/fields/DisabledField";
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import Divider from "@material-ui/core/Divider";
import {Loading} from "../../../../components/Loading";
import {ListLocations} from "../../locations/location-apis";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import Typography from "@material-ui/core/Typography";
import {Hide} from "../../../../components/Hide";
import {CustomTextArea} from "../../../../components/fields/CustomTextArea";


export const EquipmentFormModal = props => {

        const equipment = props.data.id

    const [equipment_locations, setEquipmentLocations] = useState([])
    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(true)

    useEffect(()=>{
        const fetchData = async () =>{
            await setIsLoading(true)

            let response = await ListLocations();
            let data = [];
            if(response.status){
                let equipment_locations = null;
                if (equipment !== undefined){
                    equipment_locations = await ListEquipmentLocations(equipment);
                }
                await  response.data.forEach(Location=>{
                    let temp = {location:Location.id, location_name:Location.name,quantity:0,equipment:equipment}
                    if (equipment !== undefined && equipment_locations.status){
                        equipment_locations.data.forEach(Location=>{
                            if (Location.location === temp.location){
                                temp.quantity = Location.quantity
                                temp.id = Location.id
                            }
                        })
                    }
                    data.push(temp);
                })
            }

            await setEquipmentLocations(data);
            await setIsLoading(false)
        };

        fetchData();
    }, []);


    const handleIconClick = async (index, action)=>{
        index = parseInt(index)
        let data = [
            ...equipment_locations.slice(0,index),
        {...equipment_locations[index], quantity: Math.max((action === "add"? 1 :-1) + parseInt(equipment_locations[index].quantity), 0)},
            ...equipment_locations.slice(index+1),
        ];

        await setEquipmentLocations(data);
    }


    const handleChangeQuantity = (index, value)=>{
        value = value === "" ? 0: value;
        value = parseInt(value);
        index = parseInt(index);
        if (value < 0){
            value = 0;
        }

            let data = [
                ...equipment_locations.slice(0,index),
                {...equipment_locations[index], quantity:value},
                ...equipment_locations.slice(index+1)
            ];

        setEquipmentLocations(data);
    }

    const handleSaveBtn = async () =>{
        await setIsLoading(true);

        let response;
        if (equipment === undefined) {
            response = await CreateEquipment(props.data);
        } else {
            response = await UpdateEquipment(props.data.id, props.data);
        }

        if (response.status) {
            for (const EquipmentLocations of equipment_locations) {
                EquipmentLocations.equipment = response.data.id
                if (EquipmentLocations.id === undefined) {
                    await CreateEquipmentLocations(EquipmentLocations);
                } else {
                    await UpdateEquipmentLocations(EquipmentLocations.id, {"quantity": EquipmentLocations.quantity});
                }
            }
            await props.handleModalClose()
        }else{
            setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    }


    return (
            <CustomModal
                open={props.open}
            >

             <Grid container spacing={3}>
            <Hide hide={!is_loading}><Loading/></Hide>

                    <Hide hide={is_loading}>
                    <FormHeader
                        title="pages.equipment.form.header"
                        handleClose={props.handleModalClose}
                    />

                    <Grid item xs={12}>
                        <CustomTextField
                            required={true}
                            error={helper_text.name !== undefined}
                            helperText={helper_text.name}
                            name="name"
                            label="name"
                            value={props.data.name}
                            onChangeHandle={props.onChangeHandle}
                        />
                    </Grid>

                        <Grid item xs={12} className="mt-3">
                            <CustomTextArea
                                label="description"
                                error={helper_text.description !== undefined}
                                helper_text={helper_text.description}
                                name="description"
                                rows={3}
                                value={props.data.description || ""}
                                onChangeHandle={props.onChangeHandle}
                            />
                        </Grid>


                    <Grid item xs={12}>
                    <Typography variant="h6" className='p-1'>
                        {getLabel("pages.equipment.manage_qty")}
                    </Typography>
                    </Grid>

                    {equipment_locations.map((Data,index)=>{
                        return(
                            <Fragment key={index}>

                                <Hide hide={index === 0}>
                                <Grid item xs={12} className="mt-3 mb-3"><Divider variant='middle'/></Grid>
                                </Hide>

                            <Grid item xs={12} md={7}>
                                <DisabledTextField
                                    label="location"
                                    value={Data.location_name}
                                />
                            </Grid>

                            <Grid item xs={3} md={1} className="text-center">
                                <Tooltip title={getLabel("decrease")}>
                                    <IconButton size="small" className={"mt-2 pt-2 text-start"} color='inherit'
                                                onClick={()=>handleIconClick(index,"remove")}>
                                        <RemoveCircleIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                                <Grid item xs={6} md={3}>
                                    <CustomTextField
                                        name={index}
                                        label="qty"
                                        type="number"
                                        value={Data.quantity}
                                        onChangeHandle={handleChangeQuantity}
                                    />
                                </Grid>

                                <Grid item xs={3} md={1} className="text-center">
                                    <Tooltip title={getLabel("increase")}>
                                        <IconButton size="small" className={"mt-2 pt-2 text-start"} color='inherit'
                                                    onClick={()=>handleIconClick(index,"add")}>
                                            <AddCircleIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Fragment>
                        );
                    })}

                    <Grid item xs={12}>
                        <CustomCheckBox
                            value={props.data.is_active}
                            name='is_active'
                            onChangeHandle={props.onChangeHandle}
                            label="is_active"
                        />
                    </Grid>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveBtn}
                    />

                    </Hide>
                </Grid>
            </CustomModal>
    );
};