import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {sub_directory} from "../../../App";
import {CustomTabs} from "../../../components/CustomTabs";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {ClaimForm} from "./ClaimForm";
import {ClaimAttachment} from "./ClaimAttachment";
import {ClaimPayments} from "./ClaimPayments";


export const StaffClaimsInfo = props => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [claim_id, setClaimId] = React.useState("new");
    const [is_full_paid, setIsFullPaid] = React.useState(false);
    const [can_edit, setCanEdit] = React.useState(false);
    const state = props.history.location.state || {};

    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };


    useEffect(()=>{
        if (state.id){
            setClaimId(state.id);
        }else{
            props.history.push(`${sub_directory}claims`);
        }
    },[])

    return (
        <Paper className='Paper'>

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >
                <Tab label={getLabel("details")} id={0}/>


                <Tab label={getLabel("attachment")} id={2} disabled={claim_id === 'new' || !Permissions.checkPermission(permissions_data.claim_attachment.view_staff)}/>

                <Tab label={getLabel("payments")} id={2} disabled={claim_id === 'new' || !Permissions.checkPermission(permissions_data.claim_payment.view)}/>

            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden' spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <ClaimForm
                        {...props}
                        claim_id={claim_id}
                        can_edit={can_edit}
                        setCanEdit={setCanEdit}
                        staff_view
                        setIsFullPaid={setIsFullPaid}
                    />
                </Hide>

                <Hide hide={currentTab !== 1}>
                    <ClaimAttachment
                        {...props}
                        claim_id={claim_id}
                        staff_view
                    />
                </Hide>

                <Hide hide={currentTab !== 2}>
                    <ClaimPayments
                        {...props}
                        claim_id={claim_id}
                        staff_view
                        is_full_paid={is_full_paid}
                    />
                </Hide>

            </Grid>
        </Paper>
    );
}