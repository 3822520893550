import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {CountriesAutoComplete} from "../../../components/fields/CountriesAutoComplete";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {CharFieldOnlyField} from "../../../components/fields/CharFieldOnlyField";
import {DateField} from "../../../components/fields/DateField";
import {PhoneNumberField} from "../../../components/fields/PhoneNumberField";
import {GenderRadioButton} from "../../../components/GenderRadioButton";
import {AddressFields} from "../../../components/fields/AddressFields";
import {not_validate_value, sub_directory} from "../../../App";
import moment from "moment";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseAthleteURL} from "../../../urls";
import {ConfirmationContext} from "../../../store/use_context";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import {CustomCheckBox} from "../../../components/CustomCheckBox";
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import {getLabel} from "../../../localization/MainLocalization";
import {ProfilePhoto} from "../../../components/ProfilePhoto";

export const AthleteForm = props => {
    const {setConfirmData} = useContext(ConfirmationContext);
    const {athlete_id} = props;



    const have_permission = ((Permissions.checkPermission(permissions_data.athlete.add) && athlete_id === 'new') ||
        Permissions.checkPermission(permissions_data.athlete.change));

    const [data, setData] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(true);
    const [edit_data, setEditData] = useState(false);

    useEffect(() => {
        setEditData(athlete_id === 'new');
    }, [athlete_id])

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true)

            if (athlete_id !== 'new') {
                let response = await httpRequestWithAuth(`${BaseAthleteURL}${athlete_id}/`, "GET");

                if (response.status) {
                    let temp_data = {
                        ...response.data,
                        nationality: response.data.nationality.name,
                        country: response.data.country.name,
                    };
                    setData(temp_data);
                } else {
                    props.history.push("/notfound");
                }

            }
            await setIsLoading(false)
        }

        fetchData();
    }, [athlete_id, edit_data])

    const getBirthDate = (social_id) => {
        if ((social_id.charAt(0) === '3' || social_id.charAt(0) === '2') && social_id.length === 12) {
            let year = social_id.charAt(0) === '3' ? "20" : social_id.charAt(0) === '2' ? "19" : "";
            year = year + social_id.substr(1, 2);
            let month = social_id.substr(3, 2);
            let day = social_id.substr(5, 2);
            let date = year + "-" + month + "-" + day;
            date = new Date(date);
            if (date + "" !== 'Invalid Date') {
                return date
            }
        }
        return null
    };


    const handleInfoFieldChange = (name, newValue) => {
        if (have_permission && edit_data) {
            let new_data = {[name]: newValue}

            if (name === "photo"){
                if (not_validate_value.includes(newValue)){
                    new_data['display_photo'] = newValue;
                    new_data['remove_photo'] = true;
                }else{
                    new_data['display_photo'] = URL.createObjectURL(newValue);
                    new_data['remove_photo'] = false;
                }

            }

            if (name === 'social_id') {
                let birthdate = getBirthDate(newValue)
                if (birthdate !== null) {
                    new_data['birth_date'] = birthdate
                }
            }


            setData({...data, ...new_data})
        }
    };

    const handleSaveButton = async (create_membership = false) => {
        await setIsLoading(true);

        let athlete_data = {
            ...data,
            birth_date: !not_validate_value.includes(data.birth_date) ? moment(data.birth_date).format("YYYY-MM-DD") : null,
        }

        let bodyFormData = new FormData();
        Object.keys(athlete_data).map(Name=>{
            if (Name !== "photo" || !not_validate_value.includes(athlete_data[Name])){
                bodyFormData.append(Name, athlete_data[Name] || "");
            }

        })

        let response;
        if (athlete_id === 'new') {
            response = await httpRequestWithAuth(BaseAthleteURL, "POST", bodyFormData);
        } else {
            response = await httpRequestWithAuth(`${BaseAthleteURL}${athlete_id}/`, "PATCH", bodyFormData);
        }

        await setHelperText({});
        if (response.status) {
            setEditData(false);
            if (athlete_id === 'new' && Permissions.checkPermission(permissions_data.athlete_attachment.view)) {
                props.history.push({
                    pathname: `${sub_directory}athletes/edit`,
                    state: {
                        moveToNextTab: true,
                        id: response.data.id,
                        tag: response.data.short_name
                    }
                });
            } else {
                handleBackButton();
            }
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this Athlete ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let response = await httpRequestWithAuth(`${BaseAthleteURL}${athlete_id}/`, "DELETE");
        if (response.status) {
            await handleBackButton()
        } else {
            await setHelperText(response.error_feedback)
        }
    }

    const handleBackButton = () => {
        props.history.push(`${sub_directory}athletes`);
    }

    const handleEditAction = () => {
        setEditData(!edit_data);
    }

    return (
        <>
            <Hide hide={!is_loading}><Loading/></Hide>

            <Hide hide={is_loading}>

                <Hide hide={!have_permission || athlete_id === 'new'}>
                    <Grid item xs={12} className="text-end">
                        <Button
                            size="small"
                            startIcon={edit_data ? <CloseIcon/> : <EditIcon/>}
                            className={`text-white ${edit_data ? "bg-danger" : "primary-bg"}`}
                            onClick={handleEditAction}
                        >
                            {edit_data ? getLabel("cancel_edit") : getLabel("edit")}
                        </Button>
                    </Grid>
                </Hide>

                <Grid item xs={12} sm={6} lg={5} xl={3}>
                    <ProfilePhoto
                        onChangeHandle={handleInfoFieldChange}
                        name="photo"
                        value={data.display_photo}
                        viewOnly={!edit_data}
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={7} xl={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <CharFieldOnlyField
                                viewOnly={!edit_data}
                                required
                                helper_text={helper_text.first_name}
                                error={helper_text.first_name !== undefined}
                                value={data.first_name}
                                onChangeHandle={handleInfoFieldChange}
                                name="first_name"
                                label="first_name"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                        <CharFieldOnlyField
                            viewOnly={!edit_data}
                            helper_text={helper_text.middle_name}
                            error={helper_text.middle_name !== undefined}
                            value={data.middle_name}
                            onChangeHandle={handleInfoFieldChange}
                            name="middle_name"
                            label="middle_name"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <CharFieldOnlyField
                            required
                            viewOnly={!edit_data}
                            helper_text={helper_text.last_name}
                            error={helper_text.last_name !== undefined}
                            value={data.last_name}
                            onChangeHandle={handleInfoFieldChange}
                            name="last_name"
                            label="last_name"
                        />
                    </Grid>



                        <Grid item xs={12} sm={6} md={4}>
                            <CountriesAutoComplete
                                viewOnly={!edit_data}
                                label="nationality"
                                name="nationality"
                                onChangeHandle={handleInfoFieldChange}
                                helper_text={helper_text.nationality}
                                error={helper_text.nationality !== undefined}
                                value={data.nationality}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <CustomTextField
                                required
                                viewOnly={!edit_data}
                                helper_text={helper_text.social_id}
                                error={helper_text.social_id !== undefined}
                                value={data.social_id}
                                onChangeHandle={handleInfoFieldChange}
                                name="social_id"
                                label="civil_id"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <DateField
                                openToYear
                                viewOnly={!edit_data}
                                name="birth_date"
                                error={helper_text.birth_date !== undefined}
                                helper_text={helper_text.birth_date}
                                label="birth_date"
                                value={data.birth_date}
                                onChangeHandle={handleInfoFieldChange}
                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <PhoneNumberField
                                viewOnly={!edit_data}
                                required
                                helper_text={helper_text.mobile}
                                error={helper_text.mobile !== undefined}
                                value={data.mobile}
                                onChangeHandle={handleInfoFieldChange}
                                name="mobile"
                                label="mobile"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                viewOnly={!edit_data}
                                helper_text={helper_text.email}
                                error={helper_text.email !== undefined}
                                value={data.email}
                                onChangeHandle={handleInfoFieldChange}
                                name="email"
                                label="email"
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <GenderRadioButton
                                viewOnly={!edit_data}
                                label="gender"
                                name="gender"
                                required={true}
                                helper_text={helper_text.gender}
                                error={helper_text.gender !== undefined}
                                value={data.gender}
                                onChangeHandle={handleInfoFieldChange}
                            />
                        </Grid>

                    </Grid>
                </Grid>


                <Grid item xs={12} md={6}>
                    <CharFieldOnlyField
                        viewOnly={!edit_data}
                        helper_text={helper_text.em_contact_name}
                        error={helper_text.em_contact_name !== undefined}
                        value={data.em_contact_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="em_contact_name"
                        label="emergency_contact_name"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <PhoneNumberField
                        viewOnly={!edit_data}
                        helper_text={helper_text.em_contact_telephone}
                        error={helper_text.em_contact_telephone !== undefined}
                        value={data.em_contact_telephone}
                        onChangeHandle={handleInfoFieldChange}
                        name="em_contact_telephone"
                        label="emergency_contact_telephone"
                    />
                </Grid>



                <AddressFields
                    viewOnly={!edit_data}
                    helper_text={helper_text}
                    value={data}
                    onChangeHandle={handleInfoFieldChange}
                />



                {athlete_id !== 'new' && (
                    <>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        label="created_by"
                                        disabled
                                        value={data.created_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_created"
                                        value={data.date_created}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="modified_by"
                                        value={data.modified_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_modified"
                                        value={data.date_modified}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                    </>
                )}

                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowDelete={Permissions.checkAllPermissions([permissions_data.athlete.delete]) && athlete_id !== 'new'}
                    deleteAction={handleDeleteButton}
                    backAction={handleBackButton}
                    allowSave={edit_data}
                    saveAction={handleSaveButton}
                />
            </Hide>

        </>

    );

}
