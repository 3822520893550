import React, {Component, useEffect, useState} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import {getLabel} from "../../../../localization/MainLocalization";
import {MembershipFilterData} from "../memberships_apis";
import {SelectField} from "../../../../components/fields/SelectField";


export const MembershipsFilter = props => {
        const [locations, setLocation] = useState([])
        const [services, setServices] = useState([])

    useEffect(()=>{
        const fetchData = async  () =>{
            let filterDataRespones = await MembershipFilterData();
            if (filterDataRespones.status) {
                let locations_temp = []
                let services_temp = []
                filterDataRespones.data.locations.map(Location=>{
                    locations_temp.push({title:Location.name, value:Location.id})
                })

                filterDataRespones.data.services.map(Service=>{
                    services_temp.push({title:Service.name, value:Service.id})
                })
                setLocation(locations_temp)
                setServices(services_temp)
            }
        }

        fetchData();
    }, [])


        return (

            <Toolbar className="pt-3">
                <Grid container spacing={3}>

                    <Grid item xs={6} md={3}>

                        <SelectField
                            label="location"
                            name="location"
                            value={props.value.location}
                            onChangeHandle={props.onChangeHandle}
                            options={locations}
                        />
                    </Grid>


                    <Grid item xs={6} md={3}>
                            <SelectField
                                label="gender"
                                name="gender"
                                value={props.value.gender}
                                onChangeHandle={props.onChangeHandle}
                                options={[
                                    {title:getLabel("male"), value: 'Male'},
                                    {title:getLabel("female"), value:'Female'}
                                ]}
                            />
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <SelectField
                            label="balance"
                            name="balance"
                            value={props.value.balance}
                            onChangeHandle={props.onChangeHandle}
                            options={[
                                {title:getLabel("yes"), value:'true'},
                                {title:getLabel("no"), value:'false'}
                            ]}
                        />
                    </Grid>


                    <Grid item xs={6} md={3}>
                        <SelectField
                            label="service"
                            name="service"
                            value={props.value.service}
                            onChangeHandle={props.onChangeHandle}
                            options={services}
                        />
                    </Grid>

                </Grid>

            </Toolbar>

        );

}
