import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {getLabel} from "../../../../localization/MainLocalization";
import MaterialTable from "material-table";
import {BaseFinanceLocationsCashLogURL} from "../../../../urls";
import moment from "moment";
import {DateField} from "../../../../components/fields/DateField";
import {not_validate_value} from "../../../../App";


export const LocationsCashLog = props => {
    const [data, setData] = useState( {total:0, details:[]});
    const [is_loading, setIsLoading] = useState( true);
    const columns =[
            {title: getLabel("name"), field: 'location_name'},
            {title: getLabel("amount"), field: 'amount'},
        ];
    const [setting, setSetting] = useState({date: moment().format("YYYY-MM-DD"), min_date:moment()});

    const handleDateChange = async (name, value) => {
        if (not_validate_value.includes(value)){
            value = moment().format("YYYY-MM-DD")
        }
       await setSetting({...setting, [name]:value})
    };

    useEffect(()=>{
        const fetchData = async () =>{
            await setIsLoading(true);
            let response = await httpRequestWithAuth(BaseFinanceLocationsCashLogURL, "GET", null, {date: setting.date});
            if (response.status){
                await setData(response.data)
                await setSetting({...setting, min_date: response.data.min_date})
            }
            await setIsLoading(false);
        }

        fetchData();
    },[setting.date])

        return (
            <div className='card-paper-1 h-100 w-100'>
                <Typography variant="h6" className="text-start mb-2">
                    Location Cash
                </Typography>

                <DateField
                    date_view="monthly"
                    label="date"
                    name="date"
                    min_date={setting.min_date}
                    maxDate={moment()}
                    value={setting.date}
                    onChangeHandle={handleDateChange}
                />


                <Typography component="p" variant="h5" className="text-end mt-2">
                    {`Total: ${localStorage.getItem('currency_symbol')} ${data.total}`}
                </Typography>

                <MaterialTable
                    columns={columns}
                    data={data.details}
                    isLoading={is_loading}
                    style={{
                        boxShadow: "none",overflowY:"auto",
                        overflowX:'auto', height:'auto',
                        maxHeight: "400px"
                    }}

                    options={{
                        search: false,
                        paging: false,
                        toolbar: false,
                    }}
                />
            </div>
        );
}
