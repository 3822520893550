import React from "react";
import {not_validate_value} from "../App";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
export const SuccessfulBox = props =>{
        if (!not_validate_value.includes(props.msg)) {
            return(
        <Grid item xs={12} className="success-box">
            <CheckCircleIcon className="margin-right-3"/>
            {props.msg}
        </Grid>
    )}
        return "";
}