import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseBenchmarkURL} from "../../urls";

export const BenchmarkAutoComplete = props => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            let params = {}

            let response = await httpRequestWithAuth(BaseBenchmarkURL, "GET", null, params);
            if (response.status) {
                await setOptions(response.data);
                if (props.fullObject){
                    await response.data.map(Benchmark=>{
                        if (Benchmark.id === props.value){
                            props.onChangeHandle(props.name, Benchmark);
                        }
                    })
                }
            } else {
                await setOptions([]);
            }
        }

        fetchData();
    }, [])

    const handleOnChange = (event, value) => {
        if (!props.viewOnly) {
            value = value !== null ? props.fullObject?value:value.id : "";
            props.onChangeHandle(props.name, value)
        }
    };

    const handleAutoCompleteValue = () => {
        let value = null;

        options.map(Data => {
            if (Data.id === props.value) {
                value = Data;
            }
            return 0;
        });

        return value;
    };


    return (
        <Autocomplete
            ListboxProps={{className:"list-box-autocomplete"}}
            renderOption={(option) => (
                <span className="w-100 text-start">{option.name}</span>
            )}
            disabled={props.disabled || props.viewOnly}
            options={options}
            getOptionLabel={option => option.name}
            renderInput={params => (
                <TextField variant="outlined" {...params}
                           label={<label>{getLabel(props.label)}<span
                               className='text-danger'>{props.required ? "*" : ""}</span></label>}
                           helperText={props.helper_text}
                           error={props.error}
                           fullWidth
                           disabled={props.disabled}
                           className={props.disabled ? "disabled-field" : ""}
                />
            )}
            value={handleAutoCompleteValue()}
            onChange={handleOnChange}
        />
    );
}
