import {
    BaseClientsAttachmentURL,
    BaseClientsMembershipsURL,
} from "../../../urls";
import {httpRequestWithAuth, instance} from "../../../auth/axios_instance";
import {not_validate_value} from "../../../App";



export const ListClientAttachments = async (client=null) => {
    let url = BaseClientsAttachmentURL;

    if (client != null){
        url += "?client=" + client
    }

    const feedback = await httpRequestWithAuth(url, "GET")

    return feedback;
};


export const CreateClientAttachments = async (data) => {
    let url = BaseClientsAttachmentURL;

    var bodyFormData = new FormData();
    bodyFormData.set("client", data.client);
    if (!not_validate_value.includes(data.name)){
        bodyFormData.append("name", data.name);
    }
    if (!not_validate_value.includes(data.file)){
        bodyFormData.append("file", data.file);
    }

    const feedback = await httpRequestWithAuth(url, "POST", bodyFormData)

    return feedback
};


export const UpdateClientAttachments = async (id, data) => {
    let url = BaseClientsAttachmentURL + id + "/";

    var bodyFormData = new FormData();
    if (!not_validate_value.includes(data.client)) {
        bodyFormData.set("client", data.client);
    }
    if (!not_validate_value.includes(data.name)){
        bodyFormData.append("name", data.name);
    }
    if (!not_validate_value.includes(data.file)){
        bodyFormData.append("file", data.file);
    }

    const feedback = await httpRequestWithAuth(url, "PATCH", bodyFormData)

    return feedback
};


export const DeleteClientAttachments = async (id) => {
    let url = BaseClientsAttachmentURL + id + "/";

    const feedback = await httpRequestWithAuth(url, "DELETE")

    return feedback
};



export const ListClientMemberships = async (client=null) => {
    let url = BaseClientsMembershipsURL;

    const feedback = await httpRequestWithAuth(url, "GET", null, {client})

    return feedback
};
