import React, {Component, useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {ListEquipment} from "../../pages/setting/equipment/equipment-apis";

export const EquipmentAutoCompelete = props =>{
    const [options, setOptions] = useState([])

    useEffect(()=>{
        const fetchData = async () =>{
            let response = await ListEquipment();
            if (response.status) {
                   await setOptions(response.data)
            }
        }

        fetchData();
    },[])

    const handleOnChange = (event, value) => {
        value = value !== null ? value.id : "";
        props.onChangeHandle(props.name, value)
    };

    const handleAutoCompleteValue = () => {
        let value = null;

        options.map(Data => {
            if (Data.id === props.value) {
                value = Data;
            }
            return 0;
        });

        return value;
    };


        return (
            <Autocomplete
                ListboxProps={{className:"list-box-autocomplete"}}
                renderOption={(option) => (
                    <span className="w-100 text-start">{option.name}</span>
                )}
                disabled={props.disabled || props.viewOnly}
                options={options}
                getOptionLabel={option => option.name}
                filterOptions={options=>{
                    return options.filter(option => !props.hideData.includes(option.id))
                }}
                renderInput={params => (
                    <TextField variant="outlined" {...params}
                               label={<label>{getLabel(props.label)}<span
                                   className='text-danger'>{props.required ? "*" : ""}</span></label>}
                               helperText={props.helper_text}
                               error={props.error}
                               fullWidth
                               disabled={props.disabled}
                               className={props.disabled ? "disabled-field" : ""}
                    />
                )}
                value={handleAutoCompleteValue()}
                onChange={handleOnChange}
            />
        );
}
