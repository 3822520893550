import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CreateMembershipsFreeze, UpdateMembershipsFreeze} from "../memberships_apis";
import moment from "moment";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {DateField} from "../../../../components/fields/DateField";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {DisabledDateField} from "../../../../components/fields/DisabledDateField";
import Permissions from "../../../../auth/permissions";
import {permissions_data} from "../../../../auth/permission_data";


export const FreezeModal = props => {
    const [freeze, setFreeze] = useState({...props.value, membership: props.membership})
    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(false)

    const handleFieldChange = (name, value) => {
        setFreeze({...freeze, [name]: value})
    }

    const handleDateChange = async (id, date) => {
        let data = {...freeze, [id]: moment(date).format("YYYY-MM-DD")}

        if (id === 'start_date' && moment(date).isAfter(freeze.end_date)) {
            data = {...data, end_date: moment(date).format("YYYY-MM-DD")}
        }

        await setFreeze(data);
    };

    const handleSaveButton = async () => {
        await setIsLoading(true);

        let Response;

        if (freeze.id === undefined) {
            Response = await CreateMembershipsFreeze(freeze)
        } else {
            Response = await UpdateMembershipsFreeze(freeze.id, freeze)
        }

        if (Response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(Response.error_feedback);
        }

        await setIsLoading(false);
    };

    return (

        <CustomModal
            open={props.formOpen}
        >
            <Grid container spacing={2}>

                <FormHeader
                    title="pages.membershipsinfo.freeze_form.header"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12} md={6}>
                        <Hide hide={freeze.is_location_freeze}>
                            <DateField
                                required={true}
                                name="start_date"
                                error={helper_text.start_date !== undefined}
                                helper_text={helper_text.start_date}
                                label="start_date"
                                value={freeze.start_date}
                                onChangeHandle={handleDateChange}
                            />
                        </Hide>

                        <Hide hide={!freeze.is_location_freeze}>
                            <DisabledDateField
                                label="start_date"
                                value={freeze.start_date}
                            />
                        </Hide>

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DateField
                            required={true}
                            name="end_date"
                            error={helper_text.end_date !== undefined}
                            helper_text={helper_text.end_date}
                            label="end_date"
                            min_date={freeze.start_date}
                            value={freeze.end_date}
                            onChangeHandle={handleDateChange}
                        />
                    </Grid>

                    <Hide hide={freeze.is_location_freeze || !Permissions.checkPermission(permissions_data.membership_freeze.out_of_balance_freeze)}>
                        <Grid item xs={12}>
                            <CustomCheckBox
                                value={freeze.out_of_balance}
                                name='out_of_balance'
                                onChangeHandle={handleFieldChange}
                                label="out_of_balance"
                            />
                    </Grid>
                    </Hide>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveButton}
                    />
                </Hide>

            </Grid>
        </CustomModal>
    );
}