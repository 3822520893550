import {
    BaseFinanceExpenseAttachmentURL,
    BaseFinanceExpenseCategoryURL,
    BaseFinanceExpensePaymentURL,
    BaseFinanceExpenseURL
} from "../../../urls";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {not_validate_value} from "../../../App";

export const RetrieveExpense = async (id) => {
    let url = BaseFinanceExpenseURL + id + "/";

    return await httpRequestWithAuth(url, "GET")
};


export const RetrieveExpenseCategory = async (id) => {
    let url = BaseFinanceExpenseCategoryURL + id + "/";

    return await httpRequestWithAuth(url, "GET")
};



export const ListExpenseCategory = async (is_active=null) => {
    return await httpRequestWithAuth(BaseFinanceExpenseCategoryURL, "GET", null, {is_active})
};


export const CreateExpenseCategory = async (data) => {
    return await httpRequestWithAuth(BaseFinanceExpenseCategoryURL, "POST", data)
};


export const DeleteExpenseCategory = async (id) => {
    let url = BaseFinanceExpenseCategoryURL + id + "/";

    return await httpRequestWithAuth(url, "DELETE")
};


export const UpdateExpenseCategory = async (id, data) => {
    let url = BaseFinanceExpenseCategoryURL + id + "/";

    return await httpRequestWithAuth(url, "PUT", data)
};



export const CreateExpensePayments = async (data) => {
    return await httpRequestWithAuth(BaseFinanceExpensePaymentURL, "POST", data)
};


export const ListExpenseAttachments = async (expense=null) => {
    return await httpRequestWithAuth(BaseFinanceExpenseAttachmentURL, "GET", null, {expense})
};


export const CreateExpenseAttachments = async (data) => {
    let url = BaseFinanceExpenseAttachmentURL;

    var bodyFormData = new FormData();
    bodyFormData.set("expense", data.expense);
    if (!not_validate_value.includes(data.name)){
    bodyFormData.append("name", data.name);
    }
    if (!not_validate_value.includes(data.file)){
         bodyFormData.append("file", data.file);
    }

    return await httpRequestWithAuth(url, "POST", bodyFormData)
};


export const UpdateExpenseAttachments = async (id, data) => {
    let url = BaseFinanceExpenseAttachmentURL + id + "/";

    var bodyFormData = new FormData();
    if (!not_validate_value.includes(data.expense)) {
        bodyFormData.set("expense", data.expense);
    }
    if (!not_validate_value.includes(data.name)){
        bodyFormData.append("name", data.name);
    }
    if (!not_validate_value.includes(data.file)){
        bodyFormData.append("file", data.file);
    }

    return await httpRequestWithAuth(url, "PATCH", bodyFormData)
};


export const DeleteExpenseAttachments = async (id) => {
    let url = BaseFinanceExpenseAttachmentURL + id + "/";

    return await httpRequestWithAuth(url, "DELETE")
};

