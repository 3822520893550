import React from "react";
import {CustomModal} from "../../../../components/CustomModal";
import {StaffAccessPermissions} from "./StaffAccessPermissions";
import {FormHeader} from "../../../../components/FormHeader";
import Grid from "@material-ui/core/Grid";

export const StaffAccessPermissionsModal = props => {
    return(
        <CustomModal open={props.open}>
            <Grid container spacing={3}>

            <FormHeader
                title="access_permissions"
                handleClose={props.handleModalClose}
            />

            <Grid item xs={12} className="pb-5">
            <StaffAccessPermissions staff_id={props.staff_id}/>
            </Grid>
            </Grid>
        </CustomModal>
    )

}