import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {getLabel} from "../../../../localization/MainLocalization";
import MaterialTable from "material-table";
import {BaseGroupAthletesGroupClassesNumberURL} from "../../../../urls";
import moment from "moment";
import {DateField} from "../../../../components/fields/DateField";
import {not_validate_value, sub_directory} from "../../../../App";
import {GroupsAutoComplete} from "../../../../components/fields/GroupsAutoComplete";
import {Grid} from "@material-ui/core";
import Permissions from "../../../../auth/permissions";
import {permissions_data} from "../../../../auth/permission_data";
import Button from "@material-ui/core/Button";


export const GroupClassesNumber = props => {
    const [is_loading, setIsLoading] = useState(false);
    const [data, setData] = useState({total_classes: 0, athletes: []});

    const columns = [
        {title: getLabel("athlete_name"), field: 'athlete__name'},
        {title: getLabel("optional_attended_classes"), field: 'optional_attended_classes'},
        {title: getLabel("attendance_percentage"), render:rowData => <label>{(100.0 * rowData.required_attended_classes/data.total_classes).toFixed(2)}%</label>},
        {title: getLabel("required_attended_classes"), render: rowData=>(
                <label className="mt-1 mb-1 mr-2 ml-2">
                    {`${rowData.required_attended_classes} of ${data.total_classes}`}
                </label>
            )},
        {render:rowData=>{return(
                <div className="d-inline-flex">
                    {Permissions.checkAllPermissions([permissions_data.athlete.view]) && (
                        <Button className="mr-1 ml-1" size="small" variant="outlined"
                                onClick={()=> props.history.push(
                                    {
                                        pathname: sub_directory + "athletes/edit",
                                        state: {
                                            id: rowData.athlete__id,
                                            tag: rowData.athlete__name
                                        }
                                    }
                                )}>
                            {getLabel("view_athlete_profile")}
                        </Button>)}

                </div>
            )}}
    ];

    const [setting, setSetting] = useState({
        team: "",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD")
    });

    const handleDateChange = async (name, value) => {
        if (not_validate_value.includes(value)) {
            value = moment().format("YYYY-MM-DD")
        }
        await setSetting({...setting, [name]: value})
    };

    const handleFieldChange = async (name, value) => {
        await setSetting({...setting, [name]: value});
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!not_validate_value.includes(setting.team) && !not_validate_value.includes(setting.from_date) && !not_validate_value.includes(setting.to_date)) {
                await setIsLoading(true);
                let response = await httpRequestWithAuth(BaseGroupAthletesGroupClassesNumberURL, "GET", null, {
                    team: setting.team,
                    from_date: setting.from_date,
                    to_date: setting.to_date
                });
                if (response.status) {
                    await setData(response.data)
                }
                await setIsLoading(false);
            }
        }

        fetchData();
    }, [setting.from_date, setting.to_date, setting.team])

    return (
        <div className='card-paper-1 h-100 w-100'>
            <Typography variant="h6" className="text-start mb-2">
                Team Classes
            </Typography>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <GroupsAutoComplete
                        groupedBy
                        groupFullName
                        name="team"
                        label="team"
                        value={setting.team}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DateField
                        label="from_date"
                        name="from_date"
                        maxDate={moment()}
                        value={setting.from_date}
                        onChangeHandle={handleDateChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DateField
                        label="to_date"
                        name="to_date"
                        min_date={setting.from_date}
                        maxDate={moment()}
                        value={setting.to_date}
                        onChangeHandle={handleDateChange}
                    />
                </Grid>
            </Grid>


            <Typography component="p" variant="h5" className="text-end mt-2">
                {`Total classes: ${data.total_classes}`}
            </Typography>

            <MaterialTable
                columns={columns}
                data={data.athletes}
                isLoading={is_loading}
                style={{
                    boxShadow: "none", overflowY: "auto",
                    overflowX: 'auto', height: 'auto',
                    maxHeight: "400px"
                }}

                options={{
                    search: false,
                    paging: false,
                    toolbar: false,
                }}
            />
        </div>
    );
}
