import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseClassSessionsListURL} from "../../urls";
import {not_validate_value} from "../../App";

export const ClassSessionsAutoComplete = props =>{
    const [options, setOptions] = useState([])

    useEffect(()=>{
        const fetchData = async () =>{
            let params = {}
            if (props.byDate){
                params.date = props.date || "None"
            }

            if (props.byTrainingArea){
                params.training_area = props.training_area || "None"
            }

            if (!not_validate_value.includes(props.location)){
                params.location = props.location
            }

            if (!not_validate_value.includes(props.service)){
                params.service = props.service
            }

            if (!not_validate_value.includes(props.day)){
                params.day = props.day
            }

            let response = await httpRequestWithAuth(BaseClassSessionsListURL, "GET", null, params)

            if (response.status){
                setOptions(response.data)
            }
        }

        fetchData();
    }, [props.service, props.day, props.location, props.training_area, props.date])

    const handleOnChange = (event, value) => {
        if (!props.viewOnly){
        let newValue = ""
        if (props.multiple){
            newValue = []
            value.map(Data=>{
                newValue.push(Data.id)
            })
        }else{
            newValue = value !== null ? props.fullObject?value: value.id : "";
        }

        props.onChangeHandle(props.name, newValue);
        }
    };

    const handleAutoCompleteValue = () => {
        let value = props.multiple ? [] : null ;

        if (props.multiple){
            options.map(Data => {
                if ((props.value || []).includes(Data.id)) {
                    value.push(Data);
                }
                return 0;
            });
        }else{
            options.map(Data => {
                if (Data.id === props.value) {
                    value = Data;
                }
                return 0;
            });
        }


        return value;
    };


        return (
            <Autocomplete
                ListboxProps={{className:"list-box-autocomplete"}}
                renderOption={(option) => (
                    <span className="w-100 text-start">{`${option.name} - ${option.start_time_view} : ${option.end_time_view}`}</span>
                )}
                disabled={props.disabled || props.viewOnly}
                options={options}
                multiple={props.multiple}
                getOptionLabel={option => `${option.name} - ${option.start_time_view} : ${option.end_time_view}`}
                renderInput={params => (
                    <TextField variant="outlined" {...params}
                               label={<label>{getLabel(props.label)}<span
                                   className='text-danger'>{props.required ? "*" : ""}</span></label>}
                               helperText={props.helper_text}
                               error={props.error}
                               fullWidth
                               className={props.disabled ? "disabled-field" : ""}
                    />
                )}
                value={handleAutoCompleteValue()}
                onChange={handleOnChange}
            />
        );
}
