import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {not_validate_value} from "../App";


export const Loading = props => {
    return (
        <div className="w-100 text-center mt-4 pt-4 pb-3 mb-3">
            <Typography variant="h4" className="pb-5 pt-3">{not_validate_value.includes(props.msg)?"Loading...":props.msg}</Typography>
            <CircularProgress size={100} />
        </div>
    );
};