import {
    BaseEquipmentFilterURL,
    BaseEquipmentLocationsURL, BaseEquipmentPriceListURL,
    BaseEquipmentURL
} from "../../../urls";
import {instance} from "../../../auth/axios_instance";


export const ListEquipment = async (search = null) => {
    let url = BaseEquipmentFilterURL;

    if (search !== null) {
        url = url + "?search=" + search
    }

    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });

    return feedback
};


export const CreateEquipment = async (data) => {
    let url = BaseEquipmentURL;
    const feedback = await instance.post(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const UpdateEquipment = async (equipment = null, data = null) => {
    let url = BaseEquipmentURL + equipment + "/";
    const feedback = await instance.patch(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const DeleteEquipment = async (equipment = null) => {
    let url = BaseEquipmentURL + equipment + "/";
    const feedback = await instance.delete(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};



export const ListEquipmentLocations = async (equipment = null, branch = null) => {
    let url = BaseEquipmentLocationsURL;

    if (equipment != null){
        url += "?equipment=" + equipment
    }
    if (branch != null){
        if(equipment != null){
            url += "&"
        }else{
            url += "?"
        }
        url += "branch=" + branch
    }
    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const CreateEquipmentLocations = async (data = null) => {
    let url = BaseEquipmentLocationsURL ;
    const feedback = await instance.post(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const UpdateEquipmentLocations = async (equipment = null, data = null) => {
    let url = BaseEquipmentLocationsURL + equipment + "/";
    const feedback = await instance.patch(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};



export const ListPriceListEquipment = async (package_id = null) => {
    let url = BaseEquipmentPriceListURL;

    if (package_id !== null) {
        url = url + "?package=" + package_id
    }

    const feedback = await instance.get(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });

    return feedback
};


export const CreatePriceListEquipment = async (data) => {
    let url = BaseEquipmentPriceListURL;
    const feedback = await instance.post(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const UpdatePriceListEquipment = async (package_equipment = null, data = null) => {
    let url = BaseEquipmentPriceListURL + package_equipment + "/";
    const feedback = await instance.patch(url, data).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status, error_feedback: error.response.data}
    });
    return feedback
};


export const DeletePriceListEquipment = async (package_equipment = null) => {
    let url = BaseEquipmentPriceListURL + package_equipment + "/";
    const feedback = await instance.delete(url).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};
