import React from "react";
import Typography from "@material-ui/core/Typography";
import {not_validate_value} from "../App";
import {getLabel} from "../localization/MainLocalization";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

export const CustomHeader = props =>{
    return(
        <>

            <Grid item xs={12}>
            <Typography variant="h6" className="text-start">
                {getLabel(props.title)}
            {!not_validate_value.includes(props.warning) && (
            <Chip color="secondary" className="margin-left-3" label={getLabel(props.warning)}/>
            )}
            </Typography>


        </Grid>
            </>
    );
}