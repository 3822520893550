import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {not_validate_value} from "../../App";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseFilterLocationsURL} from "../../urls";

export const LocationsAutoComplete = props => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            let params = {}
            if (!not_validate_value.includes(props.exclude_locations)) {
                params.exclude_locations = props.exclude_locations.join(",")
            }

            if (!not_validate_value.includes(props.location)) {
                params.location = props.location
            }

            if (!not_validate_value.includes(props.active)) {
                params.is_active = props.active
            }

            let response = await httpRequestWithAuth(BaseFilterLocationsURL, "GET", null, params);
            let locations = [];
            if (response.status) {
                await response.data.map(Location => {
                    locations.push({
                        value: Location.id,
                        title: Location.name,
                        male: Location.for_males,
                        female: Location.for_females,
                    })
                });

                if (props.allLocationOption) {
                    locations.push({
                        value: "",
                        title: getLabel("all_locations"),
                        male: true,
                        female: true,
                    })
                }
                // if (branches.length === 1 && props.value === undefined) {
                //     if (props.multiple) {
                //         props.onChangeHandle(props.name, branches)
                //     } else {
                //         props.onChangeHandle(props.name, branches[0].value)
                //     }
                // }
                if (props.selectFirstByDefault && locations.length > 0){
                    handleOnChange("event", locations[0]);
                }

                await setOptions(locations)
            }
        }

        fetchData();
    }, [])


    const handleOnChange = (event, value) => {
        if (!props.viewOnly) {
            let males = false;
            let females = false;
            if (!props.multiple && value !== null) {
                males = value.male;
                females = value.female;
                value = value.value;
            }

            value = value !== null ? value : "";
            if (props.multiple) {
                if (not_validate_value.includes(value)) {
                    value = [];
                } else {
                    let temp_value = [];
                    value.map(Data => temp_value.push(Data.value));
                    value = temp_value;
                }
            }

            if (props.getLocationGender) {
                props.onChangeHandle(props.name, value, males, females);
            } else {
                props.onChangeHandle(props.name, value);
            }

        }
    };

    const handleAutoCompleteValue = () => {
        let value = null;
        if (props.multiple === true) {
            value = [];
            options.map(Location => {
                if ((props.value || []).indexOf(Location.value) > -1) {
                    value.push(Location);
                }
                return 0;

            });
        } else {

            options.map(Data => {
                if (Data.value === props.value) {
                    value = Data;
                }
                return 0;
            });
        }

        return value;
    };


    return (
        <Autocomplete
            ListboxProps={{className: "list-box-autocomplete"}}
            disabled={props.disabled || props.viewOnly}
            disableCloseOnSelect={props.multiple}
            renderOption={(option) => (
                <span className="w-100 text-start">{option.title}</span>
            )}
            // disabled={props.disabled || props.viewOnly}
            multiple={props.multiple}
            filterOptions={options => {
                return options.filter(option => {
                    return (props.for === "Male" && option.male) ||
                        (props.for === "Female" && option.female) ||
                        (not_validate_value.includes(props.for))
                })
            }}
            options={options}
            getOptionLabel={option => option.title}
            renderInput={params => (
                <TextField variant="outlined" {...params}
                           label={<label>{getLabel(props.label)}<span
                               className='text-danger'>{props.required ? "*" : ""}</span></label>}
                           helperText={props.helper_text}
                           error={props.error}
                           fullWidth
                           disabled={props.disabled}
                           className={props.disabled ? "disabled-field" : ""}
                />
            )}
            getOptionSelected={(option, value) => {
                return value.value === option.value
            }}
            value={handleAutoCompleteValue()}
            onChange={handleOnChange}
        />
    );
}
