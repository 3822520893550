import React from "react";
import {dir} from "../App";

export const FlipIconRTL = props => {
    if(dir === "rtl"){
        return(
            <div className="icon-flip">
                {props.children}
            </div>
        );
    }else {
        return props.children
    }
}