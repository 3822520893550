import React from "react";
import {FormHeader} from "../../../../components/FormHeader";
import {CustomModal} from "../../../../components/CustomModal";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";

export const BulkActionErrorTable = props =>{

    return(
        <CustomModal
            open={props.open}
            className="w-75"
        >
            <Grid container spacing={2}>

                <FormHeader
                    title="bulk_action_error"
                    handleClose={props.handleModalClose}
                />

                <Grid item xs={12}>
                <MaterialTable
                    style={{padding:0.5, boxShadow:'none'}}
                    columns={props.columns}
                    data={props.data}
                    options={{
                        showTitle: false,
                        searchFieldAlignment: 'left'
                    }}
                />
                </Grid>

            </Grid>
        </CustomModal>
    )
}
