import React, {useRef, useState} from 'react';
import Permissions from "../../../auth/permissions";
import MaterialTable from "material-table";
import {instance} from "../../../auth/axios_instance";
import {BaseFinanceBankAccountURL} from "../../../urls";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";
import {permissions_data} from "../../../auth/permission_data";
import {sub_directory} from "../../../App";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export const BankAccount = props => {
        const tableRef = useRef();
        const [search , setSearch] = useState("");

            const [columns, setColumns] = useState([
                {title: getLabel("name"), render: rowData=>(
                        <Typography>
                            <Link href="" onClick={e => {
                                e.preventDefault();
                                viewBankAccount(rowData);
                            }}>
                                {rowData.title}
                            </Link>
                        </Typography>
                    ), cellStyle: {width: "25%"}},
                {title: getLabel("action"), field: 'action'},
                {title: getLabel("amount"), field: 'amount'},
                {title: getLabel("commission"), field: 'commission'},
                {title: getLabel("payment_method"), field: 'payment_method_view'},
                {title: getLabel("date"), field: 'date_view'},
                {title: getLabel("created_by"), field: 'created_by_name'},
            ]);


    const viewBankAccount = (rowData) => {
        props.history.push( {
            pathname: `${sub_directory}bank-account/edit`,
            state: {
                type: rowData.action === "Withdraw"? "add_withdraw":
                    rowData.from_cash_log? "add_cash_deposit":"add_funds",
                id: rowData.id
            }
        })
    };


    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleSearchField = async (name, newValue) => {
        await setSearch(newValue)

        if ((newValue.length >= 5 && /^\d+$/.test(newValue)) || newValue === '') {
            await reloadData();
        }
    };

        return (
            <>
                <Paper>
                    <TableToolbar
                        searchFieldName='search'
                        searchValue={search}
                        onSearchChangeHandle={handleSearchField}
                        searchAction={reloadData}
                        match={props.match}
                        actions={[
                            {
                                hide: !Permissions.checkAllPermissions([permissions_data.bank_account.add]),
                                onClick: () => props.history.push(
                                    {
                                        pathname: `${sub_directory}bank-account/new`,
                                        state: {
                                            type: "add_cash_deposit",
                                            id: "new"
                                        }
                                    }
                                ),
                                label: "add_cash_deposit"
                            },

                            {
                                hide: !Permissions.checkAllPermissions([permissions_data.bank_account.add]),
                                onClick: () => props.history.push(
                                    {
                                        pathname: `${sub_directory}bank-account/new`,
                                        state: {
                                            type: "add_funds",
                                            id: "new"
                                        }
                                    }
                                ),
                                label: "add_funds"
                            },
                            {
                                hide: !Permissions.checkAllPermissions([permissions_data.bank_account.add]),
                                onClick: () => props.history.push(
                                    {
                                        pathname: `${sub_directory}bank-account/new`,
                                        state: {
                                            type: "add_withdraw",
                                            id: "new"
                                        }
                                    }
                                ),
                                label: "add_withdraw"
                            },
                        ]
                        }
                    />

                    <MaterialTable
                        tableRef={tableRef}
                        columns={columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let url = BaseFinanceBankAccountURL;

                                url += '?page=' + (query.page + 1);

                                if (search !== '') {
                                    url += '&search=' + search;
                                }

                                instance.get(url)
                                    .then(result => {
                                        resolve({
                                            data: result.data.results,
                                            page: result.data.page - 1,
                                            totalCount: result.data.total,
                                        })
                                    })
                            })
                        }


                        {...material_table_style}
                        {...material_table_options()}
                        {...material_table_localization()}

                    />

                </Paper>
            </>
        );
}
