import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../assets/scss/Static.scss';
import {FormHeader} from "../../../../components/FormHeader";
import {CustomModal} from "../../../../components/CustomModal";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {CustomTextArea} from "../../../../components/fields/CustomTextArea";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseFinanceClaimRequestURL} from "../../../../urls";
import {ErrorBox} from "../../../../components/ErrorBox";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import {getLabel} from "../../../../localization/MainLocalization";


export const RejectOrReturnClaimFormModal = props => {
    const [helper_text, setHelperText] = useState({});
    const [data, setData] = useState({});
    const [is_loading, setIsLoading] = useState(false);
    const claim = props.claim || {};

    const handleFieldsChange = (name, value) => {
        setData({...data, [name]: value})
    }

    const handleActionBtn = async (status) =>{
        await setIsLoading(true);
        await setHelperText({})
        let response = await httpRequestWithAuth(`${BaseFinanceClaimRequestURL}${claim.id}/`, "PATCH", {...data, status});
        if (response.status){
            await props.handleModalClose();
        }else{
            await setHelperText(response.error_feedback)
        }
        await setIsLoading(false);
    }

    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="reject_or_return_claim"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>


                            <Grid item xs={12} className="mt-1">
                                <CustomTextArea
                                    label="notes"
                                    error={helper_text.notes !== undefined}
                                    helper_text={helper_text.notes}
                                    name="notes"
                                    rows={5}
                                    value={data.notes || ""}
                                    onChangeHandle={handleFieldsChange}
                                />
                            </Grid>


                        <ErrorBox
                            errorMsg={helper_text.non_field_errors}
                        />

                        <Grid container className='mt-1 pt-1 d-inline-flex'>

                            <Button
                                onClick={props.handleModalClose}
                                variant="contained"
                                color='default'
                                size="small"
                                className="m-1"
                                startIcon={<CancelIcon/>}
                                component={Grid}
                                item
                                xs={12}
                                md={3}
                            >
                                {getLabel("cancel")}
                            </Button>

                            <div className="mr-auto"/>

                            <Button
                                onClick={()=>handleActionBtn("Returned")}
                                variant="contained"
                                color='secondary'
                                size="small"
                                className="m-1"
                                component={Grid}
                                item
                                xs={12}
                                md={3}
                            >
                                {getLabel("return")}
                            </Button>

                            <Button
                                onClick={()=>handleActionBtn("Rejected")}
                                variant="contained"
                                color='secondary'
                                size="small"
                                className="m-1"
                                component={Grid}
                                item
                                xs={12}
                                md={3}
                            >
                                {getLabel("reject")}
                            </Button>
                        </Grid>

                    </Hide>
                </Grid>

            </CustomModal>
        </>
    );
};