import React, {useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {
    CreateExpenseAttachments,
    DeleteExpenseAttachments,
    ListExpenseAttachments, UpdateExpenseAttachments,
} from "./expenses_apis";
import {Hide} from "../../../components/Hide";
import {ExpenseAttachmentFormModal} from "./components/ExpenseAttachmentFormModal";
import {permissions_data} from "../../../auth/permission_data";
import {sub_directory} from "../../../App";
import {ActionButtons} from "../../../components/ActionButton";


export const ExpenseAttachment = props => {
        const expense_id = props.expense_id;

            const columns =[
                {title: getLabel("name"), field: 'name'},
            ];
    const [attachments, setAttachments] = useState([])
    const [helper_text, setHelperText] = useState({})
    const [form_open, setFormOpen] = useState(false)
    const [form_data, setFormData] = useState({})
    const [is_loading, setIsLoading] = useState({})
    const [edit_attach, setEditAttach] = useState(true)


    const ListAttachmentsData = async () => {
       await setIsLoading(true)

        let ListPaymentsResponse = await ListExpenseAttachments(expense_id);
        if (ListPaymentsResponse.status) {
           await setAttachments(ListPaymentsResponse.data)
        }

        await setIsLoading(false)
    };

    useEffect(()=>{
        ListAttachmentsData();
    },[])

    const onChangeHandle = (name, value) =>{
        setFormData({...form_data, [name]: value});
    }

    const handleModalOpen = (data, edit) => {
        setEditAttach(edit)
        setFormData(data)
        setFormOpen(true)
    };

    const handleModalClose = async () => {
        await ListAttachmentsData();
        await setHelperText({});
        setFormData({})
        setFormOpen(false)
    };


    const handleBackButton = () => {
        props.history.push(sub_directory + "expenses")
    }

    const handleSaveModal = async () => {
        await setIsLoading(true)
        let response ;
        if (form_data.id === undefined){
             response = await CreateExpenseAttachments({expense:expense_id, ...form_data});

        }else{
             response = await UpdateExpenseAttachments(form_data.id, {name:form_data.name});
        }

        if (response.status){
            await handleModalClose();
        }else {
            setHelperText(response.error_feedback)
        }

        await setIsLoading(false)
    };


        return(
            <>
                    <Hide hide={!form_open}>
                        <ExpenseAttachmentFormModal
                        open={form_open}
                        handleModalClose={handleModalClose}
                        data={form_data}
                        handleSaveForm={handleSaveModal}
                        helper_text={helper_text}
                        onChangeHandle={onChangeHandle}
                        is_loading={is_loading}
                        edit_attach={edit_attach}
                        />
                    </Hide>

                <Grid item xs={12}>
                    <MaterialTable
                        style={{padding: '0.5rem', boxShadow: "none"}}
                        is_loading={is_loading}
                        columns={columns}
                        data={attachments}
                        onRowClick={(event, rowData) => window.open(rowData.file)}
                        editable={Permissions.checkPermission(permissions_data.expense_attachment.delete) && !props.is_closed && {
                                onRowDelete: async attachmentData => {
                                    await DeleteExpenseAttachments(attachmentData.id);
                                    await ListAttachmentsData();
                                }
                        }}

                        actions={[
                            {
                            icon:()=> (
                                    <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                            startIcon={<AddIcon/>}>
                                        <Typography variant="body1">{getLabel('add_attachment')}</Typography>
                                    </Button>
                                ),
                                hidden: !Permissions.checkAllPermissions([permissions_data.expense_attachment.add]) || props.is_closed,
                                isFreeAction: true,
                                onClick: () => handleModalOpen({},true)
                            },
                            {
                                icon: () => (
                                    <Button  size="small"
                                             color="primary"
                                             variant="outlined">
                                        {getLabel("view_file")}
                                    </Button>
                                ),
                                tooltip:getLabel('view_file'),
                                onClick: (event, rowData) => window.open(rowData.file)
                            },
                            {
                                icon: 'edit',
                                tooltip:getLabel('edit_attachment'),
                                hidden: !Permissions.checkAllPermissions([permissions_data.expense_attachment.change]) || props.is_closed,
                                onClick: (event, rowData) => handleModalOpen(rowData,false)
                            }
                        ]}

                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false,
                            showTitle: false
                        }}

                        {...material_table_localization()}
                    />
                </Grid>


                <ActionButtons
                    allowBack={true}
                    backAction={handleBackButton}
                />
                </>
        );

}