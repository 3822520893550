import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../assets/scss/Static.scss';
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CustomModal} from "../../../../components/CustomModal";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {CreateExpenseCategory, UpdateExpenseCategory} from "../expenses_apis";
import {Loading} from "../../../../components/Loading";
import {Hide} from "../../../../components/Hide";

export const ExpenseCategoryFormModal = props => {
    const [helper_text, setHelperText] = useState({})
    const [data, setData] = useState({is_active:true, ...props.default_data})
    const [is_loading, setIsLoading] = useState(false)

    const handleFieldChange = (name, newValue) => {
        setData({...data, [name]: newValue})
    };

    const handleSaveForm = async () => {
        await setIsLoading(true);
        let response;
        if (data.id === undefined) {
            response = await CreateExpenseCategory(data);
        } else {
            response = await UpdateExpenseCategory(data.id, data);
        }

        if (response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="expense_category"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>
                        <Grid item xs={12}>
                            <CustomTextField
                                required={true}
                                error={helper_text.name !== undefined}
                                helper_text={helper_text.name}
                                name="name"
                                label="name"
                                value={data.name}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <CustomCheckBox
                                value={data.is_active}
                                name='is_active'
                                onChangeHandle={handleFieldChange}
                                label="is_active"
                            />
                        </Grid>

                        <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={props.handleModalClose}
                            handleSaveButton={handleSaveForm}
                        />
                    </Hide>

                </Grid>
            </CustomModal>
        </>
    );
};