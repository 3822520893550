import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormActionButtons} from "./FormActionButton";
import {FormHeader} from "./FormHeader";
import "../assets/scss/Static.scss";
import {CustomModal} from "./CustomModal";
import {PaymentMethods} from "./PaymentMethods";
import {StaffAutoComplete} from "./fields/StaffAutoComplete";
import {Hide} from "./Hide";
import {Loading} from "./Loading";
import moment from "moment";

export const PaymentModal = props => {
    const [new_payment, setNewPayment] = useState({
        [props.name]: props.id,
        payment_type: props.payment_type,
        payment_date:moment().format("YYYY-MM-DD"),
    })
    const [helper_text, setHelperText] = useState({})
    const [total_paid, setTotalPaid] = useState(0)
    const [error, setError] = useState(false)
    const [is_loading, setIsLoading] = useState(false)


    const handlePaymentFormSaveButton = async () => {
        await setIsLoading(true);

        let response = await props.CreatePayment(new_payment);
        if (response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    const handleNewPaymentFieldChange = (name, newValue) => {
        setTotalPaid(parseFloat(total_paid) + (parseInt(newValue === '' ? 0 : newValue) - parseFloat(new_payment[name] === '' ? 0 : new_payment[name])))
        setNewPayment({...new_payment, [name]: newValue})
    };


    return (
        <CustomModal
            open={props.formOpen}
        >
            <Grid container spacing={2}>

                <FormHeader
                    title="Payment_form.header"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12}>
                        <PaymentMethods
                            staticMethodOptions={props.staticMethodOptions}
                            assigned_to={props.assigned_to}
                            required={true}
                            new_payment={new_payment}
                            helper_text={helper_text}
                            handleNewPaymentFieldChange={handleNewPaymentFieldChange}
                        />
                    </Grid>

                    <Hide hide={props.name !== "reimbursement" || new_payment.payment_method !== "Cash"}>
                        <Grid item xs={12}>
                            <StaffAutoComplete
                                name="staff"
                                label="staff"
                                onChangeHandle={handleNewPaymentFieldChange}
                                error={helper_text.staff !== undefined}
                                helper_text={helper_text.staff}
                                value={new_payment.staff}
                            />
                        </Grid>
                    </Hide>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handlePaymentFormSaveButton}
                    />
                </Hide>

            </Grid>
        </CustomModal>

    );

}


