import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {CustomBreadcrumbs} from "../../../components/CustomBreadcrumbs";
import {ExpenseForm} from "./ExpenseForm";
import {ExpensePayments} from "./ExpensePayments";
import {ExpenseAttachment} from "./ExpenseAttachment";
import {sub_directory} from "../../../App";
import {CustomTabs} from "../../../components/CustomTabs";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";


export const ExpenseInfo = props => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [is_closed, setIsClosed] = React.useState(false);
    const [expense_id, setExpenseId] = React.useState("new");
    const state = props.history.location.state || {};

    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };

    const setIsClosedValue = (newValue) => {
        setIsClosed(newValue)
    };


    const moveToNextTab = () =>{
        setCurrentTab(currentTab + 1)
    }

    useEffect(()=>{
        if (state.id){
            setExpenseId(state.id);
        }else{
            props.history.push(`${sub_directory}expenses`);
        }

        if (state.moveToNextTab){
            moveToNextTab();
        }
    },[])

    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match} details={{tag: state.tag}}/>*/}

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >
                <Tab label={getLabel("details")} id={0}/>

                <Tab label={getLabel("payments")} id={1} disabled={expense_id === 'new' || !Permissions.checkPermission(permissions_data.expense_payment.view)}/>

                <Tab label={getLabel("attachment")} id={2} disabled={expense_id === 'new' || !Permissions.checkPermission(permissions_data.expense_attachment.view)}/>

            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden' spacing={2}>

                <Hide hide={currentTab !== 0}>
                    <ExpenseForm
                        {...props}
                        expense_id={expense_id}
                        is_closed={is_closed}
                        setIsClosedValue={setIsClosedValue}
                        moveToNextTab={moveToNextTab}
                    />
                </Hide>

                <Hide hide={currentTab !== 1}>
                    <ExpensePayments
                        {...props}
                        expense_id={expense_id}
                        is_closed={is_closed}
                    />
                </Hide>

                <Hide hide={currentTab !== 2}>
                    <ExpenseAttachment
                        {...props}
                        expense_id={expense_id}
                        is_closed={is_closed}
                    />
                </Hide>

            </Grid>
        </Paper>
    );
}