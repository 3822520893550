import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseSeasonGroupListAllURL} from "../../urls";
import moment from "moment";
import {not_validate_value} from "../../App";

export const GroupsMultipleAutoComplete = props => {
    const [options, setOptions] = useState([])
    const [view_name] = useState(props.groupFullName ? "group_full_name" : "name")

    useEffect(() => {
        const fetchData = async () => {
            let param = {}

            if (!not_validate_value.includes(props.activeGroup)){
                param['is_active'] = props.activeGroup
            }

            if (!not_validate_value.includes(props.activeSeason)){
                param['active_season'] = props.activeSeason
            }

            if (!not_validate_value.includes(props.activeOnDate)){
                param['active_season_on'] = moment(props.activeOnDate).format("YYYY-MM-DD")
            }

            if (!not_validate_value.includes(props.activeInDateRange) && !not_validate_value.includes(props.activeInDateRange.from_date) && !not_validate_value.includes(props.activeInDateRange.to_date)){
                param['active_season_on_date_range'] = moment(props.activeInDateRange.from_date).format("YYYY-MM-DD") + ":"
                    + moment(props.activeInDateRange.to_date).format("YYYY-MM-DD")
            }


            let response = await httpRequestWithAuth(BaseSeasonGroupListAllURL, "GET", null, param);
            if (response.status) {
                let temp_option = props.defaultOptions || []
                let options_id = []

                temp_option.map(Group=>options_id.push(Group.id))

                response.data.map(Group=>{
                    if (!options_id.includes(Group.id)){
                        temp_option.push(Group)
                    }
                })
                getGroupDetails(temp_option);
                setOptions(temp_option);
            } else {
                setOptions([])
            }
        }

        fetchData();
    }, [props.activeOnDate, (props.activeInDateRange||{}).from_date, (props.activeInDateRange||{}).to_date])

    const handleOnChange = (event, value) => {
        if (!props.viewOnly) {
            let new_value = []
            value = (value || []);
            if (props.getGroupDetails) {
                props.getGroupDetails(props.name, value)
            }
            value.map(Data => new_value.push(Data.id))
            props.onChangeHandle(props.name, new_value);
        }
    };

    const handleAutoCompleteValue = () => {
        let value = [];

        options.map(Data => {
            if ((props.value || []).includes(Data.id)) {
                value.push(Data);
            }
            return 0;
        });

        return value;
    };

    const getGroupDetails = (temp_options)=>{
        if (props.getGroupDetails){
            let value = [];

            temp_options.map(Data => {
                if ((props.value || []).includes(Data.id)) {
                    value.push(Data);
                }
                return 0;
            });

            props.getGroupDetails(props.name, value);
        }
    }

    return (
        <Autocomplete
            ListboxProps={{className: "list-box-autocomplete"}}
            renderOption={(option) => (
                <span className="w-100 text-start">{`${option[view_name]}`}</span>
            )}

            multiple
            disableCloseOnSelect
            filterOptions={(options, state) => {
                return options.filter(option => {
                    return !(props.exclude || []).includes(option.id) && (not_validate_value.includes(state.inputValue) || option.group_full_name.toLowerCase().includes(state.inputValue.toLowerCase()))
                })
            }}
            groupBy={option => option.season_name}
            disabled={props.disabled || props.viewOnly}
            options={options}
            getOptionLabel={option => `${option[view_name]}`}
            renderInput={params => (
                <TextField variant="outlined" {...params}
                           label={<label>{getLabel(props.label)}<span
                               className='text-danger'>{props.required ? "*" : ""}</span></label>}
                           helperText={props.helper_text}
                           error={props.error}
                           fullWidth
                           size={props.size}
                           disabled={props.disabled}
                           className={props.disabled ? "disabled-field" : ""}
                />
            )}
            value={handleAutoCompleteValue()}
            onChange={handleOnChange}
        />
    );
}
