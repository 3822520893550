import React from "react";
import "../../assets/scss/notfound/not_found_one.scss";
import {Hide} from "../../components/Hide";

export const NotFoundOne = props => (
    <div id="notfound">
        <div className="notfound">
            <div className="notfound-404">
                <h1>Oops!</h1>
                <h2>404 - The Page can't be found</h2>
            </div>

            <Hide hide={props.hideHomeBtn}>
            <a href="/home">Go TO Homepage</a>
            </Hide>

        </div>
    </div>
);
