import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseServicesMonthlyReportsURL} from "../../../../urls";
import moment from "moment";
import {
    ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import {DateField} from "../../../../components/fields/DateField";
import {not_validate_value} from "../../../../App";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";

//
// const renderActiveShape = (props) => {
//     const RADIAN = Math.PI / 180;
//     const {
//         cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
//         fill, payload, percent, value,
//     } = props;
//     const sin = Math.sin(-RADIAN * midAngle);
//     const cos = Math.cos(-RADIAN * midAngle);
//     const sx = cx + (outerRadius + 10) * cos;
//     const sy = cy + (outerRadius + 10) * sin;
//     const mx = cx + (outerRadius + 30) * cos;
//     const my = cy + (outerRadius + 30) * sin;
//     const ex = mx + (cos >= 0 ? 1 : -1) * 22;
//     const ey = my;
//     const textAnchor = cos >= 0 ? 'start' : 'end';
//
//     return (
//         <g>
//             <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
//             <Sector
//                 cx={cx}
//                 cy={cy}
//                 innerRadius={innerRadius}
//                 outerRadius={outerRadius}
//                 startAngle={startAngle}
//                 endAngle={endAngle}
//                 fill={fill}
//             />
//             <Sector
//                 cx={cx}
//                 cy={cy}
//                 startAngle={startAngle}
//                 endAngle={endAngle}
//                 innerRadius={outerRadius + 6}
//                 outerRadius={outerRadius + 10}
//                 fill={fill}
//             />
//             <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
//             <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
//             <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor}
//                   fill="#333">{`Count: ${value}`}</text>
//             <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
//                 {`(Rate ${(percent * 100).toFixed(2)}%)`}
//             </text>
//         </g>
//     );
// };

export const ServiceStatus = props => {
    const [data, setData] = useState([]);
    const [is_loading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(0);

    const [min_date, setMinDate] = useState(moment())
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    // const [active_index, setActiveIndex] = useState(0);

    // const onPieEnter = (data, index) => {
    //     setActiveIndex(index)
    // };

    const handleDateChange = async (name, value) => {
        if (not_validate_value.includes(value)){
            value = moment().format("YYYY-MM-DD")
        }
        await setDate(value)
    };

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);

            let response = await httpRequestWithAuth(BaseServicesMonthlyReportsURL, "GET", null, {date});
            if (response.status) {
                await setMinDate(response.data.min_date)
                await setTotal(response.data.total)
                let temp_data = []
                Object.keys(response.data.details).map(Service=>{
                    temp_data.push({
                        name: Service, memberships: response.data.details[Service]
                    })
                });

                await setData(temp_data);
            }

            await setIsLoading(false);
        }

        fetchData();
    }, [date])

    return (
        <div className='card-paper-1 h-100 w-100'>
            <Typography variant="h6" className="text-start mb-2">
                Services monthly status
            </Typography>


            <DateField
                date_view="monthly"
                label="date"
                name="date"
                min_date={min_date}
                maxDate={moment()}
                value={date}
                onChangeHandle={handleDateChange}
            />

            <Typography component="p" variant="h5" className="text-end mt-3">
                {`Total: ${total}`}
            </Typography>

            <Hide hide={!is_loading}>
                <div className="fixedHeight-400">
                    <Loading/>
                </div>
            </Hide>


            <Hide hide={is_loading || total !== 0}>
                <Typography component="p" variant="h5" className="w-100 h-50y d-flex justify-content-center align-items-center">
                    No memberships
                </Typography>
            </Hide>

            <Hide hide={is_loading || total === 0}>
            <div dir='ltr' className="h-100 w-100">
                <ResponsiveContainer width="100%" height='90%' className="fixedHeight-400">
                        <ComposedChart
                            data={data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            {/*<CartesianGrid stroke="#f5f5f5" />*/}
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />

                            <Bar dataKey="memberships" barSize={20} fill="#3f51b5" />
                        </ComposedChart>
                    {/*<PieChart>*/}
                    {/*    <Pie*/}
                    {/*        margin={{top: 0}}*/}
                    {/*        activeIndex={active_index}*/}
                    {/*        activeShape={renderActiveShape}*/}
                    {/*        data={data}*/}
                    {/*        innerRadius={80}*/}
                    {/*        outerRadius={100}*/}
                    {/*        fill="#3f51b5"*/}
                    {/*        dataKey="value"*/}
                    {/*        onMouseEnter={onPieEnter}*/}
                    {/*    />*/}
                    {/*</PieChart>*/}
                </ResponsiveContainer>
        </div>
            </Hide>

        </div>
    );
}
