import React, {useRef, useState} from "react";
import {TableToolbar} from "../../../components/TableToolbar";
import Paper from "@material-ui/core/Paper";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {getLabel} from "../../../localization/MainLocalization";
import MaterialTable from "material-table";
import {BaseClassRecurringURL} from "../../../urls";
import {instance} from "../../../auth/axios_instance";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {compareTime, sub_directory} from "../../../App";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import {DateField} from "../../../components/fields/DateField";
import {Hide} from "../../../components/Hide";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import {RecurringClassesGenerateModal} from "./components/RecurringClassesGenerateModal";


export const RecurringClasses = props => {
    const tableRef = useRef();
    const [search, setSearch] = useState("")
    const [is_loading, setIsLoading] = useState(false)
    const [confirm_generate_modal, setConfirmGenerateModal] = useState({open: false, data: {}})

    const columns = [
        {
            title: getLabel("name"), render: rowData => (
                <Typography>
                    <Link href="" onClick={e => {
                        e.preventDefault();
                        viewRecurringClasses(rowData.id, rowData.name)
                    }}>
                        {rowData.name}
                    </Link>
                </Typography>
            )
        },
        {title: getLabel("service"), field: 'service_name'},
        {title: getLabel("location"), field: 'location_name'},
        {title: getLabel("staff"), field: 'staff_name'},
    ];

    const viewRecurringClasses = (id, tag) => {
        props.history.push({
            pathname: sub_directory + 'recurring-classes/edit',
            state: {
                id: id,
                tag: tag
            }
        })
    };

    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    }

    const handleSearchField = (name, newValue) => {
        setSearch(newValue);

        if ((newValue.length >= 5 && /^\d+$/.test(newValue)) || newValue === '') {
            reloadData();
        }

    };

    const generateClassSessions = async (rowData) => {
        setConfirmGenerateModal({
            open: true,
            data: rowData
        })
    }

    const ViewSchedule = props => {
        const [details] = useState(JSON.parse(props.schedule));

        return (
            <Grid container spacing={1} className="p-3">
                <Grid item xs={12} md={6}>
                    <DateField
                        viewOnly
                        name="from_date"
                        label="from_date"
                        value={details.from_date}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DateField
                        viewOnly
                        name="to_date"
                        label="to_date"
                        value={details.to_date}
                    />
                </Grid>

                <Grid item xs={12} className="d-inline-flex">
                {Object.keys(details.days).map((Day, index) => {
                    details.days[Day].sort(compareTime)

                    return (
                        <div className="mt-2 mr-1 ml-1" key={index} style={{width: `${100/7}%`}}>
                            <div className="text-center border w-100 h-100">

                                <Typography variant="h6" className="bg-light p-1">
                                    {getLabel(Day)}
                                </Typography>

                                <Divider variant="fullWidth"/>

                                {details.days[Day].map((Data) => {
                                    return (
                                        <Chip key={`${Day}-${Data.start_time}-${Data.end_time}`} size="small" className="m-2"
                                              label={`${Data.start_time} - ${Data.end_time}`}
                                        />
                                    )
                                })}

                                <Hide hide={details.days[Day].length !== 0}>
                                    <Typography variant="body1" className="text-black-50 m-2">
                                        <span>{getLabel("empty_data_source_message")}</span>
                                    </Typography>
                                </Hide>
                            </div>
                        </div>
                    )
                })}
                </Grid>
            </Grid>
        )
    };

const handleModalClose=()=>{
    setConfirmGenerateModal({open:false, data:{}})
}
    return (
        <>
            <Hide hide={!confirm_generate_modal.open}>
                <RecurringClassesGenerateModal
                    open={confirm_generate_modal.open}
                    data={confirm_generate_modal.data}
                    handleModalClose={handleModalClose}
                />
            </Hide>

            <Paper>
                <TableToolbar
                    searchFieldName='search'
                    searchValue={search}
                    isLoading={is_loading}
                    onSearchChangeHandle={handleSearchField}
                    searchAction={reloadData}
                    actions={
                        [
                            {
                                hide: !Permissions.checkPermission(permissions_data.recurring_classes.add),
                                onClick: () => props.history.push(
                                    {
                                        pathname: `${sub_directory}recurring-classes/new`,
                                        state: {id: "new"}
                                    }
                                ),
                                label: "add_recurring_class"
                            },
                        ]
                    }
                />


                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    detailPanel={rowData => {
                        return (
                            <ViewSchedule schedule={rowData.schedule}/>
                        );
                    }}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let url = BaseClassRecurringURL;

                            url += '?page=' + (query.page + 1);

                            if (search !== '') {
                                url += '&search=' + search;
                            }

                            instance.get(url)
                                .then(result => {
                                    resolve({
                                        data: result.data.results,
                                        page: result.data.page - 1,
                                        totalCount: result.data.total,
                                    })
                                })
                        })
                    }

                    actions={[
                        {
                            icon: CreateNewFolderIcon,
                            hidden: !Permissions.checkPermission(permissions_data.recurring_classes.generate_classes),
                            tooltip: getLabel("generate_classes"),
                            onClick: (event, rowData) => generateClassSessions(rowData)
                        },
                    ]}

                    {...material_table_style}
                    {...material_table_options()}
                    {...material_table_localization()}

                />

            </Paper>
        </>
    )
}
