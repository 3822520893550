import React, {useEffect, useState} from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import Typography from "@material-ui/core/Typography";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseClientsAgeScaleURL} from "../../../../urls";


export const ClientsAgeScale = props => {
    const [data, setData] = useState( []);
    useEffect(()=>{
        const fetchData = async () =>{
            let response = await httpRequestWithAuth(BaseClientsAgeScaleURL, "GET");
            if (response.status){
                await setData([
                    {name:">15", clients: response.data[0]},
                    {name:">20", clients: response.data[1]},
                    {name:">25", clients: response.data[2]},
                    {name:">30", clients: response.data[3]},
                    {name:">35", clients: response.data[4]},
                    {name:">40", clients: response.data[5]},
                    {name:">45", clients: response.data[6]},
                    {name:"<45", clients: response.data[7]},
                    {name:"Unknown", clients: response.data[8]},
                ])
            }
        }

        fetchData();
    },[])

        return (
            <div className='card-paper-1 h-100 w-100'>
                <Typography variant="h6" className="text-start mb-2">
                    Clients Age scale
                </Typography>

                <div dir='ltr' className="h-100 w-100">
                <ResponsiveContainer width="100%" height='90%'>
                    <LineChart
                        data={data}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="clients" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
                </div>

            </div>
        );
}
