import {
    BaseEntitySettingURL,
    BaseEntityURL,
} from "../../../urls";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {not_validate_value} from "../../../App";


export const RetrieveEntity = async () => {
    let url = BaseEntityURL;

    const feedback = await httpRequestWithAuth(url, "GET")
    return feedback
};


export const UpdateEntity = async (id, data) => {
    let url = BaseEntityURL + id + "/";

    var bodyFormData = new FormData();
    Object.keys(data).map(Name=> {
        if (!not_validate_value.includes(data[Name])) {
            bodyFormData.append(Name, data[Name]);
        }
    })

    const feedback = await httpRequestWithAuth(url, "PATCH", bodyFormData)
    return feedback
};



export const RetrieveEntitySetting = async () => {
    let url = BaseEntitySettingURL;

    const feedback = await httpRequestWithAuth(url, "GET")
    return feedback
};


export const UpdateEntitySetting = async (id, data) => {
    let url = BaseEntitySettingURL + id + "/";

    const feedback = await httpRequestWithAuth(url, "PATCH", data)
    return feedback
};

