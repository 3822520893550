import React, {useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {sub_directory} from "../../../App";
import {ActionButtons} from "../../../components/ActionButton";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseMembershipChangeLogsURL} from "../../../urls";


export const MembershipChangeLogs = props => {
    const membership_id = props.membership_id;

    const columns = [
        {title: getLabel("date"), field: 'date_created'},
        {title: getLabel("by"), field: 'created_by_name'},
        {title: getLabel("action"), field: 'notes'},
    ];

    const detail_panel_columns = [
        {title: getLabel("change"), field: 'display_field_name'},
        {title: getLabel("from"), field: 'display_old_value', cellStyle:{color: 'red'}},
        {title: getLabel("to"), field: 'display_new_value', cellStyle:{color: 'green'}},
    ];

    const [data, setData] = useState([]);
    const [is_loading, setIsLoading] = useState(false);

    const ListChangeLogsData = async () => {
        await setIsLoading(true)

        let response = await httpRequestWithAuth(BaseMembershipChangeLogsURL, "GET", null, {membership: membership_id});
        if (response.status) {
            await setData(response.data)
        }

        await setIsLoading(false)
    };

    useEffect(() => {
        ListChangeLogsData();
    }, [])


    const handleBackButton = () => {
        props.history.push(sub_directory + "memberships")
    }

const DetailsTable = props =>{
    const [data, setData] = useState(JSON.parse(props.details));

    return(
    <MaterialTable
        style={{padding: '1rem', boxShadow: "none"}}
        isLoading={is_loading}
        columns={detail_panel_columns}
        data={data}
        options={{
            paging: false,
            toolbar: false
        }}
    />
)}
    return (
        <>

            <Grid item xs={12}>
                <MaterialTable
                    style={{padding: '0.5rem', boxShadow: "none"}}
                    isLoading={is_loading}
                    columns={columns}
                    data={data}

                    detailPanel={rowData => {
                        return (
                        <DetailsTable {...rowData}/>
                        )
                    }}

                    options={{
                        paging: false,
                        showTitle: false
                    }}

                    {...material_table_localization()}
                />
            </Grid>


            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />
        </>
    );

}
