import React, {useContext, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Permissions from "../../../auth/permissions";
import "../../../assets/scss/Static.scss";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {ActionButtons} from "../../../components/ActionButton";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import {sub_directory} from "../../../App";
import GooglePlacesAutoComplete from "../../../components/fields/GooglePlacesAutoComplete";
import {MaleFemaleCheckBox} from "../../../components/MaleFemaleCheckBox";
import {CreateLocation, DeleteLocation, UpdateLocation} from "./location-apis";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {AddressFields} from "../../../components/fields/AddressFields";
import {permissions_data} from "../../../auth/permission_data";
import {CustomCheckBox} from "../../../components/CustomCheckBox";
import {getLabel} from "../../../localization/MainLocalization";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseLocationURL} from "../../../urls";
import {ConfirmationContext} from "../../../store/use_context";


export const LocationsForm = props => {
    const location_id = props.location_id;
    const {setConfirmData} = useContext(ConfirmationContext)

    const have_permission = ((Permissions.checkPermission(permissions_data.location.add) && location_id === 'new') ||
        Permissions.checkPermission(permissions_data.location.change));

    const [location, setLocation] = useState({is_active: true});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(true);
    const [edit_data, setEditData] = useState(false);

    useEffect(()=>{
        setEditData(location_id === 'new');
    }, [location_id]);

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);

            if (location_id !== 'new') {
                let response = await httpRequestWithAuth(`${BaseLocationURL}${location_id}/`, 'GET')

                if (response.status) {
                    let temp_data = {...response.data,
                        country: response.data.country.name,
                    };
                    props.setGender(temp_data.for_males, temp_data.for_females);
                    setLocation({...temp_data});
                } else {
                    props.history.push("/notfound");
                }

            }

            await setIsLoading(false);
        }

        fetchData();
    }, [location_id, edit_data])


    const handleFieldChange = (name, newValue) => {
        if (have_permission && edit_data) {
            setLocation({...location, [name]: newValue})
        }
    };

    const handleSaveButton = async () => {
        if (have_permission) {
            await setIsLoading(true);
            let response;
            if (location_id === 'new') {
                response = await CreateLocation(location);
            } else {
                response = await UpdateLocation(location_id, location);
            }

            if (response.status) {
                if (location_id === 'new' && Permissions.checkPermission(permissions_data.location_service.view)) {
                    props.history.push({
                        pathname: `${sub_directory}locations/edit`,
                        state: {
                            moveToNextTab: true,
                            id: response.data.id,
                            tag: response.data.name
                        }
                    });
                } else {
                    await handleBackButton()
                }
            } else {
                setHelperText(response.error_feedback);
            }
            await setIsLoading(false);
        }
    };

    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this location ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        await setIsLoading(true);
        let response = await DeleteLocation(location_id);
        if (response.status) {
            await handleBackButton()
        }else{
            setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };

    const handleBackButton = async () => {
        props.history.push(sub_directory + 'locations')
    };

    const handleEditAction =  () => {
        setEditData(!edit_data);
    }

    return (
        <>
            <Hide hide={!is_loading}>
                <Loading/>
            </Hide>

            <Hide hide={is_loading}>

                <Hide hide={!have_permission || location_id === 'new'}>
                    <Grid item xs={12} className="d-inline-flex justify-content-between">

                        <Hide hide={!location.is_freezed}>
                        <Chip color="secondary" label={getLabel("freezed")}/>
                        </Hide>

                        <div className="text-end margin-left-auto">
                            <Button
                                size="small"
                                startIcon={edit_data ? <CloseIcon/> : <EditIcon/>}
                                className={`text-white ${edit_data ? "bg-danger" : "primary-bg"}`}
                                onClick={handleEditAction}
                            >
                                {edit_data ? getLabel("cancel_edit") : getLabel("edit")}
                            </Button>
                        </div>

                    </Grid>
                </Hide>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.name}
                        error={helper_text.name !== undefined}
                        name="name"
                        label="name"
                        value={location.name}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <GooglePlacesAutoComplete
                        viewOnly={!edit_data}
                        helper_text={helper_text.map_location}
                        error={helper_text.map_location !== undefined}
                        name="map_location"
                        label="map_location"
                        value={location.map_location}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>

                <AddressFields
                    viewOnly={!edit_data}
                    helper_text={helper_text}
                    value={location}
                    onChangeHandle={handleFieldChange}
                />
                <Grid item xs={12}>
                    <MaleFemaleCheckBox
                        viewOnly={!edit_data}
                        onChangeHandle={handleFieldChange}
                        for_males={location.for_males}
                        male_name="for_males"
                        for_females={location.for_females}
                        female_name="for_females"
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomCheckBox
                        viewOnly={!edit_data}
                        value={location.is_active}
                        name='is_active'
                        onChangeHandle={handleFieldChange}
                        label="is_active"
                    />
                </Grid>

                {location_id !== 'new' && (
                    <>
                        <Grid item xs={12} md={6}>
                            <DisabledTextField
                                label="date_created"
                                value={location.date_created}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DisabledTextField
                                label="date_modified"
                                value={location.date_modified}
                            />
                        </Grid>
                    </>
                )}


                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowDelete={Permissions.checkAllPermissions([permissions_data.location.delete]) && location_id !== 'new'}
                    deleteAction={handleDeleteButton}
                    allowBack={true}
                    backAction={handleBackButton}
                    allowSave={edit_data}
                    saveAction={handleSaveButton}
                />
            </Hide>


        </>
    );
}

