import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {not_validate_value, sub_directory} from "../../../App";
import moment from "moment";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseFinanceClaimRequestURL, BaseFinanceMyClaimRequestURL} from "../../../urls";
import {AlertContext, ConfirmationContext} from "../../../store/use_context";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import {getLabel} from "../../../localization/MainLocalization";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {DateField} from "../../../components/fields/DateField";
import {CustomTextArea} from "../../../components/fields/CustomTextArea";
import {ApproveClaimFormModal} from "./components/ApproveClaimFormModal";
import {RejectOrReturnClaimFormModal} from "./components/RejectOrReturnClaimFormModal";
import {VendorAutoComplete} from "../../../components/fields/VendorAutoComplete";

export const ClaimForm = props => {
    const {setConfirmData} = useContext(ConfirmationContext);
    const {claim_id} = props
    const {setAlert} = useContext(AlertContext);

    const have_permission = ((Permissions.checkPermission(permissions_data.claim.add) && claim_id === 'new') ||
        Permissions.checkPermission(permissions_data.claim.change));

    const [data, setData] = useState({
        total_invoice_amount: 0,
        personal_money_amount: 0,
        cash_box_amount: 0,
    });
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(true);
    const [edit_data, setEditData] = useState(false);
    const [confirm_modal_data, setConfirmModalData] = useState({open: false})
    const [reject_or_return_modal_data, setRejectOrReturnModalData] = useState({open: false})
    const [reload_data, reloadData] = useState(false)

    const view_options = [
        {title: "is_new_purchase", value: "true"},
        {title: "is_exists_purchase", value: "false"},
    ]

    useEffect(() => {
        setEditData(claim_id === 'new');
    }, [claim_id])

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true)

            if (claim_id !== 'new') {
                let url = props.staff_view ? BaseFinanceClaimRequestURL : BaseFinanceMyClaimRequestURL;
                let response = await httpRequestWithAuth(`${url}${claim_id}/`, "GET");

                if (response.status) {
                    await setData(response.data);
                    if (props.setCanEdit) {
                        await props.setCanEdit(["Saved", "Returned"].includes(response.data.status));
                    }
                    if (props.setIsFullPaid) {
                        await props.setIsFullPaid(response.data.is_full_paid);
                    }
                } else {
                    props.history.push("/notfound");
                }

            }
            await setIsLoading(false)
        }

        fetchData();
    }, [claim_id, edit_data, reload_data])

    const handleFieldsChange = (name, newValue) => {
        if (have_permission && edit_data) {
            setData({...data, [name]: newValue})
        }
    };

    const handleSaveButton = async (status) => {
        await setIsLoading(true);

        let temp_data = {
            ...data,
            date: !not_validate_value.includes(data.date) ? moment(data.date).format("YYYY-MM-DD") : null,
            status
        }

        let response;
        if (claim_id === 'new') {
            response = await httpRequestWithAuth(BaseFinanceMyClaimRequestURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${BaseFinanceMyClaimRequestURL}${claim_id}/`, "PATCH", temp_data);
        }

        await setHelperText({});
        if (response.status) {
            setEditData(false);
            if (claim_id === 'new' && Permissions.checkPermission(permissions_data.claim_attachment.view)) {
                props.history.push({
                    pathname: `${sub_directory}my-claims/edit`,
                    state: {
                        moveToNextTab: true,
                        id: response.data.id,
                        tag: response.data.claim_id
                    }
                });
            } else {
                handleBackButton();
            }
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this Claim ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let response = await httpRequestWithAuth(`${BaseFinanceMyClaimRequestURL}${claim_id}/`, "DELETE");
        if (response.status) {
            await handleBackButton()
        } else {
            await setHelperText(response.error_feedback)
        }
    }

    const handleBackButton = () => {
        if (props.staff_view) {
            props.history.push(`${sub_directory}claims`);
        } else {
            props.history.push(`${sub_directory}my-claims`);
        }
    }

    const handleEditAction = () => {
        setEditData(!edit_data);
    }

    const handleRejectButton = async () => {
        setConfirmData({
            msg: `Are you sure to reject claim ${data.claim_id} ??`,
            action: handleClaimRejectedAction
        })
    };


    const handleClaimRejectedAction = async () => {
        let response = await httpRequestWithAuth(`${BaseFinanceClaimRequestURL}${claim_id}/`, "PATCH", {status: "Rejected"})
        if (response.status) {
            handleBackButton()
        } else {
            setAlert(response.error_feedback.non_field_errors)
        }
    };

    const handleConfirmButton = (claim) => {
        setConfirmModalData({
            open: true,
            claim: data
        });
    }

    const handleCloseConfirmModal = async () => {
        await reloadData(!reload_data)
        await setConfirmModalData({
            open: false
        });
    }

    const handleRejectOrReturnButton = () => {
        setRejectOrReturnModalData({
            open: true,
            claim: data
        });
    }

    const handleCloseRejectOrReturnModal = async () => {
        await reloadData(!reload_data)
        await setRejectOrReturnModalData({
            open: false
        });
    }


    return (
        <>
            <Hide hide={!confirm_modal_data.open}>
                <ApproveClaimFormModal
                    open={confirm_modal_data.open}
                    handleModalClose={handleCloseConfirmModal}
                    claim={confirm_modal_data.claim}
                />
            </Hide>

            <Hide hide={!reject_or_return_modal_data.open}>
                <RejectOrReturnClaimFormModal
                    open={reject_or_return_modal_data.open}
                    handleModalClose={handleCloseRejectOrReturnModal}
                    claim={reject_or_return_modal_data.claim}
                />
            </Hide>

            <Hide hide={!is_loading}><Loading/></Hide>

            <Hide hide={is_loading}>
                <Hide hide={!have_permission || claim_id === 'new' || !props.can_edit || props.staff_view}>
                    <Grid item xs={12} className="text-end">
                        <Button
                            size="small"
                            startIcon={edit_data ? <CloseIcon/> : <EditIcon/>}
                            className={`text-white ${edit_data ? "bg-danger" : "primary-bg"}`}
                            onClick={handleEditAction}
                        >
                            {edit_data ? getLabel("cancel_edit") : getLabel("edit")}
                        </Button>
                    </Grid>
                </Hide>

                <Hide hide={!props.staff_view}>
                    <Grid item xs={12}>
                        <CustomTextField
                            viewOnly
                            value={data.staff_name}
                            label="staff"
                        />
                    </Grid>
                </Hide>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.title}
                        error={helper_text.title !== undefined}
                        value={data.title}
                        onChangeHandle={handleFieldsChange}
                        name="title"
                        label="title"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DateField
                        required
                        viewOnly={!edit_data}
                        name="invoice_date"
                        error={helper_text.invoice_date !== undefined}
                        helper_text={helper_text.invoice_date}
                        label="invoice_date"
                        value={data.invoice_date}
                        onChangeHandle={handleFieldsChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <VendorAutoComplete
                        viewOnly={!edit_data}
                        name='vendor'
                        label="vendor"
                        onChangeHandle={handleFieldsChange}
                        helper_text={helper_text.vendor}
                        error={helper_text.vendor !== undefined}
                        value={data.vendor}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.total_invoice_amount}
                        error={helper_text.total_invoice_amount !== undefined}
                        value={data.total_invoice_amount}
                        onChangeHandle={handleFieldsChange}
                        name="total_invoice_amount"
                        label="total_invoice_amount"
                        type="number"
                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <CustomTextField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.cash_box_amount}
                        error={helper_text.cash_box_amount !== undefined}
                        value={data.cash_box_amount}
                        onChangeHandle={handleFieldsChange}
                        name="cash_box_amount"
                        label="amount_form_cash_box"
                        type="number"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.personal_money_amount}
                        error={helper_text.personal_money_amount !== undefined}
                        value={data.personal_money_amount}
                        onChangeHandle={handleFieldsChange}
                        name="personal_money_amount"
                        label="amount_form_personal_money"
                        type="number"
                    />
                </Grid>


                <Grid item xs={12} className="mt-2">
                    <CustomTextArea
                        viewOnly={!edit_data}
                        label="description"
                        error={helper_text.description !== undefined}
                        helper_text={helper_text.description}
                        name="description"
                        rows={5}
                        value={data.description || ""}
                        onChangeHandle={handleFieldsChange}
                    />
                </Grid>

                <Hide hide={not_validate_value.includes(data.notes)}>
                    <Grid item xs={12} className="mt-2">
                        <CustomTextArea
                            viewOnly
                            label="notes"
                            error={helper_text.notes !== undefined}
                            helper_text={helper_text.notes}
                            name="notes"
                            rows={5}
                            value={data.notes || ""}
                        />
                    </Grid>
                </Hide>


                {claim_id !== 'new' && (
                    <>
                        <Grid item xs={12}>
                            <DisabledTextField
                                label="status"
                                value={data.status}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DisabledTextField
                                label="date_created"
                                value={data.date_created}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DisabledTextField
                                label="date_modified"
                                value={data.date_modified}
                            />
                        </Grid>
                    </>
                )}

                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowDelete={Permissions.checkAllPermissions([permissions_data.claim.delete]) && claim_id !== 'new' && props.can_edit && !props.staff_view}
                    deleteAction={handleDeleteButton}
                    allowBack={!props.can_edit && !props.staff_view}
                    backAction={handleBackButton}
                    allowExtraSave={['Saved', 'Returned'].includes(data.status) && !props.staff_view}
                    extraSaveLabel="submit"
                    extraSaveAction={() => handleSaveButton("Submitted")}
                    allowSave={edit_data && (['Saved', 'Returned'].includes(data.status) || not_validate_value.includes(data.status)) && !props.staff_view}
                    saveAction={() => handleSaveButton("Saved")}
                />

                {/*|| !props.can_edit*/}
                <Hide hide={!props.staff_view}>
                    <Grid container className='mt-3 pt-1 d-inline-flex'>

                        <Hide hide={!["Submitted", "Approved"].includes(data.status)}>
                            <Button
                                onClick={handleRejectOrReturnButton}
                                variant="contained"
                                color="secondary"
                                size="small"
                                className="m-1"
                                component={Grid}
                                item
                                xs={12}
                                md={2}
                            >
                                {getLabel("reject_or_return")}
                            </Button>
                        </Hide>

                        <div className='mr-auto'/>

                        <Button
                            onClick={handleBackButton}
                            variant="contained"
                            color='default'
                            size="small"
                            className="m-1"
                            component={Grid}
                            item
                            xs={12}
                            md={3}
                        >
                            {getLabel("back")}
                        </Button>

                        <Hide hide={data.status !== "Submitted"}>
                            <Button
                                onClick={handleConfirmButton}
                                variant="contained"
                                color='primary'
                                size="small"
                                className="m-1"
                                component={Grid}
                                item
                                xs={12}
                                md={2}
                            >
                                {getLabel("approve")}
                            </Button>
                        </Hide>
                    </Grid>
                </Hide>
            </Hide>

        </>

    );

}