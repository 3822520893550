import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {not_validate_value} from "../../../App";
import {BaseFinanceBankAccountAttachmentURL} from "../../../urls";


export const CreateBankAccountAttachments = async (data) => {
    let url = BaseFinanceBankAccountAttachmentURL;

    var bodyFormData = new FormData();
    bodyFormData.set("bank_account", data.bank_account);
    if (!not_validate_value.includes(data.name)){
    bodyFormData.append("name", data.name);
    }
    if (!not_validate_value.includes(data.file)){
         bodyFormData.append("file", data.file);
    }

    return await httpRequestWithAuth(url, "POST", bodyFormData)
};


export const UpdateBankAccountAttachments = async (id, data) => {
    let url = BaseFinanceBankAccountAttachmentURL + id + "/";

    var bodyFormData = new FormData();
    if (!not_validate_value.includes(data.bank_account)) {
        bodyFormData.set("bank_account", data.bank_account);
    }
    if (!not_validate_value.includes(data.name)){
        bodyFormData.append("name", data.name);
    }
    if (!not_validate_value.includes(data.file)){
        bodyFormData.append("file", data.file);
    }

    return await httpRequestWithAuth(url, "PATCH", bodyFormData)
};


export const DeleteBankAccountAttachments = async (id) => {
    let url = BaseFinanceBankAccountAttachmentURL + id + "/";

    return await httpRequestWithAuth(url, "DELETE")
};

