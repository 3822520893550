import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {CustomBreadcrumbs} from "../../../components/CustomBreadcrumbs";
import RentForm from "./RentForm";
import RentPayments from "./RentPayments";
import RentCancellation from "./RentCancellation";
// import Permissions from "../../../auth/permissions";


export const RentInfo = props => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [is_cancelled, set_is_canclled] = React.useState(false);

    let rent_id = props.match.params.id;
    if (rent_id === undefined){
        rent_id = 'new';
    }

    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };

    const setIsCancelledValue = (newValue) => {
        set_is_canclled(newValue)
    };

    const moveToNextTab = () =>{
        setCurrentTab(currentTab + 1)
    }

    useEffect(()=>{
        const {state} = props.history.location;
        if (state !== undefined && state.moveToNextTab){
            moveToNextTab();
        }
    },[])

    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match}/>*/}

            <Tabs
                value={currentTab}
                onChange={handleTabsChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label={getLabel("details")} id={0} disabled={rent_id === 'new'}/>


                <Tab label={getLabel("payments")} id={1} disabled={rent_id === 'new'}/>


                <Tab label={getLabel("cancellation")} id={3} disabled={rent_id === 'new'}/>
            </Tabs>

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <RentForm
                        {...props}
                        rent_id={rent_id}
                        setIsCancelledValue={setIsCancelledValue}
                        moveToNextTab={moveToNextTab}
                    />
                </Hide>

                <Hide hide={currentTab !== 1}>
                    <RentPayments
                        {...props}
                        is_cancelled={is_cancelled}
                        rent_id={rent_id}
                    />
                </Hide>

                <Hide hide={currentTab !== 2}>
                    <RentCancellation
                        {...props}
                        rent_id={rent_id}
                        setIsCancelledValue={setIsCancelledValue}
                    />
                </Hide>

            </Grid>
        </Paper>
    );
}