import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CustomModal} from "../../../../components/CustomModal";
import {getLabel} from "../../../../localization/MainLocalization";
import {CreateLocationServices, UpdateLocationServices} from "../location-apis";
import {Loading} from "../../../../components/Loading";
import {Hide} from "../../../../components/Hide";


export const SelectServicesAndPackagesForm = props => {
    const [service_option, setServiceOption] = useState(props.options || []);
    const [selected_service, setSelectedService] = useState(props.data || []);
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(false);

    const handleDefaultPriceListAutoComplete = (inputValue) => {
        setSelectedService({...selected_service, default_price_list: inputValue})
    }

    const handleMultiplePriceListsAutoComplete = (inputValue) => {
        let temp_price_lists = [];
        let default_price_list = null;
        selected_service.price_lists.map(PriceList => {
            let selected = false;
            inputValue.map(Input => {
                if (Input.id === PriceList.id) {
                    selected = true
                    default_price_list = selected_service.default_price_list !== null && selected_service.default_price_list.id === Input.id ? Input : default_price_list
                }
                return 0;
            });
            temp_price_lists.push({
                id: PriceList.id,
                name: PriceList.name,
                checked: selected
            });
            return 0;
        });
        setSelectedService(
            {
                ...selected_service,
                price_lists: temp_price_lists,
                default_price_list: default_price_list
            }
        )
    };

    const handleAutoCompleteValue = () => {
        let value = [];
        selected_service.price_lists.map(PriceList => {
            if (PriceList.checked) {
                value.push(PriceList);
            }
            return 0;
        });
        return value;
    };

    const handleSaveForm = async () => {
        await setIsLoading(true);
        await setHelperText({})
        let location_services = [];
        let not_available = true;
        let current_service = {}
        await props.locationServices.map(Lservice => {
            if (Lservice.service === selected_service.id) {
                not_available = false;
                let price_lists = [];
                selected_service.price_lists.map(PriceList => {
                    if (PriceList.checked) {
                        price_lists.push(PriceList.id)
                    }
                    return 0;
                });

                current_service = {
                    ...Lservice,
                    price_lists: price_lists,
                    default_price_list: selected_service.default_price_list !== null ? selected_service.default_price_list.id : null
                };
            } else {
                location_services.push(Lservice)
            }
            return 0;
        });

        if (not_available) {
            let price_lists = [];
            selected_service.price_lists.map(PriceList => {
                if (PriceList.checked) {
                    price_lists.push(PriceList.id)
                }
                return 0;
            });

            current_service = {
                service: selected_service.id,
                price_lists: price_lists,
                default_price_list: selected_service.default_price_list !== null ? selected_service.default_price_list.id : null
            };

        }

        let response;
        if (current_service.id === undefined) {
            response = await CreateLocationServices({...current_service, location: props.location_id, is_active: true});
        } else {
            response = await UpdateLocationServices(current_service.id, {
                ...current_service,
                location: props.location_id,
                is_active: true
            });
        }

        if (response.status) {
            await location_services.push(response.data)
            await props.setServicesAndPriceList(props.allServices, location_services);
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    return (

        <CustomModal
            open={props.open}
        >

            <Grid container spacing={5}>

                <FormHeader
                    title="pages.locationinfo.service_packages_form.header"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12}>
                        <Autocomplete
                            ListboxProps={{className:"list-box-autocomplete"}}
                            renderOption={(option) => (
                                <span className="w-100 text-start">{option.name}</span>
                            )}
                            disabled={props.data.id !== undefined}
                            options={service_option}
                            getOptionLabel={option => option.name}
                            value={selected_service}
                            onChange={(event, value) => {
                                setSelectedService(value || {})
                            }}
                            renderInput={params => (
                                <TextField {...params} fullWidth variant="outlined"
                                           helper_text={helper_text.selected_service}
                                           error={helper_text.selected_service !== undefined}
                                           label={getLabel("service")}/>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            ListboxProps={{className:"list-box-autocomplete"}}
                            multiple
                            disableCloseOnSelect
                            options={selected_service.price_lists}
                            getOptionLabel={option => option.name}
                            renderOption={(option) => (
                                <div className="w-100 text-start">
                                    <Checkbox
                                        checked={option.checked}
                                    />
                                    {option.name}
                                </div>
                            )}
                            onChange={(event, value) => handleMultiplePriceListsAutoComplete(value)}
                            value={handleAutoCompleteValue()}
                            renderInput={params => (
                                <TextField {...params} fullWidth variant="outlined" label={getLabel("price_list")}/>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            ListboxProps={{className:"list-box-autocomplete"}}
                            renderOption={(option) => (
                                <span className="w-100 text-start">{option.name}</span>
                            )}
                            filterOptions={options => options.filter(option => option.checked)}
                            options={selected_service.price_lists}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => handleDefaultPriceListAutoComplete(value)}
                            value={selected_service.default_price_list}
                            renderInput={params => (
                                <TextField {...params} fullWidth variant="outlined"
                                           label={getLabel("default_price_list")}/>
                            )}
                        />
                    </Grid>

                    <FormActionButtons
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                    />

                </Hide>
            </Grid>
        </CustomModal>
    );

}
