import React, {useEffect, useRef, useState} from "react";
import MaterialTable from "material-table";
import Permissions from "../../../auth/permissions";
import "../../../assets/scss/Static.scss";
import {BaseMembershipURL} from "../../../urls";
import {instance} from "../../../auth/axios_instance";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {MembershipsFilter} from "./components/MemberShipsFilter";
import ReceiptIcon from '@material-ui/icons/Receipt';
import {not_validate_value, sub_directory} from "../../../App";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";
import {viewMembershipInvoice} from "./memberships_apis";
import {permissions_data} from "../../../auth/permission_data";
import queryString from 'query-string'
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

export const Memberships = props => {
    let url_params = queryString.parse(props.location.search)

    if (!not_validate_value.includes(url_params['view-freeze'])){
        props.history.push({
            pathname: sub_directory +'memberships/edit',
            state: {
                id: url_params['membership_id'],
                tag: url_params.tag,
                freezesTab: true,
            }
        })
    }

    useEffect(()=>{
        reloadData();
    }, [url_params.freeze]);

    const [freeze, setFreeze] = useState("");

        const tableRef = useRef();

            const columns = [
                {title: getLabel("name"), render: rowData=>(
                        <Typography>
                            <Link href="" onClick={e => {
                                e.preventDefault();
                                viewMembership(rowData.id, rowData.short_name)
                            }}>
                                {rowData.full_name}
                            </Link>
                        </Typography>
                    ), cellStyle: {width: "30%"}},
                {title: getLabel("service"), field: 'service_name', render: rowData => (<>
                        <p>{rowData.service_name}</p>
                        <p>{rowData.location_name}</p>
                    </>)
                },
                {title: getLabel("start_date"), field: 'start_date_view'},
                {title: getLabel("ending_within"), render: rowData=> `${rowData.ending_within} ${getLabel('days')}`},
                {title: getLabel("end_date"), field: 'end_date_view'},
                {title: getLabel("paid"), field: 'paid'},
                {title: getLabel("balance"), field: 'balance'},
                {
                    title: getLabel('attendance'),
                    render: rowData => {
                        return (
                            <a href={`${sub_directory}clients/?client=${rowData.client}&tag=${rowData.short_name}&attend_for_membership=${rowData.id}`} target="_blank">{`${rowData.attended_session_number}`}</a>
                        )
                    },
                },
                {
                    title: getLabel('invoice'),
                    render: rowData => {
                        return (
                            <Tooltip title={getLabel("pages.memberships.view_membership_invoice")}>
                            <IconButton onClick={()=>viewMembershipInvoice(rowData.id)}>
                                <ReceiptIcon/>
                            </IconButton>
                            </Tooltip>
                        )
                    },
                },
            ];
    const [search, setSearch] = useState("")
    const [filter_data, setFilterData] = useState({})
    const [locations, setLocations] = useState([])
    const [services, setServices] = useState([])

    const viewMembership= (id, tag) => {
        props.history.push({
            pathname: sub_directory +'memberships/edit',
            state: {
                id: id,
                tag: tag
            }
        })
    };

    const reloadData = async () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };

    const handleSelectChange = async (name, newValue) => {
        if (newValue === null) {
            newValue = undefined
        }
        await setFilterData({...filter_data, [name]: newValue})
        await reloadData();
    };


    const handleSearchField = async (name, newValue) => {
            setSearch(newValue)

        if ((newValue.length >= 5 && /^\d+$/.test(newValue)) || newValue === '') {
            await reloadData();
        }
    };


        return (
            <>

                <Paper>
                    <TableToolbar
                        searchFieldName='search'
                        searchValue={search}
                        onSearchChangeHandle={handleSearchField}
                        searchAction={reloadData}
                        match={props.match}
                        actions={
                            [
                                {
                                    hide: !Permissions.checkPermission(permissions_data.membership.add),
                                    onClick: () => props.history.push(
                                {
                                    pathname: sub_directory +'memberships/new',
                                    state: {id: "new"}
                                }
                                ),
                                    label: "pages.memberships.add_membership"
                                },
                            ]
                        }
                    />

                    <MembershipsFilter
                        value={filter_data}
                        onChangeHandle={handleSelectChange}
                    />

                    <MaterialTable
                        tableRef={tableRef}
                        columns={columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let url = BaseMembershipURL;
                                url += '?page=' + (query.page + 1) ;

                                if (search !== '') {
                                    url += '&search=' + search;
                                }
                                if (filter_data.location !== undefined) {
                                    url += '&location=' + filter_data.location;
                                }
                                if (filter_data.gender !== undefined) {
                                    url += '&gender=' + filter_data.gender;
                                }
                                if (filter_data.balance !== undefined) {
                                    url += '&balance=' + filter_data.balance;
                                }
                                if (filter_data.service !== undefined) {
                                    url += '&service=' + filter_data.service;
                                }
                                if (!not_validate_value.includes(url_params.freeze)) {
                                    url += '&freeze=' + url_params.freeze;
                                }

                                if (!not_validate_value.includes(url_params.shifted_by_freeze)) {
                                    url += '&shifted_by_freeze=' + url_params.shifted_by_freeze;
                                }

                                instance.get(url)
                                    .then(result => {
                                        resolve({
                                            data: result.data.results,
                                            page: result.data.page - 1,
                                            totalCount: result.data.total,
                                        })
                                    })
                            })
                        }


                        {...material_table_style}
                        {...material_table_options()}
                        {...material_table_localization()}

                    />
                </Paper>

            </>
        );

}

