import React, {useContext, useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {SelectField} from "../../../components/fields/SelectField";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {Hide} from "../../../components/Hide";
import Grid from "@material-ui/core/Grid";
import {ActionButtons} from "../../../components/ActionButton";
import {
    CreateMembershipCancellation,
    DeleteMembershipCancellation,
    ListMembershipCancellation,
} from "./memberships_apis";
import {Loading} from "../../../components/Loading";
import {DateField} from "../../../components/fields/DateField";
import {CustomTextArea} from "../../../components/fields/CustomTextArea";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {not_validate_value, sub_directory} from "../../../App";
import moment from "moment";
import {ConfirmationContext} from "../../../store/use_context";
import {RefundModal} from "./components/RefundModal";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseMembershipCanCancelURL} from "../../../urls";


export const MemberShipCancellation = props => {
    const {membership_id} = props;
    const {setConfirmData} = useContext(ConfirmationContext)

    const have_permission = Permissions.checkPermission(permissions_data.membership_cancellation.change) && !props.is_done

    const cancel_options = [
        {title: getLabel("cancel_reason_1"), value: "Bad Equipment"},
        {title: getLabel("cancel_reason_2"), value: "The Gym Isn't clean"},
        {title: getLabel("cancel_reason_3"), value: "It's too crowded"},
        {title: getLabel("cancel_reason_4"), value: "Gym management fails to address concerns"},
        {title: getLabel("cancel_reason_5"), value: "healthy issue"},
        {title: getLabel("other"), value: "Other"},
    ];
    const [helper_text, setHelperText] = useState({})
    const [cancellation, setCancellation] = useState({})
    const [is_loading, setIsLoading] = useState(true)
    const [open_refund_modal, setOpenRefundModal] = useState(false)

    const loadMembershipCancellation = async () => {
        await setIsLoading(true)

        await setHelperText({});
        let response = await ListMembershipCancellation(membership_id);
        if (response.status) {
            if (response.data.length !== 0) {
                setCancellation({
                    ...response.data[0],
                    reason_select: cancel_options.find(option => option.value === response.data[0].reason) ? response.data[0].reason : "Other"
                });
            }
        }

        await setIsLoading(false)
    }

    useEffect(() => {
        loadMembershipCancellation();
    }, []);

    const handleFieldChange = (name, newValue) => {
        if (have_permission) {
            let data = {...cancellation, [name]: newValue}

            if (name === "reason_select") {
                data["reason"] = newValue
            }

            setCancellation(data)
        }
    }


    const handleCancelSaveButton = async () => {
        await setHelperText({});
        let cancellation_data = {
            ...cancellation,
            membership: membership_id,
            cancellation_date: not_validate_value.includes(cancellation.cancellation_date) ? null : moment(cancellation.cancellation_date).format("YYYY-MM-DDThh:mm"),
        };

        let response = await httpRequestWithAuth(BaseMembershipCanCancelURL, "POST", cancellation_data);
        if (response.status) {
            if (Permissions.checkPermission(permissions_data.membership_payment.add_refund)) {
                setConfirmData({
                    msg: "Do you want to add refund ??",
                    action: () => setOpenRefundModal(true),
                    noBtnAction: () => {
                        handleCancelAction();
                    }
                })
            } else {
                await handleCancelAction({})
            }
        } else {
            await setHelperText(response.error_feedback)
        }
    };


    const handleCancelAction = async (refund_data = {}) => {
        let cancellation_data = {
            ...cancellation,
            membership: membership_id,
            cancellation_date: not_validate_value.includes(cancellation.cancellation_date) ? null : moment(cancellation.cancellation_date).format("YYYY-MM-DDThh:mm"),
            ...refund_data
        };

        let response = await CreateMembershipCancellation(cancellation_data);

        if (response.status) {
            await loadMembershipCancellation();
            props.setIsCancelledValue(true);
        } else {
            if (not_validate_value.includes(cancellation_data.add_refund)) {
                setHelperText(response.error_feedback);
            }
        }

        return response;
    };


    const handleActivateButton = async () => {
        let response = await DeleteMembershipCancellation(cancellation.id);

        if (response.status) {
            setCancellation({...cancellation, id: undefined})
            props.setIsCancelledValue(false);
        } else {
            setHelperText(response.error_feedback);
        }

    };

    const handleBackButton = async () => {
        props.history.push(sub_directory + 'memberships');
    };

    const handleCloseRefundModal = () => {
        setOpenRefundModal(false);
    };

    return (
        <>

            <Hide hide={!open_refund_modal}>
                <RefundModal
                    open={open_refund_modal}
                    handleModalClose={handleCloseRefundModal}
                    saveAction={handleCancelAction}
                />
            </Hide>

            <Hide hide={!is_loading}>
                <Loading/>
            </Hide>

            <Hide hide={is_loading}>
                <Grid item xs={12}>
                    <SelectField
                        disabled={props.is_cancelled}
                        viewOnly={!have_permission || props.is_cancelled}
                        name="reason_select"
                        label="cancel.cancel_reason"
                        onChangeHandle={handleFieldChange}
                        options={cancel_options}
                        value={cancellation.reason_select}
                    />
                </Grid>

                <Hide hide={cancellation.reason_select !== 'Other'}>
                    <Grid item xs={12}>
                        <CustomTextField
                            viewOnly={!have_permission || props.is_cancelled}
                            disabled={props.is_cancelled}
                            helper_text={helper_text.reason}
                            error={helper_text.reason !== undefined}
                            value={cancellation.reason}
                            name="reason"
                            label="cancel.other_reason"
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>
                </Hide>

                <Grid item xs={12}>
                    <DateField
                        required={true}
                        viewOnly={props.is_cancelled || !have_permission}
                        disabled={props.is_cancelled}
                        name="cancellation_date"
                        error={helper_text.cancellation_date !== undefined}
                        helper_text={helper_text.cancellation_date}
                        label="cancellation_date"
                        value={cancellation.cancellation_date}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>

                <Grid item xs={12} className="mt-3">
                    <CustomTextArea
                        label="notes"
                        disabled={props.is_cancelled}
                        viewOnly={!have_permission}
                        error={helper_text.notes !== undefined}
                        helper_text={helper_text.notes}
                        name="notes"
                        rows={5}
                        value={cancellation.notes}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>

                <Hide hide={!props.is_cancelled}>
                    <Grid item xs={12}>
                        <CustomTextField
                            viewOnly
                            label="cancelled_by"
                            value={cancellation.created_by_name}
                        />
                    </Grid>
                </Hide>


                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowCancel={!props.is_cancelled && have_permission}
                    cancelLabel="pages.membershipsinfo.cancel"
                    cancelAction={handleCancelSaveButton}
                    allowActivate={props.is_cancelled && have_permission}
                    activateLabel="pages.membershipsinfo.activate"
                    activateAction={handleActivateButton}
                    allowBack={true}
                    backAction={handleBackButton}
                />
            </Hide>

        </>
    );
}
