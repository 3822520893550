import React, {useContext, useEffect, useState} from "react";
import MaterialTable from 'material-table';
import {material_table_localization,} from "../../../components/Material_conf";
import Chip from "@material-ui/core/Chip";
import Permissions from '../../../auth/permissions';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import {BenchFormModal} from "./components/BenchFormModal";
import {getLabel} from "../../../localization/MainLocalization";
import {Hide} from "../../../components/Hide";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseBenchmarkURL} from "../../../urls";
import {AlertContext} from "../../../store/use_context";

export const Benchmarks = props => {
    const [columns, setColumns] = useState([
        {title: getLabel('name'), field: 'name'},
        {title: getLabel('unit'), field: 'unit', render:rowData => rowData.unit || "---"},
        {title: getLabel('score_info'), field: 'score_info', render:rowData => rowData.score_info || "---"},
        {
            title: getLabel('reps'),
            render: rowData => {
                return (<Chip color={rowData.reps ? "primary" : "default"} label={rowData.reps_view}/>)
            },
        },
        {
            title: getLabel('rounds'),
            render: rowData => {
                return (<Chip color={rowData.rounds ? "primary" : "default"} label={rowData.rounds_view}/>)
            },
        },
        {
            title: getLabel('time'),
            render: rowData => {
                return (<Chip color={rowData.time ? "primary" : "default"} label={rowData.time_view}/>)
            },
        },
        {
            title: getLabel('weight'),
            render: rowData => {
                return (<Chip color={rowData.weight ? "primary" : "default"} label={rowData.weight_view}/>)
            },
        },
    ]);

    const [form_open, setFormOpen] = useState(false)
    const [form_data, setForm_data] = useState({})
    const [data, setData] = useState([])
    const [is_loading, setIsLoading] = useState(true)
    const [search, setSearch] = useState("")
    const {setAlert} = useContext(AlertContext);

    const listData = async () => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(BaseBenchmarkURL, "GET")

        if (response.status) {
            await setData(response.data)

        }
        await setIsLoading(false);
    };

    useEffect(() => {
        listData();
    }, [])


    const handleModalOpen = async data => {
        if (data !== 'new') {
            setForm_data({...data})
        }
        setFormOpen(true)
    };

    const handleModalClose = async () => {
        await listData();
        await setForm_data({})
        await setFormOpen(false)
    };


    return (
        <Paper>

            <Hide hide={!form_open}>
                <BenchFormModal
                    default_data={form_data}
                    open={form_open}
                    handleModalClose={handleModalClose}
                />
            </Hide>


            <MaterialTable
                columns={columns}
                data={data}
                style={{padding: '0.5rem', boxShadow: "none"}}
                isLoading={is_loading}
                editable={
                    Permissions.checkPermission(permissions_data.benchmark.delete) && {
                        onRowDelete: async rowData => {
                            let url = BaseBenchmarkURL + rowData.id + "/";
                            let response  = await httpRequestWithAuth(url, "DELETE")
                            if (response.status){
                                listData();
                            }else{
                                setAlert(response.error_feedback.non_field_errors)
                            }
                        }
                    }}

                actions={[
                    {
                        icon: () => (
                            <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                    startIcon={<AddIcon/>}>
                                <Typography variant="body1">{getLabel('add_benchmark')}</Typography>
                            </Button>
                        ),
                        hidden: !Permissions.checkAllPermissions([permissions_data.benchmark.add]),
                        isFreeAction: true,
                        onClick: () => handleModalOpen('new')
                    },
                    {
                        icon: 'edit',
                        hidden: !Permissions.checkAllPermissions([permissions_data.benchmark.change]),
                        tooltip: getLabel('edit_benchmark'),
                        onClick: (event, rowData) => handleModalOpen(rowData)
                    }

                ]}

                options={{
                    actionsColumnIndex: -1,
                    showTitle: false,
                    pageSize: 10,
                    pageSizeOptions: [10],
                    searchFieldAlignment: 'left'
                }}

                {...material_table_localization()}
            />
        </Paper>

    );

}
