import React from "react";
import {not_validate_value} from "../App";
import Grid from "@material-ui/core/Grid";
import ErrorIcon from "@material-ui/icons/Error";

export const ErrorBox = props =>{
        if (!not_validate_value.includes(props.errorMsg)) {
            return(
        <Grid item xs={12} className="error-box text-start">
            <ErrorIcon className="margin-right-3"/>
            {props.errorMsg}
        </Grid>
    )}
        return "";
}
