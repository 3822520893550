import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CustomModal} from "../../../../components/CustomModal";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {Hide} from "../../../../components/Hide";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseAthleteSeasonURL} from "../../../../urls";
import {Loading} from "../../../../components/Loading";
import {DateField} from "../../../../components/fields/DateField";
import {not_validate_value} from "../../../../App";
import moment from "moment";
import {ServiceAutoComplete} from "../../../../components/fields/ServiceAutoComplete";

export const SeasonFormModal = props => {
    const [is_loading, setIsLoading] = useState(false);
    const [data, setData] = useState({is_active:true, ...props.default_data});
    const [helper_text, setHelperText] = useState({});


    const handleFieldChange = (name, newValue) => {
        setData({...data, [name]: newValue});
    };

    const handleSaveForm = async () => {
        await setIsLoading(true);

        let temp_data = {
            ...data,
            start_date: !not_validate_value.includes(data.start_date) ? moment(data.start_date).format("YYYY-MM-DD") : null,
            end_date: !not_validate_value.includes(data.end_date) ? moment(data.end_date).format("YYYY-MM-DD") : null,
    }

        let Response;

        if (data.id === undefined) {
            Response = await httpRequestWithAuth(BaseAthleteSeasonURL, "POST", temp_data);
        } else {
            Response = await httpRequestWithAuth(`${BaseAthleteSeasonURL}${temp_data.id}/`, "PATCH", temp_data);
        }

        if (Response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(Response.error_feedback);
        }
        await setIsLoading(false);
    };

    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="season_form"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>

                        <Grid item xs={12}>
                            <CustomTextField
                                required
                                error={helper_text.name !== undefined}
                                helper_text={helper_text.name}
                                name="name"
                                label="name"
                                value={data.name}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <ServiceAutoComplete
                                required
                                error={helper_text.service !== undefined}
                                helper_text={helper_text.service}
                                name="service"
                                label="service"
                                value={data.service}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DateField
                                required
                                error={helper_text.start_date !== undefined}
                                helper_text={helper_text.start_date}
                                name="start_date"
                                label="start_date"
                                value={data.start_date}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <DateField
                                required
                                error={helper_text.end_date !== undefined}
                                helper_text={helper_text.end_date}
                                name="end_date"
                                label="end_date"
                                value={data.end_date}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CustomCheckBox
                                value={data.is_active}
                                name='is_active'
                                onChangeHandle={handleFieldChange}
                                label="is_active"
                            />
                        </Grid>

                        <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={props.handleModalClose}
                            handleSaveButton={handleSaveForm}
                        />

                    </Hide>
                </Grid>
            </CustomModal>
        </>
    );
};
