import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../assets/scss/Static.scss';
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CustomModal} from "../../../../components/CustomModal";
import {CustomTextArea} from "../../../../components/fields/CustomTextArea";
import {Loading} from "../../../../components/Loading";
import {Hide} from "../../../../components/Hide";
import Chip from "@material-ui/core/Chip";
import {PhoneNumberField} from "../../../../components/fields/PhoneNumberField";

export const VenderContactFormModal = props => {
    const [data, setData] = useState(props.data)
    const [is_loading, setIsLoading] = useState(false)


    const onChangeHandle = async (name, newValue) => {
        await setData({...data, [name]: newValue})
    }

   const handleSaveBtn = async () =>{
       await setIsLoading(true);
       await props.handleSaveForm(data);
       await setIsLoading(false);
   }

    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="contact_form"
                        handleClose={props.handleModalClose}
                    />


                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>

                        <Grid item xs={12}>
                            <CustomTextField
                                required
                                error={props.helper_text.name !== undefined}
                                helper_text={props.helper_text.name}
                                name="name"
                                label="name"
                                value={data.name}
                                onChangeHandle={onChangeHandle}
                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <PhoneNumberField
                                required
                                helper_text={props.helper_text.phone}
                                error={props.helper_text.phone !== undefined}
                                value={data.phone}
                                onChangeHandle={onChangeHandle}
                                name="phone"
                                label="phone"
                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                required
                                error={props.helper_text.email !== undefined}
                                helper_text={props.helper_text.email}
                                name="email"
                                label="email"
                                value={data.email}
                                onChangeHandle={onChangeHandle}
                            />
                        </Grid>

                    <FormActionButtons
                        errorMsg={props.helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveBtn}
                    />

                    </Hide>
                </Grid>

            </CustomModal>
        </>
    );
};