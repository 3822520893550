import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../../assets/scss/Static.scss';
import {CustomTextField} from "../../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../../components/FormHeader";
import {FormActionButtons} from "../../../../../components/FormActionButton";
import {CustomModal} from "../../../../../components/CustomModal";
import {MaleFemaleCheckBox} from "../../../../../components/MaleFemaleCheckBox";
import {CustomCheckBox} from "../../../../../components/CustomCheckBox";
import {Hide} from "../../../../../components/Hide";
import Permissions from "../../../../../auth/permissions";
import {permissions_data} from "../../../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../../../auth/axios_instance";
import {BaseServiceURL} from "../../../../../urls";
import {Loading} from "../../../../../components/Loading";

export const ServiceFormModal = props => {
    const [is_loading, setIsLoading] = useState(false);
    const [data, setData] = useState({is_active:true, ...props.default_data});
    const [helper_text, setHelperText] = useState({});


    const handleFieldChange = (name, newValue) => {
        setData({...data, [name]: newValue});
    };

    const handleSaveForm = async () => {
        await setIsLoading(true);
        let Response;
        if (data.id === undefined) {
            Response = await httpRequestWithAuth(BaseServiceURL, "POST", data);
        } else {
            Response = await httpRequestWithAuth(`${BaseServiceURL}${data.id}/`, "PATCH", data);
        }

        if (Response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(Response.error_feedback);
        }
        await setIsLoading(false);
    };

    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="pages.services.form.header"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>

                        <Grid item xs={12}>
                            <CustomTextField
                                required
                                error={helper_text.name !== undefined}
                                helper_text={helper_text.name}
                                name="name"
                                label="pages.services.service_name"
                                value={data.name}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>

                        <Hide hide={data.is_rental_service === true}>
                            <Grid item xs={12}>
                                <MaleFemaleCheckBox
                                    onChangeHandle={handleFieldChange}
                                    for_males={data.for_males}
                                    male_name="for_males"
                                    for_females={data.for_females}
                                    female_name="for_females"
                                />
                            </Grid>
                        </Hide>

                        <Hide
                            hide={data.id !== undefined && Permissions.checkAllPermissions([permissions_data.rent.view])}>
                            <Grid item xs={12}>
                                <CustomCheckBox
                                    note="pages.services.service_form.is_rental_service.note"
                                    value={data.is_rental_service}
                                    name='is_rental_service'
                                    onChangeHandle={handleFieldChange}
                                    label="pages.services.service_form.is_rental_service"
                                />
                            </Grid>
                        </Hide>

                        <Grid item xs={12}>
                            <CustomCheckBox
                                value={data.is_active}
                                name='is_active'
                                onChangeHandle={handleFieldChange}
                                label="is_active"
                            />
                        </Grid>

                        <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={props.handleModalClose}
                            handleSaveButton={handleSaveForm}
                        />

                    </Hide>
                </Grid>
            </CustomModal>
        </>
    );
};