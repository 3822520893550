import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {CustomBreadcrumbs} from "../../../components/CustomBreadcrumbs";
import {MembershipPayments} from "./MembershipPayments";
// import Permissions from "../../../auth/permissions";
import {MembershipFreezes} from "./MembershipFreezes";
import {MemberShipCancellation} from "./MemberShipCancellation";
import {MembershipForm} from "./MembershipForm";
import {not_validate_value, sub_directory} from "../../../App";
import {CustomTabs} from "../../../components/CustomTabs";
import queryString from "query-string";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {MembershipChangeLogs} from "./MembershipChangeLogs";


export const MembershipInfo = props => {

    const [currentTab, setCurrentTab] = React.useState(0);
    const [is_cancelled, setIsCanclled] = React.useState(false);
    const [is_fully_paid, setIsFullyPaid] = React.useState(false);
    const [is_fully_refund, setIsFullyRefund] = React.useState(false);
    const [is_transferred, setIsTransferred] = React.useState(false);
    const [is_done, setIsDone] = React.useState(false);
    const [membership_id, setMembershipId] = React.useState("new");
    const state = props.history.location.state || {};

    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };

    const setIsCancelledValue = (newValue) => {
        setIsCanclled(newValue)
    };

    const setIsFullyPaidValue = (newValue) => {
        setIsFullyPaid(newValue)
    };

    const setIsFullyRefundValue = (newValue) => {
        setIsFullyRefund(newValue)
    };

    const setIsTransferredValue = (newValue) => {
        setIsTransferred(newValue)
    };


    const setIsDoneValue = (newValue) => {
        setIsDone(newValue)
    };


    const moveToNextTab = () =>{
        setCurrentTab(currentTab + 1)
    }

    useEffect(()=>{
        if (state.id){
            setMembershipId(state.id);
            if (state.freezesTab){
                setCurrentTab(2);
            }
        }else{
            props.history.push(sub_directory + 'memberships');
        }

        if (state.moveToNextTab){
            moveToNextTab();
        }
    },[])

    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match} details={{tag: state.tag}}/>*/}

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >

                <Tab label={getLabel("details")} id={0} disabled={membership_id === 'new'}/>

                <Tab label={getLabel("payments")} id={1} disabled={membership_id === 'new' || !Permissions.checkPermission(permissions_data.membership_payment.view)}/>

                <Tab label={getLabel("freezes")} id={2} disabled={membership_id === 'new' || !Permissions.checkPermission(permissions_data.membership_freeze.view)}/>

                <Tab label={getLabel("cancellation")} id={3} disabled={membership_id === 'new' || !Permissions.checkPermission(permissions_data.membership_cancellation.view)}/>

                <Tab label={getLabel("change_logs")} id={3} disabled={membership_id === 'new' || !Permissions.checkPermission(permissions_data.membership_change_log.view)}/>

            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <MembershipForm
                        {...props}
                        membership_id={membership_id}
                        setMembershipId={setMembershipId}
                        setIsCancelledValue={setIsCancelledValue}
                        is_transferred={is_transferred}
                        is_cancelled={is_cancelled}
                        setIsTransferredValue={setIsTransferredValue}
                        setIsFullyPaidValue={setIsFullyPaidValue}
                        setIsDoneValue={setIsDoneValue}
                        setIsFullyRefundValue={setIsFullyRefundValue}
                        moveToNextTab={moveToNextTab}
                    />
                </Hide>

                <Hide hide={currentTab !== 1}>
                    <MembershipPayments
                        {...props}
                        membership_id={membership_id}
                        is_fully_paid={is_fully_paid}
                        is_fully_refund={is_fully_refund}
                        is_cancelled={is_cancelled}
                        is_done={is_done}
                        moveToNextTab={moveToNextTab}
                    />
                </Hide>

                <Hide hide={currentTab !== 2}>
                    <MembershipFreezes
                        {...props}
                        membership_id={membership_id}
                        is_cancelled={is_cancelled}
                        is_done={is_done}
                    />
                </Hide>

                <Hide hide={currentTab !== 3}>
                    <MemberShipCancellation
                        {...props}
                        membership_id={membership_id}
                        setIsCancelledValue={setIsCancelledValue}
                        is_cancelled={is_cancelled}
                        is_done={is_done}
                    />
                </Hide>

                <Hide hide={currentTab !== 4}>
                    <MembershipChangeLogs
                        {...props}
                        membership_id={membership_id}
                    />
                </Hide>

            </Grid>
        </Paper>
    );
}
