import {
    BaseLocationURL,
    BaseListLocationsServicesURL,
    BaseFilterLocationsURL, BaseListLocationServicesFullDataURL
} from "../../../urls";
import {httpRequestWithAuth, instance} from "../../../auth/axios_instance";


export const ListLocations = async () => {
    let url = BaseFilterLocationsURL;

    const feedback = await httpRequestWithAuth(url, 'GET')
    return feedback
};


export const CreateLocation = async (data) => {
    let url = BaseLocationURL;
    const feedback = await httpRequestWithAuth(url, 'POST', data)
    return feedback
};


export const UpdateLocation = async (id, data) => {
    let url = BaseLocationURL + id + "/";
    const feedback = await httpRequestWithAuth(url, 'PUT', data)
    return feedback
};


export const UpdateLocationByfield = async (id, data) => {
    let url = BaseLocationURL + id + "/";

        const feedback = await httpRequestWithAuth(url, 'PACTCH', data)
    return feedback
};


export const DeleteLocation = async (id) => {
    let url = BaseLocationURL + id + "/";
    const feedback = await httpRequestWithAuth(url, 'DELETE')
    return feedback
};


export const ListLocationServices = async (location=null) => {
    let url = BaseListLocationsServicesURL;
    const feedback = await httpRequestWithAuth(url, 'get', null, {location})
    return feedback
};


export const CreateLocationServices = async (data) => {
    let url = BaseListLocationsServicesURL;
    const feedback = await httpRequestWithAuth(url, 'POST', data)
    return feedback
};


export const UpdateLocationServices = async (id, data) => {
    let url = BaseListLocationsServicesURL + id + "/";

    const feedback = await instance.put(url, data).then(res => {
        return {status: true, data: res.data, msg: res.statusText}
    }).catch(error => {
        return {status: false, msg: error.response.status}
    });
    return feedback
};



export const ListLocationServicesFullData = async (location=null, is_rent=false, gender, membership = null) => {
    let url = BaseListLocationServicesFullDataURL ;

    const feedback = await  httpRequestWithAuth(url,"GET", null, {location, is_rent, gender, membership})

    return feedback
};
