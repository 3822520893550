import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import "../../../assets/scss/Static.scss";
import {ActionButtons} from "../../../components/ActionButton";
import MaterialTable from "material-table";
import {getLabel} from "../../../localization/MainLocalization";
import {dir, not_validate_value, sub_directory} from "../../../App";
import {
    material_table_localization,
    material_table_style
} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {BaseClassSessionClientsAttendeesURL} from "../../../urls";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import Chip from "@material-ui/core/Chip";


export const ClientAttendees = props => {
    const client_id = props.client_id;
    const state = props.history.location.state || {};
    const [data, setData] = useState([])
    const [is_loading, setIsLoading] = useState(false)


    const columns = [
        {title: getLabel("class_session"), render: rowData => (
                <label>{`${rowData.class_session_name} - ${rowData.class_session_start_time}`}</label>
            )},
        {title: getLabel('coach'), field: 'class_session_staff'},

        {
            title: getLabel("is_present"),
            render: rowData => (
                <Chip color={rowData.is_present ? "primary" : ""} label={getLabel(rowData.is_present_view)}/>
            )
        },
        {
            title: getLabel("is_cancelled"),
            render: rowData => (
                <Chip color={rowData.is_cancelled ? "secondary" : ""} label={getLabel(rowData.is_cancelled_view)}/>
            )
        },
        {title: getLabel('date'), field: 'date_view'},
        {title: getLabel("added_by"), field: 'added_by_name'},
        {title: getLabel("cancelled_by"), field: 'cancelled_by_name'},
    ];


    const listAttendance = async () => {
        await setIsLoading(true);
        let params = {client: client_id}

        if (!not_validate_value.includes(state.attend_for_membership)) {
            params['membership'] = state.attend_for_membership;
        }

        if (!not_validate_value.includes(state.attend_dates)) {
            params['dates'] =  state.attend_dates;
        }

        let response = await httpRequestWithAuth(BaseClassSessionClientsAttendeesURL, "GET", null, params)
        if (response.status) {
            await setData(response.data);
        }
        await setIsLoading(false);
    }

    const handleBackButton = () => {
        props.history.push(sub_directory + "clients")
    }


    const handleRowClick = (data) => {
        if (Permissions.checkPermission(permissions_data.classes.view)) {
            props.history.push({
                pathname: `${sub_directory}classes/edit`,
                state: {
                    id: data.class_session,
                    tag: data.class_session_name
                }
            })

        }
    }


    useEffect(()=>{
        listAttendance();
    }, [])



    return (
        <Grid item xs={12}>


            <MaterialTable
                columns={columns}
                isLoading={is_loading}
                data={data}
                onRowClick={(e, rowData) => handleRowClick(rowData)}

                options={{
                    actionsColumnIndex: -1,
                    searchFieldAlignment: 'left',
                    showTitle: false,
                    paging: false,
                    rowStyle: {
                        direction: "ltr",
                    },
                    actionsCellStyle:{
                        direction: dir
                    },
                }}

                {...material_table_style}
                {...material_table_localization()}
            />


            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />

        </Grid>
    );
}

