import React, {useContext, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {
    BaseMembershipChangeStartDateURL,
    BaseMembershipURL
} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {DateField} from "../../../../components/fields/DateField";
import {StartDateMemberships} from "../memberships_apis";
import moment from "moment";
import {DisabledTextField} from "../../../../components/fields/DisabledField";
import {ConfirmationContext} from "../../../../store/use_context";


export const ChangeStartDate = props => {
    const {setConfirmData} = useContext(ConfirmationContext);
    const [data , setData] = useState(props.default_value)
    const [helper_text , setHelperText] = useState({})
    const [is_loading , setIsLoading] = useState(false)

    const handleFieldChange = async (name, value) => {
        let response = await StartDateMemberships( value, data.client, data.price, data.extra_days, props.membership);
        if (response.status) {
            setData({...data,
                start_date: response.data.start_date,
                end_date: moment(response.data.end_date).format("YYYY-MM-DD"),
            })
        }
    };

    const handleSaveButton = async (confirm_waring=false) => {
        await setIsLoading(true);

        let url = `${BaseMembershipURL}${props.membership}/${BaseMembershipChangeStartDateURL}`;
        let response = await httpRequestWithAuth(url, "PATCH", {'confirm_waring': confirm_waring, 'start_date': data.start_date, 'original_start_date': data.start_date, 'end_date': data.end_date})

        if (response.status) {
            await props.handleModalClose(true);
        } else {
            if (response.error_feedback.attendance_waring){
                setConfirmData({
                    msg: response.error_feedback.attendance_waring_msg,
                    action: () => handleSaveButton(true)
                })
            }else{
            await setHelperText(response.error_feedback);
            }
        }

        await setIsLoading(false);
    };

        return (

            <CustomModal
                open={props.open}
            >
                    <Grid container spacing={2}>

                        <FormHeader
                            title="change_start_date"
                            handleClose={()=>props.handleModalClose(false)}
                        />

                        <Hide hide={!is_loading}>
                            <Loading/>
                        </Hide>

                        <Hide hide={is_loading}>

                            <Grid item xs={12} md={6}>
                                <DateField
                                    required={true}
                                    name="invoice_date"
                                    error={helper_text.start_date !== undefined}
                                    helper_text={helper_text.start_date}
                                    label="start_date"
                                    value={data.start_date}
                                    onChangeHandle={handleFieldChange}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DisabledTextField
                                    label="end_date"
                                    value={data.end_date}
                                />
                            </Grid>

                            <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={()=>props.handleModalClose(false)}
                            handleSaveButton={()=>handleSaveButton(false)}
                        />

                        </Hide>
                    </Grid>
            </CustomModal>
        );
}
