import React, {useRef, useState} from 'react';
import MaterialTable from 'material-table';
import Permissions from '../../../auth/permissions';
import {BaseEquipmentURL} from '../../../urls';
import {instance} from '../../../auth/axios_instance';
import {TableToolbar} from '../../../components/TableToolbar';
import Paper from '@material-ui/core/Paper';
import '../../../assets/scss/Static.scss';
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from '../../../components/Material_conf';
import {getLabel} from "../../../localization/MainLocalization";
import {DeleteEquipment} from "./equipment-apis";
import {EquipmentFormModal} from "./components/equipmentFormModal";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import {EquipmentCheckQty} from "./components/EquipmentCheckQty";
import {Hide} from "../../../components/Hide";
import {permissions_data} from "../../../auth/permission_data";

export const Equipment = props => {

        const tableRef = useRef();

            const [columns, setColumns] = useState([
                {title: getLabel('status'),
                    render:rowData=>{return(<Chip color={rowData.is_active?"primary":"default"} label={rowData.is_active_view}/>)},
                },
                {title: getLabel('name'), field: 'name'},
                {title: getLabel('total_qty'), field: 'total_qty'},
                {title: getLabel('notes'), field: 'notes'},
                {render:rowData=>{return(
                        <div className="d-inline-flex">
                                <Button className="mr-1 ml-1" style={{width:"125px"}} size="small" variant="outlined"
                                    onClick={()=>handleModalOpen(rowData, "qty")}
                                >
                                    {getLabel("pages.equipment.check_qty")}
                                </Button>
                        </div>
                    )}}
            ]);
    const [form_open, setFormOpen] = useState(false);
    const [check_qty, setCheckQty] = useState(false);
    const [new_equipment, setNewEquipment] = useState({is_active:true});
    const [search, setSearch] = useState("");

    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };



    const handleModalClose = () => {
        reloadData();
        setFormOpen(false);
        setCheckQty(false);
        setNewEquipment({is_active:true})
    };


    const handleModalOpen = async (data, form = "equipment") => {
        if (data !== 'new') {
            setNewEquipment(data)

        }
        setFormOpen(form !== "qty");
        setCheckQty(form === "qty");
    };

    const handleFieldChange = (name, newValue) => {
        setNewEquipment({...new_equipment, [name]: newValue})
    };

    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length >= 5 || newValue === '') {
            reloadData();
        }
    };



        return (
            <>
                    <Hide hide={!check_qty}>
                    <EquipmentCheckQty
                        data={new_equipment}
                        handleModalClose={handleModalClose}
                        open={check_qty}
                    />
                </Hide>

                    <Hide hide={!form_open}>
                    <EquipmentFormModal
                        equipment={new_equipment.id}
                        handleModalClose={handleModalClose}
                        open={form_open}
                        data={new_equipment}
                        onChangeHandle={handleFieldChange}
                    />
                    </Hide>


                <Paper>
                    <TableToolbar
                        searchFieldName='search'
                        searchValue={search}
                        onSearchChangeHandle={handleSearchField}
                        searchAction={reloadData}
                        match={props.match}
                        actions={
                            [
                                {
                                    hide: !Permissions.checkAllPermissions([permissions_data.equipment.add]),
                                    onClick: () => handleModalOpen('new'),
                                    label: "pages.equipment.add_equipment"
                                },
                            ]
                        }
                    />

                    <MaterialTable
                        tableRef={tableRef}
                        columns={columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let url = BaseEquipmentURL;
                                url += '?page=' + (query.page + 1);

                                if (search !== '') {
                                    url += '&search=' + search;
                                }

                                instance.get(url)
                                    .then(result => {
                                        resolve({
                                            data: result.data.results,
                                            page: result.data.page - 1,
                                            totalCount: result.data.total,
                                        })
                                    })
                            })
                        }
                        editable={
                            Permissions.checkPermission(permissions_data.equipment.delete) && {
                                onRowDelete: async EquipmentData => {
                                     await DeleteEquipment(EquipmentData.id);
                                    reloadData();
                                }
                            }}

                        actions={[
                            Permissions.checkAllPermissions([permissions_data.equipment.change]) && {
                                icon: 'edit',
                                tooltip: getLabel('pages.equipment.edit_equipment'),
                                onClick: (event, rowData) => handleModalOpen(rowData)
                            },
                        ]}

                        {...material_table_style}
                        {...material_table_options()}
                        {...material_table_localization()}
                    />
                </Paper>
                </>
        );
    }


