import React from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../assets/scss/Static.scss';
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CustomModal} from "../../../../components/CustomModal";
import {DropNDrag} from "../../../../components/DropNDrag";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";

export const AthleteAttachmentFormModal = props => {
    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="attachment"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!props.is_loading}>
                        <Loading msg="File Uploading..."/>
                    </Hide>

                    <Hide hide={props.is_loading}>

                        <Hide hide={!props.edit_attach}>
                            <Grid item xs={12}>
                                <DropNDrag
                                    onChangeHandle={props.onChangeHandle}
                                    name="file"
                                    value={props.data.file}
                                    helper_text={props.helper_text.file}
                                />
                            </Grid>
                        </Hide>

                        <Grid item xs={12}>
                            <CustomTextField
                                required={true}
                                error={props.helper_text.name !== undefined}
                                helper_text={props.helper_text.name}
                                name="name"
                                label="name"
                                value={props.data.name}
                                onChangeHandle={props.onChangeHandle}
                            />
                        </Grid>


                        <FormActionButtons
                            errorMsg={props.helper_text.non_field_errors}
                            handleCancelButton={props.handleModalClose}
                            handleSaveButton={props.handleSaveForm}
                        />
                    </Hide>

                </Grid>

            </CustomModal>
        </>
    );
};
