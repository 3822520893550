import React from "react";
import {TextField} from "@material-ui/core";
import {getLabel} from "../../localization/MainLocalization";
import {not_validate_value} from "../../App";
import moment from "moment";

export const DisabledDateField = props => {
    const handleFieldChange = (e) =>{
    }

    return (
        <TextField
            helperText={props.helper_text}
            variant="outlined"
            error={props.error}
            label={<label> {getLabel(props.label)}<span className='text-danger'>{props.required ? "*" : ""}</span></label>}
            value={not_validate_value.includes(props.value)? "": moment(props.value).format(props.withTime ? "DD-MM-YYYY hh:mm A": "DD-MM-YYYY")}
            onChange={handleFieldChange}
            fullWidth
            disabled={props.disabled}
            className={props.disabled ? "disabled-field " + props.className : props.className}
        />
    )
};