import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {getLabel} from "../../localization/MainLocalization";
import {TextareaAutosize} from "@material-ui/core";

export const CustomTextArea = props =>{
    const handleFieldChange = async (e) =>{
        if (!props.viewOnly){
            await props.onChangeHandle(e.target.name, e.target.value)
        }
    }

    return(
        <FormControl fullWidth
                     // error={props.error}
                     // helperText={props.helper_text}

        >
            <label className={"text-start"}>
                {getLabel(props.label)}
            </label>
            <TextareaAutosize
                name={props.name}
                rows={props.rows}
                style={{border:'1'}}
                value={props.value || ""}
                onChange={handleFieldChange}
                className={props.disabled ? "disabled-field" : ""}
                disabled={props.disabled}
            />
        </FormControl>
    )
}