import React from "react";
// import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {getLabel} from "../../localization/MainLocalization";
// import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from '@date-io/moment';
import TextField from "@material-ui/core/TextField";
import moment from "moment";

export const TimeField = props=>{
    const handleChange = value =>{
        if (!props.viewOnly){
             props.onChangeHandle(props.name, value)
        }

    }
    return(

        <TextField
            disabled={props.disabled}
            className={props.disabled ? "disabled-field" : ""}
            error={props.error}
            variant="outlined"
            helperText={props.helper_text || null}
            // variant="outlined"
            label={<label> {getLabel(props.label)}<span className='text-danger'>{props.required ? "*" : ""}</span></label>}
            fullWidth
            value={props.value || ""}
            onChange={e => handleChange(e.target.value)}
            type="time"
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                step: 300, // 5 min
            }}
        />

        // <MuiPickersUtilsProvider utils={MomentUtils}>
        //     />
        //         <KeyboardTimePicker
        //             autoOk
        //             disabled={props.viewOnly}
        //             className={props.disabled ? "disabled-field" : ""}
        //             error={props.error}
        //             variant="inline"
        //             helperText={props.helper_text || null}
        //             inputVariant="outlined"
        //             label={<label> {get_label(props.label)}<span className='text-danger'>{props.required ? "*" : ""}</span></label>}
        //             fullWidth
        //             value={props.value || null}
        //             onChange={e => props.onChangeHandle(props.name, e)}
        //             KeyboardButtonProps={{
        //                 'aria-label': 'change time',
        //             }}
        //         />
        // </MuiPickersUtilsProvider>
    );
}