import {CustomModal} from "../../../../components/CustomModal";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import React, {useState} from "react";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseClassesChangeOptionalAthletesCapacityURL} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {getLabel} from "../../../../localization/MainLocalization";
import {not_validate_value, sub_directory} from "../../../../App";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {Tooltip} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import {BulkActionErrorTable} from "./BulkActionErrorTable";

export const ChangeClassOptionalAthletesCapacityModal = props => {
    const [data, setData] = useState({optional_athletes_capacity:"0"});
    const [is_loading, setIsLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [error_table_open, setErrorTableOpen] = useState(false)
    const [error_data, setErrorData] = useState([])
    const [error_columns] = useState([
        {title: getLabel("name"), field: 'class_name'},
        {title: getLabel("date"), field: 'class_date'},
        {title: getLabel("time"), field: 'class_time'},
        {title: getLabel("training_area"), field: "class_training_area"},
        {title: getLabel("errors"), render:rowData=>(
                <>
                    <Hide hide={!not_validate_value.includes(rowData.non_field_errors)}>
                        <CheckCircleIcon className="text-success"/>
                    </Hide>

                    <Hide hide={not_validate_value.includes(rowData.non_field_errors)}>
                        <Tooltip title={rowData.non_field_errors}>
                            <CancelIcon  color="secondary"/>
                        </Tooltip>
                    </Hide>
                </>
            )},
        {
            render: rowData => {
                return (
                    <Button
                        href={`${sub_directory}classes/?class-session=${rowData.class_id}&tag=${rowData.class_name}`}
                        target="_blank"
                        component='a'
                        size="small"
                        variant="outlined"
                    >
                        {getLabel("view")}
                    </Button>
                )
            }
        },
    ])

    const handleFieldChange = (name, value) => {
        setData({...data, [name]: value})
    }

    const handleSaveBtn = async () => {
        await setIsLoading(true);

        let response = await httpRequestWithAuth(BaseClassesChangeOptionalAthletesCapacityURL, "PATCH", {details: props.details, data});

        if (response.status) {
            await props.handleModalClose(true);
        } else {
            if (Array.isArray(response.error_feedback)) {
                if (props.oneClassEdit){
                    await setHelperText(response.error_feedback[0]);
                }else{
                    setErrorData(response.error_feedback);
                    setErrorTableOpen(true);
                }
            } else {
                await setHelperText(response.error_feedback);
            }

        }

        await setIsLoading(false);
    }


    const handleModalClose = ()=>{
        setErrorTableOpen(false);
        setErrorData([]);
    }


    return (
        <CustomModal
            open={props.open}
        >

            <Grid container spacing={2}>

                <Hide hide={!error_table_open}>
                    <BulkActionErrorTable
                        open={error_table_open}
                        data={error_data}
                        columns={error_columns}
                        handleModalClose={handleModalClose}
                    />
                </Hide>

                <FormHeader
                    title="change_optional_athletes_capacity"
                    handleClose={()=>props.handleModalClose(false)}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>

                    <Grid item xs={12}>
                        <CustomTextField
                            required
                            helper_text={helper_text.optional_athletes_capacity}
                            error={helper_text.optional_athletes_capacity !== undefined}
                            value={data.optional_athletes_capacity}
                            type="number"
                            onChangeHandle={handleFieldChange}
                            name="optional_athletes_capacity"
                            label="optional_athletes_capacity"
                        />
                    </Grid>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={()=>props.handleModalClose(false)}
                        handleSaveButton={handleSaveBtn}
                    />

                </Hide>

            </Grid>
        </CustomModal>
    )
}
