import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {InputFile} from "../../../components/InputFile";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {CountriesAutoComplete} from "../../../components/fields/CountriesAutoComplete";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {CharFieldOnlyField} from "../../../components/fields/CharFieldOnlyField";
import {DateField} from "../../../components/fields/DateField";
import {PhoneNumberField} from "../../../components/fields/PhoneNumberField";
import {GenderRadioButton} from "../../../components/GenderRadioButton";
import {AddressFields} from "../../../components/fields/AddressFields";
import {CreateStaff, DeleteStaff, RetrieveStaff, UpdateStaff} from "./staff_apis";
import {not_validate_value, sub_directory} from "../../../App";
import {permissions_data} from "../../../auth/permission_data";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {getLabel} from "../../../localization/MainLocalization";
import Typography from "@material-ui/core/Typography";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import {ConfirmationContext} from "../../../store/use_context";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseClientURL} from "../../../urls";
// import {PersonalPhoto} from "../../../components/PersonalPhoto";


export const StaffForm = props => {
    const {setConfirmData} = useContext(ConfirmationContext)

    const {staff_id} = props

    const have_permission = ((Permissions.checkPermission(permissions_data.staff.add) && staff_id === 'new') ||
        Permissions.checkPermission(permissions_data.staff.change));

    const [staff, setStaff] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState();
    const [edit_data, setEditData] = useState(false);
    const [data_before_edit, setDataBeforeEdit] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            setEditData(staff_id === 'new');
            if (staff_id !== 'new') {
                let response = await RetrieveStaff(staff_id);

                if (response.status) {
                    let temp_data = {
                        ...response.data,
                        nationality: response.data.nationality.name,
                        country: response.data.country.name,
                    }
                    await setStaff({...temp_data});
                    await setDataBeforeEdit({...temp_data});
                } else {
                    props.history.push("/notfound");
                }

            }
            await setIsLoading(false)
        }

        fetchData();
    }, [staff_id])

    const handleInfoFieldChange = (name, newValue) => {
        if (have_permission && edit_data) {
            if (name === "photo") {
                setStaff({...staff, [name]: newValue, display_photo: URL.createObjectURL(newValue)});
            } else {
                setStaff({...staff, [name]: newValue});
            }
        }
    };

    const handleBackButton = () => {
        props.history.push(sub_directory + "staff")
    }

    const handleStaffSaveButton = async () => {
        await setIsLoading(true);

        let response;
        if (staff_id === 'new') {
            response = await CreateStaff(staff);
        } else {
            response = await UpdateStaff(staff_id, staff);
        }

        await setHelperText({});

        if (response.status) {
            if (staff_id === 'new') {
                props.history.push({
                    pathname: `${sub_directory}staff/edit`,
                    state: {
                        moveToNextTab: true,
                        id: response.data.id,
                        tag: response.data.short_name
                    }
                });
            } else {
                await handleBackButton();
            }
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this staff ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let response = await DeleteStaff(staff_id);

        if (response.status) {
            handleBackButton();
        }else {
            await setHelperText(response.error_feedback)
        }
    };

    const handleEditAction = async ()=>{
        await setEditData(!edit_data);
        setStaff(data_before_edit);
    }

    return (
        <>
            <Hide hide={!is_loading}><Loading/></Hide>

            <Hide hide={is_loading}>

                <Hide hide={!have_permission || staff_id === 'new'}>
                    <Grid item xs={12} className="text-end">
                        <Button
                            size="small"
                            startIcon={edit_data?<CloseIcon/>:<EditIcon/>}
                            className={`text-white ${edit_data?"bg-danger": "primary-bg"}`}
                            onClick={handleEditAction}
                        >
                            {edit_data? getLabel("cancel_edit"): getLabel("edit")}
                        </Button>
                    </Grid>
                </Hide>


                <Grid item xs={12} className='border m-2 p-4'>
                    <div className='w-50 m-auto text-center'>
                        {not_validate_value.includes(staff.display_photo)?(
                            <Typography variant="h4">
                                {getLabel("no_photo")}
                            </Typography>
                        ):(
                            <img src={staff.display_photo} className='w-75' alt=""/>
                        )}
                        <Hide hide={!edit_data}>
                        <InputFile
                            name='photo'
                            onChangeHandle={handleInfoFieldChange}
                            label="change_photo"
                        />
                        </Hide>
                    </div>
                    {/*<PersonalPhoto height="200px" width="200px"/>*/}
                </Grid>


                <Grid item xs={12} md={4}>
                    <CharFieldOnlyField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.first_name}
                        error={helper_text.first_name !== undefined}
                        value={staff.first_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="first_name"
                        label="first_name"
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CharFieldOnlyField
                        viewOnly={!edit_data}
                        helper_text={helper_text.middle_name}
                        error={helper_text.middle_name !== undefined}
                        value={staff.middle_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="middle_name"
                        label="middle_name"
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CharFieldOnlyField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.last_name}
                        error={helper_text.last_name !== undefined}
                        value={staff.last_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="last_name"
                        label="last_name"
                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <CountriesAutoComplete
                        required
                        viewOnly={!edit_data}
                        label="nationality"
                        name="nationality"
                        onChangeHandle={handleInfoFieldChange}
                        helper_text={helper_text.nationality}
                        error={helper_text.nationality !== undefined}
                        value={staff.nationality}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        viewOnly={!edit_data}
                        helper_text={helper_text.social_id}
                        error={helper_text.social_id !== undefined}
                        value={staff.social_id}
                        onChangeHandle={handleInfoFieldChange}
                        name="social_id"
                        label="social_id"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DateField
                        viewOnly={!edit_data}
                        name="birth_date"
                        error={helper_text.birth_date !== undefined}
                        helper_text={helper_text.birth_date}
                        label="birth_date"
                        value={staff.birth_date}
                        onChangeHandle={handleInfoFieldChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <PhoneNumberField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.mobile}
                        error={helper_text.mobile !== undefined}
                        value={staff.mobile}
                        onChangeHandle={handleInfoFieldChange}
                        name="mobile"
                        label="mobile"
                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <CharFieldOnlyField
                        viewOnly={!edit_data}
                        helper_text={helper_text.em_contact_name}
                        error={helper_text.em_contact_name !== undefined}
                        value={staff.em_contact_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="em_contact_name"
                        label="emergency_contact_name"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <PhoneNumberField
                        viewOnly={!edit_data}
                        helper_text={helper_text.em_contact_telephone}
                        error={helper_text.em_contact_telephone !== undefined}
                        value={staff.em_contact_telephone}
                        onChangeHandle={handleInfoFieldChange}
                        name="em_contact_telephone"
                        label="emergency_contact_telephone"
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <GenderRadioButton
                        viewOnly={!edit_data}
                        label="gender"
                        name="gender"
                        required
                        helper_text={helper_text.gender}
                        error={helper_text.gender !== undefined}
                        value={staff.gender}
                        onChangeHandle={handleInfoFieldChange}
                    />
                </Grid>

                <AddressFields
                    viewOnly={!edit_data}
                    helper_text={helper_text}
                    value={staff}
                    onChangeHandle={handleInfoFieldChange}
                />

                {staff_id !== 'new' && (
                    <>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="created_by"
                                        value={staff.created_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_created"
                                        value={staff.date_created}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="modified_by"
                                        value={staff.modified_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_modified"
                                        value={staff.date_modified}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                    </>
                )}

                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowDelete={Permissions.checkPermission(permissions_data.staff.delete) && staff_id !== 'new'}
                    deleteAction={handleDeleteButton}
                    allowBack={true}
                    backAction={handleBackButton}
                    allowSave={edit_data}
                    saveAction={handleStaffSaveButton}
                />
            </Hide>

        </>

    );

}
