import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "./FormHeader";
import {FormActionButtons} from "./FormActionButton";
import {CustomModal} from "./CustomModal";
import {httpRequestWithAuth} from "../auth/axios_instance";
import {Hide} from "./Hide";
import {Loading} from "./Loading";
import {CharFieldOnlyField} from "./fields/CharFieldOnlyField";
import {CustomTextField} from "./fields/CustomTextField";
import {PhoneNumberField} from "./fields/PhoneNumberField";
import {BaseClientURL} from "../urls";
import {not_validate_value} from "../App";
import {GenderRadioButton} from "./GenderRadioButton";


export const ClientModel = props => {
    const [data , setData] = useState(props.data ||{});
    const [helper_text , setHelperText] = useState({});
    const [is_loading , setIsLoading] = useState(false);

    const handleFieldChange = (name, newValue) => {
         setData({...data, [name]:newValue})
    }


    const handleSaveButton = async () => {
        await setIsLoading(true);

        let response;

        if (not_validate_value.includes(data.id)){
            response = await httpRequestWithAuth(BaseClientURL, "POST", data)
        }else{
            response = await httpRequestWithAuth(`${BaseClientURL}${data.id}/`, "PATCH", data)
        }

        if (response.status) {
            if (!not_validate_value.includes(props.handleActionSave)){
                await props.handleActionSave(response.data)
            }
            await props.handleModalClose()
        } else {
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };


    return (

        <CustomModal
            open={props.open}
        >
            <Grid container spacing={2}>

                <FormHeader
                    title="client_form"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>


                    <Grid item xs={12} md={6}>
                        <CharFieldOnlyField
                            required
                            helper_text={helper_text.first_name}
                            error={helper_text.first_name !== undefined}
                            value={data.first_name}
                            onChangeHandle={handleFieldChange}
                            name="first_name"
                            label="first_name"
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CharFieldOnlyField
                            required
                            helper_text={helper_text.last_name}
                            error={helper_text.last_name !== undefined}
                            value={data.last_name}
                            onChangeHandle={handleFieldChange}
                            name="last_name"
                            label="last_name"
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            required
                            helper_text={helper_text.social_id}
                            error={helper_text.social_id !== undefined}
                            value={data.social_id}
                            onChangeHandle={handleFieldChange}
                            name="social_id"
                            label="social_id"
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <PhoneNumberField
                            required
                            helper_text={helper_text.mobile}
                            error={helper_text.mobile !== undefined}
                            value={data.mobile}
                            onChangeHandle={handleFieldChange}
                            name="mobile"
                            label="mobile"
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <GenderRadioButton
                            label="gender"
                            name="gender"
                            required={true}
                            helper_text={helper_text.gender}
                            error={helper_text.gender !== undefined}
                            value={data.gender}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>


                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveButton}
                    />
                </Hide>
            </Grid>
        </CustomModal>
    );
}