import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {CountriesAutoComplete} from "../../../components/fields/CountriesAutoComplete";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {CharFieldOnlyField} from "../../../components/fields/CharFieldOnlyField";
import {DateField} from "../../../components/fields/DateField";
import {PhoneNumberField} from "../../../components/fields/PhoneNumberField";
import {GenderRadioButton} from "../../../components/GenderRadioButton";
import {AddressFields} from "../../../components/fields/AddressFields";
import {not_validate_value, sub_directory} from "../../../App";
import moment from "moment";
import {ClientAutoComplete} from "../../../components/fields/ClientAutoComplete";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseClientCheckMobileIfExistsURL, BaseClientCheckSocialIdIfExistsURL, BaseClientURL} from "../../../urls";
import {ConfirmationContext} from "../../../store/use_context";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import {CustomCheckBox} from "../../../components/CustomCheckBox";
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import {getLabel} from "../../../localization/MainLocalization";

export const ClientForm = props => {
    const {setConfirmData} = useContext(ConfirmationContext);
    const {client_id} = props;


    const have_permission = ((Permissions.checkPermission(permissions_data.client.add) && client_id === 'new') ||
        Permissions.checkPermission(permissions_data.client.change));

    const [client, setClient] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(true);
    const [edit_data, setEditData] = useState(false);

    useEffect(() => {
        setEditData(client_id === 'new');
    }, [client_id])

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true)

            if (client_id !== 'new') {
                let response = await httpRequestWithAuth(`${BaseClientURL}${client_id}/`, "GET");

                if (response.status) {
                    let temp_data = {
                        ...response.data,
                        nationality: response.data.nationality.name,
                        country: response.data.country.name,
                    };
                    setClient(temp_data);
                } else {
                    props.history.push("/notfound");
                }

            }
            await setIsLoading(false)
        }

        fetchData();
    }, [client_id, edit_data])

    const getBirthDate = (social_id) => {
        if ((social_id.charAt(0) === '3' || social_id.charAt(0) === '2') && social_id.length === 12) {
            let year = social_id.charAt(0) === '3' ? "20" : social_id.charAt(0) === '2' ? "19" : "";
            year = year + social_id.substr(1, 2);
            let month = social_id.substr(3, 2);
            let day = social_id.substr(5, 2);
            let date = year + "-" + month + "-" + day;
            date = new Date(date);
            if (date + "" !== 'Invalid Date') {
                return date
            }
        }
        return null
    };


    const handleInfoFieldChange = (name, newValue) => {
        if (have_permission && edit_data) {
            let new_data = {[name]: newValue}
            if (name === 'social_id') {
                let birthdate = getBirthDate(newValue)
                if (birthdate !== null) {
                    new_data['birth_date'] = birthdate
                }
            }

            setHelperText({...helper_text, [name]: undefined})
            setClient({...client, ...new_data})
        }
    };

    const handleClientSaveButton = async (create_membership = false, ignore_mobile_checking = false) => {
        await setIsLoading(true);

        let client_data = {
            ...client,
            birth_date: !not_validate_value.includes(client.birth_date) ? moment(client.birth_date).format("YYYY-MM-DD") : null,
        }

        let response;

        if (client_id === 'new') {
            if (ignore_mobile_checking) {
                response = await httpRequestWithAuth(BaseClientURL, "POST", client_data);
            } else {
                let params = {mobile: client.mobile};

                if (client_id !== 'new'){
                    params['exclude_client'] = client_id
                }

                let check_mobile = await httpRequestWithAuth(BaseClientCheckMobileIfExistsURL, "GET", null, params);
                if (check_mobile.status) {
                    response = await httpRequestWithAuth(BaseClientURL, "POST", client_data);
                } else {
                    response = {status: false, error_feedback: helper_text}
                    setConfirmData({
                        msg: "There are other client have same mobile number number, do you want to create this client with same mobile number ??",
                        action: ()=>handleClientSaveButton(create_membership, true)
                    });
                }
            }
        } else {
            response = await httpRequestWithAuth(`${BaseClientURL}${client_id}/`, "PATCH", client_data);
        }

        await setHelperText({});
        if (response.status) {
            setEditData(false);
            if (create_membership) {
                props.history.push({
                    pathname: sub_directory + 'memberships/new',
                    state: {
                        client_id: response.data.id,
                        id: 'new'
                    }
                });
            } else if (client_id === 'new' && Permissions.checkPermission(permissions_data.client_attachment.view)) {
                props.history.push({
                    pathname: `${sub_directory}clients/edit`,
                    state: {
                        moveToNextTab: true,
                        id: response.data.id,
                        tag: response.data.short_name
                    }
                });
            } else {
                handleBackButton();
            }
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this client ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let response = await httpRequestWithAuth(`${BaseClientURL}${client_id}/`, "DELETE");
        if (response.status) {
            await handleBackButton()
        } else {
            await setHelperText(response.error_feedback)
        }
    }

    const handleBackButton = () => {
        props.history.push(`${sub_directory}clients`);
    }

    const handleEditAction = () => {
        setEditData(!edit_data);
    }

    const onBlurSocialId = async (social_id) => {
        let params = {social_id};

        if (client_id !== 'new'){
            params['exclude_client'] = client_id
        }

        let response = await httpRequestWithAuth(BaseClientCheckSocialIdIfExistsURL, 'GET', null, params)
        if (response.status) {
            setHelperText({...helper_text, social_id: undefined})
        } else {
            setHelperText({...helper_text, social_id: response.error_feedback.social_id})
        }
    }

    return (
        <>
            <Hide hide={!is_loading}><Loading/></Hide>

            <Hide hide={is_loading}>

                {/*<Hide hide={not_validate_value.includes(client.active_code)}>*/}
                {/*    <Grid item xs={12}>*/}
                {/*        <Typography variant="h6" className="text-start">*/}
                {/*            {getLabel("activation_code")} : <span className="primary-color">{client.active_code}</span>*/}
                {/*        </Typography>*/}
                {/*    </Grid>*/}
                {/*</Hide>*/}

                <Hide hide={!have_permission || client_id === 'new'}>
                    <Grid item xs={12} className="text-end">
                        <Button
                            size="small"
                            startIcon={edit_data ? <CloseIcon/> : <EditIcon/>}
                            className={`text-white ${edit_data ? "bg-danger" : "primary-bg"}`}
                            onClick={handleEditAction}
                        >
                            {edit_data ? getLabel("cancel_edit") : getLabel("edit")}
                        </Button>
                    </Grid>
                </Hide>

                <Grid item xs={12} md={4}>
                    <CharFieldOnlyField
                        viewOnly={!edit_data}
                        required
                        helper_text={helper_text.first_name}
                        error={helper_text.first_name !== undefined}
                        value={client.first_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="first_name"
                        label="first_name"
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CharFieldOnlyField
                        viewOnly={!edit_data}
                        helper_text={helper_text.middle_name}
                        error={helper_text.middle_name !== undefined}
                        value={client.middle_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="middle_name"
                        label="middle_name"
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CharFieldOnlyField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.last_name}
                        error={helper_text.last_name !== undefined}
                        value={client.last_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="last_name"
                        label="last_name"
                    />
                </Grid>


                <Grid item xs={12} md={4}>
                    <CountriesAutoComplete
                        viewOnly={!edit_data}
                        label="nationality"
                        name="nationality"
                        onChangeHandle={handleInfoFieldChange}
                        helper_text={helper_text.nationality}
                        error={helper_text.nationality !== undefined}
                        value={client.nationality}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CustomTextField
                        required
                        viewOnly={!edit_data}
                        helper_text={helper_text.social_id}
                        error={helper_text.social_id !== undefined}
                        value={client.social_id}
                        onChangeHandle={handleInfoFieldChange}
                        name="social_id"
                        label="civil_id"
                        onBlur={onBlurSocialId}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DateField
                        viewOnly={!edit_data}
                        name="birth_date"
                        error={helper_text.birth_date !== undefined}
                        helper_text={helper_text.birth_date}
                        label="birth_date"
                        value={client.birth_date}
                        onChangeHandle={handleInfoFieldChange}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <PhoneNumberField
                        viewOnly={!edit_data}
                        required
                        helper_text={helper_text.mobile}
                        error={helper_text.mobile !== undefined}
                        value={client.mobile}
                        onChangeHandle={handleInfoFieldChange}
                        name="mobile"
                        label="mobile"
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CustomTextField
                        viewOnly={!edit_data}
                        helper_text={helper_text.email}
                        error={helper_text.email !== undefined}
                        value={client.email}
                        onChangeHandle={handleInfoFieldChange}
                        name="email"
                        label="email"
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <ClientAutoComplete
                        viewOnly={!edit_data}
                        name='referred_by'
                        label="pages.clientsinfo.referred_by"
                        onChangeHandle={handleInfoFieldChange}
                        helper_text={helper_text.referred_by}
                        error={helper_text.referred_by !== undefined}
                        value={client.referred_by}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CharFieldOnlyField
                        viewOnly={!edit_data}
                        helper_text={helper_text.em_contact_name}
                        error={helper_text.em_contact_name !== undefined}
                        value={client.em_contact_name}
                        onChangeHandle={handleInfoFieldChange}
                        name="em_contact_name"
                        label="emergency_contact_name"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <PhoneNumberField
                        viewOnly={!edit_data}
                        helper_text={helper_text.em_contact_telephone}
                        error={helper_text.em_contact_telephone !== undefined}
                        value={client.em_contact_telephone}
                        onChangeHandle={handleInfoFieldChange}
                        name="em_contact_telephone"
                        label="emergency_contact_telephone"
                    />
                </Grid>

                <Grid item xs={12}>
                    <GenderRadioButton
                        viewOnly={!edit_data}
                        label="gender"
                        name="gender"
                        required={true}
                        helper_text={helper_text.gender}
                        error={helper_text.gender !== undefined}
                        value={client.gender}
                        onChangeHandle={handleInfoFieldChange}
                    />
                </Grid>

                <AddressFields
                    viewOnly={!edit_data}
                    helper_text={helper_text}
                    value={client}
                    onChangeHandle={handleInfoFieldChange}
                />

                <Grid item xs={12}>
                    <CustomCheckBox
                        viewOnly={!edit_data || !Permissions.checkPermission(permissions_data.client.change_vip)}
                        value={client.is_vip}
                        name='is_vip'
                        onChangeHandle={handleInfoFieldChange}
                        label="vip"
                    />
                </Grid>

                {client_id !== 'new' && (
                    <>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        label="created_by"
                                        disabled
                                        value={client.created_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_created"
                                        value={client.date_created}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="modified_by"
                                        value={client.modified_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_modified"
                                        value={client.date_modified}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                    </>
                )}

                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowDelete={Permissions.checkAllPermissions([permissions_data.client.delete]) && client_id !== 'new'}
                    deleteAction={handleDeleteButton}
                    allowBack={true}
                    backAction={handleBackButton}
                    allowExtraSave={edit_data}
                    extraSaveAction={() => handleClientSaveButton(true)}
                    extraSaveLabel="save_&_create_membership"
                    allowSave={edit_data}
                    saveAction={() => handleClientSaveButton(false)}
                />
            </Hide>

        </>

    );

}
