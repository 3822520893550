import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {
    BaseMembershipPaymentURL,
} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {CustomRadioGroup} from "../../../../components/CustomRadioGroup";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../../localization/MainLocalization";


export const SelectCashBoxForDeletePaymentModal = props => {
    const [cash_box , setCashBox] = useState("");
    const [helper_text , setHelperText] = useState({});
    const [is_loading , setIsLoading] = useState(false);

    const view_options = [
        {title: "my_cash_box", value: "my cash box"},
        {title: "Cash_box_of_employee_who_created", value: "staff cash box", end_title: `"${props.created_by_name}"`},
    ]

    const handleFieldChange = (name, value) =>{
        setCashBox(value);
    }
    const handleSaveButton = async () => {
        await setIsLoading(true);

        let response = await  httpRequestWithAuth(`${BaseMembershipPaymentURL}${props.payment_id}/`,"DELETE", {cash_box});

        if (response.status) {
            await props.handleModalClose(true)
        } else {
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };


    return (

            <CustomModal
                open={props.open}
            >
                    <Grid container spacing={2}>

                        <FormHeader
                            title="select_cash_box_form"
                            handleClose={props.handleModalClose}
                        />

                        <Hide hide={!is_loading}>
                            <Loading/>
                        </Hide>

                        <Hide hide={is_loading}>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className='p-1 text-danger text-start'>
                                {props.payment_type === "Payment" ?
                                    getLabel("please_select_a_cash_box_to_deduct_the_amount_from,_this_is_required_to_delete_cash_payment")
                                    : getLabel("please_select_a_cash_box_to_refund_the_amount_to,_this_is_required_to_delete_cash_refund_payment") }
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <CustomRadioGroup
                                label="cash_box"
                                name="cash_box"
                                required
                                helper_text={helper_text.cash_box}
                                error={helper_text.cash_box !== undefined}
                                value={cash_box + ""}
                                onChangeHandle={handleFieldChange}
                                options={view_options}
                            />
                        </Grid>

                            <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={props.handleModalClose}
                            handleSaveButton={handleSaveButton}
                        />
                        </Hide>
                    </Grid>
            </CustomModal>
        );
}
