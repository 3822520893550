import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {instance} from "../../../../auth/axios_instance";
import {getLabel} from "../../../../localization/MainLocalization";
import MaterialTable from "material-table";
import {BaseMembershipURL} from "../../../../urls";
import Button from "@material-ui/core/Button";
import {sub_directory} from "../../../../App";


export const UnPaidMemberships = props => {
    const columns =[
        {title: getLabel("name"), field: 'short_name'},
        {title: getLabel("location"), field: 'location_name'},
        {title: getLabel("service"), field: 'service_name'},
        // {title: getLabel("start_date"), field: 'start_date_view'},
        // {title: getLabel("end_date"), field: 'end_date_view'},
        // {title: getLabel("total_price"), field: 'total_price'},
        // {title: getLabel("paid"), field: 'paid'},
        {title: getLabel("balance"), field: 'balance'},
        {
            render: rowData => {
                return (
                    <Button size="small"
                            variant="outlined"
                            onClick={() => props.history.push({
                                pathname: sub_directory +'memberships/edit',
                                state: {
                                    id: rowData.id,
                                    tag: rowData.short_name
                                }
                            })}>
                        {getLabel("view")}
                    </Button>
                )
            }
        }
    ];


    return (
        <div className='card-paper-1 h-100 w-100'>
            <Typography variant="h6" className="text-start mb-2">
                Unpaid Memberships
            </Typography>

            <MaterialTable
                columns={columns}
                data={query =>
                    new Promise((resolve, reject) => {
                        let url = BaseMembershipURL;
                        url += '?page=' + (query.page + 1) +  '&balance=true';


                        instance.get(url)
                            .then(result => {
                                resolve({
                                    data: result.data.results,
                                    page: result.data.page - 1,
                                    totalCount: result.data.total,
                                })
                            })
                    })
                }

                style={{
                    boxShadow: "none",
                }}
                options={{
                    search: false,
                    toolbar: false,
                    pageSize: 10,
                    pageSizeOptions: [10]
                }}
            />
        </div>
    );
}
