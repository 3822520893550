import React from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../../assets/scss/Static.scss';
import {CustomTextField} from "../../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../../components/FormHeader";
import {FormActionButtons} from "../../../../../components/FormActionButton";
import {CustomModal} from "../../../../../components/CustomModal";
import {CustomCheckBox} from "../../../../../components/CustomCheckBox";
import {SelectField} from "../../../../../components/fields/SelectField";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../../../localization/MainLocalization";

export const PaymentMethodFormModal = props => {

    return (
        <>
            <CustomModal
                open={props.open}
            >

                    <Grid container spacing={3}>

                        <FormHeader
                            title="expense_category"
                            handleClose={props.handleModalClose}
                        />

                        <Grid item xs={12}>
                            <CustomTextField
                                required={true}
                                error={props.helper_text.name !== undefined}
                                helper_text={props.helper_text.name}
                                name="name"
                                label="name"
                                value={props.data.name}
                                onChangeHandle={props.onChangeHandle}
                            />
                        </Grid>

                        <Grid item xs={12}  md={6}>
                            <SelectField
                                required={true}
                                label="commission_type"
                                name="commission_type"
                                value={props.data.commission_type}
                                onChangeHandle={props.onChangeHandle}
                                options={[{title:"Fixed", value:"Fixed"},{title:"Percentage", value:"Percentage"}]}
                                error={props.helper_text.commission_type !== undefined}
                                helperText={props.helper_text.commission_type}
                            />
                        </Grid>

                        <Grid item xs={12}  md={6}>
                            <CustomTextField
                                required={true}
                                error={props.helper_text.commission !== undefined}
                                helper_text={props.helper_text.commission}
                                name="commission"
                                label="commission"
                                value={props.data.commission}
                                onChangeHandle={props.onChangeHandle}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <CustomCheckBox
                                value={props.data.is_active}
                                name='is_active'
                                onChangeHandle={props.onChangeHandle}
                                label="is_active"
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <Typography variant="h6" className='p-1'>
                                {getLabel("assigned_to")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <CustomCheckBox
                                value={props.data.memberships}
                                name='memberships'
                                onChangeHandle={props.onChangeHandle}
                                label="memberships"
                            />
                        </Grid>


                        <Grid item xs={12} md={3}>
                            <CustomCheckBox
                                value={props.data.expenses}
                                name='expenses'
                                onChangeHandle={props.onChangeHandle}
                                label="expenses"
                            />
                        </Grid>


                        <Grid item xs={12} md={3}>
                            <CustomCheckBox
                                value={props.data.rents}
                                name='rents'
                                onChangeHandle={props.onChangeHandle}
                                label="rents"
                            />
                        </Grid>


                        <Grid item xs={12} md={3}>
                            <CustomCheckBox
                                value={props.data.claims}
                                name='claims'
                                onChangeHandle={props.onChangeHandle}
                                label="claims"
                            />
                        </Grid>




                        <FormActionButtons
                            errorMsg={props.helper_text.non_field_errors}
                            handleCancelButton={props.handleModalClose}
                            handleSaveButton={props.handleSaveForm}
                        />

                    </Grid>
            </CustomModal>
        </>
    );
};