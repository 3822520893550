import Grid from "@material-ui/core/Grid";
import {CustomModal} from "../../../../components/CustomModal";
import React, {useEffect, useState} from "react";
import {FormHeader} from "../../../../components/FormHeader";
import {ClassesClientsAttendees} from "../ClassesClientsAttendees";
import {CustomTabs} from "../../../../components/CustomTabs";
import Tab from "@material-ui/core/Tab";
import {getLabel} from "../../../../localization/MainLocalization";
import Permissions from "../../../../auth/permissions";
import {permissions_data} from "../../../../auth/permission_data";
import Paper from "@material-ui/core/Paper";
import {Hide} from "../../../../components/Hide";
import {ClassesAthletesAttendees} from "../ClassesAthletesAttendees";
import {CustomTab} from "../../../../components/CustomTab";

export const ViewClassAttendance = props =>{
    const [currentTab, setCurrentTab] = useState(0);


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };

    useEffect(()=>{
        if (!props.for_clients){
            setCurrentTab(1);
        }

        if (!props.for_clients && !props.for_athletes){
            props.handleModalClose()
        }
    }, [])

    return(
        <CustomModal
            open={props.open}>
        <Grid container spacing={3}>

            <FormHeader
                title="attendance"
                handleClose={props.handleModalClose}
            />

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >
                {(props.for_athletes && props.for_clients && Permissions.checkPermission(permissions_data.class_attendance.view)) && (
                <CustomTab label={getLabel("clients_attendance")} id={0}/>
                )}

                {(props.for_clients && props.for_athletes && Permissions.checkPermission(permissions_data.class_attendance.view)) && (
                <CustomTab label={getLabel("athletes_attendance")} id={1}/>
                )}

            </CustomTabs>


            <Hide hide={currentTab !== 0}>
            <ClassesClientsAttendees
                class_id={props.class_id}
                modalView
            />
            </Hide>

            <Hide hide={currentTab !== 1}>
            <ClassesAthletesAttendees
                class_id={props.class_id}
                modalView
            />
            </Hide>


        </Grid>
        </CustomModal>

    )

}
