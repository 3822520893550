import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import {getLabel} from "../localization/MainLocalization";

export const CustomRadioGroup = props => {
    return (
        <FormControl fullWidth className="mt-2">
            <FormLabel
                       error={props.error}
                       className="text-start"
            >

                {getLabel(props.label)}
                <FormHelperText component='span'
                                              className='text-danger '>{props.required ? "*" : ""} {props.helper_text}</FormHelperText>
            </FormLabel>

            <RadioGroup
                name={props.name}
                value={props.value || ""}
                onChange={(e) => {
                    if (!props.viewOnly){
                        props.onChangeHandle(e.target.name, e.target.value);
                    }
                }}
                row
            >
                {(props.options || []).map(Option=>(
                    <FormControlLabel value={Option.value} control={<Radio/>} label={`${Option.start_title || ""} ${getLabel(Option.title)} ${Option.end_title || ""}`}/>

                ))}
            </RadioGroup>
        </FormControl>
    );
};
