
export let lang_en = {
        "id": "ID",
        "username": "Username",
        "password": "Password",
        "name": "Name",
        "first_name": "First Name",
        "second_name": "Second Name",
        "middle_name": "Middle Name",
        "last_name": "Last Name",
        "start_date": "Start Date",
        "end_date": "End Date",
        "start_time": "Start Time",
        "end_time": "End Time",
        "branches": "Branches",
        "service": "Service",
        "services": "Services",
        "packages": "Packages",
        "package": "Package",
        "price_list":"Price List",
        "default_price_list": "Default Price List",
        "prices": "Prices",
        "price": "Price",
        "total": "Total",
        "gender": "Gender",
        "birthday": "Birthday",
        "birth_date": "Birth Date",
        "branch": "Branch",
        "profile": "Profile",
        "logout": "LogOut",
        "signin": "Sign In",
        "signup": "Sign Up",
        "search": "Search",
        "clients": "Clients",
        "client": "Client",
        "memberships": "Memberships",
        "membership": "Membership",
        "employee": "Employee",
        "employees": "Employees",
        "actions": "Actions",
        "action": "Action",
        "mobile": "Mobile",
        "telephone": "Telephone",
        "social_id": "Soical ID",
        "nationality": "Nationality",
        "add": "Add",
        "remove": "Remove",
        "add_all": "Add All",
        "remove_all": "Remove All",
        "delete": "Delete",
        "save": "Save",
        "cancel": "Cancel",
        "close": "Close",
        "paid": "Paid",
        "balance": "Balance",
        "male": "Male",
        "female": "Female",
        "none": "None",
        "change_lang": "Change Language To Arabic",
        "yes": "Yes",
        "no": "No",
        "account": "Account",
        "status": "Status",
        "notes": "Notes",
        "duration": "Duration",
        "duration_days": "Duration (Days)",
        "date_created": "Date Created",
        "date_modified": "Date Modified",
        "approve": "Approve",
        "reject": "Reject",
        "sessions": "Sessions",
        "address": "Address",
        "coordinates": "Coordinates",
        "timezone": "Timezone",
        "tax": "Tax",
        "discount": "Discount",
        "amount": "Amount",
        "passport_number": "Passport Number",
        "email": "E-mail",
        "is_active": "Is Active",
        "Disactive": "Disactive",
        "country": "Country",
        "currency_symbol": "Currency Symbol",
        "emergency_contact_name": "Emergency Contact Name",
        "emergency_contact_telephone": "Emergency Contact Telephone",
        "request_no": "Request No",
        "feedback_by": "Feedback By",
        "feedback_date": "Feedback Date",
        "other": "Other",
        "cash": "Cash",
        "debit": "Debit",
        "credit": "Credit",
        "pay": "Pay",
        "refund": "Refund",
        "back": "Back",
        "for": "For",
        "males": "Males",
        "females": "Females",
        "offers": "Offers",
        "type": "Type",
        "description": "Description",
        "equipment": "Equipment",
        "qty": "Quantity",
        "increase": "Increase",
        "decrease": "Decrease",
        "received": "Received",
        "delivered": "Delivered",
        "total_qty": "Total Qty",
        "available_qty": "Available Quantity",
        "rented_qty": "Rented Qty",
        "area":"Area",
        "city":"City",
        "locations": "Locations",
        "location": "Location",
        "address_line_one" : "Address line one",
        "address_line_two" : "Address line two",
        "address_line_three" : "Address line three",
        "map_location": "Map Location",
        "staff": "Staff",
        "change_photo": "Change Photo",
        "role": "Role",
        "permission_group": "Permission Group",
        "change_password": "Change Password",
        "assigned_to": "Assigned To",
        "all_locations": "All Locations",
        "details": "Details",
        "information": "Information",
        "setting": "Setting",
        "available_at": "Available at",
        "show": "Show",
        "cancellation": "Cancellation",
        "freezes": "Freezes",
        "payments": "Payments",
        "cancellation_date": "Cancellation Date",
        "title": "Title",
        "date": "Date",
        "expenses": "Expenses",
        "expense": "Expense",
        "ex_number": "Expense number",
        "ex_date": "Expense Date",
        "add_expense": "Add Expense",
        "edit_expense": "Edit Expense",
        "view_expense": "View Expense",
        "expense_category": "Expense Category",
        "add_expense_category": "Add Expense Category",
        "edit_expense_category": "Edit Expense Category",
        "open": "Open",
        "payment_information": "Payment Information",
        "expense_information": "Expense Information",
        "expense_attachment": "Expense Attachment",
        "attachment": "Attachments",
        "add_attachment": "Add Attachment",
        "edit_attachment": "Edit Attachment",
        "cost": "Cost",
        "remove_file": "Remove File",
        "paid_by": "Paid By",
        "add_payment_method": "Add Payment Method",
        "edit_payment_method": "Edit Payment Method",
        "commission_type" : "Commission Type",
        "commission" : "Commission",
        "invoices" : "Invoices",
        "finance" : "Finance",
        "bank_transfers" : "Bank Transfers",
        "add_bank_transfer" : "Add Bank Transfer",
        "edit_bank_transfer" : "Edit Bank Transfer",
        "view_bank_transfer" : "View Bank Transfer",
        "bank_account" : "Bank Account",
        "add_bank_account" : "Add Bank Account",
        "edit_bank_account" : "Edit Bank Account",
        "view_bank_account" : "View Bank Account",
        "bank_transactions" : "Bank Transactions",
        "current_balance" : "Current Balance",
        "sender" : "Sender",
        "total_price": "Total Price",
        "access_permissions": "Access permissions",
        "reference": "Reference",
        "payment_date": "Payment Date",
        "payment_type": "Payment Type",
        "payment_method": "Payment Method",
        "add_payment": "Add Payment",
        "add_refund": "Add Refund",
        "number": "Number",
        "rents": "Rents",
        "add_rent": "Add Rent",
        "edit_rent": "Edit Rent",
        "activate_rent" : "Activate Rent",
        "cancel_rent" : "Cancel Rent",
        "transfer_number" : "Transfer Number",
        "recipient" : "Recipient",
        "cash_transfer" : "Cash Transfer",
        "new_transfer" : "New Transfer",
        "add_transfer" : "Add Transfer",
        "sent_transfer" : "Sent Transfer",
        "received_transfer" : "Received Transfer",
        "transfer_form" : "Transfer Form",
        "cancel_transfer" : "Cancel Transfer",
        "confirm" : "Confirm",
        "cash_log" : "Cash Log",
        "sender_type" : "Sender Type",
        "recipient_type" : "Recipient Type",
        "sender_current_balance" : "Sender Current Balance",
        "recipient_current_balance" : "Recipient Current Balance",
        "pending_balance" : "Pending Balance",
        "free_balance" : "Free Balance",
        "available_balance" : "Available Balance",
        "target_staff" : "Target Staff",
        "add_cash_deposit" : "Add Cash Deposit",
        "add_funds" : "Add Funds",
        "add_withdraw" : "Add Withdraw",
        "received_as" : "Received As",
        "method" : "Method",
        "total_amount": "Total Amount",
        "from_cash_log": "From Cash Log",
        "bank_transfer_information": "Bank Transfer Information",
        "cheque": "Cheque",
        "bank_transfer" : "Bank Transfer",
        "paid_from" : "Paid From",
        "personal_money" : "Personal Money",
        "reimbursements" : "Reimbursements",
        "remaining" : "Remaining",
        "view_reimbursement" : "View Reimbursement",
        "attachments" : "Attachments",
        "add_class" : "Add Class",
        "edit_class" : "Edit Class",
        "classes" : "Classes",
        "schedule" : "Schedule",
        "limit" : "Limit",
        "duration_minutes" : "Duration (Minutes)",
        "saturday" : "Saturday",
        "sunday" : "Sunday",
        "monday" : "Monday",
        "tuesday" : "Tuesday",
        "wednesday" : "Wednesday",
        "thursday" : "Thursday",
        "friday" : "Friday",
        "from_date" : "From Date",
        "to_date" : "To Date",
        "add_time" : "Add Time",
        "days" : "Days",
        "vip_capacity" : "VIP Capacity",
        "capacity" : "Capacity",
        "room" : "Room",
        "edit_class_session" : "Edit Class Session",
        "add_class_session" : "Add Class Session",
        "class_sessions" : "Class Sessions",
        "is_locked" : "Is Locked",
        "registered_number" : "Registrations",
        "rooms": "Rooms",
        "add_room" : "Add Room",
        "edit_room" : "Edit Room",
        "room_form" : "Room Form",
        "edit_attendee" : "Edit Attendee",
        "add_attendee" : "Add Attendee",
        "attendee_form" : "Attendee Form",
        "attendee": "Attendee",
        "attendees": "Attendance",
        "is_present": "Is present",
        "is_cancelled": "Is cancelled",
        "added_by": "Added By",
        "group_by_date": "Group By Date",
        "class_session" : "Class Session",
        "add_class_sessions" : "Add Class Session",
        "edit_class_sessions" : "Edit Class Session",
        "add_screen": "Add Screen",
        "edit_screen": "Edit Screen",
        "screen": "Screen",
        "screens": "Screens",
        "workout_screens": "Workout Screens",
        "workout": "Workout",
        "add_workout": "Add Workout",
        "edit_workout": "Edit Workout",
        "workout_form": "Workout Form",
        "content": "Content",
        "header": "Header",
        "display_screen": "Display Screen",
        "next": "Next",
        "duplicate_workout": "Duplicate Workout",
        "workouts": "Workouts",
        "whole_day": "Whole Day",
        "view_for": "View For",
        "add_section": "Add Section",
        "edit_section": "Edit Section",
        "workout_section_form": "Workout Section Form",
        "all_class_sessions": "All Class Sessions",
        "add_location": "Add Location",
        "edit_location": "Edit Location",
        "preferred_training_time": "Preferred Training Time",
        "invoice_date": "Invoice date",
        "dashboard": "Dashboard",
        "view": "View",
        "confirm_password": "Confirm Password",
        "company_name": "Company Name",
        "business_name": "Business Name",
        "personal_info": "Personal Info.",
        "entity_info": "Entity Info.",
        "user_info": "User Info.",
        "confirm_&_submit": "Confirm & Submit",
        "already_have_an_account?_sign_in": "Already have an account? Sign in",
        "submit": "Submit",
        "personal_information": "Personal Information",
        "entity_information": "Entity information",
        "user_information": "User Information",
        "membership_info": "Membership Information",
        "freezed": "Freezed",
        "transfer": "Transfer",
        "upgrade_duration": "Upgrade Duration",
        "alert": "Alert",
        "ok": "Ok",
        "change_invoice_date": "Change invoice date",
        "change_start_date": "Change start date",
        "sub_directory": "Sub Directory",
        "view_transfer_from": "View transfer from",
        "view_transfer_to": "View transfer to",
        "create": "Create",
        "show_first_name_with_spaces": "Show first name with spaces",
        "add_freeze": "Add freeze",
        "edit_freeze": "Edit freeze",
        "location_freeze_form": "Location freeze form",
        "memberships_freezed_number": "Memberships freezed number",
        "membership(s)": "Membership(s)",
        "membership_invoice_prefix": "Membership invoice prefix",
        "is_editable": "Is viewOnly",
        "reason": "Reason",
        "attended_sessions": "Attended sessions",
        "session(s)": "Session(s)",
        "invoice_number": "Invoice number",
        "freeze_days": "Freeze days",
        "expense_invoice_prefix": "Expense invoice prefix",
        "client(s)": "Client(s)",
        "attendance": "Attendance",
        "reports": "Reports",
        "duration_type": "Duration type",
        "yearly": "Yearly",
        "monthly": "Monthly",
        "daily": "Daily",
        "change_extra_days_and_extra_sessions": "Change extra days and extra sessions",
        "extra_days": "Extra days",
        "extra_sessions": "Extra Sessions",
        "change_preferred_time": "Change preferred time",
        "change_notes": "Change Notes",
        "all_day": "All day",
        "apply": "Apply",
        "delete_all": "Delete All",
        "applied": "Applied",
        "cant_apply_before_end_date": "Can't apply before end date",
        "memberships_freezes": "Memberships freezes",
        "confirm_apply": "Confirm Apply",
        "read_notes_and_confirm_to_apply": "Read notes and confirm to apply",
        "via": "Via",
        "staff_balance": "Staff balance",
        "new_memberships": "New memberships",
        "renewed_memberships": "Renewed Memberships",
        "all_services": "All services",
        "all": "All",
        "collect_cash": "Collect cash",
        "recurring_classes": "Recurring classes",
        "list": "List",
        "training": "Training",
        "financial": "Financial",
        "general": "General",
        "generate_classes": "Generate classes",
        "add_recurring_class": "Add recurring class",
        "edit_recurring_class": "Edit recurring class",
        "edit_classes": "Edit Classes",
        "view_classes": "View Classes",
        "edit_all": "Edit all",
        "equal": "Equal",
        "greater_than": "Greater than",
        "less_than": "Less than",
        "operation": "Operation",
        "edit_actions": "Edit Actions",
        "change_location_,_room_and_service": "Change Location, Room and Service",
        "change_staff": "Change Staff",
        "change_time_duration": "Change time duration",
        "change_date": "Change date",
        "day": "Day",
        "timing": "Timing",
        "time_form": "Time Form",
        "filter": "Filter",
        "add_client": "Add Client",
        "add_membership": "Add Membership",
        "screen_code": "Screen code",
        "apply_on_memberships_start_date": "Apply on memberships start date",
        "can_edit_by_membership": "Can edit by membership",
        "before": "Before",
        "after": "After",
        "on": "On",
        "apply_start_date": "Apply start date",
        "apply_condition": "Apply condition",
        "out_of_balance": "Out of balance",
        "activate_on_location": "Activate on location",
        "deactivate_from_location": "Deactivate from location",
        "shifted_memberships": "Shifted memberships",
        "confirmation": "Confirmation",
        "banners": "Banners",
        "edit_banner": "Edit banner",
        "add_banner": "Add Banner",
        "mobile_app": "Mobile App",
        "image": "Image",
        "url": "URL",
        "link": "Link",
        "move_up": "Move up",
        "move_down": "Move down",
        "vip_client_can_book_classes_within_days": "VIP client can book classes within days",
        "normal_client_can_book_classes_within_days": "Normal client can book classes within days",
        "vip": "VIP",
        "activation_code": "Activation code",
        "unregister_from_classes": "Unregister from classes",
        "cancelled_by": "Cancelled by",
        "today": "Today",
        "tomorrow": "Tomorrow",
        "client_form": "Client form",
        "view_class": "View class",
        "cancel_edit": "Cancel edit",
        "edit": "Edit",
        "no_logo": "No logo",
        "no_photo": "No photo",
        "invoice_id": "Invoice ID",
        "save_&_create_membership": "Save & Create membership",
        "shared_locations": "Shared location",
        "all_prices": "All prices",
        "select_prices": "Select prices",
        "save_done": "Save done",
        "select_locations": "Select locations",
        "for_locations": "For locations",
        "for_prices": "For prices",
        "civil_id": "Civil ID",
        "workout_sections": "Workout Sections",
        "view_workout_sections": "View workout sections",
        "training_area": "Training area",
        "training_area_form": "Training area form",
        "add_training_area": "Add training area",
        "edit_training_area": "Edit training area",
        "training_areas": "Training areas",
        "change_location_,_training_area_and_service": "Change Location,Training area and Service",
        "access_privileges": "Access privileges",
        "claims": "claims",
        "my_claims": "My claims",
        "new_claim": "New claim",
        "request_type": "Request type",
        "claim_type": "Claim type",
        "is_new_purchase": "Is new purchase",
        "is_exists_purchase": "Is exists purchase",
        "cash_log_amount": "Cash log amount",
        "personal_money_amount": "Personal money amount",
        "add_claim": "Add claim",
        "claim_id": "Claim ID",
        "staff_claims": "Staff claims",
        "pending": "Pending",
        "confirmed": "Confirmed",
        "rejected": "Rejected",
        "settled": "Settled",
        "cancelled": "Cancelled",
        "personal_amount": "Personal amount",
        "confirm_claim": "Confirm claim",
        "link_with_exists_expense": "Link with exists expense",
        "create_new_expense": "Create new expense",
        "paid_for": "Paid for",
        "save_&_submit": "Save & submit",
        "submitted": "Submitted",
        "approved": "Approved",
        "partially_paid": "Partially paid",
        "returned": "Returned",
        "approve_claim": "Approve claim",
        "reject_or_return": "Reject or Return",
        "return": "Return",
        "reject_or_return_claim": "Reject or Return claim",
        "amount_form_personal_money": "Amount form personal money",
        "amount_form_cash_box": "Amount form cash box",
        "total_invoice_amount": "Total invoice amount",
        "add_vendor": "Add vendor",
        "vendor": "Vendor",
        "vendors": "Vendors",
        "edit_contact": "Edit contact",
        "add_contact": "Add contact",
        "contacts": "Contacts",
        "contact_form": "Contact form",
        "phone": "Phone",
        "cash_box_amount": "Cash box amount",
        "view_file": "View file",
        "file": "File",
        "settlement_to": "Settlement to",
        "cash_box": "Cash box",
        "payment": "Payment",
        "change_discount": "Change discount",
        "unlimited_access": "Unlimited access",
        "one_day_access": "One day access",
        "limited_access": "Limited access",
        "created_by": "Created by",
        "modified_by": "Modified by",
        "pending_transfer": "Pending transfer",
        "coach": "Coach",
        "my_cash_box": "My cash box",
        "cash_box_of_the_employee_who_create_the_payment": "Cash box of the employee who create the payment",
        "select_cash_box_form": "Select cash box form",
        "please_select_a_cash_box_to_deduct_the_amount_from,_this_is_required_to_delete_cash_payment": "Please select a cash box to deduct the amount from, this is required to delete Cash payment",
        "please_select_a_cash_box_to_refund_the_amount_to,_this_is_required_to_delete_cash_refund_payment": "Please select a cash box to refund the amount to, this is required to delete Cash refund payment",
        "original_price": "Original Price",
        "Cash_box_of_employee_who_created": "Cash box of employee who created",
        "classes_register_time": "Classes register time",
        "enable_classes_registration_on_specific_time_one_day_before": "Enable classes registration on specific time one day before",
        "change_logs": "Change logs",
        "field_name": "Field name",
        "old_value": "Old value",
        "new_value": "New value",
        "change": "Change",
        "from": "From",
        "to": "To",
        "by": "By",
        "create_account": "Create account",
        "need_to_create_account_for_staff_first": "Need to create account for staff first",
        "absent": "Absent",
        "present": "Present",
        "register": "Register",
        "unregister": "Unregister",
        "invoice": "Invoice",
        "change_gender": "Change gender",
        "clear_filter": "Clear filter",
        "change_capacity": "Change capacity",
        "membership_changed_freeze": "Membership changed freeze",
        "activate_memberships": "Activate memberships",
        "confirm_generate_classes": "Confirm generate classes",
        "classes_name": "Classes name",
        "available_amount": "Available amount",
        "pending_amount": "Pending amount",
        "athletes": "Athletes",
        "athlete": "Athlete",
        "add_athlete": "Add athlete",
        "edit_athlete": "Edit athlete",
        "click_or_drop_attachment_here": "Click or Drop Attachment here",
        "click_or_drop_photo_here": "Click or Drop Photo here",
        "file_uploaded": "File uploaded",
        "remove_photo": "Remove photo",
        "active": "Active",
        "inactive": "Inactive",
        "view_categories": "View categories",
        "add_season": "Add season",
        "edit_season": "Edit season",
        "season_form": "Season form",
        "seasons": "Seasons",
        "categories": "Categories",
        "groups": "Groups",
        "view_groups": "View groups",
        "category_form": "Category form",
        "add_category": "Add category",
        "edit_category": "Edit category",
        "add_group": "Add group",
        "edit_group": "Edit group",
        "group_form": "Group form",
        "view_athletes": "View athletes",
        "athlete_form": "Athlete form",
        "view_athlete_profile": "View athlete profile",
        "class_for": "Class for",
        "clients_attendance": "Clients attendance",
        "athletes_attendance": "Athletes attendance",
        "athletes_attendance_is_required": "Athletes attendance is required",
        "select_category_or_write_new_category_name": "Select category or write new category name",
        "category": "Category",
        "athletes_group": "Athletes group",
        "teams": "Teams",
        "add_team": "Add team",
        "edit_team": "Edit team",
        "for_athlete_group": "For athlete group",
        "any_athlete_group": "Any athlete group",
        "selected_athlete_group": "Selected athlete group",
        "team_form": "Team form",
        "season": "Season",
        "team": "Team",
        "season_name": "Season name",
        "athlete_name": "Athlete name",
        "TBA": "TBA",
        "unregistered": "Unregistered",
        "WOD": "WOD",
        "attendance_percentage": "Attendance percentage",
        "athlete_attendee_form": "Athlete attendee form",
        "auto_refresh": "Auto refresh",
        "full_screen": "Full screen",
        "optional_athletes_capacity": "Optional athletes capacity",
        "required_teams_to_attend": "Required teams to attend",
        "optional_teams_to_attend": "Optional teams to attend",
        "clients_capacity": "Clients capacity",
        "optional_groups_to_attend": "Optional groups to attend",
        "required_groups_to_attend": "Required groups to attend",
        "optional_attended_classes": "Optional attended classes",
        "required_attended_classes": "Required attended classes",
        "classes_per_week": "Classes per week",
        "required": "Required",
        "optional": "Optional",
        "without_team": "Without team",
        "classes_for": "Classes For",
        "change_optional_athletes_capacity": "Change optional athletes capacity",
        "change_clients_capacity": "Change clients capacity",
        "change_class_for": "Change Class For",
        "change_date_and_teams": "Change Date And Teams",
        "add_to_team_type": "Add to team type",
        "bulk_action_error": "Bulk action error",
        "error": "Error",
        "errors": "Errors",
        "any": "Any",
        "remove_team": "Remove team",
        "remove_from_team_type": "Remove from team type",
        "allow": "Allow",
        "assigned_to_clients": "Assigned to clients",
        "change_assigned_to_clients": "Change assigned to clients",
        "add_athlete_team": "Add athlete team",
        "remove_athlete_team": "Remove athlete team",
        "location_error": "Location error",
        "training_area_error": "Training area error",
        "service_error": "Service error",
        "general_errors": "General errors",
        "staff_error": "Staff error",
        "start_time_error": "Start time error",
        "end_time_error": "End time error",
        "clients_capacity_error": "Clients capacity error",
        "date_error": "Date error",
        "required_team_filter": "Required team filter",
        "optional_team_filter": "Optional team filter",
        "contain_all": "Contain all",
        "contain_exact": "Contain exact",
        "contain_any": "Contain any",
        "exclude": "Exclude",
        "name_error": "Name error",
        "change_name": "Change name",
        "cancel_reason": "Cancel reason",
        "benchmark": "Benchmark",
        "benchmarks": "Benchmarks",
        "score_info": "Score Info.",
        "reps": "Reps",
        "rounds": "Rounds",
        "time": "Time",
        "weight": "Weight",
        "add_benchmark": "Add Benchmark",
        "edit_benchmark": "Edit Benchmark",
        "benchmark_form": "Benchmark Form",
        "unit": "Unit",
        "add_athlete_benchmark": "Add Athlete Benchmark",
        "athlete_benchmark_form": "Athlete Benchmark Form",
        "minutes": "Minutes",
        "seconds": "Seconds",
        "performance": "Performance",
        "view_performance_result": "View Performance Result",
        "restore_classes": "Restore Classes",
        "deleted_classes": "Deleted Classes",
        "lock_class": "Lock Class",
        "unlock_class": "Unlock Class",
        "lock_classes": "Lock Classes",
        "unlock_classes": "Unlock Classes",
        "male_only": "Male Only",
        "female_only": "Female Only",
        "male_&_female": "Male & Female",
        "male_or_female": "Male Or Female",
        "ending_within": "Ending Within",
        "ending_within_days": "Ending Within (Days)",

        "empty_data_source_message": "No records to display",
        "table.delete_text": "Are you sure you want to delete this data ?",

        "login.remember_me": "Remember me",
        "login.forgot_password": "Forgot password?",
        "login.no_account": "Don't have an account? Sign Up",

        "layout.appbar.menu.my_leave_request": "My Leave Request",

        "layout.sideDrawer.menu": "Menu",

        "layout.sideDrawer.main": "Main",
        "layout.sideDrawer.main.rents": "Rents",
        "layout.sideDrawer.main.reports": "Reports",
        "layout.sideDrawer.accounting": "Accounting",

        "layout.sideDrawer.store": "Store",
        "layout.sideDrawer.store.sales": "Sales",
        "layout.sideDrawer.store.companies": "Companies",
        "layout.sideDrawer.store.categories": "Categories",
        "layout.sideDrawer.store.products": "Products",

        "layout.sideDrawer.hr": "Human Resources",
        "layout.sideDrawer.hr.leaves": "Leaves",

        "layout.sideDrawer.setting.entity": "Entity",
        "layout.sideDrawer.setting.services": "Services",
        "layout.sideDrawer.setting.global_action": "Global Actions",
        "layout.sideDrawer.setting.export_data": "Export Data",

        "pages.dashboard.latest_memberships": "Latest Memberships",
        "pages.dashboard.clients_age_scale": "Clients AgeScale",
        "pages.dashboard.clients_up_coming_birthDay": "Birthdays",

        "pages.clients.add_client": "Add Client",
        "pages.clients.edit_client": "Edit Client",
        "pages.clients.view_client": "View Client",
        "pages.clients.add_membership": "Add Membership",

        "pages.clientsinfo.referred_by": "Referred By",
        "pages.clientsinfo.back": "Clients List",
        "pages.clientsinfo.header": "Client Information",

        "pages.staff.edit_staff": "Edit Staff",
        "pages.staff.add_staff": "Add Staff",

        "pages.staff.staff_info.tab_three": "Staff User",
        "pages.staff.staff_info.edit_staff_role": "Edit Staff Role",
        "pages.staff.staff_info.add_staff_role": "Add Staff Role",
        "pages.staff.staff_info.edit_staff_permission": "Edit Staff Permission",
        "pages.staff.staff_info.add_staff_permission": "Add Staff Permission",
        "pages.staff.staff_info.staff_permission.form.header": "Staff Permission",

        "pages.memberships.add_membership": "Add Membership",
        "pages.memberships.edit_membership": "Edit Membership",
        "pages.memberships.view_membership": "View Membership",
        "pages.memberships.view_membership_invoice": "View Membership Invice",

        "pages.membershipsinfo.back": "Memberships List",
        "pages.membershipsinfo.cancel": "Cancel Membership",
        "pages.membershipsinfo.activate": "Activate Membership",
        "pages.membershipsinfo.freezed_by": "Freezed By",
        "pages.membershipsinfo.freeze_date": "Freeze Date",
        "pages.membershipsinfo.payment_table.header": "Payment History",
        "pages.membershipsinfo.freeze_table.header": "Freeze History",
        "pages.membershipsinfo.add_freeze": "Add Freeze",
        "pages.membershipsinfo.edit_freeze": "Edit Freeze",
        "pages.membershipsinfo.extra_days": "Extra Days",
        "pages.membershipsinfo.extra_sessions": "Extra Sessions",
        "pages.membershipsinfo.payment_information.header": "Payment Information",
        "pages.membershipsinfo.invoice_num": "Invoice Number",
        "pages.membershipsinfo.invoice_date": "Invoice Date",
        "pages.membershipsinfo.original_price": "Original Price",
        "cancel.header": "Cancellation Membership Form",
        "cancel.cancel_reason": "Cancel Reason",
        "cancel.other_reason": "Other Reasons",
        "cancel_reason_1": "Bad Equipment",
        "cancel_reason_2": "The Gym Isn't clean",
        "cancel_reason_3": "It's too crowded",
        "cancel_reason_4": "Gym management fails to address concerns",
        "cancel_reason_5": "healthy issue",
        "pages.membershipsinfo.freeze_form.header": "Freeze Membership Form",
        "Payment_form.header": "Payment Form",
        "pages.membershipsinfo.Payment_form.payment_type": "Payment Type",
        "pages.membershipsinfo.Payment_form.payment_method": "Payment Method",

        "latest_rents_clients": "Latest Rents",
        "need_to_be_deliver": "To deliver",
        "need_to_be_collect": "To receive",
        "pages.rents.tabel_column.ends_during": "Ends during",
        "pages.rentsinfo.header": "Rent Information",
        "pages.rentsinfo.received_date": "Received Date",
        "pages.rentsinfo.delivered_date": "Delivered Date",
        "pages.rentsinfo.cancel": "Cancel Rent",

        "pages.employee_request.employee_id": "Employee ID",
        "pages.employee_request.new_leave": "Add Leaves",
        "pages.employee_request.old_leave": "Old Leaves",
        "pages.employee_request.add_leave": "Add Leaves",
        "pages.employee_request.leave_form.header": "Leave Form",

        "pages.locationinfo.edit_service": "Edit Service",
        "pages.locationinfo.deactivate_service": "Deactivate Service",
        "pages.locationinfo.activate_service": "Activate Service",
        "pages.locationinfo.service_packages_form.header": "Service & PriceLists Form",

        "pages.services.service_id": "Service ID",
        "pages.services.view_price_list": "View Price Lists",
        "pages.services.edit_service": "Edit Service",
        "pages.services.add_service": "Add Service",
        "pages.services.form.header": "Service Form",
        "pages.services.service_name": "Service Name",
        "pages.services.service_form.is_rental_service": "Is Rental Service",
        "pages.services.service_form.is_rental_service.note": "(Can't change once created)",

        "pages.packages.form.header": "Price List Form",
        "pages.packages.header": "Price Lists",
        "pages.packages.package_id": "Price List ID",
        "pages.packages.package_name": "Price List Name",
        "pages.packages.add_package": "Add Price List",
        "pages.packages.edit_package": "Edit Price List",
        "pages.packages.manage_package_equipment": "Manage Equipment",
        "pages.packages.view_offers": "View Offers",
        "pages.packages.view_prices": "View Prices",

        "pages.price_list.offers.title": "Price List offers",
        "pages.price_list.offers.deactivate_offer": "Deactivate Offer",
        "pages.price_list.offers.activate_offer": "Activate Offer",
        "pages.packages.package_offer.add_offer": "Add Offer",
        "pages.packages.package_offer.edit_offer": "Edit Offer",
        "pages.packages.package_offer.new_offer_prices": "Add Offer Prices",
        "pages.packages.package_offer.view_offer_prices": "View Offer Prices",
        "pages.packages.package_offer.offer_prices.header": "Offer Prices",
        "pages.packages.package_offer.price.form.original_price": "Original Price",
        "pages.packages.package_offer.price.form.offer_price": "Offer Price",
        "pages.packages.package_offer.price.form.original_duration": "Original Duration (Days)",
        "pages.packages.package_offer.price.form.offer_duration": "Offer Duration (Days)",
        "pages.packages.package_offer.price.form.original_sessions": "Original Sessions",
        "pages.packages.package_offer.price.form.offer_sessions": "Offer Sessions",
        "pages.packages.package_offer.price.form.original_freeze_days": "Original Freeze Days",
        "pages.packages.package_offer.price.form.offer_freeze_days": "Offer Freeze Days",

        "pages.prices.form.header": "Price Form",
        "pages.prices.price_id": "Price ID",
        "pages.prices.price_name": "Price Name",
        "pages.prices.freeze_days": "Freeze Days",
        "pages.prices.add_price": "Add Price",
        "pages.prices.edit_price": "Edit Price",

        "pages.global_actions.global_freeze": "Global Freezes",
        "pages.global_actions.global_freeze_id": "ID",
        "pages.global_actions.add_global_freeze": "Add Global Freeze",
        "pages.global_actions.edit_global_freeze": "Edit Global Freeze",

        "pages.entity.header.tab_one": "Entity Information",
        "pages.entity.header.tab_two": "Entity Setting",
        "pages.entity.change_logo": "Change Logo",
        "pages.entity.company_name": "Company Name",
        "pages.entity.business_name": "Business Name",
        "pages.entity.lock_ended_membership": "Lock ended memberships",
        "pages.entity.lock_after": "Lock After (Days)",

        "pages.my_profile.header": "My Profile Information",
        "pages.my_profile.height": "Height (CM)",

        "pages.my_request.header": "My Leaves",
        "pages.my_request.edit_leave": "Edit Leave",
        "pages.my_request.add_leave": "Add Leave",

        "pages.equipment.add_equipment": "Add Equipment",
        "pages.equipment.edit_equipment": "Edit Equipment",
        "pages.equipment.manage_qty": "Manage Qty",
        "pages.equipment.form.header": "Equipment Form",
        "pages.equipment.check_qty": "Check Qty",

};
