import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseFinanceExpenseCategoryURL} from "../../urls";

export const ExpenseCategoryComplete = props => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            let response = await httpRequestWithAuth(BaseFinanceExpenseCategoryURL, "GET", null, {
                is_active: true,
                category: props.category
            });
            if (response.status) {
                await setOptions(response.data);
            } else {
                await setOptions([]);
            }
        }

        fetchData();
    }, [])

    const handleOnChange = (event, value) => {
        if (!props.viewOnly) {
            value = value !== null ? value.id : "";
            props.onChangeHandle(props.name, value)
        }
    };

    const handleAutoCompleteValue = () => {
        let value = null;

        options.map(Data => {
            if (Data.id === props.value) {
                value = Data;
            }
            return 0;
        });

        return value;
    };


    return (
        <Autocomplete
            ListboxProps={{className:"list-box-autocomplete"}}
            renderOption={(option) => (
                <span className="w-100 text-start">{option.name}</span>
            )}
            disabled={props.disabled || props.viewOnly}
            options={options}
            getOptionLabel={option => option.name}
            renderInput={params => (
                <TextField variant="outlined" {...params}
                           label={<label>{getLabel(props.label)}<span
                               className='text-danger'>{props.required ? "*" : ""}</span></label>}
                           helperText={props.helper_text}
                           error={props.error}
                           fullWidth
                           disabled={props.disabled}
                           className={props.disabled ? "disabled-field" : ""}
                />
            )}
            value={handleAutoCompleteValue()}
            onChange={handleOnChange}
        />
    );
}
