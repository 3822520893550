import React, {useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {Hide} from "../../../components/Hide";
import {
    CreateStaffAttachments,
    DeleteStaffAttachments,
    ListStaffAttachments,
    UpdateStaffAttachments
} from "./staff_apis";
import {StaffAttachmentFormModal} from "./components/StaffAttachmentFormModal";
import {permissions_data} from "../../../auth/permission_data";


export const StaffAttachment = props =>{
       const {staff_id} = props;

            const columns = [
                {title: getLabel("name"), field: 'name'},
            ];
    const [attachments, setAttachments] = useState([]);
    const [helper_text, setHelperText] = useState({});
    const [form_open, setFormOpen] = useState(false);
    const [form_data, setFormData] = useState({});
    const [edit_attach, setEditAttach] = useState(true);
    const [is_loading, setISLoading] = useState(true);


    const listAttachmentsData = async () => {
        await setISLoading(true);

        let ListPaymentsResponse = await ListStaffAttachments(staff_id);
        if (ListPaymentsResponse.status) {
           await setAttachments(ListPaymentsResponse.data)
        }

        await setISLoading(false);
    };

    useEffect(()=>{
        listAttachmentsData();
    }, [])

    const onChangeHandle = (name, value) =>{
        setFormData({...form_data,[name]: value});
    }

    const handleModalOpen = async (data, edit) => {
        await setFormData(data);
        await setFormOpen(true);
        await setEditAttach(edit);
    };

    const handleModalClose = async () => {
        await listAttachmentsData();
        await setFormOpen(false);
        await setHelperText({})
        await setFormData({});
        await setEditAttach();

    };

    const handleSaveModal = async () => {
        await setISLoading(true);

        let response ;
        if (form_data.id === undefined){
             response = await CreateStaffAttachments({staff:staff_id, ...form_data});

        }else{
             response = await UpdateStaffAttachments(form_data.id, {name:form_data.name});
        }

        if (response.status){
            await handleModalClose();
        }else {
            setHelperText(response.error_feedback)
        }

        await setISLoading(false);
    };



        return(
            <>
                    <Hide hide={!form_open}>
                        <StaffAttachmentFormModal
                        open={form_open}
                        handleModalClose={handleModalClose}
                        data={form_data}
                        handleSaveForm={handleSaveModal}
                        helper_text={helper_text}
                        onChangeHandle={onChangeHandle}
                        is_loading={is_loading}
                        edit_attach={edit_attach}
                        />
                    </Hide>

                <Grid item xs={12}>
                    <MaterialTable
                        style={{padding: '0.5rem', boxShadow: "none"}}
                        is_loading={is_loading}
                        columns={columns}
                        data={attachments}
                        onRowClick={(event, rowData) => window.open(rowData.file)}
                        editable={Permissions.checkPermission(permissions_data.staff_attachment.delete) && {
                                onRowDelete: async attachmentData => {
                                    await DeleteStaffAttachments(attachmentData.id);
                                    await listAttachmentsData();
                                }
                        }}

                        actions={[
                            {
                            icon:()=> (
                                    <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                            startIcon={<AddIcon/>}>
                                        <Typography variant="body1">{getLabel('add_attachment')}</Typography>
                                    </Button>
                                ),
                                hidden: !Permissions.checkAllPermissions([permissions_data.staff_attachment.add]),
                                isFreeAction: true,
                                onClick: () => handleModalOpen({},true)
                            },
                            {
                                icon: () => (
                                    <Button  size="small"
                                             color="primary"
                                             variant="outlined">
                                        {getLabel("view_file")}
                                    </Button>
                                ),
                                tooltip:getLabel('view_file'),
                                onClick: (event, rowData) => window.open(rowData.file)
                            },
                            {
                                icon: 'edit',
                                tooltip:getLabel('edit_attachment'),
                                hidden: !Permissions.checkAllPermissions([permissions_data.staff_attachment.change]),
                                onClick: (event, rowData) => handleModalOpen(rowData,false)
                            }
                        ]}

                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false,
                            showTitle: false
                        }}

                        {...material_table_localization()}
                    />
                </Grid>
                </>
        );
}