import React, {Fragment, useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import {DataBox} from "../../../../components/DataBox";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseFinanceMyCashBalanceURL} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import Paper from "@material-ui/core/Paper";
import {Loading} from "../../../../components/Loading";

export const CashOnHand = props =>{
    const [data, setData] = useState([])
    const [is_loading, setIsLoading] = useState(true)

    useEffect(()=>{
        const fetchData = async () =>{
            let response = await httpRequestWithAuth(BaseFinanceMyCashBalanceURL, "GET")
            if (response.status){
                await setData(response.data)
            }
                await setIsLoading(false);
        }
        fetchData();
    }, [])

    return(
            <>
                <Hide hide={!is_loading}>
                        <Paper className="card-paper-1 h-100 w-100">
                            <Loading/>
                        </Paper>
                </Hide>

                <Hide hide={is_loading}>
                            <DataBox
                                header="Cash on Hand"
                                content={`${localStorage.getItem('currency_symbol')} ${data.current_balance}`}
                            />
                </Hide>
            </>
    )
}
