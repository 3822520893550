import React, {useContext, useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {ActionButtons} from "../../../components/ActionButton";
import {not_validate_value, sub_directory} from "../../../App";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {AlertContext} from "../../../store/use_context";
import {BaseAthleteBenchmarkPerformanceURL} from "../../../urls";
import {BenchmarkAutoComplete} from "../../../components/fields/BenchmarkAutoComplete";
import {DateField} from "../../../components/fields/DateField";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {Typography} from "@material-ui/core";


export const AthletePerformance = props => {
    const {athlete_id} = props;
    const [helper_text, setHelperText] = useState([])

    const [data, setData] = useState([])
    const [setting, setSetting] = useState({})
    const [benchmark_data, setBenchmarkData] = useState({})
    const [is_loading, setIsLoading] = useState(false)

    const listData = async () => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(`${BaseAthleteBenchmarkPerformanceURL(athlete_id)}`, "GET",null,  setting);
        if (response.status) {
            await setData(response.data);
            setHelperText({});
        }else{
            setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };


    const onChangeSetting = (name, value) => {
        let new_data = {...setting, [name]: value};

        if (name === "benchmark"){
            let benchmark_value = not_validate_value.includes(value)?{}:value;
            setBenchmarkData(benchmark_value);
            new_data[name] = benchmark_value.id;
        }

        setSetting(new_data);
    }


    const handleBackButton = () => {
        props.history.push(sub_directory + "athletes")
    }


    return (
        <>

            <Hide hide={!is_loading}>
                <Loading/>
            </Hide>

            <Hide hide={is_loading}>
            <Hide hide={data.length !==0}>
                <Grid item xs={12} className="d-flex text-center align-content-center align-items-center justify-content-center fixedHeight-400">
                    <Typography component="h1" variant="h5">
                        {getLabel("empty_data_source_message")}
                    </Typography>
                </Grid>
            </Hide>

            <Hide hide={data.length ===0}>
                <Grid item xs={12}>
                <div dir='ltr' className="fixedHeight-400 w-100">
                    <ResponsiveContainer width="100%" height='90%'>
                        <LineChart
                            data={data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date_view" />
                            <YAxis />
                            <Tooltip />
                            <Legend />

                            {benchmark_data.reps &&( <Line type="monotone" name="Reps" dataKey="reps" stroke="#0088FE" activeDot={{ r: 8 }} />)}
                            {benchmark_data.rounds &&( <Line type="monotone" name="Rounds" dataKey="rounds" stroke="#00C49F" activeDot={{ r: 8 }} />)}
                            {benchmark_data.weight &&( <Line type="monotone" name="Weight" dataKey="weight" stroke="#FFBB28" activeDot={{ r: 8 }} />)}
                            {benchmark_data.time &&( <Line type="monotone" name="Time" dataKey="time" stroke="#FF8042" activeDot={{ r: 8 }} />)}

                        </LineChart>
                    </ResponsiveContainer>
                </div>
                </Grid>
            </Hide>

            </Hide>



            <Grid item xs={12} md={4}>
                <BenchmarkAutoComplete
                    required
                    fullObject
                    error={helper_text.benchmark !== undefined}
                    helper_text={helper_text.benchmark}
                    name="benchmark"
                    label="benchmark"
                    value={setting.benchmark}
                    onChangeHandle={onChangeSetting}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <DateField
                    required
                    error={helper_text.start_date !== undefined}
                    helper_text={helper_text.start_date}
                    name="start_date"
                    label="start_date"
                    value={setting.start_date}
                    onChangeHandle={onChangeSetting}
                />
            </Grid>


            <Grid item xs={12} md={4}>
                <DateField
                    required
                    error={helper_text.end_date !== undefined}
                    helper_text={helper_text.end_date}
                    name="end_date"
                    label="end_date"
                    value={setting.end_date}
                    onChangeHandle={onChangeSetting}
                />
            </Grid>



            <Grid item xs={12} className="mb-4">
                <Button
                    disabled={is_loading}
                    variant="contained"
                    fullWidth
                    color="primary"
                    className='h-100'
                    onClick={listData}
                >
                    {getLabel("view_performance_result")}
                </Button>
            </Grid>


            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />
        </>
    );

}
