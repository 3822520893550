import React, {useEffect, useState} from "react";
import {CustomModal} from "../../../../../components/CustomModal";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {dir, not_validate_value} from "../../../../../App";
import TextField from "@material-ui/core/TextField";
import {getLabel} from "../../../../../localization/MainLocalization";
import {ListPricesForOffer} from "../offers_prices_apis";
import {CustomTextField} from "../../../../../components/fields/CustomTextField";
import {FormActionButtons} from "../../../../../components/FormActionButton";
import {FormHeader} from "../../../../../components/FormHeader";
import {CustomCheckBox} from "../../../../../components/CustomCheckBox";
import {Loading} from "../../../../../components/Loading";
import {Hide} from "../../../../../components/Hide";
import {DisabledTextField} from "../../../../../components/fields/DisabledField";
import {httpRequestWithAuth} from "../../../../../auth/axios_instance";
import {BasePriceOfferURL, BasePriceURL} from "../../../../../urls";

export const OfferPriceForm = props => {

    const [originl_prices, setOriginlPrices] = useState([]);
    const [price, setPrice] = useState( {is_active:true, ...props.value});
    const [selected_price, setSelectedPrice] = useState( {});
    const [helper_text, setHelperText] = useState( {});
    const [is_loading, setIsLoading] = useState( true);

    useEffect(()=>{
        const fetchData = async  () =>{
            await setIsLoading(true);

            if (props.value.id !== undefined) {
                let RetrievePriceResponse = await httpRequestWithAuth(`${BasePriceURL}${props.value.original_price}/`, "GET")
                if (RetrievePriceResponse.status) {
                    setSelectedPrice(RetrievePriceResponse.data);
                }
            } else {
                let ListPricesResponse = await ListPricesForOffer([props.original_package], props.package_offer);
                if (ListPricesResponse.status) {
                    setOriginlPrices(ListPricesResponse.data)
                }
            }

            await setIsLoading(false);
        }

        fetchData();
    }, [])


    const handlePriceAutocompleteChange = (value) => {
        setSelectedPrice(value);
    }

    const handlePriceFieldChange = (name, value) => {
        setPrice({...price, [name]: value});
    }

    const handleSaveForm = async () => {
        await setIsLoading(true);
        let offer_price = {
            ...price,
            original_price: selected_price.id,
            name: selected_price.name,
            price_list: props.package_offer,
        };

        let Response;
        if (offer_price.id === undefined) {
            Response = await httpRequestWithAuth(BasePriceOfferURL,"POST", offer_price);
        } else {
            Response = await httpRequestWithAuth(`${BasePriceOfferURL}${offer_price.id}/`, "PATCH", offer_price);
        }

        if (Response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(Response.error_feedback);
        }

        await setIsLoading(false);
    }

        return (
            <CustomModal
                open={props.open}
            >
                <Grid container spacing={2}>

                    <FormHeader
                        title="pages.prices.form.header"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                        <Hide hide={is_loading}>
                            <Hide hide={price.id !== undefined}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        ListboxProps={{className:"list-box-autocomplete"}}
                                        renderOption={(option) => (
                                            <span className="w-100 text-start">{option.name}</span>
                                        )}
                                        options={originl_prices}
                                        getOptionLabel={option => not_validate_value.includes(option.name)?"":option.name}
                                        value={not_validate_value.includes(selected_price.name)?null:selected_price}
                                        onChange={(event, value) => handlePriceAutocompleteChange(value)}
                                        renderInput={params => (
                                            <TextField {...params} fullWidth variant="outlined"
                                                       helper_text={helper_text.original_price}
                                                       error={helper_text.original_price !== undefined}
                                                       label={getLabel("prices")}/>
                                        )}
                                        // getOptionSelected={(option, value) => {
                                        //     alert(value)
                                        //     alert(option.name)
                                        //     return option.name === value
                                        //}}
                                    />
                                </Grid>
                            </Hide>

                            <Grid item xs={12}>
                                <DisabledTextField
                                    label="name"
                                    value={selected_price.name}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DisabledTextField
                                    label="pages.packages.package_offer.price.form.original_price"
                                    value={selected_price.price}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    required={true}
                                    error={helper_text.price !== undefined}
                                    helper_text={helper_text.price}
                                    name="price"
                                    label="pages.packages.package_offer.price.form.offer_price"
                                    value={price.price}
                                    onChangeHandle={handlePriceFieldChange}
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DisabledTextField
                                    label="pages.packages.package_offer.price.form.original_duration"
                                    value={selected_price.duration}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    required={true}
                                    error={helper_text.duration !== undefined}
                                    helper_text={helper_text.duration}
                                    name="duration"
                                    label="pages.packages.package_offer.price.form.offer_duration"
                                    value={price.duration}
                                    onChangeHandle={handlePriceFieldChange}
                                    type="number"
                                />
                            </Grid>

                            <Hide hide={props.is_rent}>

                            <Grid item xs={12} md={6}>
                                <DisabledTextField
                                    label="pages.packages.package_offer.price.form.original_sessions"
                                    value={selected_price.sessions}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    required={true}
                                    error={helper_text.sessions !== undefined}
                                    helper_text={helper_text.sessions}
                                    name="sessions"
                                    label="pages.packages.package_offer.price.form.offer_sessions"
                                    value={price.sessions}
                                    onChangeHandle={handlePriceFieldChange}
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DisabledTextField
                                    label="pages.packages.package_offer.price.form.original_freeze_days"
                                    value={selected_price.freeze_days}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    required={true}
                                    error={helper_text.freeze_days !== undefined}
                                    helper_text={helper_text.freeze_days}
                                    name="freeze_days"
                                    label="pages.packages.package_offer.price.form.offer_freeze_days"
                                    value={price.freeze_days}
                                    onChangeHandle={handlePriceFieldChange}
                                    type="number"
                                />
                            </Grid>
                            </Hide>

                            <Grid item xs={12}>
                                <CustomCheckBox
                                    value={price.is_active}
                                    name='is_active'
                                    onChangeHandle={handlePriceFieldChange}
                                    label="is_active"
                                />
                            </Grid>
                        </Hide>

                    <FormActionButtons
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                    />
                </Grid>

            </CustomModal>
        );

}
