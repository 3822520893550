import {getLabel} from "../localization/MainLocalization";
import {dir} from "../App";


export const material_table_localization = ()=>({
    localization: {
        header: {
            actions: getLabel("actions")
        },
        body: {
            emptyDataSourceMessage: getLabel("empty_data_source_message"),
        editRow: {
            saveTooltip: getLabel("yes"),
            cancelTooltip: getLabel("no"),
            deleteText: getLabel("table.delete_text"),
        },
        deleteTooltip: getLabel("delete")
        },

    }
});

export const material_table_style = {
    style: {
        padding: "0.5rem",
        boxShadow: "none",
    }
};

export const material_table_options = (additional_options={})=>({
    options: {
        actionsColumnIndex: -1,
        pageSize: 10,
        toolbar: false,
        pageSizeOptions: [10],
        rowStyle: {
            direction: "ltr",
        },
        actionsCellStyle:{
          direction: dir
        },
        ...additional_options
    }
});
