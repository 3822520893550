import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {
    BaseGenerateClassSessionsTimingURL,
    BaseMembershipChangeExtraDaysExtraSessionsURL,
    BaseMembershipURL
} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {StartDateMemberships} from "../memberships_apis";
import moment from "moment";
import {DisabledTextField} from "../../../../components/fields/DisabledField";
import {CustomTextField} from "../../../../components/fields/CustomTextField";


export const ChangeExtraDaysAndSessionsModal = props => {
    const [data , setData] = useState({...props.default_value});
    const [helper_text , setHelperText] = useState({});
    const [is_loading , setIsLoading] = useState(false);

    const handleFieldChange = async (name, newValue) => {
        let membership_data = {...data};
            newValue = parseInt(newValue === '' ? 0 : newValue);
            if (newValue < 0) {
                newValue = 0;
            }

        if (name === 'extra_days') {
            let start_date = await handleDateChange(newValue);
            membership_data = {...membership_data, ...start_date};
        }

       await setData({...membership_data, [name]:newValue})
    }

    const handleDateChange = async (extra_days) => {
        let response = await StartDateMemberships(data.start_date, data.client, data.price, extra_days, props.membership);
        if (response.status) {
           return {
                start_date: response.data.start_date,
                end_date: moment(response.data.end_date).format("YYYY-MM-DD"),
            }
        }

        return {start_date: data.start_date, end_date:""}
    };

    const handleSaveButton = async () => {
        await setIsLoading(true);

        let url = `${BaseMembershipURL}${props.membership}/${BaseMembershipChangeExtraDaysExtraSessionsURL}`;
        let response = await httpRequestWithAuth(url, "PATCH", data)

        if (response.status) {
            await props.handleModalClose(true)
        } else {
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };


    return (

            <CustomModal
                open={props.open}
            >
                    <Grid container spacing={2}>

                        <FormHeader
                            title="change_extra_days_and_extra_sessions"
                            handleClose={()=>props.handleModalClose(false)}
                        />

                        <Hide hide={!is_loading}>
                            <Loading/>
                        </Hide>

                        <Hide hide={is_loading}>

                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    error={helper_text.extra_days !== undefined}
                                    helper_text={helper_text.extra_days}
                                    name="extra_days"
                                    label="extra_days"
                                    type="number"
                                    value={data.extra_days}
                                    onChangeHandle={handleFieldChange}
                                />
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    error={helper_text.extra_sessions !== undefined}
                                    helper_text={helper_text.extra_sessions}
                                    name="extra_sessions"
                                    label="extra_sessions"
                                    type="number"
                                    value={data.extra_sessions}
                                    onChangeHandle={handleFieldChange}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DisabledTextField
                                    label="start_date"
                                    value={data.start_date}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DisabledTextField
                                    label="end_date"
                                    value={data.end_date}
                                />
                            </Grid>

                            <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={()=>props.handleModalClose(false)}
                            handleSaveButton={handleSaveButton}
                        />
                        </Hide>
                    </Grid>
            </CustomModal>
        );
}