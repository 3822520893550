import axios from "axios";
import {BaseBackEndURL} from "../urls";
import moment from "moment";
import auth, {setLocalStorage} from "./auth";
import {not_validate_value} from "../App";

export let token = '';
export var instance;

export async function createAxiosInstance(token) {
    instance = axios.create({
        baseURL: BaseBackEndURL,
        headers: {'Authorization': 'Bearer ' + token}
    });
    

    await instance.interceptors.request.use(async (response) => {
            if(!not_validate_value.includes(localStorage.getItem('expires_in')) && moment().add(3600, 'seconds').isAfter(moment(localStorage.getItem('expires_in')))){
                let refreshTokenResponse = await auth.getRefreshToken();
            if (refreshTokenResponse.status){
                await setLocalStorage(refreshTokenResponse.data)
                instance.defaults.headers.Authorization = 'Bearer ' + refreshTokenResponse.data.access_token
                response.headers['Authorization'] = 'Bearer ' + refreshTokenResponse.data.access_token;
            }else{
                auth.logout();
            }
        }

        return response;
    }, (error) => {
        // console.log(error)
        return Promise.reject(error.message);
    });

}


export async function httpRequestWithAuth(url, method, data, params) {
    let feedback ;
    try {

        feedback = await instance(url,
        {
            method: method,
            data,
            params
        }
    ).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    })
        .catch(error => {
            return {status: false, msg: error.response.status, error_feedback: error.response.data}
        });

    }catch (e) {
        feedback =  {status: false, fatal_error:true, msg: "Something went wrong, try again", error_feedback:{}}
    }

    return feedback

}



export async function httpRequestWithoutAuth(url, method, data, params) {
    let feedback ;
    try {

        feedback = await axios(url,
            {
                method: method,
                data,
                params
            }
        ).then(res => {
            return {status: true, msg: res.statusText, data: res.data}
        })
            .catch(error => {
                return {status: false, msg: error.response.status, error_feedback: error.response.data}
            });

    }catch (e) {
        feedback =  {status: false, fatal_error:true, msg: "Something went wrong, try again", error_feedback:{}}
    }

    return feedback

}

