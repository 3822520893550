import React, {useContext, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import "../../../assets/scss/Static.scss";
import {ActionButtons} from "../../../components/ActionButton";
import MaterialTable from "material-table";
import {getLabel} from "../../../localization/MainLocalization";
import {sub_directory} from "../../../App";
import {
    material_table_localization,
    material_table_style
} from "../../../components/Material_conf";
import {BaseGroupAthletesURL} from "../../../urls";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import Chip from "@material-ui/core/Chip";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {AlertContext} from "../../../store/use_context";
import {Hide} from "../../../components/Hide";
import {AthleteTeamFormModal} from "./components/AthleteTeamFormModal";


export const AthleteTeams = props => {
    const athlete_id = props.athlete_id;
    const {setAlert} = useContext(AlertContext);

    const [data, setData] = useState([]);
    const [is_loading, setIsLoading] = useState(false);
    const [material_table_localization_value] = useState(material_table_localization());
    const [form_open, setFormOpen] = useState(false);
    const [new_team, setNewTeam] = useState({});

    const [columns] = useState([
        {title: getLabel("team"), field: 'group_name', cellStyle:{width:"20%"}},
        {title: getLabel("season"), field: 'season_name', cellStyle:{width:"20%"}},
        {title: getLabel("coach"), field: 'coach_name'},
        {title: getLabel("category"), field: 'category_name'},
        {
            title: getLabel('status'),
            render: rowData => {
                return (
                    <Chip color={rowData.is_active ? "primary" : "default"} label={getLabel(rowData.is_active ? "active" : "inactive")}/>)
            }
        }
    ]);


    const listData = async () => {
        await setIsLoading(true);
        let params = {athlete: athlete_id}

        let response = await httpRequestWithAuth(BaseGroupAthletesURL, "GET", null, params)
        if (response.status) {
            await setData(response.data);
        }
        await setIsLoading(false);
    }

    const handleBackButton = () => {
        props.history.push(sub_directory + "athletes")
    }


    useEffect(()=>{
        listData();
    }, [])


    const handleModalClose = async () => {
        await listData();
        await setFormOpen(false)
        await setNewTeam({})
    };


    const handleModalOpen = async data => {
        if (data !== "new") {
            await setNewTeam(data)
        }
        await setFormOpen(true)
    };


    return (
        <Grid item xs={12}>

            <Hide hide={!form_open}>
                <AthleteTeamFormModal
                    open={form_open}
                    handleModalClose={handleModalClose}
                    default_data={{...new_team, athlete: athlete_id}}
                />
            </Hide>


            <MaterialTable
                columns={columns}
                data={data}
                isLoading={is_loading}
                editable={
                    Permissions.checkPermission(permissions_data.athlete_group.delete) && {
                        onRowDelete: async rowData => {
                            let response = await httpRequestWithAuth(`${BaseGroupAthletesURL}${rowData.id}/`, "DELETE");
                            if (response.status){
                                await listData();
                            }else{
                                setAlert(response.error_feedback.non_field_errors)
                            }
                        }
                    }}


                actions={[
                    {
                        icon: 'edit',
                        hidden: !Permissions.checkAllPermissions([permissions_data.athlete_group.change]),
                        tooltip: getLabel("edit_team"),
                        onClick: (event, rowData) => handleModalOpen(rowData)
                    },
                    {
                        icon: ()=> (<Button variant="contained" color="primary" size="small"
                                            startIcon={<AddIcon/>}>{getLabel('add_team')}</Button>),
                        hidden: !Permissions.checkAllPermissions([permissions_data.athlete_group.add]),
                        onClick: () => handleModalOpen('new'),
                        isFreeAction: true
                    },

                ]}

                options={{
                    searchFieldAlignment: 'left',
                    showTitle: false,
                    actionsColumnIndex: -1,
                    paging: false
                }}

                {...material_table_style}
                {...material_table_localization_value}
            />

            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />

        </Grid>
    );
}

