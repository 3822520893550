import React, {useContext, useEffect} from "react";
import {Route} from "react-router-dom";
import auth from "../auth/auth";
import {Redirect} from "react-router";
import Permissions from "../auth/permissions";
import {setRedirect} from "../App";
import {BreadcrumbContext, CashTransferContext} from "../store/use_context";

const ProtectedRoute = ({component: Component, ...rest}) => {
    setRedirect(rest.location.pathname);
    const {setBreadcrumbProps} = useContext(BreadcrumbContext);
    const {setCheckPendingReceivedCashTransfer} = useContext(CashTransferContext);

    useEffect(() => {
        const set_breadcrumb_props = async () => {
            await setBreadcrumbProps({details: rest.location.state || {}, match: rest.computedMatch})
        }

        document.title = `${localStorage.getItem('business_name')} | ${rest.title}`;
        setCheckPendingReceivedCashTransfer(Math.random()*1000);
        set_breadcrumb_props();
    }, [rest.location.pathname, rest.location.state])

    return (
        <Route {...rest} render={(props) => {
            return (
                (auth.isAuthenticated()) && (rest.permission === true || Permissions.checkOnePermissions(rest.permission)) ?
                    <Component {...props}/> : <Redirect to={`/home`}/>
            )
        }}/>
    )
};

export default ProtectedRoute;
