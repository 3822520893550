import React from "react";
import {SelectField} from "./SelectField";
import {getLabel} from "../../localization/MainLocalization";

export const StaticPaymentMethod = props => {
    let options =[
            {title: getLabel("cash"), value: "Cash"},
            {title: getLabel("bank_transfer"), value: "Bank_Transfer"},
            {title: getLabel("cheque"), value: "Cheque"},

        ];

    return(
        <SelectField
            viewOnly={props.disabled}
            disabled={props.disabled}
            required={props.required}
            label={props.label}
            name={props.name}
            value={props.value}
            onChangeHandle={props.onChangeHandle}
            options={options}
            error={props.error}
            helper_text={props.helper_text}
        />
    );

}