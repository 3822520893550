import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";

export const CustomButton = props =>{
    const [custom_style, setCustomStyle] = useState({});

    useEffect(()=>{
        if (window.innerWidth < 850){
            setCustomStyle({
                fontSize:'12px',
                height:'30px'
            });
        }else{
            setCustomStyle({});
        }
    }, [window.innerWidth])

    return(
        <Button {...props} style={{...(props.style||{}), ...custom_style}}>
            {props.children}
        </Button>
    )
}
