import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {ClientForm} from "./ClientForm";
import {ClientAttachment} from "./ClientAttachment";
import {ClientMemberships} from "./ClientMemberships";
import {ClientAttendees} from "./ClientAttendees";
import {sub_directory} from "../../../App";
import {CustomTabs} from "../../../components/CustomTabs";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";


export const ClientInfo = props => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [client_id, setClientId] = React.useState('new');


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };


    const state = props.history.location.state || {};

    useEffect(()=>{
        if (state !== undefined && state.id){
            setClientId(state.id);
        }else{
            props.history.push(`${sub_directory}clients`);
        }

        if (state !== undefined && state.attend_for_membership){
            setCurrentTab(2)
        }

        if (state !== undefined && state.moveToNextTab){
            setCurrentTab(currentTab + 1)
        }
    },[]);

    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match} details={{tag: state.tag}}/>*/}

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >

                <Tab label={getLabel("details")} id={0}/>

                <Tab label={getLabel("memberships")} id={1} disabled={client_id === 'new' || !Permissions.checkPermission(permissions_data.membership.view)}/>

                <Tab label={getLabel("attendees")} id={2} disabled={client_id === 'new' || !Permissions.checkPermission(permissions_data.class_attendance.view)}/>

                <Tab label={getLabel("attachment")} id={3} disabled={client_id === 'new' || !Permissions.checkPermission(permissions_data.client_attachment.view)}/>

            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <ClientForm
                        history={props.history}
                        client_id={client_id}
                    />
                </Hide>


                <Hide hide={currentTab !== 1}>
                    <ClientMemberships
                        history={props.history}
                        client_id={client_id}
                    />
                </Hide>

                <Hide hide={currentTab !== 2}>
                    <ClientAttendees
                        {...props}
                        client_id={client_id}
                    />
                </Hide>

                <Hide hide={currentTab !== 3}>
                    <ClientAttachment
                        history={props.history}
                        client_id={client_id}
                    />
                </Hide>

            </Grid>
        </Paper>
    );
}
