import React, {useEffect, useState} from "react";
import {DeleteMembershipsFreeze} from "./memberships_apis";
import {Hide} from "../../../components/Hide";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import {getLabel} from "../../../localization/MainLocalization";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import {FreezeModal} from "./components/FreezeModal";
import Permissions from "../../../auth/permissions";
import moment from "moment";
import {sub_directory} from "../../../App";
import {material_table_localization} from "../../../components/Material_conf";
import {permissions_data} from "../../../auth/permission_data";
import {ActionButtons} from "../../../components/ActionButton";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseMembershipFreezeURL} from "../../../urls";
import Chip from "@material-ui/core/Chip";
import {Loading} from "../../../components/Loading";


export const MembershipFreezes = props => {
    const state = props.history.location.state || {};

    const {membership_id} = props;

    const [columns, setColumns] = useState([
        {title: getLabel("start_date"), field: 'start_date_view'},
        {title: getLabel("end_date"), field: 'end_date_view'},
        {title: getLabel("duration"), field: 'duration'},
        {title: getLabel("pages.membershipsinfo.freezed_by"), field: 'freezed_by'},
        {
            title: getLabel('out_of_balance'),
            render: rowData => {
                return (
                    <Chip color={rowData.out_of_balance ? "primary" : "default"}
                          label={rowData.out_of_balance ? "yes" : "no"}/>)
            },
        },
        {title: getLabel("pages.membershipsinfo.freeze_date"), field: 'freeze_date_view'},
        {title: getLabel("created_by"), field: 'created_by_name'},
    ]);
    const [freezes, setFreezes] = useState([]);
    const [freeze_form_open, setFreezeFormOpen] = useState(false);
    const [freeze_data, setFreezeData] = useState([]);
    const [freeze_details, setFreezeDetails] = useState({});
    const [is_loading, setIsLoading] = useState({});

    const listFreezesData = async () => {
        await setIsLoading(true);

        const Response = await httpRequestWithAuth(BaseMembershipFreezeURL, "GET", null, {membership: membership_id})

        if (Response.status) {
            let freezes = [];
            setFreezeDetails(Response.data);
            (Response.data.data || []).map((Freeze) => {
                freezes.push({
                    ...Freeze,
                    freeze_date: moment(Freeze.freeze_date).format("DD-MM-YYYY HH:mm:ss"),
                    duration: Freeze.duration + " Days",
                    freezed_by: Freeze.is_location_freeze ? "Location" : "Client"
                });
                return 0;
            });

            await setFreezes(freezes)

        }
        await setIsLoading(false);
    };

    useEffect(() => {
        listFreezesData();
    }, [])


    const handleFreezeModalOpen = async (data) => {
        setFreezeFormOpen(true)
        setFreezeData(data)
    };


    const handleModalClose = async () => {
        await listFreezesData();
        setFreezeFormOpen(false)
        setFreezeData({})
    };


    const handleBackButton = async () => {
        props.history.push(sub_directory + 'memberships');
    };

    return (
        <>

            <Hide hide={!freeze_form_open}>
                <FreezeModal
                    membership={membership_id}
                    formOpen={freeze_form_open}
                    value={freeze_data}
                    handleModalClose={handleModalClose}
                />
            </Hide>


            <Hide hide={!is_loading}>
                <Loading/>
            </Hide>


            <Hide hide={is_loading}>




                <Grid item xs={12}>
                    <MaterialTable
                        title={getLabel("pages.membershipsinfo.freeze_table.header")}
                        style={{padding: '0.5rem', boxShadow: "none"}}
                        columns={columns}
                        data={freezes}
                        isLoading={is_loading}
                        editable={
                            (Permissions.checkPermission(permissions_data.membership_freeze.delete) && ((!props.is_done && !props.is_cancelled))) && {
                                isDeleteHidden: Freeze_data => !Freeze_data.can_edit,
                                onRowDelete: async Freeze_data => {
                                    await DeleteMembershipsFreeze(Freeze_data.id);
                                    listFreezesData();
                                },
                            }
                        }

                        actions={[
                            {
                                icon: () => (
                                    <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                            startIcon={<AddIcon/>}>
                                        <Typography
                                            variant="body1">{getLabel('pages.membershipsinfo.add_freeze')}</Typography>
                                    </Button>
                                ),
                                hidden: !(Permissions.checkPermission(permissions_data.membership_freeze.add) && ((!props.is_done && !props.is_cancelled))),
                                isFreeAction: true,
                                onClick: () => handleFreezeModalOpen()
                            },
                            (rowData) => {
                                return (
                                    {
                                        icon: 'edit',
                                        hidden: !(Permissions.checkPermission(permissions_data.membership_freeze.change) && ((!props.is_done && !props.is_cancelled)) && rowData.can_edit),
                                        tooltip: getLabel("pages.membershipsinfo.edit_freeze"),
                                        onClick: () => handleFreezeModalOpen(rowData)
                                    }
                                )
                            },

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false
                        }}

                        {...material_table_localization()}
                    />
                </Grid>

                <Grid item xs={12} className="pl-3 p-3">
                    <Typography variant="subtitle1" className="font-weight-bold border-top border-bottom">Eligible Freeze Days: {freeze_details.eligible_freeze_days || 0}</Typography>
                    <Typography variant="subtitle1" className="font-weight-bold border-top border-bottom">Total Freeze Days: {freeze_details.total_freeze_days || 0}</Typography>
                    <Typography variant="subtitle1" className="font-weight-bold border-top border-bottom">Remaining Freeze Days: {freeze_details.remaining_freezing_days || 0}</Typography>
                    <Typography variant="subtitle1" className="font-weight-bold border-top border-bottom">Total Freeze Days By
                        Client: {freeze_details.total_freeze_days_by_client || 0}</Typography>
                    <Typography variant="subtitle1" className="font-weight-bold border-top border-bottom">Total Freeze Days By
                        Location: {freeze_details.total_freeze_days_by_location || 0}</Typography>
                    <Typography variant="subtitle1" className="font-weight-bold border-top border-bottom">Total Freeze Days Out of Balance: {freeze_details.total_freeze_day_out_of_balance || 0}</Typography>

                </Grid>

            </Hide>

            <ActionButtons
                allowBack={true}
                backAction={handleBackButton}
            />

        </>
    );
}
