import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../assets/scss/Static.scss';
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CustomModal} from "../../../../components/CustomModal";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseTrainingAreaURL} from "../../../../urls";
import {Loading} from "../../../../components/Loading";
import {Hide} from "../../../../components/Hide";

export const TrainingAreaFormModal = props => {
    const [data, setData] = useState({...props.default_data})
    const [helper_text, setHelperText] = useState({is_active:true})
    const [is_loading, setIsLoading] = useState(false)

    const onChangeHandle = (name, value) => {
        setData({...data, [name]: value})
    }

    const handleSaveModal = async () => {
        await setIsLoading(true);

        let response;
        if (data.id === undefined) {
            response = await httpRequestWithAuth(BaseTrainingAreaURL, "POST", data)
        } else {
            response = await httpRequestWithAuth(`${BaseTrainingAreaURL}${data.id}/`, "PUT", data)
        }

        if (response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback)
        }

        await setIsLoading(false);
    };


    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="training_area_form"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>
                        <Grid item xs={12}>
                            <CustomTextField
                                required={true}
                                error={helper_text.name !== undefined}
                                helper_text={helper_text.name}
                                name="name"
                                label="name"
                                value={data.name}
                                onChangeHandle={onChangeHandle}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CustomCheckBox
                                value={data.is_active}
                                name='is_active'
                                onChangeHandle={onChangeHandle}
                                label="is_active"
                            />
                        </Grid>


                        <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={props.handleModalClose}
                            handleSaveButton={handleSaveModal}
                        />
                    </Hide>
                </Grid>

            </CustomModal>
        </>
    );
};