import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {
    BaseClassesAthleteInClassGroupsNotRegisterToClassURL,
    BaseClassSessionAthletesAttendeesURL,
} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {AthleteAutoComplete} from "../../../../components/fields/AthleteAutoComplete";
import {getLabel} from "../../../../localization/MainLocalization";
import Typography from "@material-ui/core/Typography";
import {dir, not_validate_value} from "../../../../App";
import Button from "@material-ui/core/Button";
import Permissions from "../../../../auth/permissions";
import {permissions_data} from "../../../../auth/permission_data";
import {AlertContext} from "../../../../store/use_context";
import {CustomButton} from "../../../../components/CustomButton";
import {CustomMaterialTable} from "../../../../components/CustomMaterialTable";
import {material_table_localization} from "../../../../components/Material_conf";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import {ViewRegisterClasses} from "./ViewRegisterClasses";
import Chip from "@material-ui/core/Chip";


export const AthletesAttendeeModel = props => {
    const [new_athlete_data, setNewAthleteData] = useState(null);
    const [add_new_athlete, setAddNewAthlete] = useState(false);
    const [helper_text, setHelperText] = useState("");
    const [is_loading, setIsLoading] = useState(false);
    const [athletes_data, setAthletesData] = useState([]);
    const {setAlert} = useContext(AlertContext);
    const [material_table_localization_value] = useState(material_table_localization());
    const [multi_classes_data, setMultiClassesData] = useState({open: false, details: [], saveAction: null});


    const mobile_columns = [
        {
            render: rowData => (
                <>
                    <Grid container className="align-items-center text-center" spacing={1}>
                        <Grid item xs={12}>
                            <div className='w-100 text-start'>
                                <Chip size="small"
                                      color={rowData.attendance_type === "required" ? "primary" : rowData.attendance_type === "without_team" ? "secondary" : ""}
                                      label={getLabel(rowData.attendance_type)}/>
                            </div>

                            <Avatar src={rowData.photo} className="mr-auto ml-auto mb-2"
                                    style={{width: "100px", height: "100px"}}/>

                            <div className="d-inline-flex">
                                <Typography gutterBottom variant="subtitle2" className="font-weight-bold mr-1"
                                            component="h6">
                                    {rowData.athlete_name}
                                </Typography>
                                {/*{rowData.is_cancelled ? (*/}
                                {/*    <Chip size="small" color={rowData.is_cancelled ? "secondary" : ""}*/}
                                {/*          label={getLabel("unregistered")}/>*/}
                                {/*) : (*/}
                                {/*    <Chip size="small"*/}
                                {/*          color={rowData.is_present ? "primary" : !not_validate_value.includes(rowData.is_present) ? "secondary" : ""}*/}
                                {/*          label={getLabel(rowData.is_present ? "present" : !not_validate_value.includes(rowData.is_present) ? "absent" : "TBA")}/>*/}
                                {/*)}*/}
                            </div>
                        </Grid>

                        <Hide hide={rowData.is_present && !not_validate_value.includes(rowData.is_present)}>
                            <Grid item xs={12}>
                                <Button className="mr-1 ml-1" size="small"
                                        variant="outlined"
                                        fullWidth
                                        color={rowData.is_present ? "secondary" : "primary"}
                                        onClick={() => handleBtnAction(rowData, true, false)}
                                >
                                    {getLabel("present")}
                                </Button>
                            </Grid>
                        </Hide>

                        <Hide hide={!rowData.is_present && !not_validate_value.includes(rowData.is_present)}>
                            <Grid item xs={12}>
                                <Button className="mr-1 ml-1" size="small"
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        onClick={() => handleBtnAction(rowData, false, false)}
                                >
                                    {getLabel("absent")}
                                </Button>
                            </Grid>
                        </Hide>

                        {/*<Hide*/}
                        {/*    hide={not_validate_value.includes(rowData.id) || !rowData.is_cancelled && !Permissions.checkPermission(permissions_data.class_attendance.cancel_attendance)}>*/}
                        {/*    <Grid item xs={12}>*/}
                        {/*        <Button className="mr-1 ml-1" size="small"*/}
                        {/*                variant="outlined"*/}
                        {/*                fullWidth*/}
                        {/*                color={rowData.is_cancelled ? "primary" : "secondary"}*/}
                        {/*                onClick={() => handleBtnAction(rowData, false, !rowData.is_cancelled)}*/}
                        {/*        >*/}
                        {/*            {getLabel(rowData.is_cancelled ? "register" : "unregister")}*/}
                        {/*        </Button>*/}
                        {/*    </Grid>*/}
                        {/*</Hide>*/}

                    </Grid>
                </>
            )
        },
    ];

    const normal_columns = [
        {
            title: getLabel("athlete"), render: rowData => (
                <div className="d-inline-flex align-items-center">
                    <Avatar src={rowData.photo} className="mr-auto ml-auto mb-2"
                            style={{width: "75px", height: "75px"}}/>

                    <Typography variant="subtitle1" className="mr-3 ml-3">{rowData.athlete_name}</Typography>
                </div>
            )
        },
        {
            title: getLabel('attendance_type'), render: rowData => (
                <Chip size="small"
                      color={rowData.attendance_type === "required" ? "primary" : rowData.attendance_type === "without_team" ? "secondary" : ""}
                      label={getLabel(rowData.attendance_type)}/>
            )
        },

        // {
        //     title: getLabel("status"),
        //     render: rowData => {
        //         return rowData.is_cancelled ?(
        //             <Chip size="small" color={rowData.is_cancelled ? "secondary" : ""}
        //                   label={getLabel("unregistered")}/>
        //         ) : (
        //             <Chip size="small"
        //                   color={rowData.is_present ? "primary" : !not_validate_value.includes(rowData.is_present) ? "secondary" : ""}
        //                   label={getLabel(rowData.is_present ? "present" : !not_validate_value.includes(rowData.is_present) ? "absent" : "TBA")}/>
        //         )}
        //
        // },
        {
            hidden: !Permissions.checkAllPermissions([permissions_data.class_attendance.change]),
            render: rowData => {
                return (
                    <div className="d-inline-flex">
                        <Hide hide={rowData.is_present && !not_validate_value.includes(rowData.is_present)}>
                            <Button className="mr-1 ml-1" size="small"
                                    variant="outlined"
                                    color={rowData.is_present ? "secondary" : "primary"}
                                    onClick={() => handleBtnAction(rowData, true, false)}
                            >
                                {getLabel("present")}
                            </Button>
                        </Hide>

                        <Hide hide={!rowData.is_present && !not_validate_value.includes(rowData.is_present)}>
                            <Button className="mr-1 ml-1" size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleBtnAction(rowData, false, false)}
                            >
                                {getLabel("absent")}
                            </Button>
                        </Hide>


                        {/*<Hide*/}
                        {/*    hide={not_validate_value.includes(rowData.id) || !rowData.is_cancelled && !Permissions.checkPermission(permissions_data.class_attendance.cancel_attendance)}>*/}
                        {/*    <Button className="mr-1 ml-1" size="small"*/}
                        {/*            variant="outlined"*/}
                        {/*            color={rowData.is_cancelled ? "primary" : "secondary"}*/}
                        {/*            onClick={() => handleBtnAction(rowData, false, !rowData.is_cancelled)}*/}
                        {/*    >*/}
                        {/*        {getLabel(rowData.is_cancelled ? "register" : "unregister")}*/}
                        {/*    </Button>*/}
                        {/*</Hide>*/}

                    </div>
                )
            }
        }
    ];

    const updateData = async (athlete_details) => {
        let temp_athletes = []
        athletes_data.map(Athlete => {
            let athlete_data = {...Athlete};
            if (athlete_details.athlete === Athlete.athlete) {
                athlete_data = {
                    ...athlete_data,
                    ...athlete_details,
                }
            }
            temp_athletes.push(athlete_data);
        })
        setAthletesData(temp_athletes);
    }

    const handleBtnAction = async (rowData, is_present, is_cancelled) => {
        await setIsLoading(true);
        let temp_data = {
            ...rowData,
            is_present: is_present,
            is_cancelled: is_cancelled,
            class_session: props.class_id,
            date: moment().format("YYYY-MM-DD"),
        }

        let response;
        if (not_validate_value.includes(temp_data.id)) {
            response = await httpRequestWithAuth(`${BaseClassSessionAthletesAttendeesURL}`, "POST", temp_data)
        } else {
            response = await httpRequestWithAuth(`${BaseClassSessionAthletesAttendeesURL}${temp_data.id}/`, "PATCH", temp_data)
        }

        if (response.status) {
            await updateData(response.data);
        } else {
            if (response.error_feedback.multi_classes_error) {
                await setMultiClassesData({
                    open: true,
                    details: response.error_feedback.details,
                    info: response.error_feedback.info,
                    saveAction: () => handleBtnAction(rowData, is_present, is_cancelled)
                })
            } else {
                setAlert(response.error_feedback.non_field_errors)
            }
        }

        await setIsLoading(false);
    };


    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            let response = await httpRequestWithAuth(BaseClassesAthleteInClassGroupsNotRegisterToClassURL(props.class_id), "GET")
            if (response.status) {
                await setAthletesData(response.data);
            }
            await setIsLoading(false);
        }
        fetchData();
    }, [])

    const onChangeHandle = (name, value) => {
        setNewAthleteData(value);
    }


    const handleCancelAddForm = () => {
        setNewAthleteData(null);
        setAddNewAthlete(false);
        setHelperText("");
    }

    const handleAddForm = async () => {
        if (not_validate_value.includes((new_athlete_data || {}).id)) {
            setHelperText("Should select athlete")
        }

        let error = "";

        athletes_data.map(Athlete => {
            if (Athlete.athlete === (new_athlete_data || {}).id) {
                error = "This athlete already in list"
            }
        })

        if (not_validate_value.includes(error)) {
            let temp_data = {
                'athlete': new_athlete_data.id,
                class_session: props.class_id,
                date: moment().format("YYYY-MM-DD"),
            }
            // let response = await httpRequestWithAuth(`${BaseClassSessionAthletesAttendeesURL}`, "POST", temp_data)

            // if (response.status) {
            setAthletesData([{
                'athlete': new_athlete_data.id,
                'athlete_name': new_athlete_data.short_name,
                'photo': new_athlete_data.photo,
                'attendance_type': "without_team",
                // ...response.data
            }, ...athletes_data]);
            setNewAthleteData(null);
            setAddNewAthlete(false);
            // } else {
            //     setAlert(response.error_feedback.non_field_errors)
            // }
        } else {
            setHelperText(error);
        }
    };


    const handleRegisterClassesModalClose = () => {
        setMultiClassesData({open: false, details: []})
    }

    return (
        <CustomModal
            open={props.open}
        >

            <Grid container spacing={1}>

                <Hide hide={!multi_classes_data.open}>
                    <ViewRegisterClasses
                        {...props}
                        open={multi_classes_data.open}
                        details={multi_classes_data.details}
                        handleModalClose={handleRegisterClassesModalClose}
                        addAttendance={multi_classes_data.saveAction}
                        info={multi_classes_data.info}
                        for="Athlete"
                    />
                </Hide>

                <FormHeader
                    title="athlete_attendee_form"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>

                    <Hide hide={!add_new_athlete}>
                        <Grid item xs={12} md={8}>
                            <AthleteAutoComplete
                                getFullObject
                                required
                                name='athlete'
                                label="athlete"
                                onChangeHandle={onChangeHandle}
                                helper_text={helper_text}
                                error={!not_validate_value.includes(helper_text)}
                                value={(new_athlete_data || {}).id}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <CustomButton fullWidth color="primary" variant="contained"
                                          onClick={handleAddForm}>{getLabel("add")}</CustomButton>
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <CustomButton fullWidth color="primary" variant="contained"
                                          onClick={handleCancelAddForm}>{getLabel("cancel")}</CustomButton>
                        </Grid>

                    </Hide>

                    <Hide hide={add_new_athlete}>
                        <Grid item xs={12} className="text-end">
                            <CustomButton color="primary" variant="contained"
                                          onClick={() => setAddNewAthlete(true)}>{getLabel("add_athlete")}</CustomButton>
                        </Grid>
                    </Hide>

                    <Grid item xs={12}>
                        <CustomMaterialTable
                            mobileColumns={mobile_columns}
                            normalColumns={normal_columns}
                            hideHeaderOnMobileView
                            isLoading={is_loading}
                            data={athletes_data}
                            style={{padding: "0", boxShadow: "none", marginBottom: "1rem"}}

                            options={{
                                actionsColumnIndex: -1,
                                toolbar: false,
                                paging: false,
                                rowStyle: {
                                    direction: "ltr",
                                },
                                actionsCellStyle: {
                                    direction: dir
                                },
                            }}
                            {...material_table_localization_value}
                        />
                    </Grid>

                    <FormActionButtons
                        handleCancelButton={props.handleModalClose}
                    />
                </Hide>

            </Grid>
        </CustomModal>
    );
}
