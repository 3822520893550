import React from "react";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../localization/MainLocalization";
import Chip from "@material-ui/core/Chip";


const days_name = ['saturday', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

export const DaysComponent = props =>{

    const onDayChipClick = clicked_day => {
        let value = (props.value || []);
        if (value.includes(clicked_day)){
            props.onChangeHandle(props.name, value.filter(option=> option !== clicked_day))
        }else{
            value.push(clicked_day);
            props.onChangeHandle(props.name, value)
        }
    }

    return(
        <>
                <Typography variant="subtitle1" component="h6" className='p-1 m-1'>
                    {getLabel(props.label)}:
                    <span className="text-danger">{props.required?"*":""}</span>
                    <span className="ml-2 mr-2 text-danger">{props.helper_text}</span>
                </Typography>

                {days_name.map((Day, index) => (
                    <Chip
                        key={index}
                        color={(props.value||[]).includes(Day) ? "primary" : "default"}
                        className="m-2"
                        label={getLabel(Day)}
                        onClick={() => onDayChipClick(Day)}
                    />
                ))}
        </>
    );
}
