import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {
    BaseMembershipUpgradePriceURL,
    BaseMembershipURL, BasePriceUpgradeToURL, BasePriceURL
} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {StartDateMemberships} from "../memberships_apis";
import moment from "moment";
import {DisabledTextField} from "../../../../components/fields/DisabledField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {not_validate_value} from "../../../../App";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import TextField from "@material-ui/core/TextField";
import {getLabel} from "../../../../localization/MainLocalization";
import Permissions from "../../../../auth/permissions";
import {permissions_data} from "../../../../auth/permission_data";
import Typography from "@material-ui/core/Typography";
import {PaymentMethods} from "../../../../components/PaymentMethods";


export const ChangePrice = props => {
    const [data , setData] = useState({...props.default_value, price:"",
        total_price:'',
        sessions:'',
        duration:'',
        end_date:'',
        freeze_days:''
    });
    const [helper_text , setHelperText] = useState({});
    const [is_loading , setIsLoading] = useState(false);
    const [prices , setPrices] = useState([]);
    const [add_refund, setAddRefund] = useState(false);

    useEffect(()=>{
        const fetchData = async () =>{
            let url = `${BasePriceURL}${props.price}/${BasePriceUpgradeToURL}`;

            let response = await httpRequestWithAuth(url, "GET",null, {location:data.location})
            let temp_prices =[]

            if (response.status){
                response.data.map(Price=>{
                    temp_prices.push({
                        value: Price.id,
                        title: Price.name,
                        data: Price
                    });
                })
                setPrices(temp_prices);
            }
        }

        fetchData();
    },[])

    const handleDateChange = async (price) => {
        let response = await StartDateMemberships(data.start_date, data.client, price, data.extra_days, props.membership);
        if (response.status) {
           return {
                start_date: response.data.start_date,
                end_date: moment(response.data.end_date).format("YYYY-MM-DD"),
            }
        }

        return {start_date: data.start_date, end_date:""}
    };

    const handleSaveButton = async () => {
        await setIsLoading(true);

        let url = `${BaseMembershipURL}${props.membership}/${BaseMembershipUpgradePriceURL}`;
        let response = await httpRequestWithAuth(url, "PATCH", data)

        if (response.status) {
            await props.handleModalClose(true)
        } else {
            if (response.error_feedback.paid_more_error && Permissions.checkPermission(permissions_data.membership_payment.add_refund)){
                await setData({...data, 'amount': response.error_feedback.paid_more_amount, add_refund: true});
                await setAddRefund(true);
            }
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };


    const handlePriceChange = async newValue => {
        let is_null = newValue === null;
        return {
            original_price: is_null ? 0 : newValue.data.price,
            duration: is_null ? 0 : newValue.data.duration,
            price: is_null ? "" : newValue.value,
            end_date: is_null ? "" : data.end_date,
            total_price: is_null ? 0 : Math.max(0, parseFloat(newValue.data.price) - parseFloat(data.discount)),
            sessions: is_null ? 0 : newValue.data.sessions,
            freeze_days: is_null ? 0 : newValue.data.freeze_days,
            offer_price: is_null ? "" : not_validate_value.includes(newValue.data.offer_price)? "" : newValue.data.offer_price,
        }
    };

    const handleFieldChange = async (name, newValue) => {
        await setData({...data, [name]: newValue});
    }

    const handleAutoCompleteChange = async (name, newValue) => {
        let new_data  =  await handlePriceChange(newValue)

        if (!not_validate_value.includes(newValue)){
            let start_date = await handleDateChange(newValue.value);
            new_data = {...new_data, ...start_date}
        }else{
            new_data = {...new_data, start_date:data.start_date, end_date: ""}
        }

        new_data = {...data, ...new_data};

        delete new_data['add_refund']
        setHelperText({});
        setAddRefund(false);

        await setData(new_data);
        };



    const handleAutoCompleteValue = () => {
        let value = null;
            prices.map(Price => {
                if (Price.value === data.price) {
                    value = Price;
                }
                return 0;
            });
        return value;
    };



    return (

            <CustomModal
                open={props.open}
            >
                    <Grid container spacing={2}>

                        <FormHeader
                            title="upgrade_duration"
                            handleClose={()=>props.handleModalClose(false)}
                        />

                        <Hide hide={!is_loading}>
                            <Loading/>
                        </Hide>

                        <Hide hide={is_loading}>

                            <Grid item xs={12}>
                                <Autocomplete
                                    ListboxProps={{className:"list-box-autocomplete"}}
                                    renderOption={(option) => (
                                        <span className={"w-100 text-start"}>
                                    {option.title}
                                            {!not_validate_value.includes(option.data.offer_price) && (
                                                <MoneyOffIcon color="primary" className="mr-2 ml-2"/>
                                            )}
                                </span>
                                    )}
                                    options={prices}
                                    getOptionLabel={option => option.title}
                                    renderInput={params => (
                                        <TextField {...params}
                                                   label={<label>{getLabel("duration")}<span className='text-danger'>*</span></label>}
                                                   variant="outlined"
                                                   error={helper_text.price !== undefined}
                                                   helperText={helper_text.price}
                                                   fullWidth
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        handleAutoCompleteChange('price', value)
                                    }}
                                    value={handleAutoCompleteValue()}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DisabledTextField
                                    label="start_date"
                                    value={data.start_date}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DisabledTextField
                                    label="end_date"
                                    value={data.end_date}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DisabledTextField
                                    label="duration_days"
                                    value={data.duration}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DisabledTextField
                                    label="sessions"
                                    value={data.sessions}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DisabledTextField
                                    label="freeze_days"
                                    value={data.freeze_days}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DisabledTextField
                                    label="total_price"
                                    value={data.total_price}
                                />
                            </Grid>

                            <Hide hide={!add_refund}>

                                <Grid item xs={12}>
                                    <Typography variant="h6" className='p-1'>
                                        {getLabel("add_refund")}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <PaymentMethods
                                        disableAmount
                                        assigned_to="memberships"
                                        required
                                        new_payment={data}
                                        helper_text={helper_text}
                                        handleNewPaymentFieldChange={handleFieldChange}
                                    />
                                </Grid>
                            </Hide>

                            <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={()=>props.handleModalClose(false)}
                            handleSaveButton={handleSaveButton}
                        />
                        </Hide>
                    </Grid>
            </CustomModal>
        );
}
