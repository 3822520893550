import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {StaffForm} from "./StaffForm";
import {StaffAccessPermissions, StaffAccessPermissionsTab} from "./StaffAccessPermissionsTab";
import {StaffAttachment} from "./StaffAttachment";
import {sub_directory} from "../../../App";
import {CustomTabs} from "../../../components/CustomTabs";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {StaffAccount} from "./StaffAccount";


export const StaffInfo = props => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [staff_id, setStaffId] = React.useState("new");
    const state = props.history.location.state || {};

    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };


    const moveToNextTab = () =>{
        setCurrentTab(currentTab + 1);
    }

    const moveToTab = (value) =>{
        setCurrentTab(value);
    }

    useEffect(()=>{
        if (state.id){
            setStaffId(state.id);
        }else{
            props.history.push(`${sub_directory}staff`);
        }

        if (state.moveToNextTab){
            moveToNextTab();
        }
    },[])

    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match} details={{tag: state.tag}}/>*/}

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >

                <Tab label={getLabel("details")} id={0}/>

                <Tab label={getLabel("attachment")} id={1} disabled={staff_id === 'new' || !Permissions.checkPermission(permissions_data.staff_attachment.view)}/>

                <Tab label={getLabel("account")} id={2} disabled={staff_id === 'new' || !Permissions.checkPermission(permissions_data.user.view)}/>

                <Tab label={getLabel("access_permissions")} id={3} disabled={staff_id === 'new' || !Permissions.checkPermission(permissions_data.staff_permission.view)}/>

            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <StaffForm
                        {...props}
                    staff_id={staff_id}
                    />
                </Hide>

                <Hide hide={currentTab !== 1}>
                    <StaffAttachment
                        {...props}
                        staff_id={staff_id}
                    />
                </Hide>


                <Hide hide={currentTab !== 2}>
                    <StaffAccount
                        {...props}
                        staff_id={staff_id}
                    />
                </Hide>

                <Hide hide={currentTab !== 3}>
                    <StaffAccessPermissionsTab
                        {...props}
                        staff_id={staff_id}
                        moveToTab={moveToTab}
                    />
                </Hide>

            </Grid>
        </Paper>
    );
}
