import React, {Component, useRef, useState} from 'react';
import Permissions from "../../../auth/permissions";
import VisibilityIcon from '@material-ui/icons/Visibility';
import MaterialTable from "material-table";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import {instance} from "../../../auth/axios_instance";
import {BaseClientURL} from "../../../urls";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {not_validate_value, sub_directory} from "../../../App";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";
import {permissions_data} from "../../../auth/permission_data";
import queryString from "query-string";
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import {IconButton} from "@material-ui/core";

export const Clients = props => {
let url_params = queryString.parse(props.location.search)

if (!not_validate_value.includes(url_params.attend_for_membership)) {
    props.history.push({
        pathname: sub_directory +'clients/edit',
        state: {
            id: url_params.client,
            tag: url_params.tag,
            attend_for_membership: url_params.attend_for_membership,
            attend_dates: url_params.attend_dates
        }
    })
}

        const tableRef = useRef();


    const columns = [
        {title: getLabel("name"), render: rowData=>(
        <Typography>
            <Link href="" onClick={e => {
                e.preventDefault();
                viewClient(rowData.id, rowData.short_name)
            }}>
                {rowData.full_name}
            </Link>
        </Typography>
            ), cellStyle: {width: "50%"}},
        {title: getLabel("mobile"), field: 'mobile_view'},
        {title: getLabel("gender"), field: 'gender', cellStyle: {width: "10%"}},
        {title: getLabel("birthday"), field: 'birth_date_view', cellStyle: {width: "100px"}},
        {title: getLabel('pages.clients.add_membership'), render: rowData => (
            <div className="text-center">
            <Tooltip title={getLabel('pages.clients.add_membership')}>
                <IconButton onClick={()=>props.history.push({
                    pathname: sub_directory + 'memberships/new',
                    state: {
                        client_id: rowData.id,
                        id: 'new'
                    }
                })}>
                    <GroupAddIcon/>
                </IconButton>
            </Tooltip>
            </div>
            ),
            hidden: !Permissions.checkPermission(permissions_data.membership.add),
        }
    ];
    const [search, setSearch] = useState("");

    const viewClient = (id, tag) => {
            props.history.push({
                pathname: sub_directory +'clients/edit',
                state: {
                    id: id,
                    tag: tag
                }
            })
    };


    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if ((newValue.length >= 5 && /^\d+$/.test(newValue)) || newValue === '') {
            reloadData();
        }

    };


    return (
        <>
            <Paper>
                <TableToolbar
                    searchFieldName='search'
                    searchValue={search}
                    onSearchChangeHandle={handleSearchField}
                    searchAction={reloadData}
                    match={props.match}
                    actions={
                        [
                            {
                                hide: !Permissions.checkPermission(permissions_data.client.add),
                                onClick: () => props.history.push({
                                    pathname: sub_directory +'clients/new',
                                    state: {id: "new"}
                                }),
                                label: "pages.clients.add_client"
                            },
                        ]
                    }
                />

                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let url = BaseClientURL;

                            url += '?page=' + (query.page + 1);

                            if (search !== '') {
                                url += '&search=' + search;
                            }

                            instance.get(url)
                                .then(result => {
                                    resolve({
                                        data: result.data.results,
                                        page: result.data.page - 1,
                                        totalCount: result.data.total,
                                    })
                                })
                        })
                    }

                    {...material_table_style}
                    {...material_table_options()}
                    {...material_table_localization()}

                />

            </Paper>
        </>
    );
}
