import React, {useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {Hide} from "../../../components/Hide";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseTrainingAreaURL} from "../../../urls";
import {TrainingAreaFormModal} from "./components/TrainingAreaFormModal";


export const TrainingArea = props => {
    const {location_id} = props;

    const columns = [
        {title: getLabel("name"), field: 'name'},
    ];

    const [data, setData] = useState([])
    const [form_open, setFormOpen] = useState(false)
    const [form_data, setFormData] = useState({is_active:true})
    const [is_loading, setIsLoading] = useState(true)

    const listData = async () => {
        await setIsLoading(true)
        let response = await httpRequestWithAuth(BaseTrainingAreaURL, "GET", null, {location: location_id});

        if (response.status) {
            await setData(response.data);
        }else{
            await setData([]);
        }

        await setIsLoading(false)
    };

    useEffect(() => {
        listData();
    }, [])


    const handleModalOpen = (data) => {
        setFormOpen(true);
        setFormData({is_active:true, ...data});
    };

    const handleModalClose = async () => {
        await listData();
        await setFormOpen(false);
        setFormData({is_active:true});
    };

    return (
        <>
            <Hide hide={!form_open}>
                <TrainingAreaFormModal
                    open={form_open}
                    handleModalClose={handleModalClose}
                    default_data={{...form_data,location: location_id}}
                />
            </Hide>

            <Grid item xs={12}>
                <MaterialTable
                    style={{padding: '0.5rem', boxShadow: "none"}}
                    is_loading={is_loading}
                    columns={columns}
                    data={data}
                    editable={Permissions.checkPermission(permissions_data.training_area.delete) && {
                        onRowDelete: async rowData => {
                            await httpRequestWithAuth(`${BaseTrainingAreaURL}${rowData.id}/`, "DELETE")
                            await listData();
                        }
                    }}

                    actions={[
                        {
                            icon: () => (
                                <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                        startIcon={<AddIcon/>}>
                                    <Typography variant="body1">{getLabel('add_training_area')}</Typography>
                                </Button>
                            ),
                            hidden: !Permissions.checkPermission(permissions_data.training_area.add),
                            isFreeAction: true,
                            onClick: () => handleModalOpen({})
                        },
                        {
                            icon: 'edit',
                            tooltip: getLabel('edit_training_area'),
                            hidden: !Permissions.checkPermission(permissions_data.training_area.change),
                            onClick: (event, rowData) => handleModalOpen(rowData)
                        }
                    ]}

                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        showTitle: false
                    }}

                    {...material_table_localization()}
                />
            </Grid>
        </>
    );

}