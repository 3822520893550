import React, {useContext, useEffect, useRef, useState} from 'react';
import MaterialTable from 'material-table';
import Permissions from "../../../../auth/permissions";
import {BaseSeasonGroupURL} from "../../../../urls";
import {
    material_table_localization,
} from "../../../../components/Material_conf";
import {getLabel} from "../../../../localization/MainLocalization";
import Chip from "@material-ui/core/Chip";
import {Hide} from "../../../../components/Hide";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {permissions_data} from "../../../../auth/permission_data";
import {sub_directory} from "../../../../App";
import {AlertContext} from "../../../../store/use_context";
import {GroupFormModal} from "./components/GroupFormModal";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";


export const Groups = props => {

    const {setAlert} = useContext(AlertContext);

    const tableRef = useRef();
    const [material_table_localization_value] = useState(material_table_localization());
    const [is_loading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const [columns] = useState([
        {title: getLabel("name"), field: 'name', cellStyle:{width:"25%"}},
        {title: getLabel("category"), field: 'category_name'},
        {title: getLabel("coach"), field: 'coach_name'},
        {
            title: getLabel('status'),
            render: rowData => {
                return (
                    <Chip color={rowData.is_active ? "primary" : "default"} label={rowData.is_active_view}/>)
            },
        },
        {render:rowData=>{return(
                <div className="d-inline-flex">
                    {Permissions.checkAllPermissions([permissions_data.athlete_group.view]) && (
                        <Button className="mr-1 ml-1" size="small" variant="outlined"
                                onClick={()=> props.history.push(
                                    {
                                        pathname: sub_directory + "teams/athletes",
                                        state: {
                                            season: props.season,
                                            season_name: props.season_name,
                                            group: rowData.id,
                                            group_name: rowData.name
                                        }
                                    }
                                )}>
                            {getLabel("view_athletes")}
                        </Button>)}

                </div>
            )}}
    ]);
    const [form_open, setFormOpen] = useState(false)
    const [new_group, setNewGroup] = useState({})

    const listData = async () => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(BaseSeasonGroupURL, "GET", null, {season: props.season})
        if (response.status){
            setData(response.data)
        }
        await setIsLoading(false);
    };

    useEffect(()=>{
        listData();
    }, []);

    const handleModalClose = async () => {
        await listData();
        await setFormOpen(false)
        await setNewGroup({})
    };


    const handleModalOpen = async data => {
        if (data !== "new") {
            await setNewGroup(data)
        }
        await setFormOpen(true)
    };



    return (
        <>
            <Hide hide={!form_open}>
                <GroupFormModal
                    open={form_open}
                    handleModalClose={handleModalClose}
                    default_data={{...new_group, season: props.season}}
                />
            </Hide>


                <MaterialTable
                    title={getLabel("teams")}
                    tableRef={tableRef}
                    columns={columns}
                    isLoading={is_loading}
                    data={data}
                    editable={
                        Permissions.checkPermission(permissions_data.group.delete) && {
                            onRowDelete: async rowData => {
                                let response = await httpRequestWithAuth(`${BaseSeasonGroupURL}${rowData.id}/`, "DELETE");
                                if (response.status){
                                    await listData();
                                }else{
                                    setAlert(response.error_feedback.non_field_errors)
                                }
                            }
                        }}


                    actions={[
                        {
                            icon: 'edit',
                            hidden: !Permissions.checkAllPermissions([permissions_data.group.change]),
                            tooltip: getLabel("edit_team"),
                            onClick: (event, rowData) => handleModalOpen(rowData)
                        },
                        {
                            icon: ()=> (<Button variant="contained" color="primary" size="small"
                            startIcon={<AddIcon/>}>{getLabel('add_team')}</Button>),
                            hidden: !Permissions.checkAllPermissions([permissions_data.group.add]),
                            onClick: () => handleModalOpen('new'),
                            isFreeAction: true
                        },
                    ]}

                    style={{
                        padding: "0.5rem",
                        paddingBottom: "2rem",
                        boxShadow: "none",
                    }}

                    options={{
                        search: false,
                        actionsColumnIndex: -1,
                        paging: false
                    }}

                    {...material_table_localization_value}
                />
        </>
    );
}

