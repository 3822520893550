import React from "react";
import TextField from "@material-ui/core/TextField";
import {getLabel} from "../../localization/MainLocalization";

export const CharFieldOnlyField = props => {

    const validateInput = input => {
        if (!props.viewOnly){
        let newValue = input.target.value.replace(/[^a-zA-Zا-ي ]/g, "");
        props.onChangeHandle(input.target.name, newValue);
        }

    };


        return (

            <TextField
                disabled={props.disabled}
                className={props.disabled ? "disabled-field" : ""}
                autoComplete="off"
                variant="outlined"
                name={props.name}
                value={props.value || ""}
                onChange={validateInput}
                label={<label>{getLabel(props.label)}<span
                    className='text-danger'>{props.required ? "*" : ""}</span></label>}
                helperText={props.helper_text}
                error={props.error}
                fullWidth
            />
        );
}
