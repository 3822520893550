import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {ListPaymentMethod} from "../../pages/setting/entity/payment_method/payment_method_apis";

export const PaymentMethodAutoComplete = props => {
    const [options, setOptions] = useState([])

    useEffect(()=>{
        const fetchData= async () =>{
            let response = await ListPaymentMethod(props.assigned_to);
            if (response.status) {
                setOptions(response.data)
            }
        }

        fetchData();
    }, [])


    const handleOnChange = (event, value) => {
        value = value !== null ? props.fullObject?value: value.id : "";
        props.onChangeHandle(props.name, value)
    };

    const handleAutoCompleteValue = () => {
        let value = null;

            options.map(Data => {
                if (Data.id === props.value) {
                    value = Data;
                }
                return 0;
            });

        return value;
    };

        return (
            <Autocomplete
                renderOption={(option) => (
                    <span className="w-100 text-start">{option.name}</span>
                )}
                disabled={props.disabled || props.viewOnly}
                options={options}
                getOptionLabel={option => option.name}
                renderInput={params => (
                    <TextField variant="outlined" {...params}
                               label={<label>{getLabel(props.label)}<span
                                   className='text-danger'>{props.required ? "*" : ""}</span></label>}
                               helperText={props.helper_text}
                               error={props.error}
                               fullWidth
                               disabled={props.disabled}
                               className={props.disabled ? "disabled-field" : ""}
                    />
                )}
                value={handleAutoCompleteValue()}
                onChange={handleOnChange}
            />
        );
}
