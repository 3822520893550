import {BaseFinanceMyClaimAttachmentRequestURL, BaseFinanceExpenseAttachmentURL} from "../../../urls";
import {not_validate_value} from "../../../App";
import {httpRequestWithAuth, instance} from "../../../auth/axios_instance";

export const CreateClaimAttachments = async (url, data) => {

    var bodyFormData = new FormData();

    bodyFormData.set("claim", data.claim);

    if (!not_validate_value.includes(data.name)){
        bodyFormData.append("name", data.name);
    }
    if (!not_validate_value.includes(data.file)){
        bodyFormData.append("file", data.file);
    }

    let feedback = await httpRequestWithAuth(url, "POST", bodyFormData);
    return feedback
};

export const UpdateClaimAttachments = async (url, id, data) => {
    url = url + id + "/";

    var bodyFormData = new FormData();

    bodyFormData.set("claim", data.claim);

    if (!not_validate_value.includes(data.name)){
        bodyFormData.append("name", data.name);
    }
    if (!not_validate_value.includes(data.file)){
        bodyFormData.append("file", data.file);
    }

    let feedback = await httpRequestWithAuth(url, "PATCH", bodyFormData);
    return feedback
};