import React, {useState} from "react";
import {CustomModal} from "../../../../components/CustomModal";
import {Grid} from "@material-ui/core";
import {FormHeader} from "../../../../components/FormHeader";
import Typography from "@material-ui/core/Typography";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {ErrorBox} from "../../../../components/ErrorBox";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import {getLabel} from "../../../../localization/MainLocalization";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";


export const ConfirmModalForApply = props => {
    const [confirm, setConfirm] = useState(false);
    const [error, setError] = useState("");
    const [is_loading, setIsLoading] = useState(false);

    const handleConfirmBtn = async () => {
        await setIsLoading(true);
        if (!confirm) {
            setError("Must Click on check box of read notes and confirm to apply")
        } else {
            await props.handleModalClose()
            await props.handleApply(props.freeze_id)
        }
        await setIsLoading(false);
    }
    return (
        <CustomModal
            open={props.open}
        >
            <Grid container spacing={2}>

                <FormHeader
                    title="confirm_apply"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Notes when will  do apply for location freeze:
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            - Can't change it if there are any membership has frozen days more than price freeze days
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            - Can't delete it if there are any membership has frozen days more than price freeze days
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            - Location freeze will not include any new membership after apply
                        </Typography>
                    </Grid>


                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            - Must delete all class session with attendance in location freeze to apply
                        </Typography>
                    </Grid>


                    <Grid item xs={12}>
                            <CustomCheckBox
                                value={confirm}
                                name='confirm'
                                onChangeHandle={(name, value) => setConfirm(value)}
                                label="read_notes_and_confirm_to_apply"
                            />
                    </Grid>

                    <ErrorBox
                        errorMsg={error}
                    />

                    <Grid item xs={12} className="d-inline-flex mt-2 justify-content-between">
                        <Button
                            onClick={props.handleModalClose}
                            variant="contained"
                            color='default'
                            size="medium"
                            startIcon={<CancelIcon/>}
                        >
                            {getLabel("cancel")}
                        </Button>

                        <Button
                            onClick={handleConfirmBtn}
                            variant="contained"
                            color='primary'
                            size="medium"
                            startIcon={<SaveIcon/>}
                        >
                            {getLabel("apply")}
                        </Button>
                    </Grid>

                </Hide>
            </Grid>
        </CustomModal>
    )
}