import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {getLabel} from "../../../../localization/MainLocalization";
import {BaseClassClassSessionsOfDayURL,} from "../../../../urls";
import Button from "@material-ui/core/Button";
import {not_validate_value, sub_directory} from "../../../../App";
import {Hide} from "../../../../components/Hide";
import {AttendeeModel} from "../../../classes/classes/components/AttendeeModel";
import moment from "moment";
import {CustomTabs} from "../../../../components/CustomTabs";
import Tab from "@material-ui/core/Tab";
import Permissions from "../../../../auth/permissions";
import {permissions_data} from "../../../../auth/permission_data";
import {ViewClassAttendance} from "../../../classes/classes/components/ViewClassAttendance";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {CustomMaterialTable} from "../../../../components/CustomMaterialTable";
import {CustomButton} from "../../../../components/CustomButton";
import Grid from "@material-ui/core/Grid";
import {AthletesAttendeeModel} from "../../../classes/classes/components/AthletesAttendeeModel";

export const ClassSessionOfDay = props => {
    const [data, setData] = useState([]);
    const [view_data, setViewData] = useState([]);
    const [is_loading, setIsLoading] = useState(true);
    const [attendee_data, setAttendeeData] = useState({open: false});
    const [location_options, setLocationOptions] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [view_class_attendance, setViewClassAttendance] = useState({open: false})
    const [fetch_data, setFetchData] = useState(false);
    const [current_date, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
    let can_view = Permissions.checkPermission(permissions_data.class_attendance.view)

    const [setting, setSetting] = useState({
        location: ""
    });


    const mobile_columns = [
        {
            cellStyle: {width: "100%"}, render: rowData => (
                <Grid container className="align-items-center">
                <Grid item xs={7}>
                    <Typography gutterBottom variant="subtitle1" className="font-weight-bold" component="p">
                        {`${rowData.start_time_view} - ${rowData.end_time_view}`}
                    </Typography>

                    <Typography variant="body1" color="textSecondary" component="p">
                        {`${rowData.staff_name}`}
                    </Typography>

                    <Typography variant="body1" color="textSecondary" component="p">
                        {`${rowData.training_area_name}`}
                    </Typography>

                    {/*<label>{rowData.name}</label>*/}
                    {/*<label>{${rowData.date_view} - }</label>*/}
                </Grid>


                    <Grid item xs={5}>
                        <div>
                            <Hide hide={!can_view}>
                                <Button color="primary" size="small"
                                        fullWidth
                                        disabled={!Permissions.checkPermission(permissions_data.class_attendance.view)}
                                        onClick={() => setViewClassAttendance({
                                            open: true,
                                            class_id: rowData.id,
                                            for_clients: rowData.for_clients,
                                            for_athletes: rowData.for_athletes
                                        })}>
                                    {`${rowData.current_registered_number} of ${rowData.total_capacity}`}
                                </Button>
                            </Hide>

                            <Hide hide={can_view}>
                                <label className="mt-1 mb-1 mr-2 ml-2 text-center">
                                    {`${rowData.current_registered_number} of ${rowData.total_capacity}`}
                                </label>
                            </Hide>

                            <Hide
                                hide={!Permissions.checkPermission(permissions_data.class_attendance.add) || !rowData.for_clients}>
                                <CustomButton size="small"
                                              fullWidth
                                              className="m-1"
                                              variant="outlined"
                                              onClick={() => handleAddAttendeeModalOpen(rowData.id, "Client")}>
                                    {getLabel("add_client")}
                                </CustomButton>
                            </Hide>

                            <Hide
                                hide={!Permissions.checkPermission(permissions_data.class_attendance.add) || !rowData.for_athletes}>
                                <CustomButton size="small"
                                              fullWidth
                                              className="m-1"
                                              variant="outlined"
                                              onClick={() => handleAddAttendeeModalOpen(rowData.id, 'Athlete')}>
                                    {getLabel("add_athlete")}
                                </CustomButton>
                            </Hide>
                        </div>
                    </Grid>

                    <Grid item xs={12} className="p-0 mr-0 ml-0 mt-2">
                        <Button size="small"
                                fullWidth
                                variant="outlined"
                                onClick={() => props.history.push({
                                    pathname: sub_directory + 'classes/edit',
                                    state: {
                                        id: rowData.id,
                                        tag: rowData.name
                                    }
                                })}>
                            {getLabel("view")}
                        </Button>
                    </Grid>
                </Grid>
            )
        },
    ];

    const normal_columns = [
        {title: getLabel("name"), field: 'name'},
        {title: getLabel("staff"), field: 'staff_name'},
        {title: getLabel("date"), field: 'date_view'},
        {title: getLabel("start_time"), field: 'start_time_view'},
        {
            title: getLabel('registered_number'), render: rowData => {
                let can_view = Permissions.checkPermission(permissions_data.class_attendance.view)

                return (
                    <div className="d-inline-flex">
                        <Hide hide={!can_view}>
                            <Button color="primary" size="small"
                                    disabled={!Permissions.checkPermission(permissions_data.class_attendance.view)}
                                    onClick={() => setViewClassAttendance({
                                        open: true,
                                        class_id: rowData.id,
                                        for_clients: rowData.for_clients,
                                        for_athletes: rowData.for_athletes
                                    })}>
                                {`${rowData.current_registered_number} of ${rowData.total_capacity}`}
                            </Button>
                        </Hide>

                        <Hide hide={can_view}>
                            <label className="mt-1 mb-1 mr-2 ml-2">
                                {`${rowData.current_registered_number} of ${rowData.total_capacity}`}
                            </label>
                        </Hide>

                        <Hide
                            hide={!Permissions.checkPermission(permissions_data.class_attendance.add) || !rowData.for_clients}>
                            <Button size="small"
                                    variant="outlined"
                                    className='mr-1'
                                    onClick={() => handleAddAttendeeModalOpen(rowData.id, "Client")}>
                                {getLabel("add_client")}
                            </Button>
                        </Hide>

                        <Hide
                            hide={!Permissions.checkPermission(permissions_data.class_attendance.add) || !rowData.for_athletes}>
                            <Button size="small"
                                    variant="outlined"
                                    onClick={() => handleAddAttendeeModalOpen(rowData.id, 'Athlete')}>
                                {getLabel("add_athlete")}
                            </Button>
                        </Hide>
                    </div>
                )
            }
        },
        {title: getLabel("training_area"), field: 'training_area_name'},
        {
            render: rowData => {
                return (
                    <Button size="small"
                            variant="outlined"
                            onClick={() => props.history.push({
                                pathname: sub_directory + 'classes/edit',
                                state: {
                                    id: rowData.id,
                                    tag: rowData.name
                                }
                            })}>
                        {getLabel("view")}
                    </Button>
                )
            }
        },
    ];


    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            let response = await httpRequestWithAuth(BaseClassClassSessionsOfDayURL, "GET", null, {date: current_date});
            if (response.status) {
                let temp_setting = {...setting};
                let temp_data = [];

                if (response.data.locations.length > 0) {
                    if (not_validate_value.includes(setting.location) || response.data.locations[0] !== location_options[0]) {
                        temp_setting.location = response.data.locations[0];
                    }

                    temp_data = response.data.details.filter(ClassData => {
                        return ClassData.location_name === temp_setting.location
                    })

                } else {
                    temp_setting['location'] = ""
                }

                await setData(response.data.details);
                await setViewData(temp_data);
                await setSetting(temp_setting);
                await setLocationOptions(response.data.locations);
            }

            await setIsLoading(false);
        }

        fetchData();
    }, [fetch_data, current_date])


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
        handleSettingChange('location', location_options[newValue])
    };

    const handleAddAttendanceModalClose = async () => {
        await setAttendeeData({open: false});
        await setFetchData(!fetch_data);
    };

    const handleViewAttendanceModalClose = async () => {
        setViewClassAttendance({open: false})
        await setFetchData(!fetch_data);
    };

    const handleSettingChange = async (name, value) => {
        let temp_setting = {...setting, [name]: value};

        await setSetting({...setting, [name]: value});


        let temp_data = await data.filter(ClassData => {
            return ClassData.location_name === temp_setting.location
        })

        await setViewData(temp_data)
    };

    const handleAddAttendeeModalOpen = async (class_id, attendance_for) => {
        setAttendeeData({open: true, class_id: class_id, 'for': attendance_for});
    };

    const backDate = async () => {
        await setCurrentDate(moment(current_date).add(-1, 'day').format("YYYY-MM-DD"));
        // setFetchData(!fetch_data);

    }

    const nextDate = async () => {
        await setCurrentDate(moment(current_date).add(1, 'day').format("YYYY-MM-DD"));
        // setFetchData(!fetch_data);
    }

    return (
        <>
                <Hide hide={!attendee_data.open || attendee_data.for !== "Athlete"}>
                <AthletesAttendeeModel
                    {...props}
                    open={attendee_data.open && attendee_data.for === "Athlete"}
                    handleModalClose={handleAddAttendanceModalClose}
                    class_id={attendee_data.class_id}
                    for={attendee_data.for}
                />
            </Hide>

            <Hide hide={!attendee_data.open || attendee_data.for !== "Client"}>
                <AttendeeModel
                    {...props}
                    open={attendee_data.open && attendee_data.for === "Client"}
                    handleModalClose={handleAddAttendanceModalClose}
                    class_id={attendee_data.class_id}
                    for={attendee_data.for}
                />
            </Hide>

            <Hide hide={!view_class_attendance.open}>
                <ViewClassAttendance
                    open={view_class_attendance.open}
                    class_id={view_class_attendance.class_id}
                    for_clients={view_class_attendance.for_clients}
                    for_athletes={view_class_attendance.for_athletes}
                    handleModalClose={handleViewAttendanceModalClose}
                />
            </Hide>

            <div className='card-paper-1 h-100 w-100'>

                <div className="d-md-inline-flex w-100 align-items-center">
                    <Typography variant="h6" className="text-start">
                        {getLabel("classes")}
                    </Typography>

                    <div className="d-inline-flex align-items-center w-100 justify-content-md-end justify-content-center">
                        <IconButton onClick={backDate} disabled={is_loading}>
                            <ArrowBackIosIcon/>
                        </IconButton>

                        <Typography variant="body1" color="textSecondary" component="p">
                            {`${moment(current_date, "YYYY-MM-DD").format("dddd, DD MMM YYYY")}`}
                        </Typography>

                        <IconButton onClick={nextDate} disabled={is_loading}>
                            <ArrowForwardIosIcon/>
                        </IconButton>
                    </div>

                </div>


                <CustomTabs
                    currentTab={currentTab}
                    handleTabsChange={handleTabsChange}
                >
                    {location_options.map((Location, Index) => (
                        <Tab label={Location} id={Index}/>
                    ))}
                </CustomTabs>

                <CustomMaterialTable
                    normalColumns={normal_columns}
                    mobileColumns={mobile_columns}
                    hideHeaderOnMobileView
                    data={view_data}
                    isLoading={is_loading}
                    style={{
                        boxShadow: "none",
                    }}

                    options={{
                        search: false,
                        toolbar: false,
                        paging: false,
                    }}
                />
            </div>
        </>
    );
}
