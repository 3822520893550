import React from "react";
import MuiPhoneInput from "material-ui-phone-number";
// import MuiPhoneInput from "react-phone-input-2";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";

export const PhoneNumberField = props => {

    const handlePhoneFieldChange = inputValue => {
        if (!props.viewOnly){
        props.onChangeHandle(props.name, inputValue);
        }
    };


    return (
            <MuiPhoneInput
                fullWidth
                disableAreaCodes={true}
                error={props.error}
                helperText={props.helper_text}
                style={{direction:"ltr"}}
                variant="outlined"
                label={<label> {getLabel(props.label)}<span className='text-danger'>{props.required ? "*" : ""}</span></label>}
                defaultCountry="kw"
                onChange={handlePhoneFieldChange}
                value={props.value || ""}
            />
    )
};