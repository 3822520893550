import React, {useContext, useEffect, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {CreateExpensePayments} from "./expenses_apis";
import {Hide} from "../../../components/Hide";
import {permissions_data} from "../../../auth/permission_data";
import {ExpensePaymentModal} from "./components/ExpensePaymentModal";
import {ActionButtons} from "../../../components/ActionButton";
import {sub_directory} from "../../../App";
import {AlertContext} from "../../../store/use_context";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseFinanceExpensePaymentURL} from "../../../urls";


export const ExpensePayments = props =>{
        const {expense_id} = props;
        const {setAlert} = useContext(AlertContext);

            const [columns, setColumns] = useState([
                {title: getLabel("type"), field: 'payment_type'},
                {
                    title: getLabel('payment_method'), render: rowData => {
                        return (
                            <>
                            <Hide hide={rowData.paid_by_claim}>
                                <label>{rowData.payment_method_name}</label>
                            </Hide>

                                <Hide hide={!rowData.paid_by_claim}>
                                <Button color="primary" size="small"
                                        onClick={() => viewClaim(rowData.claim_pk, rowData.claim_tag)}>
                                    {rowData.payment_method_name}
                                </Button>
                                </Hide>
                                </>
                        )
                    }
                },
                {title: getLabel("amount"), field: 'amount'},
                {title: getLabel("reference"), field: 'reference'},
                {title: getLabel("paid_by"), field: 'paid_by_name'},
                {title: getLabel("payment_date"), field: 'payment_date_view'},
                {title: getLabel("created_by"), field: 'created_by_name'},
            ]);
    const [payments, setPayments] = useState([])
    const [payment_form_open, setPaymentFormOpen] = useState(false)
    const [payment_type_form, setPaymentTypeForm] = useState("")
    const [is_loading, setIsLoading] = useState(true)

    const viewClaim = (id, tag) =>{
        props.history.push({
            pathname: `${sub_directory}claims/view`,
            state: {
                id: id,
                tag: tag
            }
        })
    }
    const listPaymentsData = async () => {
        await setIsLoading(true)
        let response = await httpRequestWithAuth(BaseFinanceExpensePaymentURL,"GET", null, {expense: expense_id});
        if (response.status) {
           await setPayments(response.data)
        }

        await setIsLoading(false)
    };

    useEffect(()=>{
        listPaymentsData()
    },[])

    const handlePaymentModalOpen = async data => {
        await setPaymentTypeForm(data)
        await setPaymentFormOpen(true)
    };

    const handleModalClose = async () => {
        await listPaymentsData();
        await setPaymentTypeForm("")
        await setPaymentFormOpen(false)
    };


    const handleBackButton = () => {
        props.history.push(`${sub_directory}expenses`)
    }

        return(
            <>
                    <Hide hide={!payment_form_open}>
                    <ExpensePaymentModal
                        formOpen={payment_form_open}
                        handleModalClose={handleModalClose}
                        id={expense_id}
                        name="expense"
                        CreatePayment={CreateExpensePayments}
                        payment_type={payment_type_form}
                    />
                    </Hide>

                <Grid item xs={12}>
                    <MaterialTable
                        style={{padding: '0.5rem', boxShadow: "none"}}
                        isLoading={is_loading}
                        columns={columns}
                        data={payments}
                        editable={Permissions.checkPermission(permissions_data.expense_payment.delete) && !props.is_closed && {
                                onRowDelete: async PaymentData => {
                                    let response = await httpRequestWithAuth(`${BaseFinanceExpensePaymentURL}${PaymentData.id}`, "DELETE");
                                    if (response.status){
                                        await listPaymentsData();
                                    }else{
                                        await setAlert(response.error_feedback.non_field_errors)
                                    }
                                }
                        }}

                        actions={[
                            {
                                icon: ()=>(
                                    <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                            startIcon={<AddIcon/>}>
                                        <Typography variant="body1">{getLabel('add_payment')}</Typography>
                                    </Button>
                                ),
                                hidden: !Permissions.checkPermission(permissions_data.expense_payment.add) || props.is_closed,
                                isFreeAction: true,
                                onClick: () => handlePaymentModalOpen('Payment')
                            },
                            {
                                icon: ()=>(
                                    <Button key="1" size="small" variant="contained" className='mt-2' color='primary'
                                            startIcon={<AddIcon/>}>
                                        <Typography variant="body1">{getLabel('add_refund')}</Typography>
                                    </Button>
                                ),
                                hidden: !Permissions.checkPermission(permissions_data.expense_payment.add) || props.is_closed,
                                isFreeAction: true,
                                onClick: () => handlePaymentModalOpen('Refund')
                            }
                        ]}

                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false,
                            showTitle: false
                        }}

                        {...material_table_localization()}
                    />
                </Grid>


                <ActionButtons
                    allowBack={true}
                    backAction={handleBackButton}
                />
                </>
        );

}
