import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {not_validate_value, sub_directory} from "../../../App";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseClassesChangeIsLockedURL, BaseClassesChangeTimeDurationURL, BaseClassesURL} from "../../../urls";
import {ServiceAutoComplete} from "../../../components/fields/ServiceAutoComplete";
import {LocationsAutoComplete} from "../../../components/fields/LocationsAutoComplete";
import {StaffAutoComplete} from "../../../components/fields/StaffAutoComplete";
import {DateField} from "../../../components/fields/DateField";
import moment from "moment";
import {TimeField} from "../../../components/fields/TimeField";
import {CustomCheckBox} from "../../../components/CustomCheckBox";
import {TrainingAreaAutoComplete} from "../../../components/fields/TrainingAreaAutoComplete";
import {AlertContext, ConfirmationContext} from "../../../store/use_context";
import {getLabel} from "../../../localization/MainLocalization";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import {MaleFemaleCheckBox} from "../../../components/MaleFemaleCheckBox";
import {ClientAthleteCheckBox} from "../../../components/ClientAthleteCheckBox";
import {GroupsMultipleAutoComplete} from "../../../components/fields/GroupsMultipleAutoComplete";
import {ChangeTimeDurationClassModal} from "./components/ChangeTimeDurationClassModal";
import {ChangeAddTeamModal} from "./components/ChangeAddTeamModal";
import {ChangeRemoveTeamModal} from "./components/ChangeRemoveTeamModal";
import {ChangeChangeForClientsModal} from "./components/ChangeChangeForClientsModal";
import {ChangeStaffClassModal} from "./components/ChangeStaffClassModal";
import {ChangeLocationAndTrainingAreaAndServiceClassModal} from "./components/ChangeLocationAndTrainingAreaAndServiceClassModal";
import {ChangeClassGenderModal} from "./components/ChangeClassGenderModal";
import {ChangeClassClientCapacityModal} from "./components/ChangeClassClientCapacityModal";
import {ChangeClassOptionalAthletesCapacityModal} from "./components/ChangeClassOptionalAthletesCapacityModal";
import {CustomDropDown} from "../../../components/CustomDropDown";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {ChangeClassNameModal} from "./components/ChangeClassNameModal";


export const ClassForm = (props) => {
    const class_id = props.class_id
    const {setConfirmData} = useContext(ConfirmationContext);
    const {setAlert} = useContext(AlertContext);

    const have_permission = ((Permissions.checkPermission(permissions_data.classes.add) && class_id === 'new') ||
        Permissions.checkPermission(permissions_data.classes.change));

    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(true)
    const [data, setData] = useState({required_groups_to_attend:[], required_groups_to_attend_data:[],
        optional_athletes_capacity:0, optional_groups_to_attend_data:[], optional_groups_to_attend:[]});

    const [fetch_data, setFetchData] = useState(false);
    const [open_modals, setOpenModals] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            if (class_id !== 'new') {
                let response = await httpRequestWithAuth(`${BaseClassesURL}${class_id}/`, "GET");

                if (response.status) {
                        props.setForClients(response.data.for_clients);
                        props.setForAthletes(response.data.for_athletes);
                        setData(response.data);
                } else {
                    props.history.push("/notfound");
                }

            }

            await setIsLoading(false)
        }

        fetchData();
    }, [class_id, fetch_data])



    const handleModalOpen = async (name) => {
        await setOpenModals({[name]: true});
    }

    const handleModalClose = async (reload = false) => {
        if (reload) {
            await setFetchData(!fetch_data);
        }
        await setOpenModals({});
    }

    const onLocationChange = async (name, newValue, for_males, for_females) => {
        if (have_permission) {
            await setData({...data, [name]: newValue, service: "", staff: "", training_area: "", for_males, for_females});
        }
    }


    const handleFieldChange = async (name, newValue) => {
        if (have_permission && class_id === "new") {
            let value = moment("1995-06-09 " + newValue)
            if (name === 'start_time' && value.isAfter(moment("1995-06-09 " + data.end_time))) {
                await setData({...data, [name]: newValue, 'end_time': newValue})
            } else if (name === 'end_time' && value.isBefore(moment("1995-06-09 " + data.start_time))) {
                await setData({...data, [name]: newValue, 'start_time': newValue})
            } else {
                let new_data = {...data, [name]: newValue};
                if (name === "date"){
                    new_data['required_groups_to_attend'] = []
                    new_data['optional_groups_to_attend'] = []
                }
                await setData(new_data)
            }
        }
    };


    const handleSaveButton = async () => {
        setIsLoading(true)

        let temp_data = {...data}
        temp_data.date = not_validate_value.includes(temp_data.date) ? null : moment(temp_data.date).format("YYYY-MM-DD");
        if (!temp_data.for_clients){
            temp_data.clients_capacity = 0
        }

        let response;

        if (class_id === 'new') {
            response = await httpRequestWithAuth(BaseClassesURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${BaseClassesURL}${class_id}/`, "PUT", temp_data);
        }

        await setHelperText({});
        if (response.status) {
            handleBackButton()
        } else {
            await setHelperText(response.error_feedback);
        }

        setIsLoading(false)
    };

    const handleBackButton = () => {
        props.history.push(`${sub_directory}classes`)
    }


    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this class ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let response = await httpRequestWithAuth(`${BaseClassesURL}${class_id}/`, "DELETE");
        if (response.status) {
            await handleBackButton()
        } else {
            setHelperText(response.error_feedback)
        }
    }


    const handleChangeIsLocked = (is_locked=false) =>{
        setConfirmData({
            msg: is_locked? "Are you sure to lock this class ??" : "Are you sure to unlock this class ??",
            action: ()=> changeIsLocked(is_locked)
        })
    }

    const changeIsLocked = async (is_locked=false) =>{
        await setIsLoading(true);

        let response = await httpRequestWithAuth(BaseClassesChangeIsLockedURL, "PATCH", {details: [class_id], data:{is_locked}});
        if (response.status){
            await setFetchData(!fetch_data);
        }else{
            setAlert(response.error_feedback[0].non_field_errors)
        }
        await setIsLoading(false);
    }

    return (
        <>

            <Hide hide={open_modals.change_name !== true}>
                <ChangeClassNameModal
                    open={open_modals.change_name === true}
                    handleModalClose={handleModalClose}
                    details={[class_id]}
                    oneClassEdit
                />
            </Hide>

            <Hide hide={open_modals.change_time_duration !== true}>
                <ChangeTimeDurationClassModal
                    open={open_modals.change_time_duration === true}
                    handleModalClose={handleModalClose}
                    details={[class_id]}
                    oneClassEdit
                />
            </Hide>

            <Hide hide={open_modals.add_athlete_team !== true}>
                <ChangeAddTeamModal
                    open={open_modals.add_athlete_team === true}
                    handleModalClose={handleModalClose}
                    details={[class_id]}
                    oneClassEdit
                />
            </Hide>

            <Hide hide={open_modals.remove_athlete_team !== true}>
                <ChangeRemoveTeamModal
                    open={open_modals.remove_athlete_team === true}
                    handleModalClose={handleModalClose}
                    details={[class_id]}
                    oneClassEdit
                />
            </Hide>

            <Hide hide={open_modals.change_assigned_to_clients !== true}>
                <ChangeChangeForClientsModal
                    open={open_modals.change_assigned_to_clients === true}
                    handleModalClose={handleModalClose}
                    details={[class_id]}
                    oneClassEdit
                />
            </Hide>

            <Hide hide={open_modals.change_staff !== true}>
                <ChangeStaffClassModal
                    open={open_modals.change_staff === true}
                    handleModalClose={handleModalClose}
                    details={[class_id]}
                    oneClassEdit
                />
            </Hide>


            <Hide hide={open_modals.change_location_service_training_area !== true}>
                <ChangeLocationAndTrainingAreaAndServiceClassModal
                    open={open_modals.change_location_service_training_area === true}
                    handleModalClose={handleModalClose}
                    details={[class_id]}
                    oneClassEdit
                />
            </Hide>


            <Hide hide={open_modals.change_gender !== true}>
                <ChangeClassGenderModal
                    open={open_modals.change_gender === true}
                    handleModalClose={handleModalClose}
                    details={[class_id]}
                    oneClassEdit
                />
            </Hide>

            <Hide hide={open_modals.change_clients_capacity !== true}>
                <ChangeClassClientCapacityModal
                    open={open_modals.change_clients_capacity === true}
                    handleModalClose={handleModalClose}
                    details={[class_id]}
                    oneClassEdit
                />
            </Hide>

            <Hide hide={open_modals.change_optional_athletes_capacity !== true}>
                <ChangeClassOptionalAthletesCapacityModal
                    open={open_modals.change_optional_athletes_capacity === true}
                    handleModalClose={handleModalClose}
                    details={[class_id]}
                    oneClassEdit
                />
            </Hide>

            <Hide hide={!is_loading}><Loading/></Hide>

            <Hide hide={is_loading}>

                <Hide hide={!have_permission || class_id === 'new'}>
                    <Grid item xs={12} className="text-end">

                        <CustomDropDown
                            readerItem={(
                                <>
                                    <Typography variant='inherit' className="margin-left-2">
                                        {getLabel('edit_actions')}
                                    </Typography>
                                    <ArrowDropDownIcon/>
                                </>
                            )}

                            options={
                                [
                                    {
                                        label: "change_name",
                                        action: () => handleModalOpen('change_name'),
                                        disabled: false
                                    },
                                    {
                                        label: "change_location_,_training_area_and_service",
                                        action: () => handleModalOpen('change_location_service_training_area'),
                                        disabled: false
                                    },
                                    {
                                        label: "change_staff",
                                        action: () => handleModalOpen('change_staff'),
                                        disabled: false
                                    },
                                    {
                                        label: "change_time_duration",
                                        action: () => handleModalOpen('change_time_duration'),
                                        disabled: false
                                    },
                                    {
                                        label: "change_gender",
                                        action: () => handleModalOpen('change_gender'),
                                        disabled: false
                                    },
                                    {
                                        label: "add_athlete_team",
                                        action: () => handleModalOpen('add_athlete_team'),
                                        disabled: false
                                    },
                                    {
                                        label: "remove_athlete_team",
                                        action: () => handleModalOpen('remove_athlete_team'),
                                        disabled: !data.for_athletes
                                    },
                                    {
                                        label: "change_assigned_to_clients",
                                        action: () => handleModalOpen('change_assigned_to_clients'),
                                        disabled: false
                                    },
                                    {
                                        label: "change_clients_capacity",
                                        action: () => handleModalOpen('change_clients_capacity'),
                                        disabled: !data.for_clients
                                    },
                                    {
                                        label: "change_optional_athletes_capacity",
                                        action: () => handleModalOpen('change_optional_athletes_capacity'),
                                        disabled: !data.for_athletes
                                    },
                                    {
                                        label: "lock_class",
                                        action: () => handleChangeIsLocked(true),
                                        disabled: data.is_locked
                                    },
                                    {
                                        label: "unlock_class",
                                        action: () => handleChangeIsLocked(false),
                                        disabled: !data.is_locked
                                    },

                                ]
                            }
                        />

                    </Grid>
                </Hide>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        required
                        viewOnly={class_id !== "new"}
                        helper_text={helper_text.name}
                        error={helper_text.name !== undefined}
                        value={data.name}
                        onChangeHandle={handleFieldChange}
                        name="name"
                        label="name"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <LocationsAutoComplete
                        required
                        active
                        viewOnly={class_id !== "new"}
                        location={data.location}
                        helper_text={helper_text.location}
                        error={helper_text.location !== undefined}
                        name='location'
                        label="location"
                        onChangeHandle={onLocationChange}
                        value={data.location}
                        getLocationGender
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <ServiceAutoComplete
                        active_on_locations={class_id === 'new' ? true : null}
                        required
                        byLocation
                        location={data.location}
                        viewOnly={class_id !== "new"}
                        helper_text={helper_text.service}
                        error={helper_text.service !== undefined}
                        value={data.service}
                        onChangeHandle={handleFieldChange}
                        name="service"
                        label="service"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <StaffAutoComplete
                        name='staff'
                        label="coach"
                        viewOnly={class_id !== "new"}
                        onChangeHandle={handleFieldChange}
                        error={helper_text.staff !== undefined}
                        helper_text={helper_text.staff}
                        value={data.staff}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TimeField
                        required
                        viewOnly={class_id !== "new"}
                        error={helper_text.start_time !== undefined}
                        helper_text={helper_text.start_time}
                        label="start_time"
                        name="start_time"
                        value={data.start_time}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TimeField
                        required
                        viewOnly={class_id !== "new"}
                        error={helper_text.end_time !== undefined}
                        helper_text={helper_text.end_time}
                        label="end_time"
                        name="end_time"
                        value={data.end_time}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <TrainingAreaAutoComplete
                        byLocation
                        location={data.location}
                        viewOnly={class_id !== "new"}
                        helper_text={helper_text.training_area}
                        error={helper_text.training_area !== undefined}
                        value={data.training_area}
                        onChangeHandle={handleFieldChange}
                        name="training_area"
                        label="training_area"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Hide hide={!have_permission}>
                        <DateField
                            required
                            viewOnly={class_id !== "new"}
                            helper_text={helper_text.date}
                            error={helper_text.date !== undefined}
                            name="date"
                            label="date"
                            value={data.date}
                            onChangeHandle={handleFieldChange}
                        />
                    </Hide>
                </Grid>


                <Grid item xs={12} md={6}>
                    <MaleFemaleCheckBox
                        viewOnly={class_id !== "new"}
                        onChangeHandle={handleFieldChange}
                        for_males={data.for_males}
                        male_name="for_males"
                        for_females={data.for_females}
                        female_name="for_females"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <ClientAthleteCheckBox
                        viewOnly={class_id !== "new"}
                        onChangeHandle={handleFieldChange}
                        for_clients={data.for_clients}
                        clients_name="for_clients"
                        for_athletes={data.for_athletes}
                        athletes_name="for_athletes"
                    />
                </Grid>

                <Hide hide={!data.for_clients}>
                <Grid item xs={12}>
                    <CustomTextField
                        required
                        viewOnly={class_id !== "new"}
                        helper_text={helper_text.clients_capacity}
                        error={helper_text.clients_capacity !== undefined}
                        value={data.clients_capacity}
                        type="number"
                        onChangeHandle={handleFieldChange}
                        name="clients_capacity"
                        label="clients_capacity"
                    />
                </Grid>
                </Hide>

                <Hide hide={!data.for_athletes}>
                <Grid item xs={12}>
                        <GroupsMultipleAutoComplete
                            disabled={not_validate_value.includes(data.date)}
                            activeOnDate={data.date}
                            exclude={data.optional_groups_to_attend}
                            groupFullName
                            activeGroup
                            defaultOptions={data.required_groups_to_attend_data || []}
                            viewOnly={class_id !== "new"}
                            helper_text={helper_text.required_groups_to_attend}
                            error={helper_text.athletes_group !== undefined}
                            name="required_groups_to_attend"
                            label="required_groups_to_attend"
                            value={data.required_groups_to_attend}
                            onChangeHandle={handleFieldChange}
                        />
                </Grid>
            </Hide>

                <Hide hide={!data.for_athletes}>
                    <Grid item xs={12}>
                        <GroupsMultipleAutoComplete
                            disabled={not_validate_value.includes(data.date)}
                            activeOnDate={data.date}
                            exclude={data.required_groups_to_attend}
                            groupFullName
                            activeGroup
                            defaultOptions={data.optional_groups_to_attend_data || []}
                            viewOnly={class_id !== "new"}
                            helper_text={helper_text.optional_groups_to_attend}
                            error={helper_text.optional_groups_to_attend !== undefined}
                            name="optional_groups_to_attend"
                            label="optional_groups_to_attend"
                            value={data.optional_groups_to_attend}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>
                </Hide>

                <Hide hide={!data.for_athletes || (data.optional_groups_to_attend || []).length < 1}>
                    <Grid item xs={12}>
                        <CustomTextField
                            viewOnly={class_id !== "new"}
                            helper_text={helper_text.optional_athletes_capacity}
                            error={helper_text.optional_athletes_capacity !== undefined}
                            value={data.optional_athletes_capacity}
                            type="number"
                            onChangeHandle={handleFieldChange}
                            name="optional_athletes_capacity"
                            label="optional_athletes_capacity"
                        />
                    </Grid>
                </Hide>

                <Grid item xs={12}>
                    <CustomCheckBox
                        viewOnly={class_id !== "new"}
                        value={data.is_locked}
                        name='is_locked'
                        onChangeHandle={handleFieldChange}
                        label="is_locked"
                    />
                </Grid>

                {class_id !== 'new' && (
                    <>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="created_by"
                                        value={data.created_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_created"
                                        value={data.date_created}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="modified_by"
                                        value={data.modified_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_modified"
                                        value={data.date_modified}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                    </>
                )}


                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowDelete={Permissions.checkAllPermissions([permissions_data.classes.delete]) && class_id !== 'new'}
                    deleteAction={handleDeleteButton}
                    backAction={handleBackButton}
                    allowSave={class_id === "new"}
                    saveAction={handleSaveButton}
                />


            </Hide>

        </>

    );
}
