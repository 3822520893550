import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {BankAccountForm} from "./BankAccountForm";
import {sub_directory} from "../../../App";
import {CustomTabs} from "../../../components/CustomTabs";
import {BankAccountAttachment} from "./BankAccountAttachment";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";


export const BankAccountInfo = props => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [bank_account_id, setBankAccountId] = React.useState("new");
    const [is_loading, setIsLoading] = React.useState(true);


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };

    useEffect(() => {
        const checkData = async () => {
            await setIsLoading(true)
            const {state} = await props.history.location;
            if (state !== undefined && state.id) {
                await setBankAccountId(state.id);
            } else {
                await props.history.push(`${sub_directory}bank-account`)
            }
            await setIsLoading(false)
        }

        checkData();
    }, [])


    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match}/>*/}

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >

                <Tab label={getLabel("details")} id={0}/>

                <Tab label={getLabel("attachments")} id={0} disabled={!Permissions.checkPermission(permissions_data.bank_account_attachment.view) || bank_account_id === "new"}/>

            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden' spacing={2}>
                <Hide hide={is_loading}>

                    <Hide hide={currentTab !== 0}>
                        <BankAccountForm
                            {...props}
                            bank_account_id={bank_account_id}
                        />
                    </Hide>

                    <Hide hide={currentTab !== 1}>
                        <BankAccountAttachment
                            {...props}
                            bank_account_id={bank_account_id}
                        />
                    </Hide>

                </Hide>


            </Grid>
        </Paper>
    );
}
