import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {sub_directory} from "../../../App";
import {CustomTabs} from "../../../components/CustomTabs";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {getLabel} from "../../../localization/MainLocalization";
import {AthleteForm} from "./AthleteForm";
import {AthleteAttachment} from "./AthleteAttachment";
import {AthleteAttendees} from "./AthleteAttendees";
import {AthleteTeams} from "./AthleteTeams";
import {AthleteBenchmarks} from "./AthleteBenchmarks";
import {AthletePerformance} from "./AthletePerformance";


export const AthleteInfo = props => {
    const state = props.history.location.state || {};
    const [currentTab, setCurrentTab] = React.useState(0);
    const [athlete_id, setAthleteId] = React.useState(state.id);


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };



    useEffect(()=>{
        if (state !== undefined && state.id){
            setAthleteId(state.id);
        }else{
            props.history.push(`${sub_directory}athletes`);
        }

        if (state !== undefined && state.moveToNextTab){
            setCurrentTab(currentTab + 1)
        }
    },[]);

    return (
        <Paper className='Paper'>

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >

                <Tab label={getLabel("details")} id={0}/>

                <Tab label={getLabel("attachment")} id={1} disabled={athlete_id === 'new' || !Permissions.checkPermission(permissions_data.athlete_attachment.view)}/>

                <Tab label={getLabel("attendance")} id={2} disabled={athlete_id === 'new' || !Permissions.checkPermission(permissions_data.class_attendance.view)}/>

                <Tab label={getLabel("teams")} id={3} disabled={athlete_id === 'new' || !Permissions.checkPermission(permissions_data.athlete_group.view)}/>

                <Tab label={getLabel("benchmarks")} id={4} disabled={athlete_id === 'new' || !Permissions.checkPermission(permissions_data.athlete_benchmark.view)}/>

                <Tab label={getLabel("performance")} id={5} disabled={athlete_id === 'new'}/>

            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <AthleteForm
                        history={props.history}
                        athlete_id={athlete_id}
                    />
                </Hide>


                <Hide hide={currentTab !== 1}>
                    <AthleteAttachment
                        history={props.history}
                        athlete_id={athlete_id}
                    />
                </Hide>

                <Hide hide={currentTab !== 2}>
                    <AthleteAttendees
                        {...props}
                        athlete_id={athlete_id}
                    />
                </Hide>

                <Hide hide={currentTab !== 3}>
                    <AthleteTeams
                        {...props}
                        athlete_id={athlete_id}
                    />
                </Hide>


                <Hide hide={currentTab !== 4}>
                    <AthleteBenchmarks
                        {...props}
                        athlete_id={athlete_id}
                    />
                </Hide>


                <Hide hide={currentTab !== 5}>
                    <AthletePerformance
                        {...props}
                        athlete_id={athlete_id}
                    />
                </Hide>

            </Grid>
        </Paper>
    );
}
