import React from "react";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";
import {getLabel} from "../localization/MainLocalization";
import {not_validate_value} from "../App";

export const InputFile = props => {
    const handleChange = (e) => {

        let file = e.target.files[0];
        props.onChangeHandle(props.name, not_validate_value.includes(file)?"":file)
    };

    return (
        <>
            <Button
                variant="contained"
                color='primary'
                size="medium"
                className='mt-3 mb-1 w-75'
            >
                <label htmlFor="file-upload" className='p-0 m-0 w-100'>
                    <PublishIcon className='margin-right-2'/>
                    {getLabel(props.label)}
                </label>
            </Button>
            <input
                type='file'
                id="file-upload"
                onChange={handleChange}
            />
        </>
    );
};