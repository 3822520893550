import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import moment from "moment";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {DateField} from "../../../../components/fields/DateField";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseListLocationsFreezeURL} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {CustomTextArea} from "../../../../components/fields/CustomTextArea";
import {SelectField} from "../../../../components/fields/SelectField";
import {getLabel} from "../../../../localization/MainLocalization";
import {not_validate_value} from "../../../../App";


export const LocationFreezeModal = props => {

    let condition_options = [
        {title: getLabel("on"), value: "on"},
        {title: getLabel("before"), value: "before"},
        {title: getLabel("after"), value: "after"},

    ];
    const [freeze, setFreeze] = useState({
        ...props.value,
        location: props.location,
        apply_on_memberships_start_date: !not_validate_value.includes(props.value.apply_start_date)
    })
    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(false);

    const handleDateChange = async (id, date) => {
        let data = {...freeze, [id]: moment(date).format("YYYY-MM-DD")}

        if (id === 'start_date' && moment(date).isAfter(freeze.end_date)) {
            data = {...data, end_date: moment(date).format("YYYY-MM-DD")}
        }

        await setFreeze(data);
    };

    const handleFieldChange = async (id, value) => {
        await setFreeze({...freeze, [id]: value});
    };

    const handleSaveButton = async () => {
        await setIsLoading(true);
        let temp_data = {...freeze}
        if (!freeze.apply_on_memberships_start_date){
            delete temp_data['apply_condition']
            delete temp_data['apply_start_date']
        }

        let response;
        if (freeze.id === undefined) {
            response = await httpRequestWithAuth(BaseListLocationsFreezeURL, "POST", temp_data)
        } else {
            response = await httpRequestWithAuth(`${BaseListLocationsFreezeURL}${freeze.id}/`, "PATCH", temp_data)
        }

        if (response.status) {
            await props.handleModalClose();
        } else {
            if (response.error_feedback.memberships_freeze_error) {
                await props.handleMembershipsFreezeModelOpen(response.error_feedback)
                await props.handleModalClose();
            } else {
                await setHelperText(response.error_feedback);
            }
        }
        await setIsLoading(false);
    };

    return (

        <CustomModal
            open={props.formOpen}
        >
            <Grid container spacing={2}>

                <FormHeader
                    title="location_freeze_form"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12} md={6}>
                        <DateField
                            required={true}
                            name="start_date"
                            error={helper_text.start_date !== undefined}
                            helper_text={helper_text.start_date}
                            label="start_date"
                            value={freeze.start_date}
                            onChangeHandle={handleDateChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DateField
                            required={true}
                            name="end_date"
                            error={helper_text.end_date !== undefined}
                            helper_text={helper_text.end_date}
                            label="end_date"
                            min_date={freeze.start_date}
                            value={freeze.end_date}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>


                    <Grid item xs={12} className="mt-2">
                        <CustomCheckBox
                            value={freeze.apply_on_memberships_start_date}
                            name='apply_on_memberships_start_date'
                            onChangeHandle={handleFieldChange}
                            label="apply_on_memberships_start_date"
                        />
                    </Grid>

                    <Hide hide={!freeze.apply_on_memberships_start_date}>
                        <Grid item xs={12} md={4}>
                            <SelectField
                                label="apply_condition"
                                name="apply_condition"
                                value={freeze.apply_condition}
                                onChangeHandle={handleFieldChange}
                                options={condition_options}
                            />
                        </Grid>


                        <Grid item xs={12} md={8}>
                            <DateField
                                name="apply_start_date"
                                error={helper_text.apply_start_date !== undefined}
                                helper_text={helper_text.apply_start_date}
                                label="apply_start_date"
                                value={freeze.apply_start_date}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>
                    </Hide>

                    <Grid item xs={12}>
                        <CustomTextArea
                            label="reason"
                            error={helper_text.reason !== undefined}
                            helper_text={helper_text.reason}
                            name="reason"
                            rows={5}
                            value={freeze.reason}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <CustomCheckBox
                            value={freeze.is_editable}
                            name='is_editable'
                            onChangeHandle={handleFieldChange}
                            label="can_edit_by_membership"
                        />
                    </Grid>


                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveButton}
                    />
                </Hide>

            </Grid>
        </CustomModal>
    );
}
