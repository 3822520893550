import React, {Component} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import {PaymentModal} from "../../../components/PaymentModal";
import {Hide} from "../../../components/Hide";
import {CreateRentsPayment, DeleteRentsPayment, ListRentPayment} from "./rents_apis";
import {permissions_data} from "../../../auth/permission_data";

let rent_id = -1;

export default class RentPayments extends Component {
    constructor(props) {
        super(props);
        rent_id = props.rent_id;

        this.state = {
            payments_columns: [
                {title: getLabel("type"), field: 'payment_type'},
                {title: getLabel("payment_method"), field: 'payment_method_name'},
                {title: getLabel("amount"), field: 'amount'},
                {title: getLabel("reference"), field: 'reference'},
                {title: getLabel("payment_date"), field: 'payment_date_view'},
            ],
            payments: [],
            paymentFormOpen: false,
            paymentTypeForm: "",
            is_loading: true,

        }
    }

    ListPaymentsData = async () => {
        await this.setState({is_loading: true});
        let ListPaymentsResponse = await ListRentPayment(rent_id);
        if (ListPaymentsResponse.status) {
            await this.setState({payments: ListPaymentsResponse.data})

        }

        await this.setState({is_loading: false});
    };

    async componentDidMount() {
        await this.ListPaymentsData();
    }

    handlePaymentModalOpen = data => {
        this.setState({
            paymentFormOpen: true,
            paymentTypeForm: data,
        });
    };

    handleModalClose = async () => {
        await this.ListPaymentsData();

        this.setState({
            paymentFormOpen: false,
            paymentTypeForm: "",
        });
    };


    render() {
        return (
            <>
                <Hide hide={!this.state.paymentFormOpen}>
                    <PaymentModal
                        assigned_to="rents"
                        formOpen={this.state.paymentFormOpen}
                        handleModalClose={this.handleModalClose}
                        id={rent_id}
                        name="rent"
                        CreatePayment={CreateRentsPayment}
                        payment_type={this.state.paymentTypeForm}
                    />
                </Hide>
                <Grid item xs={12}>
                    <MaterialTable
                        // title={ get_label("pages.rentinfo.payment_table.header")}
                        style={{padding: '0.5rem', boxShadow: "none"}}
                        is_loading={this.state.is_loading}
                        columns={this.state.payments_columns}
                        data={this.state.payments}
                        viewOnly={{
                            isDeletable: !Permissions.checkAllPermissions([permissions_data.rent_payment.delete]) && !this.props.is_cancelled,
                            onRowDelete: async PaymentData => {
                                await DeleteRentsPayment(PaymentData.id);
                                await this.ListPaymentsData();
                            }
                        }}

                        actions={[
                            {
                                icon: () => (
                                    <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                            startIcon={<AddIcon/>}>

                                        <Typography variant="body1">{getLabel('add_payment')}</Typography>
                                    </Button>
                                ),
                                hidden: !Permissions.checkAllPermissions([permissions_data.rent_payment.add]) || this.props.is_cancelled,
                                isFreeAction: true,
                                onClick: () => this.handlePaymentModalOpen('Payment')
                            },
                            {
                                icon: () => (
                                    <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                            startIcon={<AddIcon/>}>
                                        <Typography variant="body1">{getLabel('add_refund')}</Typography>
                                    </Button>
                                ),
                                hidden: !Permissions.checkAllPermissions([permissions_data.rent_payment.add]) || this.props.is_cancelled,
                                isFreeAction: true,
                                onClick: () => this.handlePaymentModalOpen('Refund')
                            }
                        ]}

                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false,
                            showTitle: false,
                            // toolbar: false
                        }}

                        {...material_table_localization()}
                    />
                </Grid>
            </>
        );
    }

}