export const permissions_data = {
    entity:{
        add:"entities.add_entity",
        view:"entities.view_entity",
        change:"entities.change_entity",
        delete:"entities.delete_entity",
        view_reports:"entities.can_view_reports",
    },
    entity_setting:{
        add:"entities.add_entitysetting",
        view:"entities.view_entitysetting",
        change:"entities.change_entitysetting",
        delete:"entities.delete_entitysetting",
    },
    user:{
        add:"users.add_user",
        view:"users.view_user",
        change:"users.change_user",
        delete:"users.delete_user",
    },
    staff:{
        add:"staff.add_staff",
        view:"staff.view_staff",
        change:"staff.change_staff",
        delete:"staff.delete_staff",
    },
    staff_attachment:{
        add:"staff.add_staffattachment",
        view:"staff.view_staffattachment",
        change:"staff.change_staffattachment",
        delete:"staff.delete_staffattachment",
    },
    staff_permission:{
        add:"staff.add_staffpermission",
        view:"staff.view_staffpermission",
        change:"staff.change_staffpermission",
        delete:"staff.delete_staffpermission",
    },
    location:{
        add:"locations.add_location",
        view:"locations.view_location",
        change:"locations.change_location",
        delete:"locations.delete_location",
    },
    training_area:{
        // add:"locations.add_trainingarea",
        // view:"locations.view_trainingarea",
        // change:"locations.change_trainingarea",
        // delete:"locations.delete_trainingarea",
        add:"locations.add_locationroom",
        view:"locations.view_locationroom",
        change:"locations.change_locationroom",
        delete:"locations.delete_locationroom",
    },
    location_service:{
        add:"locations.add_locationservice",
        view:"locations.view_locationservice",
        change:"locations.change_locationservice",
        delete:"locations.delete_locationservice",
    },
    shared_location_service:{
        add:"locations.add_sharedlocationservice",
        view:"locations.view_sharedlocationservice",
        change:"locations.change_sharedlocationservice",
        delete:"locations.delete_sharedlocationservice",
    },
    location_freeze:{
        add:"locations.add_locationfreeze",
        view:"locations.view_locationfreeze",
        change:"locations.change_locationfreeze",
        delete:"locations.delete_locationfreeze",
    },
    service:{
        add:"services.add_service",
        view:"services.view_service",
        change:"services.change_service",
        delete:"services.delete_service",
    },
    price_list:{
        add:"services.add_pricelist",
        view:"services.view_pricelist",
        change:"services.change_pricelist",
        delete:"services.delete_pricelist",
    },
    price_list_equipment:{
        add:"equipment.add_pricelistequipment",
        view:"equipment.view_pricelistequipment",
        change:"equipment.change_pricelistequipment",
        delete:"equipment.delete_pricelistequipment",
    },
    offer_price_list:{
        add:"services.add_offerpricelist",
        view:"services.view_offerpricelist",
        change:"services.change_offerpricelist",
        delete:"services.delete_offerpricelist",
    },
    price:{
        add:"services.add_price",
        view:"services.view_price",
        change:"services.change_price",
        delete:"services.delete_price",
    },
    offer_price:{
        add:"services.add_offerprice",
        view:"services.view_offerprice",
        change:"services.change_offerprice",
        delete:"services.delete_offerprice",
    },
    equipment:{
        add:"equipment.add_equipment",
        view:"equipment.view_equipment",
        change:"equipment.change_equipment",
        delete:"equipment.delete_equipment",
    },
    equipment_location:{
        add:"equipment.add_equipmentlocation",
        view:"equipment.view_equipmentlocation",
        change:"equipment.change_equipmentlocation",
        delete:"equipment.delete_equipmentlocation",
    },
    client:{
        add:"clients.add_client",
        view:"clients.view_client",
        change:"clients.change_client",
        delete:"clients.delete_client",
        change_vip: 'clients.change_vip'
    },
    client_attachment:{
        add:"clients.add_clientattachment",
        view:"clients.view_clientattachment",
        change:"clients.change_clientattachment",
        delete:"clients.delete_clientattachment",
    },
    membership:{
        add:"memberships.add_membership",
        view:"memberships.view_membership",
        change:"memberships.change_membership",
        delete:"memberships.delete_membership",
        force_edit: "memberships.force_edit_membership",
        transfer: "memberships.transfer_membership",
        upgrade_duration: "memberships.upgrade_duration",
        change_start_date: 'memberships.change_start_date',
        change_invoice_date: 'memberships.change_invoice_date',
        change_extra_sessions_and_extra_days: 'memberships.change_extra_sessions_and_extra_days',
        change_preferred_time: 'memberships.change_preferred_time',
        change_notes: 'memberships.change_notes',
        add_discount: 'memberships.add_discount',
        change_discount: 'memberships.change_discount',
        add_extra_days: 'memberships.add_extra_days',
        add_extra_sessions: 'memberships.add_extra_sessions',
    },
    membership_payment:{
        add:"memberships.add_membershippayment",
        view:"memberships.view_membershippayment",
        change:"memberships.change_membershippayment",
        delete:"memberships.delete_membershippayment",
        add_refund: 'memberships.add_membership_refund',
    },
    membership_freeze:{
        add:"memberships.add_membershipfreeze",
        view:"memberships.view_membershipfreeze",
        change:"memberships.change_membershipfreeze",
        delete:"memberships.delete_membershipfreeze",
        out_of_balance_freeze: 'memberships.add_out_of_balance_freeze',
    },
    membership_cancellation:{
        add:"memberships.add_membershipcancellation",
        view:"memberships.view_membershipcancellation",
        change:"memberships.change_membershipcancellation",
        delete:"memberships.delete_membershipcancellation",
    },
    membership_change_log:{
        add:"memberships.add_membershipchangelog",
        view:"memberships.view_membershipchangelog",
        change:"memberships.change_membershipchangelog",
        delete:"memberships.delete_membershipchangelog",
    },
    rent:{
        add:"rents.add_rent",
        view:"rents.view_rent",
        change:"rents.change_rent",
        delete:"rents.delete_rent",
    },
    rent_payment:{
        add:"rents.add_rentpayment",
        view:"rents.view_rentpayment",
        change:"rents.change_rentpayment",
        delete:"rents.delete_rentpayment",
    },
    rent_cancellation:{
        add:"rents.add_rentcancellation",
        view:"rents.view_rentcancellation",
        change:"rents.change_rentcancellation",
        delete:"rents.delete_rentcancellation",
    },
    rent_equipment:{
        add:"rents.add_rentequipment",
        view:"rents.view_rentequipment",
        change:"rents.change_rentequipment",
        delete:"rents.delete_rentequipment",
    },
    bank_transactions:{
        add:"finance.add_banktransactions",
        view:"finance.view_banktransactions",
        change:"finance.change_banktransactions",
        delete:"finance.delete_banktransactions",
    },
    payment_method:{
        add:"finance.add_paymentmethod",
        view:"finance.view_paymentmethod",
        change:"finance.change_paymentmethod",
        delete:"finance.delete_paymentmethod",
    },
    bank_account:{
        add:"finance.add_bankaccount",
        view:"finance.view_bankaccount",
        change:"finance.change_bankaccount",
        delete:"finance.delete_bankaccount",
    },
    bank_account_attachment:{
        add:"finance.add_bankaccountattachment",
        view:"finance.view_bankaccountattachment",
        change:"finance.change_bankaccountattachment",
        delete:"finance.delete_bankaccountattachment",
    },
    expense:{
        add:"finance.add_expense",
        view:"finance.view_expense",
        change:"finance.change_expense",
        delete:"finance.delete_expense",
    },
    expense_category:{
        add:"finance.add_expensecategory",
        view:"finance.view_expensecategory",
        change:"finance.change_expensecategory",
        delete:"finance.delete_expensecategory",
    },
    expense_attachment:{
        add:"finance.add_expenseattachment",
        view:"finance.view_expenseattachment",
        change:"finance.change_expenseattachment",
        delete:"finance.delete_expenseattachment",
    },
    expense_payment:{
        add:"finance.add_expensepayment",
        view:"finance.view_expensepayment",
        change:"finance.change_expensepayment",
        delete:"finance.delete_expensepayment",
    },
    claim:{
        add:"finance.add_claimrequest",
        view:"finance.view_claimrequest",
        change:"finance.change_claimrequest",
        delete:"finance.delete_claimrequest",
        view_staff: "finance.view_staff_claims",
        change_staff: "finance.change_claims_status",
    },
    claim_payment:{
        add:"finance.add_claimrequestpayment",
        view:"finance.view_claimrequestpayment",
        change:"finance.change_claimrequestpayment",
        delete:"finance.delete_claimrequestpayment",
    },
    claim_attachment:{
        add:"finance.add_claimrequestattachment",
        view:"finance.view_claimrequestattachment",
        change:"finance.change_claimrequestattachment",
        delete:"finance.delete_claimrequestattachment",
        view_staff: "finance.view_staff_claims_attachment",
        add_staff: "finance.add_staff_claims_attachment",
        change_staff: "finance.edit_staff_claims_attachment",
        delete_staff: "finance.delete_staff_claims_attachment",
    },
    cash_transfer_request:{
        add:"finance.add_cashtransferrequest",
        view:"finance.view_cashtransferrequest",
        change:"finance.change_cashtransferrequest",
        delete:"finance.delete_cashtransferrequest",
        collect:"finance.can_collect_cash",
        receive:"finance.can_receive_cash",
        view_staff_pending_request_and_staff_cash:"finance.can_view_staff_pending_request_and_staff_cash",
    },
    cash_log:{
        add:"finance.add_cashlog",
        view:"finance.view_cashlog",
        change:"finance.change_cashlog",
        delete:"finance.delete_cashlog",
    },
    reimbursement:{
        add:"finance.add_reimbursement",
        view:"finance.view_reimbursement",
        change:"finance.change_reimbursement",
        delete:"finance.delete_reimbursement",
    },
    reimbursement_attachment:{
        add:"finance.add_reimbursementattachment",
        view:"finance.view_reimbursementattachment",
        change:"finance.change_reimbursementattachment",
        delete:"finance.delete_reimbursementattachment",
    },
    reimbursement_payment:{
        add:"finance.add_reimbursementpayment",
        view:"finance.view_reimbursementpayment",
        change:"finance.change_reimbursementpayment",
        delete:"finance.delete_reimbursementpayment",
    },
    vendor:{
        add:"finance.add_vendor",
        view:"finance.view_vendor",
        change:"finance.change_vendor",
        delete:"finance.delete_vendor",
    },
    recurring_classes:{
        add:"classes.add_recurringclass",
        view:"classes.view_recurringclass",
        change:"classes.change_recurringclass",
        delete:"classes.delete_recurringclass",
        generate_classes:"classes.can_generate_classes",
    },
    classes:{
        add:"classes.add_class",
        view:"classes.view_class",
        change:"classes.change_class",
        delete:"classes.delete_class",
        view_deleted_classes:"classes.restore_deleted_classes",
        restore_deleted_classes:"classes.restore_deleted_classes",
    },
    class_attendance:{
        add:"classes.add_classattendance",
        view:"classes.view_classattendance",
        change:"classes.change_classattendance",
        delete:"classes.delete_classattendance",
        add_over_capacity:"classes.add_attendance_over_capacity",
        cancel_attendance:"classes.can_cancel_attendance",
    },
    screens:{
        add:"classes.add_screen",
        view:"classes.view_screen",
        change:"classes.change_screen",
        delete:"classes.delete_screen",
    },
    workout:{
        add:"classes.add_workout",
        view:"classes.view_workout",
        change:"classes.change_workout",
        delete:"classes.delete_workout",
    },
    workout_section:{
        add:"classes.add_workoutsection",
        view:"classes.view_workoutsection",
        change:"classes.change_workoutsection",
        delete:"classes.delete_workoutsection",
    },
    banners:{
        add:"mobileapp.add_banner",
        view:"mobileapp.view_banner",
        change:"mobileapp.change_banner",
        delete:"mobileapp.delete_banner",
    },athlete:{
        add:"athletes.add_athlete",
        view:"athletes.view_athlete",
        change:"athletes.change_athlete",
        delete:"athletes.delete_athlete",
    },
    athlete_attachment:{
        add:"athletes.add_athleteattachment",
        view:"athletes.view_athleteattachment",
        change:"athletes.change_athleteattachment",
        delete:"athletes.delete_athleteattachment",
    },
    season:{
        add:"athletes.add_season",
        view:"athletes.view_season",
        change:"athletes.change_season",
        delete:"athletes.delete_season",
    },
    category:{
        add:"athletes.add_category",
        view:"athletes.view_category",
        change:"athletes.change_category",
        delete:"athletes.delete_category",
    },
    group:{
        add:"athletes.add_group",
        view:"athletes.view_group",
        change:"athletes.change_group",
        delete:"athletes.delete_group",
    },
    athlete_group:{
        add:"athletes.add_athletegroup",
        view:"athletes.view_athletegroup",
        change:"athletes.change_athletegroup",
        delete:"athletes.delete_athletegroup",
    },
    athlete_benchmark:{
        add:"athletes.add_athletebenchmark",
        view:"athletes.view_athletebenchmark",
        change:"athletes.change_athletebenchmark",
        delete:"athletes.delete_athletebenchmark",
    },
    benchmark:{
        add:"athletes.add_benchmark",
        view:"athletes.view_benchmark",
        change:"athletes.change_benchmark",
        delete:"athletes.delete_benchmark",
    },
}
