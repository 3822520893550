import React, {useContext, useState} from "react";
import {compareTime, not_validate_value, sub_directory} from "../../../App";
import moment from "moment";
import {getLabel} from "../../../localization/MainLocalization";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {DateField} from "../../../components/fields/DateField";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import {material_table_localization, material_table_style} from "../../../components/Material_conf";
import {AlertContext, ConfirmationContext} from "../../../store/use_context";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import {LocationsAutoComplete} from "../../../components/fields/LocationsAutoComplete";
import {Hide} from "../../../components/Hide";
import {ServiceAutoComplete} from "../../../components/fields/ServiceAutoComplete";
import {StaffAutoComplete} from "../../../components/fields/StaffAutoComplete";
import {TrainingAreaAutoComplete} from "../../../components/fields/TrainingAreaAutoComplete";
import Chip from "@material-ui/core/Chip";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseClassesChangeIsLockedURL, BaseClassesDeleteAllURL, BaseClassesGetAllURL} from "../../../urls";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {CustomDropDown} from "../../../components/CustomDropDown";
import {ChangeStaffClassModal} from "./components/ChangeStaffClassModal";
import {ChangeLocationAndTrainingAreaAndServiceClassModal} from "./components/ChangeLocationAndTrainingAreaAndServiceClassModal";
import {ChangeTimeDurationClassModal} from "./components/ChangeTimeDurationClassModal";
import Divider from "@material-ui/core/Divider";
import {TimeModal} from "../../../components/TimeModal";
import AddIcon from "@material-ui/icons/Add";
import Tab from "@material-ui/core/Tab";
import {Loading} from "../../../components/Loading";
import {CustomTabs} from "../../../components/CustomTabs";
import {ChangeClassGenderModal} from "./components/ChangeClassGenderModal";
import {MaleFemaleCheckBox} from "../../../components/MaleFemaleCheckBox";
import {GroupsMultipleAutoComplete} from "../../../components/fields/GroupsMultipleAutoComplete";
import {CustomRadioGroup} from "../../../components/CustomRadioGroup";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {ChangeClassClientCapacityModal} from "./components/ChangeClassClientCapacityModal";
import {ChangeClassOptionalAthletesCapacityModal} from "./components/ChangeClassOptionalAthletesCapacityModal";
import {ChangeAddTeamModal} from "./components/ChangeAddTeamModal";
import {ChangeRemoveTeamModal} from "./components/ChangeRemoveTeamModal";
import {ChangeChangeForClientsModal} from "./components/ChangeChangeForClientsModal";
import {SelectField} from "../../../components/fields/SelectField";
import {ChangeClassNameModal} from "./components/ChangeClassNameModal";
import {BulkActionErrorTable} from "./components/BulkActionErrorTable";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {Tooltip} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const class_for_options = [
    {title: "clients", value: "clients"},
    {title: "athletes", value: "athletes"},
]

const assigned_to_options = [
    {title: "male_only", value: "Male Only"},
    {title: "female_only", value: "Female Only"},
    {title: "male_&_female", value: "Male & Female"},
    {title: "male_or_female", value: "Male Or Female"},
]


export const ClassesBulkEdit = props => {
    const {setConfirmData} = useContext(ConfirmationContext);

    const team_filter_option = [
        {title: getLabel("contain_any"), value: "contain_any"},
        {title: getLabel("contain_all"), value: "contain_all"},
        {title: getLabel("contain_exact"), value: "contain_exact"},
        {title: getLabel("exclude"), value: "exclude"},
    ];

    const default_filter_data = {
        required_team_filter: "contain_any",
        optional_team_filter: "contain_any",
        assigned_to: "Male Or Female",
        classes_for: "clients",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        start_time_operation: "e",
        end_time_operation: "e",
        days: {
            'saturday': [], 'sunday': [],
            'monday': [], 'tuesday': [],
            'wednesday': [], 'thursday': [],
            'friday': []
        },
    };

    const [currentTab, setCurrentTab] = React.useState(0);
    const {setAlert} = useContext(AlertContext);
    const [is_loading, setIsLoading] = useState(false)
    const [show_classes, setShowClasses] = useState(false)
    const [open_add_time_modal, setOpenAddTimeModal] = useState(false)
    const [setting, setSetting] = useState({...default_filter_data});

    const [data, setData] = useState([]);
    const [modal_details, setModalDetails] = useState([]);
    const [open_modals, setOpenModals] = useState({});

    const [error_table_open, setErrorTableOpen] = useState(false)
    const [error_data, setErrorData] = useState([])
    const [error_columns] = useState([
        {title: getLabel("name"), field: 'class_name'},
        {title: getLabel("date"), field: 'class_date'},
        {title: getLabel("time"), field: 'class_time'},
        {title: getLabel("training_area"), field: "class_training_area"},
        {title: getLabel("is_locked"), render:rowData=>(
                <>
                    <Hide hide={!not_validate_value.includes(rowData.is_locked)}>
                        <CheckCircleIcon className="text-success"/>
                    </Hide>

                    <Hide hide={not_validate_value.includes(rowData.is_locked)}>
                        <Tooltip title={rowData.is_locked}>
                            <CancelIcon  color="secondary"/>
                        </Tooltip>
                    </Hide>
                </>
            )},
        {title: getLabel("general_errors"), render:rowData=>(
                <>
                    <Hide hide={!not_validate_value.includes(rowData.non_field_errors)}>
                        <CheckCircleIcon className="text-success"/>
                    </Hide>

                    <Hide hide={not_validate_value.includes(rowData.non_field_errors)}>
                        <Tooltip title={rowData.non_field_errors}>
                            <CancelIcon  color="secondary"/>
                        </Tooltip>
                    </Hide>
                </>
            )},
        {
            render: rowData => {
                return (
                    <Button
                        href={`${sub_directory}classes/?class-session=${rowData.class_id}&tag=${rowData.class_name}`}
                        target="_blank"
                        component='a'
                        size="small"
                        variant="outlined"
                    >
                        {getLabel("view")}
                    </Button>
                )
            }
        },
    ])

    const columns = [
        {title: getLabel("name"), field: 'name'},
        {title: getLabel("service"), field: 'service_name'},
        {title: getLabel("location"), field: 'location_name'},
        {title: getLabel("staff"), field: 'staff_name'},
        // {title: getLabel("for"), field: 'for_who'},
        {title: getLabel("training_area"), field: 'training_area_name'},
        // setting.classes_for === "athletes" ?
        //     {title: getLabel("optional_athletes_capacity"), field: 'optional_athletes_capacity'} :
        //     {title: getLabel("clients_capacity"), field: 'clients_capacity'},
        {title: getLabel("day"), field: 'day'},
        {title: getLabel("date"), field: 'date_view'},
        {title: getLabel("start_time"), field: 'start_time_view'},
        {title: getLabel("end_time"), field: 'end_time_view'},
    ];

    const ColumnDetails = (props) => (
        <Grid container spacing={2} className="p-3">
            <Grid item xs={12} md={4}>
                <CustomTextField
                    label="assigned_to"
                    value={props.for_who}
                    viewOnly
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <CustomTextField
                    label="class_for"
                    value={props.class_for}
                    viewOnly
                    size="small"
                />
            </Grid>

            <Hide hide={setting.classes_for === "athletes"}>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="clients_capacity"
                        value={props.clients_capacity}
                        viewOnly
                        size="small"
                    />
                </Grid>
            </Hide>

            <Hide hide={setting.classes_for !== "athletes"}>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        label="optional_athletes_capacity"
                        value={props.optional_athletes_capacity}
                        viewOnly
                        size="small"
                    />
                </Grid>
            </Hide>


            <Hide hide={setting.classes_for !== "athletes"}>
                <Grid item xs={12}>
                    <GroupsMultipleAutoComplete
                        label="required_groups_to_attend"
                        value={props.required_groups_to_attend}
                        viewOnly
                        defaultOptions={props.required_groups_to_attend_data || []}
                        size="small"
                    />
                </Grid>
            </Hide>

            <Hide hide={setting.classes_for !== "athletes"}>
                <Grid item xs={12}>
                    <GroupsMultipleAutoComplete
                        label="optional_groups_to_attend"
                        value={props.optional_groups_to_attend}
                        viewOnly
                        defaultOptions={props.optional_groups_to_attend_data || []}
                        size="small"
                    />
                </Grid>
            </Hide>

        </Grid>
    )


    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };


    const handleSettingChange = (name, value) => {
        setSetting({...setting, [name]: value})
    }

    const onLocationChange = async (name, newValue) => {
        await setSetting({...setting, [name]: newValue, training_area: ""})
    }

    const handleDateChange = (name, newValue) => {
        if (not_validate_value.includes(newValue)) {
            newValue = moment().format("YYYY-MM-DD");
        }


        if (name === 'from_date' && moment(newValue).isAfter(moment(setting.to_date))) {

            setSetting({...setting, [name]: newValue, 'to_date': newValue})

        } else if (name === 'to_date' && moment(newValue).isBefore(moment(setting.from_date))) {

            setSetting({...setting, [name]: newValue, 'from_date': newValue})

        } else {

            setSetting({...setting, [name]: newValue})

        }
    }


    const handleViewClassesBtn = async () => {
        await setIsLoading(true);

        const {
            location,
            training_area,
            service,
            staff,
            from_date,
            to_date,
            days,
            assigned_to,
            classes_for,
            required_groups_to_attend,
            optional_groups_to_attend,
            required_team_filter,
            optional_team_filter,
        } = setting

        let params = {}

        if (!not_validate_value.includes(location)) {
            params['location'] = location
        }

        if (!not_validate_value.includes(training_area)) {
            params['training_area'] = training_area
        }

        if (!not_validate_value.includes(service)) {
            params['service'] = service
        }

        if (!not_validate_value.includes(staff)) {
            params['staff'] = staff
        }

        if (!not_validate_value.includes(from_date)) {
            params['from_date'] = from_date
        }

        if (!not_validate_value.includes(to_date)) {
            params['to_date'] = to_date
        }

        if (!not_validate_value.includes(assigned_to)) {
            params['assigned_to'] = assigned_to
        }

        if (!not_validate_value.includes(classes_for)) {
            params['classes_for'] = classes_for
        }

        if (!not_validate_value.includes(required_groups_to_attend) && required_groups_to_attend.length > 0) {
            params['required_groups_to_attend'] = (required_groups_to_attend || []).join(",")
        }

        if (!not_validate_value.includes(optional_groups_to_attend) && optional_groups_to_attend.length > 0) {
            params['optional_groups_to_attend'] = (optional_groups_to_attend || []).join(",")
        }

        if (!not_validate_value.includes(required_team_filter)) {
            params['required_team_filter'] = required_team_filter
        }

        if (!not_validate_value.includes(optional_team_filter)) {
            params['optional_team_filter'] = optional_team_filter
        }


        let temp_days = {}
        Object.keys(days).map(Day => {
            if (days[Day].length > 0) {
                temp_days[Day] = days[Day]
            }
        });

        if (Object.keys(temp_days).length > 0) {
            params['days'] = temp_days
        }

        let response = await httpRequestWithAuth(BaseClassesGetAllURL, "GET", null, params)

        if (response.status) {
            await setData(response.data)
            await setShowClasses(true);
            await setCurrentTab(1)


        } else {
            await setAlert(response.error_feedback.non_field_errors)
        }

        await setIsLoading(false);
    }

    const handleScheduleTimeDelete = (day, index) => {
        let temp = [...setting.days[day]]
        temp.splice(index, 1);

        setSetting({
            ...setting,
            days: {...setting.days, [day]: temp}
        })
    };


    const handleDeleteAll = async (e, data) => {
        await setIsLoading(true);

        let temp_data = []
        data.map(session => {
            temp_data.push(session.id)
        })

        let response = await httpRequestWithAuth(BaseClassesDeleteAllURL, "DELETE", temp_data)

        if (response.status) {
            await handleViewClassesBtn();
        } else {
            await setAlert(response.error_feedback.non_field_errors)
        }
        await setIsLoading(false);
    }

    const handleClearFilter = async () => {
        setSetting({...default_filter_data});
    }

    const handleErrorModalClose = ()=>{
        setErrorTableOpen(false);
        setErrorData([]);
    }

    const handleModalClose = async (reload = false) => {
        if (reload) {
            await handleViewClassesBtn();
        }
        await setOpenModals({});
        await setModalDetails([]);
    }

    const handleModalOpen = async (name) => {
        await setOpenModals({[name]: true});
    }

    const handleDetailsChange = async data => {
        let temp_data = []
        await data.map(Session => temp_data.push(Session.id));
        await setModalDetails(temp_data);
    }


    const handleAddTime = (time_data, days) => {
        let temp_days_data = {...setting.days};

        time_data.map(TimeData => {
            Object.keys(days).map(Day => {
                if (days[Day] === true) {
                    let have_value = false
                    setting.days[Day].map(Data => {
                        if (Data.start_time === TimeData.start_time && Data.end_time === TimeData.end_time) {
                            have_value = true
                        }
                    });

                    if (!have_value) {
                        temp_days_data[Day].push({start_time: TimeData.start_time, end_time: TimeData.end_time})
                    }
                }
                return 0;
            })
        });
        setSetting({...setting, days: temp_days_data})
    }

    const handleChangeIsLocked = (is_locked=false) =>{
        setConfirmData({
            msg: is_locked? `Are you sure to lock ${modal_details.length} classes ??` : `Are you sure to unlock ${modal_details.length} classes ??`,
            action: ()=> changeIsLocked(is_locked)
        })
    }

    const changeIsLocked = async (is_locked=false) =>{
        await setIsLoading(true);

        let response = await httpRequestWithAuth(BaseClassesChangeIsLockedURL, "PATCH", {details: modal_details, data:{is_locked}});
        if (response.status){
            await handleViewClassesBtn(true);
        }else{
            if (Array.isArray(response.error_feedback)) {
                    setErrorData(response.error_feedback);
                    setErrorTableOpen(true);
            } else {
                await setAlert(response.error_feedback[0].non_field_errors);
            }
        }
        await setIsLoading(false);
    }

    const Filter_by = (
        <>

            <Grid item xs={12} className="text-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClearFilter}
                >
                    {getLabel("clear_filter")}
                </Button>
            </Grid>

            <Grid item xs={12} md={6}>
                <LocationsAutoComplete
                    active
                    name='location'
                    label="location"
                    onChangeHandle={onLocationChange}
                    value={setting.location}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TrainingAreaAutoComplete
                    byLocation
                    location={setting.location}
                    value={setting.training_area}
                    onChangeHandle={handleSettingChange}
                    name="training_area"
                    label="training_area"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <ServiceAutoComplete
                    value={setting.service}
                    onChangeHandle={handleSettingChange}
                    name="service"
                    label="service"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <StaffAutoComplete
                    name='staff'
                    label="staff"
                    onChangeHandle={handleSettingChange}
                    value={setting.staff}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <CustomRadioGroup
                    label="assigned_to"
                    name="assigned_to"
                    value={setting.assigned_to}
                    onChangeHandle={handleSettingChange}
                    options={assigned_to_options}
                />
            </Grid>


            <Grid item xs={12} md={6}>
                <CustomRadioGroup
                    label="classes_for"
                    name="classes_for"
                    value={setting.classes_for}
                    onChangeHandle={handleSettingChange}
                    options={class_for_options}
                />
            </Grid>

            <Hide hide={setting.classes_for !== "athletes"}>

                <Grid item xs={12} md={4}>
                    <SelectField
                        removeNullValue
                        label="required_team_filter"
                        name="required_team_filter"
                        value={setting.required_team_filter}
                        onChangeHandle={handleSettingChange}
                        options={team_filter_option}
                    />
                </Grid>

                <Grid item xs={12} md={8}>
                    <GroupsMultipleAutoComplete
                        exclude={setting.optional_groups_to_attend}
                        groupFullName
                        name="required_groups_to_attend"
                        label="required_groups_to_attend"
                        value={setting.required_groups_to_attend}
                        onChangeHandle={handleSettingChange}
                    />
                </Grid>
            </Hide>

            <Hide hide={setting.classes_for !== "athletes"}>
                <Grid item xs={12} md={4}>
                    <SelectField
                        label="optional_team_filter"
                        name="optional_team_filter"
                        value={setting.optional_team_filter}
                        onChangeHandle={handleSettingChange}
                        options={team_filter_option}
                    />
                </Grid>

                <Grid item xs={12} md={8}>
                    <GroupsMultipleAutoComplete
                        exclude={setting.required_groups_to_attend}
                        groupFullName
                        name="optional_groups_to_attend"
                        label="optional_groups_to_attend"
                        value={setting.optional_groups_to_attend}
                        onChangeHandle={handleSettingChange}
                    />
                </Grid>
            </Hide>

            <Grid item xs={12} md={6}>
                <DateField
                    label="from_date"
                    name="from_date"
                    // inputVariant="standard"
                    value={setting.from_date || null}
                    onChangeHandle={handleDateChange}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <DateField
                    label="to_date"
                    name="to_date"
                    min_date={setting.from_date}
                    value={setting.to_date || null}
                    onChangeHandle={handleDateChange}
                />
            </Grid>

            <Grid item xs={12} className="d-inline-flex justify-content-between mt-2">
                <Typography variant="h6">
                    {getLabel("timing")}
                </Typography>

                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={() => setOpenAddTimeModal(true)}
                >
                    {getLabel("add_time")}
                </Button>
            </Grid>

            {Object.keys(setting.days).map((Day, index) => {
                setting.days[Day].sort(compareTime)

                return (
                    <Grid item xs={12} className="mt-2" key={index}>
                        <div className="text-center border w-100 d-inline-flex align-items-center h-100">

                            <div className="bg-light p-2 h-auto justify-content-center align-items-center d-flex"
                                 style={{minWidth: '120px'}}>
                                <Typography variant="h6">
                                    {getLabel(Day)}
                                </Typography>
                            </div>

                            <Divider horizontal variant="fullWidth"/>

                            {setting.days[Day].map((Data, index) => {
                                return (
                                    <Chip key={`${Day}-${Data.start_time}-${Data.end_time}`} className="m-2"
                                          label={`${Data.start_time} - ${Data.end_time}`}
                                          size="small"
                                          onDelete={() => handleScheduleTimeDelete(Day, index)}/>
                                )
                            })}

                            <Hide hide={setting.days[Day].length !== 0}>
                                <Typography variant="body1" className="text-black-50 m-2">
                                    <span>{getLabel("empty_data_source_message")}</span>
                                </Typography>
                            </Hide>
                        </div>
                    </Grid>
                )
            })}


            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color='primary'
                    size="large"
                    fullWidth
                    onClick={handleViewClassesBtn}
                >
                    {getLabel("view_classes")}
                </Button>
            </Grid>

        </>
    )

    const ClassesDetails = (
        <Grid item xs={12}>
            <MaterialTable
                columns={columns}
                data={data}
                isLoading={is_loading}
                detailPanel={rowData => <ColumnDetails {...rowData}/>}
                // onSelectionChange={(selected_rows)=>this.handleSelectChange(selected_rows)}

                options={{
                    selection: true,
                    search: true,
                    showTitle: false,
                    pageSize: 10,
                    pageSizeOptions: [10],
                    searchFieldAlignment: "left",
                    selectionProps: rowData => ({
                        // checked: rowData.selected,
                        color: 'primary'
                    }),
                    showTextRowsSelected: false,
                }}

                actions={[
                    {
                        icon: () => (
                            <CustomDropDown
                                readerItem={(
                                    <>
                                        <Typography variant='inherit' className="margin-left-2">
                                            {getLabel('edit_actions')}
                                        </Typography>
                                        <ArrowDropDownIcon/>
                                    </>
                                )}

                                options={
                                    [
                                        {
                                            label: "change_name",
                                            action: () => handleModalOpen('change_name'),
                                            disabled: false
                                        },
                                        {
                                            label: "change_location_,_training_area_and_service",
                                            action: () => handleModalOpen('change_location_service_training_area'),
                                            disabled: false
                                        },
                                        {
                                            label: "change_staff",
                                            action: () => handleModalOpen('change_staff'),
                                            disabled: false
                                        },
                                        {
                                            label: "change_time_duration",
                                            action: () => handleModalOpen('change_time_duration'),
                                            disabled: false
                                        },
                                        {
                                            label: "change_gender",
                                            action: () => handleModalOpen('change_gender'),
                                            disabled: false
                                        },
                                        {
                                            label: "add_athlete_team",
                                            action: () => handleModalOpen('add_athlete_team'),
                                            disabled: false
                                        },
                                        {
                                            label: "remove_athlete_team",
                                            action: () => handleModalOpen('remove_athlete_team'),
                                            disabled: setting.classes_for !== "athletes"
                                        },
                                        {
                                            label: "change_assigned_to_clients",
                                            action: () => handleModalOpen('change_assigned_to_clients'),
                                            disabled: false
                                        },
                                        {
                                            label: "change_clients_capacity",
                                            action: () => handleModalOpen('change_clients_capacity'),
                                            disabled: setting.classes_for !== "clients"
                                        },
                                        {
                                            label: "change_optional_athletes_capacity",
                                            action: () => handleModalOpen('change_optional_athletes_capacity'),
                                            disabled: setting.classes_for !== "athletes"
                                        },
                                        {
                                            label: "lock_classes",
                                            action: () => handleChangeIsLocked(true),
                                            disabled: false
                                        },
                                        {
                                            label: "unlock_classes",
                                            action: () => handleChangeIsLocked(false),
                                            disabled: false
                                        },
                                    ]
                                }
                            />
                        ),
                        onClick: (e, data) => handleDetailsChange(data)
                    },
                    {
                        icon: () => (
                            <Button key="0" size="small" variant="outlined" color='secondary'
                                    startIcon={<DeleteIcon/>}>
                                <Typography variant="body1">{getLabel('delete_all')}</Typography>
                            </Button>
                        ),
                        tooltip: getLabel('delete_all'),
                        onClick: handleDeleteAll
                    },
                ]}

                {...material_table_style}
                {...material_table_localization()}
            />
        </Grid>
    )

    return (
        <>

            <Hide hide={!error_table_open}>
                <BulkActionErrorTable
                    open={error_table_open}
                    data={error_data}
                    columns={error_columns}
                    handleModalClose={handleErrorModalClose}
                />
            </Hide>

            <Hide hide={!open_add_time_modal}>
                <TimeModal
                    handleModalClose={() => setOpenAddTimeModal(false)}
                    handleAddTime={handleAddTime}
                    open={open_add_time_modal}
                />
            </Hide>

            <Hide hide={open_modals.change_name !== true}>
                <ChangeClassNameModal
                    open={open_modals.change_name === true}
                    handleModalClose={handleModalClose}
                    details={modal_details}
                    oneClassEdit
                />
            </Hide>

            <Hide hide={open_modals.change_time_duration !== true}>
                <ChangeTimeDurationClassModal
                    open={open_modals.change_time_duration === true}
                    handleModalClose={handleModalClose}
                    details={modal_details}
                />
            </Hide>

            <Hide hide={open_modals.add_athlete_team !== true}>
                <ChangeAddTeamModal
                    open={open_modals.add_athlete_team === true}
                    handleModalClose={handleModalClose}
                    details={modal_details}
                />
            </Hide>

            <Hide hide={open_modals.remove_athlete_team !== true}>
                <ChangeRemoveTeamModal
                    open={open_modals.remove_athlete_team === true}
                    handleModalClose={handleModalClose}
                    details={modal_details}
                />
            </Hide>

            <Hide hide={open_modals.change_assigned_to_clients !== true}>
                <ChangeChangeForClientsModal
                    open={open_modals.change_assigned_to_clients === true}
                    handleModalClose={handleModalClose}
                    details={modal_details}
                />
            </Hide>

            <Hide hide={open_modals.change_staff !== true}>
                <ChangeStaffClassModal
                    open={open_modals.change_staff === true}
                    handleModalClose={handleModalClose}
                    details={modal_details}
                />
            </Hide>


            <Hide hide={open_modals.change_location_service_training_area !== true}>
                <ChangeLocationAndTrainingAreaAndServiceClassModal
                    open={open_modals.change_location_service_training_area === true}
                    handleModalClose={handleModalClose}
                    details={modal_details}
                />
            </Hide>


            <Hide hide={open_modals.change_gender !== true}>
                <ChangeClassGenderModal
                    open={open_modals.change_gender === true}
                    handleModalClose={handleModalClose}
                    details={modal_details}
                />
            </Hide>

            <Hide hide={open_modals.change_clients_capacity !== true}>
                <ChangeClassClientCapacityModal
                    open={open_modals.change_clients_capacity === true}
                    handleModalClose={handleModalClose}
                    details={modal_details}
                />
            </Hide>

            <Hide hide={open_modals.change_optional_athletes_capacity !== true}>
                <ChangeClassOptionalAthletesCapacityModal
                    open={open_modals.change_optional_athletes_capacity === true}
                    handleModalClose={handleModalClose}
                    details={modal_details}
                />
            </Hide>


            <Paper className="Paper">
                <CustomTabs
                    currentTab={currentTab}
                    handleTabsChange={handleTabsChange}
                >
                    <Tab label={getLabel("filter")} id={0}/>
                    <Tab label={getLabel("details")} id={1} disabled={!show_classes}/>
                </CustomTabs>


                <Grid container spacing={2} className="pt-4 pb-3">
                    <Hide hide={currentTab !== 0}>
                        <Hide hide={!is_loading}>
                            <Loading/>
                        </Hide>

                        <Hide hide={is_loading}>
                            {Filter_by}
                        </Hide>
                    </Hide>

                    <Hide hide={currentTab !== 1}>
                        {ClassesDetails}
                    </Hide>

                </Grid>
            </Paper>
        </>
    )
}
