import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../assets/scss/Static.scss';
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import {CustomModal} from "../../../../components/CustomModal";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {ExpenseCategoryComplete} from "../../../../components/fields/ExpenseCategoryComplete";
import {DateField} from "../../../../components/fields/DateField";
import {LocationsAutoComplete} from "../../../../components/fields/LocationsAutoComplete";
import {CustomTextArea} from "../../../../components/fields/CustomTextArea";
import {ExpenseAutoComplete} from "../../../../components/fields/ExpenseAutoComplete";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseFinanceClaimApproveURL} from "../../../../urls";
import {VendorAutoComplete} from "../../../../components/fields/VendorAutoComplete";
import {getLabel} from "../../../../localization/MainLocalization";


export const ApproveClaimFormModal = props => {
    const claim = props.claim || {};
    const [helper_text, setHelperText] = useState({});
    const [data, setData] = useState({
        title:claim.title,
        vendor:claim.vendor,
        invoice_date:claim.invoice_date,
        cost:claim.total_invoice_amount,
    });

    const [is_loading, setIsLoading] = useState(false);

    const handleFieldsChange = (name, value) => {
        setData({...data, [name]: value})
    }

    const handleSaveBtn = async () =>{
        await setIsLoading(true);
        await setHelperText({});
        let response = await httpRequestWithAuth(`${BaseFinanceClaimApproveURL(claim.id)}`, "PATCH", data);
        if (response.status){
            await props.handleModalClose();
        }else{
            await setHelperText(response.error_feedback)
        }
        await setIsLoading(false);
    }

    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>

                    <FormHeader
                        title="approve_claim"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={is_loading}>

                        <RadioGroup name="is_new_expense" value={data.is_new_expense + ""}
                                    onChange={e => handleFieldsChange('is_new_expense', e.target.value)}>

                            <Grid container spacing={3} className="p-2">


                            <Grid item xs={12}>
                                <FormControlLabel value="false" control={<Radio/>}
                                                  label={getLabel("link_with_exists_expense")}/>
                            </Grid>

                            <Grid item xs={12}>
                                <ExpenseAutoComplete
                                    full_paid={false}
                                    vendor={claim.vendor}
                                    disabled={data.is_new_expense !== "false"}
                                    required
                                    helper_text={helper_text.expense}
                                    error={helper_text.expense !== undefined}
                                    value={data.expense}
                                    onChangeHandle={handleFieldsChange}
                                    name="expense"
                                    label="expense"
                                />
                            </Grid>

                            <Grid item xs={12} className="mt-3">
                                <FormControlLabel value="true" control={<Radio/>}
                                                  label={getLabel("create_new_expense")}/>
                            </Grid>

                            <Grid item xs={12}>

                                <CustomTextField
                                    disabled={data.is_new_expense !== "true"}
                                    required
                                    helper_text={helper_text.title}
                                    error={helper_text.title !== undefined}
                                    value={data.title}
                                    onChangeHandle={handleFieldsChange}
                                    name="title"
                                    label="title"
                                />
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <ExpenseCategoryComplete
                                    disabled={data.is_new_expense !== "true"}
                                    category={data.category}
                                    required
                                    name='category'
                                    label="expense_category"
                                    onChangeHandle={handleFieldsChange}
                                    error={helper_text.category !== undefined}
                                    value={data.category}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DateField
                                    disabled={data.is_new_expense !== "true"}
                                    required
                                    name="invoice_date"
                                    error={helper_text.invoice_date !== undefined}
                                    helper_text={helper_text.invoice_date}
                                    label="invoice_date"
                                    value={data.invoice_date}
                                    onChangeHandle={handleFieldsChange}
                                />
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <LocationsAutoComplete
                                    disabled={data.is_new_expense !== "true"}
                                    name='location'
                                    label="location"
                                    onChangeHandle={handleFieldsChange}
                                    error={helper_text.location !== undefined}
                                    value={data.location}
                                />
                            </Grid>

                                <Grid item xs={12} md={6}>
                                    <VendorAutoComplete
                                        disabled={data.is_new_expense !== "true"}
                                        name='vendor'
                                        label="vendor"
                                        onChangeHandle={handleFieldsChange}
                                        error={helper_text.vendor !== undefined}
                                        value={data.vendor}
                                    />
                                </Grid>


                            <Grid item xs={12}>
                                <CustomTextField
                                    disabled={data.is_new_expense !== "true"}
                                    required
                                    name="cost"
                                    label="cost"
                                    type="number"
                                    error={helper_text.cost !== undefined}
                                    helper_text={helper_text.cost}
                                    value={data.cost}
                                    onChangeHandle={handleFieldsChange}
                                />
                            </Grid>

                            <Grid item xs={12} className="mt-1">
                                <CustomTextArea
                                    disabled={data.is_new_expense !== "true"}
                                    label="description"
                                    error={helper_text.description !== undefined}
                                    helper_text={helper_text.description}
                                    name="description"
                                    rows={5}
                                    value={data.description || ""}
                                    onChangeHandle={handleFieldsChange}
                                />
                            </Grid>

                            </Grid>
                        </RadioGroup>

                        <FormActionButtons
                            errorMsg={helper_text.non_field_errors}
                            handleCancelButton={props.handleModalClose}
                            handleSaveButton={handleSaveBtn}
                        />
                    </Hide>
                </Grid>

            </CustomModal>
        </>
    );
};
