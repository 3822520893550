import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {not_validate_value, sub_directory} from "../../../App";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseFinanceVendorURL} from "../../../urls";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../localization/MainLocalization";
import {material_table_localization} from "../../../components/Material_conf";
import {VenderContactFormModal} from "./components/VenderContactFormModal";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {ConfirmationContext} from "../../../store/use_context";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {AddressFields} from "../../../components/fields/AddressFields";
import {CustomTextArea} from "../../../components/fields/CustomTextArea";
import {DisabledTextField} from "../../../components/fields/DisabledField";


export const VendorForm = (props) => {
    const vendor_id = props.vendor_id
    const {setConfirmData} = useContext(ConfirmationContext);


    const contact_columns = [
        {title: getLabel("name"), field: "name"},
        {title: getLabel("phone"), field: "phone"},
        {title: getLabel("email"), field: "email"},
    ]

    const have_permission = ((Permissions.checkPermission(permissions_data.vendor.add) && vendor_id === 'new') ||
        (vendor_id !== 'new' && Permissions.checkPermission(permissions_data.vendor.change)));

    const [helper_text, setHelperText] = useState({});
    const [open_contact_modal, setOpenContactModal] = useState(false);
    const [contact_modal_data, setContactModalData] = useState({});
    const [is_loading, setIsLoading] = useState(true);
    const [is_loading_section, setIsLoadingSection] = useState(true);
    const [data, setData] = useState({});
    const [contacts_data, setContactsData] = useState([]);
    const [edit_data, setEditData] = useState(false);


    useEffect(()=>{
        setEditData(vendor_id === 'new' && have_permission);
    }, [vendor_id]);

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            if (vendor_id !== 'new') {
                let response = await httpRequestWithAuth(`${BaseFinanceVendorURL}${vendor_id}/`, "GET");

                if (response.status) {
                    setData({
                        ...response.data,
                        country: not_validate_value.includes(response.data.country)?"":response.data.country.name,
                    });

                    if (!not_validate_value.includes(response.data.contacts)){
                        let temp_contacts = JSON.parse(response.data.contacts);
                        temp_contacts.map((Contact, Index) =>Contact.index = Index);
                        setContactsData(temp_contacts);
                    }
                } else {
                    props.history.push("/notfound");
                }

            }

            await setIsLoading(false)
        }

        fetchData();
    }, [vendor_id, edit_data])


    const handleFieldChange = (name, newValue) => {
        if (have_permission && edit_data) {
            setData({...data, [name]: newValue})
        }
    };


    const handleModalClose = async () => {
        await setContactModalData({});
        await setOpenContactModal(false);
        await setIsLoadingSection(false);
        await setHelperText({});
    }

    const handleModalOpen = async (data) => {
        await setContactModalData(data)
        await setOpenContactModal(true)
        await setIsLoadingSection(true)
    }

    const handleSaveButton = async () => {
        setIsLoading(true)

        let vendor_data = {...data,contacts: JSON.stringify([...contacts_data])}

        let response;
        if (vendor_id === 'new') {
            response = await httpRequestWithAuth(BaseFinanceVendorURL, "POST", vendor_data);
        } else {
            response = await httpRequestWithAuth(`${BaseFinanceVendorURL}${vendor_id}/`, "PUT", vendor_data);
        }

        await setHelperText({});
        if (response.status) {
            handleBackButton()
        } else {
            await setHelperText(response.error_feedback);
        }

        setIsLoading(false)
    };

    const handleBackButton = () => {
        props.history.push(`${sub_directory}vendors`)
    }


    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this vendor ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let response = await httpRequestWithAuth(`${BaseFinanceVendorURL}${vendor_id}/`, "DELETE");

        if (response.status) {
            handleBackButton()
        }else{
            await setHelperText(response.error_feedback)

        }
    }

    const handleSaveForm = async (data) =>{
        let check_data = true;
        let temp_helper_text = {}
        await contacts_data.map((Contact, index)=>{
            if (data.index !== index && Contact.name === data.name){
                temp_helper_text = {...temp_helper_text, name: "this field must be unique"};
                check_data = false;
            }
        })

        if (not_validate_value.includes(data.name)){
            temp_helper_text = {...temp_helper_text, name: "this field is required"};
            check_data = false;
        }

        if (not_validate_value.includes(data.phone)){
            temp_helper_text = {...temp_helper_text, phone: "this field is required"};
            check_data = false;
        }

        if (not_validate_value.includes(data.email)){
            temp_helper_text = {...temp_helper_text, email: "this field is required"};
            check_data = false;
        }

        if (check_data){
            let new_data = [...contacts_data];
            if (not_validate_value.includes(data.index)){
                new_data = [...contacts_data, {...data}]
            }else{
                new_data[data.index] = {...data}
            }
            await new_data.map((Contact, index)=>{
                Contact.index = index;
            })
            await setContactsData(new_data);
            await handleModalClose();
        }else{
            setHelperText(temp_helper_text)
        }
    }

    const moveUp = (index)=>{
        if (have_permission && edit_data) {
            if (index === 0) return;
            let temp_data = [
                ...contacts_data.slice(0, index - 1),
                contacts_data[index],
                contacts_data[index - 1],
                ...contacts_data.slice(index + 1)
            ]

            temp_data.map((Data, Index) => {
                Data.index = Index
            })
            setContactsData(temp_data);
        }
    }

    //
    // const moveDown = (index)=>{
    //     if (index === section_data.length -1)return ;
    //     let temp_data = [
    //         ...section_data.slice(0, index),
    //         section_data[index + 1],
    //         section_data[index],
    //         ...section_data.slice(index + 2)
    //     ]
    //
    //     temp_data.map((Data,Index)=>{
    //         Data.index = Index
    //     })
    //     setSectionData(temp_data);
    // }


    const handleEditAction = () => {
        setEditData(!edit_data);
    }

    return (
        <>
            <Hide hide={!is_loading}><Loading/></Hide>


            <Hide hide={is_loading}>

                <Hide hide={!open_contact_modal}>
                    <VenderContactFormModal
                        open={open_contact_modal}
                        handleModalClose={handleModalClose}
                        data={contact_modal_data}
                        vendor_id={vendor_id}
                        helper_text={helper_text}
                        handleSaveForm={handleSaveForm}
                    />
                </Hide>

                <Hide hide={!have_permission || vendor_id === 'new'}>
                    <Grid item xs={12} className="text-end">
                        <Button
                            size="small"
                            startIcon={edit_data ? <CloseIcon/> : <EditIcon/>}
                            className={`text-white ${edit_data ? "bg-danger" : "primary-bg"}`}
                            onClick={handleEditAction}
                        >
                            {edit_data ? getLabel("cancel_edit") : getLabel("edit")}
                        </Button>
                    </Grid>
                </Hide>


                <Grid item xs={12}>
                    <CustomTextField
                        required
                        viewOnly={!edit_data}
                        location={data.location}
                        helper_text={helper_text.name}
                        error={helper_text.name !== undefined}
                        value={data.name}
                        onChangeHandle={handleFieldChange}
                        name="name"
                        label="name"
                    />
                </Grid>


                <AddressFields
                    viewOnly={!edit_data}
                    helper_text={helper_text}
                    value={data}
                    onChangeHandle={handleFieldChange}
                />

                <Grid item xs={12} className="mt-2">
                    <CustomTextArea
                        viewOnly={!edit_data}
                        label="notes"
                        error={helper_text.notes !== undefined}
                        helper_text={helper_text.notes}
                        name="notes"
                        rows={5}
                        value={data.notes || ""}
                        onChangeHandle={handleFieldChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MaterialTable
                        title={getLabel("contacts")}
                        style={{padding: '0.5rem', boxShadow: "none", marginTop:10, marginBottom: 10}}
                        is_loading={is_loading_section}
                        columns={contact_columns}
                        data={contacts_data}
                        editable={edit_data && {
                            onRowDelete: async rowData => {
                                let new_data = [...contacts_data];
                                await new_data.splice(rowData.index, 1);
                                await setContactsData(new_data);
                            }
                        }}

                        actions={[
                            {
                                icon: () => (
                                    <Button key="0" size="small" variant="contained" className='mt-2'
                                            color='primary'
                                            startIcon={<AddIcon/>}>
                                        <Typography variant="body1">{getLabel('add_contact')}</Typography>
                                    </Button>
                                ),
                                isFreeAction: true,
                                onClick: () => handleModalOpen({}),
                                hidden: !edit_data
                            },
                            // {
                            //     icon: ()=><ArrowDownwardOutlinedIcon/>,
                            //     hidden: true,
                            //     tooltip: getLabel('move_down'),
                            //     onClick: (event, rowData) => moveDown(rowData.index)
                            // },
                            {
                                icon: ()=><ArrowUpwardOutlinedIcon/>,
                                tooltip: getLabel('move_up'),
                                onClick: (event, rowData) => moveUp(rowData.index),
                                hidden: !edit_data
                            },
                            {
                                icon: 'edit',
                                tooltip: getLabel('edit_contact'),
                                onClick: (event, rowData) => handleModalOpen(rowData),
                                hidden: !edit_data
                            },
                        ]}

                        options={{
                            actionsColumnIndex: -1,
                            paging: false,
                            showTitle: false,
                            searchFieldAlignment: "left"
                        }}

                        {...material_table_localization()}
                    />
                </Grid>

                {vendor_id !== 'new' && (
                    <>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="created_by"
                                        value={data.created_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_created"
                                        value={data.date_created}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="modified_by"
                                        value={data.modified_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DisabledTextField
                                        label="date_modified"
                                        value={data.date_modified}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                    </>
                )}



                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowDelete={Permissions.checkAllPermissions([permissions_data.vendor.delete]) && vendor_id !== 'new'}
                    deleteAction={handleDeleteButton}
                    allowBack
                    backAction={handleBackButton}
                    allowSave={edit_data}
                    saveAction={handleSaveButton}
                />


            </Hide>

        </>

    );
}
