import React, {useRef, useState} from "react";
import {getLabel} from "../../../localization/MainLocalization";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import Permissions from "../../../auth/permissions";
import MaterialTable from "material-table";
import {permissions_data} from "../../../auth/permission_data";
import {instance} from "../../../auth/axios_instance";
import {BaseFinanceVendorURL} from "../../../urls";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {sub_directory} from "../../../App";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export const Vendors = props => {
    const tableRef = useRef();

    const columns = [
        {
            title: getLabel("name"), render: rowData => (
                <Typography>
                    <Link href="" onClick={e => {
                        e.preventDefault();
                        viewVendor(rowData.id, rowData.name)
                    }}>
                        {rowData.name}
                    </Link>
                </Typography>
            )
        },
        {title: getLabel("date_created"), field: 'date_created'},
        {title: getLabel("date_modified"), field: 'date_modified'},
    ];

    const [search, setSearch] = useState("")

    const viewVendor = (id, tag) => {
        props.history.push(
            {
                pathname: `${sub_directory}vendors/edit`,
                state: {
                    id: id,
                    tag: tag,
                }
            }
        )
    }
    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if ((newValue.length >= 5 && /^\d+$/.test(newValue)) || newValue === '') {
            reloadData();
        }

    };

    return (
        <>
            <Paper>

                <TableToolbar
                    searchFieldName='search'
                    searchValue={search}
                    onSearchChangeHandle={handleSearchField}
                    searchAction={reloadData}
                    match={props.match}
                    actions={
                        [
                            {
                                hide: !Permissions.checkPermission(permissions_data.vendor.add),
                                onClick: () => props.history.push(
                                    {
                                        pathname: `${sub_directory}vendors/new`,
                                        state: {id: "new"}
                                    }
                                ),
                                label: "add_vendor"
                            },
                        ]
                    }
                />

                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let url = BaseFinanceVendorURL;

                            url += '?page=' + (query.page + 1);

                            if (search !== '') {
                                url += '&search=' + search;
                            }

                            instance.get(url)
                                .then(result => {
                                    resolve({
                                        data: result.data.results,
                                        page: result.data.page - 1,
                                        totalCount: result.data.total,
                                    })
                                })
                        })
                    }


                    {...material_table_style}
                    {...material_table_options()}
                    {...material_table_localization()}


                    {...material_table_localization()}
                />
            </Paper>
        </>
    );

}