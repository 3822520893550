import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseAthleteSeasonListAllWithGroupsURL} from "../../urls";
import {not_validate_value} from "../../App";

export const SeasonAutoComplete = props=> {
    const [options, setOptions] = useState([])

    useEffect(()=>{
        const fetchData = async ()=>{
            let params = {}

            if (!not_validate_value.includes(props.is_active)){
                params['is_active'] = props.is_active
            }

            let response = await httpRequestWithAuth(BaseAthleteSeasonListAllWithGroupsURL, "GET", null, params);
            if (response.status){
                setOptions(response.data);
            }else{
                setOptions([])
            }
        }

        fetchData();
    },[])

    const handleOnChange = (event, value) => {
        if (!props.viewOnly){
            if (props.getGroups && !not_validate_value.includes(value)){
                props.getGroups(value.groups);
            }

        value = value !== null ? props.fullObject?value: value.id : "";
        props.onChangeHandle(props.name, value);
        }

    };

    const handleAutoCompleteValue = () => {
        let value = null;

            options.map(Data => {
                if (Data.id === props.value) {
                    value = Data;
                }
                return 0;
            });

        return value;
    };


        return (
            <Autocomplete
                ListboxProps={{className:"list-box-autocomplete"}}
                renderOption={(option) => (
                    <span className="w-100 text-start">{`${option.name}`}</span>
                )}
                disabled={props.disabled || props.viewOnly}
                options={options}
                getOptionLabel={option => `${option.name}`}
                renderInput={params => (
                    <TextField variant="outlined" {...params}
                               label={<label>{getLabel(props.label)}<span
                                   className='text-danger'>{props.required ? "*" : ""}</span></label>}
                               helperText={props.helper_text}
                               error={props.error}
                               fullWidth
                               disabled={props.disabled}
                               className={props.disabled ? "disabled-field" : ""}
                    />
                )}
                value={handleAutoCompleteValue()}
                onChange={handleOnChange}
            />
        );
}
