import React, {useEffect, useState} from 'react';
import {Bar, BarChart, Tooltip, XAxis, YAxis,} from 'recharts';
import Typography from "@material-ui/core/Typography";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseFinanceEntityTotalRevenueURL} from "../../../../urls";
import moment from "moment";
import {DateField} from "../../../../components/fields/DateField";
import {Grid} from "@material-ui/core";
import {LocationsAutoComplete} from "../../../../components/fields/LocationsAutoComplete";
import {not_validate_value} from "../../../../App";
import {CustomRadioGroup} from "../../../../components/CustomRadioGroup";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";


const duration_type_options = [
    {title: "yearly", value: "yearly"},
    {title: "monthly", value: "monthly"},
    {title: "daily", value: "daily"},
]


export const EntityTotalRevenue = props => {
    const [data, setData] = useState({total: 0})
    const [is_loading, setIsLoading] = useState(true)
    const [min_date, setMinDate] = useState(moment())
    const [setting, setSetting] = useState({
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        duration_type: "yearly",
        location: ""
    })

    const handleSettingChange = async (name, value) => {
        if (name === 'location' && not_validate_value.includes(value)) {
            value = ""
        }

        await setSetting({...setting, [name]: value})
    };

    const handleDateChange = async (id, date) => {
        let data = {...setting, [id]: moment(date).format("YYYY-MM-DD")}

        if (id === 'from_date' && moment(date).isAfter(data.to_date)) {
            data = {...setting, to_date: moment(date).format("YYYY-MM-DD")}
        }

        await setSetting(data);
    };


    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            const {from_date, to_date, location, duration_type} = setting
            let response = await httpRequestWithAuth(BaseFinanceEntityTotalRevenueURL, "GET", null,
                {from_date, to_date, location: not_validate_value.includes(location) ? null : location, duration_type});
            if (response.status) {
                await setData(response.data)
                await setMinDate(response.data.min_date)
            }
            await setIsLoading(false);
        }

        fetchData();
    }, [setting.from_date, setting.to_date, setting.time_type, setting.location, setting.duration_type])

    return (
        <div className='card-paper-1 h-100 w-100'>
            <Typography variant="h6" className="text-start mb-2">
                Entity total revenue
            </Typography>

            <Grid container spacing={2} className="mb-3 mt-2">

                <Grid item xs={12}>
                    <CustomRadioGroup
                        label="duration_type"
                        name="duration_type"
                        value={setting.duration_type}
                        onChangeHandle={handleSettingChange}
                        options={duration_type_options}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <DateField
                        date_view={setting.duration_type}
                        name="from_date"
                        label="from_date"
                        maxDate={moment()}
                        min_date={min_date}
                        value={setting.from_date}
                        onChangeHandle={handleDateChange}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <DateField
                        date_view={setting.duration_type}
                        maxDate={moment()}
                        min_date={setting.from_date}
                        name="to_date"
                        label="to_date"
                        value={setting.to_date}
                        onChangeHandle={handleDateChange}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <LocationsAutoComplete
                        allLocationOption
                        name='location'
                        label="location"
                        onChangeHandle={handleSettingChange}
                        value={setting.location}
                    />
                </Grid>


            </Grid>

            <Typography component="p" variant="h5" className="text-end mb-2">
                {`Total: ${localStorage.getItem('currency_symbol')} ${data.total}`}
            </Typography>

            <Hide hide={!is_loading}>
                <div className="fixedHeight-400">
                    <Loading/>
                </div>
            </Hide>

            <Hide hide={is_loading}>
                <div dir='ltr' className="h-100 w-100">
                    <ResponsiveContainer width="100%" height='90%' className="fixedHeight-400">

                        <BarChart
                            data={data.details}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <Tooltip/>

                            <Bar dataKey="amount" barSize={30} fill="#3f51b5"/>

                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Hide>

        </div>
    );
}
