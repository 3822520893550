import Toolbar from "@material-ui/core/Toolbar";
import {SearchField} from "./fields/SearchField";
import React from "react";
import {CustomBreadcrumbs} from "./CustomBreadcrumbs";
import {Hide} from "./Hide";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {getLabel} from "../localization/MainLocalization";
import {CustomButton} from "./CustomButton";

export const TableToolbar = props => {
    return (
        <>

            {/*<Hide hide={props.match === undefined}>*/}
            {/*<Toolbar variant="regular"  style={{paddingInlineStart:'2rem', paddingTop:'0.7rem'}}>*/}
            {/*<CustomBreadcrumbs match={props.match} details={props.breadcrumDetails || {}}/>*/}
            {/*</Toolbar>*/}
            {/*</Hide>*/}


            <Toolbar variant="regular" className="pt-3">

                {/*<Typography variant="h6">{props.title}</Typography>*/}

                <SearchField
                    name={props.searchFieldName}
                    value={props.searchValue}
                    onChangeHandle={props.onSearchChangeHandle}
                    action={props.searchAction}
                />

                <div className="mr-auto"/>

                {/*<div className="text-end margin-left-2">*/}
                {/*        {props.actions}*/}
                {/*    </div>*/}

                <div className="text-end margin-left-3">
                    {props.actions && props.actions.map((Actions, index)=>(
                        <Hide hide={Actions.hide} key={index} >
                            <CustomButton size="small" variant="contained" className='m-1' color='primary'
                                style={{minWidth: "130px"}}
                                    startIcon={<AddIcon/>}
                                    onClick={Actions.onClick}>
                                {getLabel(Actions.label)}
                            </CustomButton>
                        </Hide>
                    ))}

                </div>
            </Toolbar>
        </>
    );
};
