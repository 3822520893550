import React, {useContext, useEffect, useRef, useState} from "react";
import MaterialTable from "material-table";
import Permissions from "../../../auth/permissions";
import "../../../assets/scss/Static.scss";
import {instance} from "../../../auth/axios_instance";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {getLabel} from "../../../localization/MainLocalization";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Hide} from "../../../components/Hide";
import Tab from "@material-ui/core/Tab";
import {permissions_data} from "../../../auth/permission_data";
import {BaseFinanceCashTransferRecipientURL, BaseFinanceCashTransferSenderURL} from "../../../urls";
import {CashTransferFormModal} from "./components/CashTransferFormModal";
import {CancelCashTransfer, UpdatelCashTransferStatusAsRecipient} from "./cash_transfer_apis";
import {AlertContext, CashTransferContext} from "../../../store/use_context";
import {CustomTabs} from "../../../components/CustomTabs";
import {not_validate_value} from "../../../App";
import Badge from "@material-ui/core/Badge";
import Hidden from "@material-ui/core/Hidden";

export const CashTransfer = props =>{
    const state = props.history.location.state || {};
    const {pending_received_cash_transfer, setCheckPendingReceivedCashTransfer} = useContext(CashTransferContext);

    const tableRef = useRef();

           const  columns = as_sender => ([
                {
                    title: getLabel("transfer_number"),
                    render: rowData => <label># {rowData.transfer_number}</label>
                },
                as_sender ? {title: getLabel("recipient"), field: 'recipient_name'} :
                    {title: getLabel("sender"), field: 'sender_name'},
                {title: getLabel("amount"), field: 'amount'},
                {title: getLabel("status"), field: 'status'},
                {title: getLabel("date_created"), field: 'date_created'},
                as_sender ?{render:rowData=>{return(
                    <Hide hide={rowData.status !== "Pending"}>
                            <Button color="primary" size="small"
                                    variant="outlined"
                                    onClick={()=> handleCancelTransfer(rowData.id)}>
                                {getLabel("cancel_transfer")}
                            </Button>
                    </Hide>
                    )}}:
                    {render:rowData=>{return(
                            <Hide hide={rowData.status !== "Pending"}>
                                <div style={{display: "inline-flex"}}>
                                <Button color="primary" size="small"
                                        className="ml-1 mr-1"
                                        variant="outlined"
                                        onClick={()=> handleTransferStatusChange(rowData.id, "Confirm")}>
                                    {getLabel("confirm")}
                                </Button>

                                    <Button color="primary" size="small"
                                            className="ml-1 mr-1"
                                            variant="outlined"
                                            onClick={()=> handleTransferStatusChange(rowData.id, "Rejected")}>
                                        {getLabel("reject")}
                                    </Button>
                                </div>
                            </Hide>
                        )}},
            ]);

    const [search, setSearch] = useState("")
    const [current_tab, setCurrentTab] = useState(0)
    const [open_modal, setOpenModal] = useState(false)
    const [modal_type, setModalType] = useState("")
    const [filter, setFilter] = useState({})
    const {setAlert} = useContext(AlertContext)

    useEffect(()=>{
        if (state.pending_transfer){
            setFilter({status: "Pending"});
            setCurrentTab(1);
            reloadData();
        }
    },[props.history.location.state]);

   const handleTabsChange = (event, newValue) => {
       setCurrentTab(newValue)
       setFilter({});
    };

    const reloadData = async () => {
        setCheckPendingReceivedCashTransfer(Math.random()*1000);

        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleCancelTransfer = async (id) => {
        let response = await CancelCashTransfer(id);
        if (response.status){
        await reloadData();
        }else{
            setAlert(response.error_feedback.non_field_errors);
        }
    };


    const handleTransferStatusChange = async (id, status) => {
        let response = await UpdatelCashTransferStatusAsRecipient(id, status);
        if (response.status){
            await reloadData();
        }else{
            setAlert(response.error_feedback.non_field_errors);
        }
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length >= 5  || newValue === '') {
            reloadData();
        }
    };

    const handleShowModal = async (value, action_type="") => {
        if (!value){
            await setCurrentTab(0)
           await reloadData();
        }
        await setModalType(action_type)
        await setOpenModal(value)
    }


        return (
            <Paper>

                <Hide hide={!open_modal}>
                    <CashTransferFormModal
                        open={open_modal}
                        type={modal_type}
                        handleModalClose={() => handleShowModal(false)}
                    />
                </Hide>

                {/*<Toolbar variant="regular" style={{paddingInlineStart: '2rem', paddingTop: '0.7rem'}}>*/}
                {/*    <CustomBreadcrumbs match={props.match}/>*/}
                {/*</Toolbar>*/}

                <Grid item xs={12} className="Paper">
                    <CustomTabs
                        currentTab={current_tab}
                        handleTabsChange={handleTabsChange}
                    >
                        <Tab label={getLabel("sent_transfer")} id={0}/>
                        <Tab label={
                            <Badge badgeContent={pending_received_cash_transfer} color="error">
                                <div className='pr-2 pl-2'>
                                {getLabel("received_transfer")}
                                </div>

                            </Badge>
                        } id={1}/>
                    </CustomTabs>
                </Grid>

                <Grid container spacing={3}>

                    <Grid item xs={12}>

                        <TableToolbar
                            searchFieldName='search'
                            searchValue={search}
                            onSearchChangeHandle={handleSearchField}
                            searchAction={() => reloadData()}
                            actions={
                                [
                                    {
                                        hide: !Permissions.checkAllPermissions([permissions_data.cash_transfer_request.add]),
                                        onClick: () => handleShowModal(true, "transfer"),
                                        label: "add_transfer"
                                    },
                                    {
                                        hide: !Permissions.checkAllPermissions([permissions_data.cash_transfer_request.collect]),
                                        onClick: () => handleShowModal(true, 'collect'),
                                        label: "collect_cash"
                                    },
                                ]
                            }
                        />

                        <Hide hide={current_tab !== 0}>

                            <MaterialTable
                                tableRef={tableRef}
                                columns={columns(true)}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        let url = BaseFinanceCashTransferSenderURL;
                                        url += '?page=' + (query.page + 1);

                                        if (search !== '') {
                                            url += '&search=' + search;
                                        }

                                        instance.get(url)
                                            .then(result => {
                                                resolve({
                                                    data: result.data.results,
                                                    page: result.data.page - 1,
                                                    totalCount: result.data.total,
                                                })
                                            })
                                    })
                                }


                                {...material_table_style}
                                {...material_table_options()}
                                {...material_table_localization()}

                            />
                        </Hide>


                        <Hide hide={current_tab !== 1}>
                            <MaterialTable
                                tableRef={tableRef}
                                columns={columns(false)}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        let url = BaseFinanceCashTransferRecipientURL;
                                        url += '?page=' + (query.page + 1);

                                        if (search !== '') {
                                            url += '&search=' + search;
                                        }

                                        if (!not_validate_value.includes(filter.status)) {
                                            url += '&status=' + filter.status;
                                        }

                                        instance.get(url)
                                            .then(result => {
                                                resolve({
                                                    data: result.data.results,
                                                    page: result.data.page - 1,
                                                    totalCount: result.data.total,
                                                })
                                            })
                                    })
                                }


                                {...material_table_style}
                                {...material_table_options()}
                                {...material_table_localization()}

                            />
                        </Hide>
                    </Grid>
                </Grid>
            </Paper>
        );

}

