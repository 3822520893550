import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Hide} from "../../../components/Hide";
import {getLabel} from "../../../localization/MainLocalization";
import {EntityInfo} from "./EntityInfo";
import {EntitySetting} from "./EntitySetting";
import {PaymentMethod} from "./payment_method/PaymentMethod";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {CustomTabs} from "../../../components/CustomTabs";


export const Entity = props => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };


    return (
        <Paper className='Paper'>
            {/*<CustomBreadcrumbs match={props.match}/>*/}

            <CustomTabs
                currentTab={currentTab}
                handleTabsChange={handleTabsChange}
            >
                <Tab label={getLabel("details")} id={0}/>

                {Permissions.checkPermission(permissions_data.entity_setting.view) && (
                <Tab label={getLabel("setting")} id={1} disabled={!Permissions.checkPermission(permissions_data.entity_setting.view)}/>
                )}

                {Permissions.checkPermission(permissions_data.payment_method.view) && (
                  <Tab label={getLabel("payment_method")} id={2} disabled={!Permissions.checkPermission(permissions_data.payment_method.view)}/>
                )}

            </CustomTabs>

            <Grid container className='pt-4 overflow-hidden'  spacing={2}>
                <Hide hide={currentTab !== 0}>
                    <EntityInfo {...props}/>
                </Hide>

                <Hide hide={currentTab !== 1}>
                    <EntitySetting {...props}/>
                </Hide>

                <Hide hide={currentTab !== 2}>
                    <PaymentMethod {...props}/>
                </Hide>


            </Grid>
        </Paper>
    );
}
