import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import {PaymentMethods} from "../../../../components/PaymentMethods";
import moment from "moment";


export const RefundModal = props => {
    const [data, setData] = useState({
        add_refund: true,
        payment_type: "Refund",
        payment_date: moment().format("YYYY-MM-DD"),
    });
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(false);


    const handleFieldChange = async (name, newValue) => {
        await setData({...data, [name]: newValue});
    }

    const handleSaveButton = async () => {
        await setIsLoading(true);
        let response = await props.saveAction(data)

        if (response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };


    return (

        <CustomModal
            open={props.open}
        >
            <Grid container spacing={2}>

                <FormHeader
                    title="add_refund"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>

                        <Grid item xs={12}>
                            <PaymentMethods
                                assigned_to="memberships"
                                required
                                new_payment={data}
                                helper_text={helper_text}
                                handleNewPaymentFieldChange={handleFieldChange}
                            />
                        </Grid>
                    </Hide>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveButton}
                    />
            </Grid>
        </CustomModal>
    );
}