import React, {useContext, useEffect, useRef, useState} from 'react';
import MaterialTable from 'material-table';
import moment from "moment";
import Permissions from "../../../../auth/permissions";
import {PriceListOfferFormModal} from "./components/PriceListOfferFormModal";
import {BasePriceListOfferURL} from "../../../../urls";
import {TableToolbar} from "../../../../components/TableToolbar";
import Paper from "@material-ui/core/Paper";
import "../../../../assets/scss/Static.scss";
import {sub_directory, not_validate_value} from "../../../../App";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../../components/Material_conf";
import {getLabel} from "../../../../localization/MainLocalization";
import {Hide} from "../../../../components/Hide";
import {RetrievePriceList} from "../price_list/price_list_apis";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import {httpRequestWithAuth, instance} from "../../../../auth/axios_instance";
import {permissions_data} from "../../../../auth/permission_data";
import {AlertContext} from "../../../../store/use_context";


export const OffersPriceList = props => {
    let is_rent = false;

    const tableRef = useRef();

    const state = props.history.location.state || {};

    useEffect(()=>{
        if (!(state.service && state.pricelist)){
            props.history.push(`${sub_directory}services`);
        }
    },[]);

    const [columns, setColumns] = useState([
        {title: getLabel("name"), field: 'name'},
        {title: getLabel("start_date"), field: 'start_date'},
        {title: getLabel("end_date"), field: 'end_date'},
        {title: getLabel('assigned_to'), field: 'for_who'},
        {
            title: getLabel('status'),
            render: rowData => {
                return (<Chip color={rowData.is_active ? "primary" : "default"} label={rowData.is_active_view}/>)
            },
        },
        {
            render: rowData => {
                return (
                    <div className="d-inline-flex">
                        {Permissions.checkAllPermissions([permissions_data.offer_price.view]) && (
                            <Button className="mr-1 ml-1" size="small"
                                    style={{width: "125px", fontSize: "11px"}}
                                    variant="outlined"
                                    onClick={() => props.history.push(
                                        {
                                            pathname: sub_directory + "services/price-lists/offers/prices",
                                            state: {
                                                ...state,
                                                offer: rowData.id,
                                                offer_name: rowData.name
                                            }
                                        }
                                    )}
                            >
                                {getLabel("pages.packages.package_offer.view_offer_prices")}
                            </Button>)}

                        {Permissions.checkAllPermissions([permissions_data.offer_price_list.change]) && (
                            <Button className="mr-1 ml-1"
                                    style={{width: "125px", fontSize: "12px"}}
                                    size="small" variant="outlined"
                                    onClick={() => handleActiveBtn(rowData.id, !rowData.is_active)}
                            >
                                {getLabel(rowData.is_active ? "pages.price_list.offers.deactivate_offer" : "pages.price_list.offers.activate_offer")}
                            </Button>)}
                    </div>
                )
            }
        }
    ]);
    const [disable_gender, setDisableGender] = useState(false)
    const [form_open, setFormOpen] = useState(false);
    const [new_price_list, setNewPriceList] = useState({});
    const [search, setSearch] = useState("");
    const {setAlert} = useContext(AlertContext);

    useEffect(() => {
        const fetchData = async () => {
            let response = await RetrievePriceList(state.pricelist);
            if (response.status) {
                is_rent = response.data.is_rent

                let disable_gender_value = !(response.data.for_males && response.data.for_females)
                await setDisableGender(disable_gender_value);

                if (disable_gender_value){
                    await setNewPriceList({for_males: response.data.for_males, for_females: response.data.for_females});
                }
            }
        }

        fetchData();
    }, [])

    const reloadData = async () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue);

        if (newValue.length >= 5 || newValue === '') {
            reloadData();
        }
    };

    const handleModalClose = async () => {
        await reloadData();
        await setFormOpen(false)
        await setNewPriceList(
            disable_gender ? {
                for_males: new_price_list.for_males,
                for_females: new_price_list.for_females
            } : {}
        )
    };

    const handleModalOpen = async data => {
        if (data !== "new") {
            await setNewPriceList(data)
        }
        await setFormOpen(true)
    };



       const handleActiveBtn = async (id, value) => {
        let price_list_offer = {
            is_active: value,
        };

        let response;

        response = await httpRequestWithAuth(`${BasePriceListOfferURL}${id}/`,"PATCH", price_list_offer);
        if (response.status) {
            await setAlert("Update offer price list successfully")
            await reloadData();
        } else {
            await setAlert(response.error_feedback.non_field_errors)
        }
    };

    return (
        <>

            <Hide hide={!form_open}>
                <PriceListOfferFormModal
                    disable_gender={disable_gender}
                    open={form_open}
                    handleModalClose={handleModalClose}
                    default_data={{...new_price_list, price_list: state.pricelist}}
                    is_rent={is_rent}
                />
            </Hide>


            <Paper>
                <TableToolbar
                    // title={<CustomBreadcrumbs service={service} price_list={orginal_price_list} is_offer={true}/>}
                    searchFieldName='search'
                    searchValue={search}
                    onSearchChangeHandle={handleSearchField}
                    searchAction={reloadData}
                    match={props.match}
                    breadcrumDetails={state}
                    backbtn={true}
                    actions={[
                        {
                            hide: !Permissions.checkAllPermissions([permissions_data.offer_price_list.add]),
                            onClick: () => handleModalOpen('new'),
                            label: "pages.packages.package_offer.add_offer"
                        },
                    ]}
                />


                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let url = BasePriceListOfferURL;
                            url += '?page=' + (query.page + 1);

                            url += '&price_list=' + state.pricelist;

                            if (search !== '') {
                                url += '&search=' + search;

                            }

                            instance.get(url)
                                .then(result => {
                                    resolve({
                                        data: result.data.results,
                                        page: result.data.page - 1,
                                        totalCount: result.data.total,
                                    })
                                })
                        })
                    }
                    editable={
                        Permissions.checkPermission(permissions_data.offer_price_list.delete) && {
                            onRowDelete: async rowData => {
                                await httpRequestWithAuth(`${BasePriceListOfferURL}${rowData.id}/`, "DELETE");
                                await reloadData();
                            }
                        }}


                    actions={[
                        // Permissions.checkAllPermissions([permissions_data.offer_price_list.change]) &&
                        // ((rowData) => {
                        //     return (
                        //         {
                        //             icon: () => rowData.is_active ?
                        //                 <RemoveCircleOutlineIcon /> :
                        //                 <CheckCircleOutlineIcon />,
                        //             tooltip: get_label(rowData.is_active ? "pages.price_list.offers.deactivate_offer" : "pages.price_list.offers.activate_offer"),
                        //             onClick: (event, rowData) => handleActiveBtn(rowData.id, !rowData.is_active)
                        //         }
                        //     )
                        // }),
                        //
                        // Permissions.checkAllPermissions([permissions_data.offer_price.view]) && {
                        //     icon: LineWeightIcon,
                        //     tooltip: get_label('pages.packages.package_offer.view_offer_prices'),
                        //     onClick: (event, rowData) => props.history.push(default_url + "services/" + service + "/price-lists/" + orginal_price_list + "/offers/" + rowData.id + "/prices")
                        // },
                        Permissions.checkAllPermissions([permissions_data.offer_price_list.change]) && {
                            icon: 'edit',
                            tooltip: getLabel("pages.packages.package_offer.edit_offer"),
                            onClick: (event, rowData) => handleModalOpen(rowData)
                        },

                    ]}
                    {...material_table_style}
                    {...material_table_options()}
                    {...material_table_localization()}
                />
            </Paper>
        </>
    );
}

