import React from "react";
import {Grid} from "@material-ui/core";
import {Hide} from "../../../components/Hide";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {ClientsAgeScale} from "./components/ClientsAgeScale";
import {ServiceStatus} from "./components/ServiceStatus";
import {LocationsCashLog} from "./components/LocationsCashLog";
import {StaffCashLog} from "./components/StaffCashLog";
import {LocationsTotalRevenue} from "./components/LocationsTotalRevenue";
import {EntityTotalRevenue} from "./components/EntityTotalRevenue";
import {MembershipsStatus} from "./components/MembershipsStatus";
import {GroupClassesNumber} from "./components/GroupClassesNumber";
import {AthleteAgeScale} from "./components/AthleteAgeScale";
import {OldAndNewAthlete} from "./components/OldAndNewAthlete";

export const Reports = props =>{
    return(
        <Grid container spacing={3}>

            <Hide hide={!Permissions.checkAllPermissions([permissions_data.athlete.view])}>
                <Grid item xs={12} className="h-auto">
                    <GroupClassesNumber {...props}/>
                </Grid>
            </Hide>


            <Hide hide={!Permissions.checkAllPermissions([permissions_data.athlete.view])}>
                <Grid item xs={12} md={6} className="h-auto">
                    <OldAndNewAthlete/>
                </Grid>
            </Hide>



            <Hide hide={!Permissions.checkAllPermissions([permissions_data.athlete.view])}>
                <Grid item xs={12} md={6} className="h-auto">
                    <AthleteAgeScale/>
                </Grid>
            </Hide>


            <Hide hide={!Permissions.checkAllPermissions([permissions_data.membership.view])}>
                <Grid item xs={12} md={6} className="h-auto">
                    <LocationsTotalRevenue {...props}/>
                </Grid>
            </Hide>


            <Hide hide={!Permissions.checkAllPermissions([permissions_data.membership.view])}>
                <Grid item xs={12} md={6} className="h-auto">
                    <ServiceStatus {...props}/>
                </Grid>
            </Hide>


            <Hide hide={!Permissions.checkAllPermissions([permissions_data.membership.view])}>
                <Grid item xs={12} md={5} className="h-auto">
                    <LocationsCashLog {...props}/>
                </Grid>
            </Hide>


            <Hide hide={!Permissions.checkAllPermissions([permissions_data.client.view])}>
                <Grid item xs={12} md={6} className="h-auto">
                    <ClientsAgeScale/>
                </Grid>
            </Hide>



            <Hide hide={!Permissions.checkAllPermissions([permissions_data.membership.view])}>
                <Grid item xs={12} className="h-auto">
                    <MembershipsStatus {...props}/>
                </Grid>
            </Hide>

            <Hide hide={!Permissions.checkAllPermissions([permissions_data.membership.view])}>
                <Grid item xs={12} className="h-auto">
                    <EntityTotalRevenue {...props}/>
                </Grid>
            </Hide>

        </Grid>
    )
}
