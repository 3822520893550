import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {getLabel} from "../../../../localization/MainLocalization";
import MaterialTable from "material-table";
import {BaseFinanceLocationsCashLogURL, BaseFinanceStaffCashLogURL, BaseMembershipLatestURL} from "../../../../urls";
import moment from "moment";
import {DateField} from "../../../../components/fields/DateField";


export const StaffCashLog = props => {
    const [data, setData] = useState( []);
    const [is_loading, setIsLoading] = useState( true);
    const columns =[
            {title: getLabel("name"), field: 'staff_name'},
            {title: getLabel("amount"), field: 'amount'},
            {title: getLabel("available_amount"), field: 'available_amount'},
            {title: getLabel("pending_amount"), field: 'pending_amount'},
        ];


    useEffect(()=>{
        const fetchData = async () =>{
            await setIsLoading(true);
            let response = await httpRequestWithAuth(BaseFinanceStaffCashLogURL, "GET", null);
            if (response.status){
                await setData(response.data)
            }
            await setIsLoading(false);
        }

        fetchData();
    },[])

        return (
            <div className='card-paper-1 h-100 w-100'>
                <Typography variant="h6" className="text-start mb-2">
                    Staff Cash
                </Typography>

                <MaterialTable
                    columns={columns}
                    data={data}
                    isLoading={is_loading}
                    style={{
                        boxShadow: "none",overflowY:"auto",
                        overflowX:'auto',
                        maxHeight: "500px"
                    }}

                    options={{
                        search: false,
                        paging: false,
                        toolbar: false,
                    }}
                />
            </div>
        );
}
