import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "../../assets/scss/Static.scss";
import {getLabel} from "../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import {BaseServiceListAllURL} from "../../urls";
import {not_validate_value} from "../../App";

export const ServiceAutoComplete = props => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            let params = {}
            if (!not_validate_value.includes(props.byLocation) && props.byLocation){
                params['location'] = props.location
            }

            if (!not_validate_value.includes(props.active_on_locations)){
                params['active_on_locations'] = props.active_on_locations
            }

            if (!not_validate_value.includes(props.rental)){
                params['is_rental_service'] = props.rental
            }

            let response = await httpRequestWithAuth(BaseServiceListAllURL, "GET", null, params);
            if (response.status) {
                let services = response.data

                if (props.allServiceOption) {
                    services.push({
                        id: "",
                        name: getLabel("all_services"),
                    })
                }
                await setOptions(services);
            }
        }

        fetchData();
    }, [props.location])

    const handleOnChange = (event, value) => {
        if (!props.viewOnly) {
            value = value !== null ? props.fullObject ? value : value.id : "";
            props.onChangeHandle(props.name, value)
        }
    };

    const handleAutoCompleteValue = () => {
        let value = null;

        options.map(Data => {
            if (Data.id === props.value) {
                value = Data;
            }
            return 0;
        });

        return value;
    };


    return (
        <Autocomplete
            ListboxProps={{className:"list-box-autocomplete"}}
            renderOption={(option) => (
                <span className="w-100 text-start">{option.name}</span>
            )}
            disabled={props.disabled || props.viewOnly}
            options={options}
            getOptionLabel={option => option.name}
            renderInput={params => (
                <TextField variant="outlined" {...params}
                           label={<label>{getLabel(props.label)}<span
                               className='text-danger'>{props.required ? "*" : ""}</span></label>}
                           helperText={props.helper_text}
                           error={props.error}
                           fullWidth
                           disabled={props.disabled}
                           className={props.disabled ? "disabled-field" : ""}
                />
            )}
            value={handleAutoCompleteValue()}
            onChange={handleOnChange}
        />
    );
}
