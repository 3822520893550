import React, {useRef, useState} from "react";
import {getLabel} from "../../../../localization/MainLocalization";
import MaterialTable from 'material-table';
import {BaseServiceURL, BaseUpdateLocationServiceForServiceURL} from "../../../../urls";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../../components/Material_conf";
import Chip from "@material-ui/core/Chip";
import Permissions from '../../../../auth/permissions';
import Button from "@material-ui/core/Button";
import {sub_directory} from "../../../../App";
import {ServiceFormModal} from "./components/ServiceFormModal";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../../components/TableToolbar";
import Typography from "@material-ui/core/Typography";
import {ViewLocations} from "../components/ViewLocations";
import {Hide} from "../../../../components/Hide";
import {httpRequestWithAuth, instance} from "../../../../auth/axios_instance";
import {permissions_data} from "../../../../auth/permission_data";


export const Service = props => {

    const tableRef = useRef();

    const [columns, setCoumns] = useState([
        {
            title: getLabel('name'),
            render: rowData => {
                return (
                    <div className="d-inline-flex">
                         <Typography variant="subtitle1" className={"margin-right-2"}>{rowData.name}</Typography>
                         <Hide hide={!rowData.is_rental_service}>
                             <Chip label="Rent" size="small" color="primary" disabled variant="outlined"/>
                         </Hide>
                     </div>
                 )
             }
             , cellStyle: {width: "40%"}
         },
         {title: getLabel('assigned_to'), field: 'for_who'},
         {
             title: getLabel('available_at'), render: rowData => {
                 return (
                     <div className="d-inline-flex">
                         <Button color="primary" size="small"
                                 onClick={() => viewLocationsModel(rowData)}>
                             {rowData.locations.length} Location(s)
                         </Button>
                     </div>
                 )
             }
         },
         {
             title: getLabel('status'),
             render: rowData => {
                 return (
                     <Chip color={rowData.is_active ? "primary" : "default"} label={rowData.is_active_view}/>)
            },
        },
        {
            render: rowData => {
                return (
                    <div className="d-inline-flex">
                         {Permissions.checkAllPermissions([permissions_data.price_list.view]) && (
                            <Button className="mr-1 ml-1" style={{width: "125px"}} size="small"
                                    variant="outlined"
                                    onClick={() => props.history.push(
                                        {
                                            pathname: sub_directory + 'services/price-lists',
                                            state: {
                                                service: rowData.id,
                                                service_name: rowData.name
                                            }
                                        }
                                    )}>
                                 {getLabel("pages.services.view_price_list")}
                             </Button>)}
                     </div>
                 )
            }
        }
    ]);

    const [form_open, setFormOpen] = useState(false);
    const [location_form_open, setLocationFormOpen] = useState(false);
    const [locations_data, setLocationsData] = useState([]);
    const [request_url, setRequestUrl] = useState("");
    const [new_service, setNewService] = useState({});
    const [search, setSearch] = useState("");

    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const viewLocationsModel = async data => {
        await setRequestUrl(BaseUpdateLocationServiceForServiceURL(data.id))
        await setLocationsData(data.locations)
        await setLocationFormOpen(true)
    };


    const handleModalOpen = async data => {
        if (data !== 'new') {
            await setNewService(data)
        }
        await setFormOpen(true)
    };

    const handleModalClose = async () => {
        await reloadData();
        await setFormOpen(false)
        await setNewService({})
        await setLocationsData({})
        await setLocationFormOpen(false)
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length >= 5 || newValue.length === 0) {
            reloadData()
        }
    }


    return (
        <Paper>

            <Hide hide={!location_form_open}>
                <ViewLocations
                    request_url={request_url}
                    active_locations={locations_data}
                    open={location_form_open}
                    handleModalClose={handleModalClose}
                />
            </Hide>

            <Hide hide={!form_open}>
                <ServiceFormModal
                    default_data={new_service}
                    open={form_open}
                    handleModalClose={handleModalClose}
                />
            </Hide>

            <TableToolbar
                searchFieldName='search'
                searchValue={search}
                onSearchChangeHandle={handleSearchField}
                searchAction={reloadData}
                match={props.match}
                backbtn={true}
                actions={
                    [
                        {
                            hide: !Permissions.checkAllPermissions([permissions_data.service.add]),
                            onClick: () => handleModalOpen('new'),
                            label: "pages.services.add_service"
                        },
                    ]
                }
            />

            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={query =>
                    new Promise((resolve, reject) => {
                        let url = BaseServiceURL;
                        url += '?page=' + (query.page + 1);

                        if (search !== '') {
                            url += '&search=' + search;
                        }

                        instance.get(url)
                            .then(result => {
                                resolve({
                                    data: result.data.results,
                                    page: result.data.page - 1,
                                    totalCount: result.data.total,
                                })
                            })
                    })
                }

                editable={
                    Permissions.checkPermission(permissions_data.service.delete) && {
                        onRowDelete: async ServiceData => {
                            await httpRequestWithAuth(`${BaseServiceURL}${ServiceData.id}/`, "DELETE");
                            await reloadData();
                        }
                    }}

                actions={[
                    {
                        icon: 'edit',
                        hidden: !Permissions.checkAllPermissions([permissions_data.service.change]),
                        tooltip: getLabel('pages.services.edit_service'),
                        onClick: (event, rowData) => handleModalOpen(rowData)
                    }
                ]}

                {...material_table_style}
                {...material_table_options()}
                {...material_table_localization()}
            />
        </Paper>

    );

}
