import React, {useEffect, useRef, useState} from 'react';
import MaterialTable from 'material-table';
import Permissions from "../../../../auth/permissions";
import {BasePriceOfferURL} from "../../../../urls";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../../components/TableToolbar";
import "../../../../assets/scss/Static.scss";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../../components/Material_conf";
import {getLabel} from "../../../../localization/MainLocalization";
import {OfferPriceForm} from "./components/OfferPriceForm";
import Chip from "@material-ui/core/Chip";
import {Hide} from "../../../../components/Hide";
import {httpRequestWithAuth, instance} from "../../../../auth/axios_instance";
import {RetrievePriceList} from "../price_list/price_list_apis";
import {permissions_data} from "../../../../auth/permission_data";
import {sub_directory} from "../../../../App";


export const PricesOffers = props => {
    let is_rent = false;

    const state = props.history.location.state || {};

    useEffect(() => {
        if (!(state.service && state.pricelist && state.offer)) {
            props.history.push(`${sub_directory}services`);
        }
    }, [])


    const tableRef = useRef();

    const [columns, setColumns] = useState([
        {title: getLabel("name"), field: 'name'},
        {title: getLabel("price"), field: 'price'},
        {
            title: getLabel("duration"), field: 'duration',
            render: rowData => <label>{rowData.duration} <label className="mr-1 ml-1">Days</label></label>
        },
        {title: getLabel("sessions"), field: 'sessions'},
        {title: getLabel("pages.prices.freeze_days"), field: 'freeze_days'},
        {
            title: getLabel('status'),
            render: rowData => {
                return (<Chip color={rowData.is_active ? "primary" : "default"} label={rowData.is_active_view}/>)
            },
        },

        // {title: get_label("pages.packages.package_name"), field: 'package'},
    ]);
    const [form_open, setFormOpen] = useState(false)
    const [new_price, setNewPrice] = useState({})
    const [search, setSearch] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            let response = await RetrievePriceList(state.pricelist);
            if (response.status) {
                is_rent = response.data.is_rent
            }
        }

        fetchData();
    }, []);

    const reloadData = async () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };


    const handleModalClose = async () => {
        await reloadData();
        await setFormOpen(false)
        await setNewPrice({})
    };


    const handleModalOpen = async data => {
        if (data !== "new") {
            await setNewPrice(data)
        }
        await setFormOpen(true)
    };


    const handleSearchField = (name, newValue) => {
        setSearch(newValue)

        if (newValue.length >= 5 || newValue === '') {
            reloadData();
        }
    };


    return (
        <>
            <Hide hide={!form_open}>
                <OfferPriceForm
                    open={form_open}
                    package_offer={state.offer}
                    original_package={state.pricelist}
                    handleModalClose={handleModalClose}
                    value={new_price}
                    is_rent={is_rent}
                />
            </Hide>


            <Paper>
                <TableToolbar
                    searchFieldName='search'
                    searchValue={search}
                    onSearchChangeHandle={handleSearchField}
                    searchAction={reloadData}
                    match={props.match}
                    breadcrumDetails={state}
                    backbtn={true}
                    actions={
                        [
                            {
                                hide: !Permissions.checkAllPermissions([permissions_data.offer_price.add]),
                                onClick: () => handleModalOpen('new'),
                                label: "pages.packages.package_offer.new_offer_prices"
                            },
                        ]
                    }
                />

                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let url = BasePriceOfferURL;
                            url += '?page=' + (query.page + 1);

                            url += '&price_list=' + state.offer;

                            if (search !== '') {
                                url += '&search=' + search;
                            }

                            instance.get(url)
                                .then(result => {
                                    resolve({
                                        data: result.data.results,
                                        page: result.data.page - 1,
                                        totalCount: result.data.total,
                                    })
                                })
                        })
                    }
                    editable={
                        Permissions.checkAllPermissions([permissions_data.offer_price.delete]) && {
                            onRowDelete: async PriceData => {
                                await httpRequestWithAuth(`${BasePriceOfferURL}${PriceData.id}/`, "DELETE");
                                await reloadData();
                            }
                        }}


                    actions={[
                        {
                            icon: 'edit',
                            hidden: !Permissions.checkAllPermissions([permissions_data.offer_price.change]),
                            tooltip: 'Edit Offer',
                            onClick: (event, rowData) => handleModalOpen(rowData)
                        },


                    ]}
                    {...material_table_style}
                    {...material_table_options()}
                    {...material_table_localization()}
                />
            </Paper>
        </>
    );
}

