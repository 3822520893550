import React, {useEffect, useState} from "react";
import MaterialTable from "material-table";
import {
    material_table_localization,
} from "../../../../components/Material_conf";
import Chip from "@material-ui/core/Chip";
import Permissions from '../../../../auth/permissions';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../../localization/MainLocalization";
import {CreatePaymentMethod, DeletePaymentMethod, ListPaymentMethod, UpdatePaymentMethod} from "./payment_method_apis";
import {Hide} from "../../../../components/Hide";
import {PaymentMethodFormModal} from "./components/PaymentMethodFormModal";
import {permissions_data} from "../../../../auth/permission_data";
import Grid from "@material-ui/core/Grid";


export const PaymentMethod = props =>{
            const [columns, setColumns] = useState([
                {title: getLabel('name'), field:'name'},
                {title: getLabel('commission_type'), field:'commission_type'},
                {title: getLabel('commission'), field:'commission'},
                {title: getLabel('status'),
                    render:rowData=>{return(<Chip color={rowData.is_active?"primary":"default"} label={rowData.is_active_view}/>)},
                },
                {title: getLabel('created_by'), field:'created_by_name'},
            ]);

    const [data, setData] = useState([])
    const [form_open, setFormOpen] = useState(false)
    const [new_payment_method, setNewPaymentMethod] = useState({is_active:true})
    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(true)

    const listPaymentMethodData = async () => {
        await setIsLoading(true);

        let response = await ListPaymentMethod();
        if (response.status) {
            await setData(response.data)

        }

        await setIsLoading(false);
    };

    useEffect(()=>{
        listPaymentMethodData();
    },[])

    const handleModalOpen = async data => {
        if (data !== 'new') {
                await setNewPaymentMethod({...data});
        }
       await setFormOpen(true)
    };

    const handleModalClose = async () => {
        await listPaymentMethodData();
        await setHelperText({})
        await setNewPaymentMethod({is_active:true});
        await setFormOpen(false)

    };

    const handleSaveForm = async () => {
        let response;
        if (new_payment_method.id === undefined) {
            response = await CreatePaymentMethod(new_payment_method);
        } else {
            response = await UpdatePaymentMethod(new_payment_method.id, new_payment_method);
        }

        if (response.status) {
            await handleModalClose();
        } else {
            setHelperText(response.error_feedback);
        }
    };

    const handleFieldChange = (name, newValue) => {
        setNewPaymentMethod({...new_payment_method, [name]: newValue});
    };



        return(
            <>

                <Hide hide={!form_open}>
                    <PaymentMethodFormModal
                        helper_text={helper_text}
                        data={new_payment_method}
                        open={form_open}
                        handleModalClose={handleModalClose}
                        onChangeHandle={handleFieldChange}
                        handleSaveForm={handleSaveForm}
                    />
                </Hide>

                {/*<Toolbar variant="regular"  style={{paddingInlineStart:'2rem', paddingTop:'0.7rem'}}>*/}
            {/*<CustomBreadcrumbs match={props.match}/>*/}
            {/*    </Toolbar>*/}

        <Grid item xs={12}>
            <MaterialTable
                columns={columns}
                data={data}
                style={{boxShadow: "none"}}
                isLoading={is_loading}
                editable={
                    Permissions.checkPermission(permissions_data.payment_method.delete) && {
                        onRowDelete: async rowData => {
                            await DeletePaymentMethod(rowData.id);
                            await listPaymentMethodData();
                        }
                    }}

                actions={[
                    {
                        icon: ()=>(
                            <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                    startIcon={<AddIcon/>}>
                                <Typography variant="body1">{getLabel('add_payment_method')}</Typography>
                            </Button>
                        ),
                        hidden: !Permissions.checkPermission(permissions_data.payment_method.add),
                        isFreeAction: true,
                        onClick: () => handleModalOpen('new')
                    },
                    {
                        icon: 'edit',
                        hidden: !Permissions.checkPermission(permissions_data.payment_method.change),
                        tooltip: getLabel('edit_payment_method'),
                        onClick: (event, rowData) => handleModalOpen(rowData)
                    }

                ]}

                options={{
                    actionsColumnIndex: -1,
                    showTitle: false,
                    pageSize: 10,
                    pageSizeOptions: [10],
                    searchFieldAlignment: 'left'
                }}

                {...material_table_localization()}
            />
        </Grid>

            </>
        );

}
