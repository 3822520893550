import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {CharFieldOnlyField} from "../../../components/fields/CharFieldOnlyField";
import {DateField} from "../../../components/fields/DateField";
import {sub_directory, not_validate_value} from "../../../App";
import moment from "moment";
import {CustomTextArea} from "../../../components/fields/CustomTextArea";
import {permissions_data} from "../../../auth/permission_data";
import {StaffAutoComplete} from "../../../components/fields/StaffAutoComplete";
import Paper from "@material-ui/core/Paper";
import {DisabledTextField} from "../../../components/fields/DisabledField";
import {CustomHeader} from "../../../components/CustomHeader";
import {CustomCheckBox} from "../../../components/CustomCheckBox";
import {SelectField} from "../../../components/fields/SelectField";
import {getLabel} from "../../../localization/MainLocalization";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseClientURL, BaseFinanceBankAccountURL} from "../../../urls";
import {ConfirmationContext} from "../../../store/use_context";


export const BankAccountForm = props => {
    const {setConfirmData} = useContext(ConfirmationContext)

    const bank_account_id = props.bank_account_id;
    const have_permission = Permissions.checkAllPermissions([permissions_data.bank_account.add]) && bank_account_id === 'new';

    let hide_deposit_field;
    let method_label;

    if (props.location.state.type !== "add_withdraw") {
        hide_deposit_field = true;
        method_label = "received_as";
    } else {
        hide_deposit_field = false
        method_label = "method"
    }


    const payment_options = [
        {title: getLabel("cash"), value: "Cash"},
        {title: getLabel("bank_transfer"), value: "Bank_Transfer"},
        {title: getLabel("cheque"), value: "Cheque"},

    ];
    const [bank_account, setBankAccount] = useState({
        payment_method: {},
        action: props.location.state.type !== "add_withdraw" ? "Deposit" : "Withdraw",
        commission: 0,
        cash_log: props.location.state.type === "add_cash_deposit"
    });
    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            if (bank_account_id !== 'new') {
                let response = await httpRequestWithAuth(`${BaseFinanceBankAccountURL}${bank_account_id}/`, "GET")

                if (response.status) {
                    await setBankAccount(response.data);
                } else {
                    props.history.push("/notfound");
                }

            }
            setIsLoading(false)
        }

        fetchData();
    }, [bank_account_id])


    const handleInfoFieldChange = async (name, newValue) => {
        if (have_permission) {
            let data = {...bank_account, [name]: newValue}

            if (['amount', 'commission'].includes(name)) {
                data = {
                    ...data,
                    total_amount: not_validate_value.includes(newValue)?"":parseFloat(data.amount) + parseFloat(data.commission)
                };
            }
            await setBankAccount(data);
        }
    };

    const handleBackButton = () => {
        props.history.push(sub_directory + "bank-account")
    }


    const handleSaveButton = async () => {
        setIsLoading(true)

        let bank_account_data = {
            ...bank_account,
            date: !not_validate_value.includes(bank_account.date) ? moment(bank_account.date).format("YYYY-MM-DD") : null
        }

        let response;
        if (bank_account_id === 'new') {
            response = await httpRequestWithAuth(BaseFinanceBankAccountURL,"POST", bank_account_data);
        } else {
            response = await httpRequestWithAuth(`${BaseFinanceBankAccountURL}${bank_account_id}/`,"PUT", bank_account_data);
        }

        await setHelperText({});
        if (response.status) {
            handleBackButton();
        } else {
            await setHelperText(response.error_feedback);
        }

        setIsLoading(false)
    };

    const handleDeleteButton = async () => {
        setConfirmData({
            msg: "Are you sure to delete this bank account operation ??",
            action: deleteAction
        })
    };

    const deleteAction = async () => {
        let response = await httpRequestWithAuth(`${BaseFinanceBankAccountURL}${bank_account_id}/`, "DELETE");
        if (response.status) {
            handleBackButton();
        }else {
            await setHelperText(response.error_feedback)
        }
    };



    let bank_account_data = (

        <Paper className="h-100">
            <Grid container spacing={2} className='Paper'>

                <CustomHeader
                    title="bank_transfer_information"
                />

                <Hide hide={hide_deposit_field}>
                    <Grid item xs={12}>
                        <CharFieldOnlyField
                            required={true}
                            disabled={!have_permission}
                            helper_text={helper_text.title}
                            error={helper_text.title !== undefined}
                            value={bank_account.title}
                            onChangeHandle={handleInfoFieldChange}
                            name="title"
                            label="title"
                        />
                    </Grid>
                </Hide>

                <Grid item xs={12} md={6}>
                    <StaffAutoComplete
                        name="staff"
                        disabled={!have_permission}
                        label="staff"
                        onChangeHandle={handleInfoFieldChange}
                        error={helper_text.staff !== undefined}
                        helper_text={helper_text.staff}
                        value={bank_account.staff}
                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <DateField
                        required={true}
                        viewOnly={!have_permission}
                        disabled={!have_permission}
                        name="date"
                        error={helper_text.date !== undefined}
                        helper_text={helper_text.date}
                        label="date"
                        value={bank_account.date}
                        onChangeHandle={handleInfoFieldChange}
                    />
                </Grid>

                <Hide hide={hide_deposit_field}>
                    <Grid item xs={12}>
                        <CustomCheckBox
                            disable={!have_permission}
                            value={bank_account.cash_log}
                            name='cash_log'
                            onChangeHandle={handleInfoFieldChange}
                            label="cash_log"
                        />
                    </Grid>
                </Hide>

                <Grid item xs={12} className="mt-3">
                    <CustomTextArea
                        label="description"
                        disabled={!have_permission}
                        error={helper_text.description !== undefined}
                        helper_text={helper_text.description}
                        name="description"
                        rows={10}
                        value={bank_account.description}
                        onChangeHandle={handleInfoFieldChange}
                    />
                </Grid>

                {bank_account_id !== 'new' && (
                    <>

                                <Grid item xs={12} md={6}>
                                    <CustomTextField
                                        viewOnly
                                        disabled
                                        label="created_by"
                                        value={bank_account.created_by_name}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <DisabledTextField
                                        label="date_created"
                                        value={bank_account.date_created}
                                    />
                                </Grid>
                    </>
                )}

            </Grid>
        </Paper>
    )


    let bank_account_payment = (
        <Paper className="h-100">

            <Grid container spacing={2} className='Paper'>

                <CustomHeader
                    title="payment_information"
                />

                <Grid item xs={12}>
                    <SelectField
                        viewOnly={!have_permission}
                        disabled={!have_permission}
                        required={true}
                        label={method_label}
                        name="payment_method"
                        value={bank_account.payment_method}
                        onChangeHandle={handleInfoFieldChange}
                        options={payment_options}
                        error={helper_text.payment_method !== undefined}
                        helper_text={helper_text.payment_method}
                    />
                </Grid>


                <Grid item xs={12}>
                    <CustomTextField
                        required={true}
                        disabled={!have_permission}
                        viewOnly={!have_permission}
                        helper_text={helper_text.amount}
                        error={helper_text.amount !== undefined}
                        value={bank_account.amount}
                        onChangeHandle={handleInfoFieldChange}
                        name="amount"
                        label="amount"
                        type="number"
                    />
                </Grid>

                <Hide hide={hide_deposit_field}>
                    <Grid item xs={12}>
                        <CustomTextField
                            required={true}
                            disabled={!have_permission}
                            viewOnly={!have_permission}
                            helper_text={helper_text.commission}
                            error={helper_text.commission !== undefined}
                            value={bank_account.commission}
                            onChangeHandle={handleInfoFieldChange}
                            name="commission"
                            label="commission"
                            type="number"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DisabledTextField
                            label="total_amount"
                            value={bank_account.total_amount}
                        />
                    </Grid>
                </Hide>


            </Grid>
        </Paper>
    )

    return (
        <>

            <Hide hide={!is_loading}><Loading/></Hide>

            <Hide hide={is_loading}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={8}>
                        {bank_account_data}
                    </Grid>

                    <Grid item xs={12} md={12} lg={4}>
                        {bank_account_payment}
                    </Grid>

                    <ActionButtons
                        errorMsg={helper_text.non_field_errors}
                        allowDelete={Permissions.checkAllPermissions([permissions_data.bank_account.delete]) && bank_account_id !== 'new'}
                        deleteAction={handleDeleteButton}
                        allowBack={have_permission}
                        backAction={handleBackButton}
                        allowSave={have_permission}
                        saveAction={handleSaveButton}
                    />

                </Grid>
            </Hide>
        </>

    );

}
