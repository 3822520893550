import React, {useContext, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import "../../../assets/scss/Static.scss";
import {ActionButtons} from "../../../components/ActionButton";
import MaterialTable from "material-table";
import {SelectServicesAndPackagesForm} from "./components/SelectServicesAndPackagesForm";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {getLabel} from "../../../localization/MainLocalization";
import {sub_directory} from "../../../App";
import {material_table_localization} from "../../../components/Material_conf";
import EditIcon from "@material-ui/icons/Edit";
import {ListLocationServices} from "./location-apis";
import {Hide} from "../../../components/Hide";
import Permissions from "../../../auth/permissions";
import {permissions_data} from "../../../auth/permission_data";
import {httpRequestWithAuth} from "../../../auth/axios_instance";
import {BaseListLocationsServicesURL, ServiceFullDataURL} from "../../../urls";
import {AlertContext} from "../../../store/use_context";
import Button from "@material-ui/core/Button";
import {ViewAccessPrivilegesModal} from "./components/ViewAccessPrivilegesModal";
import Chip from "@material-ui/core/Chip";


export const LocationServices = props => {
    const {setAlert} = useContext(AlertContext);
    const [access_privileges_data, setAccessPrivilegesData] = useState({open: false, price_lists: []});

    const location_id = props.location;

    const [columns, setColumns] = useState([
        {title: getLabel("services"), field: 'service'},
        {title: getLabel('default_price_list'), field: 'default_price_list.name'},
        {
            title: getLabel('status'),
            render: rowData => {
                return (
                    <Chip color={rowData.status === 'Active' ? "primary" : "default"}
                          label={rowData.status === 'Active' ? "Active" : "Inactive"}/>)
            },
        },
        {
            render: rowData => (
                <Hide hide={rowData.status !== 'Active'}>
                    <Button color="primary" size="small"
                            disabled={!Permissions.checkPermission(permissions_data.shared_location_service.view)}
                            onClick={() => ViewAccessPrivileges(rowData)}>
                        {getLabel('access_privileges')}
                    </Button>
                </Hide>
            )
        }
    ]);

    const [helper_text, setHelperText] = useState({});
    const [services, setServices] = useState([]);
    const [selected_services, setSelectedServices] = useState([]);
    const [unselected_services, setUnselectedServices] = useState([]);
    const [form_open, setFormOpen] = useState(false);
    const [form_data, setFormData] = useState({});
    const [location_services, setLocationServices] = useState([]);
    const [is_loading, setIsLoading] = useState(true);


    const setServicesAndPriceList = (services, location_services) => {
        services = services !== undefined ? services : [];
        location_services = location_services !== undefined ? location_services : [];
        let select_services = [];
        let unselecte_services = [];
        let temp_services = [];

        services.map((Service, index) => {
            let price_lists = [];
            let selected_price_list_name = "";
            let select_service = false;
            let ref_location_service = -1;
            let default_price_list = null;
            let is_active = false;
            location_services.map(Bservice => {
                if (Service.id === Bservice.service) {
                    select_service = true;
                    is_active = Bservice.is_active
                    ref_location_service = Bservice.id;
                }
                return 0;
            });
            Service.price_lists.map(PriceList => {
                let select_price_list = false;
                location_services.map(Lservice => {

                    if (PriceList.id === Lservice.default_price_list) {
                        default_price_list = PriceList
                    }

                    select_price_list = select_service && Lservice.price_lists.indexOf(PriceList.id) > -1 ? true : select_price_list;

                    if (select_service && Lservice.price_lists.indexOf(PriceList.id) > -1) {
                        selected_price_list_name = selected_price_list_name + PriceList.name + ", ";
                    }
                    return 0;
                });

                price_lists.push({
                    id: PriceList.id,
                    name: PriceList.name,
                    checked: select_price_list
                });
                return 0;
            });
            temp_services.push({
                id: Service.id,
                name: Service.name,
                checked: select_service,
                price_lists: price_lists,
                selected_price_list_name: selected_price_list_name,
                default_price_list: default_price_list
            });

            if (select_service && is_active) {
                select_services.push({
                    location_service_id: ref_location_service,
                    id: Service.id,
                    index: index,
                    service: temp_services[index].name,
                    price_lists: temp_services[index].price_lists,
                    default_price_list: default_price_list,
                    status: "Active"
                });
            } else {
                select_services.push({
                    location_service_id: ref_location_service,
                    id: Service.id,
                    index: index,
                    service: temp_services[index].name,
                    price_lists: [],
                    default_price_list: "",
                    status: "Inactive"
                });
                unselecte_services.push(temp_services[index]);
            }
            return 0;
        });

        setServices(temp_services);
        setSelectedServices(select_services);
        setUnselectedServices(unselecte_services);
        setLocationServices(location_services);
    };

    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            let ListLocationServicesResponse = await ListLocationServices(location_id);
            let ListServicesResponse = await httpRequestWithAuth(ServiceFullDataURL, "GET", null, {location: location_id});
            if (ListServicesResponse.status && ListLocationServicesResponse.status) {
                setServicesAndPriceList(ListServicesResponse.data, ListLocationServicesResponse.data);
            }
            await setIsLoading(false);
        }

        fetchData();
    }, [])


    const handleForm_open = data => {
        setIsLoading(true)
        setFormData(data)
        setFormOpen(true)
    };

    const handleFormClose = () => {
        setIsLoading(false)
        setFormData({})
        setFormOpen(false)
    };

    const handleDisableButton = async ServiceData => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(`${BaseListLocationsServicesURL}${ServiceData.location_service_id}/`, 'DELETE')
        if (response.status) {
            let branch_services = [];
            await location_services.map(Service => {
                if (Service.id !== ServiceData.location_service_id) {
                    branch_services.push(Service)
                } else {
                    branch_services.push({...Service, is_active: false})
                }
                return 0;
            })
            await setServicesAndPriceList(services, branch_services)
            await setAlert("Deactivate service successfully")
        } else {
            await setAlert(response.error_feedback.non_field_errors)
        }
        await setIsLoading(false);
    }


    const handleBackButton = async () => {
        props.history.push(sub_directory + 'locations')
    };

    const ViewAccessPrivileges = (data) => {
        if (Permissions.checkPermission(permissions_data.shared_location_service.view)) {
            setAccessPrivilegesData({
                open: true,
                location_service: data.location_service_id,
                price_lists: data.price_lists || []
            });
        }
    }

    const CloseAccessPrivileges = () => {
        setAccessPrivilegesData({open: false, price_lists: []});
    }
    return (
        <>
            <Hide hide={!access_privileges_data.open}>
                <ViewAccessPrivilegesModal
                    open={access_privileges_data.open}
                    location_service={access_privileges_data.location_service}
                    price_lists={access_privileges_data.price_lists}
                    handleModalClose={CloseAccessPrivileges}
                    location_gender={props.location_gender}
                    location_id={location_id}
                />
            </Hide>

            <Hide hide={!form_open}>
                <SelectServicesAndPackagesForm
                    open={form_open}
                    data={form_data}
                    options={form_data.id === undefined ? unselected_services : [form_data]}
                    handleModalClose={handleFormClose}
                    setServicesAndPriceList={setServicesAndPriceList}
                    allServices={services}
                    locationServices={location_services}
                    location_id={location_id}
                />
            </Hide>


            <Grid item xs={12}>
                <MaterialTable
                    columns={columns}
                    isLoading={is_loading}
                    data={selected_services}
                    style={{boxShadow: "none"}}
                    // onRowClick={(e, value) => console.log(value)}
                    actions={[
                        ((rowData) => {
                            return (
                                {
                                    icon: () => rowData.status === 'Active' ?
                                        <EditIcon/> :
                                        <CheckCircleOutlineIcon/>,
                                    hidden: !Permissions.checkAllPermissions([permissions_data.location_service.add, permissions_data.location_service.change]),
                                    tooltip: rowData.status === 'Active' ? getLabel("pages.locationinfo.edit_service") : getLabel("pages.branchinfo.activate_service"),
                                    onClick: () => handleForm_open(services[rowData.index])
                                }
                            );
                        }),

                        ((rowData) => {
                            return (
                                {
                                    icon: RemoveCircleOutlineIcon,
                                    hidden: rowData.status !== 'Active' || !Permissions.checkPermission(permissions_data.location_service.delete),
                                    tooltip: getLabel("pages.locationinfo.deactivate_service"),
                                    onClick: () => handleDisableButton(rowData)
                                }
                            )
                        }),
                    ]}

                    options={{
                        paging: false,
                        actionsColumnIndex: -1,
                        showTitle: false,
                        searchFieldAlignment: 'left'
                    }}

                    {...material_table_localization()}

                />
            </Grid>


            <ActionButtons
                errorMsg={helper_text.non_field_errors}
                allowBack={true}
                backAction={handleBackButton}
            />

        </>
    );
}

