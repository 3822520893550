import {BasePriceForOfferURL} from "../../../../urls";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";



export const ListPricesForOffer = async (price_list = null, exclude_price_list_offer= null) => {
    let url = BasePriceForOfferURL;

    if (price_list != null) {
        url += '?price_list=' + price_list;
    }

    if (exclude_price_list_offer != null) {
        url += '&exclude_price_list_offer=' + exclude_price_list_offer;
    }
    const feedback = await httpRequestWithAuth(url, "GET");

    return feedback
};

