import React, {useState} from "react";
import {getLabel} from "../../../../localization/MainLocalization";
import {sub_directory} from "../../../../App";
import Button from "@material-ui/core/Button";
import {Hide} from "../../../../components/Hide";
import {ViewDataModel} from "../../../../components/ViewDataModel";

export const ViewMembershipsForFreezeApply = props => {
    const [is_loading, setIsLoading] = useState(false);
    const columns = [
        {title: getLabel("name"), field: 'name'},
        {title: getLabel("service"), field: 'service'},
        {title: getLabel("start_date"), field: 'start_date'},
        {title: getLabel("end_date"), field: 'end_date'},
        {
            render: rowData => {
                return (
                    <Button size="small"
                            variant="outlined"
                            component="a"
                            target="_blank"
                            href={
                                props.attendance_error?
                                    `${sub_directory}clients/?client=${rowData.client}&tag=${rowData.name}&attend_for_membership=${rowData.id}&attend_dates=${rowData.dates.join(',')}`
                                    :
                                `${sub_directory}memberships/?membership_id=${rowData.id}&tag=${rowData.name}&view-freeze=${true}`
                            }
                    >
                        {getLabel("view")}
                    </Button>
                )
            }
        }
    ]


    return (
        <Hide hide={!props.open}>
            <ViewDataModel
                open={props.open}
                header="memberships_freezes"
                notes={props.msg}
                columns={columns}
                data={props.data}
                handleModalClose={props.handleModalClose}
                actionBtn={props.action && (
                    <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={props.action}
                    >
                        {getLabel(props.actionLabel)}
                    </Button>
                )}
            />


        </Hide>
    )
}
