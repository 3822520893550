import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../../assets/scss/Static.scss';
import {CustomTextField} from "../../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../../components/FormHeader";
import {FormActionButtons} from "../../../../../components/FormActionButton";
import {CustomModal} from "../../../../../components/CustomModal";
import {CustomCheckBox} from "../../../../../components/CustomCheckBox";
import {Hide} from "../../../../../components/Hide";
import {Loading} from "../../../../../components/Loading";
import {httpRequestWithAuth} from "../../../../../auth/axios_instance";
import {BasePriceURL} from "../../../../../urls";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {Typography} from "@material-ui/core";
import {getLabel} from "../../../../../localization/MainLocalization";
import {not_validate_value} from "../../../../../App";

export const PriceFormModal = props => {
    const [is_loading, setIsLoading] = useState(false);
    const [helper_text, setHelperText] = useState([])
    const [data, setData] = useState({is_active: true, ...props.default_data})
    const [session_type, setSessionType] = useState("0");

    useEffect(()=>{
        if (!not_validate_value.includes(props.default_data.sessions)){
        let session_value = parseInt(props.default_data.sessions)
            if (session_value === 0){
               setSessionType("0");
                setData({...data, sessions:'0'});
            }

        if (session_value === 1){
               setSessionType("1");
                setData({...data, sessions:'1'});
            }

            if (session_value > 1){
               setSessionType("2");
            }
        }else{
            setData({...data, sessions:'0'});
        }

    },[])

    const handleFieldChange = (name, newValue) => {
        setData({...data, [name]: newValue})
    };

    const handleSaveForm = async () => {
        await setIsLoading(true);
        let temp_data = {...data};
        let no_error = true ;

        if (session_type === "0"){
            temp_data['sessions'] = 0
        }
        if (session_type === "1"){
            temp_data['sessions'] = 1
        }

        if (session_type === "2"){
                let value = parseInt(temp_data['sessions']);
                if (value < 2){
                    no_error = false;
                    setHelperText({...helper_text, sessions: "Min value for limited access is 2"})
                }
        }

        if (no_error){
        let response;
        if (data.id === undefined) {
            response = await httpRequestWithAuth(BasePriceURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${BasePriceURL}${data.id}/`, "PATCH", temp_data);
        }

        if (response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback);
        }
        }

        await setIsLoading(false);
    };

    return (
        <CustomModal
            open={props.open}
        >
            <Grid container spacing={3}>

                <FormHeader
                    title="pages.prices.form.header"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            required
                            error={helper_text.name !== undefined}
                            helper_text={helper_text.name}
                            name="name"
                            label="pages.prices.price_name"
                            value={data.name}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            required
                            error={helper_text.price !== undefined}
                            helper_text={helper_text.price}
                            name="price"
                            label="price"
                            type="number"
                            value={data.price}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            required
                            error={helper_text.duration !== undefined}
                            helper_text={helper_text.duration}
                            name="duration"
                            label="duration_days"
                            type="number"
                            value={data.duration}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>

                    <Hide hide={props.is_rent}>
                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                required
                                error={helper_text.freeze_days !== undefined}
                                helper_text={helper_text.freeze_days}
                                name="freeze_days"
                                label="pages.prices.freeze_days"
                                type="number"
                                value={data.freeze_days}
                                onChangeHandle={handleFieldChange}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <Typography component="body"  className="pt-3">
                                Session type
                            </Typography>
                        </Grid>

                        <Grid item xs={10}>
                            <RadioGroup value={session_type}
                                        onChange={e => {
                                            setHelperText({...helper_text, 'sessions': undefined});
                                            setSessionType(e.target.value);
                                            setData({...data, sessions: e.target.value});
                                        }}>

                                <Grid container spacing={3} className="p-2">

                                    <Grid item xs={12}>
                                        <div>
                                        <FormControlLabel value="0" control={<Radio/>}
                                                          label={getLabel("unlimited_access")}/>
                                        </div>

                                        <div>
                                        <FormControlLabel value="1" control={<Radio/>}
                                                          label={getLabel("one_day_access")}/>
                                        </div>

                                        <div>
                                        <FormControlLabel value="2" control={<Radio/>}
                                                          label={getLabel("limited_access")}/>
                                        <CustomTextField
                                            required
                                            disabled={session_type !== '2'}
                                            error={helper_text.sessions !== undefined}
                                            helper_text={helper_text.sessions}
                                            name="sessions"
                                            label="sessions"
                                            type="number"
                                            value={data.sessions}
                                            onChangeHandle={handleFieldChange}
                                        />
                                        </div>
                                    </Grid>


                                </Grid>
                            </RadioGroup>
                        </Grid>
                    </Hide>

                    <Grid item xs={12}>
                        <CustomCheckBox
                            value={data.is_active}
                            name='is_active'
                            onChangeHandle={handleFieldChange}
                            label="is_active"
                        />
                    </Grid>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                    />

                </Hide>
            </Grid>

        </CustomModal>
    );
};
