import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {getLabel} from "../localization/MainLocalization";

const useStyles = makeStyles({
    table: {
        minWidth: "650px",
    },
});


export const CustomDisplayDataTable = props => {
    const classes = useStyles();
    const {columns} = props
    const {data} = props
    const headerAlign = props.headerAlign || "left"
    const rowAlign = props.rowAlign || "left"

    return (
        <TableContainer className="w-100">
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {columns.map(Column => (
                            <TableCell align={headerAlign}>{Column.title}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.length === 0 ? (
                        <TableRow>
                            <TableCell align="center" colSpan={Object.keys(columns).length} component="th" scope="row">
                                {getLabel("empty_data_source_message")}
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {data.map((Row, index) => (
                                <TableRow key={index}>
                                    {columns.map(Column => (
                                        <TableCell align={rowAlign} component="th" scope="row"
                                                   style={Column.cellStyle}>{Row[Column.field]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </>
                    )}

                </TableBody>
            </Table>
        </TableContainer>
    );
}
