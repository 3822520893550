import React, {useContext, useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BusinessIcon from '@material-ui/icons/Business';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Permissions from '../../auth/permissions';
import {getLabel} from "../../localization/MainLocalization";
import "../../assets/scss/Static.scss";
import {sub_directory} from "../../App";
import {Link} from 'react-router-dom';
import {Hide} from "../../components/Hide";
import {permissions_data} from "../../auth/permission_data";
import Collapse from "@material-ui/core/Collapse";
import {SideMenuToggleContext} from "../../store/use_context";
import Typography from "@material-ui/core/Typography";
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import ListAltIcon from '@material-ui/icons/ListAlt';
import RepeatIcon from '@material-ui/icons/Repeat';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import BarChartIcon from '@material-ui/icons/BarChart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import SettingsIcon from '@material-ui/icons/Settings';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GavelIcon from '@material-ui/icons/Gavel';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import VideoLabelOutlinedIcon from '@material-ui/icons/VideoLabelOutlined';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import SportsIcon from '@material-ui/icons/Sports';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';


export const ListItems = props => {
    const [open_classes, setOpenClasses] = useState(false);
    const [open_training, setOpenTraining] = useState(false);
    const [open_financial, setOpenFinancial] = useState(false);
    const [open_account, setOpenAccount] = useState(false);
    const [open_mobile_app, setOpenMobileApp] = useState(false);
    const {side_menu_toggle, setSideMenuToggle} = useContext(SideMenuToggleContext);

    const handleSideMenuToggle = () => {
        if (props.is_drawer) {
            setSideMenuToggle(!side_menu_toggle)
        }
    }

    return (
        <>
            <Typography variant='h6' className='text-center mt-3 mb-3'>
                {localStorage.getItem('business_name')}
            </Typography>

            <ListItem button component={Link} className="side-menu-item" onClick={handleSideMenuToggle}
                      to={sub_directory + 'dashboard'}>
                <ListItemIcon className="side-menu-item">
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary={getLabel('dashboard')}/>
            </ListItem>

            <Hide hide={!Permissions.checkPermission(permissions_data.athlete.view)}>
                <ListItem button component={Link} className="side-menu-item" onClick={handleSideMenuToggle}
                          to={sub_directory + 'athletes'}>
                    <ListItemIcon className="side-menu-item">
                        <PersonIcon/>
                    </ListItemIcon>
                    <ListItemText primary={getLabel('athletes')}/>
                </ListItem>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.client.view)}>
                <ListItem button component={Link} className="side-menu-item" onClick={handleSideMenuToggle}
                          to={sub_directory + 'clients'}>
                    <ListItemIcon className="side-menu-item">
                        <PersonIcon/>
                    </ListItemIcon>
                    <ListItemText primary={getLabel('clients')}/>
                </ListItem>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.membership.view)}>
                <ListItem button component={Link} className="side-menu-item" onClick={handleSideMenuToggle}
                          to={sub_directory + 'memberships'}>
                    <ListItemIcon className="side-menu-item">
                        <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText primary={getLabel('memberships')}/>
                </ListItem>
            </Hide>

            <Hide
                hide={!Permissions.checkOnePermissions([permissions_data.classes.view, permissions_data.recurring_classes.view])}>
                <ListItem button onClick={() => setOpenClasses(!open_classes)}
                          className="side-menu-item">
                    <ListItemIcon>
                        <DateRangeIcon className="side-menu-item"/>
                    </ListItemIcon>
                    <ListItemText primary={getLabel('classes')}/>
                    {open_classes ? <ExpandLess className="side-menu-item"/> : <ExpandMore className="side-menu-item"/>}
                </ListItem>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.classes.view)}>
                <Collapse in={open_classes}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'classes'}>
                        <ListItemIcon>
                            <ListAltIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('list')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.recurring_classes.view)}>
                <Collapse in={open_classes}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'recurring-classes'}>
                        <ListItemIcon>
                            <RepeatIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('recurring_classes')}/>
                    </ListItem>


                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.classes.view_deleted_classes)}>
                <Collapse in={open_classes}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'classes/deleted-classes'}>
                        <ListItemIcon>
                            <RestoreFromTrashIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('deleted_classes')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.workout.view)}>
                <ListItem button onClick={() => setOpenTraining(!open_training)} className="side-menu-item">
                    <ListItemIcon>
                        <SportsHandballIcon className="side-menu-item"/>
                    </ListItemIcon>
                    <ListItemText primary={getLabel('training')}/>
                    {open_training ? <ExpandLess className="side-menu-item"/> :
                        <ExpandMore className="side-menu-item"/>}
                </ListItem>

            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.workout.view)}>
                <Collapse in={open_training}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'workouts'}>
                        <ListItemIcon>
                            <SportsKabaddiIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('workouts')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkOnePermissions([
                permissions_data.expense.view, permissions_data.expense_category.view, permissions_data.reimbursement.view,
                permissions_data.bank_account.view, permissions_data.cash_transfer_request.view, permissions_data.cash_log.view,
                permissions_data.bank_transactions.view, permissions_data.claim.view,
                permissions_data.claim.view_staff
            ])}>
                <ListItem button onClick={() => setOpenFinancial(!open_financial)}
                          className="side-menu-item">
                    <ListItemIcon>
                        <MonetizationOnIcon className="side-menu-item"/>
                    </ListItemIcon>
                    <ListItemText primary={getLabel('financial')}/>
                    {open_financial ? <ExpandLess className="side-menu-item"/> :
                        <ExpandMore className="side-menu-item"/>}
                </ListItem>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.claim.view)}>
                <Collapse in={open_financial}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'my-claims'}>
                        <ListItemIcon>
                            <ShoppingBasketIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('my_claims')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.claim.view_staff)}>
                <Collapse in={open_financial}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'claims'}>
                        <ListItemIcon>
                            <AssignmentIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('staff_claims')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.expense.view)}>
                <Collapse in={open_financial}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'expenses'}>
                        <ListItemIcon>
                            <AttachMoneyIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('expenses')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.expense_category.view)}>
                <Collapse in={open_financial}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'expenses/category'}>
                        <ListItemIcon>
                            <SubtitlesIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('expense_category')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.vendor.view)}>
                <Collapse in={open_financial}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'vendors/'}>
                        <ListItemIcon>
                            <LocationCityIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('vendors')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            {/*<Hide hide={!Permissions.checkPermission(permissions_data.reimbursement.view)}>*/}
            {/*    <Collapse in={open_financial}>*/}
            {/*        <ListItem button component={Link} className="side-menu-item margin-left-3"*/}
            {/*                  onClick={handleSideMenuToggle} to={sub_directory + 'reimbursements'}>*/}
            {/*            <ListItemIcon>*/}
            {/*                <WorkIcon className="side-menu-item"/>*/}
            {/*            </ListItemIcon>*/}
            {/*            <ListItemText primary={getLabel('reimbursements')}/>*/}
            {/*        </ListItem>*/}
            {/*    </Collapse>*/}
            {/*</Hide>*/}

            <Hide hide={!Permissions.checkPermission(permissions_data.bank_account.view)}>
                <Collapse in={open_financial}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'bank-account'}>
                        <ListItemIcon>
                            <AccountBalanceWalletIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('bank_account')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.cash_transfer_request.view)}>
                <Collapse in={open_financial}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'cash-transfer-requests'}>
                        <ListItemIcon>
                            <TransferWithinAStationIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('cash_transfer')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.cash_log.view)}>
                <Collapse in={open_financial}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'cash-log'}>
                        <ListItemIcon>
                            <LocalAtmIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('cash_log')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.bank_transactions.view)}>
                <Collapse in={open_financial}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={sub_directory + 'bank-transactions'}>
                        <ListItemIcon>
                            <AccountBalanceIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('bank_transactions')}/>
                    </ListItem>
                </Collapse>
            </Hide>


            <Hide
                hide={!Permissions.checkOnePermissions([permissions_data.banners.view])}>
                <ListItem button onClick={() => setOpenMobileApp(!open_mobile_app)}
                          className="side-menu-item">
                    <ListItemIcon>
                        <PhoneIphoneOutlinedIcon className="side-menu-item"/>
                    </ListItemIcon>
                    <ListItemText primary={getLabel('mobile_app')}/>
                    {open_mobile_app ? <ExpandLess className="side-menu-item"/> :
                        <ExpandMore className="side-menu-item"/>}
                </ListItem>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.banners.view)}>
                <Collapse in={open_mobile_app}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={`${sub_directory}banners`}>
                        <ListItemIcon>
                            <VideoLabelOutlinedIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('banners')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.entity.view_reports)}>
                <ListItem button component={Link} className="side-menu-item" onClick={handleSideMenuToggle}
                          to={sub_directory + 'reports'}>
                    <ListItemIcon className="side-menu-item">
                        <BarChartIcon/>
                    </ListItemIcon>
                    <ListItemText primary={getLabel('reports')}/>
                </ListItem>
            </Hide>

            <Hide
                hide={!Permissions.checkOnePermissions([permissions_data.entity.view, permissions_data.location.view, permissions_data.service.view,
                    permissions_data.equipment.view, permissions_data.staff.view, permissions_data.benchmark.view])}>
                <ListItem button onClick={() => setOpenAccount(!open_account)}
                          className="side-menu-item">
                    <ListItemIcon>
                        <BusinessIcon className="side-menu-item"/>
                    </ListItemIcon>
                    <ListItemText primary={getLabel('account')}/>
                    {open_account ? <ExpandLess className="side-menu-item"/> : <ExpandMore className="side-menu-item"/>}
                </ListItem>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.entity.view)}>
                <Collapse in={open_account}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={`${sub_directory}entity`}>
                        <ListItemIcon>
                            <SettingsIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('general')}/>
                    </ListItem>
                </Collapse>
            </Hide>


            <Hide hide={!Permissions.checkPermission(permissions_data.location.view)}>
                <Collapse in={open_account}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={`${sub_directory}locations`}>
                        <ListItemIcon>
                            <LocationOnIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('locations')}/>
                    </ListItem>
                </Collapse>
            </Hide>


            <Hide hide={!Permissions.checkPermission(permissions_data.service.view)}>
                <Collapse in={open_account}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={`${sub_directory}services`}>
                        <ListItemIcon>
                            <FitnessCenterIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('services')}/>
                    </ListItem>
                </Collapse>
            </Hide>


            <Hide hide={!Permissions.checkPermission(permissions_data.season.view)}>
                <Collapse in={open_account}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={`${sub_directory}teams`}>
                        <ListItemIcon>
                            <SettingsIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('teams')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.benchmark.view)}>
                <Collapse in={open_account}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={`${sub_directory}benchmarks`}>
                        <ListItemIcon>
                            <SportsIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('benchmarks')}/>
                    </ListItem>
                </Collapse>
            </Hide>


            <Hide hide={!Permissions.checkPermission(permissions_data.staff.view)}>
                <Collapse in={open_account}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={`${sub_directory}staff`}>
                        <ListItemIcon>
                            <AssignmentIndIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('staff')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <Hide hide={!Permissions.checkPermission(permissions_data.equipment.view)}>
                <Collapse in={open_account}>
                    <ListItem button component={Link} className="side-menu-item margin-left-3"
                              onClick={handleSideMenuToggle} to={`${sub_directory}equipment`}>
                        <ListItemIcon>
                            <GavelIcon className="side-menu-item"/>
                        </ListItemIcon>
                        <ListItemText primary={getLabel('equipment')}/>
                    </ListItem>
                </Collapse>
            </Hide>

            <div className="mb-5"/>
        </>
    );
}
