import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import '../../../../assets/scss/Static.scss';
import {FormHeader} from "../../../../components/FormHeader";
import {CustomModal} from "../../../../components/CustomModal";
import {Loading} from "../../../../components/Loading";
import {Hide} from "../../../../components/Hide";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseListSharedLocationsServicesURL} from "../../../../urls";
import {not_validate_value} from "../../../../App";
import {getLabel} from "../../../../localization/MainLocalization";
import MaterialTable from "material-table";
import {material_table_localization} from "../../../../components/Material_conf";
import {LocationsAutoComplete} from "../../../../components/fields/LocationsAutoComplete";
import {CustomCheckBox} from "../../../../components/CustomCheckBox";
import {CustomRadioGroup} from "../../../../components/CustomRadioGroup";
import {PricesAutoComplete} from "../../../../components/fields/PricesAutoComplete";
import {ErrorBox} from "../../../../components/ErrorBox";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import {SuccessfulBox} from "../../../../components/SuccessfulBox";
import Permissions from "../../../../auth/permissions";
import {permissions_data} from "../../../../auth/permission_data";

export const ViewAccessPrivilegesModal = props => {
    const [data, setData] = useState([]);
    const [is_loading, setIsLoading] = useState(false);

    const prices_select_options = [
        {title: "all_prices", value: "true"},
        {title: "select_prices", value: "false"},
    ]

    const locations_select_options = [
        {title: "all_locations", value: "true"},
        {title: "select_locations", value: "false"},
    ]

    const columns = [
        {title: getLabel('price_list'), field: 'price_list_name'},
        // {
        //     title: getLabel('locations'), render: rowData => {
        //         return rowData.locations_names.map(Location => (
        //             <Typography variant="body2" key={Location}>
        //                 {Location}
        //             </Typography>
        //         ));
        //     }
        // },
    ]

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (!not_validate_value.includes(props.location_service)) {
                let params = [];
                params.location_service = props.location_service;

                let response = await httpRequestWithAuth(BaseListSharedLocationsServicesURL, "GET", null, {location_service: props.location_service});

                if (response.data) {
                    let temp_data = [];
                    let temp_price_lists = [...props.price_lists];

                    response.data.map((SharedData, Index) => {
                        temp_data.push({
                            index: Index,
                            helper_text: {},
                            save_done: false,
                            ...SharedData
                        });
                        temp_price_lists = temp_price_lists.filter(obj => obj.id !== SharedData.price_list);
                    })

                    temp_price_lists.map((PriceListData) => {
                        temp_data.push({
                            index: temp_data.length,
                            location_service: props.location_service,
                            price_list: PriceListData.id,
                            price_list_name: PriceListData.name,
                            is_active: false,
                            locations: [],
                            prices: [],
                            all_prices: true,
                            all_locations: false,
                            helper_text: {},
                            save_done: false,
                        });
                    })

                    setData(temp_data);
                }
            }


            setIsLoading(false);
        }

        fetchData();
    }, []);

    const onChangeField = (index, name, value) => {
        let temp_data = [...data];
        temp_data[index][name] = value;
        temp_data[index].save_done = false;
        setData(temp_data);
    }


    const handleSaveActions = async index => {
        let new_data = [...data];
        let temp_data = {...data[index]};

        delete temp_data['index'];
        delete temp_data['helper_text'];
        delete temp_data['save_done'];

        let response;
        if (not_validate_value.includes(temp_data.id)) {
            response = await httpRequestWithAuth(BaseListSharedLocationsServicesURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${BaseListSharedLocationsServicesURL}${temp_data.id}/`, "PATCH", temp_data);
        }

        if (response.status) {
            new_data[index].helper_text = {}
            new_data[index].save_done = true
            new_data[index].id = response.data.id
            new_data[index].prices = response.data.prices
            new_data[index].locations = response.data.locations
            await setData(new_data);
        } else {
            new_data[index].helper_text = response.error_feedback;
            await setData(new_data);
        }
    }

    const DetailsPanel = props => {
        const [detail_panel_loading, setDetailPanelLoading] = useState(false);
        const onSave = async () => {
            await setDetailPanelLoading(true);
            await handleSaveActions(props.index);
            await setDetailPanelLoading(false);
        }

        return (
            <Grid container spacing={1} className="pl-3 pr-3 pb-1 pt-3">
                <Hide hide={!detail_panel_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={detail_panel_loading}>

                    <SuccessfulBox
                        msg={props.save_done ? getLabel("save_done") : null}
                    />

                    <ErrorBox
                        errorMsg={props.helper_text.non_field_errors}
                    />

                    {/*<Grid item xs={12}>*/}
                    {/*    <CustomRadioGroup*/}
                    {/*        label="for_locations"*/}
                    {/*        name="all_locations"*/}
                    {/*        error={props.helper_text.all_locations !== undefined}*/}
                    {/*        helper_text={props.helper_text.all_locations}*/}
                    {/*        value={props.all_locations + ""}*/}
                    {/*        onChangeHandle={(name, value) => onChangeField(props.index, name, value)}*/}
                    {/*        options={locations_select_options}*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                    <Grid item xs={12}>
                        <LocationsAutoComplete
                            for={props.location_gender}
                            exclude_locations={[props.location_id]}
                            // disabled={props.all_locations + "" !== "false"}
                            error={props.helper_text.locations !== undefined}
                            helper_text={props.helper_text.locations}
                            multiple
                            name='locations'
                            label="locations"
                            onChangeHandle={(name, value) => onChangeField(props.index, name, value)}
                            value={props.locations}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <CustomRadioGroup
                            label="for_prices"
                            name="all_prices"
                            error={props.helper_text.all_prices !== undefined}
                            helper_text={props.helper_text.all_prices}
                            value={props.all_prices + ""}
                            onChangeHandle={(name, value) => onChangeField(props.index, name, value)}
                            options={prices_select_options}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PricesAutoComplete
                            disabled={props.all_prices + "" !== "false"}
                            multiple
                            price_list={props.price_list}
                            error={props.helper_text.prices !== undefined}
                            helper_text={props.helper_text.prices}
                            name="prices"
                            label="prices"
                            value={props.prices}
                            onChangeHandle={(name, value) => onChangeField(props.index, name, value)}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <CustomCheckBox
                            value={props.is_active}
                            name='is_active'
                            onChangeHandle={(name, value) => onChangeField(props.index, name, value)}
                            label="is_active"
                        />
                    </Grid>

                    <Hide hide={!Permissions.checkPermission(permissions_data.shared_location_service.change)}>
                        <Grid item xs={12} className="text-end">
                            <Button
                                onClick={onSave}
                                variant="contained"
                                size="medium"
                                className="primary-bg mb-3 text-white"
                                startIcon={<SaveIcon/>}
                            >
                                {getLabel("save")}
                            </Button>
                        </Grid>
                    </Hide>

                </Hide>
            </Grid>
        )
    };

    return (
        <>
            <CustomModal
                open={props.open}
            >

                <Grid container spacing={3}>
                    <FormHeader
                        title="access_privileges"
                        handleClose={props.handleModalClose}
                    />

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>


                    <Hide hide={is_loading}>
                        <Grid item xs={12}>
                            <MaterialTable
                                columns={columns}
                                data={data}
                                style={{boxShadow: "none", marginBottom: 10}}
                                options={{
                                    paging: false,
                                    toolbar: false
                                }}

                                onRowClick={(event, rowData, togglePanel) => {
                                    onChangeField(rowData.index, 'save_done', false);
                                    togglePanel();
                                }}

                                detailPanel={rowData => {
                                    return (
                                        <DetailsPanel
                                            {...rowData}
                                            location_gender={props.location_gender}
                                            location_id={props.location_id}
                                        />
                                    )
                                }}


                                {...material_table_localization()}
                            />
                        </Grid>

                    </Hide>
                </Grid>

            </CustomModal>
        </>
    );
};