import React, {useEffect, useState} from "react";
import MaterialTable from 'material-table';
import {
    material_table_localization,
} from "../../../components/Material_conf";
import Chip from "@material-ui/core/Chip";
import Permissions from '../../../auth/permissions';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import {
    CreateExpenseCategory,
    DeleteExpenseCategory,
    ListExpenseCategory,
    UpdateExpenseCategory
} from "./expenses_apis";
import {ExpenseCategoryFormModal} from "./components/ExpenseCategoryFormModal";
import {getLabel} from "../../../localization/MainLocalization";
import {CustomBreadcrumbs} from "../../../components/CustomBreadcrumbs";
import {Hide} from "../../../components/Hide";
import Toolbar from "@material-ui/core/Toolbar";
import {permissions_data} from "../../../auth/permission_data";


export const ExpenseCategory = props =>{
           const  [columns, setColumns] = useState([
                {title: getLabel('name'), field:'name'},
                {title: getLabel('status'),
                    render:rowData=>{return(<Chip color={rowData.is_active?"primary":"default"} label={rowData.is_active_view}/>)},
                },
            ]);
    const [form_open, setFormOpen] = useState(false)
    const [new_expense_category, setnewExpenseCategory] = useState({})
    const [data, setData] = useState([])
    const [is_loading, setIsLoading] = useState(true)
    const [search, setSearch] = useState("")

    const listExpenseCategoryData = async () => {
        await setIsLoading(true);
        let response = await ListExpenseCategory();
        if (response.status) {
            await setData(response.data)

        }
        await setIsLoading(false);
    };

    useEffect(()=>{
        listExpenseCategoryData();
    }, [])


    const handleModalOpen = async data => {
        if (data !== 'new') {
                setnewExpenseCategory({...data})
        }
            setFormOpen(true)
    };

    const handleModalClose = async () => {
        await listExpenseCategoryData();
        await setnewExpenseCategory({})
        await setFormOpen(false)
    };




        return(
            <Paper>

                <Hide hide={!form_open}>
                <ExpenseCategoryFormModal
                    default_data={new_expense_category}
                    open={form_open}
                    handleModalClose={handleModalClose}
                />
                </Hide>

                {/*<Toolbar variant="regular"  style={{paddingInlineStart:'2rem', paddingTop:'0.7rem'}}>*/}
                {/*<CustomBreadcrumbs match={props.match}/>*/}
                {/*</Toolbar>*/}

            <MaterialTable
                columns={columns}
                data={data}
                style={{padding: '0.5rem', boxShadow: "none"}}
                isLoading={is_loading}
                editable={
                    Permissions.checkPermission(permissions_data.expense_category.delete) && {
                        onRowDelete: async rowData => {
                            await DeleteExpenseCategory(rowData.id);
                            await listExpenseCategoryData();
                        }
                    }}

                actions={[
                    {
                        icon: ()=>(
                            <Button key="0" size="small" variant="contained" className='mt-2' color='primary'
                                    startIcon={<AddIcon/>}>
                                <Typography variant="body1">{getLabel('add_expense_category')}</Typography>
                            </Button>
                        ),
                        hidden: !Permissions.checkAllPermissions([permissions_data.expense_category.add]),
                        isFreeAction: true,
                        onClick: () => handleModalOpen('new')
                    },
                    {
                        icon: 'edit',
                        hidden: !Permissions.checkAllPermissions([permissions_data.expense_category.change]),
                        tooltip: getLabel('edit_expense_category'),
                        onClick: (event, rowData) => handleModalOpen(rowData)
                    }

                ]}

                options={{
                    actionsColumnIndex: -1,
                    showTitle: false,
                    pageSize: 10,
                    pageSizeOptions: [10],
                    searchFieldAlignment: 'left'
                }}

                {...material_table_localization()}
            />
            </Paper>

        );

}
