import React, {useRef, useState} from 'react';
import MaterialTable from 'material-table';
import Permissions from "../../../auth/permissions";
import {BaseLocationURL} from "../../../urls";
import Paper from "@material-ui/core/Paper";
import {TableToolbar} from "../../../components/TableToolbar";
import {
    material_table_localization,
    material_table_options,
    material_table_style
} from "../../../components/Material_conf";
import {sub_directory} from "../../../App";
import {getLabel} from "../../../localization/MainLocalization";
import {instance} from "../../../auth/axios_instance";
import {permissions_data} from "../../../auth/permission_data";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";


export const Locations = props => {

        const tableRef = useRef();

           const [columns, setColumns] = useState([
               {title: getLabel("name"), render: rowData=>(
                       <Typography>
                           <Link href="" onClick={e => {
                               e.preventDefault();
                               viewLocation(rowData.id, rowData.name)
                           }}>
                               {rowData.name}
                           </Link>
                       </Typography>
                   ), cellStyle: {width: "35%"}},
               {title: getLabel('assigned_to'), field: 'for_who'},
               {
                   title: getLabel('status'),
                   render: rowData => {
                       return (
                           <Chip color={rowData.is_active ? "primary" : "default"} label={rowData.is_active_view}/>)
                   },
               },
                {title: getLabel("screen_code"), field: 'screen_code'},
                // {title: get_label("area"), field: 'area'},
            ]);

           const [search, setSearch] = useState("")


    const viewLocation = (id, tag) => {
        props.history.push({
            pathname: sub_directory +'locations/edit',
            state: {
                id: id,
                tag: tag
            }
        })
    };

    const reloadData = () => {
        tableRef.current && tableRef.current.onChangePage(tableRef.current, 0);
        tableRef.current && tableRef.current.onQueryChange();
    };

    const handleSearchField = (name, newValue) => {
       setSearch(newValue)

        if (newValue.length >= 5 || newValue === '') {
            reloadData();
        }
    };

        return (
            <>
                <Paper>

                    <TableToolbar
                        searchFieldName='search'
                        searchValue={search}
                        onSearchChangeHandle={handleSearchField}
                        searchAction={reloadData}
                        match={props.match}
                        actions={
                            [
                                {
                                    hide: !Permissions.checkPermission(permissions_data.location.add),
                                    onClick:() => props.history.push(
                                        {
                                            pathname: sub_directory + 'locations/new',
                                            state: {id: "new"}
                                        }
                                    ),
                                    label: "add_location"
                                },
                            ]
                            }
                    />

                    <MaterialTable
                        tableRef={tableRef}
                        columns={columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let url = BaseLocationURL;
                                url += '?page=' + (query.page + 1);

                                if (search !== '') {
                                    url += '&search=' + search;
                                }

                                instance.get(url)
                                    .then(result => {
                                        resolve({
                                            data: result.data.results,
                                            page: result.data.page - 1,
                                            totalCount: result.data.total,
                                        })
                                    })
                            })
                        }


                        {...material_table_style}
                        {...material_table_options()}
                        {...material_table_localization()}
                    />
                </Paper>
            </>
        );
    }

