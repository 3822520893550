import React, {useEffect, useState} from "react";
import {CustomModal} from "../../../../components/CustomModal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../../localization/MainLocalization";
import {FormHeader} from "../../../../components/FormHeader";
import {ListEquipmentLocations} from "../equipment-apis";
import {material_table_localization, material_table_style} from "../../../../components/Material_conf";
import MaterialTable from "material-table";
import {Loading} from "../../../../components/Loading";

export const EquipmentCheckQty = props =>{
           const columns = [
                {title: getLabel("location"), field: 'location_name'},
                {title: getLabel("total_qty"), field: 'quantity'},
                {title: getLabel("rented_qty"), field: 'rented_qty'},
                {title: getLabel("available_qty"), field: 'available_qty'},

            ];
    const [data, setData] = useState([])
    const [is_loading, setIsLoading] = useState(true)

    useEffect(()=>{
        const fetchData = async () =>{
            await setIsLoading(true);
            let response = await ListEquipmentLocations(props.data.id)
               setData(response.data)
            await setIsLoading(false);
        }

        fetchData();
    }, [])


    return(
        <CustomModal
        open={props.open}
        >

            <Grid container spacing={3}>

                <FormHeader
                    title="pages.equipment.check_qty"
                    handleClose={props.handleModalClose}
                />

                <Grid xs={12}>
                    {is_loading ? (<Loading/>) :(

                    <MaterialTable
                        columns={columns}
                        data={data}
                        options={{
                            paging: false,
                            showTitle: false,
                            searchFieldAlignment: 'left'
                        }}

                        {...material_table_style}
                        {...material_table_localization()}
                    />
                    )}
                </Grid>

                <Grid item xs={12} md={4} className="text-center text-primary">
                    <Typography variant="h6" className='p-1'> {getLabel("total_qty")}</Typography>
                    <Typography variant="subtitle1" className='p-1'> {props.data.total_qty}</Typography>
                </Grid>

                <Grid item xs={12} md={4} className="text-center text-danger">
                    <Typography variant="h6" className='p-1'> {getLabel("rented_qty")}</Typography>
                    <Typography variant="subtitle1" className='p-1'> {props.data.rented_qty}</Typography>
                </Grid>

                <Grid item xs={12} md={4} className="text-center text-success">
                    <Typography variant="h6" className='p-1'> {getLabel("available_qty")}</Typography>
                    <Typography variant="subtitle1" className='p-1'> {props.data.available_qty}</Typography>
                </Grid>

            </Grid>

        </CustomModal>
    );

}