import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "../../../../../components/fields/CustomTextField";
import {FormHeader} from "../../../../../components/FormHeader";
import {FormActionButtons} from "../../../../../components/FormActionButton";
import {CustomModal} from "../../../../../components/CustomModal";
import {CustomCheckBox} from "../../../../../components/CustomCheckBox";
import {Hide} from "../../../../../components/Hide";
import {Loading} from "../../../../../components/Loading";
import {httpRequestWithAuth} from "../../../../../auth/axios_instance";
import {BaseSeasonGroupURL} from "../../../../../urls";
import {StaffAutoComplete} from "../../../../../components/fields/StaffAutoComplete";
import {CategoryAutoCompleteFreeSolo} from "../../../../../components/fields/CategoryAutoCompleteFreeSolo";
import {DaysComponent} from "../../../../../components/DaysComponent";

export const GroupFormModal = props => {
    const [is_loading, setIsLoading] = useState(false);
    const [helper_text, setHelperText] = useState([])
    const [data, setData] = useState({
        is_active: true, ...props.default_data,
        category: props.default_data.category?props.default_data.category_name :"" });


    const handleFieldChange = (name, newValue) => {
        setData({...data, [name]: newValue})
    };


    const handleSaveForm = async () => {
        await setIsLoading(true);
        let temp_data = {...data};

        let response;
        if (data.id === undefined) {
            response = await httpRequestWithAuth(BaseSeasonGroupURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${BaseSeasonGroupURL}${data.id}/`, "PATCH", temp_data);
        }

        if (response.status) {
            await props.handleModalClose();
        } else {
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false);
    };

    return (
        <CustomModal
            open={props.open}
        >
            <Grid container spacing={3}>

                <FormHeader
                    title="team_form"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>


                <Hide hide={is_loading}>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            required
                            error={helper_text.name !== undefined}
                            helper_text={helper_text.name}
                            name="name"
                            label="name"
                            value={data.name}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <StaffAutoComplete
                            required
                            name='coach'
                            label="coach"
                            onChangeHandle={handleFieldChange}
                            error={helper_text.coach !== undefined}
                            helper_text={helper_text.coach}
                            value={data.coach}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CategoryAutoCompleteFreeSolo
                            required
                            autoSelect
                            freeSolo
                            name='category'
                            label="category"
                            onChangeHandle={handleFieldChange}
                            error={helper_text.category !== undefined}
                            helper_text={helper_text.category}
                            value={data.category}
                            placeHolder="select_category_or_write_new_category_name"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            required
                            error={helper_text.classes_per_week !== undefined}
                            helper_text={helper_text.classes_per_week}
                            name="classes_per_week"
                            label="classes_per_week"
                            value={data.classes_per_week}
                            type="number"
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DaysComponent
                            required
                            helper_text={helper_text.days}
                            name="days"
                            label="days"
                            value={data.days}
                            onChangeHandle={handleFieldChange}
                        />
                    </Grid>



                    <Grid item xs={12}>
                        <CustomCheckBox
                            value={data.is_active}
                            name='is_active'
                            onChangeHandle={handleFieldChange}
                            label="is_active"
                        />
                    </Grid>

                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                    />

                </Hide>
            </Grid>

        </CustomModal>
    );
};
