import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {CustomTextField} from "../../../components/fields/CustomTextField";
import {ActionButtons} from "../../../components/ActionButton";
import Permissions from "../../../auth/permissions";
import {RetrieveEntitySetting, UpdateEntitySetting} from "./entity_apis";
import {CustomCheckBox} from "../../../components/CustomCheckBox";
import {Loading} from "../../../components/Loading";
import {Hide} from "../../../components/Hide";
import {permissions_data} from "../../../auth/permission_data";
import {AlertContext} from "../../../store/use_context";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {getLabel} from "../../../localization/MainLocalization";
import {TimeField} from "../../../components/fields/TimeField";


export const  EntitySetting = props => {
    const {setAlert} = useContext(AlertContext)
    const have_permission = Permissions.checkPermission(permissions_data.entity_setting.change);

    const [entity_setting, setEntitySetting] = useState({})
    const [helper_text, setHelperText] = useState({})
    const [is_loading, setIsLoading] = useState(true)
    const [edit_data, setEditData] = useState(false);

    useEffect(()=>{
        const fetchData = async () => {
            await setIsLoading(true)
            let response = await RetrieveEntitySetting();
            if (response.status) {
                setEntitySetting(response.data[0])
            }
            await setIsLoading(false)
        }
        fetchData();
    },[edit_data]);

    const handleSettingFieldChange = (name, newValue) => {
        if (have_permission && edit_data) {
            setEntitySetting( {...entity_setting, [name]: newValue})
        }
    };

    const handleEntitySettingSaveButton = async () => {
        await setIsLoading(true)

        let response = await UpdateEntitySetting(entity_setting.id, entity_setting);
        await setHelperText({});
        if (response.status) {
            setEditData(false);
            setAlert("Entity setting updated successfully");
            localStorage.setItem('currency_symbol', response.data.currency_symbol);
        }else{
            await setHelperText(response.error_feedback);
        }

        await setIsLoading(false)
    };

    const handleEditAction = () => {
        setEditData(!edit_data);
    }


    return (
        <>
            <Hide hide={!is_loading}><Loading/></Hide>

            <Hide hide={is_loading}>

                <Hide hide={!have_permission}>
                    <Grid item xs={12} className="text-end">
                        <Button
                            size="small"
                            startIcon={edit_data ? <CloseIcon/> : <EditIcon/>}
                            className={`text-white ${edit_data ? "bg-danger" : "primary-bg"}`}
                            onClick={handleEditAction}
                        >
                            {edit_data ? getLabel("cancel_edit") : getLabel("edit")}
                        </Button>
                    </Grid>
                </Hide>

                <Grid item xs={12}>
                    <CustomTextField
                        viewOnlt={!edit_data}
                        helper_text={helper_text.currency_symbol}
                        error={helper_text.currency_symbol !== undefined}
                        value={entity_setting.currency_symbol}
                        onChangeHandle={handleSettingFieldChange}
                        name="currency_symbol"
                        label="currency_symbol"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        viewOnlt={!edit_data}
                        helper_text={helper_text.membership_invoice_prefix}
                        error={helper_text.membership_invoice_prefix !== undefined}
                        value={entity_setting.membership_invoice_prefix}
                        onChangeHandle={handleSettingFieldChange}
                        name="membership_invoice_prefix"
                        label="membership_invoice_prefix"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        viewOnlt={!edit_data}
                        helper_text={helper_text.expense_invoice_prefix}
                        error={helper_text.expense_invoice_prefix !== undefined}
                        value={entity_setting.expense_invoice_prefix}
                        onChangeHandle={handleSettingFieldChange}
                        name="expense_invoice_prefix"
                        label="expense_invoice_prefix"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        viewOnlt={!edit_data}
                        helper_text={helper_text.normal_client_can_book_classes_within_days}
                        error={helper_text.normal_client_can_book_classes_within_days !== undefined}
                        value={entity_setting.normal_client_can_book_classes_within_days}
                        type="number"
                        onChangeHandle={handleSettingFieldChange}
                        name="normal_client_can_book_classes_within_days"
                        label="normal_client_can_book_classes_within_days"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomTextField
                        viewOnlt={!edit_data}
                        helper_text={helper_text.vip_client_can_book_classes_within_days}
                        error={helper_text.vip_client_can_book_classes_within_days !== undefined}
                        value={entity_setting.vip_client_can_book_classes_within_days}
                        type="number"
                        onChangeHandle={handleSettingFieldChange}
                        name="vip_client_can_book_classes_within_days"
                        label="vip_client_can_book_classes_within_days"
                    />
                </Grid>

                <Grid item xs={12} md={7}>
                    <CustomTextField
                        viewOnlt={!edit_data}
                        disabled={!entity_setting.lock_ended_membership}
                        helper_text={helper_text.lock_after}
                        error={helper_text.lock_after !== undefined}
                        value={entity_setting.lock_after}
                        type="number"
                        onChangeHandle={handleSettingFieldChange}
                        name="lock_after"
                        label="pages.entity.lock_after"
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <CustomCheckBox
                        viewOnlt={!edit_data}
                        value={entity_setting.lock_ended_membership}
                        name='lock_ended_membership'
                        onChangeHandle={handleSettingFieldChange}
                        label="pages.entity.lock_ended_membership"
                    />
                </Grid>

                <Grid item xs={12} md={7}>
                    <TimeField
                        viewOnlt={!edit_data}
                        helper_text={helper_text.classes_register_time}
                        error={helper_text.classes_register_time !== undefined}
                        value={entity_setting.classes_register_time}
                        onChangeHandle={handleSettingFieldChange}
                        name="classes_register_time"
                        label="classes_register_time"
                        disabled={!entity_setting.enable_classes_register_time}
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <CustomCheckBox
                        viewOnlt={!edit_data}
                        value={entity_setting.enable_classes_register_time}
                        name='enable_classes_register_time'
                        onChangeHandle={handleSettingFieldChange}
                        label="enable_classes_registration_on_specific_time_one_day_before"
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomCheckBox
                        viewOnlt={!edit_data}
                        value={entity_setting.is_active}
                        name='is_active'
                        onChangeHandle={handleSettingFieldChange}
                        label="is_active"
                    />
                </Grid>


                <ActionButtons
                    errorMsg={helper_text.non_field_errors}
                    allowSave={edit_data}
                    saveAction={handleEntitySettingSaveButton}
                />
            </Hide>
        </>
    );
}
