import React from "react";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {FlipIconRTL} from "./FlipIconRTL";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {sub_directory} from "../App";
import {getLabel} from "../localization/MainLocalization";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";



export const CustomBreadcrumbs = props => {
    let page = "nothing";
    const details = props.details || {};
    const data = {
        dashboard: {
            path: `${sub_directory}dashboard`,
            path_data: {
                pathname: `${sub_directory}dashboard`,
            },
            tag: getLabel('dashboard'),
            parent: null
        },
        report: {
            path: `${sub_directory}reports`,
            path_data: {
                pathname: `${sub_directory}reports`,
            },
            tag: getLabel('reports'),
            parent: null
        },
        athlete_info: {
            path: `${sub_directory}athlete-info`,
            path_data: {
                pathname: `${sub_directory}athlete-info`,
            },
            tag: "Athlete Information",
            parent: null
        },
        workouts_edit: {
            path: `${sub_directory}workouts/edit`,
            path_data: {
                pathname: `${sub_directory}workouts/edit`,
            },
            tag: getLabel('edit_workout'),
            parent: "workouts"
        },
        workouts_new: {
            path: `${sub_directory}workouts/new`,
            path_data: {
                pathname: `${sub_directory}workouts/new`,
            },
            tag: getLabel('add_workout'),
            parent: "workouts"
        },
        workouts: {
            path: `${sub_directory}workouts`,
            path_data: {
                pathname: `${sub_directory}workouts`,
            },
            tag: getLabel('workouts'),
            parent: null
        },
        my_claims_edit: {
            path: `${sub_directory}my-claims/edit`,
            path_data: {
                pathname: `${sub_directory}my-claims/edit`,
            },
            tag: details.tag,
            parent: 'my_claims'
        },
        my_claims_add: {
            path: `${sub_directory}my-claims/new`,
            path_data: {
                pathname: `${sub_directory}my-claims/new`,
            },
            tag: getLabel('add_claim'),
            parent: 'my_claims'
        },
        my_claims: {
            path: `${sub_directory}my-claims`,
            path_data: {
                pathname: `${sub_directory}my-claims`,
            },
            tag: getLabel('my_claims'),
            parent: null
        },
        staff_claims_view: {
            path: `${sub_directory}claims/view`,
            path_data: {
                pathname: `${sub_directory}claims/view`,
            },
            tag: details.tag,
            parent: 'staff_claims'
        },
        staff_claims: {
            path: `${sub_directory}claims`,
            path_data: {
                pathname: `${sub_directory}claims`,
            },
            tag: getLabel('staff_claims'),
            parent: null
        },
        vendors_add: {
            path: `${sub_directory}vendors/new`,
            path_data: {
                pathname: `${sub_directory}vendors/new`,
            },
            tag: getLabel('add_vendor'),
            parent: 'vendors'
        },
        vendors_edit: {
            path: `${sub_directory}vendors/edit`,
            path_data: {
                pathname: `${sub_directory}vendors/edit`,
            },
            tag: details.tag,
            parent: 'vendors'
        },
        vendors: {
            path: `${sub_directory}vendors`,
            path_data: {
                pathname: `${sub_directory}vendors`,
            },
            tag: getLabel('vendors'),
            parent: null
        },
        screens_edit: {
            path: `${sub_directory}screens/edit`,
            path_data: {
                pathname: `${sub_directory}screens/edit`,
            },
            tag: getLabel('edit_screen'),
            parent: 'screens'
        },
        screens_add: {
            path: `${sub_directory}screens/new`,
            path_data: {
                pathname: `${sub_directory}screens/new`,
            },
            tag: getLabel('add_screen'),
            parent: 'screens'
        },
        screens: {
            path: `${sub_directory}screens`,
            path_data: {
                pathname: `${sub_directory}screens`,
            },
            tag: getLabel('screens'),
            parent: null
        },
        recurring_classes_new: {
            path: `${sub_directory}recurring-classes/new`,
            path_data: {
                pathname: `${sub_directory}recurring-classes/new`,
            },
            tag: getLabel('add_recurring_class'),
            parent: 'recurring_classes'
        },
        recurring_classes_edit: {
            path: `${sub_directory}recurring-classes/edit`,
            path_data: {
                pathname: `${sub_directory}recurring-classes/edit`,
            },
            tag: details.tag,
            parent: 'recurring_classes'
        },
        recurring_classes: {
            path: `${sub_directory}recurring-classes`,
            path_data: {
                pathname: `${sub_directory}recurring-classes`,
            },
            tag: getLabel('recurring_classes'),
            parent: null
        },
        classes_new: {
            path: `${sub_directory}classes/new`,
            path_data: {
                pathname: `${sub_directory}classes/new`,
            },
            tag: getLabel('add_class'),
            parent: 'classes'
        },
        classes_edit: {
            path: `${sub_directory}classes/edit`,
            path_data: {
                pathname: `${sub_directory}classes/edit`,
            },
            tag: details.tag,
            parent: 'classes'
        },
        classes_bulk_edit: {
            path: `${sub_directory}classes/edit-classes`,
            path_data: {
                pathname: `${sub_directory}classes/edit-classes`,
            },
            tag: getLabel('edit_classes'),
            parent: 'classes'
        },
        classes: {
            path: `${sub_directory}classes`,
            path_data: {
                pathname: `${sub_directory}classes`,
            },
            tag: getLabel('classes'),
            parent: null
        },
        reimbursements_info: {
            path: `${sub_directory}reimbursements/edit`,
            path_data: {
                pathname: `${sub_directory}reimbursements/edit`,
            },
            tag: details.tag,
            parent: 'reimbursements'
        },
        reimbursements: {
            path: `${sub_directory}reimbursements`,
            path_data: {
                pathname: `${sub_directory}reimbursements`,
            },
            tag: getLabel('reimbursements'),
            parent: null
        },
        cash_log: {
            path: `${sub_directory}cash-log`,
            path_data: {
                pathname: `${sub_directory}cash-log`,
            },
            tag: getLabel('cash_log'),
            parent: null
        },
        cash_transfer_requests: {
            path: `${sub_directory}cash-transfer-requests`,
            path_data: {
                pathname: `${sub_directory}cash-transfer-requests`,
            },
            tag: getLabel('cash_transfer'),
            parent: null
        },
        rents_new: {
            path: `${sub_directory}rents/new`,
            path_data: {
                pathname: `${sub_directory}rents/new`,
            },
            tag: getLabel('add_rent'),
            parent: "rents"
        },
        rents_edit: {
            path: `${sub_directory}rents/edit`,
            path_data: {
                pathname: `${sub_directory}rents/edit`,
            },
            tag: getLabel('edit_rent'),
            parent: "rents"
        },
        rents: {
            path: `${sub_directory}rents`,
            path_data: {
                pathname: `${sub_directory}rents`,
            },
            tag: getLabel('rent'),
            parent: null
        },
        clients: {
            path: `${sub_directory}clients`,
            path_data: {
                pathname: `${sub_directory}clients`,
            },
            tag: getLabel('clients'),
            parent: null
        },
        clients_edit: {
            path: `${sub_directory}clients/edit`,
            path_data: {
                pathname: `${sub_directory}clients/edit`,
            },
            tag: details.tag,
            parent: "clients"
        },
        clients_new: {
            path: `${sub_directory}clients/new`,
            path_data: {
                pathname: `${sub_directory}clients/new`,
            },
            tag: 'Add Client',
            parent: "clients"
        },
        memberships: {
            path: `${sub_directory}memberships`,
            path_data: {
                pathname: `${sub_directory}memberships`,
            },
            tag: getLabel('memberships'),
            parent: null
        },
        memberships_edit: {
            path: `${sub_directory}memberships/edit`,
            path_data: {
                pathname: `${sub_directory}memberships/edit`,
            },
            tag: details.tag,
            parent: "memberships"
        },
        memberships_new: {
            path: `${sub_directory}memberships/new`,
            path_data: {
                pathname: `${sub_directory}memberships/new`,
            },
            tag: 'Add Memberships',
            parent: "memberships"
        },
        staff: {
            path: `${sub_directory}staff`,
            path_data: {
                pathname: `${sub_directory}staff`,
            },
            tag: getLabel('staff'),
            parent: null
        },
        staff_edit: {
            path: `${sub_directory}staff/edit`,
            path_data: {
                pathname: `${sub_directory}staff/edit`,
            },
            tag: details.tag,
            parent: "staff"
        },
        staff_new: {
            path: `${sub_directory}staff/new`,
            path_data: {
                pathname: `${sub_directory}staff/new`,
            },
            tag: 'Add Staff',
            parent: "staff"
        },
        expenses_category: {
            path: `${sub_directory}expenses/category`,
            path_data: {
                pathname: `${sub_directory}expenses/category`,
            },
            tag: getLabel('expense_category'),
            parent: null
        },
        payment_method: {
            path: `${sub_directory}payment-method`,
            path_data: {
                pathname: `${sub_directory}payment-method`,
            },
            tag: getLabel('payment_method'),
            parent: null
        },
        expenses: {
            path: `${sub_directory}expenses`,
            path_data: {
                pathname: `${sub_directory}expenses`,
            },
            tag: getLabel('expenses'),
            parent: null
        },
        expenses_new: {
            path: `${sub_directory}expenses/new`,
            path_data: {
                pathname: `${sub_directory}expenses/new`,
            },
            tag: getLabel('add_expense'),
            parent: 'expenses'
        },
        expenses_edit: {
            path: `${sub_directory}expenses/edit`,
            path_data: {
                pathname: `${sub_directory}expenses/edit`,
            },
            tag: details.tag,
            parent: 'expenses'
        },
        bank_account: {
            path: `${sub_directory}bank-account`,
            path_data: {
                pathname: `${sub_directory}bank-account`,
            },
            tag: getLabel('bank_account'),
            parent: null
        },
        bank_account_new: {
            path: `${sub_directory}bank-account/new`,
            path_data: {
                pathname: `${sub_directory}bank-account/new`,
            },
            tag: getLabel('add_bank_account'),
            parent: 'bank_account'
        },
        bank_account_edit: {
            path: `${sub_directory}bank-account/edit`,
            path_data: {
                pathname: `${sub_directory}bank-account/edit`,
            },
            tag: getLabel('edit_bank_account'),
            parent: 'bank_account'
        },
        bank_transactions: {
            path: `${sub_directory}bank-transactions`,
            path_data: {
                pathname: `${sub_directory}bank-transactions`,
            },
            tag: getLabel('bank_transactions'),
            parent: null
        },
        entity: {
            path: `${sub_directory}entity`,
            path_data: {
                pathname: `${sub_directory}entity`,
            },
            tag: getLabel('account'),
            parent: null
        },
        equipment: {
            path: `${sub_directory}equipment`,
            path_data: {
                pathname: `${sub_directory}equipment`,
            },
            tag: getLabel('equipment'),
            parent: null
        },
        locations: {
            path: `${sub_directory}locations`,
            path_data: {
                pathname: `${sub_directory}locations`,
            },
            tag: getLabel('locations'),
            parent: null
        },
        locations_edit: {
            path: `${sub_directory}locations/edit`,
            path_data: {
                pathname: `${sub_directory}locations/edit`,
            },
            tag: details.tag,
            parent: 'locations'
        },
        locations_new: {
            path: `${sub_directory}locations/new`,
            path_data: {
                pathname: `${sub_directory}locations/new`,
            },
            tag: 'Add Location',
            parent: 'locations'
        },
        services: {
            path: `${sub_directory}services`,
            path_data: {
                pathname:`${sub_directory}services`,
            },
            tag: getLabel('services'),
            parent: null},
        service_name: {
            path: `no_path`,
            path_data: {
                pathname:`${sub_directory}services/price-lists`,
                state: details
            },
            tag: details.service_name,
            parent: 'services',
            disabled: true
        },
        price_lists: {
            path: `${sub_directory}services/price-lists`,
            path_data: {
                pathname:`${sub_directory}services/price-lists`,
                state: details
            },
            tag: 'Price Lists',
            parent: 'service_name'
        },
        price_list_name: {
            path: `no_path`,
            path_data: {
                pathname: `${sub_directory}services/price-lists/prices`,
                state: details
            },
            tag: details.pricelist_name,
            parent: 'price_lists',
            disabled: true

        },
        prices: {
            path: `${sub_directory}services/price-lists/prices`,
            path_data: {
                pathname: `${sub_directory}services/price-lists/prices`,
            },
            tag: 'Prices',
            parent: 'price_list_name'
        },
        offers_price_list: {
            path: `${sub_directory}services/price-lists/offers`,
            path_data: {
                pathname:`${sub_directory}services/price-lists/offers`,
                state: details
            },
            tag: 'Offers Price List',
            parent: 'price_list_name'
        },
        offers_price_list_name: {
            path: `no_path`,
            path_data: {
                pathname:`${sub_directory}services/price-lists/offers`,
                state: details
            },
            tag: details.offer_name,
            parent: 'offers_price_list',
            disabled: true
        },
        offer_prices: {
            path: `${sub_directory}services/price-lists/offers/prices`,
            path_data: {
                pathname: `${sub_directory}services/price-lists/offers/prices`,
            },
            tag: 'Offer Prices',
            parent: 'offers_price_list_name'
        },
        banners: {
            path: `${sub_directory}banners`,
            path_data: {
                pathname: `${sub_directory}banners`,
            },
            tag: getLabel('banners'),
            parent: null
        },
        banners_edit: {
            path: `${sub_directory}banners/edit`,
            path_data: {
                pathname: `${sub_directory}banners/edit`,
            },
            tag: details.tag,
            parent: 'banners'
        },
        banners_new: {
            path: `${sub_directory}banners/new`,
            path_data: {
                pathname: `${sub_directory}banners/new`,
            },
            tag: getLabel("add_banner"),
            parent: 'banners'
        },
        athletes: {
            path: `${sub_directory}athletes`,
            path_data: {
                pathname: `${sub_directory}athletes`,
            },
            tag: getLabel('athletes'),
            parent: null
        },
        athlete_edit: {
            path: `${sub_directory}athletes/edit`,
            path_data: {
                pathname: `${sub_directory}athletes/edit`,
            },
            tag: details.tag,
            parent: 'athletes'
        },
        athlete_new: {
            path: `${sub_directory}athletes/new`,
            path_data: {
                pathname: `${sub_directory}athletes/new`,
            },
            tag: getLabel("add_athlete"),
            parent: 'athletes'
        },
        seasons: {
            path: `${sub_directory}teams`,
            path_data: {
                pathname:`${sub_directory}teams`,
            },
            tag: getLabel('teams'),
            parent: null},
        season_name: {
            path: `no_path`,
            path_data: {
                pathname:`${sub_directory}teams/athletes`,
                state: details
            },
            tag: details.season_name,
            parent: 'seasons',
            disabled: true
        },
        // groups: {
        //     path: `${sub_directory}seasons/groups`,
        //     path_data: {
        //         pathname: `${sub_directory}seasons/groups`,
        //         state: details
        //     },
        //     tag: getLabel('groups'),
        //     parent: 'season_name',
        //     disabled: true
        // },
        group_name: {
            path: `no_path`,
            path_data: {
                pathname: `${sub_directory}teams/athletes`,
                state: details
            },
            tag: details.group_name,
            parent: 'season_name',
            disabled: true

        },
        athletes_group: {
            path: `${sub_directory}teams/athletes`,
            path_data: {
                pathname: `${sub_directory}teams/athletes`,
            },
            tag: getLabel('athletes'),
            parent: 'group_name'
        },
        benchmarks: {
            path: `${sub_directory}benchmarks`,
            path_data: {
                pathname:`${sub_directory}benchmarks`,
            },
            tag: getLabel('benchmarks'),
            parent: null
        },
        deleted_classes: {
            path: `${sub_directory}classes/deleted-classes`,
            path_data: {
                pathname: `${sub_directory}classes/deleted-classes`,
            },
            tag: getLabel('restore_classes'),
            parent: 'classes'
        },

    }

    for (let key in data) {
        if ([props.match.url, props.match.url.slice(0, -1)].includes(data[key].path)) {
            page = key;
        }
    }


    const getBreadcrumbs = (value, index) => {
        if (value === "nothing"){
            return null
        }
        let parent = ""
        if (data[value].parent !== null) {
            parent = getBreadcrumbs(data[value].parent, index + 1);
        }

        return (
            <Breadcrumbs separator={<FlipIconRTL><NavigateNextIcon fontSize="large"/></FlipIconRTL>}>

                {parent}

                <Button key={index} component={Link} className="font-weight-bolder" size="large"  color="inherit" to={data[value].path_data}
                        disabled={index === 0 || data[value].disabled}>
                    <Hidden mdDown>
                        <Typography variant="h6" className={index !== 0 && !data[value].disabled ?"text-dark":""}>
                            {data[value].tag}
                        </Typography>
                    </Hidden>

                    <Hidden lgUp>
                        <Typography variant="p" component="p" className={index !== 0 && !data[value].disabled ?"text-dark":""}>
                            {data[value].tag}
                        </Typography>
                    </Hidden>
                </Button>
            </Breadcrumbs>
        )

    }

    return getBreadcrumbs(page, 0);

}
