import React from "react";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {getLabel} from "../../localization/MainLocalization";

export const SearchField = props => {

    return (
        <form onSubmit={e => e.preventDefault()}>
            <TextField
                style={{minWidth:"150px"}}
                placeholder={getLabel("search")}
                autoComplete="off"
                value={props.value || ""}
                onChange={(e) => props.onChangeHandle(props.name, e.target.value)}
                InputProps={{
                    startAdornment: (
                        <Tooltip title={getLabel("search")}>
                            <IconButton type="submit" size="small"
                                        color='inherit' onClick={props.action}>
                                <SearchIcon/>
                            </IconButton>
                        </Tooltip>
                    ),
                }}
            />
        </form>
    );
};