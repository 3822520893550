import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {FormHeader} from "../../../../components/FormHeader";
import {FormActionButtons} from "../../../../components/FormActionButton";
import "../../../../assets/scss/Static.scss";
import {CustomModal} from "../../../../components/CustomModal";
import {httpRequestWithAuth} from "../../../../auth/axios_instance";
import {BaseGenerateClassSessionsURL} from "../../../../urls";
import {Hide} from "../../../../components/Hide";
import {Loading} from "../../../../components/Loading";
import Typography from "@material-ui/core/Typography";
import {getLabel} from "../../../../localization/MainLocalization";
import {AlertContext} from "../../../../store/use_context";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import {compareTime} from "../../../../App";
import {CustomTextField} from "../../../../components/fields/CustomTextField";
import {CustomDisplayDataTable} from "../../../../components/CustomDisplayDataTable";


export const RecurringClassesGenerateModal = props => {
    const {setAlert} = useContext(AlertContext);
    const [helper_text, setHelperText] = useState({non_field_errors: props.data.can_generate_classes.error});
    const [data] = useState({...props.data, schedule: JSON.parse(props.data.schedule)});
    const [is_loading, setIsLoading] = useState(false);
    const [columns,] = useState([
        {title: getLabel("season_name"), field: "season_name", cellStyle: {width:"20%"}},
        {title: getLabel("team"), field: "name"},
        {title: getLabel("start_date"), field: "season_start_date", cellStyle: {width:"15%"}},
        {title: getLabel("end_date"), field: "season_end_date", cellStyle: {width:"15%"}},
        {title: getLabel("notes"), field: "notes", cellStyle: {color: "red"}},
    ]);
    const [required_athlete_teams_details, setRequiredAthleteTeamsDetails] = useState(props.data.required_groups_to_attend_data || []);
    const [optional_athlete_teams_details, setOptionalAthleteTeamsDetails] = useState(props.data.optional_groups_to_attend_data || []);

    useEffect(() => {
        let temp_data = props.data.required_groups_to_attend_data
        temp_data.map(Data => {
            let notes = "";

            if (moment(Data.season_start_date, "DD-MM-YYYY").isAfter(moment(data.schedule.from_date))) {
                notes = "Season duration not full includes in recurring class generate dates"
            }

            if (moment(Data.season_end_date, "DD-MM-YYYY").isBefore(moment(data.schedule.to_date))) {
                notes = "Season duration not full includes in recurring class generate dates"
            }

            if (!Data.is_active) {
                notes = "Team not Active"
            }

            if (!Data.season_is_active) {
                notes = "Season not Active"
            }

            Data.notes = notes
        })
        setRequiredAthleteTeamsDetails(temp_data);
    }, [])


    useEffect(() => {
        let temp_data = props.data.optional_groups_to_attend_data
        temp_data.map(Data => {
            let notes = "";

            if (moment(Data.season_start_date, "DD-MM-YYYY").isAfter(moment(data.schedule.from_date))) {
                notes = "Season duration not full includes in recurring class generate dates"
            }

            if (moment(Data.season_end_date, "DD-MM-YYYY").isBefore(moment(data.schedule.to_date))) {
                notes = "Season duration not full includes in recurring class generate dates"
            }

            if (!Data.is_active) {
                notes = "Team not Active"
            }

            if (!Data.season_is_active) {
                notes = "Season not Active"
            }

            Data.notes = notes
        })
        setOptionalAthleteTeamsDetails(temp_data);
    }, [])

    const handleSaveForm = async () => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(BaseGenerateClassSessionsURL, "POST", {class: data.id});
        if (response.status) {
            props.handleModalClose();
            await setAlert("Classes has been created successfully");
        } else {
            await setHelperText(response.error_feedback);
        }
        await setIsLoading(false);
    };

    return (
        <CustomModal
            open={props.open}
        >

            <Grid container spacing={2}>

                <FormHeader
                    title="confirm_generate_classes"
                    handleClose={props.handleModalClose}
                />

                <Hide hide={!is_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={is_loading}>

                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={data.name}
                            label="classes_name"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={data.location_name}
                            label="location"
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={data.training_area_name}
                            label="training_area"
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={data.service_name}
                            label="service"
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={data.staff_name}
                            label="staff"
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={data.assigned_to_view}
                            label="assigned_to"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={data.class_for_view}
                            label="class_for"
                        />
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={data.clients_capacity}
                            label="clients_capacity"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={data.optional_athletes_capacity}
                            label="optional_athletes_capacity"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={moment(data.schedule.from_date).format("DD-MM-YYYY")}
                            label="from_date"
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            viewOnly
                            size="small"
                            value={moment(data.schedule.to_date).format("DD-MM-YYYY")}
                            label="to_date"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            {getLabel("required_teams_to_attend")}
                        </Typography>
                    </Grid>

                    <Hide hide={!data.for_athletes}>
                        <Grid item xs={12} className="text-end mb-2">
                            <CustomDisplayDataTable
                                columns={columns}
                                data={required_athlete_teams_details}
                            />
                        </Grid>
                    </Hide>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            {getLabel("optional_teams_to_attend")}
                        </Typography>
                    </Grid>

                    <Hide hide={!data.for_athletes}>
                        <Grid item xs={12} className="text-end mb-2">
                            <CustomDisplayDataTable
                                columns={columns}
                                data={optional_athlete_teams_details}
                            />
                        </Grid>
                    </Hide>

                    {Object.keys(data.schedule.days).map((Day, index) => {
                        data.schedule.days[Day].sort(compareTime)

                        return (
                            <Grid item xs={12} className="mt-2" key={index}>
                                <div className="text-center border w-100 d-md-inline-flex align-items-center h-100">

                                    <div
                                        className="bg-light p-1 h-auto justify-content-center align-items-center d-flex"
                                        style={{minWidth: '120px'}}>
                                        <Typography variant="subtitle1">
                                            {getLabel(Day)}
                                        </Typography>
                                    </div>

                                    <Divider horizontal variant="fullWidth"/>

                                    <div>
                                        {data.schedule.days[Day].map((Data, index) => {
                                            return (
                                                <Chip key={`${Day}-${Data.start_time}-${Data.end_time}`} className="m-2"
                                                      label={`${Data.start_time} - ${Data.end_time}`}
                                                      size="small"
                                                />
                                            )
                                        })}
                                    </div>

                                    <Hide hide={data.schedule.days[Day].length !== 0}>
                                        <Typography variant="body1" className="text-black-50 m-2">
                                            <span>{getLabel("empty_data_source_message")}</span>
                                        </Typography>
                                    </Hide>
                                </div>
                            </Grid>
                        )
                    })}


                    <Grid item xs={12} className="mt-2"/>


                    <FormActionButtons
                        errorMsg={helper_text.non_field_errors}
                        handleCancelButton={props.handleModalClose}
                        handleSaveButton={handleSaveForm}
                        saveLabel="generate_classes"
                    />
                </Hide>

            </Grid>
        </CustomModal>
    );
}
